import { EMAIL_REGEXP } from '@packs/layouts/AppLayer/constants';

import { Form } from './Form';

import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';

const validate = ({ email, county, location }) => {
  const errors = {};

  if (email && !EMAIL_REGEXP.test(email)) {
    errors.email = 'Email is not valid';
  }
  if (!county) errors.county = 'Required';
  if (!location) errors.location = 'Required';

  return errors;
};

const mapStateToProps = (state, props) => ({ formAttributes: getFormValues(props.form)(state) });

const createReduxForm = reduxForm({ form: 'personalizeForm', validate });

export default createReduxForm(connect(mapStateToProps)(Form));
