import {
  removeShoppingCartItemMutation,
  removeShoppingCartItemUnauthorizedMutation,
  shoppingCartItemsCreateMutation,
  shoppingCartItemsCreateUnauthorizedMutation,
  shoppingCartRetrieve,
  shoppingCartUpdate
} from '@packs/graphql/queries/shoppingCart';
import { graphQLRequest } from '@packs/lib/utils';

const api = {
  shoppingCartRetrieve: variables =>
    graphQLRequest({
      query: shoppingCartRetrieve,
      variables
    }),
  createShoppingCartItems: variables =>
    graphQLRequest({
      query: shoppingCartItemsCreateMutation,
      variables: { input: variables }
    }),
  createShoppingCartItemsUnauthorized: variables =>
    graphQLRequest({
      query: shoppingCartItemsCreateUnauthorizedMutation,
      variables: { input: variables }
    }),
  removeShoppingCartItem: variables =>
    graphQLRequest({
      query: removeShoppingCartItemMutation,
      variables: { input: variables }
    }),
  removeShoppingCartItemUnauthorized: variables =>
    graphQLRequest({
      query: removeShoppingCartItemUnauthorizedMutation,
      variables: { input: variables }
    }),
  shoppingCartUpdate: variables =>
    graphQLRequest({
      query: shoppingCartUpdate,
      variables: { input: variables }
    })
};

export default api;
