import React, { FC } from 'react';
import cx from 'classnames';

import RatingNormalIcon from '@packs/images/svg/ui/smile.svg';
import RatingBadIcon from '@packs/images/svg/ui/smile-bad.svg';
import RatingGoodIcon from '@packs/images/svg/ui/smile-good.svg';

const ratingSteps = 10;

export const stepsCollection = Array.from(Array(ratingSteps), (_, i) => i + 1);

type Props = {
  value: number | null;
  onChange?: (val: number) => void;
  disabled?: boolean;
  onTrigger?: () => void;
  controlError?: string;
  touched?: boolean;
  submitFailed?: boolean;
};

export const Rate: FC<Props> = ({
  value,
  onChange,
  disabled,
  onTrigger,
  controlError,
  touched,
  submitFailed = false
}) => {
  const onClickHandler = i => {
    if (disabled) {
      return;
    }
    if (onChange) {
      onChange(i);
    }
    if (onTrigger) {
      onTrigger();
    }
  };

  const ratingSmileIcon = index => {
    if (index <= 5) {
      return RatingBadIcon;
    }

    if (index <= 7) {
      return RatingNormalIcon;
    }

    return RatingGoodIcon;
  };

  return (
    <div className='rate-block'>
      <div className='rate-icon-container'>
        <div>
          <div className='rating-list d-flex align-items-center'>
            {stepsCollection.map((item, index) => (
              <div key={item} className='d-flex flex-column align-items-center'>
                <div className={cx('rating-item', { active: value === item })} onClick={() => onClickHandler(item)}>
                  {item}
                </div>
                <div className='rating-smile'>
                  <img src={ratingSmileIcon(index)} alt='Smile' />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {controlError && (touched || submitFailed) && <div className='error-hint'>{controlError}</div>}
    </div>
  );
};

export default Rate;
