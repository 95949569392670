import borders from '@crm/assets/theme/base/borders';
import colors from '@crm/assets/theme/base/colors';
import typography from '@crm/assets/theme/base/typography';
import pxToRem from '@shared/assets/theme/functions/pxToRem';

const { size } = typography;
const { text } = colors;
const { borderWidth, borderColor } = borders;

const dialogContent = {
  styleOverrides: {
    root: {
      padding: pxToRem(16),
      fontSize: size.md,
      color: text.main
    },

    dividers: {
      borderTop: `${borderWidth[1]} solid ${borderColor}`,
      borderBottom: `${borderWidth[1]} solid ${borderColor}`
    }
  }
};

export default dialogContent;
