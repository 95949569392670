import React from 'react';

export const GovernmentIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path
          d='M10.9999 2V5C13.9999 5 12.9999 6 14.9999 6H15.9999V3H14.9999C12.9999 3 13.9999 2 10.9999 2ZM1.28114 7C0.630139 7 0.152435 7.61019 0.310435 8.24219L0.810435 10.2422C0.921435 10.6872 1.32214 11 1.78114 11H22.2186C22.6776 11 23.0783 10.6872 23.1893 10.2422L23.6893 8.24219C23.8473 7.61019 23.3696 7 22.7186 7H1.28114ZM2.98426 12.9863C2.71939 12.9905 2.46698 13.0995 2.28243 13.2896C2.09787 13.4796 1.99626 13.7351 1.99989 14V19C1.44789 19 0.999889 19.448 0.999889 20V22C0.999889 22.552 1.44789 23 1.99989 23H21.9999C22.5519 23 22.9999 22.552 22.9999 22V20C22.9999 19.448 22.5519 19 21.9999 19V14C22.0017 13.8662 21.9767 13.7334 21.9263 13.6095C21.8759 13.4855 21.8011 13.373 21.7064 13.2784C21.6117 13.1839 21.499 13.1094 21.3749 13.0592C21.2509 13.009 21.118 12.9842 20.9843 12.9863C20.7194 12.9905 20.467 13.0995 20.2824 13.2896C20.0979 13.4796 19.9963 13.7351 19.9999 14V19H15.9999V14C16.0017 13.8662 15.9767 13.7334 15.9263 13.6095C15.8759 13.4855 15.8011 13.373 15.7064 13.2784C15.6117 13.1839 15.499 13.1094 15.3749 13.0592C15.2509 13.009 15.118 12.9842 14.9843 12.9863C14.7194 12.9905 14.467 13.0995 14.2824 13.2896C14.0979 13.4796 13.9963 13.7351 13.9999 14V19H9.99989V14C10.0017 13.8662 9.97669 13.7334 9.92627 13.6095C9.87586 13.4855 9.80108 13.373 9.70638 13.2784C9.61168 13.1839 9.49897 13.1094 9.37493 13.0592C9.25089 13.009 9.11805 12.9842 8.98426 12.9863C8.71939 12.9905 8.46698 13.0995 8.28242 13.2896C8.09787 13.4796 7.99626 13.7351 7.99989 14V19H3.99989V14C4.00172 13.8662 3.97669 13.7334 3.92627 13.6095C3.87586 13.4855 3.80108 13.373 3.70638 13.2784C3.61168 13.1839 3.49897 13.1094 3.37493 13.0592C3.25089 13.009 3.11805 12.9842 2.98426 12.9863Z'
          fill='white'
        />
      </g>
    </svg>
  );
};
