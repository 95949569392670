import React from 'react';

export default () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18.3948 5.14798C18.3709 4.62182 18.3709 4.11958 18.3709 3.61734C18.3709 3.21076 18.06 2.89985 17.6534 2.89985C14.6639 2.89985 12.3918 2.03886 10.5024 0.197309C10.2154 -0.0657698 9.78493 -0.0657698 9.49794 0.197309C7.60855 2.03886 5.3365 2.89985 2.34697 2.89985C1.94039 2.89985 1.62948 3.21076 1.62948 3.61734C1.62948 4.11958 1.62948 4.62182 1.60556 5.14798C1.5099 10.1704 1.3664 17.0583 9.76101 19.9522L10.0002 20L10.2393 19.9522C18.61 17.0583 18.4905 10.1943 18.3948 5.14798ZM9.42619 12.1076C9.28269 12.2272 9.11527 12.299 8.92394 12.299H8.90003C8.7087 12.299 8.51737 12.2033 8.39779 12.0598L6.17357 9.59641L7.2498 8.63976L8.99569 10.577L12.8701 6.89387L13.8507 7.94619L9.42619 12.1076Z'
        fill='#00C7A6'
      />
    </svg>
  );
};
