import theme from '@crm/assets/theme';

import styled from 'styled-components';

const { palette, borders, typography } = theme;

const { borderRadius } = borders;
const { size } = typography;
const { text, white, dark, grey, primary } = palette;

export default styled.div`
  & .quill {
    display: flex;
    flex-direction: column;
    background-color: ${white.main};
    border-radius: ${borderRadius.md};
    border: ${props => `${props.isEditorFocused ? 2 : 1}px solid ${props.isEditorFocused ? primary.main : '#DEE2E6'}`};
  
  & .ql-toolbar {
    height: ${props => (props.isEditorFocused ? 'auto' : 0)};
    padding: ${props => (props.isEditorFocused ? '8px' : 0)};
    order: 2;
    border-radius: 0 0 ${borderRadius.md} ${borderRadius.md};
    border: none;
    background-color: ${grey[100]};
    overflow: hidden;
      
    & .ql-picker,
    & .ql-stroke {
      stroke: ${dark.main} !important;
      color: ${dark.main} !important;
    }
  }
  & .ql-container {
    order: 1;
    border-radius: ${borderRadius.md} ${borderRadius.md} 0 0;
    border: none;
  }
    
  & .ql-editor {
    padding: 11px 16px;
    color: ${text.main};

    & p {
      font-size: ${size.md};
      color: ${text.main};
    },

    & ul li {
      color: ${text.main}
    }
  }
`;
