const colors = {
  background: {
    default: '#F4F4F4'
  },

  text: {
    main: '#7b809a',
    focus: '#7b809a',
    secondary: 'rgba(0, 0, 0, 0.60)'
  },

  transparent: {
    main: 'transparent'
  },

  white: {
    main: '#ffffff',
    focus: '#ffffff'
  },

  black: {
    light: '#000000',
    main: '#000000',
    focus: '#000000'
  },

  primary: {
    main: '#3969EC',
    focus: '#1A73E8',
    transparent: 'rgba(1, 103, 205, 0.08)',
    50: '#A9CCFF',
    100: '#539AFF',
    200: '#4C8CFF',
    300: '#457FFF',
    400: '#3F74FF',
    500: '#3969EC',
    600: '#335FD4',
    700: '#2E55BF',
    800: '#2A4DAC',
    900: '#25459B'
  },

  secondary: {
    main: '#F8B500',
    focus: '#F9DB4F'
  },

  info: {
    main: '#0288D1',
    focus: '#1A73E8'
  },

  success: {
    main: '#43A148',
    focus: '#67bb6a'
  },

  warning: {
    main: '#ED6C02',
    focus: '#fc9d26'
  },

  error: {
    main: '#D32F2F',
    focus: '#f65f53'
  },

  light: {
    main: '#f0f2f5',
    focus: '#f0f2f5'
  },

  dark: {
    main: 'rgba(0, 0, 0, 0.87)',
    focus: '#2c3c58'
  },

  borderColor: {
    main: 'rgba(0, 0, 0, 0.16)'
  },

  category: {
    insurance: {
      main: '#00C7A6',
      light: '#DEF3ED',
      dark: '#51B096'
    },
    broadband: {
      main: '#8860D0',
      light: '#E6DFF4',
      dark: '#7558B4'
    },
    mobile: {
      main: '#E05BB8',
      light: '#F6E0F0',
      dark: '#BB59A2'
    },
    energy: {
      main: '#F19B78',
      light: '#FCEBE4',
      dark: '#D88B6C'
    },
    subscriptions: {
      main: '#489FF8',
      light: '#DAECFE',
      dark: '#418FDF'
    },
    business: {
      main: '#3274D9',
      light: '#D6E3F7',
      dark: '#2D68C3'
    },
    government: {
      main: '#456f93',
      light: '#D6E3F7',
      dark: '#2D68C3'
    },
    banking: {
      main: '#5AC47B',
      light: '#DEF3E5',
      dark: '#51B06E'
    },
    waste_collection: {
      main: '#00C7BB',
      light: '#DEF3F0',
      dark: '#00AFA7'
    },
    'general-category': {
      main: '#00AFC7',
      light: '#DEF3F7',
      dark: '#0099B3'
    },
    food: {
      main: '#F44336',
      light: '#F6685E',
      dark: '#AA2E25'
    },
    travel: {
      main: '#9C27B0',
      light: '#AF52BF',
      dark: '#6D1B7B'
    },
    local_services: {
      main: '#FFC107',
      light: '#FFCD38',
      dark: '#B28704'
    }
  },

  statuses: {
    active: '#D4F8D3',
    archived: '#FBE7E9',
    draft: '#E8E8E8'
  },

  grey: {
    100: '#f8f9fa',
    200: '#f0f2f5',
    300: '#dee2e6',
    400: '#ced4da',
    500: '#adb5bd',
    600: '#6c757d',
    700: '#495057',
    800: '#343a40',
    900: '#212529'
  },

  blue: {
    main: '#EEF5FF',
    secondary: '#A9CCFF',
    contrastText: '#fff',
    twitter: '#1D9BF0'
  },

  orange: {
    main: '#F8B500',
    focus: '#f3bd3f'
  },

  gradients: {
    primary: {
      main: '#4C8CFF',
      state: '#3969EC'
    },

    secondary: {
      main: 'rgba(0, 0, 0, 0.6)',
      state: '#495361'
    },

    info: {
      main: '#49a3f1',
      state: '#1A73E8'
    },

    success: {
      main: '#66BB6A',
      state: '#43A047'
    },

    warning: {
      main: '#FFA726',
      state: '#FB8C00'
    },

    error: {
      main: '#EF5350',
      state: '#E53935'
    },

    light: {
      main: '#EBEFF4',
      state: '#CED4DA'
    },

    dark: {
      main: 'rgba(0, 0, 0, 0.87)',
      state: '#191919'
    }
  },

  socialMediaColors: {
    facebook: {
      main: '#3b5998',
      dark: '#344e86'
    },

    twitter: {
      main: '#55acee',
      dark: '#3ea1ec'
    },

    instagram: {
      main: '#125688',
      dark: '#0e456d'
    },

    linkedin: {
      main: '#0077b5',
      dark: '#00669c'
    },

    pinterest: {
      main: '#cc2127',
      dark: '#b21d22'
    },

    youtube: {
      main: '#e52d27',
      dark: '#d41f1a'
    },

    vimeo: {
      main: '#1ab7ea',
      dark: '#13a3d2'
    },

    slack: {
      main: '#3aaf85',
      dark: '#329874'
    },

    dribbble: {
      main: '#ea4c89',
      dark: '#e73177'
    },

    github: {
      main: '#24292e',
      dark: '#171a1d'
    },

    reddit: {
      main: '#ff4500',
      dark: '#e03d00'
    },

    tumblr: {
      main: '#35465c',
      dark: '#2a3749'
    }
  },

  badgeColors: {
    primary: {
      background: '#EEF5FF',
      text: '#3969EC'
    },

    secondary: {
      background: '#d7d9e1',
      text: '#6c757d'
    },

    info: {
      background: '#aecef7',
      text: '#095bc6'
    },

    success: {
      background: '#bce2be',
      text: '#339537'
    },

    warning: {
      background: '#ffd59f',
      text: '#c87000'
    },

    error: {
      background: '#fcd3d0',
      text: '#f61200'
    },

    light: {
      background: '#ffffff',
      text: '#c7d3de'
    },

    dark: {
      background: '#8097bf',
      text: '#1e2e4a'
    }
  },

  coloredShadows: {
    primary: '#3969EC',
    secondary: '#110e0e',
    info: '#00bbd4',
    success: '#4caf4f',
    warning: '#ff9900',
    error: '#f44336',
    light: '#adb5bd',
    dark: '#404040'
  },

  inputBorderColor: '#d2d6da',

  tabs: {
    indicator: { boxShadow: '#ddd' }
  }
};

export default colors;
