import { styled } from '@mui/material/styles';

import { enqueueSnackbar, MaterialDesignContent } from 'notistack';

export const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: '#43A148'
  }
}));

const notify = (variant, opts) => {
  let title = opts[0];

  if (Array.isArray(title)) {
    title = title.toString();
  }

  const options = opts[1] || {};

  enqueueSnackbar(title, { variant, ...options });
};

const toastr = {
  success: (...opts) => notify('success', opts),
  info: (...opts) => notify('info', opts),
  warning: (...opts) => notify('warning', opts),
  error: (...opts) => notify('error', opts),
  message: (...opts) => notify('message', opts),
  confirm: (...opts) => notify('confirm', opts),
  custom: (...opts) => notify('customActionSnackbar', opts)
};

export default toastr;
