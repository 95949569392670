export const useHandleFormSubmit = (props, onSuccess, onError) => {
  const { renewalDetail } = props;
  return (data = {}) => {
    const params = {
      renewalDetailId: renewalDetail?.id,
      ...data
    };

    props.updateAdvancedPriceDispatch({
      ...params,
      callback: onSuccess,
      errorCallback: onError
    });
  };
};
