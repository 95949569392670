import { action, observable, runInAction } from 'mobx';
import moment from 'moment';

import api from '@packs/apis/company';
import { commentableType } from '@packs/lib/constants';
import { setError } from '@packs/lib/utils';

import { renewalDetailApi, reviewCommentApi } from '../apis';

const concatPostTypes = res => {
  return [
    ...(res?.renewalDetailList.map(item => ({ type: 'insight', ...item })) || []),
    ...(res?.voteList.map(item => ({ type: 'vote', ...item })) || [])
  ].sort((a, b) => moment(b.updatedAt) - moment(a.updatedAt));
};

export class InsightsCompanyStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable posts = [];
  @observable postsTotalCount = 0;
  @observable totalCountReviews = {
    claim: 0,
    purchase: 0,
    review: 0
  };
  @observable groupByPercentInsights = {
    excellent: 0,
    fair: 0,
    good: 0,
    great: 0,
    poor: 0
  };
  @observable createdAtFirstYear = moment().year();
  @observable object = {};
  @observable companyIsLoading = false;
  @observable loading = false;
  @observable answers = {};

  @action load(attrs) {
    this.object = {};
    this.companyIsLoading = true;
    api.fetchInsightsCompany(attrs).then(resp => {
      if (resp?.errors?.length) {
        setError(resp.errors);
        return Promise.reject(resp.errors);
      }

      runInAction(() => {
        this.object = resp;
        this.companyIsLoading = false;
      });
    });
  }

  @action loadPosts = async (attrs, more) => {
    this.loading = true;
    if (!more) {
      this.posts = [];
    }
    const resp = await renewalDetailApi.fetchRenewalDetailsList(attrs);

    if (resp?.errors?.length) {
      setError(resp.errors);
      return Promise.reject(resp.errors);
    }

    runInAction(() => {
      if (more) {
        this.posts = [...this.posts, ...(resp?.list || [])];
      } else {
        this.posts = resp?.list;
      }

      this.postsTotalCount = resp?.totalCount;
      this.totalCountReviews = resp?.totalCountReviews;
      this.groupByPercentInsights = resp?.groupByPercentInsights;
      this.createdAtFirstYear = resp?.createdAtFirstYear;
      this.loading = false;
    });
  };

  @action loadProfilePosts = async (attrs, more) => {
    this.loading = true;
    if (!more) {
      this.posts = [];
    }
    const resp = await renewalDetailApi.fetchRenewalDetailsProfileList(attrs);

    if (resp?.errors?.length) {
      setError(resp.errors);
      return Promise.reject(resp.errors);
    }

    runInAction(() => {
      const list = concatPostTypes(resp);

      if (more) {
        this.posts = [...this.posts, ...list];
      } else {
        this.posts = list;
      }

      this.postsTotalCount = resp?.totalCount;
      this.createdAtFirstYear = resp?.createdAtFirstYear;
      this.loading = false;
    });
  };

  @action addPost(post) {
    this.posts = [post, ...this.posts];
    this.postsTotalCount = this.postsTotalCount + 1;
  }

  @action loadAnswersInsight = async attrs => {
    const res = await reviewCommentApi.list(attrs);

    if (res?.errors?.length) {
      return Promise.reject(res.errors);
    }

    runInAction(() => {
      this.answers = {
        ...this.answers,
        [attrs.renewalDetailId]: res
      };
    });

    return res;
  };

  @action createCommentInsight = async (attrs, postId, type) => {
    const res = await reviewCommentApi.create(attrs);

    if (res?.errors?.length) {
      return Promise.reject(res.errors);
    }
    runInAction(() => {
      const answers = { ...this.answers };

      if (type === commentableType.post) {
        answers[postId] = [...answers[postId], res.reviewComment];

        this.posts = this.posts.map(item => {
          const newData = { ...item };
          if (item.id === postId) {
            newData.reviewCommentsCount = ++newData.reviewCommentsCount;
          }

          return newData;
        });
      } else if (type === commentableType.comment) {
        answers[postId] = answers[postId].map(item => {
          if (item.id === attrs.replayCommentId) {
            item.usersReviewsCount = ++item.usersReviewsCount;
            item.replies = [...item.replies, res.reviewComment];

            return item;
          }
          return item;
        });
      }

      this.answers = answers;
    });

    return res;
  };

  @action toggleHelpfulInsight = ({ attrs, postId }) => {
    renewalDetailApi.toggleHelpfulRenewalDetail(attrs).then(res => {
      runInAction(() => {
        if (res?.errors?.length) {
          return Promise.reject(res.errors);
        }

        this.posts = this.posts.map(post => {
          if (post.id === postId) {
            post.helpfulCount = res?.renewalDetail?.helpfulCount || 0;
            post.isHelpful = res?.renewalDetail?.isHelpful || false;
          }

          return post;
        });
      });
    });
  };

  @action toggleHelpfulInsightComment = async ({ attrs, postId, rootCommentId }) => {
    const res = await reviewCommentApi.toggleHelpful(attrs);

    if (res?.errors?.length) {
      return Promise.reject(res.errors);
    }

    runInAction(() => {
      const answers = { ...this.answers };

      answers[postId] = answers[postId].map(item => {
        if (item.id === attrs.commentId) {
          item.helpfulCount = res?.reviewComment?.helpfulCount || 0;
          item.isHelpful = res?.reviewComment?.isHelpful || false;
        } else if (item.id === rootCommentId) {
          item.replies = item.replies.map(item => {
            if (item.id === attrs.commentId) {
              item.helpfulCount = res?.reviewComment?.helpfulCount || 0;
              item.isHelpful = res?.reviewComment?.isHelpful || false;
            }

            return item;
          });
        }

        return item;
      });

      this.answers = answers;
    });

    return res;
  };
}
