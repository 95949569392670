import colors from '@packs/assets/theme/base/colors';

const { white, borderColor } = colors;

export default () => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: {
      xs: 'column',
      sm: 'row'
    },
    gap: 1,
    borderRadius: '8px',
    border: `1px solid ${borderColor.main}`,
    p: 1,
    backgroundColor: white.main,
    cursor: 'pointer'
  },
  textAreaWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    width: {
      xs: '100%',
      sm: 'auto'
    },
    minHeight: '40px',
    backgroundColor: '#EEE',
    borderRadius: '4px',
    py: 1,
    px: 2
  },
  button: {
    width: {
      xs: '100%',
      sm: 'auto'
    }
  }
});
