import colors from '@packs/assets/theme/base/colors';

const { dark } = colors;

export default () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '417px',
    maxWidth: '100vw',
    height: '100%'
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${dark.transparent}`,
    py: 2,
    px: 3
  },
  bodyWrapper: {
    mb: 'auto',
    px: {
      xs: 2,
      md: 5
    },
    py: {
      xs: 2,
      md: 4
    },
    overflowY: 'auto'
  },
  btnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 2,
    borderTop: `1px solid ${dark.transparent}`,
    py: 2,
    px: 2
  }
});
