import React, { useState } from 'react';
import cx from 'classnames';

import ArrowDownIcon from '@packs/images/svg/dropdown-arrow-gray.svg';
import { countriesCoinsCurrency, rateCostList } from '@packs/lib/constants';
import { useStore } from '@packs/stores';

import { DebugName } from './debug';
import { InputField } from './InputField';
import { SelectField } from './SelectField';

import get from 'lodash-es/get';
import startCase from 'lodash-es/startCase';
import AnimateHeight from 'react-animate-height';

export const TrackUnitPrices = props => {
  const { category, simpleForm, formValues, setRerender, rerender } = props;
  const { currentCountry } = useStore();

  const name = 'advancedPrice';

  const values = formValues[name];

  const [advancedPriceIsOpen, setAdvancedPriceIsOpen] = useState(false);
  const electricity = category?.code === 'electricity';
  const gas = category?.code === 'gas';
  const electricityAndGas = category?.code === 'gas_and_electricity';
  const coinCurrency =
    countriesCoinsCurrency.find(item => item.code === currentCountry?.isoA2Code)?.coin ||
    countriesCoinsCurrency[0]?.coin;
  const coinCurrencyName =
    countriesCoinsCurrency.find(item => item.code === currentCountry?.isoA2Code)?.name ||
    countriesCoinsCurrency[0]?.name;

  return (
    <>
      <DebugName name={name} />
      <div className='advanced-price'>
        <div
          className='advanced-price-title d-flex align-items-center justify-content-between'
          onClick={() => {
            setAdvancedPriceIsOpen(!advancedPriceIsOpen);
            if (simpleForm) {
              setTimeout(() => {
                setRerender(!rerender);
              }, 250);
            }
          }}
        >
          <span>Track your unit prices (Optional) </span>
          <img src={ArrowDownIcon} alt='down' className={cx({ rotate: advancedPriceIsOpen })} />
        </div>
        <AnimateHeight duration={simpleForm ? 0 : 250} height={advancedPriceIsOpen ? 'auto' : 0}>
          {(gas || electricityAndGas) && (
            <div>
              <div className='label contract-length-label d-flex align-items-center'>
                <div className='contract-length-wrapper d-flex align-items-center mb-16'>
                  <span className='mr-8'>
                    {electricityAndGas && <span className='type-name'>GAS </span>}
                    Unit rate cost per (KWh) :
                  </span>
                  <SelectField
                    name={`${name}.gas.includingTaxes`}
                    className='select-ui medium gray-bg light-border'
                    classNamePrefix='Select'
                    isSearchable={false}
                    options={rateCostList}
                    placeholder='Select an option'
                    value={get(formValues, `${name}.gas.includingTaxes`, null)}
                  />
                </div>
              </div>
              <div className='form-row-list d-flex flex-column gap-8'>
                <div className='input-field'>
                  <div className='label mb-8'>Cost per day ({startCase(coinCurrencyName)})</div>
                  <InputField
                    name={`${name}.gas.dayPrice`}
                    value={values?.gas?.dayPrice || 0}
                    placeholder='Price...'
                    currencySymbol={coinCurrency}
                    maxLength={4}
                    symbolsAfterDot={2}
                    withDot
                    numeric
                  />
                </div>
                <div className='input-field'>
                  <div className='label mb-8'>Cost per night ({startCase(coinCurrencyName)})</div>
                  <InputField
                    name={`${name}.gas.nightPrice`}
                    value={values?.gas?.nightPrice || 0}
                    placeholder='Price...'
                    currencySymbol={coinCurrency}
                    maxLength={4}
                    symbolsAfterDot={2}
                    withDot
                    numeric
                  />
                </div>
                <div className='input-field'>
                  <div className='label mb-8'>Standing charge ({startCase(coinCurrencyName)})</div>
                  <InputField
                    name={`${name}.gas.standingPrice`}
                    value={values?.gas?.standingPrice || 0}
                    placeholder='Price...'
                    currencySymbol={coinCurrency}
                    maxLength={4}
                    symbolsAfterDot={2}
                    withDot
                    numeric
                  />
                </div>
              </div>
            </div>
          )}

          {(electricity || electricityAndGas) && (
            <div>
              <div className='label contract-length-label d-flex align-items-center'>
                <div className='contract-length-wrapper d-flex align-items-center mb-16'>
                  <span className='mr-8'>
                    {electricityAndGas && <span className='type-name'>Electricity </span>}
                    Unit rate cost per (KWh) :
                  </span>
                  <SelectField
                    name={`${name}.electricity.includingTaxes`}
                    className='select-ui medium'
                    classNamePrefix='Select'
                    isSearchable={false}
                    options={rateCostList}
                    placeholder='Select an option'
                    value={get(formValues, `${name}.electricity.includingTaxes`, null)}
                  />
                </div>
              </div>
              <div className='form-row-list d-flex flex-column gap-8'>
                <div className='input-field'>
                  <div className='label mb-8'>Cost per day ({startCase(coinCurrencyName)})</div>
                  <InputField
                    name={`${name}.electricity.dayPrice`}
                    value={values?.electricity?.dayPrice || 0}
                    placeholder='Price...'
                    currencySymbol={coinCurrency}
                    maxLength={4}
                    symbolsAfterDot={2}
                    withDot
                    numeric
                  />
                </div>
                <div className='input-field'>
                  <div className='label mb-8'>Cost per night ({startCase(coinCurrencyName)})</div>
                  <InputField
                    name={`${name}.electricity.nightPrice`}
                    value={values?.electricity?.nightPrice || 0}
                    placeholder='Price...'
                    currencySymbol={coinCurrency}
                    maxLength={4}
                    symbolsAfterDot={2}
                    withDot
                    numeric
                  />
                </div>
                <div className='input-field'>
                  <div className='label mb-8'>Standing charge ({startCase(coinCurrencyName)})</div>
                  <InputField
                    name={`${name}.electricity.standingPrice`}
                    value={values?.electricity?.standingPrice || 0}
                    placeholder='Price...'
                    currencySymbol={coinCurrency}
                    maxLength={4}
                    symbolsAfterDot={2}
                    withDot
                    numeric
                  />
                </div>
              </div>
            </div>
          )}
        </AnimateHeight>
      </div>
    </>
  );
};
