import borders from '@crm/assets/theme/base/borders';
import boxShadows from '@crm/assets/theme/base/boxShadows';
import colors from '@crm/assets/theme/base/colors';
import typography from '@crm/assets/theme/base/typography';
import appBar from '@crm/assets/theme/components/appBar';
import avatar from '@crm/assets/theme/components/avatar';
import breadcrumbs from '@crm/assets/theme/components/breadcrumbs';
import button from '@crm/assets/theme/components/button';
import buttonBase from '@crm/assets/theme/components/buttonBase';
import card from '@crm/assets/theme/components/card';
import cardContent from '@crm/assets/theme/components/card/cardContent';
import cardMedia from '@crm/assets/theme/components/card/cardMedia';
import container from '@crm/assets/theme/components/container';
import dialog from '@crm/assets/theme/components/dialog';
import dialogActions from '@crm/assets/theme/components/dialog/dialogActions';
import dialogContent from '@crm/assets/theme/components/dialog/dialogContent';
import dialogContentText from '@crm/assets/theme/components/dialog/dialogContentText';
import dialogTitle from '@crm/assets/theme/components/dialog/dialogTitle';
import flatpickr from '@crm/assets/theme/components/flatpickr';
import autocomplete from '@crm/assets/theme/components/form/autocomplete';
import checkbox from '@crm/assets/theme/components/form/checkbox';
import formLabel from '@crm/assets/theme/components/form/formLabel';
import input from '@crm/assets/theme/components/form/input';
import inputLabel from '@crm/assets/theme/components/form/inputLabel';
import inputOutlined from '@crm/assets/theme/components/form/inputOutlined';
import radio from '@crm/assets/theme/components/form/radio';
import switchButton from '@crm/assets/theme/components/form/switchButton';
import textField from '@crm/assets/theme/components/form/textField';
import icon from '@crm/assets/theme/components/icon';
import link from '@crm/assets/theme/components/link';
import list from '@crm/assets/theme/components/list';
import listItem from '@crm/assets/theme/components/list/listItem';
import listItemText from '@crm/assets/theme/components/list/listItemText';
import menu from '@crm/assets/theme/components/menu';
import menuItem from '@crm/assets/theme/components/menu/menuItem';
import popover from '@crm/assets/theme/components/popover';
import sidenav from '@crm/assets/theme/components/sidenav';
import slider from '@crm/assets/theme/components/slider';
import svgIcon from '@crm/assets/theme/components/svgIcon';
import muiDataGrid from '@crm/assets/theme/components/table/muiDataGrid';
import tableCell from '@crm/assets/theme/components/table/tableCell';
import tableContainer from '@crm/assets/theme/components/table/tableContainer';
import tableHead from '@crm/assets/theme/components/table/tableHead';
import tabs from '@crm/assets/theme/components/tabs';
import tab from '@crm/assets/theme/components/tabs/tab';
import tooltip from '@crm/assets/theme/components/tooltip';
import boxShadow from '@crm/assets/theme/functions/boxShadow';
import hexToRgb from '@crm/assets/theme/functions/hexToRgb';
import linearGradient from '@crm/assets/theme/functions/linearGradient';
import rgba from '@crm/assets/theme/functions/rgba';
import { createTheme } from '@mui/material/styles';
import breakpoints from '@shared/assets/theme/base/breakpoints';
import globals from '@shared/assets/theme/base/globals';
import pxToRem from '@shared/assets/theme/functions/pxToRem';

export default createTheme({
  breakpoints: { ...breakpoints },
  palette: { ...colors },
  typography: { ...typography },
  boxShadows: { ...boxShadows },
  borders: { ...borders },
  functions: {
    boxShadow,
    hexToRgb,
    linearGradient,
    pxToRem,
    rgba
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ...globals,
        ...flatpickr,
        ...container
      }
    },
    MuiDrawer: { ...sidenav },
    MuiList: { ...list },
    MuiListItem: { ...listItem },
    MuiListItemText: { ...listItemText },
    MuiCard: { ...card },
    MuiCardMedia: { ...cardMedia },
    MuiCardContent: { ...cardContent },
    MuiButton: { ...button },
    MuiInput: { ...input },
    MuiInputLabel: { ...inputLabel },
    MuiOutlinedInput: { ...inputOutlined },
    MuiTextField: { ...textField },
    MuiMenu: { ...menu },
    MuiMenuItem: { ...menuItem },
    MuiSwitch: { ...switchButton },
    MuiTableContainer: { ...tableContainer },
    MuiTableHead: { ...tableHead },
    MuiTableCell: { ...tableCell },
    MuiDataGrid: { ...muiDataGrid },
    MuiBreadcrumbs: { ...breadcrumbs },
    MuiSlider: { ...slider },
    MuiAvatar: { ...avatar },
    MuiTooltip: { ...tooltip },
    MuiAppBar: { ...appBar },
    MuiTabs: { ...tabs },
    MuiTab: { ...tab },
    MuiFormLabel: { ...formLabel },
    MuiCheckbox: { ...checkbox },
    MuiRadio: { ...radio },
    MuiAutocomplete: { ...autocomplete },
    MuiPopover: { ...popover },
    MuiButtonBase: { ...buttonBase },
    MuiIcon: { ...icon },
    MuiSvgIcon: { ...svgIcon },
    MuiLink: { ...link },
    MuiDialog: { ...dialog },
    MuiDialogTitle: { ...dialogTitle },
    MuiDialogContent: { ...dialogContent },
    MuiDialogContentText: { ...dialogContentText },
    MuiDialogActions: { ...dialogActions }
  }
});
