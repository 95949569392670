import React, { useState } from 'react';
import { startCase } from 'lodash-es';
import cx from 'classnames';

import { Typography } from '@mui/material';
import colors from '@packs/assets/theme/base/colors';
import { Switcher } from '@packs/components/Switcher';
import Delete from '@packs/images/icons/Delete';
import MyNotesIcon from '@packs/images/icons/MyNotesIcon';
import { autoQuoteTooltip, autoQuoteTooltipNoPolicy } from '@packs/lib/constants';
import { hasPolicyCriteria } from '@packs/lib/helpers/additionalHelpers';
import { useStore } from '@packs/stores';
import { TooltipMUI } from '@shared/components/TooltipMUI/TooltipMUI';
import { SUB_ICONS } from '@shared/helpers/subCategoryIcon';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

export const ReminderItem = ({ event, handleClickEdit, handleClickDelete, withAutoQuote }) => {
  const { smartRemindersStore } = useStore();
  const { isMobile } = useWindowSize();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { isAutoQuote, quoteRequest = null } = event;

  const onClickEdit = () => {
    handleClickEdit(event);
  };

  const onClickDelete = () => {
    handleClickDelete(event);
  };

  const onClickToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleIsAutoQuote = () => {
    if (quoteRequest) {
      smartRemindersStore.toggleIsAutoQuote({
        isAutoQuote: !isAutoQuote,
        smartReminder: { ...event, id: event?.renewalDetail?.smartReminder?.id }
      });
    } else {
      onClickEdit();
    }
  };

  return (
    <div className='reminder-item-wrapper'>
      <div className='reminder-item d-flex align-items-center justify-content-between'>
        <div className='reminder-data d-flex'>
          <div className='name d-flex align-items-center gap-8'>
            {event.category &&
              SUB_ICONS({ category: event.category, fill: colors?.category[event.category?.root?.enName]?.main })}
            <span>
              {startCase(event.category?.name)} {event.aliasName}
            </span>
          </div>
          <div className='status-box d-flex align-items-center'>
            <div className={cx('status', event.status)} />
            <span>Status</span>
          </div>
        </div>
        <div className='reminder-action d-flex align-items-center'>
          <Dropdown isOpen={dropdownOpen} direction='down' toggle={onClickToggle}>
            <DropdownToggle tag='span' className='dote-container'>
              <span className='dote' />
              <span className='dote' />
              <span className='dote' />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={onClickEdit}>
                <span>
                  <MyNotesIcon />
                </span>
                Edit
              </DropdownItem>

              <DropdownItem onClick={onClickDelete}>
                <span className='delete-icon'>
                  <Delete />
                </span>
                Delete
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      {withAutoQuote && isMobile && (
        <TooltipMUI
          title={
            <Typography variant='caption'>
              {hasPolicyCriteria(event?.renewalDetail) ? autoQuoteTooltip : autoQuoteTooltipNoPolicy}
            </Typography>
          }
          arrow
          placement='top'
        >
          <div className='d-flex align-items-center justify-content-between mt-8 mb-8 pl-16 pr-16'>
            <div className='fs-13 lh-5'>Auto. quote</div>
            <Switcher
              textOff='off'
              textOn='on'
              primary
              value={isAutoQuote}
              onChange={toggleIsAutoQuote}
              disabled={hasPolicyCriteria(event?.renewalDetail)}
            />
          </div>
        </TooltipMUI>
      )}
    </div>
  );
};
