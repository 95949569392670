import React from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { TooltipMUI } from '@shared/components/TooltipMUI/TooltipMUI';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import useStyles from './styles';

const { text } = colors;

export const AdditionalItem = ({ title, value, tooltip }) => {
  const { isMobile } = useWindowSize();
  const styles = useStyles();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={styles.additionalItem}>
        <Typography variant={isMobile ? 'body3' : 'body5'} sx={{ color: text.secondary }}>
          {title}
        </Typography>
        {tooltip && (
          <TooltipMUI title={tooltip} placement='top' arrow>
            <InfoOutlinedIcon sx={{ fontSize: '16px', color: text.secondary }} />
          </TooltipMUI>
        )}
      </Box>
      <Typography variant={isMobile ? 'body2' : 'body6'}>{value}</Typography>
    </Box>
  );
};
