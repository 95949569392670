import React from 'react';

export const BankingPrepaidCards = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <path d='M15.08 8.07985C15.2455 8.07985 15.4109 8.08683 15.5764 8.1008C15.7583 8.11615 15.92 7.97651 15.92 7.79394V6.53985C15.92 6.37416 15.7857 6.23985 15.62 6.23985H0.8C0.634315 6.23985 0.5 6.37416 0.5 6.53985V11.5798C0.5 12.2198 1.02 12.7398 1.66 12.7398H9.21114C9.35545 12.7398 9.47848 12.6368 9.51132 12.4963C10.1044 9.95871 12.3855 8.07985 15.08 8.07985Z' />
      <path d='M15.92 2.92016C15.92 2.28016 15.4 1.76016 14.76 1.76016H1.66C1.02 1.76016 0.5 2.28016 0.5 2.92016V4.02016C0.5 4.18584 0.634315 4.32016 0.8 4.32016H15.62C15.7857 4.32016 15.92 4.18584 15.92 4.02016V2.92016Z' />
      <path d='M15.08 9.4C12.64 9.4 10.66 11.38 10.66 13.82C10.66 16.26 12.64 18.24 15.08 18.24C17.52 18.24 19.5 16.26 19.5 13.82C19.5 11.38 17.52 9.4 15.08 9.4ZM15.7562 15.9324C15.6224 15.983 15.52 16.1029 15.52 16.2459V16.48C15.52 16.58 15.44 16.66 15.34 16.66H14.82C14.72 16.66 14.64 16.58 14.64 16.48V16.2885C14.64 16.1356 14.5227 16.0113 14.3791 15.9589C13.969 15.8093 13.6699 15.4293 13.62 14.98C13.62 14.9532 13.6289 14.9175 13.6409 14.8847C13.6519 14.8545 13.6704 14.8267 13.6973 14.8089C13.7263 14.7896 13.7583 14.78 13.8 14.78H14.34C14.42 14.78 14.5 14.84 14.52 14.92C14.54 15.06 14.66 15.14 14.8 15.14H15.26C15.5 15.14 15.72 14.96 15.74 14.74C15.7582 14.6305 15.7265 14.5043 15.6448 14.4223C15.6291 14.4066 15.6134 14.3909 15.5979 14.375C15.5187 14.2933 15.4076 14.26 15.28 14.26H14.94C14.24 14.26 13.62 13.74 13.56 13.06C13.5074 12.4468 13.8539 11.895 14.3977 11.6869C14.5336 11.6349 14.64 11.5139 14.64 11.3683V11.16C14.64 11.06 14.72 10.98 14.82 10.98H15.34C15.44 10.98 15.52 11.06 15.52 11.16V11.3515C15.52 11.5044 15.6372 11.6287 15.7808 11.6811C16.1909 11.8307 16.49 12.2107 16.54 12.66C16.54 12.6868 16.531 12.7225 16.5191 12.7553C16.508 12.7855 16.4895 12.8133 16.4626 12.8311C16.4336 12.8504 16.4016 12.86 16.36 12.86H15.82C15.74 12.86 15.66 12.8 15.64 12.72C15.62 12.58 15.5 12.5 15.36 12.5H14.9C14.66 12.5 14.44 12.68 14.42 12.9C14.4017 13.0095 14.4334 13.1357 14.5152 13.2177C14.5308 13.2334 14.5466 13.2491 14.562 13.265C14.6412 13.3467 14.7523 13.38 14.88 13.38H15.28C15.66 13.38 16.02 13.54 16.26 13.82C16.52 14.1 16.64 14.46 16.6 14.84C16.5654 15.3408 16.2327 15.7521 15.7562 15.9324Z' />
    </svg>
  );
};
