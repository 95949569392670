import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { Avatar } from '@packs/components/UI';

const { dark } = colors;

export const RenewalDetailsModal = ({ closeModal, renewalDetail, isOpen, onClickNo, onClickYes }) => {
  const buyingType = renewalDetail?.buyingType;
  const anyCompanyName = renewalDetail?.anyCompany?.name;
  const anyCompanyAvatar = renewalDetail?.anyCompany?.avatarUrl;

  return (
    <Dialog open={isOpen} onClose={closeModal}>
      <DialogTitle sx={{ borderBottom: `1px solid ${dark.transparent}` }}>Update details</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={closeModal}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: dark.main
        }}
      >
        <CloseIcon sx={{ fontSize: '32px' }} />
      </IconButton>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
          {(anyCompanyAvatar || anyCompanyName) && (
            <Avatar avatarUrl={anyCompanyAvatar} size='80px' variant='rounded' placeholder={anyCompanyName} />
          )}
          <Typography variant='subtitle1' sx={{ mt: 2, mb: 3 }}>
            {anyCompanyName}
          </Typography>
          <Typography variant='h5'>
            Are you still with this <br />
            {buyingType}?
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ borderTop: `1px solid ${dark.transparent}` }}>
        <Button variant='text' color='dark' size='smallToMedium' onClick={onClickNo ? onClickNo : closeModal}>
          No
        </Button>
        <Button
          variant='contained'
          color='primary'
          size='smallToMedium'
          onClick={() => {
            onClickYes && onClickYes();
            closeModal();
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
