import {
  categoryLandingPage,
  categoryRootPage,
  categorySubscribe,
  fetchCategory,
  fetchCategoryAveragePriceQuery,
  fetchCategoryCompanyDetails,
  leafCategoriesQuery,
  savingsInformationShow
} from '@packs/graphql/queries/category';
import { graphQLRequest } from '@packs/lib/utils';

const api = {
  categorySubscribe: variables =>
    graphQLRequest({
      query: categorySubscribe,
      variables: { input: variables }
    }),
  fetchCategory: ({ id }) =>
    graphQLRequest({
      query: fetchCategory,
      variables: { id }
    }),
  fetchCategoryAveragePrice: variables =>
    graphQLRequest({
      query: fetchCategoryAveragePriceQuery,
      variables
    }),
  fetchLeftCategories: variables =>
    graphQLRequest({
      query: leafCategoriesQuery,
      variables
    }),
  fetchCategoryCompanies: () =>
    graphQLRequest({
      query: fetchCategoryCompanyDetails
    }),
  fetchCategoryLandingPage: variables =>
    graphQLRequest({
      query: categoryLandingPage,
      variables
    }),
  fetchCategoryRootPage: variables =>
    graphQLRequest({
      query: categoryRootPage,
      variables
    }),
  fetchSavingsInformation: variables =>
    graphQLRequest({
      query: savingsInformationShow,
      variables
    })
};

export { api as categoryApi };
export default api;
