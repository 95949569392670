import React, { useState } from 'react';
import _ from 'lodash-es';
import cx from 'classnames';
import moment from 'moment';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { toastr } from '@packs/lib/helpers';
import { SAVE_DATE_FORMAT } from '@packs/screens/PersonalizeResults/formHooks/defaults';
import { useStore } from '@packs/stores';

import { observer } from 'mobx-react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

export const EditAliasNameModal = observer(({ isOpen, category, renewalDetail, formValues, ...props }) => {
  const [aliasName, setAliasName] = useState(formValues?.aliasName);
  const { userStore } = useStore();
  const { smartRemindersStore, policiesListStore } = useStore();

  const refresh = () => {
    // TODO reloadForm() policy
    props.refresh && props.refresh();
  };

  const submitHandler = () => {
    const { aliasName, triggeredAt } = formValues || {};
    if (!aliasName) {
      return;
    }

    props.toggle();

    const smartReminderId = _.get(renewalDetail, 'smartReminder.id');
    const triggeredAtValue =
      !triggeredAt || moment(triggeredAt).diff(moment(), 'hours') < 0
        ? moment(triggeredAt).add(1, 'day').toDate()
        : triggeredAt;

    const onSuccess = () => {
      userStore.setProfileReminderCount(userStore.userProfileStats.remindersCount + 1);
      policiesListStore
        .loadPolicyData({
          categoryId: category.id
        })
        .then(() => {
          refresh();
        });
      toastr.success(`Smart reminder ${smartReminderId ? 'updated' : 'created'}!`);
    };

    const onError = err => {
      if (err.base) {
        toastr.error(err.base[0]);
      }
    };

    const attrs = {
      aliasName,
      triggeredAt: moment(triggeredAtValue).format(SAVE_DATE_FORMAT),
      reminderType: 'select',
      categoryId: category.id,
      renewalDetailId: renewalDetail.id
    };

    if (smartReminderId) {
      smartRemindersStore.update({ ...attrs, id: renewalDetail.smartReminder.id }, onSuccess, onError);
    } else {
      smartRemindersStore.create(attrs, onSuccess, onError);
    }
  };

  const categoryName = category && category.root && category.root.enName;

  return (
    <Modal {...{ isOpen }} toggle={props.toggle} className={`edit-policy-modal ${categoryName}`}>
      <ModalHeader toggle={props.toggle}>Edit Policy Name</ModalHeader>
      <ModalBody>
        <div className='sub-title mb-24'>Add name for your policy.</div>
        <div className='form'>
          <div className='input-field'>
            <TextField
              name='aliasName'
              className='form-input'
              type='text'
              label='Policy name'
              variant='outlined'
              value={aliasName}
              onChange={ev => {
                props.change('aliasName', ev.target.value);
                setAliasName(ev.target.value);
              }}
            />
            {!aliasName && <span className='error-hint'>Policy Name is required</span>}
          </div>
        </div>

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 3, p: 2 }}>
          <Button variant='filledTonal' color='primary' onClick={props.toggle}>
            Cancel
          </Button>
          <Button variant='contained' color='primary' disabled={!aliasName} onClick={submitHandler}>
            <span>Save</span>
          </Button>
        </Box>
      </ModalBody>
    </Modal>
  );
});
