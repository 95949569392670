import { action, observable } from 'mobx';

import { additionalApi } from '@packs/apis';
import { toastr } from '@packs/lib/helpers';
import { setError } from '@packs/lib/utils';

export class AdditionalStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable
  activeSliderID = null;

  @action setActiveSliderId(sliderId) {
    this.activeSliderID = sliderId;
  }

  @action associationSendMail(attrs) {
    return additionalApi.associationSendMail(attrs).then(resp => {
      if (resp?.errors?.length) {
        setError(resp.errors);
        return Promise.reject(resp.errors);
      }
      toastr.success('Association was successful added');
      return resp;
    });
  }
}
