import React, { useMemo } from 'react';
import _, { map } from 'lodash-es';
import moment from 'moment';

import { getCurrency } from '@packs/lib/constants';
import { useDetailsForm } from '@packs/screens/PersonalizeResults/DetailsFormProvider';
import { useStore } from '@packs/stores/base';

import { SidebarAnswer } from '../SidebarAnswer';

export const SidebarContentCriteria = ({ formValues, category, formOptions }) => {
  const { countryCode } = useStore();

  const criteriaValues = useMemo(() => {
    return formValues?.criteriaQuestions;
  }, [formValues?.criteriaQuestions]);

  const { categoryCriteriaQuestions } = useDetailsForm();

  const currency = useMemo(() => {
    return getCurrency(countryCode);
  }, [countryCode]);

  const pricesQuestions = useMemo(() => {
    return formValues?.pricesQuestions?.price || {};
  }, [formValues?.pricesQuestions?.price]);

  const list = useMemo(() => {
    if (!criteriaValues) {
      return [];
    }

    return map(
      categoryCriteriaQuestions.filter(i => criteriaValues[i.name]),
      item => {
        const criteriaValue = criteriaValues[item.name];

        let answer = item?.options?.length
          ? item?.options.find(option => option.value === criteriaValue)?.label
          : criteriaValue;
        const claimTitle = formValues.anyCompanyId && !formValues.sourceProviderCompanyId ? 'broker' : 'provider';

        const label =
          item.label &&
          item.label.replace(':category_name', _.startCase(category.name)).replace(':buyingType', claimTitle);

        if (item.name === 'companiesClients') {
          answer = (formOptions?.criteriaQuestions?.companiesClients || []).find(
            ({ value }) => value === criteriaValue
          )?.label;
        }

        if (item.name === 'datepicker') {
          answer = moment(criteriaValue).format('MMM DD, YYYY');
        }

        return { answer, label };
      }
    );
  }, [formValues, formOptions?.criteriaQuestions?.companiesClients, categoryCriteriaQuestions]);

  return (
    <div>
      {list.map(({ label, answer }) => (
        <SidebarAnswer key={label} label={label} answer={answer} />
      ))}
      {!!pricesQuestions?.value && (
        <SidebarAnswer
          label='Price'
          answer={`${currency} ${pricesQuestions?.value}/${
            pricesQuestions?.value?.period === 'per_month' ? 'Per Month' : 'Per Year'
          }`}
        />
      )}
    </div>
  );
};
