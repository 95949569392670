import React from 'react';

import { Switch } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useDetailsForm } from '@packs/screens/PersonalizeResults/DetailsFormProvider';

import { DebugName } from './debug';

export const SwitcherField = props => {
  const { getRef, onChange } = useDetailsForm();

  const { name, size = 'medium', value, label, disabled } = props;

  if (!name) {
    throw new Error('no name');
  }

  return (
    <label className='field switcher-checkbox d-flex align-items-center cursor-pointer'>
      <DebugName name={name} />
      <Switch ref={getRef} name={name} size={size} checked={!!value} onChange={onChange} disabled={disabled} />
      {label && <Typography variant='body2'>{label}</Typography>}
    </label>
  );
};
