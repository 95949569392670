import React, { useState } from 'react';
import { get, map } from 'lodash-es';
import cx from 'classnames';
import moment from 'moment';

import { Popover } from '@packs/components';
import { ReminderItem } from '@packs/components/Calendar/components/ReminderItem';
import Close from '@packs/images/svg/close.svg';
import { VIEW_LONG_DATE_FORMAT } from '@packs/screens/PersonalizeResults/formHooks/defaults';

import { PopoverBody, PopoverHeader } from 'reactstrap';

export const CustomEventPopover = ({
  target,
  event,
  events,
  yearType,
  isMiddleDay,
  trigger,
  onCalendarEditReminder,
  onCalendarDeleteReminder,
  onCalendarAddNewReminder
}) => {
  const [open, setOpen] = useState(false);

  const handleClickEdit = evt => {
    setOpen(false);
    setTimeout(() => {
      onCalendarEditReminder(evt);
    }, 100);
  };

  const handleClickDelete = evt => {
    setOpen(false);
    setTimeout(() => {
      onCalendarDeleteReminder(evt);
    }, 100);
  };

  const onClickAdd = () => {
    setOpen(false);
    setTimeout(() => {
      onCalendarAddNewReminder();
    }, 50);
  };

  return (
    <Popover
      delay={50}
      trigger={trigger || 'legacy'}
      placement='right-end'
      target={target}
      className={cx('calendar-popover', get(event, 'root.code'), { 'middle-day': isMiddleDay })}
      isOpen={open}
      toggle={() => {
        setOpen(!open);
      }}
    >
      <PopoverHeader>
        <span>
          {yearType
            ? events.length > 0 && moment(events[0].triggeredAt).format(VIEW_LONG_DATE_FORMAT)
            : moment(event.triggeredAt).format(VIEW_LONG_DATE_FORMAT)}
        </span>

        <span
          className='close'
          onClick={() => {
            setOpen(!open);
          }}
        >
          <img src={Close} alt='Close' />
        </span>
      </PopoverHeader>
      <PopoverBody>
        {yearType ? (
          <div className='items-list'>
            {map(events, (e, i) => (
              <ReminderItem
                key={`${e.id}_${target}`}
                event={e}
                withAutoQuote
                {...{ handleClickEdit, handleClickDelete }}
              />
            ))}
          </div>
        ) : (
          <ReminderItem
            key={`${event.id}_${target}`}
            withAutoQuote
            event={event}
            {...{ handleClickEdit, handleClickDelete }}
          />
        )}
      </PopoverBody>

      {yearType && (
        <div className='add-new-reminder' onClick={onClickAdd}>
          Add a new Reminder
        </div>
      )}
    </Popover>
  );
};
