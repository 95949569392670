import colors from '@packs/assets/theme/base/colors';

const { borderColor, white } = colors;

export default () => ({
  noteItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    borderRadius: '16px',
    border: `1px solid ${borderColor.main}`,
    p: 2,
    backgroundColor: white.main
  },
  noteHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    mb: 2
  },
  noteBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2
  },
  categoryWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 2
  }
});
