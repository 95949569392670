import React, { useState } from 'react';
import _ from 'lodash-es';

import { DaySeriesModel } from '@fullcalendar/common';
import { getYearData } from '@packs/components/Calendar/yearGridCustomPlugin/getYearData';
import { MonthDesktop } from '@packs/components/Calendar/yearGridCustomPlugin/MonthDesktop';
import { MonthMobile } from '@packs/components/Calendar/yearGridCustomPlugin/MonthMobile';
import { useWindowSize } from '@shared/hooks/useWindowSize';

export const YearTable = ({ dateProfile, eventStore, ...otherProps }) => {
  const daySeries = new DaySeriesModel(dateProfile.renderRange, { isHiddenDay: () => false });
  const yearData = getYearData(new Date().getFullYear(), daySeries.dates);
  const weekData = yearData[0].monthData.cells[1];

  const events = Object.values(eventStore.defs).map(s => ({
    start: new Date(s.extendedProps.triggeredAt),
    allDay: true,
    ...s.extendedProps
  }));

  const { windowSize } = useWindowSize();
  const [popoverOpen, setPopoverOpen] = useState();
  const [targetPopover, setTargetPopover] = useState();

  const openPopover = idDate => {
    setPopoverOpen(true);
    setTargetPopover(idDate);
  };

  let yearColumns = 4;

  if (windowSize < 768) {
    yearColumns = 1;
  } else if (windowSize < 992) {
    yearColumns = 2;
  } else if (windowSize < 1200) {
    yearColumns = 3;
  }

  const eventsData = {};
  const eventsMonthData = {};
  _.each(events, el => {
    const k1 = el.start.toDateString();
    const k2 = `${el.start.getFullYear()}-${el.start.getMonth()}`;
    if (!eventsData[k1]) eventsData[k1] = [];
    if (!eventsMonthData[k2]) eventsMonthData[k2] = [];
    eventsData[k1].push(el);
    eventsMonthData[k2].push(el);
  });

  let index = -1;

  return (
    <div className='fc-view-container'>
      <div className='fc-view fc-year-view'>
        {/* mobile */}
        {yearColumns === 1 &&
          _.map(yearData, ({ monthIndex, monthData }, indx) => (
            <MonthMobile
              key={monthIndex}
              openPopover={openPopover}
              {...monthData}
              {...{ eventsData, eventsMonthData, weekData, monthIndex }}
            />
          ))}

        {/* desktop */}
        {yearColumns !== 1 && (
          <table className='fc-year-main-table fc-border-separate'>
            <tbody>
              {_.times(12 / yearColumns, rInx => (
                <tr key={rInx}>
                  <td className='fc-year-month-border fc-first'></td>
                  {_.times(yearColumns, cInx => {
                    index += 1;
                    const monthObj = yearData[index].monthData;
                    const monthIndex = yearData[index].monthIndex;
                    return (
                      <MonthDesktop
                        key={cInx}
                        rowIndex={rInx}
                        openPopover={openPopover}
                        {...monthObj}
                        {...{ eventsData, weekData, monthIndex, eventsMonthData }}
                      />
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
