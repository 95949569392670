import { companiesFragment } from './fetchCompaniesList';

export const fetchWikiPricesCompanies = `
  query wikiPricesCompanies(
    $categoryId: Int,
    $page: Int,
    $companyType: String,
    $lastBuy: String,
    $perPage: Int,
    $orderColumn: CompanyWikiOrderColumnEnum,
    $orderDirection: String,
    $criteriaQuestions: JSON,
    $keyWord: String,
    $zipCode: String,
    $city: String,
    $address: String,
    $policyId: Int,
  ) {
    wikiPricesCompanies(
      categoryId: $categoryId,
      companyType: $companyType,
      criteriaQuestions: $criteriaQuestions,
      keyWord: $keyWord,
      zipCode: $zipCode,
      city: $city,
      lastBuy: $lastBuy,
      policyId: $policyId,
      address: $address,
      perPage: $perPage,
      page: $page,
      orderColumn: $orderColumn,
      orderDirection: $orderDirection,
    ) {
    ${companiesFragment}
    allCriteriasMatch
    errors
    totalCount
  }
}
`.trim();
