import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { BreadcrumbsMUI } from '@packs/components/MaterialUi/BreadcrumbsMUI/BreadcrumbsMUI';
import useContainerStyles from '@packs/styles/Global/container';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import useStyles from './styles';

const { white } = colors;

export const PageTitleWrapper = ({
  breadcrumbsRoutes,
  currentPage,
  title,
  subTitle,
  homeIsLanding = false,
  sx = {},
  children
}) => {
  const { isMobile } = useWindowSize();
  const styles = useStyles();
  const stylesContainer = useContainerStyles();

  return (
    <Box sx={{ ...styles.wrapper, ...sx }}>
      <Box sx={styles.innerWrapper}>
        <Box sx={{ ...stylesContainer.container, ...styles.container }}>
          <BreadcrumbsMUI
            routes={breadcrumbsRoutes}
            currentPage={currentPage}
            homeIsLanding={homeIsLanding}
            color={white.main}
          />
          <Box sx={styles.contentWrapper}>
            <Box>
              <Typography variant='h5' sx={styles.title}>
                {title}
              </Typography>
              {subTitle && (
                <Box sx={{ mt: 1 }}>
                  <Typography variant={isMobile ? 'body3' : 'subtitle1'} sx={styles.subTitle}>
                    {subTitle}
                  </Typography>
                </Box>
              )}
            </Box>
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
