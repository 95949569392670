import React from 'react';

export const RatingCup = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='black' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path
          d='M13.9997 1.9987H11.9997V1.33203H3.99967V1.9987H1.99967C1.59967 1.9987 1.33301 2.26536 1.33301 2.66536V4.26536C1.33301 5.7987 2.46634 7.06536 3.99967 7.26536V7.33203C3.99967 9.26536 5.33301 10.8654 7.13301 11.2654L6.66634 12.6654H5.13301C4.86634 12.6654 4.59967 12.8654 4.53301 13.132L3.99967 14.6654H11.9997L11.4663 13.132C11.3997 12.8654 11.133 12.6654 10.8663 12.6654H9.33301L8.86634 11.2654C10.6663 10.8654 11.9997 9.26536 11.9997 7.33203V7.26536C13.533 7.06536 14.6663 5.7987 14.6663 4.26536V2.66536C14.6663 2.26536 14.3997 1.9987 13.9997 1.9987ZM3.99967 5.93203C3.26634 5.73203 2.66634 5.06536 2.66634 4.26536V3.33203H3.99967V5.93203ZM9.33301 7.9987L7.99967 7.26536L6.66634 7.9987L6.99967 6.66536L5.99967 5.33203H7.39967L7.99967 3.9987L8.59967 5.33203H9.99967L8.99967 6.66536L9.33301 7.9987ZM13.333 4.26536C13.333 5.06536 12.733 5.7987 11.9997 5.93203V3.33203H13.333V4.26536Z'
          fillOpacity='0.87'
        />
      </g>
    </svg>
  );
};
