import React, { useMemo } from 'react';

import EmojiEventsRoundedIcon from '@mui/icons-material/EmojiEventsRounded';
import LocalGroceryStoreRoundedIcon from '@mui/icons-material/LocalGroceryStoreRounded';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { AdditionalItem } from '@packs/components/MaterialUi/Leaderboard/CompanyItem/components/AdditionInfo/components/AdditionalItem/AdditionalItem';
import { WikiList } from '@packs/components/MaterialUi/Leaderboard/CompanyItem/components/AdditionInfo/components/WikiList/WikiList';
import useStyles from '@packs/components/MaterialUi/Leaderboard/CompanyItem/components/AdditionInfo/styles';
import { COMPANY_TYPE } from '@packs/models/company/constants';
import { useWindowSize } from '@shared/hooks/useWindowSize';

const { grey, text } = colors;

export const ProductDetails = ({ company, showCompanyType }) => {
  const { isMobile } = useWindowSize();
  const styles = useStyles();
  const description = company?.description || company?.metaDescription;
  const companyType = COMPANY_TYPE.find(item => item.value === company?.companyType);
  const recommendationScore = +company?.additionalData?.recommendation?.score;
  const status = useMemo(() => {
    if (recommendationScore < 0) {
      return 'Poor';
    }

    if (recommendationScore >= 0 && recommendationScore <= 19) {
      return 'Good';
    }

    if (recommendationScore >= 20 && recommendationScore <= 49) {
      return 'Good!';
    }

    if (recommendationScore >= 50) {
      return 'Excellent';
    }

    return null;
  }, [company?.additionalData?.recommendation?.score]);

  return (
    <Box>
      {description && (
        <Box sx={{ mb: 2 }}>
          <Typography variant='body3' sx={{ color: grey[900] }}>
            {description}
          </Typography>
        </Box>
      )}
      <Box sx={styles.additionalItems}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          {!isMobile && <LocalGroceryStoreRoundedIcon sx={{ fontSize: '18px' }} />}
          <AdditionalItem
            title='Bought here'
            value={`${company?.additionalData?.members ? `${company.additionalData.members}%` : '0.0%'}`}
            tooltip='Are you a customer of this business? Vote for it!'
          />
        </Box>
        {!!status && (
          <Box sx={{ display: 'flex', gap: 1, minWidth: { lg: '173px' } }}>
            {!isMobile && <EmojiEventsRoundedIcon sx={{ fontSize: '18px' }} />}
            <AdditionalItem
              title='Recommended score'
              value={status}
              tooltip='A Net Promoter Score (NPS) is a measure used to gauge customer loyalty, satisfaction, and
                        enthusiasm with a company. In general, a net promoter score above 0 is good, above 20 is great
                        and above 50 is amazing! It is calculated by asking a customer on a scale of 1-10 on how likely
                        they are to recommend a business!'
            />
          </Box>
        )}
        {showCompanyType && <AdditionalItem title='Source type' value={companyType?.label} />}
      </Box>
      <Box sx={{ mt: 2 }}>
        <WikiList {...{ company }} />
      </Box>
    </Box>
  );
};
