import colors from '@packs/assets/theme/base/colors';

const { dark, grey } = colors;

export default () => ({
  wrapper: {
    width: '100%',
    maxWidth: '1160px',
    mx: 'auto',
    pb: 9,
    px: 2
  },
  breadcrumbsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    pt: {
      xs: 2,
      md: 4
    },
    pb: {
      xs: 6,
      md: 8
    }
  },
  actionBar: { display: 'flex', justifyContent: 'flex-end', p: 4 },
  btnWrapper: {
    minWidth: '56px'
  },
  btn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '56px',
    height: '56px',
    borderRadius: '50%',
    backgroundColor: grey[200],
    color: dark.main,
    fontSize: '40x',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: grey[100]
    }
  }
});
