export { default as loader } from './loader';
export * from './shared';
export { default as toastr } from './toastr';

export const runFormSubmit = form => {
  form?.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
};

export const stringToNumber = (str, { minValue }) => {
  const parsedNumber = parseInt(str.replace(/[.,]/g, ''), 10);
  if (minValue !== null) {
    return parsedNumber < minValue ? minValue : parsedNumber;
  }
  return parsedNumber;
};

export const numberToString = (number, { symbolsAfterDot, withoutComma }) => {
  if (number !== 0 && !number) return '0';
  if (number === 0) return symbolsAfterDot ? '0.00' : '0';
  if (withoutComma) return number;

  const str = (symbolsAfterDot ? number / 100.0 : number).toString();

  const symbolsBeforeFirstDivider = (str.length - (symbolsAfterDot || 0)) % 3;
  let reg;
  if (symbolsBeforeFirstDivider) {
    reg = new RegExp(`(^\\d{${symbolsBeforeFirstDivider}}|(?:.)\\d{3})(?=[^.])`, 'g');
  } else {
    reg = new RegExp('(\\d{3})(?=[^.])', 'g');
  }
  return str.replace(reg, '$1,');
};
