export const MONTH_FORMAT = 'MMMM YYYY';
export const SAVE_DATE_FORMAT = 'YYYY-MM-DD';
export const VIEW_DATE_FORMAT = 'MMM DD, YYYY';
export const VIEW_LONG_DATE_FORMAT = 'MMMM DD, YYYY';
export const DATE_FORMAT = 'DD/MM/YYYY';

export const defaultShowFields = { sourceProviderCompanyId: true, anyCompanyRate: true };

// check onDetailRetrieve logic
export const defaultHideCliteria = {
  yearsDrivingType: true,
  claimRate: true,
  mobileModel: true,
  model: true,
  carModel: true,
  carTrim: true,
  trim: true,
  pricesQuestions: true,
  childrenCount: true
};

export const defaultHideBlock = {
  policySelect: true,
  lastUpdated: true
};

export const defaultFormValues = {
  addSmartReminder: true,
  triggeredAt: '',
  triggeredAtDate: {
    month: '',
    day: ''
  }
};
