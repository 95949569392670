import React from 'react';

export const Local_servicesPainters = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path d='M4 3C2.895 3 2 3.895 2 5V7C2 8.105 2.895 9 4 9H17C18.105 9 19 8.105 19 7V5C19 3.895 18.105 3 17 3H4ZM20.9844 4.98633C20.7195 4.99047 20.4671 5.09953 20.2825 5.28957C20.098 5.47962 19.9964 5.73512 20 6V11H12C10.9069 11 10 11.9069 10 13V15C9.4 15 9 15.4 9 16V20C9 20.6 9.4 21 10 21H12C12.6 21 13 20.6 13 20V16C13 15.4 12.6 15 12 15V13H20C21.0931 13 22 12.0931 22 11V6C22.0018 5.86621 21.9768 5.73341 21.9264 5.60947C21.876 5.48553 21.8012 5.37297 21.7065 5.27845C21.6118 5.18393 21.4991 5.10937 21.375 5.05919C21.251 5.00902 21.1182 4.98424 20.9844 4.98633Z' />
      </g>
    </svg>
  );
};
