import { ICategory, IRawCategory } from '@packs/interfaces/rootInterfaces';
import { getWindow } from '@shared/lib/utils';

import sortBy from 'lodash-es/sortBy';

const listToTree = (data: IRawCategory[]) => {
  const list = data as ICategory[];
  const map: Record<number, number> = {};
  let node: ICategory;
  const roots: ICategory[] = [];
  let i: number;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i; // initialize the map
    list[i].children = []; // initialize the children
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parentId) {
      // if you have dangling branches check that map[node.parentId] exists
      list[map[node.parentId]].children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
};

export const buildSyncTreeCategories = (categories: IRawCategory[]) => {
  const rootCategories = listToTree(categories);

  rootCategories.forEach(root => {
    const leafChildren: ICategory[] = [];
    const getLeft = (children: ICategory[]) => {
      children.forEach(cat => {
        if (!cat.children?.length) {
          leafChildren.push(cat);
        } else {
          getLeft(cat.children);
        }
      });
    };
    getLeft(root.children);

    root.leafChildren = leafChildren;
    root.leafChildren.forEach(leaf => {
      leaf.root = { ...root, leafChildren: [], children: [] };
    });

    root.leafChildren = sortBy(root.leafChildren, 'name');
    if (root.code === 'insurance') {
      const leafSortingOrder = ['car_insurance'];
      root.leafChildren = root.leafChildren.sort((a, b) => {
        return leafSortingOrder.indexOf(b.code) - leafSortingOrder.indexOf(a.code);
      });
    }
  });

  return rootCategories;
};

export const categoryFromLocation = (categories: ICategory[]) => {
  if (!location.pathname.match(/categories/)) {
    return null;
  }
  const [, , , rootCode, codeSlag] = location.pathname.split('/');
  const root = categories.find(c => c.code === rootCode);
  if (!root) {
    return null;
  }
  return root.leafChildren.find(c => c.slag === codeSlag);
};

const sortingArr = [
  'insurance',
  'broadband',
  'mobile',
  'energy',
  'subscriptions',
  'banking',
  'waste_collection',
  'general',
  'business',
  'government',
  'travel',
  'food',
  'local_services'
];

export const getSortedCategories = (categories: ICategory[]) =>
  categories.sort(function (a, b) {
    return sortingArr.indexOf(a.enName) - sortingArr.indexOf(b.enName);
  });

const LOCAL_STORE_CATEGORY_CRITERIA = 'categoryCriteria';

export const setCategoryCriteria = (categoryId, criteriaAnswers) => {
  const categoryCriteriaStore = getWindow().localStorage.getItem(LOCAL_STORE_CATEGORY_CRITERIA);
  const categoryCriteria = categoryCriteriaStore ? JSON.parse(categoryCriteriaStore) : {};

  categoryCriteria[categoryId] = criteriaAnswers;

  getWindow().localStorage.setItem(LOCAL_STORE_CATEGORY_CRITERIA, JSON.stringify(categoryCriteria));
};

export const clearAllCategoryCriteria = () => {
  getWindow().localStorage.setItem(LOCAL_STORE_CATEGORY_CRITERIA, JSON.stringify({}));
};

export const getCategoryCriteria = categoryId => {
  const categoryCriteriaStore = getWindow().localStorage.getItem(LOCAL_STORE_CATEGORY_CRITERIA);
  const categoryCriteria = categoryCriteriaStore ? JSON.parse(categoryCriteriaStore) : {};

  return categoryCriteria[categoryId];
};
