import React from 'react';

import { RadioButtonField } from '@packs/components';
import { PriceField } from '@packs/components/PriceField';
import { countriesCoinsCurrency, rateCostList } from '@packs/lib/constants';
import { useCategories } from '@packs/models/category/useCategories';
import { useStore } from '@packs/stores';

import { getPeriodsList } from './constants/constants';

import startCase from 'lodash-es/startCase';
import { observer } from 'mobx-react';
import { Field } from 'redux-form';

export const EnergyAdvancedFields = observer(({ gas, electricityAndGas }) => {
  const { currentCountry } = useStore();
  const { currentCategory } = useCategories();

  const coinCurrency =
    countriesCoinsCurrency.find(item => item.code === currentCountry?.isoA2Code)?.coin ||
    countriesCoinsCurrency[0]?.coin;
  const coinCurrencyName =
    countriesCoinsCurrency.find(item => item.code === currentCountry?.isoA2Code)?.name ||
    countriesCoinsCurrency[0]?.name;

  const electricity = currentCategory?.code === 'electricity';

  return (
    <>
      {(gas || electricityAndGas) && (
        <div>
          <div className='label contract-length-label d-flex align-items-center'>
            <div className='d-flex flex-column pt-16 pb-20'>
              <div className='fs-12 secondary-text mb-8'>
                {electricityAndGas && <span className='type-name'>GAS </span>}
                Unit rate cost per (KWh) :
              </div>
              <Field name='advancedPrice.gas.includingTaxes' options={rateCostList} component={RadioButtonField} />
            </div>
          </div>
          <div className='form-row-list gap-16'>
            <div className='input-field'>
              <div className='label'>Cost per day ({startCase(coinCurrencyName)})</div>
              <Field
                name='advancedPrice.gas.dayPrice'
                placeholder='Price...'
                currencySymbol={coinCurrency}
                component={PriceField}
                maxValue={4}
                symbolsAfterDot={2}
                className='circle light-border'
                withDot
              />
            </div>
            <div className='input-field'>
              <div className='label'>Cost per night ({startCase(coinCurrencyName)})</div>
              <Field
                name='advancedPrice.gas.nightPrice'
                placeholder='Price...'
                currencySymbol={coinCurrency}
                component={PriceField}
                maxValue={4}
                symbolsAfterDot={2}
                className='circle light-border'
                withDot
              />
            </div>
            <div className='input-field'>
              <div className='label'>Standing charge ({startCase(coinCurrencyName)})</div>
              <Field
                name='advancedPrice.gas.standingPrice'
                placeholder='Price...'
                currencySymbol={coinCurrency}
                component={PriceField}
                maxValue={4}
                symbolsAfterDot={2}
                className='circle light-border'
                withDot
              />
            </div>
          </div>
        </div>
      )}
      {(electricity || electricityAndGas) && (
        <div>
          <div className='label contract-length-label d-flex align-items-center'>
            <div className='d-flex flex-column pt-16 pb-20'>
              <div className='fs-12 secondary-text mb-8'>
                {electricityAndGas && <span className='type-name'>Electricity </span>}
                Unit rate cost per (KWh) :
              </div>
              <Field
                name='advancedPrice.electricity.includingTaxes'
                options={rateCostList}
                component={RadioButtonField}
              />
            </div>
          </div>
          <div className='form-row-list gap-16'>
            <div className='input-field'>
              <div className='label'>Cost per day ({startCase(coinCurrencyName)})</div>
              <Field
                name='advancedPrice.electricity.dayPrice'
                placeholder='Price...'
                currencySymbol={coinCurrency}
                component={PriceField}
                maxValue={4}
                symbolsAfterDot={2}
                className='circle light-border'
                withDot
              />
            </div>
            <div className='input-field'>
              <div className='label'>Cost per night ({startCase(coinCurrencyName)})</div>
              <Field
                name='advancedPrice.electricity.nightPrice'
                placeholder='Price...'
                currencySymbol={coinCurrency}
                component={PriceField}
                maxValue={4}
                symbolsAfterDot={2}
                className='circle light-border'
                withDot
              />
            </div>
            <div className='input-field'>
              <div className='label'>Standing charge ({startCase(coinCurrencyName)})</div>
              <Field
                name='advancedPrice.electricity.standingPrice'
                placeholder='Price...'
                currencySymbol={coinCurrency}
                component={PriceField}
                maxValue={4}
                symbolsAfterDot={2}
                className='circle light-border'
                withDot
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
});
