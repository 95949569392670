import { toJS } from 'mobx';
import _, { get, set } from 'lodash-es';
import moment from 'moment';

import { renewalDetailApi } from '@packs/apis';
import { numberToString, toastr } from '@packs/lib/helpers';
import { CATEGORY_PER_MONTH } from '@packs/models/category/constants';
import { DYNAMIC_OPTIONS_TYPES } from '@packs/models/policy/constants';
import { nodes } from '@packs/screens/PersonalizeResults/DetailsFormProvider';

import { isCurrentMonth, selectOptions as dateSelectOptions } from './dateTimeFunctions';
import { MONTH_FORMAT, SAVE_DATE_FORMAT } from './defaults';

export const fetchDynamicFormOptions = opts => {
  return renewalDetailApi.fetchRenewalDetailDynamicFormOptions(opts).then(data => {
    if (data.errors) {
      return [];
    }
    return data;
  });
};

export const handleOnChangeLogic = (name, value, { localData, onChangeDetailsOptions, componentOptions }) => {
  const { props, showFields, setShowFields, setHideCliteria, rootMobxStore, setStep, step } = localData;
  const { detailsFormStore, policiesListStore } = rootMobxStore;
  const { mixOptions } = policiesListStore;

  const hideCliteria = _.clone(localData.hideCliteria);
  const node = nodes[name];
  const values = toJS(detailsFormStore.formValues, { recurseEverything: true });

  const setOptions = (localName, newOptions) => {
    const formOpts = toJS(detailsFormStore.formOptions, { recurseEverything: true });
    set(formOpts, localName, newOptions);
    detailsFormStore.setFormOptions(formOpts);
    if (nodes[localName]) nodes[localName].options = newOptions;
  };

  const perMonth = CATEGORY_PER_MONTH;
  let defaultPeriod = 'per_year';

  if (perMonth.includes(props.category?.code) || perMonth.includes(props.category?.root?.code)) {
    defaultPeriod = 'per_month';
  }

  if (!(name === 'criteriaQuestions.price.value' || name === 'criteriaQuestions.price.period')) {
    detailsFormStore.setChanged({ name, value });
  }

  if (name === 'dontRememberProvider') {
    onChange_dontRememberProvider(value, localData);
  }

  if (value !== undefined) {
    const fe = { ...detailsFormStore.formErrors };
    delete fe[name];
    detailsFormStore.setFormErrors(fe);
  }

  if (name === 'policySelect') {
    onChangeDetailsOptions(value);
  }

  if (name === 'buyingType') {
    if (value === 'provider') {
      showFields.sourceProviderCompanyId = true;
    }
    ['provider', 'broker', 'comparison_site', 'other'].forEach(name => (showFields[name] = false));
    showFields[value] = true;
    setShowFields(showFields);

    hideCliteria.claimWithBussiness = value === 'comparison_site';
    setHideCliteria({ ...hideCliteria });

    if (['broker', 'comparison_site', 'other'].includes(value)) {
      _.set(values, 'sourceProviderCompanyId', null);
    }
    _.set(values, 'anyCompanyId', null);
    detailsFormStore.setFormValues(values);
  }

  if (name === 'criteriaQuestions.havingDriverOnPolicy') {
    hideCliteria.yearsDrivingType = !value;
    setHideCliteria({ ...hideCliteria });
  }

  if (name === 'criteriaQuestions.claimWithBussiness') {
    hideCliteria.claimRate = !value;
    setHideCliteria({ ...hideCliteria });
  }

  if (name === 'criteriaQuestions.typeOfCoverRequired') {
    hideCliteria.childrenCount = value !== 'family';

    setHideCliteria({ ...hideCliteria });
  }

  if (name.includes('advancedPrice.') && ['standingPrice', 'dayPrice', 'nightPrice'].some(str => name.includes(str))) {
    if (value.length <= 5) {
      _.set(values, name, numberToString(value, { symbolsAfterDot: 2 }));
      detailsFormStore.setFormValues(values);
    }
  }

  if (name === 'criteriaQuestions.isCurrentCost') {
    _.set(values, 'criteriaQuestions.price.period', 'per_month');
    detailsFormStore.setFormValues(values);

    hideCliteria.price = !value;
    setHideCliteria({ ...hideCliteria });
  }

  if (name === 'sourceProviderCompanyId') {
    if (value === 'i_cant_remember') return;
    const company = _.find(mixOptions, { id: value });
    if (company) {
      if (company.companyType === 'provider') {
        _.set(values, 'sourceProviderCompanyId', company.id);
      } else {
        if (values.buyingType !== 'provider') {
          toastr.warning('you can’t select source -  because source was selected before');
          _.set(values, 'sourceProviderCompanyId', null);
        } else {
          _.set(values, 'anyCompanyId', company.id);
          _.set(values, 'sourceProviderCompanyId', null);
        }
      }
      // reset always when change
      _.set(values, 'anyCompanyRate', null);

      if (values.buyingType === 'provider') {
        _.set(values, 'buyingType', company.companyType);
      }

      detailsFormStore.setFormValues(values);
    }
    detailsFormStore.fetchPreviewReviews(value, 'sourceProviderCompany');
  }

  if (name === 'anyCompanyId') {
    if (value === 'i_cant_remember') {
      setStep(step + 1);
      return;
    }
    const company = mixOptions.find(item => item.id === value);

    if (company) {
      _.set(values, 'buyingType', company.companyType);
      _.set(values, 'anyCompanyId', value);

      if (company.companyType === 'provider') {
        _.set(values, 'sourceProviderCompanyId', null);
      }

      const savedCompany = localData?.props?.renewalDetail?.anyCompany;
      const savedCompanyRate = localData?.props?.renewalDetail?.anyCompanyRate;

      if (savedCompany?.id === value) {
        _.set(values, 'anyCompanyRate', savedCompanyRate);
        setShowFields({ ...showFields, anyCompanyRate: false });
      } else {
        _.set(values, 'anyCompanyRate', null);
        setShowFields({ ...showFields, anyCompanyRate: true });
      }
      detailsFormStore.setFormValues(values);
    }

    detailsFormStore.fetchPreviewReviews(value, 'anyCompany');
  }

  if (name === 'addSmartReminder') {
    onChange_addSmartReminder(!value);
  }

  if (name === 'triggeredAtDate.month') {
    onChange_triggeredAtDate(value, localData, 'month');
  }

  if (name === 'triggeredAtDate.day') {
    onChange_triggeredAtDate(value, localData, 'day');
  }

  const loadDynamicFormOptions = (loadKey, makeVal, modelVal = undefined) => {
    hideCliteria[loadKey] = false;
    setHideCliteria({ ...hideCliteria });
    const loadName = `criteriaQuestions.${loadKey}`;
    // reset preview values
    set(values, loadName, null);
    if (loadKey === 'bikeModel') {
      set(values, 'criteriaQuestions.bikeTrim', null);
    } else if (loadKey === 'catModel') {
      set(values, 'criteriaQuestions.carTrim', null);
    } else if (loadKey === 'model') {
      set(values, 'criteriaQuestions.trim', null);
    }
    detailsFormStore.setFormValues(values);

    // clear options
    setOptions(loadName, []);

    // show loading indicator
    const opLoadings = toJS(detailsFormStore.loadingsComponentOptions, { recurseEverything: true });
    set(opLoadings, loadName, true);
    detailsFormStore.setLoadingsComponentOptions({ ...opLoadings });

    fetchDynamicFormOptions({
      type: DYNAMIC_OPTIONS_TYPES[loadKey],
      make: makeVal,
      model: modelVal
    }).then(options => {
      set(opLoadings, loadName, false);
      detailsFormStore.setLoadingsComponentOptions({ ...opLoadings });

      let optionsFormatted = options;
      if (loadKey === 'bikeModel') {
        optionsFormatted = [{ value: 'not_remember', label: "I don't remember." }, ...options];
      }
      // set Options
      setOptions(loadName, optionsFormatted);

      if (componentOptions.isSliderSteps) {
        const showSkipOp = toJS(detailsFormStore.showSkipButton);
        if (options.length === 0) {
          set(showSkipOp, loadName, true);
        } else if (get(showSkipOp, loadName)) {
          set(showSkipOp, loadName, false);
        }
        if (options.length === 0 || [true, false].includes(get(showSkipOp, loadName))) {
          detailsFormStore.setShowSkipButton(showSkipOp);
        }
      } else {
        hideCliteria[loadKey] = options.length === 0;
        setHideCliteria({ ...hideCliteria });
      }
    });
  };

  if (name === 'criteriaQuestions.bikeMake' && value) {
    loadDynamicFormOptions('bikeModel', value);
  }
  if (name === 'criteriaQuestions.bikeModel' && value) {
    loadDynamicFormOptions('bikeTrim', get(values, 'criteriaQuestions.bikeMake'), value);
  }
  // props.fetchRenewalDetailCriteriasQuestionsCarModelsOptionsDispatch
  if (name === 'criteriaQuestions.carMake' && value) {
    loadDynamicFormOptions('carModel', value);
  }
  if (name === 'criteriaQuestions.carModel' && value) {
    loadDynamicFormOptions('carTrim', get(values, 'criteriaQuestions.carMake'), value);
  }
  // props.fetchRenewalDetailCriteriasQuestionsCarModelsOptionsDispatch
  if (name === 'criteriaQuestions.make' && value) {
    loadDynamicFormOptions('model', value);
  }
  // props.fetchRenewalDetailCriteriasQuestionsCarTrimOptionsDispatch
  if (name === 'criteriaQuestions.model' && value) {
    loadDynamicFormOptions('trim', get(values, 'criteriaQuestions.make'), value);
  }

  if (name === 'criteriaQuestions.mobileMake' && value) {
    if (value === 'not_remember') {
      hideCliteria.mobileModel = true;
      setHideCliteria({ ...hideCliteria });
    } else {
      loadDynamicFormOptions('mobileModel', value);
    }
  }

  if (name === 'pricesQuestions.price.value') {
    if (_.isNull(values.pricesQuestions.price.period) || _.isUndefined(values.pricesQuestions.price.period)) {
      _.set(values, 'pricesQuestions.price.period', defaultPeriod);
      detailsFormStore.setFormValues(values);
    }
  }
};

const onChange_addSmartReminder = value => {
  // if value true - means that user don’t wish create reminder
  nodes['triggeredAtDate.month'].disabled = value;
  nodes['triggeredAtDate.day'].disabled = value;
};

const onChange_triggeredAtDate = (value, { rootMobxStore }, type) => {
  const { detailsFormStore } = rootMobxStore;
  const values = toJS(detailsFormStore.formValues, { recurseEverything: true });
  const formOptions = toJS(detailsFormStore.formOptions, { recurseEverything: true });

  const monthMoment =
    type === 'month'
      ? moment(value, MONTH_FORMAT)
      : values.triggeredAtDate?.month
      ? moment(values.triggeredAtDate.month, MONTH_FORMAT)
      : moment();

  const dayValue = type === 'month' ? (isCurrentMonth(monthMoment) ? `${moment().date() + 1}` : '1') : value;

  if (type === 'month') {
    const options = dateSelectOptions(value);
    _.set(formOptions, 'triggeredAtDate.day', options.day);
    nodes['triggeredAtDate.day'].options = options.day;
    detailsFormStore.setFormOptions(formOptions);

    nodes['triggeredAtDate.day'].value = dayValue;
    _.set(values, 'triggeredAtDate.day', dayValue);
  }

  _.set(values, 'triggeredAt', monthMoment.add(+dayValue - 1, 'day').format(SAVE_DATE_FORMAT));
  detailsFormStore.setFormValues(values);
};

const onChange_dontRememberProvider = (value, localData) => {
  const { disabledFields, setDisabledFields, setHideCliteria, rootMobxStore } = localData;
  const { detailsFormStore } = rootMobxStore;
  const hideCliteria = _.cloneDeep(detailsFormStore.hideCliteria);

  if (value === true) {
    disabledFields.sourceProviderCompanyId = true;
    hideCliteria.claimWithBussiness = true;
  } else {
    hideCliteria.claimWithBussiness = false;
    disabledFields.sourceProviderCompanyId = false;
  }

  setHideCliteria({ ...hideCliteria });

  setDisabledFields(disabledFields);
};
