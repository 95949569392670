import React from 'react';
import cx from 'classnames';
import moment from 'moment';

import Facebook from '@packs/images/icons/Facebook';
import LinkedIn from '@packs/images/icons/LinkedIn';
import { Twitter } from '@packs/images/icons/Twitter';
import WhatsApp from '@packs/images/icons/WhatsApp';
// Images
import CloseDark from '@packs/images/svg/close-dark.svg';
import ThankYouModalIcon from '@packs/images/svg/ui/ThankYouModalIcon.svg';
import { usePath } from '@packs/layouts/constants';
import { BASE_APP_URL } from '@packs/lib/constants';
import { useCategories } from '@packs/models/category/useCategories';
import { DATE_FORMAT } from '@packs/screens/PersonalizeResults/formHooks/defaults';

import { MacScrollbar } from 'mac-scrollbar';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';

export const ThankYouState = observer(({ toggle, thankYouShowBenefits, submitModalData }) => {
  const { currentCategory } = useCategories();
  const { PROFILE_MY_REMINDERS_PATH, LEADERBOARD_PATH } = usePath();

  const triggeredAt = submitModalData?.renewalDetail?.smartReminder?.triggeredAt;
  const renewalDetail = submitModalData?.renewalDetail;
  const companyAvatar = renewalDetail?.anyCompany?.avatarUrl || null;
  const companyName = renewalDetail?.anyCompany?.name || null;
  const sourceProviderAvatar = renewalDetail?.sourceProviderCompany?.avatarUrl || null;
  const sourceProviderName = renewalDetail?.sourceProviderCompany?.name || null;
  const inviteLinkUrl = BASE_APP_URL + LEADERBOARD_PATH;
  const inviteMessage = `I just voted for ${companyName || 'Company'} for my '${
    currentCategory?.name || ''
  }' on Bill Winner. Join the world's first crowd sourced app for renewals. Worth to see it here: ${inviteLinkUrl}.`;
  const winnerTitle = companyName ? 'Your winners' : 'Your winner';

  return (
    <MacScrollbar
      style={{ height: '100%' }}
      trackStyle={horizontal => ({
        [horizontal ? 'height' : 'width']: 0,
        right: 2,
        border: 0
      })}
      thumbStyle={horizontal => ({ [horizontal ? 'height' : 'width']: 4 })}
      suppressAutoHide
    >
      <div className='modal-content-inner'>
        <div className='close-btn' onClick={toggle}>
          <img src={CloseDark} alt='Close' />
        </div>
        <div className='img-container'>
          <img src={ThankYouModalIcon} alt='Vote' />
        </div>
        <div className='title'>Thank you for voting!</div>
        <p>Your vote helps us crowdsource the best businesses</p>

        <div className='company d-flex flex-column align-items-center'>
          <div className='title'>{winnerTitle}</div>
          <div className='category-name'>{`${currentCategory?.name} (${moment().year()})`}</div>
          <div className='companies-list d-flex align-items-center'>
            {companyName && (
              <div className='company-box'>
                <div className='avatar winner-circle'>
                  <img src={companyAvatar} alt='Company' />
                </div>
                <div className='company-name'>{companyName}</div>
              </div>
            )}
            {sourceProviderName && (
              <div className='company-box'>
                <div className='avatar winner-circle'>
                  <img src={sourceProviderAvatar} alt='Company' />
                </div>
                <div className='company-name'>{sourceProviderName}</div>
              </div>
            )}
          </div>
        </div>

        {triggeredAt && (
          <>
            <div className='title'>Your details have been updated!</div>
            <p>Your next renewal date is :</p>
            <div className='status-wrapper d-flex align-items-center'>
              <div className={cx('reminder-status', renewalDetail?.smartReminder?.status)} />
              <Link className='my-data' to={PROFILE_MY_REMINDERS_PATH}>
                {moment(triggeredAt).format(DATE_FORMAT)}
              </Link>
            </div>
          </>
        )}

        <div className='title'>Share your vote on Social Media!</div>

        <div className='leaderboard-social-list'>
          <FacebookShareButton url={BASE_APP_URL} quote={inviteMessage} className='social-link facebook'>
            <span className='icon'>
              <Facebook />
            </span>
          </FacebookShareButton>
          <TwitterShareButton url={BASE_APP_URL} title={inviteMessage} className='social-link twitter'>
            <span className='icon'>
              <Twitter />
            </span>
          </TwitterShareButton>
          <LinkedinShareButton url={BASE_APP_URL} title={inviteMessage} className='social-link linkedin'>
            <span className='icon'>
              <LinkedIn />
            </span>
          </LinkedinShareButton>
          <WhatsappShareButton url={BASE_APP_URL} title={inviteMessage} className='social-link whatsApp'>
            <span className='icon'>
              <WhatsApp />
            </span>
          </WhatsappShareButton>
        </div>
      </div>
    </MacScrollbar>
  );
});
