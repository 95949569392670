import React from 'react';

export const BusinessBusinessGas = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path d='M20 14.9379C19.978 12.2639 18.56 9.6499 17.522 7.7379C17.292 7.3129 17.076 6.9169 16.895 6.5529L16.209 5.1819L15.23 6.3619C15.082 6.5419 14.944 6.7579 14.819 6.9929C13.588 4.5769 12.957 2.7079 12.949 2.6839L12.436 1.1499L11.293 2.2929C10.995 2.5909 4 9.6469 4 14.9999C4 19.4109 7.589 22.9999 12 22.9999C16.411 22.9999 20 19.4109 20 14.9999V14.9969C20 14.9809 20 14.9669 20 14.9509L20.006 14.9429L20 14.9379ZM9 17.9999C9 14.9999 12 11.9999 12 11.9999C12.574 15.0589 15.129 15.9539 15 17.9999C14.896 19.6539 13.657 20.9999 12 20.9999C10.343 20.9999 9 19.6569 9 17.9999Z' />
      </g>
    </svg>
  );
};
