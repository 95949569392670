import colors from '@packs/assets/theme/base/colors';

const { dark } = colors;

export default () => ({
  link: {
    display: 'block',
    width: '100%',
    color: dark.main,
    p: 1
  }
});
