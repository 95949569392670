import _ from 'lodash-es';

import { addDays } from '@fullcalendar/core';

import moment from 'moment/moment';

export const getYearData = (selectedYear, dates) => {
  const yearData = [];

  if (selectedYear === new Date().getYear()) {
    const firstMonthDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const indx = _.findIndex(dates, el => el.toDateString() === firstMonthDay.toDateString());
    dates = dates.slice(indx, dates.length);

    const day = dates[dates.length - 1];
    const startOfYear = moment().startOf('year');
    const differenceDays = Math.abs(-startOfYear.diff(moment(), 'days'));
    const newDates = _.times(differenceDays, i => addDays(day, i + 1));
    dates = [...dates, ...newDates];
  }

  let index = -1;
  _.times(12, tableGridIndex => {
    index = _.findIndex(dates, (date, inx) => inx > index && date.getDate() === 1);
    const date = dates[index];

    let sliceDates = [];
    const case1 = date.getDay() === 0 && tableGridIndex !== 11;
    const case2 = tableGridIndex === 0;
    const case3 = tableGridIndex === 11;

    if (case1) {
      sliceDates = dates.slice(index, index + 42);
    } else if (case2) {
      sliceDates = dates.slice(index, index - date.getDay() + 42);
      const today = sliceDates[0];
      const prevDates = _.times(date.getDay(), i => addDays(today, -(i + 1))).reverse();
      sliceDates = [...prevDates, ...sliceDates];
    } else if (case3) {
      sliceDates = dates.slice(index, index + 42);
      const today = sliceDates[sliceDates.length - 1];
      const nextDates = _.times(42 - sliceDates.length, i => addDays(today, i + 1));
      sliceDates = [...sliceDates, ...nextDates];
    } else {
      sliceDates = dates.slice(index - date.getDay(), index - date.getDay() + 42);
    }

    const monthIndex = date.getMonth();

    const mapSliceDates = _.map(sliceDates, el => ({
      date: el,
      dateStr: el.toDateString(),
      isThatMonth: el && el.getMonth() === monthIndex,
      isToday: el.toDateString() === new Date().toDateString()
    }));

    const cells = mapSliceDates.eachSlice(7);

    yearData.push({
      monthIndex,
      monthData: {
        dateStr: date.toDateString(),
        date,
        index,
        cells
      }
    });
  });

  return yearData;
};
