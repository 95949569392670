import React from 'react';

import { useStore } from '@packs/stores';
import { toCamelCase } from '@shared/helpers/additionalHelpers';
import { getWindow } from '@shared/lib/utils';

import TwitterLogin from 'react-twitter-auth';

export const CustomSocialButton = ({
  provider,
  appId,
  appSecret,
  onLoginSuccess,
  onLoginFailure,
  redirect,
  children,
  ...props
}) => {
  const { countryCode } = useStore();

  const onSuccess = async respond => {
    const body = await respond.json();
    const userData = toCamelCase(body.data || {});

    if (provider === 'twitter') {
      const [firstName, lastName] = userData.profile?.name?.split(' ') || [];

      onLoginSuccess({
        profile: {
          id: userData.profile?.id,
          email: userData.profile?.email,
          name: userData.profile?.name,
          firstName: firstName || '',
          lastName: lastName || '',
          gender: null,
          profilePicURL: userData.profileImageUrlHttps
        },
        token: {
          accessToken: userData.token?.oauthToken,
          secret: userData.token?.oauthTokenSecret,
          idToken: userData.token?.userId,
          expiresIn: new Date().getTime() + 1800, // 30minutes
          scope: null
        },
        provider
      });
      // createdAt: "Fri Jul 30 07:45:37 +0000 2021"
      // favouritesCount: 0
      // followersCount: 0
      // friendsCount: 0
      // lang: null
      // listedCount: 0
      // location: ""
      // name: "mykola"
      // screenName: "mykola16503406"
      // profileImageUrl: "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png"
      // profileImageUrlHttps: "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png"
    } else if (provider === 'linkedin') {
      const firstName = userData.profile?.localizedFirstName || '';
      const lastName = userData.profile?.localizedLastName || '';

      onLoginSuccess({
        profile: {
          id: userData.profile?.id,
          email: userData.profile?.email,
          name: `${firstName} ${lastName}`,
          firstName,
          lastName,
          gender: null,
          profilePicURL: ''
        },
        token: {
          accessToken: userData.token?.accessToken,
          secret: userData.token?.oauthTokenSecret,
          idToken: null,
          expiresIn: userData.token?.expiresAt, // 30minutes
          scope: null
        },
        provider
      });
      // {
      //   "profile": {
      //   "localizedLastName": "Horak",
      //   "profilePicture": {
      //     "displayImage": "urn:li:digitalmediaAsset:C5603AQElBcFHcsjMrw"
      //   },
      //   "id": "qJyy47ZM5C",
      //   "localizedFirstName": "Andriy"
      // },
      //   "token": {
      //   "accessToken": "AQVofm2WwNaRiDN3TyyYsaYAfjLp5KDRTDFhX3CT1_fMWyfUlvr27j4xSbVTL2N72EU_c194ENg8Y7CLqJy5iaZmSwO7TkRwYHmroGG7SZ2R6xvdI2XioGGZr3RQV3bEVrP4inAwoJmSYvOYd63B_frDd4Vf2VTNB0J3IbwxDQ6nWgtF2T1cQ3mt6-6unDpm1B-5fpXBkw7d9KoKaQthTlEut3gcT4JMNsiYG9LZHjY6tQyNo2AN_-uexsg5PuHZQY43jO_D4RlkgBI9udVXTDZOAFDYKThgV56VhAkOVkxuAp5QbmsZ98Hv9ZfYvE4lkriCb74YFqzl31ehNdGWbWnBXcWn8g",
      //   "refreshToken": null,
      //   "expiresAt": 1633378922
      // }
      // }
    }
  };

  const onFailure = err => {
    onLoginFailure(err);
  };

  const getOauthToken = async code => {
    const loginUrl = `${location.origin}/${countryCode}/auth/linkedin/login`;
    const respond = await fetch(`${loginUrl}?code=${code}`);
    onSuccess(respond);
  };

  const triggerLogin = () => {
    // fetch([
    //   'https://www.linkedin.com/oauth/v2/authorization?',
    //   'response_type=code&',
    //   `client_id=${appId}&`,
    //   'state=*&',
    //   'scope=*&',
    //   `redirect_uri=${redirect}`
    // ].join(''), {
    //   method: 'GET',
    //   mode: 'no-cors'
    // })
    //   .then(s => {

    //   }).catch(e => {

    //   })
    const oauthUrl = [
      'https://www.linkedin.com/oauth/v2/authorization?',
      'response_type=code&',
      `client_id=${appId}&`,
      'state=123456&',
      'scope=r_liteprofile r_emailaddress w_member_social&',
      `redirect_uri=${redirect}`
    ].join('');
    const width = 450;
    const height = 730;
    const left = getWindow().screen.width / 2 - width / 2;
    const top = getWindow().screen.height / 2 - height / 2;

    const popup = getWindow().open(
      oauthUrl,
      'Linkedin',
      'menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=' +
        width +
        ', height=' +
        height +
        ', top=' +
        top +
        ', left=' +
        left
    );

    const polling = setInterval(function () {
      if (!popup || popup.closed || popup.closed === undefined) {
        clearInterval(polling);
        onFailure(new Error('Popup has been closed by user'));
      }

      const closeDialog = () => {
        clearInterval(polling);
        popup.close();
      };

      try {
        if (!popup.location.hostname.includes('linkedin.com/oauth') && !popup.location.hostname === '') {
          if (popup.location.search) {
            const query = new URLSearchParams(popup.location.search);
            const code = query.get('code');
            closeDialog();
            getOauthToken(code);
          } else {
            closeDialog();
            return onFailure(
              new Error(
                'OAuth redirect has occurred but no query or hash parameters were found. ' +
                  'They were either not set during the redirect, or were removed—typically by a ' +
                  'routing library—before Twitter react component could read it.'
              )
            );
          }
        }
      } catch (error) {
        // Ignore DOMException: Blocked a frame with origin from accessing a cross-origin frame.
        // A hack to get around same-origin security policy errors in IE.
      }
    }, 500);
  };

  return provider === 'twitter' ? (
    <TwitterLogin
      className={props.className}
      loginUrl={`${location.origin}/${countryCode}/auth/twitter/login`}
      requestTokenUrl={`${location.origin}/${countryCode}/auth/twitter/request_token`}
      showIcon={false}
      onFailure={onFailure}
      onSuccess={onSuccess}
    >
      {children}
    </TwitterLogin>
  ) : (
    <button onClick={triggerLogin} {...props}>
      {children}
    </button>
  );
};
