import React from 'react';

export const SubscriptionsIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19 8H5C4.45 8 4 7.55 4 7C4 6.45 4.45 6 5 6H19C19.55 6 20 6.45 20 7C20 7.55 19.55 8 19 8ZM17 2H7C6.45 2 6 2.45 6 3C6 3.55 6.45 4 7 4H17C17.55 4 18 3.55 18 3C18 2.45 17.55 2 17 2ZM22 12V20C22 21.1 21.1 22 20 22H4C2.9 22 2 21.1 2 20V12C2 10.9 2.9 10 4 10H20C21.1 10 22 10.9 22 12ZM15.19 15.56L10 12.73V19.26L15.19 16.44C15.54 16.25 15.54 15.75 15.19 15.56Z'
        fill='white'
      />
    </svg>
  );
};
