import React from 'react';

export default () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='23' height='23' viewBox='0 0 23 23'>
      <path
        fill=''
        fillRule='nonzero'
        d='M11.472.14l3.544 7.46 7.927 1.196-5.736 5.806 1.354 8.199-7.09-3.871-7.09 3.87 1.355-8.198L0 8.796 7.927 7.6z'
      />
    </svg>
  );
};
