import React from 'react';

export const Local_servicesHairSalons = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path d='M6 3C3.243 3 1 5.243 1 8C1 9.33 1.51744 10.5828 2.52344 11.5898L3.30664 12.2422L4 19.1895C4.093 20.2145 4.95337 21 5.98438 21C7.15538 21 8.07475 19.9941 7.96875 18.8281L7.69141 16H8C8.13251 16.0019 8.26407 15.9774 8.38704 15.928C8.51001 15.8786 8.62193 15.8052 8.7163 15.7122C8.81067 15.6191 8.88561 15.5082 8.93676 15.386C8.9879 15.2637 9.01424 15.1325 9.01424 15C9.01424 14.8675 8.9879 14.7363 8.93676 14.614C8.88561 14.4918 8.81067 14.3809 8.7163 14.2878C8.62193 14.1948 8.51001 14.1214 8.38704 14.072C8.26407 14.0226 8.13251 13.9981 8 14H7.49609L7.44336 13.459C7.39436 12.954 7.73056 12.4928 8.22656 12.3848L14 11.1348V4.83008L13.25 4.62109C10.183 3.76709 7.321 3 6 3ZM18.293 4.00391C18.1658 3.99138 18.0318 4.00966 17.9023 4.06641L16 5V11L17.9258 12C18.4408 12.206 19 11.8265 19 11.2715V4.78516C19 4.36141 18.6746 4.0415 18.293 4.00391ZM21 5V7H22C22.553 7 23 6.553 23 6C23 5.447 22.553 5 22 5H21ZM6 6C7.105 6 8 6.895 8 8C8 9.105 7.105 10 6 10C4.895 10 4 9.105 4 8C4 6.895 4.895 6 6 6ZM21 9V11H22C22.553 11 23 10.553 23 10C23 9.447 22.553 9 22 9H21Z' />
      </g>
    </svg>
  );
};
