import React from 'react';

import { FormControl, FormControlLabel, FormHelperText, Stack, Switch } from '@mui/material';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import PhoneInput from '@packs/components/PhoneInput/phoneInput';
import { ReminderForm } from '@packs/components/ReminderFormModal/ReminderFormModal';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { IPolicy } from '@packs/interfaces/rootInterfaces';
import { autoQuoteTooltip, autoQuoteTooltipNoPolicy } from '@packs/lib/constants';
import { hasPolicyCriteria } from '@packs/lib/helpers/additionalHelpers';

import AnimateHeight from 'react-animate-height';
import { Controller, useFormContext } from 'react-hook-form';
import { Country } from 'react-phone-number-input';

export const AutoQuotesNew = ({ currentPolicy }: { currentPolicy: IPolicy }) => {
  const { watch, control } = useFormContext<ReminderForm>();
  const { currentUser } = useUserSelectors();

  const defaultCountry = (currentUser?.country?.isoA2Code || 'ie') as Country;

  const isAutoQuote = watch('isAutoQuote');

  return (
    <Box>
      <Controller
        control={control}
        name='isAutoQuote'
        render={({ field, fieldState: { error } }) => (
          <FormControl error={!!error?.message}>
            <FormControlLabel
              control={
                <Switch
                  checked={!!field.value}
                  onChange={field.onChange}
                  disabled={!hasPolicyCriteria(currentPolicy)}
                />
              }
              label={
                <Stack direction='row' justifyContent='center' alignItems='center' spacing={1}>
                  <Tooltip
                    title={
                      <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', gap: 2 }}>
                        <Box>
                          <Typography variant='caption'>
                            {hasPolicyCriteria(currentPolicy) ? autoQuoteTooltip : autoQuoteTooltipNoPolicy}
                          </Typography>
                        </Box>
                      </Box>
                    }
                    arrow
                    placement='top'
                  >
                    <Typography>Auto. quote</Typography>
                  </Tooltip>
                </Stack>
              }
            />
            <FormHelperText>{error?.message}</FormHelperText>
          </FormControl>
        )}
      />
      <AnimateHeight duration={250} height={isAutoQuote ? 'auto' : 0}>
        <Stack>
          <Box sx={{ mt: 2, mb: 1 }}>
            <Controller
              name='phoneNumber'
              control={control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <FormControl fullWidth error={!!error?.message}>
                    <PhoneInput
                      meta={{ touched: false, error: error?.message || '' }}
                      input={{ onChange: field.onChange, value: field.value }}
                      placeholder='Phone (ex. +353 123...)'
                      countryCallingCodeEditable={false}
                      defaultCountry={defaultCountry}
                    />
                    <FormHelperText>{error?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
          </Box>
        </Stack>
      </AnimateHeight>
    </Box>
  );
};
