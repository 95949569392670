import React from 'react';

export const Local_servicesPhoneRepair = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path d='M7.5 1C6.119 1 5 2.119 5 3.5V20.5C5 21.881 6.119 23 7.5 23H16.5C17.881 23 19 21.881 19 20.5V18.5C19 18.224 18.776 18 18.5 18H7V4H18.5C18.776 4 19 3.776 19 3.5C19 2.119 17.881 1 16.5 1H7.5ZM17.2324 6C17.1034 6 16.9955 6.09661 16.9805 6.22461L16.8633 7.23633C16.3793 7.40433 15.9425 7.65928 15.5625 7.98828L14.627 7.58203C14.509 7.53103 14.3706 7.57745 14.3066 7.68945L13.3691 9.31055C13.3051 9.42255 13.3345 9.56462 13.4375 9.64062L14.2441 10.2383C14.1971 10.4863 14.168 10.74 14.168 11C14.168 11.26 14.1961 11.5128 14.2441 11.7598L13.4395 12.3594C13.3365 12.4364 13.3061 12.5785 13.3711 12.6895L14.3066 14.3105C14.3706 14.4225 14.509 14.467 14.627 14.416L15.5625 14.0117C15.9415 14.3397 16.3793 14.5957 16.8633 14.7637L16.9805 15.7754C16.9945 15.9044 17.1034 16 17.2324 16H19.1035C19.2325 16 19.3405 15.9034 19.3555 15.7754L19.4727 14.7637C19.9567 14.5957 20.3934 14.3407 20.7734 14.0117L21.7109 14.418C21.8289 14.469 21.9653 14.4215 22.0293 14.3105L22.9668 12.6895C23.0308 12.5775 23.0014 12.4354 22.8984 12.3594L22.0918 11.7598C22.1398 11.5128 22.168 11.26 22.168 11C22.168 10.74 22.1398 10.4872 22.0918 10.2402L22.8965 9.64062C22.9995 9.56363 23.0298 9.42155 22.9648 9.31055L22.0293 7.68945C21.9653 7.57745 21.827 7.53298 21.709 7.58398L20.7734 7.98828C20.3944 7.66028 19.9567 7.40433 19.4727 7.23633L19.3555 6.22461C19.3415 6.09561 19.2325 6 19.1035 6H17.2324ZM18.168 9.25C19.134 9.25 19.918 10.033 19.918 11C19.918 11.966 19.134 12.75 18.168 12.75C17.202 12.75 16.418 11.966 16.418 11C16.418 10.033 17.202 9.25 18.168 9.25ZM12 18.875C12.622 18.875 13.125 19.378 13.125 20C13.125 20.622 12.622 21.125 12 21.125C11.378 21.125 10.875 20.622 10.875 20C10.875 19.378 11.378 18.875 12 18.875Z' />
      </g>
    </svg>
  );
};
