import React, { Component } from 'react';

import SocialLogin from 'react-social-login';

export const SocialButton = SocialLogin(
  class Button extends Component {
    render = () => (
      <button onClick={this.props.triggerLogin} type='button' {...this.props}>
        {this.props.children}
      </button>
    );
  }
);
