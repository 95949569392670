import { useContext } from 'react';
import { action, computed, observable } from 'mobx';

import { AdditionalStore } from '@packs/stores/additionalStore';
import { CategoryStore } from '@packs/stores/CategoryStore';
import { InsightsCompanyStore } from '@packs/stores/InsightsCompanyStore';
import { MixedPostsStore } from '@packs/stores/MixedPostsStore';
import { ModalStore } from '@packs/stores/ModalStore';
import { NotesStore } from '@packs/stores/notesStore';
import { NotificationsStore } from '@packs/stores/NotificationsStore';
import { PoliciesListStore } from '@packs/stores/PoliciesListStore';
import { PolicyStore } from '@packs/stores/PolicyStore';
import { PreferenceStore } from '@packs/stores/PreferenceStore';
import { QuestionsStore } from '@packs/stores/QuestionsStore';
import { RequestsStore } from '@packs/stores/RequestsStore';
import { ReviewCommentStore } from '@packs/stores/ReviewCommentStore';
import { SmartRemindersStore } from '@packs/stores/SmartRemindersStore';

import { AssociationStore } from './AssociationStore';
import { AppRootStore, StoreContext } from './base';
import { CompaniesStore } from './CompaniesStore';
import { DetailsFormStore } from './DetailsFormStore';
import { HomeStore } from './HomeStore';
import { LandingStore } from './LandingStore';
import { LeaderboardStore } from './LeaderboardStore';
import { OffersStore } from './OffersStore';
import { ShoppingCartStore } from './ShoppingCartStore';
import { UserStore } from './UserStore';

export class RootStore extends AppRootStore {
  @observable
  currentCountry;
  @observable
  countryCode;
  @observable
  launchCountries = [];
  @observable
  countriesSelectOptions = [];
  @observable
  modalStore = new ModalStore(this);
  userStore = new UserStore(this);
  categoryStore = new CategoryStore(this);
  notificationsStore = new NotificationsStore(this);
  homeStore = new HomeStore(this);
  additionalStore = new AdditionalStore(this);
  notesStore = new NotesStore(this);
  reviewCommentStore = new ReviewCommentStore(this);
  detailsFormStore = new DetailsFormStore(this);
  associationStore = new AssociationStore(this);
  leaderboardStore = new LeaderboardStore(this);
  shoppingCartStore = new ShoppingCartStore(this);
  insightsCompanyStore = new InsightsCompanyStore(this);
  companiesStore: CompaniesStore = new CompaniesStore(this);
  offersStore = new OffersStore(this);
  landingStore = new LandingStore(this);
  preferenceStore = new PreferenceStore(this);
  questionsStore = new QuestionsStore(this);
  smartRemindersStore = new SmartRemindersStore(this);
  mixedPostsStore = new MixedPostsStore(this);
  requestsStore = new RequestsStore(this);
  policyStore = new PolicyStore(this);
  policiesListStore = new PoliciesListStore(this);

  @computed get currentCategory() {
    return this.categoryStore.currentCategory;
  }

  @computed get currentPolicy() {
    return this.policyStore.currentPolicy;
  }

  @action
  setLaunchCountries(countries) {
    this.launchCountries = countries;
    this.countriesSelectOptions = countries.map(c => ({ id: c.id, value: c.isoA2Code, label: c.name }));
  }

  @action
  setCurrentCountry(country) {
    this.currentCountry = country;
    this.countryCode = `en-${country.isoA2Code}`;
  }

  // for better exporting
  rootStore = this;
}

export const useStore = (): RootStore => useContext(StoreContext);

let store;
// function to initialize the store
export function initializeStore() {
  const _store = store ?? new RootStore();

  // For server side rendering always create a new store
  if (typeof window === 'undefined') return _store;

  // Create the store once in the client
  if (!store) store = _store;

  return _store;
}
