import React, { useState } from 'react';

import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { useStore } from '@packs/stores';

export const DocumentActions = ({ note, general }) => {
  const { notesStore } = useStore();
  const { toggleConfirmModalOpen, toggleNoteModalOpen } = useModalsContext();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDeleteNote = () => {
    toggleConfirmModalOpen({
      modalProps: {
        title: 'Delete doc/note',
        description: 'Are you sure you want to delete this doc/note?',
        submitBtnText: 'Delete',
        onSubmit: () => notesStore.delete({ id: note?.id })
      }
    });
    handleClose();
  };

  const onUpdateNote = () => {
    toggleNoteModalOpen({ modalProps: { note, general } });
    handleClose();
  };

  return (
    <>
      <IconButton
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={onUpdateNote}>
          <ListItemIcon>
            <EditRoundedIcon />
          </ListItemIcon>
          Update doc/note
        </MenuItem>
        <MenuItem onClick={onDeleteNote}>
          <ListItemIcon>
            <DeleteRoundedIcon />
          </ListItemIcon>
          Delete doc/note
        </MenuItem>
      </Menu>
    </>
  );
};
