import React from 'react';
import _ from 'lodash-es';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DefaultBusinessIcon from '@packs/images/svg/default_business.svg';
import { defaultBusinessImage } from '@packs/lib/constants';
import { InputField } from '@packs/screens/PersonalizeResults/formFields/InputField';
import { RatingField } from '@packs/screens/PersonalizeResults/formFields/RatingField';
import { SelectedCompany } from '@packs/screens/PersonalizeResults/stepComponents/components/SelectedCompany';
import { useStore } from '@packs/stores/base';
import { TooltipMUI } from '@shared/components/TooltipMUI/TooltipMUI';

import { observer } from 'mobx-react';

export const RatingBlock = observer(props => {
  const { namePrefix, category } = props;
  const { detailsFormStore, policiesListStore } = useStore();
  const { mixOptions, providersOptions, brokerOptions, comparisonSiteOptions } = policiesListStore;
  const { formErrors, formValues } = detailsFormStore;

  const insuranceCategory = category?.root?.code === 'insurance';
  const broadbandCategory = category?.root?.code === 'broadband';
  const mobileCategory = category?.root?.code === 'mobile';

  const company = _.find(
    [...brokerOptions, ...comparisonSiteOptions, ...(insuranceCategory ? mixOptions : providersOptions)],
    { id: formValues.anyCompanyId || formValues.sourceProviderCompanyId }
  );

  return (
    <div className='rating-container' key={formValues[`${namePrefix}Id`]}>
      <Box sx={{ mb: 2 }}>
        <SelectedCompany company={company} />
      </Box>
      <RatingField
        name={`${namePrefix}Rate`}
        value={formValues[`${namePrefix}Rate`]}
        minLabel={(broadbandCategory || mobileCategory) && 'Very poor'}
        maxLabel={(broadbandCategory || mobileCategory) && 'Excellent'}
      />

      {formErrors[`${namePrefix}Rate`] && <div className='field-error'>Required</div>}

      <Box sx={{ mt: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
          <Typography variant='body2'>Add an insight! (Optional)</Typography>
          <TooltipMUI
            title='You can add an insight here to help other members here! Insights will appear on the public business page of this business. Please make sure your insight is truthful and fair! Thank you!'
            arrow
            placement='top'
          >
            <InfoOutlinedIcon />
          </TooltipMUI>
        </Box>
        <InputField name={`${namePrefix}RateComment`} question={{ type: 'textarea' }} placeholder='Add an insight' />

        {formErrors[`${namePrefix}RateComment`] && <div className='field-error'>Required</div>}
      </Box>
    </div>
  );
});
