import React, { useState } from 'react';

import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import GoogleIcon from '@mui/icons-material/Google';
import StarIcon from '@mui/icons-material/Star';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { RatingMUI } from '@shared/components/RatingMUI/RatingMUI';
import { getTotalRating, transformToHTML } from '@shared/helpers/companyHelper';
import { RatingCup } from '@shared/images/rating/RatingCup';

import useStyles from './styles';

import size from 'lodash-es/size';

export const AISummary = ({ colors, company }) => {
  const styles = useStyles(colors);
  const [value, setValue] = useState(0);

  const billwinnerScores = company?.billwinnerScores || {};
  const googleScores = company?.googleScores || {};
  const trustpilotScores = company?.trustpilotScores || {};
  const { totalRating, totalReviews } = getTotalRating(company);

  const generalSummary = company?.generalSummary;
  const billwinnerSummary = company?.billwinnerSummary;
  const googleSummary = company?.googleSummary;
  const trustpilotSummary = company?.trustpilotSummary;

  const ratingList = [
    {
      icon: <StarIcon sx={{ fontSize: '16px' }} />,
      label: 'Overview',
      rating: totalRating,
      reviews: totalReviews,
      summary: generalSummary
    },
    {
      icon: <GoogleIcon sx={{ fontSize: '16px' }} />,
      label: 'Google',
      rating: googleScores.rating,
      reviews: googleScores.reviews,
      summary: googleSummary
    },
    {
      icon: <StarIcon sx={{ fontSize: '16px' }} />,
      label: 'Trustpilot',
      rating: trustpilotScores.rating,
      reviews: trustpilotScores.reviews,
      summary: trustpilotSummary
    },
    {
      icon: <RatingCup />,
      label: 'Bill Winner',
      rating: billwinnerScores.rating,
      reviews: billwinnerScores.reviews,
      summary: billwinnerSummary
    }
  ];

  const validRatingList = ratingList.filter(item => item.summary);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (size(validRatingList) === 0) {
    return null;
  }

  const currentSummary = validRatingList[value]?.summary || '';
  const currentLabel = validRatingList[value]?.label || '';
  const currentRating = validRatingList[value]?.rating || 0;
  const currentReviews = validRatingList[value]?.reviews || 0;

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.titleWrapper}>
        <Typography variant='h3' sx={{ color: 'inherit' }}>
          AI
        </Typography>
        <Typography variant='h6' sx={{ color: 'inherit' }}>
          Comprehensive reviews analysis
        </Typography>
        <Tooltip
          title='Bill Winner uses AI-generated review summaries to provide our members with an overall insight into  what customers in general think about this business.'
          placement='top'
        >
          <ErrorOutlineRoundedIcon />
        </Tooltip>
      </Box>
      <Tabs value={value} onChange={handleChange} variant='buttonTabs' sx={styles.tabs}>
        {validRatingList.map(rating => (
          <Tab
            key={rating.label}
            label={
              <Box sx={styles.tabLabel}>
                {rating.icon}
                {rating.label}
              </Box>
            }
            variant='buttonTabs'
            disableRipple
            sx={styles.tab}
          />
        ))}
      </Tabs>
      <Box sx={styles.tabsContent}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
          <RatingMUI value={currentRating} size='large' />
          <Typography variant='body4'>{currentRating.toFixed(1)} out of 5</Typography>
          <Divider
            flexItem
            orientation='vertical'
            sx={{ alignSelf: 'center', height: '12px', width: '2px', borderColor: colors.dark.main }}
          />
          <Typography variant='body4'>{currentReviews} Reviews</Typography>
        </Box>
        <Typography
          variant='body1'
          dangerouslySetInnerHTML={transformToHTML(currentSummary, [company.name, currentLabel])}
        />
      </Box>
    </Box>
  );
};
