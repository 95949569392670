export const companiesFragment = `
  companies {
    id
    companyType
    name
    url
    urlId
    avatarUrl
    companyClicks
    description
    metaDescription
    responseTime
    quoteRequestsCount
    sentimentAnalysis {
        score
        summary
    }
    benefits {
        title
        description
    }
    additionalData {
      averagePriceData {
        averageYearly
        averageMonthly
      }
      members
      insights
      satisfaction
      rank
      rating
      wikisCount
      recommendation {
        score
        promoters
        passives
        detractors
      }
      boughtHere
      hasDetails
      trustedFriendsCount
    }
    googleScores { rating reviews }
    billwinnerScores { rating reviews }
    trustpilotScores { rating reviews }
    partnerProvider {
      customWebLink
      offerMessage
      offerDetails
      associationName
      region
      offerType
    }
    quoteAgent {
      id
      agentFirstName
      agentLastName
      agentEmail
      regions
    }
    affinityLink {
      id
      url
    }
    wikiTable {
      id
      planPrice
      variablePrice
      fullTermCost
      fixedRate
      greenRate
      fixedPeriod
      aprRate
      aerRate
      variableRate
      updatedAt
      providerCompany {
        id
        name
        urlId
        avatarUrl
      }
      informationalSections {
        id
        answer
        group {
          id
          name
        }
        criteriaQuestion {
          label
          name
        }
      }
    }
  }
`.trim();

export const fetchCompaniesList = `
  query categoryCompanies(
    $categoryId: Int,
    $categoryCode: String,
    $page: Int,
    $companyType: String,
    $countryCode: String,
    $lastBuy: String,
    $perPage: Int,
    $orderColumn: CompanyOrderColumnEnum,
    $orderDirection: String,
    $criteriaQuestions: JSON,
    $keyWord: String,
    $zipCode: String,
    $city: String,
    $address: String,
    $currentUserAssign: String,
    $policyId: Int,
  ) {
    categoryCompanies(
      categoryId: $categoryId,
      categoryCode: $categoryCode,
      page: $page,
      companyType: $companyType,
      countryCode: $countryCode,
      criteriaQuestions: $criteriaQuestions,
      keyWord: $keyWord,
      zipCode: $zipCode,
      city: $city,
      lastBuy: $lastBuy,
      perPage: $perPage,
      orderColumn: $orderColumn,
      orderDirection: $orderDirection,
      address: $address,
      currentUserAssign: $currentUserAssign,
      policyId: $policyId,
    ) {
    ${companiesFragment}
    errors
    totalCount
  }
}
`.trim();

export const fetchCompaniesListProduct = `
  query categoryCompanies(
    $categoryId: Int,
    $page: Int,
    $perPage: Int,
    $excludeCompanyId: Int,
  ) {
    categoryCompanies(
      categoryId: $categoryId,
      page: $page,
      perPage: $perPage,
      excludeCompanyId: $excludeCompanyId,
    ) {
    companies {
      id
      companyType
      name
      url
      urlId
      additionalData {
        members
        insights
        satisfaction
        rank
        rating
        boughtHere
      }
      avatarUrl
    }
    errors
    totalCount
  }
}
`;
