import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { pathGen, usePath } from '@packs/layouts/constants';
import { hasPolicyCriteria, setTourProgress } from '@packs/lib/helpers/additionalHelpers';
import { useDetails } from '@packs/models/policy/useDetails';
import { useStore } from '@packs/stores';
import { categoriesWithoutPersonalizeFlow, subCategoriesWithoutPersonalizeFlow } from '@shared/constants/categories';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import useStyles from './styles';

import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

export const PersonalizationQuoteButton = observer(({ btnText = '' }) => {
  const { currentCategory, leaderboardStore, countryCode, companiesStore } = useStore();
  const { ADD_DETAILS_PATH } = usePath();
  const { currentPolicy } = useDetails();
  const styles = useStyles();
  const { isMobile } = useWindowSize();

  const notPersonalized = !hasPolicyCriteria(currentPolicy);

  const skipBusinessStep = notPersonalized && !!currentPolicy?.anyCompany?.id;

  const isAgentsExist = companiesStore?.requestQuotesCount > 0;

  const businessesCount = leaderboardStore.requestBusinesses.length;
  const isCategoriesWithoutPersonalizeFlow = categoriesWithoutPersonalizeFlow.includes(currentCategory?.root?.code);
  const isSubCategoriesWithoutPersonalizeFlow = subCategoriesWithoutPersonalizeFlow.includes(currentCategory.code);

  if (isCategoriesWithoutPersonalizeFlow || isSubCategoriesWithoutPersonalizeFlow) {
    return null;
  }

  if (notPersonalized) {
    return (
      <Box sx={styles.wrapper}>
        <Box sx={styles.requestBtnWrapper}>
          <Button
            component={Link}
            variant='contained'
            color='primary'
            size='large'
            fullWidth={isMobile}
            sx={styles.requestBtn}
            to={ADD_DETAILS_PATH.concat(skipBusinessStep ? `?skipBusinessStep=${skipBusinessStep}` : '')}
          >
            {companiesStore?.globalRequestQuotesCount > 0
              ? btnText
                ? btnText
                : businessesCount === 0
                ? 'Request Quotes!'
                : `Request Quotes (${businessesCount}/3)`
              : 'Get Matched!'}
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={styles.wrapper}>
      {isAgentsExist && (
        <Box sx={styles.requestBtnWrapper}>
          <Button
            component={Link}
            variant='contained'
            color='primary'
            size='large'
            fullWidth={isMobile}
            to={pathGen('REQUEST_QUOTES', { countryCode, category: currentCategory }).concat(
              businessesCount ? '?initialStep=2' : ''
            )}
            onClick={() => {
              leaderboardStore?.cleanCategoryAgents();
              setTourProgress('myResults', ['requestQuotes', 'requestQuotesBox'], true);
            }}
            sx={styles.requestBtn}
          >
            {businessesCount === 0 ? 'Request Quotes!' : `Request Quotes (${businessesCount}/3)`}
          </Button>
        </Box>
      )}
    </Box>
  );
});
