import React from 'react';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

export const SidebarAnswer = ({ label, answer }) => {
  if (!answer) {
    return null;
  }

  const answerValue = Array.isArray(answer) ? answer.join(', ') : answer;

  return (
    <div className='request-details-item d-flex align-items-start'>
      <div className='img-container'>
        <CheckRoundedIcon color='success' sx={{ fontSize: '18px' }} />
      </div>
      <div className='d-flex flex-column'>
        <div className='fs-13 fw-700 lh-9'>{label}</div>
        <div className='fs-11 lh-5 secondary-text'>{answerValue}</div>
      </div>
    </div>
  );
};
