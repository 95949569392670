import _ from 'lodash-es';

export const locationOptions = currentCountry =>
  _.get(currentCountry, 'regions', []).map(option => {
    return {
      label: option.name,
      value: option.name
    };
  });

export const getDetailsOptions = ({ userRenewalDetails }) => [
  ..._.map(userRenewalDetails, (detail, inx) => {
    detail.index = inx;
    return {
      value: detail.id || `new-${inx}`,
      object: detail,
      label: _.get(detail, 'smartReminder.aliasName')
    };
  }),
  {
    value: 'new-detail',
    type: 'new-detail',
    label: 'Add another policy',
    className: 'add-new-button'
  }
];
