import React from 'react';

export const Local_servicesHeatingOil = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path d='M9 4C8.448 4 8 4.448 8 5C8 5.552 8.448 6 9 6H9.5C9.776 6 10 6.224 10 6.5V7.5C10 7.776 9.776 8 9.5 8H5.7207L4.94922 5.68359C4.81322 5.27459 4.431 5 4 5H1C0.448 5 0 5.448 0 6V10C0 10.379 0.213734 10.7255 0.552734 10.8945L4 12.6172V19C4 19.552 4.448 20 5 20H15C15.39 20 15.7452 19.7739 15.9082 19.4199L21.6406 7H22C22.552 7 23 6.552 23 6C23 5.448 22.552 5 22 5H20C19.677 5 19.3735 5.15692 19.1855 5.41992L16 9.87891V9C16 8.448 15.552 8 15 8H12.5C12.224 8 12 7.776 12 7.5V6.5C12 6.224 12.224 6 12.5 6H13C13.552 6 14 5.552 14 5C14 4.448 13.552 4 13 4H9ZM2 7H3.2793L4 9.16211V10.3828L2 9.38281V7ZM21.5 10.9883C21.2803 10.9883 21.0618 11.1119 20.9688 11.3574C20.5368 12.4934 20 14.004 20 14.5C20 15.329 20.671 16 21.5 16C22.329 16 23 15.329 23 14.5C23 14.004 22.4642 12.4934 22.0332 11.3574C21.9402 11.1119 21.7197 10.9883 21.5 10.9883Z' />
      </g>
    </svg>
  );
};
