import React from 'react';

export const GeneralHealthAndFitness = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path d='M16.256 3.00496C13.515 3.11696 12 5.08996 12 5.08996C12 5.08996 10.377 2.97596 7.45099 2.99996C6.33799 3.00896 5.27799 3.47596 4.40299 4.16396C2.01899 6.03796 1.70199 8.06696 2.20299 9.99996H5.66699L7.39999 8.69996C7.85899 8.35596 8.51399 8.46796 8.83199 8.94496L10.246 11.065L11.134 10.399C11.48 10.139 11.901 9.99896 12.334 9.99896H13.278C13.701 9.27196 14.558 8.82996 15.502 9.06096C16.222 9.23696 16.803 9.84196 16.955 10.567C17.226 11.861 16.246 13 15 13C14.262 13 13.624 12.595 13.277 12H12.333L10.6 13.3C10.141 13.644 9.48599 13.532 9.16799 13.055L7.75399 10.935L6.86699 11.6C6.52099 11.86 6.09999 12 5.66699 12H3.02399C4.53799 14.764 7.30599 17.08 8.28099 17.99C9.31399 18.952 10.588 20.095 11.345 20.769C11.72 21.103 12.279 21.103 12.654 20.769C13.411 20.095 14.686 18.952 15.718 17.99C17.438 16.387 24.75 10.416 20.888 5.31196C19.779 3.84496 18.094 2.92996 16.256 3.00496Z' />
      </g>
    </svg>
  );
};
