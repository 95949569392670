import React, { useState } from 'react';
import _ from 'lodash-es';
import cx from 'classnames';

import { signUpApi } from '@packs/apis/signUp';
// Components
import { FieldWithErrors } from '@packs/components/FieldWithErrors';
import { SelectField } from '@packs/components/SelectField';
import { toastr } from '@packs/lib/helpers';
import { useCategories } from '@packs/models/category/useCategories';
import { useStore } from '@packs/stores';
import { getWindow } from '@shared/lib/utils';

import { PersonalizeQuestions } from '../PersonalizeQuestions';

import { Field } from 'redux-form';

export const Form = ({ handleSubmit, toggle, formAttributes }) => {
  const [loading, setLoading] = useState(false);
  const { currentCategory } = useCategories();
  const { companiesStore, countryCode, currentCountry } = useStore();

  const isCountySelect = ['en-ie', 'en-za', 'en-sg'].includes(countryCode);
  const options = _.get(currentCountry, 'regions', []).map(option => {
    return {
      label: option.name,
      value: option.name
    };
  });

  const locationPlaceholder = () => {
    switch (countryCode) {
      case 'us':
        return 'Location (zip code)';
      case 'in':
        return 'Location (pin code)';
      default:
        return 'Location (postal code)';
    }
  };

  const locationSelectPlaceholder = () => {
    switch (countryCode) {
      case 'za':
      case 'sg':
        return 'Select states';
      default:
        return 'Select county';
    }
  };

  const successCallback = ({ location, email, criteriaQuestions }) => {
    getWindow().localStorage.setItem('region', location);
    if (email) {
      getWindow().localStorage.setItem('prefilled-email', email);
    }

    if (location) {
      const filters = { ...companiesStore.filters, city: location };
      companiesStore.load({ filters });
    }

    if (criteriaQuestions) {
      const criteriaQuestionsLocalStore = getWindow().localStorage.getItem('criteriaQuestions');
      const criteria = criteriaQuestionsLocalStore ? JSON.parse(criteriaQuestionsLocalStore) : {};
      const criteriaData = {
        [currentCategory.id]: criteriaQuestions,
        ...criteria
      };

      const filters = {
        ...companiesStore.filters,
        criteriaQuestions
      };
      if (countryCode === 'en-ie' || location === 'Florida') {
        companiesStore.load({ filters });
      }

      getWindow().localStorage.setItem('criteriaQuestions', JSON.stringify(criteriaData));
    }

    toggle();
  };

  const onsubmit = async data => {
    const newData = { location: data.location || data.county || null, email: data.email };
    const criteriaQuestions = data?.criteriaQuestions || {};

    setLoading(true);

    if (data.county && !data.email) {
      successCallback({ location: data?.county, criteriaQuestions });
    } else {
      const resp = await signUpApi.userZipSubscribe(newData);

      if (resp?.errors?.length === 0) {
        successCallback({
          location: isCountySelect ? data?.county : resp.location,
          email: data.email,
          criteriaQuestions
        });
      } else {
        toastr.error(resp?.errors);
      }
    }

    setLoading(false);
  };

  return (
    <form autoComplete='off' className='form-container' onSubmit={handleSubmit(onsubmit)}>
      <div className='form-field'>
        <PersonalizeQuestions {...{ formAttributes }} />
        {isCountySelect ? (
          <div className='form-field'>
            <Field
              name='county'
              classNamePrefix='Select'
              placeholder={locationSelectPlaceholder()}
              className='no-scale   form-select'
              options={options}
              component={SelectField}
              mobileDrawer
            />
          </div>
        ) : (
          <Field
            component={FieldWithErrors}
            type='text'
            name='location'
            autoComplete='off'
            placeholder={locationPlaceholder()}
            className='form-input'
          />
        )}
      </div>

      <div className='btn-container d-flex align-items-center justify-content-center'>
        <button
          className={cx('btn full theme white text-uppercase', { disabled: loading })}
          type='submit'
          disabled={loading}
        >
          <span>let’s go!</span>
        </button>
      </div>
    </form>
  );
};
