import { userApi } from '@packs/apis/currentUser';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { toastr } from '@packs/lib/helpers';
import { onSaveClick } from '@packs/screens/PersonalizeResults/formHooks/onSave';
import { useStore } from '@packs/stores/base';

import get from 'lodash-es/get';
import { isValidPhoneNumber } from 'react-phone-number-input';

export const useDetailsSubmitActions = ({ localData, setDisabledBtn, submitCallbacks }) => {
  const { associationStore, detailsFormStore } = useStore();
  const { currentUser } = useUserSelectors();
  const { toggleVerificationModalOpen } = useModalsContext();
  const { formValues } = detailsFormStore;

  const handleSubmit = (signUpFlow, verified) => {
    setDisabledBtn(true);
    onSaveClick(localData, submitCallbacks.successCallback, submitCallbacks.errorCallback, signUpFlow, verified);
    associationStore.assignClientUsersLater();
  };

  const handleSubmitVerification = (signUpFlow, verified) => {
    toggleVerificationModalOpen({ modalProps: {} }, true);
    handleSubmit(signUpFlow, verified);
  };

  const handleVerification = async signUpFlow => {
    const resp = await userApi.phoneVerify({ phoneNumber: formValues?.phoneNumber.replace(/\s+/g, '') });
    if (resp?.errors) {
      toastr.error(resp?.errors);
    } else {
      toggleVerificationModalOpen({
        modalProps: {
          handleSubmitVerification: () => handleSubmitVerification(signUpFlow, true),
          phoneNumber: formValues?.phoneNumber
        }
      });
    }
  };

  const phoneVerification =
    formValues?.phoneNumber &&
    get(currentUser, 'verifiedNumbers', []).some(item => item === formValues?.phoneNumber.replace(/\s+/g, ''));

  const onClickLastStepSave = (signUpFlow, verified) => {
    const { phoneNumber, isAutoQuote } = formValues;

    if (isAutoQuote && !phoneVerification) {
      if (isValidPhoneNumber(phoneNumber)) {
        handleVerification(signUpFlow, verified);
      }
      return;
    }

    handleSubmit(signUpFlow, verified);
  };

  return {
    onClickLastStepSave,
    handleSubmitVerification,
    handleVerification
  };
};
