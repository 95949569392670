import React, { useEffect, useMemo, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import { ProfileSkeleton } from '@packs/components/UI';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { useProfileContext } from '@packs/layouts/ProfileLayout/context';
import { EmptyState } from '@packs/screens/Profile/components/EmptyState';
import { useStore } from '@packs/stores';
import { QuestionItem } from '@shared/components/QA/QuestionItem';

import { InsightItem } from '../Insights/InsightItem';
import RequestsItem from '../Requests/RequestsItem';

import { observer } from 'mobx-react';
import InfiniteScroll from 'react-infinite-scroll-component';

const postsPerPage = 10;

export const MixedPosts = observer(() => {
  const context = useProfileContext();
  const { currentUser } = useUserSelectors();
  const { toggleCreateQuestionModal, toggleConfirmModalOpen, toggleSignUpModal } = useModalsContext();

  const questionsContainerRef = useRef(null);
  const { mixedPostsStore, questionsStore } = useStore();
  const [page, setPage] = useState(1);

  const postsList = useMemo(() => {
    return mixedPostsStore.posts;
  }, [mixedPostsStore.posts]);
  const loadMorePage = () => {
    mixedPostsStore.load(
      {
        page: page + 1,
        perPage: postsPerPage,
        categories: context?.categoriesFilter.length ? context?.categoriesFilter : null
      },
      true
    );

    setPage(page + 1);
  };

  useEffect(() => {
    mixedPostsStore.load({
      page: 1,
      perPage: postsPerPage,
      categories: context?.categoriesFilter.length ? context?.categoriesFilter : null
    });
  }, [context?.categoriesFilter]);

  return (
    <div className='questions-main-container'>
      <div className='position-relative' ref={questionsContainerRef}>
        {!mixedPostsStore.loading && postsList?.length === 0 ? (
          <EmptyState
            title='You have not created any posts, voted, or requested a quote yet!'
            description='Your content will appear here..'
          />
        ) : (
          <>
            <InfiniteScroll
              dataLength={postsList.length}
              next={loadMorePage}
              scrollThreshold={0.9}
              hasMore={postsList.length < mixedPostsStore.postsTotalCount}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: 3, lg: 4 } }}>
                {postsList.map((item, index) => {
                  if (item.type === 'qa') {
                    return (
                      <QuestionItem
                        key={item?.id}
                        question={item}
                        owner={currentUser}
                        first={index === 0}
                        questionsContainerOffset={questionsContainerRef?.current?.offsetTop}
                        withOutCategoryMode
                        {...{
                          questionsStore,
                          currentUser,
                          toggleCreateQuestionModal,
                          toggleConfirmModalOpen,
                          toggleSignUpModal
                        }}
                      />
                    );
                  } else if (item?.type === 'insight' || item?.type === 'vote') {
                    return (
                      <InsightItem
                        key={item?.id}
                        insight={item}
                        owner={currentUser}
                        first={index === 0}
                        postType={item?.type}
                        questionsContainerOffset={questionsContainerRef?.current?.offsetTop}
                        isProfile
                        withOutCategoryMode
                      />
                    );
                  } else if (item?.type === 'request') {
                    return (
                      <RequestsItem
                        key={item?.id}
                        request={item}
                        owner={currentUser}
                        first={index === 0}
                        postType='request'
                        questionsContainerOffset={questionsContainerRef?.current?.offsetTop}
                        withOutCategoryMode
                      />
                    );
                  }
                })}
              </Box>
              {mixedPostsStore.loading && <ProfileSkeleton />}
            </InfiniteScroll>
          </>
        )}
      </div>
    </div>
  );
});
