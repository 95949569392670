import React from 'react';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FiltersDrawer } from '@packs/components/MaterialUi/FiltersDrawer/FiltersDrawer';
import { useStore } from '@packs/stores';
import useStyles from '@packs/styles/Leaderboard/topBarStyles';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { observer } from 'mobx-react';

export const NotesTopBar = observer(({ documentFilters, disabledRemove, onResetFilters, general }) => {
  const { isDesktop } = useWindowSize();
  const { notesStore } = useStore();
  const styles = useStyles();

  if (general && isDesktop) {
    return null;
  }

  return (
    <Box sx={styles.wrapper}>
      {!isDesktop && (
        <Box sx={styles.searchFilterWrapper}>
          <Box />

          <Box sx={styles.actionSectionWrapper}>
            <Box sx={styles.actionSection}>
              <FiltersDrawer
                buttonProps={{
                  variant: 'filledTonal',
                  color: 'primary',
                  startIcon: <FilterAltIcon />,
                  sx: { width: { xs: '100%', lg: 'auto' } }
                }}
                handleResetFilters={onResetFilters}
                disabledResetFilters={disabledRemove}
                showResultText={`Show ${notesStore?.list?.length} documents`}
              >
                {documentFilters()}
              </FiltersDrawer>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
});
