import React from 'react';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export const ProfileSkeleton = () => {
  return (
    <>
      {[...Array(10)].map((_, index) => (
        <div key={index} className='profile-skeleton d-flex flex-column'>
          <SkeletonTheme color='#E4EEFA' highlightColor='#fff'>
            <div className='d-flex mb-24'>
              <Skeleton count={1} height={46} width={46} className='mr-15 avatar-skeleton' />
              <Skeleton count={1} height={46} maxWidth={100} width={'100%'} />
            </div>
            <div className='d-flex justify-content-between mb-16'>
              <Skeleton count={1} height={30} maxWidth={300} width={'100%'} />
              <Skeleton count={1} height={30} maxWidth={150} width={'100%'} />
            </div>
            <div className='mb-20'>
              <Skeleton count={1} height={90} width='100%' />
            </div>
            <Skeleton count={1} height={56} width='100%' />
          </SkeletonTheme>
        </div>
      ))}
    </>
  );
};
