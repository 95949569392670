import React from 'react';

export default () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'>
      <path
        fillRule='evenodd'
        strokeWidth='.5'
        d='M14.826 4.562L13.686 4.562 13.686 13.686 4.562 13.686 4.562 14.826 13.686 14.826 13.686 23.95 14.826 23.95 14.826 14.826 23.95 14.826 23.95 13.686 14.826 13.686z'
        transform='rotate(45 18.499 4.013)'
      />
    </svg>
  );
};
