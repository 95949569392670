import _ from 'lodash-es';

import { nodes } from '@packs/screens/PersonalizeResults/DetailsFormProvider';
import { useStore } from '@packs/stores/base';

export const useClickYesNo = ({ setModalOpen }) => {
  const { detailsFormStore } = useStore();
  const { formValues, hideCliteria, showFields } = detailsFormStore;

  const onClickYes = () => {
    detailsFormStore.setShowFields({
      ...showFields,
      anyCompanyRate: true
    });
  };

  const onClickNo = () => {
    const hC = _.clone(hideCliteria);
    const sF = _.clone(showFields);
    const fV = _.clone(formValues);
    setModalOpen(false);
    if (nodes.sourceProviderCompanyId) nodes.sourceProviderCompanyId.value = null;
    if (nodes.anyCompanyId) nodes.anyCompanyId.value = null;
    fV.sourceProviderCompanyId = null;
    fV.anyCompanyId = null;
    fV.lastBuy = null;
    fV.buyingType = null;
    if (!fV.criteriaQuestions) fV.criteriaQuestions = {};
    fV.criteriaQuestions.claimWithBussiness = null;
    fV.criteriaQuestions.claimRate = null;
    if (!fV.pricesQuestions) fV.pricesQuestions = {};
    fV.triggeredAtDate = { month: null, day: null };
    hC.claimWithBussiness = false;
    hC.claimRate = true;
    detailsFormStore.setHideCliteria(hC);

    sF.dontRememberProvider = true;
    sF.comparison_site = null;
    sF.broker = null;
    sF.provider = null;
    detailsFormStore.setShowFields(sF);
    detailsFormStore.setFormValues(fV);
  };

  return {
    onClickYes,
    onClickNo
  };
};
