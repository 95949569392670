import React from 'react';
import cx from 'classnames';

import colors from '@packs/assets/theme/base/colors';
import { BusinessSidebarBox } from '@packs/components/UI';
import OtherCategoriesIcon from '@packs/images/svg/ui/other-categories.svg';
import { pathGen } from '@packs/layouts/constants';
import { handleVisitCompany } from '@packs/lib/helpers/additionalHelpers';
import { useStore } from '@packs/stores';
import { SUB_ICONS } from '@shared/helpers/subCategoryIcon';

import { NavLink } from 'react-router-dom';

export const OtherCategories = ({ company }) => {
  const { companiesStore, categoryStore, countryCode } = useStore();

  const list = (company && company?.suggestedCategories) || [];

  const handleRedirect = subCategory => {
    return e => {
      handleVisitCompany(company, subCategory);
      categoryStore.setCurrentCategory(subCategory);
      companiesStore.clearFilters();
    };
  };

  if (list.length === 0) {
    return null;
  }

  return (
    <BusinessSidebarBox title='Other categories' icon={OtherCategoriesIcon}>
      <div className='d-flex flex-column'>
        {list.map(category => {
          return (
            <NavLink
              key={category.id}
              className={cx('category-link theme-icon', category?.root?.enName)}
              to={pathGen('PRODUCT', {
                countryCode,
                category,
                company
              })}
              onClick={handleRedirect(category)}
            >
              {SUB_ICONS({ category, fill: colors?.category[category?.root?.enName]?.main })}
              {category.name}
            </NavLink>
          );
        })}
      </div>
    </BusinessSidebarBox>
  );
};
