import { action, computed, observable, runInAction } from 'mobx';

import api from '@packs/apis/myOffers';
import { offerBusinessType, offerTypes } from '@packs/models/company/constants';
import { OFFERS_PER_PAGE } from '@packs/models/offer/constants';

import cloneDeep from 'lodash-es/cloneDeep';
import isEqual from 'lodash-es/isEqual';

export const defaultCategory = { value: 'all', label: 'All categories' };

export const INIT_FILTERS = {
  rootCategoryCode: defaultCategory,
  categoryId: defaultCategory,
  offerType: offerTypes[0].value,
  customerType: offerBusinessType[0].value,
  keyWord: '',
  page: 1,
  perPage: OFFERS_PER_PAGE
};

export class OffersStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable
  offers = [];
  @observable
  offerDetailed = {};
  @observable
  totalCount = [];
  @observable
  filters = INIT_FILTERS;
  @observable
  featuredOffers = [];
  @observable
  personalizedOffers = [];
  @observable
  loader = false;
  @observable
  featuredLoader = false;
  @observable
  personalizedLoader = false;

  @computed get serializeFilters() {
    const { categoryId, offerType, rootCategoryCode, keyWord, customerType } = this.filters;
    const newFilters = {
      ...this.filters
    };
    if (!categoryId?.value || categoryId?.value === 'all') {
      delete newFilters.categoryId;
    } else {
      newFilters.categoryId = categoryId?.value;
    }
    if (!rootCategoryCode?.value || rootCategoryCode?.value === 'all') {
      delete newFilters.rootCategoryCode;
    } else {
      newFilters.rootCategoryCode = rootCategoryCode?.value;
    }
    if (offerType === 'all') {
      delete newFilters.offerType;
    }
    if (customerType === 'all') {
      delete newFilters.customerType;
    }
    if (!keyWord) {
      newFilters.keyWord = null;
    }
    return newFilters;
  }

  @action
  async load(filters = {}, loadMore = false) {
    this.filters = {
      ...this.filters,
      ...filters,
      page: filters.page || 1
    };

    this.loader = true;

    const resp = await api.fetchMyOffers(this.serializeFilters);

    runInAction(() => {
      if (resp?.errors?.length === 0) {
        this.totalCount = resp.totalCount;

        if (loadMore) {
          this.offers = [...this.offers, ...resp.list];
        } else {
          const prevArray = this.offers.map(({ id }) => ({ id }));
          const newArray = resp.list.map(({ id }) => ({ id }));

          if (!isEqual(prevArray, newArray)) {
            this.offers = resp.list;
          }
        }
      }
      this.loader = false;
    });
  }

  @action
  async loadFeatured(categoryId, rootCategoryCode) {
    this.featuredLoader = true;
    const resp = await api.fetchMyOffersFeatured({
      categoryId: categoryId || null,
      rootCategoryCode: rootCategoryCode || null
    });
    runInAction(() => {
      const prevArray = this.featuredOffers.map(({ id }) => ({ id }));
      const newArray = resp.list.map(({ id }) => ({ id }));
      if (!isEqual(prevArray, newArray)) {
        this.featuredOffers = resp.list;
      }
      this.featuredLoader = false;
    });

    return resp;
  }
  @action
  async loadPersonalizedOffers(categoryId, criteriaQuestions) {
    this.personalizedLoader = true;
    const resp = await api.fetchMyOffers({ categoryId: categoryId || null, criteriaQuestions });
    runInAction(() => {
      this.personalizedOffers = resp.list;
      this.personalizedLoader = false;
    });

    return resp;
  }

  @action
  async loadDetailed(variables) {
    if (this.offerDetailed.id !== variables.id) {
      this.loader = true;
      const resp = await api.fetchMyOffersDetailed(variables);
      runInAction(() => {
        if (resp?.errors?.length === 0) {
          this.offerDetailed = { ...resp?.offer, companyContact: resp.companyContact } || {};
        }

        this.loader = false;
      });
    }
  }

  @action
  clearFilters() {
    this.filters = cloneDeep(INIT_FILTERS);
  }

  @action
  setFilters(name, value) {
    this.filters = {
      ...this.filters,
      [name]: value
    };
  }
}
