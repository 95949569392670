import React from 'react';

const InfoIcon = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M8.33887 8.5H9.10585V11.3345' stroke='#7B7988' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8.33301 11.3335H9.87321' stroke='#7B7988' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M9.06661 6.49753C9.06661 6.58962 8.99196 6.66427 8.89988 6.66427C8.80779 6.66427 8.73314 6.58962 8.73314 6.49753C8.73314 6.40545 8.80779 6.3308 8.89988 6.3308'
        stroke='#7B7988'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.90001 6.32823C8.99209 6.32823 9.06674 6.40288 9.06674 6.49497'
        stroke='#6E7F95'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <rect
        x='2.99756'
        y='2.99609'
        width='12.005'
        height='12.005'
        rx='5'
        stroke='#6E7F95'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default InfoIcon;
