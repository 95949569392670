import { forwardRef } from 'react';

import TextField from '@mui/material/TextField';

const PhoneInput = (props, ref) => {
  return (
    <TextField {...props} inputRef={ref} fullWidth size='medium' label='Phone Number' variant='outlined' name='phone' />
  );
};
export default forwardRef(PhoneInput);
