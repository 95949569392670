import React from 'react';

export const BusinessBusinessDeposits = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path
          id='Vector'
          d='M10 1C8.343 1 7 2.343 7 4C7 4.772 7.3003 5.468 7.7793 6H12.2207C12.6997 5.468 13 4.772 13 4C13 2.343 11.657 1 10 1ZM19.25 6.57812C19.1234 6.57394 18.9931 6.60448 18.8711 6.67773L17.0625 7.76367C16.2961 7.28896 15.4329 6.88828 14.4707 6.5918L14.2285 7.31641C14.0925 7.72441 13.7103 8 13.2793 8H6.7207C6.2897 8 5.90748 7.72541 5.77148 7.31641L5.69336 7.08008C3.97919 7.98155 2.81391 9.3888 2.30078 11.0469C2.2035 11.016 2.10207 11.0002 2 11C1.73478 11 1.48043 11.1054 1.29289 11.2929C1.10536 11.4804 1 11.7348 1 12C1 12.2652 1.10536 12.5196 1.29289 12.7071C1.48043 12.8946 1.73478 13 2 13C2 14.1643 2.39178 15.3507 3.07422 16.4434L3.67188 20.0273C3.86188 21.1663 4.846 22 6 22C7.00975 22 7.89106 21.3602 8.22266 20.4375C9.24585 20.7906 10.346 21 11.5 21C12.1945 21 12.8693 20.9421 13.5215 20.8379L13.5664 20.9121C13.9714 21.5861 14.7013 22 15.4883 22C16.9013 22 17.9606 20.7063 17.6836 19.3203L17.6816 19.3086C18.6836 18.6744 19.5004 17.8886 20.0742 17H20.1133C21.2213 17 22.188 16.2469 22.457 15.1719C22.763 13.9469 22.0769 12.692 20.8789 12.293L20.7246 12.2422C20.4538 11.3614 19.9852 10.5022 19.334 9.7168C19.5868 9.06085 19.8102 8.28079 19.9219 7.33984C19.9721 6.91609 19.6299 6.59069 19.25 6.57812ZM18 12C18.552 12 19 12.448 19 13C19 13.552 18.552 14 18 14C17.448 14 17 13.552 17 13C17 12.448 17.448 12 18 12Z'
        />
      </g>
    </svg>
  );
};
