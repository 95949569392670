import React from 'react';

import MobiScroll from '@packs/lib/MobiScroll/js/mobiscroll.react.min.js';

const group = {
  groupWheel: false,
  header: false
};

export const SwipePicker = props => {
  const {
    value,
    options = [],
    remoteData,
    onChange,
    display = 'bottom',
    placeholder,
    filterPlaceholderText = 'Filter',
    filterEmptyText = 'No results found',
    useOptions,
    buttons = []
  } = props;
  const optionsList = options.map(item => ({ ...item, text: item?.label }));
  const optionChange = (event, inst) => {
    onChange({ value: inst.getVal() });
  };

  return (
    <MobiScroll.Select
      value={value?.value || ''}
      display={display}
      data={useOptions ? optionsList : remoteData}
      width={400}
      animate='slideup'
      buttons={buttons}
      placeholder={placeholder}
      filterPlaceholderText={filterPlaceholderText}
      scrollLock={false}
      filter
      {...{
        display,
        group,
        filterEmptyText
      }}
      onSet={optionChange}
    >
      <MobiScroll.Input inputStyle='box' placeholder={placeholder} />
    </MobiScroll.Select>
  );
};
