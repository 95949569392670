import colors from '@packs/assets/theme/base/colors';

const { borderColor } = colors;

export default () => ({
  accordionsWrapper: {
    // overflow: 'hidden'
  },
  accordionItem: {
    borderRadius: '0 0 16px 16px !important',
    borderTop: `1px solid ${borderColor.main}`,
    boxShadow: 'none',
    '&:before': {
      content: 'none'
    },
    '&.Mui-expanded': {
      margin: '0 !important'
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: '48px !important',
      '& .MuiAccordionSummary-content': {
        margin: 0
      }
    }
    // '&.MuiAccordionSummary-content': {
    //   justifyContent: 'space-between'
    // }
  }
});
