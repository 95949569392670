import React from 'react';

export const Local_servicesPetSittersMinders = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path d='M15 2V5L14.084 6.83203L3.37109 2.11719L2.56445 3.94727L13.1875 8.62305L13 9H7.40039L6.09961 7.69922C5.71261 7.31222 5.08622 7.31222 4.69922 7.69922C4.31222 8.08622 4.31222 8.71261 4.69922 9.09961L6 10.4004V12.2227L4 13L1.5918 17.625C1.3398 18.108 1.50761 18.7044 1.97461 18.9844C2.46261 19.2774 3.09472 19.1189 3.38672 18.6309L4.96289 16.0059L8.31641 15.1699L12.2188 15.6543L13.6426 21.1953C13.7646 21.6683 14.1907 22 14.6797 22H14.6875C15.3625 22 15.8693 21.3847 15.7383 20.7227L15 17L15.1953 15.832L18 13.4141V9H18.7598C19.5498 9 20.3209 8.67914 20.8809 8.11914L22 7V5.5C22 5.22 21.78 5 21.5 5H20L19.2891 4.28906C19.0991 4.10906 18.8498 4 18.5898 4H17.5L16.5996 2.80078C16.2196 2.30078 15.63 2 15 2ZM19.5703 14.6699L18.0898 16.1602L20.4668 17.3496C21.0198 17.6266 21.6871 17.3149 21.8301 16.7129C21.9311 16.2919 21.7374 15.8564 21.3574 15.6484L19.5703 14.6699ZM7.75977 17.1094L7.04297 20.7227C6.91197 21.3837 7.41875 22 8.09375 22H8.09961C8.58861 22 9.01472 21.6683 9.13672 21.1953L10.1191 17.3691L7.75977 17.1094Z' />
      </g>
    </svg>
  );
};
