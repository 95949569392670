import React from 'react';
import moment from 'moment';

import { Avatar } from '@packs/components/UI';
import { divideStarRating } from '@packs/lib/helpers';
import { FilesPreview } from '@shared/components/QA/FilesPreview';
import { RatingMUI } from '@shared/components/RatingMUI/RatingMUI';

export const QuoteRequestsTable = ({ quoteRequests }) => {
  return (
    <div className='quote-requests-table-scroll'>
      <table className='quote-requests-table'>
        <thead>
          <tr>
            <th>Company:</th>
            <th>Rating:</th>
            <th className='text-center'>Attachments:</th>
            <th>Create Date:</th>
          </tr>
        </thead>
        <tbody>
          {quoteRequests.map(item => (
            <tr key={item?.id}>
              <td>
                <div className='d-flex align-items-center'>
                  <Avatar avatarUrl={item?.quoteAgent?.company?.avatarUrl} fullSize withBG />
                  <div className='ml-8'>{item?.quoteAgent?.company?.name}</div>
                </div>
              </td>
              <td>
                <RatingMUI value={divideStarRating(item?.quoteAgent?.company?.additionalData?.rating) || 0} />
              </td>
              <td>
                <div className='quote-requests-files-list d-flex align-items-center justify-content-center'>
                  {item?.files.length > 0 ? (
                    <>
                      <FilesPreview files={item?.files} />
                    </>
                  ) : (
                    '-'
                  )}
                </div>
              </td>
              <td>{item?.createdAt ? moment(item.createdAt).fromNow() : '-'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
