import React, { useEffect, useState } from 'react';
import { size } from 'lodash-es';

import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import ArrowIcon from '@packs/images/icons/CategoryArrow';
import PlusCirclePrimaryIcon from '@packs/images/svg/ui/PlusCirclePrimaryIcon.svg';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { useStore } from '@packs/stores';

import { ReminderItem } from './components/ReminderItem';

import { observer } from 'mobx-react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

export const BusinessReminder = observer(({ renewalDetail, category, company }) => {
  const { smartRemindersStore } = useStore();
  const { currentUser } = useUserSelectors();
  const { toggleReminderFormModal } = useModalsContext();
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);

  const reminders = smartRemindersStore?.businessSmartReminders;

  const toggleDropDown = () => {
    setIsOpenDropDown(!isOpenDropDown);
  };

  const firstReminder = size(reminders) > 0 ? reminders[0] : null;

  const onShowReminder = () => {
    toggleReminderFormModal({
      renewalDetail,
      category: null,
      company,
      currentCategory: null,
      shouldShowDetails: true,
      isCreateBusinessReminder: true
    });
  };

  const fetchReminders = () => {
    smartRemindersStore.fetchBusinessSmartReminders({ companyId: company?.id, userId: currentUser?.id });
  };

  useEffect(() => {
    if (company?.id && currentUser?.id) {
      fetchReminders();
    }
  }, [company?.id, currentUser?.id]);

  return (
    <div>
      <div className='d-flex align-items-center'>
        <div
          className='add-reminder-btn d-flex align-items-center gap-4 fs-11 fw-600 tt-uc primary-text cursor-pointer mb-4'
          onClick={onShowReminder}
        >
          <span>add reminder</span>
          <img src={PlusCirclePrimaryIcon} alt='plus' />
        </div>
        {size(reminders) > 1 && (
          <Dropdown className='dropdown-ui bottom-left ml-30' isOpen={isOpenDropDown} toggle={toggleDropDown}>
            <DropdownToggle tag='div'>
              <div className='d-flex align-items-center gap-4 cursor-pointer'>
                <span className='fs-11 fw-500 secondary-text'>({size(reminders)})</span>
                <span className='arrow'>
                  <ArrowIcon />
                </span>
              </div>
            </DropdownToggle>
            <DropdownMenu className='dropdown-menu-ui reminders-dropdown-menu' right>
              {reminders.map(reminder => (
                <ReminderItem
                  key={reminder.id}
                  {...{
                    reminder,
                    category,
                    company
                  }}
                />
              ))}
            </DropdownMenu>
          </Dropdown>
        )}
      </div>
      {firstReminder && <ReminderItem reminder={firstReminder} {...{ category, company }} />}
    </div>
  );
});
