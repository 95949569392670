import React from 'react';
import cx from 'classnames';

import { useDetailsForm } from '@packs/screens/PersonalizeResults/DetailsFormProvider';

import { DebugName } from './debug';

export const InputPercentageField = props => {
  const { getRef, onChange, onBlur, value } = useDetailsForm();
  const { name, type, question } = props;
  const placeholder = props.placeholder || (question && question.placeholder) || '';
  const currencySymbol = props.currencySymbol || (question && question.currencySymbol) || false;
  const inputPlaceholder = currencySymbol ? 'Price...' : placeholder;

  const handleOnChange = data => {
    if (data.target.value <= 100) {
      onChange(data);
    }
  };

  if (!name) {
    throw new Error('no name');
  }

  return (
    <div className={cx('field field-input', { withIcon: currencySymbol })}>
      <DebugName name={name} />
      <label>
        <input
          ref={getRef}
          name={name}
          type='number'
          pattern='^([0-9]*[,])?[0-9]*$'
          max='100'
          placeholder={inputPlaceholder}
          onChange={handleOnChange}
          onBlur={onBlur}
        />
        {currencySymbol && <span className='currency'>{currencySymbol}</span>}
      </label>
    </div>
  );
};
