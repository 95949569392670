import React from 'react';
import _ from 'lodash-es';
import cx from 'classnames';

import { EventItem } from '@packs/components/Calendar/components/EventItem';
import { useStore } from '@packs/stores';

export const ReminderList = ({ reminders, onCalendarDeleteReminder, onCalendarEditReminder }) => {
  const { countryCode } = useStore();

  return (
    <div className='your-reminders-list'>
      {_.map(reminders, event => {
        return (
          <div
            className={cx('your-reminders-item', event.status, _.get(event, 'category.root.enName', ''))}
            key={event.id}
          >
            <EventItem
              countryCode={countryCode}
              onCalendarDeleteReminder={onCalendarDeleteReminder}
              onCalendarEditReminder={onCalendarEditReminder}
              {...{ event }}
            />
          </div>
        );
      })}
    </div>
  );
};
