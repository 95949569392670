import { FC, ReactElement } from 'react';

import { Box, Typography, TypographyProps } from '@mui/material';
import EmptyIcn from '@shared/images/company/leaderboard-empty.svg';

import useStyles from './styles';

type EmptyPlaceholderProps = {
  title?: string;
  description?: string;
  children?: ReactElement;
};
export const EmptyPlaceholder: FC<EmptyPlaceholderProps> = ({ title = 'No results found.', description, children }) => {
  const styles = useStyles();

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.imgWrapper}>
        <img src={EmptyIcn} alt='Empty' />
      </Box>
      {title && (
        <Typography variant='body2' sx={styles.title}>
          {title}
        </Typography>
      )}
      {description && (
        <Typography variant={'body3' as TypographyProps['variant']} sx={styles.description}>
          {description}
        </Typography>
      )}
      {children}
    </Box>
  );
};
