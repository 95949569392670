import _ from 'lodash-es';
import moment from 'moment';

import { MONTH_FORMAT } from './defaults';

export const isCurrentMonth = momentValue =>
  momentValue.month() === moment().month() && momentValue.year() === moment().year();

export const selectOptions = value => {
  const momentValue = value.constructor.name === 'Moment' ? value : moment(value, MONTH_FORMAT);

  const limitDate = moment().add(4, 'year').endOf('year').startOf('day');
  const monthDatesArray = [];

  const initMonth = moment().startOf('month');

  if (momentValue < initMonth) {
    monthDatesArray.push(momentValue);
  }

  let inx = 0;
  while (initMonth < limitDate) {
    initMonth.startOf('month').add(1, 'month');
    monthDatesArray.push(moment().startOf('month').add(inx, 'month'));
    inx++;
  }

  const yearMonthOptions = monthDatesArray
    .map(m => m.format(MONTH_FORMAT))
    .map(v => {
      const nextYear = moment(v).year() > moment().year() && moment(v).year() < moment().add(2, 'year').year();

      if (nextYear && moment(v).month() === moment().month()) {
        return {
          value: v,
          label: v,
          startOption: true
        };
      }
      return { value: v, label: v };
    });

  const daysArray = _.times(momentValue.daysInMonth()).map(d => d + 1);
  let daysArraySlice = isCurrentMonth(momentValue) ? daysArray.slice(moment().date()) : daysArray;

  if (!daysArraySlice.includes(momentValue.date())) {
    daysArraySlice = [momentValue.date(), ...daysArraySlice];
  }

  const dayOptions = daysArraySlice.map(d => `${d}`).map(v => ({ value: v, label: v }));

  return { yearMonth: yearMonthOptions, day: dayOptions };
};

export const getMonthOption = value => {
  return moment(value).startOf('month').format(MONTH_FORMAT);
};

export const getDayOption = value => {
  return moment(value).startOf('day').format('D');
};
