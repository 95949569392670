import React from 'react';

import Button from '@mui/material/Button';
import { pathGen } from '@packs/layouts/constants';
import { handleVisitCompany } from '@packs/lib/helpers/additionalHelpers';
import { useStore } from '@packs/stores';

import { Link } from 'react-router-dom';

export const VoteButton = ({ company }) => {
  const { currentCategory, countryCode } = useStore();

  const onVisitCompanyPage = () => {
    handleVisitCompany(company, currentCategory);
  };

  return (
    <Button
      component={Link}
      to={pathGen('PRODUCT', {
        countryCode,
        category: currentCategory,
        company
      }).concat('?voteModalIsOpen=true')}
      variant='filledTonal'
      color='primary'
      size='small'
      onClick={onVisitCompanyPage}
    >
      Write a review!
    </Button>
  );
};
