import { companiesFragment } from './fetchCompaniesList';

export const fetchCategoryAgents = `
  query categoryAgents(
    $categoryId: Int,
    $companyType: String,
    $city: String,
    $criteriaQuestions: JSON,
    $page: Int,
    $perPage: Int
  ) {
    categoryAgents(
      categoryId: $categoryId,
      city: $city,
      companyType: $companyType,
      criteriaQuestions: $criteriaQuestions,
      page: $page,
      perPage: $perPage
    ) {
    ${companiesFragment}
    errors
  }
}
`.trim();
