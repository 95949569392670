import React from 'react';

const SendMessageIcon = () => {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M27.1753 3.53912C27.0506 3.01693 26.4768 2.66162 25.9565 2.8463C25.9565 2.8463 3.41807 10.801 3.41432 10.8038C3.06838 10.9322 2.81432 11.2622 2.79557 11.6401C2.7787 12.0029 3.00276 12.3807 3.33369 12.5344L12.0637 16.6097C12.0637 16.6097 16.3171 12.3563 16.3246 12.3488C17.1684 11.5051 18.5156 12.8101 17.6503 13.6744L13.3893 17.9354C13.3893 17.9354 17.459 26.656 17.4665 26.6701C17.6324 26.9888 17.9568 27.1913 18.3131 27.2072C18.7003 27.2251 19.0584 26.9288 19.1971 26.5819C19.1971 26.5819 27.1471 4.05662 27.1518 4.04255C27.2109 3.88037 27.2146 3.70505 27.1753 3.53912Z' />
    </svg>
  );
};

export default SendMessageIcon;
