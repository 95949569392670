import React from 'react';

import { getCurrency } from '@packs/lib/constants';
import { TrackUnitPrices } from '@packs/screens/PersonalizeResults/formFields/TrackUnitPrices';

import { DebugName } from './debug';
import { InputField } from './InputField';
import { RadioField } from './RadioField';

export const PriceCriteriaPerPeriod = props => {
  const {
    name,
    question: { options },
    countryCode,
    category
  } = props;

  const energyCategory = category?.root?.code === 'energy';

  return (
    <div className='w-100'>
      <DebugName name={name} />
      <div>
        <InputField name={`${name}.value`} type='text' currencySymbol={getCurrency(countryCode)} />
      </div>
      <div className='price-criteria-period-list'>
        <RadioField name={`${name}.period`} options={options} />
      </div>
      {energyCategory && <TrackUnitPrices {...props} />}
    </div>
  );
};
