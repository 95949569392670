import React from 'react';
import moment from 'moment';

import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

const DateField = ({ input, className, name, error, touched, disabled, controlError }) => {
  const spanError = err => err && <span className='error-hint'>{err}</span>;

  return (
    <>
      <DatePicker
        selected={input.value || new Date()}
        dateFormat='MMMM d, yyyy'
        minDate={moment().add(1, 'day').toDate()}
        onChange={input.onChange}
        {...{ className, name, disabled }}
      />

      {controlError === undefined ? touched && spanError(error) : spanError(controlError)}
    </>
  );
};

DateField.propTypes = {
  input: PropTypes.object.isRequired,
  className: PropTypes.string,
  controlError: PropTypes.string
};

export default DateField;
