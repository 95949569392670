import React, { useEffect, useMemo, useState } from 'react';
import { get } from 'lodash-es';

import { useStore } from '@packs/stores';

import { CommentItem } from '../CommentItem';

import { observer } from 'mobx-react';

export const CommentsList = observer(({ postId, isOpen, questionsContainerOffset }) => {
  const { insightsCompanyStore } = useStore();
  const [loading, setLoading] = useState(false);

  const answers = useMemo(() => {
    return get(insightsCompanyStore.answers, `${postId}`, []);
  }, [insightsCompanyStore.answers]);

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      insightsCompanyStore.loadAnswersInsight({ renewalDetailId: +postId }).finally(() => {
        setLoading(false);
      });
    }
  }, [isOpen]);

  if (loading) {
    return <div>Loading</div>;
  }

  return (
    <div className='comments-list-wrapper answers-list'>
      {answers.map(answer => (
        <CommentItem
          key={answer?.id}
          postId={postId}
          comment={answer}
          questionsContainerOffset={questionsContainerOffset}
        />
      ))}
    </div>
  );
});
