import React from 'react';

import { divideStarRating } from '@packs/lib/helpers';
import { RatingMUI } from '@shared/components/RatingMUI/RatingMUI';

const StarRateField = ({
  input: { value, onChange },
  disabled = false,
  half = true,
  controlError,
  touched,
  size = 50,
  count = 5
}) => {
  const onClickHandler = i => {
    if (disabled) return;
    onChange(i * 2);
  };

  const spanError = controlError && touched && <span className='error-hint'>{controlError}</span>;

  return (
    <div className='rate-block-wrapper'>
      <div className='d-flex align-items-center rate-wrapper'>
        <RatingMUI
          value={divideStarRating(value) || 0}
          max={count}
          readOnly={disabled}
          onChange={onClickHandler}
          half={half}
          size={size >= 50 ? 'large' : 'medium'}
        />
        <span className='rating-value'>{`${divideStarRating(value) || 0}/${count}`}</span>
      </div>
      {spanError && <div className='rate-block-errors'>{spanError}</div>}
    </div>
  );
};

export default StarRateField;
