import React, { useEffect } from 'react';
import { omit, size } from 'lodash-es';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArrowBack from '@packs/images/icons/ArrowBack';
import { autoQuoteTooltip, autoQuoteTooltipNoPolicy } from '@packs/lib/constants';
import { toastr } from '@packs/lib/helpers';
import { recurringOptions } from '@packs/models/company/constants';
import { ActionPanel } from '@packs/screens/PersonalizeResults/components/ActionPanel/ActionPanel';
import { PersonalizeModalHeader } from '@packs/screens/PersonalizeResults/components/PersonalizeModalHeader';
import { useDetailsForm } from '@packs/screens/PersonalizeResults/DetailsFormProvider';
import { FieldContainer } from '@packs/screens/PersonalizeResults/FieldContainer';
import { PhoneInputField } from '@packs/screens/PersonalizeResults/formFields/PhoneInputField';
import { SelectField } from '@packs/screens/PersonalizeResults/formFields/SelectField';
import { SwitcherField } from '@packs/screens/PersonalizeResults/formFields/SwitcherField';
import { onOpenMonth, scrollToTopPersonalize } from '@packs/screens/PersonalizeResults/helpers';
import { TooltipMUI } from '@shared/components/TooltipMUI/TooltipMUI';

import dayjs from 'dayjs';
import AnimateHeight from 'react-animate-height';

export const SetReminder = props => {
  const { step, SaveBtn, setStep, category, formValues, simpleForm, percentage, inModalFrame, handleCloseModal } =
    props;
  const { onChange } = useDetailsForm();

  const dateInTheFuture = dayjs(formValues?.triggeredAt).isBefore(dayjs());
  const disabled = size(omit({ ...(formValues?.criteriaQuestions || {}) }, 'location')) === 0;

  const handleChange = (name, value) => {
    onChange({ target: { name, value } });
  };

  const handlePrevQuestion = () => {
    setStep(step - 1);
  };

  const notifyDateInTheFuture = () => {
    toastr.error('Smart reminder date should be in the future');
    return null;
  };

  const onSaveReminder = e => {
    if (dateInTheFuture && formValues?.addSmartReminder) {
      return notifyDateInTheFuture();
    }

    handleChange('addSmartReminder', true);
  };

  const onNotSaveReminder = () => {
    if (dateInTheFuture) {
      return notifyDateInTheFuture();
    }

    handleChange('addSmartReminder', false);
  };

  useEffect(() => {
    scrollToTopPersonalize();
  }, []);

  return (
    <>
      <div className='policy-form reminder-form'>
        {inModalFrame && <ActionPanel handleBack={handlePrevQuestion} />}
        {simpleForm && !inModalFrame && (
          <div className='policy-progress d-flex flex-column'>
            <div className='d-flex align-items-center justify-content-between'>
              <div className='img-container'>
                <div className='d-flex align-items-center back' onClick={() => setStep(1)}>
                  <ArrowBack />
                  Back
                </div>
              </div>
            </div>
            <div className='policy-progress-wrapper'>
              <div className='policy-progress-indicator' />
            </div>
          </div>
        )}
        <FieldContainer className='active'>
          <div className='reminder-form-title d-flex align-items-center justify-content-between mb-16'>
            <Typography
              variant={inModalFrame ? 'h3' : 'h6'}
              sx={{ textAlign: inModalFrame ? 'center' : 'left' }}
              className='title mr-15 mb-0'
            >
              Do you wish to set a renewal reminder?
            </Typography>
            {!inModalFrame && (
              <SwitcherField textOff='off' textOn='on' name='addSmartReminder' value={formValues?.addSmartReminder} />
            )}
          </div>
          <div className='field-types-list isSelectType large d-flex'>
            <SelectField
              name='triggeredAtDate.month'
              placeholder='Renewal Month'
              title='Renewal Month'
              formatOptionLabel={({ value: code, label, startOption }) => (
                <div>
                  {startOption && <span className='scroll-to-first' />}
                  {label}
                </div>
              )}
              disabled={!formValues?.addSmartReminder}
              onOpen={onOpenMonth}
              value={formValues?.triggeredAtDate?.month}
            />
            <SelectField
              name='triggeredAtDate.day'
              title='Renewal Date'
              placeholder='Renewal Date'
              disabled={!formValues?.addSmartReminder}
              value={formValues?.triggeredAtDate?.day}
            />
            <SelectField
              name='recurringTime'
              title='Recurring period for reminder'
              placeholder='Select period'
              options={recurringOptions}
              disabled={!formValues?.addSmartReminder}
              value={formValues?.recurringTime}
            />
          </div>

          <AnimateHeight duration={250} height={formValues?.addSmartReminder ? 'auto' : 0}>
            <Box>
              {disabled && (
                <Alert severity='warning' sx={{ mt: 4, alignItems: 'flex-start' }}>
                  <Typography variant='body1'>
                    Your Auto. quota is unavailable. To make the function available, fill in all your personal details
                    for this category. In section "Criteria".
                  </Typography>
                </Alert>
              )}
              <Box sx={{ mt: 2, width: '100%', maxWidth: '360px' }}>
                <Box sx={{ display: 'flex' }}>
                  <TooltipMUI
                    title={
                      <Typography variant='caption'>
                        {!disabled ? autoQuoteTooltip : autoQuoteTooltipNoPolicy}
                      </Typography>
                    }
                    placement='top'
                    arrow
                  >
                    <Box sx={{ whiteSpace: 'nowrap' }}>
                      <SwitcherField
                        label='Auto. quote'
                        name='isAutoQuote'
                        value={formValues?.isAutoQuote}
                        disabled={disabled}
                      />
                    </Box>
                  </TooltipMUI>
                </Box>

                <AnimateHeight duration={250} height={formValues?.isAutoQuote ? 'auto' : 0}>
                  <div className='mt-16'>
                    <PhoneInputField
                      value={formValues?.phoneNumber}
                      name='phoneNumber'
                      placeholder='Phone (ex. +353 123...)'
                    />
                  </div>
                </AnimateHeight>
              </Box>
            </Box>
          </AnimateHeight>

          {simpleForm && (
            <div className='reminder-btn-container d-flex align-items-center justify-content-between'>
              <SaveBtn className='btn empty black' text='No thanks!' onClick={onNotSaveReminder} />
              <SaveBtn className='btn full black' text='Yes Please!' onClick={onSaveReminder} />
            </div>
          )}
        </FieldContainer>
      </div>
      {inModalFrame && (
        <PersonalizeModalHeader
          {...{
            category,
            handleCloseModal,
            percentage
          }}
          handleBack={handlePrevQuestion}
        />
      )}
    </>
  );
};
