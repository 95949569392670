import React from 'react';

export const TravelCarRental = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path d='M7.99997 6.99999C9.29997 6.99999 10.41 6.15999 10.83 4.99999H16V5.99999C16 6.54999 16.45 6.99999 17 6.99999C17.55 6.99999 18 6.54999 18 5.99999V4.99999C18.55 4.99999 19 4.54999 19 3.99999C19 3.44999 18.55 2.99999 18 2.99999H10.83C10.35 1.64999 8.94997 0.759986 7.39997 1.05999C6.22997 1.28999 5.27997 2.24999 5.04997 3.41999C4.69997 5.31999 6.14997 6.99999 7.99997 6.99999ZM7.99997 2.99999C8.54997 2.99999 8.99997 3.44999 8.99997 3.99999C8.99997 4.54999 8.54997 4.99999 7.99997 4.99999C7.44997 4.99999 6.99997 4.54999 6.99997 3.99999C6.99997 3.44999 7.44997 2.99999 7.99997 2.99999ZM16.39 8.99999H7.60997C7.17997 8.99999 6.79997 9.27999 6.65997 9.67999L4.99997 14.69V21C4.99997 21.55 5.44997 22 5.99997 22C6.54997 22 6.99997 21.55 6.99997 21V20H17V21C17 21.55 17.45 22 18 22C18.55 22 19 21.55 19 21V14.69L17.34 9.67999C17.2 9.27999 16.82 8.99999 16.39 8.99999ZM8.99997 17.5C8.44997 17.5 7.99997 17.05 7.99997 16.5C7.99997 15.95 8.44997 15.5 8.99997 15.5C9.54997 15.5 9.99997 15.95 9.99997 16.5C9.99997 17.05 9.54997 17.5 8.99997 17.5ZM15 17.5C14.45 17.5 14 17.05 14 16.5C14 15.95 14.45 15.5 15 15.5C15.55 15.5 16 15.95 16 16.5C16 17.05 15.55 17.5 15 17.5ZM7.66997 13L8.32997 11H15.67L16.33 13H7.66997Z' />
      </g>
    </svg>
  );
};
