import React from 'react';

export default () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='19' height='24' viewBox='0 0 19 24'>
      <g fill='#11998E' fillRule='nonzero'>
        <path d='M14.448 8.8H4.552a.398.398 0 0 0-.396.4c0 .221.178.4.396.4h9.896a.398.398 0 0 0 .396-.4c0-.221-.178-.4-.396-.4zM4.552 6.4H8.51a.398.398 0 0 0 .396-.4c0-.221-.177-.4-.396-.4H4.552a.398.398 0 0 0-.396.4c0 .221.178.4.396.4zM14.448 12H4.552a.398.398 0 0 0-.396.4c0 .221.178.4.396.4h9.896a.398.398 0 0 0 .396-.4c0-.221-.178-.4-.396-.4zM14.448 15.2H4.552a.398.398 0 0 0-.396.4c0 .221.178.4.396.4h9.896a.398.398 0 0 0 .396-.4c0-.221-.178-.4-.396-.4zM14.448 18.4H4.552a.398.398 0 0 0-.396.4c0 .221.178.4.396.4h9.896a.398.398 0 0 0 .396-.4c0-.221-.178-.4-.396-.4z' />
        <path d='M13.028 0H.198v24h18.604V5.834L13.028 0zm.232 1.366L17.45 5.6h-4.19V1.366zM.99 23.2V.8h11.479v5.6h5.541v16.8H.99z' />
      </g>
    </svg>
  );
};
