import React from 'react';

export default () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.9975 2H12.0025C17.5162 2 22 6.48625 22 12C22 17.5138 17.5162 22 12.0025 22C9.96875 22 8.0825 21.395 6.50125 20.3475L2.6575 21.5763L3.90375 17.8612C2.705 16.215 2 14.1875 2 12C2 6.485 6.48375 2 11.9975 2ZM15.8588 17.5325C16.6226 17.3675 17.5801 16.8025 17.8213 16.1213C18.0626 15.4388 18.0626 14.8575 17.9926 14.7338C17.936 14.6356 17.8024 14.5712 17.6029 14.4752C17.5509 14.4502 17.4945 14.423 17.4338 14.3925C17.1401 14.2463 15.7113 13.54 15.4413 13.4463C15.1763 13.3463 14.9238 13.3813 14.7238 13.6638C14.6858 13.717 14.6478 13.7704 14.6101 13.8235C14.3688 14.1628 14.1361 14.4902 13.9426 14.6988C13.7663 14.8863 13.4788 14.91 13.2376 14.81C13.2113 14.7991 13.1811 14.7869 13.1473 14.7732C12.7654 14.619 11.9181 14.2768 10.8926 13.3638C10.0276 12.5938 9.44006 11.635 9.27006 11.3475C9.10315 11.0591 9.24712 10.89 9.38018 10.7337C9.38265 10.7308 9.38511 10.7279 9.38756 10.725C9.47335 10.6189 9.5566 10.5305 9.64027 10.4417C9.70045 10.3778 9.76085 10.3136 9.82256 10.2425C9.83173 10.232 9.84064 10.2217 9.84932 10.2117C9.98036 10.0611 10.0584 9.97134 10.1463 9.78377C10.2463 9.59002 10.1751 9.39002 10.1038 9.24252C10.0548 9.13922 9.75197 8.40448 9.492 7.77374C9.38076 7.50383 9.27736 7.25296 9.20506 7.07877C9.01131 6.61502 8.86381 6.59752 8.57006 6.58502C8.56107 6.58457 8.55198 6.58411 8.5428 6.58365C8.44995 6.57895 8.34768 6.57377 8.23506 6.57377C7.85256 6.57377 7.45381 6.68627 7.21256 6.93252C7.20413 6.94113 7.19535 6.95007 7.18623 6.95934C6.87759 7.27323 6.19006 7.97245 6.19006 9.36627C6.19006 10.7628 7.18095 12.1143 7.36428 12.3644C7.36934 12.3713 7.37378 12.3773 7.37756 12.3825C7.3886 12.397 7.40939 12.4271 7.43965 12.4709C7.814 13.0131 9.63735 15.6536 12.3563 16.78C14.6538 17.7325 15.3363 17.6438 15.8588 17.5325Z'
      />
    </svg>
  );
};
