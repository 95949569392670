import React from 'react';

export const BroadbandBroadbandTv = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <path d='M3 3C1.895 3 1 3.895 1 5V17C1 18.105 1.895 19 3 19H8V20C8 20.552 8.448 21 9 21H15C15.552 21 16 20.552 16 20V19H21C22.105 19 23 18.105 23 17V5C23 3.895 22.105 3 21 3H3ZM4 5H20C20.552 5 21 5.448 21 6V16C21 16.552 20.552 17 20 17H4C3.448 17 3 16.552 3 16V6C3 5.448 3.448 5 4 5Z' />
      <path d='M8.16228 10.2225C8.35912 10.4248 8.67176 10.4446 8.88404 10.2621C10.6865 8.73884 13.3034 8.73884 15.1098 10.2582C15.3259 10.4407 15.6424 10.4248 15.8393 10.2225C16.067 9.98843 16.0515 9.59967 15.8007 9.38942C13.5968 7.53686 10.4086 7.53686 8.20087 9.38942C7.94999 9.5957 7.93069 9.98446 8.16228 10.2225ZM11.1574 13.3008L11.7248 13.884C11.8753 14.0387 12.1185 14.0387 12.269 13.884L12.8364 13.3008C13.0178 13.1144 12.9792 12.7931 12.7476 12.6701C12.2767 12.4202 11.7132 12.4202 11.2385 12.6701C11.0185 12.7931 10.976 13.1144 11.1574 13.3008ZM9.71774 11.8212C9.90687 12.0155 10.2041 12.0354 10.4241 11.8727C11.3658 11.1864 12.6318 11.1864 13.5736 11.8727C13.7936 12.0314 14.0908 12.0155 14.2799 11.8212L14.2838 11.8172C14.5154 11.5792 14.4999 11.1745 14.2336 10.9802C12.9059 9.9924 11.0957 9.9924 9.76406 10.9802C9.49774 11.1785 9.4823 11.5792 9.71774 11.8212Z' />
    </svg>
  );
};
