import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { find, get } from 'lodash-es';
import classNames from 'classnames';

import { Button, Grid, Stack, Typography } from '@mui/material';
import { Loader } from '@packs/components';
import { LAST_BUY_OPTIONS } from '@packs/models/policy/constants';
import { ActionPanel } from '@packs/screens/PersonalizeResults/components/ActionPanel/ActionPanel';
import { PersonalizeModalHeader } from '@packs/screens/PersonalizeResults/components/PersonalizeModalHeader';
import { useDetailsForm } from '@packs/screens/PersonalizeResults/DetailsFormProvider';
import { FieldContainer } from '@packs/screens/PersonalizeResults/FieldContainer';
import { RadioField } from '@packs/screens/PersonalizeResults/formFields/RadioField';
import { SkipButton } from '@packs/screens/PersonalizeResults/formFields/SkipButton';
import { isValid } from '@packs/screens/PersonalizeResults/formHooks/validate';
import { useStore } from '@packs/stores/base';

import { SelectedCompany } from './components/SelectedCompany';
import { CompanyAnySelect, CompanyProviderSelect, RateField } from './components';

import lowerCase from 'lodash-es/lowerCase';
import queryString from 'query-string';

export const cantRemember = {
  id: 'i_cant_remember',
  label: "I can't remember",
  name: "I can't remember",
  value: 'i_cant_remember'
};

const useRefObject = () => {
  const ref = useRef();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (ref.current) setReady(true);
  }, [ref.current]);

  return [ref, ready];
};

export const MyProvider = ({
  step,
  setStep,
  remount,
  category,
  localData,
  showFields,
  percentage,
  disabledFields,
  simpleForm = false,
  inModalFrame = false,
  handleCloseModal = false,
  location
}) => {
  const { skipBusinessStep } = queryString.parse(location?.search);
  const { detailsFormStore, policiesListStore, countryCode } = useStore();
  const [tabStep, setTabStep] = useState(0);
  const [providerFormScroll, setProviderScroll] = useState(0);
  const [providerStep, setProviderStep] = useState(1);
  const [tabChanged, setTabChanged] = useState(false);
  const [prevStep, setPrevStep] = useState('');
  const [initialStepMount, setInitialStepMount] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [useForm] = useRefObject();

  const { formErrors, formValues, prevChangedField, changedField } = detailsFormStore;

  const { onChange } = useDetailsForm();

  const getUseForm = useForm?.current;
  const questionsCount = getUseForm?.children.length || 0;
  const formWidth = getUseForm?.clientWidth;
  const getAtt = getUseForm?.children[providerStep - 1]?.getAttribute('data-parent');
  const disabled = simpleForm && formErrors[getAtt];

  const showButton = get(formValues, `${getAtt}`, false);
  const showNextSkipButton =
    (showFields.broker || showFields.comparison_site) && providerStep === 3 && !formValues.anyCompanyId;
  const showNextSkipProviderOptional =
    (showFields.broker || showFields.comparison_site) && providerStep === 3 && !formValues.sourceProviderCompanyId;

  const isContractCategories = ['mobile', 'energy', 'broadband', 'banking'].includes(category?.root?.code);
  const { mixOptions, brokerOptions, comparisonSiteOptions, providersOptions } = policiesListStore;

  const anyCompanyTitle = useMemo(() => {
    let title = 'Where did you purchase your current ';

    if (isContractCategories) {
      title = title + 'contract';
    } else {
      title = title + 'policy';
    }

    return title + '?';
  }, [category?.id]);

  const [targetScroll, setTargetScroll] = useState(0);

  useLayoutEffect(() => {
    setTimeout(() => {
      getUseForm?.scroll(targetScroll, 0);
    }, 80); // wait 1+ for active class appends
  }, [targetScroll]);

  const handleNextQuestion = () => {
    if (simpleForm && providerStep < questionsCount) {
      const calcScroll = providerFormScroll + formWidth;

      setPrevStep(getAtt);
      setProviderScroll(calcScroll);
      setProviderStep(providerStep + 1);

      setTargetScroll(calcScroll);
    }
  };

  const handlePrevQuestion = () => {
    if (simpleForm && providerStep >= 2) {
      const calcScroll = providerFormScroll - formWidth;

      setProviderScroll(calcScroll);
      setProviderStep(providerStep - 1);

      setTargetScroll(calcScroll);
    }
  };

  const checkScrollPosition = () => {
    setTargetScroll(providerFormScroll);
  };

  const updateFieldValue = (name, value) => {
    onChange({ target: { name, value } });
  };

  const initialState = () => {
    setProviderScroll(0);
    setProviderStep(1);

    setTargetScroll(0);
  };

  useEffect(() => {
    initialState();
    detailsFormStore.setChanged({ name: null, value: null });

    return initialState();
  }, []);

  useEffect(() => {
    if (simpleForm) {
      isValid(localData, simpleForm);

      if (formValues?.anyCompanyId && !initialStepMount && skipBusinessStep === 'true') {
        setIsLoading(true);
        setInitialStepMount(true);

        const calcScroll = providerFormScroll + formWidth * 3;

        setPrevStep(getAtt);
        setProviderScroll(calcScroll);
        setProviderStep(3);

        setTargetScroll(calcScroll);

        setIsLoading(false);
      }
    }
  }, [getAtt, formValues, tabStep]);

  const onClickDontHaveBusiness = () => {
    updateFieldValue('anyCompanyId', 'i_cant_remember');
    handleNextQuestion();
    setStep(step + 1);
  };

  const onClickDontHaveProvider = () => {
    updateFieldValue('sourceProviderCompanyId', 'i_cant_remember');
    handleNextQuestion();
  };
  const onClickNextSkipButton = () => onClickDontHaveProvider();

  const handleFieldChange = _val => {
    handleNextQuestion();
  };

  const ButtonsGroup = () => {
    if (!simpleForm) {
      return null;
    }

    const onClickNextButton = () => {
      if (
        ((formValues.sourceProviderCompanyId && formValues.sourceProviderCompanyId !== 'i_cant_remember') ||
          (formValues.anyCompanyId && formValues.anyCompanyId !== 'i_cant_remember')) &&
        showFields.anyCompanyRate &&
        !formValues.anyCompanyRate
      ) {
        return null;
      }

      if (!disabled || !showButton) {
        if (providerStep === questionsCount) {
          setStep(step + 1);
        } else {
          handleNextQuestion();
        }
      }
    };

    return (
      <>
        {showNextSkipProviderOptional && !(showButton || providerStep === questionsCount) && (
          <div className='policy-steps-controller d-flex align-items-center'>
            <Button variant='contained' color='primary' disabled={!showButton} onClick={onClickNextSkipButton}>
              <span>NEXT / SKIP</span>
            </Button>
          </div>
        )}
        {showNextSkipButton && (
          <div className='policy-steps-controller d-flex align-items-center'>
            <SkipButton name='buyingType' btnText='NEXT / SKIP' value='other' />
          </div>
        )}
        {(showButton || providerStep === questionsCount) && (
          <div className='policy-steps-controller d-flex align-items-center'>
            <Button variant='contained' color='primary' disabled={disabled || !showButton} onClick={onClickNextButton}>
              <span>Next</span>
            </Button>
          </div>
        )}
      </>
    );
  };

  const insuranceCategory = category?.root?.code === 'insurance';
  const anyCompany = useMemo(() => {
    return find([...brokerOptions, ...comparisonSiteOptions, ...(insuranceCategory ? mixOptions : providersOptions)], {
      id: formValues.anyCompanyId || formValues.sourceProviderCompanyId
    });
  }, [
    brokerOptions,
    comparisonSiteOptions,
    insuranceCategory,
    formValues.anyCompanyId,
    formValues.sourceProviderCompanyId
  ]);

  const providerCompany = useMemo(() => {
    return find([...(insuranceCategory ? mixOptions : providersOptions)], { id: formValues.sourceProviderCompanyId });
  }, [insuranceCategory, formValues.sourceProviderCompanyId, mixOptions?.length]);

  const policyQuestionTitle = useMemo(() => {
    const titleStart = 'Do you currently have';

    // sub-categories
    if (category?.code === 'car_home_insurance') return `${titleStart} Car & Home Bundled Insurance`;
    if (category?.code === 'mobile_contract') return 'Do you currently have a bill pay service?';
    if (category?.code === 'mobile_pre_pay') return 'Are you currently on pre a prepay service?';
    if (category?.code === 'mobile_subcat') return 'Are you currently on a sim only service?';
    if (category?.code === 'credit_cards') return 'Do you currently have a credit card?';
    if (category?.code === 'accounts') return 'Do you currently have a current account?';
    if (category?.code === 'income_protection') return 'Do you currently have an income protection policy?';
    if (category?.code === 'loans') return 'Do you currently have an existing loan?';
    if (category?.code === 'mortgages_protection') return 'Do you currently have mortgage protection?';
    if (category?.code === 'mortgages') return 'Do you currently have a mortgage?';
    if (category?.code === 'savings') return 'Do you currently have a savings account?';
    if (category?.code === 'pensions') return 'Do you currently have a pension?';
    if (category?.code === 'business_broadband_subcat') return 'Do you currently have a broadband contract?';
    if (category?.code === 'business_mobile') return 'Do you currently have a mobile contract?';
    if (category?.code === 'business_gadget_insurance') return 'Do you currently have gadget insurance?';
    if (category?.code === 'business_taxi_insurance') return 'Do you currently have taxi insurance?';
    if (
      category?.code === 'business_electricity' ||
      category?.code === 'business_gas' ||
      category?.code === 'business_gas_and_electricity'
    ) {
      return 'Do you currently have an energy contract?';
    }
    if (category?.code === 'farm_insurance') return 'Do you currently have a farm insurance?';

    // root categories
    if (category?.root?.code === 'broadband') return `${titleStart} a broadband contract?`;
    if (category?.root?.code === 'energy') return `${titleStart} an energy contract?`;

    return `${titleStart} ${lowerCase(category?.name)}?`;
  }, [category?.id]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 1000);
  // }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className='policy-from-wrapper'>
      {inModalFrame && <ActionPanel handleBack={providerStep > 1 ? handlePrevQuestion : handleCloseModal} />}
      <div className='form-inner-wrapper'>
        <div className='policy-form' ref={useForm}>
          {simpleForm && (
            <FieldContainer attr='havePolicy'>
              <Typography
                variant={inModalFrame ? 'h3' : 'h6'}
                sx={{ textAlign: inModalFrame ? 'center' : 'left' }}
                className='title'
              >
                {policyQuestionTitle}
              </Typography>
              <div className='field-types-list medium mobile-medium'>
                <div className='field-radio' onClick={handleNextQuestion}>
                  <label>
                    <span className='label-text'>Yes</span>
                  </label>
                </div>
                <div className='field-radio' onClick={onClickDontHaveBusiness}>
                  <label>
                    <span className='label-text'>No</span>
                  </label>
                </div>
              </div>
              <ButtonsGroup />
            </FieldContainer>
          )}
          {mixOptions?.length > 0 && (
            <FieldContainer
              remount={remount}
              error={formErrors.anyCompanyId}
              attr='anyCompanyId'
              className={classNames('isSelectType', { active: getAtt === 'anyCompanyId' })}
            >
              <Typography
                variant={inModalFrame ? 'h3' : 'h6'}
                sx={{ textAlign: inModalFrame ? 'center' : 'left' }}
                className='title'
              >
                {anyCompanyTitle}
              </Typography>

              <Stack spacing={1}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={5}>
                    <CompanyAnySelect
                      category={category}
                      name='anyCompanyId'
                      label={'Type business'}
                      isSliderSteps={undefined}
                      onChange={handleFieldChange}
                      widthOutReCache
                    />
                  </Grid>
                  <Grid item xs={12} md={7}>
                    {!inModalFrame && <SelectedCompany company={anyCompany} />}
                  </Grid>
                </Grid>

                {formErrors.anyCompanyId && <div className='field-error'>Required</div>}
              </Stack>
              <Button variant='text' color='dark' sx={{ mt: 1 }} onClick={onClickDontHaveBusiness}>
                {`I don't have a ${isContractCategories ? 'contract' : 'policy'}`}
              </Button>
              <ButtonsGroup />
            </FieldContainer>
          )}

          {formValues.buyingType !== 'provider' && mixOptions?.length > 0 && (
            <FieldContainer
              remount={remount}
              error={formErrors.sourceProviderCompanyId}
              attr='sourceProviderCompanyId'
              className={classNames('isSelectType', { active: getAtt === 'sourceProviderCompanyId' })}
            >
              <Typography
                variant={inModalFrame ? 'h3' : 'h6'}
                sx={{ textAlign: inModalFrame ? 'center' : 'left' }}
                className='title'
              >
                {'Please name your current provider '}
                {(showFields.broker || showFields.comparison_site) && <span>(optional)</span>}
              </Typography>
              <Stack spacing={1}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={5}>
                    <CompanyProviderSelect
                      category={category}
                      name='sourceProviderCompanyId'
                      label={'Search Provider'}
                      isSliderSteps={undefined}
                      onChange={handleFieldChange}
                      disabled={disabledFields.sourceProviderCompanyId}
                    />
                  </Grid>
                  <Grid item xs={12} md={7}>
                    {!inModalFrame && <SelectedCompany company={providerCompany} />}
                  </Grid>
                </Grid>
                {formErrors.sourceProviderCompanyId && <div className='field-error'>Required</div>}
              </Stack>

              <ButtonsGroup />
              {inModalFrame && (
                <div className='dont-have-business' onClick={onClickDontHaveProvider}>
                  I don’t have a provider.
                </div>
              )}
            </FieldContainer>
          )}

          <FieldContainer
            remount={remount}
            error={formErrors.lastBuy}
            attr='lastBuy'
            className={classNames({ active: getAtt === 'lastBuy' })}
          >
            <Typography
              variant={inModalFrame ? 'h3' : 'h6'}
              sx={{ textAlign: inModalFrame ? 'center' : 'left' }}
              className='title'
            >
              When did you sign up with your current provider?
            </Typography>
            <div className='field-types-list medium mobile-medium'>
              <RadioField name='lastBuy' onChange={handleFieldChange} options={LAST_BUY_OPTIONS} />
            </div>
            {formErrors.lastBuy && <div className='field-error'>Required</div>}
            <ButtonsGroup />
          </FieldContainer>
          {((formValues.sourceProviderCompanyId && formValues.sourceProviderCompanyId !== 'i_cant_remember') ||
            (formValues.anyCompanyId && formValues.anyCompanyId !== 'i_cant_remember')) &&
            showFields.anyCompanyRate && (
              <FieldContainer attr='anyCompanyRate'>
                <RateField
                  onChange={handleFieldChange}
                  {...{
                    setTabStep,
                    tabStep,
                    tabChanged,
                    setTabChanged,
                    remount,
                    simpleForm
                  }}
                />
                <ButtonsGroup />
              </FieldContainer>
            )}
        </div>
      </div>
      {inModalFrame && (
        <PersonalizeModalHeader
          {...{
            category,
            handleCloseModal,
            percentage
          }}
        />
      )}
    </div>
  );
};
