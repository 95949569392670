import React, { useRef, useState } from 'react';
import cx from 'classnames';
import moment from 'moment';

import { SignInModalLink } from '@packs/components';
import { Avatar } from '@packs/components/UI';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import Like from '@packs/images/icons/LikeIconEmpty';
import CommentIcon from '@packs/images/icons/UI/CommentIcon';
import ReplyIcon from '@packs/images/svg/ui/reply-icon.svg';
import { commentableType } from '@packs/lib/constants';
import { shortSecondName } from '@packs/lib/helpers/additionalHelpers';
import { useStore } from '@packs/stores';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { AnswerFormComment } from '../InsightForm/InsightForm';
import { RepliesListInsight } from '../RepliesListInsight';

import AnimateHeight from 'react-animate-height';

export const CommentItem = ({ comment, postId, withoutComments, questionsContainerOffset }) => {
  const { insightsCompanyStore } = useStore();
  const answerItemRef = useRef(null);
  const { isMobile } = useWindowSize();
  const { currentUser } = useUserSelectors();
  const [showReplies, setShowReplies] = useState(false);

  const { id, user, message, createdAt, isHelpful, replies, companyUser, helpfulCount } = comment;
  const companyAssigned = !!companyUser?.company;
  const commentsCount = replies.length;

  const avatarUrl = companyUser?.company?.avatarUrl || user?.avatarUrl || '';
  const displayName = companyUser?.company?.name || user?.displayName || '';

  const toggleReplies = () => {
    setShowReplies(!showReplies);
  };

  const onToggleHelpful = () => {
    insightsCompanyStore.toggleHelpfulInsightComment({
      attrs: { commentId: id },
      postId,
      rootCommentId: id
    });
  };

  const submitCallback = () => {
    window.scrollTo({
      top: answerItemRef?.current?.offsetTop + answerItemRef?.current?.clientHeight + questionsContainerOffset - 245,
      behavior: 'smooth'
    });
  };

  return (
    <div className={cx('answer-item', { companyAssigned })} ref={answerItemRef}>
      <div className='answer-body d-flex align-items-start'>
        <div className='mr-15'>
          <Avatar avatarUrl={avatarUrl} placeholder={displayName} size={48} withBorder fullSize withBG />
        </div>
        <div>
          <div className='d-flex align-items-center'>
            {companyAssigned && <img src={ReplyIcon} alt='->' className='mr-8' />}
            <div className='fs-14 lh-9 fw-600'>
              {companyAssigned && <span className='mr-4'>Replay from</span>}
              {companyAssigned ? displayName : shortSecondName(displayName)}
            </div>
          </div>
          <div className='fs-11 lh-9 secondary-text'>{(createdAt && moment(createdAt).fromNow()) || '-'}</div>
        </div>
      </div>
      <div className='answer-message'>{message}</div>
      <div className={cx('bottom-part d-flex align-items-center', !isMobile && 'justify-content-between')}>
        <div className='answer-action-btn comments-btn mr-5' onClick={toggleReplies}>
          <CommentIcon />
          <span>
            {!isMobile && 'Replies '}
            {commentsCount}
          </span>
        </div>

        {currentUser?.id ? (
          <div className={cx('answer-action-btn', { active: isHelpful })} onClick={onToggleHelpful}>
            <Like />
            <span>
              {!isMobile && 'Helpful '}
              {helpfulCount || 0}
            </span>
          </div>
        ) : (
          <SignInModalLink>
            <div className='answer-action-btn'>
              <Like />
              <span>
                {!isMobile && 'Helpful '}
                {helpfulCount || 0}
              </span>
            </div>
          </SignInModalLink>
        )}
      </div>
      {!withoutComments && (
        <div className='answer-replies'>
          <AnimateHeight duration={250} height={showReplies ? 'auto' : 0}>
            <div className='replies-wrapper'>
              <AnswerFormComment
                form={`insight-from-reply-${id}`}
                submitBtnText='Add a reply'
                placeholder='Add a reply...'
                commentableType={commentableType.comment}
                commentableId={id}
                postId={postId}
                submitCallback={submitCallback}
                createComment={insightsCompanyStore.createCommentInsight}
              />
              <RepliesListInsight postId={postId} commentId={id} isOpen={showReplies} />
            </div>
          </AnimateHeight>
        </div>
      )}
    </div>
  );
};
