import React, { useState } from 'react';
import { isNull, isUndefined, keys, reduce, size } from 'lodash-es';
import cx from 'classnames';

import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { Loader } from '@packs/components';
import { SignUpModalLink } from '@packs/components/SignUpModalLink';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { pathGen, usePath } from '@packs/layouts/constants';
import { toastr } from '@packs/lib/helpers';
import { useDetailsForm } from '@packs/screens/PersonalizeResults/DetailsFormProvider';
import { useStore } from '@packs/stores/base';
import useContainerStyles from '@packs/styles/Global/container';
import { categoriesWithoutSimpleForm } from '@shared/constants/categories';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { Summary } from './components/Summary';
import { SelectField } from './formFields/SelectField';
import { PersonalizeSidebar } from './PersonalizeSidebar';
import { MyCriteria, MyProvider, SetReminder } from './stepComponents';

import dayjs from 'dayjs';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { Link } from 'react-router-dom';

const { borderColor } = colors;

const maxPercent = 95;

const getSteps = (props, SaveBtn, percentage) => [
  {
    step: 1,
    name: 'Provider',
    component: <MyProvider {...props} {...{ percentage }} />
  },
  {
    step: 2,
    name: 'Criteria',
    component: <MyCriteria {...props} {...{ percentage }} />
  },
  {
    step: 3,
    name: 'Reminder & Auto. quote',
    component: <SetReminder {...props} {...{ percentage }} SaveBtn={SaveBtn} />
  }
];

export const RenewalDetailsForm = props => {
  const {
    step,
    remount,
    setStep,
    category,
    isLoaded,
    formOptions,
    hideCliteria,
    policyOptions,
    disabledSave,
    showFields,
    simpleForm = false,
    inModalFrame = false,
    onClickLastStepSave
  } = props;
  const { isMobile, isDesktop } = useWindowSize();
  const { detailsFormStore, policiesListStore, userStore, countryCode } = useStore();
  const { categoryCriteriaQuestions } = useDetailsForm();
  const { currentUser } = useUserSelectors();
  const [confirmIsOpen, setConfirmIsOpen] = useState(true);
  const { LEADERBOARD_PATH } = usePath();
  const containerStyles = useContainerStyles();

  const formValues = detailsFormStore?.formValues;
  const dateInTheFuture = dayjs(formValues?.triggeredAt).isBefore(dayjs());
  const { pricesQuestionsOptions } = policiesListStore;

  const calcProviderTotal = () => {
    let sum = 4;

    if (formValues?.buyingType === 'provider' && !formValues?.sourceProviderCompanyId) {
      sum = 2;
    } else if (formValues?.buyingType === 'comparison_site' || formValues?.buyingType === 'broker') {
      if (formValues?.anyCompanyId === 'i_cant_remember') {
        sum = 5;

        if (formValues?.sourceProviderCompanyId === 'i_cant_remember') {
          sum = 4;
        }
      } else if (formValues?.anyCompanyId && formValues?.anyCompanyId !== 'i_cant_remember') {
        sum = 5;

        if (formValues?.sourceProviderCompanyId && formValues?.sourceProviderCompanyId !== 'i_cant_remember') {
          sum = 6;
        }
      }
    } else if (formValues?.sourceProviderCompanyId) {
      if (formValues?.sourceProviderCompanyId === 'i_cant_remember') {
        sum = 3;
      }
    }

    return sum;
  };

  const calcPercentage = () => {
    // Provider questions & answers
    const providerTotalQuestionsSize = calcProviderTotal();
    const providerQuestions = ['buyingType', 'sourceProviderCompanyId', 'anyCompanyId', 'anyCompanyRate', 'lastBuy'];
    const completedProviderAnswersSize =
      formValues?.buyingType === 'provider' && !formValues?.sourceProviderCompanyId
        ? 2
        : size(
            providerQuestions.filter(
              item =>
                !isNull(detailsFormStore?.formValues[item]) &&
                !isUndefined(detailsFormStore?.formValues[item]) &&
                detailsFormStore?.formValues[item] !== '' &&
                detailsFormStore?.formValues[item] !== 0
            )
          );

    // Criteria questions & answers
    const hideCriteriaQuestionsNames = keys(hideCliteria);
    const criteriaQuestionsFiltered = categoryCriteriaQuestions.filter(item => item.type !== 'association');
    const totalCriteriaQuestions = criteriaQuestionsFiltered.filter(
      item => !hideCriteriaQuestionsNames.includes(item.name)
    );
    const criteriaQuestions = detailsFormStore?.formValues?.criteriaQuestions;
    const answers = reduce(
      detailsFormStore?.formValues?.criteriaQuestions,
      (memo, val, key) => {
        if (val) {
          memo.push(key);
        }
        return memo;
      },
      []
    );
    const completedCriteriaAnswersSize = size(
      answers.filter(
        qvName =>
          !hideCriteriaQuestionsNames.includes(qvName) &&
          !isNull(criteriaQuestions[qvName]) &&
          !isUndefined(criteriaQuestions[qvName]) &&
          criteriaQuestions[qvName] !== ''
      )
    );
    // Price answer
    const totalPriceQuestions = keys(pricesQuestionsOptions);
    const priceAnswersSize = detailsFormStore?.formValues?.pricesQuestions?.price?.value ? 1 : 0;

    // Provider/Criteria total
    const totalQuestionsSize =
      providerTotalQuestionsSize + size(totalCriteriaQuestions) + size(totalPriceQuestions) + 1;
    const totalCompletedAnswersSize = completedProviderAnswersSize + completedCriteriaAnswersSize + priceAnswersSize;
    let calcVal = Math.floor((totalCompletedAnswersSize * 100) / totalQuestionsSize);

    if (step === 2 || calcVal >= maxPercent) {
      calcVal = maxPercent;
    }

    return calcVal;
  };

  const setConfirmLink = () => {
    const isRedirectToLeaderboard =
      category?.root?.code === 'energy' || categoriesWithoutSimpleForm.includes(category.code);
    if (!isRedirectToLeaderboard) {
      userStore.setRegisterEvent(pathGen('REQUEST_QUOTES', { countryCode, category }));
    }
  };

  const onSubmit = signUpFlow => {
    if (dateInTheFuture && formValues?.addSmartReminder) {
      return toastr.error('Smart reminder date should be in the future');
    }

    if (formValues?.isAutoQuote) {
      if (!formValues?.phoneNumber) {
        return toastr.error('Please enter phone number');
      }
      if (!isValidPhoneNumber(formValues?.phoneNumber)) {
        return toastr.error('Phone number is invalid');
      }
    }

    if (!disabledSave) {
      onClickLastStepSave(signUpFlow, currentUser?.isConfirmed);
    }
  };

  const SaveBtn = ({ text = '', onClick = null, className = '', disabled }) => {
    return (
      <>
        {currentUser.id ? (
          <Button
            variant='contained'
            color='primary'
            disabled={disabledSave}
            sx={isMobile ? { flexGrow: 1 } : {}}
            onClick={e => {
              onClick && onClick(e);
              detailsFormStore.setFormValuesForSubmit();
              onSubmit();
            }}
          >
            {text ? text : 'Save'}
          </Button>
        ) : (
          <SignUpModalLink
            variant='contained'
            color='primary'
            className={cx(className ? className : 'policy-control-btn next btn full black')}
            authModalCall='personalize'
            skipRedirect
            onClick={() => {
              setConfirmLink();
              onClick && onClick();
              detailsFormStore.setFormValuesForSubmit();
            }}
            disabled={disabled}
            onSignUpSuccess={type => onSubmit(type === 'signUp')}
          >
            <span>{text ? text : 'Save'}</span>
          </SignUpModalLink>
        )}
      </>
    );
  };

  const handlePressNext = () => {
    if (
      ((formValues.sourceProviderCompanyId && formValues.sourceProviderCompanyId !== 'i_cant_remember') ||
        (formValues.anyCompanyId && formValues.anyCompanyId !== 'i_cant_remember')) &&
      showFields.anyCompanyRate &&
      !formValues.anyCompanyRate
    ) {
      return toastr.error('Rate your current contract');
    }
    setStep(step + 1);
  };

  const steps = getSteps(props, SaveBtn, calcPercentage());

  const editPolicyBtn = (
    <IconButton onClick={() => props.setIsOpenAliasNameModal(true)}>
      <EditRoundedIcon />
    </IconButton>
  );

  const renderStepsNav = () => (
    <Box sx={{ mb: { xs: 0, lg: 5 } }}>
      <Box sx={{ ...containerStyles.container, px: 0 }}>
        <Tabs variant='scrollable' scrollButtons='auto' value={step} onChange={(_, newValue) => setStep(newValue)}>
          {steps.map((item, index) => (
            <Tab key={index} label={`${index + 1}. ${item.name}`} variant='standard' sx={{ fontSize: '18px' }} />
          ))}
        </Tabs>
      </Box>
      <Divider />
    </Box>
  );

  const policyFormContainer = () => {
    return (
      <div className='policy-form-container'>
        {!inModalFrame && !isDesktop && (
          <div className='policy-header'>
            {
              // !hideBlock.policySelect && !remount ? (
              !remount ? (
                <div className='policy-header-selector d-flex align-items-center justify-content-between mt-16'>
                  <SelectField
                    name='policySelect'
                    options={policyOptions}
                    defaultValue={formValues?.policySelect}
                    placeholder='Select Policy'
                    classNamePrefix='Select'
                    className='light-border no-scale'
                    value={formValues?.policySelect}
                  />
                  {editPolicyBtn}
                </div>
              ) : null
            }

            <div className={cx('d-flex justify-content-between align-items-center w-100')}>
              {!isMobile && simpleForm && renderStepsNav()}
            </div>
            {simpleForm && (
              <div className='questions-progress-container'>
                <div className='progress-title'>Get matched with the best businesses to quote you!</div>
                <div className='questions-progress'>
                  <div className='progress-indicator' style={{ width: `${calcPercentage()}%` }} />
                </div>
                <div className='progress-footer text-center'>My progress: {` ${calcPercentage()}%`}</div>
              </div>
            )}
          </div>
        )}
        {!isMobile && simpleForm && !inModalFrame && (
          <div className='mobile-category-box d-flex align-items-center justify-content-center'>
            <div>{`${category.name}`} Details</div>
          </div>
        )}

        {isMobile && !simpleForm && !inModalFrame && renderStepsNav()}

        <>
          {steps[step].component}

          {!simpleForm && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 2,
                pt: { xs: 3, md: 5 },
                borderTop: `1px solid ${borderColor.main}`
              }}
            >
              {isDesktop && (
                <Button variant='filledTonal' color='primary' component={Link} to={LEADERBOARD_PATH}>
                  Cancel
                </Button>
              )}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, width: { xs: '100%', lg: 'auto' } }}>
                {step > 0 && (
                  <Button
                    variant='filledTonal'
                    color='primary'
                    startIcon={<ChevronLeftRoundedIcon />}
                    onClick={() => setStep(step - 1)}
                    sx={isMobile ? { flexGrow: 1 } : {}}
                  >
                    Back
                  </Button>
                )}
                {step === 2 ? (
                  <SaveBtn />
                ) : (
                  <Button
                    variant='contained'
                    color='primary'
                    endIcon={<ChevronRightRoundedIcon />}
                    sx={isMobile ? { flexGrow: 1 } : {}}
                    onClick={handlePressNext}
                  >
                    Next
                  </Button>
                )}
              </Box>
            </Box>
          )}
        </>
      </div>
    );
  };

  return (
    <div className={cx('inner-pages', { simpleForm, inModalFrame })}>
      {disabledSave && <Summary simpleForm={simpleForm} />}
      <div className='personalize-container'>
        <Box sx={{ backgroundColor: '#fff' }}>{!isMobile && !simpleForm && !inModalFrame && renderStepsNav()}</Box>
        {!inModalFrame && !isMobile && confirmIsOpen && (
          <Box sx={{ ...containerStyles.container, mb: { xs: 0, lg: 5 } }}>
            <Alert
              severity='info'
              action={
                <IconButton aria-label='close' color='inherit' size='small' onClick={() => setConfirmIsOpen(false)}>
                  <CloseRoundedIcon />
                </IconButton>
              }
              sx={{ alignItems: 'center' }}
            >
              <Typography>Please confirm your details are correct before requesting a quote. Thank you!</Typography>
            </Alert>
          </Box>
        )}
        <Box
          sx={!simpleForm ? { ...containerStyles.container, flexDirection: { xs: 'column', lg: 'row' } } : {}}
          className='personalize-wrapper'
        >
          {policyFormContainer()}

          {!isLoaded && <Loader />}

          {!simpleForm && (
            <PersonalizeSidebar
              {...{
                step,
                remount,
                category,
                formValues,
                formOptions,
                editPolicyBtn,
                policyOptions
              }}
              percentage={calcPercentage()}
            />
          )}
        </Box>
      </div>
    </div>
  );
};
