import React, { FC, useState } from 'react';

import CloseIcon from '@mui/icons-material/CloseRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { TextFieldProps } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { cantRemember } from '@packs/models/company/constants';
import { EmptyPlaceholder } from '@shared/components/EmptyPlaceholder/EmptyPlaceholder';

import useStyles from './styles';

type MobileSelectProps = {
  value: any;
  options: readonly any[];
  onChange: (val: any) => void;
  size?: TextFieldProps['size'];
  variant?: TextFieldProps['variant'];
  label?: string;
  disabled?: boolean;
  onFilter?: (val: string) => void;
  placeholder?: string;
  filterPlaceholder?: string;
};

export const MobileSelect: FC<MobileSelectProps> = ({
  value,
  options = [],
  onChange,
  size = 'medium',
  variant = 'outlined',
  label = '',
  disabled = false,
  onFilter,
  placeholder = '',
  filterPlaceholder = 'Search'
}) => {
  const styles = useStyles();
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const optionsCount = options.filter(item => item.value !== cantRemember.value).length;

  const handleSearchChange = event => {
    setSearchValue(event.target.value);
    onFilter && onFilter(event.target.value);
  };

  const handleChangeDialog = data => {
    return () => {
      if (data) {
        onChange(data);
        setOpen(false);
        onFilter && onFilter('');
        setSearchValue('');
      }
    };
  };

  return (
    <>
      <TextField
        {...{ disabled, label, variant, size, placeholder }}
        fullWidth
        onClick={() => setOpen(true)}
        value={value?.label || ''}
      />
      <Dialog
        fullScreen
        open={open}
        scroll='paper'
        // ModalProps={{
        //   keepMounted: true, // Better open performance on mobile.
        //   disableScrollLock: false // This is the key property to block body scroll
        // }}
        onClose={() => setOpen(false)}
      >
        <DialogTitle sx={{ display: 'fex', alignItems: 'center', justifyContent: 'space-between', py: 1.5 }}>
          <Box />
          <Typography variant='h6'>{label}</Typography>
          <IconButton edge='end' color='inherit' onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={styles.content}>
          <Box sx={{ pt: 2, pb: 1 }}>
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchRoundedIcon />
                  </InputAdornment>
                )
              }}
              value={searchValue}
              onChange={handleSearchChange}
              placeholder={filterPlaceholder}
            />
          </Box>
          {optionsCount > 0 ? (
            <List>
              {options.map((option, index) => (
                <ListItem key={index} onClick={handleChangeDialog(option)} sx={styles.listItem}>
                  <ListItemIcon>
                    <Avatar src={option.avatarUrl} />
                  </ListItemIcon>
                  <ListItemText primary={<Typography variant='body2'>{option.label}</Typography>} />
                </ListItem>
              ))}
            </List>
          ) : (
            <Box sx={{ mt: 2 }}>
              <EmptyPlaceholder title='No results found.' description='Try different current provider!' />
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
