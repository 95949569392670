/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React base styles
import borders from '@crm/assets/theme/base/borders';
import boxShadows from '@crm/assets/theme/base/boxShadows';
import colors from '@crm/assets/theme/base/colors';
import typography from '@crm/assets/theme/base/typography';
// Material Dashboard 2 PRO React helper functions
import pxToRem from '@shared/assets/theme/functions/pxToRem';

const { lg } = boxShadows;
const { size } = typography;
const { text, white, transparent, dark, primary } = colors;
const { borderRadius } = borders;

const autocomplete = {
  styleOverrides: {
    root: {
      ['& .MuiOutlinedInput-root']: {
        padding: `${pxToRem(4)} ${pxToRem(8)} ${pxToRem(5)}`
      }
    },
    popper: {
      boxShadow: lg,
      padding: pxToRem(8),
      fontSize: size.sm,
      color: text.main,
      textAlign: 'left',
      backgroundColor: `${white.main} !important`,
      borderRadius: borderRadius.md
    },

    paper: {
      boxShadow: 'none',
      backgroundColor: transparent.main
    },

    option: {
      padding: `${pxToRem(4.8)} ${pxToRem(16)}`,
      borderRadius: borderRadius.md,
      fontSize: size.sm,
      transition: 'background-color 300ms ease, color 300ms ease',

      '&:hover': {
        backgroundColor: `${primary[50]} !important`
      },
      '&[aria-selected="true"]': {
        backgroundColor: `${primary.main} !important`,
        color: white.main
      }
    },

    noOptions: {
      fontSize: size.sm,
      color: text.main
    },

    groupLabel: {
      color: dark.main
    },

    loading: {
      fontSize: size.sm,
      color: text.main
    },

    tag: {
      display: 'flex',
      alignItems: 'center',
      height: 'auto',
      padding: pxToRem(4),

      '& .MuiChip-label': {
        padding: `0 ${pxToRem(10)} 0 ${pxToRem(4)}`
      },

      '& .MuiSvgIcon-root, & .MuiSvgIcon-root:hover, & .MuiSvgIcon-root:focus': {
        marginRight: 0
      }
    }
  }
};

export default autocomplete;
