import { green } from '@mui/material/colors';
import { numberWithCommas } from '@packs/lib/constants';
import { LAST_BUY_OPTIONS } from '@packs/models/policy/constants';

export const COMPANIES_PER_PAGE = 8;
export const FIRST_COMPANIES_PER_PAGE = 3;
export const ASC = 'asc';
export const DESC = 'desc';

export const TIME_OPTIONS = [{ label: 'All time', value: 'all' }, ...LAST_BUY_OPTIONS];

export const COMPANY_TYPE_EACH_TYPE = { value: 'all_types', label: 'Compare Sources' };

export const COMPANY_TYPE_OPTIONS = [
  { value: 'all', label: 'All' },
  { value: 'provider', label: 'Best Providers' },
  { value: 'broker', label: 'Best Agencies / Brokers' },
  { value: 'comparison_site', label: 'Best comparison sites' }
];

export const COMPANY_TYPE = [
  { value: 'provider', label: 'Provider' },
  { value: 'broker', label: 'Broker' },
  { value: 'comparison_site', label: 'Comparison Site' }
];

export const COMPANY_TYPE_OPTIONS_DESKTOP = [
  {
    value: 'all',
    label: 'All',
    iconType: 'all'
  },
  {
    value: 'provider',
    label: 'Top Providers',
    iconType: 'provider'
  },
  {
    value: 'broker',
    label: 'Top Agencies / Brokers',
    iconType: 'broker'
  },
  {
    value: 'comparison_site',
    label: 'Top Price Comparison Sites',
    iconType: 'comparison_site'
  }
];

export const SUBSCRIPTIONS_COMPANY_TYPE_OPTIONS = [{ value: 'provider', label: 'Provider' }];

export const shortList = { value: 'short_list', label: 'Personalized Results' };

export const ORDER_OPTIONS = [
  { value: 'wiki_price', label: 'Best priced' },
  { value: 'most_popular', label: 'Most Popular' },
  { value: 'reviewed', label: 'Most reviewed' },
  { value: 'higher_raited', label: 'Highest rated' }
];

export const INSURANCE_ORDER_OPTIONS = [
  { value: 'best_avg_prices', label: 'Best priced' },
  { value: 'most_popular', label: 'Most Popular' },
  { value: 'best_claims', label: 'Best claims ratings' },
  { value: 'reviewed', label: 'Most reviewed' },
  { value: 'higher_raited', label: 'Highest rated' }
];

export const mostPopularDefaultCategories = ['investments', 'deposits', 'pensions'];

export const TRUST_NETWORK_ORDER_OPTION = { value: 'trust_network', label: 'My Trust Network' };

export const parseTimeFilters = period => {
  switch (period) {
    case 'today':
      return 'today';
    case 'this_week':
      return 'in the past 7 days';
    case 'this_month':
      return 'in the past 30 days';
    case 'this_quarter':
      return 'in the past 90 days';
    case 'this_year':
      return 'in the past year';
    case 'year_or_more':
      return 'one year or more';
    default:
      return '';
  }
};

export const pricesOrderOption = [
  {
    value: ASC,
    label: 'Lowest'
  },
  {
    value: DESC,
    label: 'Highest'
  }
];

export const periods = [
  { value: 'monthly', label: 'Per month' },
  { value: 'yearly', label: 'Per year' }
];

export const offerTypes = [
  {
    value: 'all',
    label: 'All'
  },
  {
    value: 'trust_network',
    label: 'Network Offers'
  },
  {
    value: 'association',
    label: 'Association Offers'
  },
  {
    value: 'discount_code',
    label: 'Discount code'
  }
];

export const offerBusinessType = [
  {
    value: 'all',
    label: 'All'
  },
  {
    value: 'consumer',
    label: 'Consumer'
  },
  {
    value: 'business',
    label: 'Business'
  }
];

export const recurringOptions = [
  { label: 'Do not renew', value: null },
  { label: 'Renews every year ', value: '1_year' },
  { label: 'Renews every 18 months', value: '18_month' },
  { label: 'Renews every 2 years', value: '2_years' },
  { label: 'Renews every 3 years', value: '3_years' }
];

export const WIKI_LIMIT = 6;
export const WIKI_LIMIT_MOBILE = 4;

const filterTitle = {
  lastBuy: 'Time',
  city: 'Location',
  companyType: 'Source type',
  offerType: 'Type of offer',
  customerType: 'Type of business',
  rootCategoryCode: 'Category',
  categoryId: 'Sub category',
  keyWord: 'Search by category or business name',
  selectedType: 'Type',
  countryRegionName: 'Location',
  trustNetwork: 'Community posts',
  selectedCategory: 'Category',
  selectedYear: 'Year',
  age: 'Age',
  ageTypeOne: 'Age',
  ageTypeTwo: 'Age',
  ageTypeTree: 'Age',
  ageTypeFour: 'Age'
};

export const appliedFiltersLabels = (filters, fields = [], criteriaFilters = [], options = []) => {
  const filteredValues = [];

  const processField = field => {
    const filterItem = filters[field];
    const title = filterTitle[field];

    if (typeof filterItem === 'object' && filterItem?.value && filterItem.value !== 'all') {
      filteredValues.push({ ...filterItem, title });
    } else if (typeof filterItem === 'string' && filterItem && filterItem !== 'all') {
      const option = options.find(item => item.value === filterItem);
      filteredValues.push(option ? { ...option, title } : { label: filterItem, title });
    }
  };

  // Process regular fields
  fields.filter(field => filters[field] && field !== 'criteriaQuestions').forEach(processField);

  // Process criteriaQuestions
  if (filters['criteriaQuestions'] && filters?.criteriaQuestions) {
    Object.entries(filters.criteriaQuestions).forEach(([key, value]) => {
      if (value !== 'all' && value !== null && value?.length !== 0 && key !== 'location') {
        const { type, options, label, multiple } = criteriaFilters[key] || {};

        if (type === 'select' || type === 'radio') {
          if (multiple) {
            const option = Array.isArray(value)
              ? value.map(item => options.find(option => option.value === item)?.label)
              : options.find(option => option.value === value)?.label;
            filteredValues.push({ label: option, title: label });
          } else {
            const option = options.find(item => item.value === value);
            filteredValues.push({ ...option, title: label });
          }
        } else {
          const title = label || filterTitle[key] || 'Filter';
          filteredValues.push({ label: value, title });
        }
      }
    });
  }

  return filteredValues;
};

export const getWikiList = ({ company, currentCategory, currency }) => {
  const isDynamicPrice = ['loans', 'mortgages'].includes(currentCategory.code);
  const wikiTable = company?.wikiTable;
  if (
    !(
      wikiTable?.informationalSections.length > 0 ||
      (isDynamicPrice && wikiTable?.variableRate) ||
      wikiTable?.fixedRate ||
      wikiTable?.greenRate
    )
  ) {
    return [];
  }

  const list = [];

  if (isDynamicPrice && wikiTable?.variableRate && !wikiTable?.fixedRate) {
    list.push({ title: 'Rate:', description: `${wikiTable?.variableRate}%` });
  }

  if (wikiTable?.fixedRate && !wikiTable?.greenRate) {
    list.push({ title: `Fixed rate for ${wikiTable?.fixedPeriod} years`, description: `${wikiTable?.fixedRate}%` });
  }

  if (wikiTable?.greenRate && wikiTable?.greenRate > 0) {
    list.push({
      title: 'Green Rate',
      description: `${wikiTable?.greenRate}%`,
      sx: { backgroundColor: green[50], color: green[600] }
    });
  }

  wikiTable?.informationalSections.forEach(item => {
    list.push({
      title: item?.criteriaQuestion.label.replace(':category_name', currentCategory.name),
      description: item.answer
    });
  });

  if (wikiTable?.fullTermCost) {
    list.push({
      title: 'Full term cost:',
      description: currency + numberWithCommas(wikiTable.fullTermCost.toFixed(2))
    });
  }

  return {
    list,
    count: list.length
  };
};

export const cantRemember = {
  value: 'i_cant_remember',
  label: "I can't remember"
};
