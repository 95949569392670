import React from 'react';

export const BankingAccounts = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <path d='M10 9.23077C12.4853 9.23077 14.5 7.16439 14.5 4.61538C14.5 2.06638 12.4853 0 10 0C7.51472 0 5.5 2.06638 5.5 4.61538C5.5 7.16439 7.51472 9.23077 10 9.23077Z' />
      <path d='M15.25 12.3077C13.18 12.3077 11.5 14.0308 11.5 16.1538C11.5 18.2769 13.18 20 15.25 20C17.32 20 19 18.2769 19 16.1538C19 14.0308 17.32 12.3077 15.25 12.3077ZM17.6553 15.5438L15.4053 17.8515C15.259 18.0015 15.067 18.0769 14.875 18.0769C14.683 18.0769 14.491 18.0015 14.3448 17.8515L13.2198 16.6977C12.9265 16.3969 12.9265 15.9108 13.2198 15.61C13.513 15.3092 13.987 15.3092 14.2803 15.61L14.875 16.22L16.5948 14.4562C16.888 14.1554 17.362 14.1554 17.6553 14.4562C17.9485 14.7569 17.9485 15.2431 17.6553 15.5438Z' />
      <path d='M14.176 11.6769C12.8725 11.0969 11.4587 10.7692 10 10.7692C6.53725 10.7692 3.29125 12.5208 1.309 15.4669C1.10725 15.7762 1 16.1331 1 16.5V16.9231C1 17.3477 1.33525 17.6923 1.75 17.6923H11.0125C10.8453 17.2108 10.75 16.6938 10.75 16.1538C10.75 13.9862 12.2103 12.1715 14.176 11.6769Z' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.875 18.0769C15.067 18.0769 15.259 18.0015 15.4053 17.8515L17.6553 15.5438C17.9485 15.2431 17.9485 14.7569 17.6553 14.4562C17.362 14.1554 16.888 14.1554 16.5948 14.4562L14.875 16.22L14.2803 15.61C13.987 15.3092 13.513 15.3092 13.2198 15.61C12.9265 15.9108 12.9265 16.3969 13.2198 16.6977L14.3448 17.8515C14.491 18.0015 14.683 18.0769 14.875 18.0769ZM17.2475 15.0527C17.3842 15.1894 17.3842 15.411 17.2475 15.5477L15.2475 17.5477C15.1108 17.6844 14.8892 17.6844 14.7525 17.5477L13.7525 16.5477C13.6159 16.411 13.6159 16.1894 13.7525 16.0527C13.8892 15.916 14.1108 15.916 14.2475 16.0527L15 16.8052L16.7525 15.0527C16.8892 14.916 17.1108 14.916 17.2475 15.0527Z'
      />
    </svg>
  );
};
