import React, { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import moment from 'moment';

import {
  Add as AddIcon,
  CloseRounded as CloseIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  MoreHoriz as MoreHorizIcon
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { categoryApi } from '@packs/apis/category';
import companyApi from '@packs/apis/company';
import colors from '@packs/assets/theme/base/colors';
import { Switcher } from '@packs/components';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { usePath } from '@packs/layouts/constants';
import { autoQuoteTooltip, autoQuoteTooltipNoPolicy, getCurrency } from '@packs/lib/constants';
import { toastr } from '@packs/lib/helpers';
import { hasPolicyCriteria } from '@packs/lib/helpers/additionalHelpers';
import { AVAILABLE_CATEGORIES } from '@packs/models/category/constants';
import { isCompanyPerMonth, isWikiTableCategory, priceWithCommas } from '@packs/models/category/functions';
import { pricesOrderOption } from '@packs/models/company/constants';
import { useDetails } from '@packs/models/policy/useDetails';
import { useStore } from '@packs/stores';
import { TooltipMUI } from '@shared/components/TooltipMUI/TooltipMUI';

import './styles.scss';

import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

export const BillBuddy = observer(() => {
  const { onEditReminder } = useModalsContext();
  const { companiesStore, leaderboardStore, countryCode, currentCategory, policyStore, smartRemindersStore } =
    useStore();
  const { currentPolicy } = useDetails();
  const { MY_DETAILS_PATH_FN } = usePath();

  const [categoryAveragePrice, setCategoryAveragePrice] = useState(null);
  const [companyPackage, setCompanyPackage] = useState(null);
  const [currentCompanyPackage, setCurrentCompanyPackage] = useState(null);

  const category = currentCategory;

  const currency = useMemo(() => getCurrency(countryCode), [countryCode]);
  const isWiki = useMemo(() => isWikiTableCategory(category), [category?.id]);
  const isPerMonth = isCompanyPerMonth(category);
  const company = companiesStore.listInfinity.length ? companiesStore.listInfinity[0] : null;
  const isBusinessEnergyCategories = ['business_electricity', 'business_gas', 'business_gas_and_electricity'].includes(
    category.code
  );
  const quoteRequest = currentPolicy?.smartReminder?.quoteRequest;
  const isAutoQuote = currentPolicy?.smartReminder?.isAutoQuote;
  const emptyPolicy = !hasPolicyCriteria(currentPolicy);

  const onCLoseBillBuddy = () => {
    leaderboardStore.showBillBuddy(false);
  };

  const business = currentPolicy?.anyCompany;
  const isEnergy = useMemo(() => category?.root?.code === 'energy', [category?.root?.code]);

  const price = useMemo(() => {
    if (isEnergy) {
      return currentCompanyPackage?.planPrice
        ? currency + priceWithCommas(currentCompanyPackage?.planPrice) + '/' + (isPerMonth ? 'per month' : 'per year')
        : '-';
    }

    if (!currentPolicy?.pricesQuestions?.price?.value) {
      return '-';
    }

    if (isPerMonth && currentPolicy?.pricesQuestions?.price?.period === 'per_year') {
      if (category.code === 'pensions') {
        return currency + priceWithCommas(currentPolicy?.pricesQuestions?.price?.value / 12);
      }

      return (
        currency +
        priceWithCommas(currentPolicy?.pricesQuestions?.price?.value / 12) +
        '/' +
        (isPerMonth ? 'per month' : 'per year')
      );
    } else if (!isPerMonth && currentPolicy?.pricesQuestions?.price?.period !== 'per_year') {
      if (category.code === 'pensions') {
        return currency + priceWithCommas(currentPolicy?.pricesQuestions?.price?.value * 12);
      }

      return (
        currency +
        priceWithCommas(currentPolicy?.pricesQuestions?.price?.value * 12) +
        '/' +
        (isPerMonth ? 'per month' : 'per year')
      );
    }

    return (
      currency +
      priceWithCommas(currentPolicy?.pricesQuestions?.price?.value) +
      '/' +
      (isPerMonth ? 'per month' : 'per year')
    );
  }, [
    isEnergy,
    currentPolicy?.pricesQuestions?.price?.value,
    currentPolicy?.pricesQuestions?.price?.period,
    isPerMonth,
    currency,
    currentCompanyPackage?.planPrice,
    category.code
  ]);

  const isDynamicPrice = useMemo(() => ['loans', 'mortgages'].includes(category.code), [category.code]);

  const businessTitle = useMemo(() => {
    if (category.code === 'pensions') {
      return 'Average for other members';
    }

    return category?.root?.code === 'banking' && !isDynamicPrice
      ? 'Best APR rate:'
      : isWiki && !isBusinessEnergyCategories
      ? 'Best market price:'
      : 'Average for members like you:';
  }, [category.code, category?.root?.code, isBusinessEnergyCategories, isDynamicPrice, isWiki]);

  const topBusinessPrice = () => {
    if (isWiki && category.code !== 'pensions') {
      if (isDynamicPrice) {
        return company?.wikiTable?.variablePrice
          ? currency + priceWithCommas(company?.wikiTable?.variablePrice) + '/per month'
          : null;
      } else if (category.code === 'credit_cards') {
        return `${company?.wikiTable?.aerRate || 0}%`;
      } else if (AVAILABLE_CATEGORIES['banking'].includes(category.code)) {
        return `${company?.wikiTable?.aprRate || 0}%`;
      }

      if (companyPackage) {
        return companyPackage?.planPrice
          ? currency + priceWithCommas(companyPackage?.planPrice) + '/' + (isPerMonth ? 'per month' : 'per year')
          : null;
      }

      return company?.wikiTable?.planPrice
        ? currency + priceWithCommas(company?.wikiTable?.planPrice) + '/' + (isPerMonth ? 'per month' : 'per year')
        : null;
    }

    const pricePerPeriod = categoryAveragePrice
      ? categoryAveragePrice[isPerMonth ? 'averageMonthly' : 'averageYearly']
      : null;

    if (!pricePerPeriod) {
      return null;
    }

    if (category.code === 'pensions') {
      return currency + priceWithCommas(pricePerPeriod) + '/';
    }

    return currency + priceWithCommas(pricePerPeriod) + '/' + (isPerMonth ? 'per month' : 'per year');
  };

  const handleEditReminder = () => {
    onEditReminder(
      { ...currentPolicy?.smartReminder, renewalDetailId: currentPolicy?.id },
      {
        parentType: 'calendar',
        category,
        disabledCategory: true,
        hidePolicy: true
      }
    );
    handleCloseDropdown();
  };

  const toggleIsAutoQuote = () => {
    if (quoteRequest) {
      policyStore.toggleIsAutoQuote(!isAutoQuote);
    } else {
      handleEditReminder();
    }
  };

  const handleDeleteReminder = () => {
    smartRemindersStore.destroy(
      { ids: [currentPolicy?.smartReminder?.id], policyId: currentPolicy?.id },
      () => {
        toastr.success('Smart reminder deleted');
      },
      () => {
        toastr.error('Smart reminder NOT deleted');
      }
    );
    handleCloseDropdown();
  };

  useEffect(() => {
    if (!isWiki || isBusinessEnergyCategories) {
      categoryApi.fetchCategoryAveragePrice({ id: category.id }).then(data => {
        if (!data.errors) {
          setCategoryAveragePrice(data?.averagePriceData);
        }
      });
    }
  }, [category?.id, isBusinessEnergyCategories, isWiki]);

  useEffect(() => {
    if (isWiki && company?.id) {
      companyApi
        .fetchProducts({
          page: 1,
          perPage: 1,
          companyId: company.id,
          categoryId: category?.id,
          orderColumn: 'plan_price',
          orderDirection: pricesOrderOption[0].value
        })
        .then(res => {
          if (res?.data?.length) {
            setCompanyPackage(res?.data[0]);
          } else {
            setCompanyPackage(null);
          }
        });
    }
  }, [isWiki, company?.id, category?.id]);

  useEffect(() => {
    if (isWiki && business?.id && isEnergy) {
      companyApi
        .fetchProducts({
          page: 1,
          perPage: 1,
          companyId: business?.id,
          categoryId: category?.id,
          orderColumn: 'plan_price',
          orderDirection: pricesOrderOption[0].value
        })
        .then(res => {
          if (res?.data?.length) {
            setCurrentCompanyPackage(res?.data[0]);
          } else {
            setCurrentCompanyPackage(null);
          }
        });
    }
  }, [isWiki, business?.id, isEnergy, category?.id]);

  const [anchorEl, setAnchorEl] = useState(null);
  const dropdownOpen = Boolean(anchorEl);
  const handleOpenDropdown = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  if (!leaderboardStore.isBillBuddyOpen) {
    return null;
  }

  return (
    <Box sx={{ position: 'fixed', top: '250px', left: '8px', width: 1, maxWidth: '360px', zIndex: 20 }}>
      <Card sx={{ borderRadius: 2 }} elevation={4}>
        <CardContent>
          <IconButton sx={{ position: 'absolute', top: 4, right: 4 }} onClick={onCLoseBillBuddy}>
            <CloseIcon />
          </IconButton>
          <Typography variant='h6' gutterBottom>
            My Reminder
          </Typography>

          <Grid container alignItems='center'>
            <Grid item xs='auto'>
              {currentPolicy?.smartReminder?.id ? (
                <Stack direction='row' spacing={0.5} alignItems='center'>
                  <div className={cx('reminder-status', currentPolicy?.smartReminder?.status)} />
                  <Typography variant='body3'>
                    {moment(currentPolicy?.smartReminder?.triggeredAt).format('DD MMM YYYY')}
                  </Typography>
                </Stack>
              ) : (
                <Typography variant='body3'>No date</Typography>
              )}
            </Grid>
            <Grid item xs>
              <Stack direction='row' justifyContent='flex-end' spacing={0.5}>
                <Stack direction='row' alignItems='center' spacing={0.5}>
                  <TooltipMUI
                    title={
                      <Typography variant='caption'>
                        {!emptyPolicy ? autoQuoteTooltip : autoQuoteTooltipNoPolicy}
                      </Typography>
                    }
                    arrow
                    placement='top'
                  >
                    <Box>
                      <Switcher primary value={isAutoQuote} onChange={toggleIsAutoQuote} disabled={emptyPolicy} />
                      <Typography variant='body5'>Auto. quote</Typography>
                    </Box>
                  </TooltipMUI>
                </Stack>

                <Box>
                  <IconButton size='small' onClick={handleOpenDropdown}>
                    <MoreHorizIcon fontSize='inherit' />
                  </IconButton>
                  <Menu
                    id='billBuddy-menu'
                    anchorEl={anchorEl}
                    open={dropdownOpen}
                    onClose={handleCloseDropdown}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button'
                    }}
                  >
                    {!currentPolicy?.smartReminder?.id ? (
                      <MenuItem onClick={handleEditReminder}>
                        <ListItemIcon>
                          <AddIcon />
                        </ListItemIcon>
                        <Typography variant='inherit'>Add remainder</Typography>
                      </MenuItem>
                    ) : (
                      <>
                        <MenuItem onClick={handleEditReminder}>
                          <ListItemIcon>
                            <EditIcon />
                          </ListItemIcon>
                          <Typography variant='inherit'>Edit</Typography>
                        </MenuItem>
                        <MenuItem onClick={handleDeleteReminder}>
                          <ListItemIcon>
                            <DeleteIcon />
                          </ListItemIcon>
                          <Typography variant='inherit'>Delete</Typography>
                        </MenuItem>
                      </>
                    )}
                  </Menu>
                </Box>
              </Stack>
            </Grid>
          </Grid>
          <Card sx={{ background: grey[100], mt: 1, mb: 1, borderRadius: 2 }} elevation={0}>
            <CardContent sx={{ pb: 0 }}>
              <Box>
                <Typography variant='body4' gutterBottom>
                  Your provider:
                </Typography>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                  {business?.name ? (
                    <Typography variant='body4' color='primary.dark' sx={{ textDecoration: 'underline' }}>
                      {business.name}
                    </Typography>
                  ) : (
                    <Typography>-</Typography>
                  )}
                  <Typography variant='body4' color={colors.text.secondary}>
                    {price}
                  </Typography>
                </Stack>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography variant='body4' gutterBottom>
                  {businessTitle}
                </Typography>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                  {isWiki && category.code !== 'pensions' && !isBusinessEnergyCategories ? (
                    <Typography variant='body4'>
                      {companyPackage ? companyPackage?.providerCompany?.name || companyPackage?.company?.name : '-'}
                    </Typography>
                  ) : (
                    <Typography color={colors.text.secondary}>Average price</Typography>
                  )}
                  {topBusinessPrice() ? (
                    <Typography variant='body4' color={colors.text.secondary}>
                      {topBusinessPrice()}
                    </Typography>
                  ) : (
                    '-'
                  )}
                </Stack>
              </Box>
            </CardContent>
          </Card>
          <Typography variant='body4' gutterBottom>
            Make sure your details are up to date!
          </Typography>
          <Stack direction='row' alignItems='center' spacing={0.5}>
            <Typography variant='body3'>Last updated:</Typography>
            <Typography variant='body3' color={colors.text.secondary}>
              {moment(currentPolicy?.updatedAt).format('DD MMM YYYY')}
            </Typography>
          </Stack>
          <Button
            variant='contained'
            color='primary'
            size='small'
            fullWidth
            component={Link}
            to={MY_DETAILS_PATH_FN(currentPolicy, category)}
            onClick={onCLoseBillBuddy}
            sx={{ mt: 1 }}
          >
            Update your details
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
});
