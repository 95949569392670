import React from 'react';

const PlusIcon = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.76286 13.2721L13.4002 3.45876C13.5982 2.92409 13.0775 2.40409 12.5435 2.60209L2.72686 6.24209C2.11286 6.47009 2.16086 7.35409 2.79619 7.51343L7.35219 8.65809L8.49019 13.2021C8.65019 13.8381 9.53486 13.8868 9.76286 13.2721V13.2721Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.2468 2.75342L7.35352 8.66008'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default PlusIcon;
