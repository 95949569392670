import { getWindow } from '@shared/lib/utils';

const rand = () => Math.random(0).toString(36).substr(2);
const token = length => (rand() + rand() + rand() + rand()).substr(0, length);
const tokenKey = 'session-token';

export function refreshSessionToken() {
  return getWindow().localStorage.setItem(tokenKey, token());
}

export function getSessionToken() {
  return getWindow().localStorage.getItem(tokenKey);
}

export function createSessionToken() {
  if (!getSessionToken()) {
    return refreshSessionToken();
  }
}
