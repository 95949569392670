import React from 'react';
import cx from 'classnames';

import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { useStore } from '@packs/stores';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { observer } from 'mobx-react';
// Components
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

export const PersonalizeConfirmModal = observer(({ toggle, isOpen }) => {
  const { togglePersonalizeUnAuthorizedModal } = useModalsContext();
  const { isDesktop } = useWindowSize();
  const { currentCategory } = useStore();

  const handleCancel = () => {
    toggle();
  };

  const handleContinue = () => {
    toggle();
    togglePersonalizeUnAuthorizedModal(true);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggle()}
      className={cx('personalize-confirm-modal', currentCategory.root.enName)}
    >
      <ModalHeader toggle={() => toggle()} charCode='x' />
      <ModalBody>
        <div className='title fs-25 lh-5 mb-40 fw-700 text-center'>Are you sure that you want to leave?</div>
        <p className='text-center fs-17 mb-40'>
          We will need your location to match you {isDesktop && <br />} with the best businesses to quote to you!
        </p>
        <div className='btn-footer d-flex flex-column align-items-center'>
          <div className='btn full theme white' onClick={handleContinue}>
            <span>Continue</span>
          </div>
          <div className='btn empty black light cancel' onClick={handleCancel}>
            Quit
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
});
