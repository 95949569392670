import { action, computed, observable, runInAction, toJS } from 'mobx';
import { size } from 'lodash-es';

import { hasPolicyCriteria } from '@packs//lib/helpers/additionalHelpers';
import api from '@packs/apis/company';
import { requestsApi } from '@packs/apis/requests';
import { criteriaFiltersUnAuthorized } from '@packs/lib/helpers/category';
import { pickPersonalizedCriteriaQuestionsFromOptions } from '@packs/lib/helpers/renewalDetails';
import { setError } from '@packs/lib/utils';
import { isWikiTableCategory } from '@packs/models/category/functions';
import {
  ASC,
  COMPANIES_PER_PAGE,
  COMPANY_TYPE_EACH_TYPE,
  COMPANY_TYPE_OPTIONS,
  DESC,
  FIRST_COMPANIES_PER_PAGE,
  INSURANCE_ORDER_OPTIONS,
  offerTypes,
  ORDER_OPTIONS,
  TIME_OPTIONS
} from '@packs/models/company/constants';
import { RootStore } from '@packs/stores';

import cloneDeep from 'lodash-es/cloneDeep';
import keys from 'lodash-es/keys';
import omit from 'lodash-es/omit';
import pick from 'lodash-es/pick';

export type TFilter = {
  page: number;
  perPage: number;

  categoryId?: number;
  countryCode?: string;
  lastBuy?: string;
  orderColumn?: string;
  orderDirection?: string;
  keyWord?: string;
  country?: string;
  city?: string;
  policyId?: number;
  offerType?: string;
  criteriaQuestions?: Record<string, string>;
  companyType?: string;
};

export const INIT_FILTERS: TFilter = {
  categoryId: undefined,
  companyType: COMPANY_TYPE_OPTIONS[0].value,
  countryCode: undefined,
  criteriaQuestions: {},
  lastBuy: TIME_OPTIONS[0].value,
  orderColumn: ORDER_OPTIONS[0].value,
  orderDirection: DESC,
  keyWord: '',
  country: undefined,
  city: undefined,
  page: 1,
  perPage: COMPANIES_PER_PAGE,
  policyId: undefined,
  offerType: offerTypes[0].value
};

const DEFAULT_META = {
  isResultWithCriteria: false
};

const initialWikiData = {
  wikiMatchCount: 0,
  wikiMatchPercent: 0
};

const serializeFilters = (filters): Record<string, string | number | null> => {
  return keys(filters).reduce((memo, key) => {
    if (filters[key] === 'all') {
      memo[key] = null;
    } else if (key === 'criteriaQuestions') {
      if (keys(filters[key]).length) {
        memo[key] = filters[key];
      } else {
        memo[key] = undefined;
      }
    } else {
      memo[key] = filters[key];
    }
    return memo;
  }, {});
};

export class CompaniesStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable
  list: { id: number; companyClicks: number }[] = [];
  @observable
  allTypesList = {
    provider: [],
    broker: [],
    comparison_site: []
  };
  @observable
  listAutocomplete = [];
  @observable
  listInfinity: Record<string, string>[] = [];
  @observable
  loading = false;
  @observable
  total = 0;
  @observable
  meta: Record<string, string> = cloneDeep(DEFAULT_META) as unknown as Record<string, string>;
  @observable
  filters: TFilter = cloneDeep(INIT_FILTERS);
  @observable
  preFiledCriteriaFilters = {};
  @observable
  myStatsFilters = {};
  @observable
  personalizeFilterStatus = 'personalized';
  @observable
  isShortList = false;
  @observable
  addInfinity = false;
  @observable
  wikiData = initialWikiData;
  @observable
  allCriteriasMatch = false;
  @observable
  featuredBusinesses: Record<string, string>[] = [];
  @observable
  featuredBusinessesTotal = 0;
  @observable
  featuredBusinessesLoading = false;
  @observable
  globalRequestQuotesCount = 0;
  @observable
  requestQuotesCount = 0;

  @computed get pagesCount() {
    return Math.ceil(this.total / this.filters.perPage);
  }

  @action
  async loadAllTypes(callback) {
    const wikiLogic = isWikiTableCategory(this.rootStore.currentCategory);
    const apiCall = wikiLogic ? api.fetchWikiPricesCompaniesAllTypes : api.fetchCompaniesAllTypes;

    const variables = {
      ...this.serializeFilters,
      page: 1,
      perPage: 3
    };
    const visibleSources = this.rootStore.currentCategory?.visibleSources || [];

    if (visibleSources?.length === 0) {
      this.allTypesList = {
        provider: [],
        broker: [],
        comparison_site: []
      };
      callback && callback();
      return {};
    } else {
      this.loading = true;
      const resp = await apiCall(variables, visibleSources?.slice() || []);

      runInAction(() => {
        this.allTypesList = {
          provider: resp?.provider?.companies || [],
          broker: resp?.broker?.companies || [],
          comparison_site: resp?.comparison_site?.companies || []
        };
        this.loading = false;
        callback && callback();
      });

      return resp;
    }
  }

  @action
  async load({
    filters,
    callback,
    // errorCallback,
    infinityClear = true,
    noCriteria,
    prefillCriteria
  }: {
    filters?: TFilter;
    callback?: () => void;
    // errorCallback?: () => void;
    infinityClear?: boolean;
    noCriteria?: Record<string, string>;
    prefillCriteria?: boolean;
  }) {
    let searchCriteria = toJS(this.rootStore.policyStore?.searchCriteria) || {};

    const isPersonalized = hasPolicyCriteria(this.rootStore.currentPolicy);
    const showCriteriaForUnAuthorized =
      criteriaFiltersUnAuthorized.includes(this.rootStore.currentCategory?.code) ||
      criteriaFiltersUnAuthorized.includes(this.rootStore.currentCategory?.root?.code);

    if (!isPersonalized) {
      if (showCriteriaForUnAuthorized && size(this.rootStore.policyStore.formOptions)) {
        const criteriaQuestions = {};
        Object.keys(this.rootStore.policyStore.formOptions).forEach(key => {
          const defaultValue = this.rootStore.policyStore.formOptions[key].defaultValue;
          if (defaultValue) {
            criteriaQuestions[key] = defaultValue;
          }
        });
        searchCriteria = criteriaQuestions;
      } else {
        searchCriteria = pickPersonalizedCriteriaQuestionsFromOptions(
          filters?.criteriaQuestions,
          this.rootStore.policyStore.formOptions,
          true
        );
      }
    }

    this.setFilters({
      ...filters,
      policyId: this.rootStore.currentPolicy?.id || filters?.policyId || null,
      criteriaQuestions: noCriteria
        ? {}
        : prefillCriteria
        ? Object.assign(searchCriteria, filters?.criteriaQuestions)
        : { ...this.filters.criteriaQuestions, ...filters?.criteriaQuestions }
    });

    if (this.filters.companyType === COMPANY_TYPE_EACH_TYPE.value) {
      return this.loadAllTypes(callback);
    }

    this.loading = true;
    if (infinityClear) {
      this.resetInfinityList();
      this.wikiData = initialWikiData;
    }

    const wikiLogic = isWikiTableCategory(this.rootStore.currentCategory);

    const apiCall = wikiLogic ? api.fetchWikiPricesCompanies : api.fetchCompaniesList;

    const resp = await apiCall(this.serializeFilters);

    if (!resp.errors.length) {
      runInAction(() => {
        this.list = resp.companies;
        this.setInfinityList(resp.companies);
        this.total = resp.totalCount || 0;
        this.meta = omit(resp, 'companies', 'totalCount', 'errors');
        this.wikiData = {
          wikiMatchCount: resp?.wikiMatchCount || 0,
          wikiMatchPercent: resp?.wikiMatchPercent || 0
        };
        this.loading = false;
        this.allCriteriasMatch = resp?.allCriteriasMatch;
      });
      if (callback) callback();
    } else {
      setError(resp.errors);
      // if (errorCallback) {
      //   errorCallback(resp.errors);
      // }
    }

    return resp;
  }

  @action
  async loadFeaturedBusinesses({ filters }) {
    this.featuredBusinessesLoading = true;
    this.featuredBusinesses = [];
    this.featuredBusinessesTotal = 0;
    const resp = await api.companyFeaturedBusinesses({
      ...filters,
      perPage: 10,
      page: 1
    });

    if (!resp.errors.length) {
      runInAction(() => {
        this.featuredBusinesses = [...resp.companies];
        this.featuredBusinessesTotal = resp.totalCount || 0;
        this.featuredBusinessesLoading = false;
      });
    } else {
      runInAction(() => {
        this.featuredBusinessesLoading = false;
      });
      setError(resp.errors);
    }
  }

  @computed get serializeFilters() {
    const filters = serializeFilters(toJS(this.filters));
    filters.policyId = filters?.policyId ? +filters?.policyId : null;
    if (filters.orderColumn === 'wiki_price' || filters.orderColumn === 'best_avg_prices') {
      filters.orderDirection = ASC;
    }
    if (this.isShortList) {
      return {
        ...filters,
        page: 1,
        perPage: FIRST_COMPANIES_PER_PAGE
      };
    }

    return serializeFilters(filters);
  }

  @action
  clearFilters() {
    const wikiLogic = isWikiTableCategory(this.rootStore.currentCategory);

    this.filters = {
      ...this.filters,
      // ...cloneDeep(omit(INIT_FILTERS, ['criteriaQuestions'])),
      ...cloneDeep(INIT_FILTERS),
      page: 1,
      orderColumn: wikiLogic ? ORDER_OPTIONS[0].value : INSURANCE_ORDER_OPTIONS[1].value,
      policyId: this.filters.policyId,
      categoryId: this.filters.categoryId,
      perPage: this.filters.perPage
    };
  }

  @action
  clearCompaniesList() {
    this.clearFilters();
    this.listInfinity = [];
    this.allTypesList = {
      provider: [],
      broker: [],
      comparison_site: []
    };
    this.featuredBusinesses = [];
    this.featuredBusinessesTotal = 0;
  }

  @action
  setFilters(newFilters) {
    this.filters = {
      ...this.filters,
      ...newFilters
    };
  }

  @action
  companyUpdateClicks({ companyId, categoryId }) {
    api.companyUpdateClicks({ companyId, categoryId }).then(() => {
      runInAction(() => {
        this.list = this.list.map(company => {
          if (company.id === companyId) {
            const clicks = company.companyClicks || 0;
            company.companyClicks = clicks + 1;
          }
          return company;
        });
      });
    });
  }

  @action
  loadAutocompleteCompanies({ categoryId, keyWord }) {
    const wikiLogic = isWikiTableCategory(this.rootStore.currentCategory);
    const apiCall = wikiLogic ? api.autocompleteWiki : api.autocomplete;

    apiCall({ categoryId, keyWord }).then(resp => {
      runInAction(() => {
        this.listAutocomplete = resp.companies;
        this.list = resp.companies;
        this.listInfinity = resp.companies;
        this.filters.page = 1;
        this.total = resp?.companies?.length || 0;
      });
    });
  }

  @action
  async loadGlobalRequestQuotesCount(categoryId: number) {
    const resp = await requestsApi.quoteAgentCount({
      categoryId,
      criteriaQuestions: {},
      isWiki: isWikiTableCategory(this.rootStore.currentCategory)
    });
    runInAction(() => {
      this.globalRequestQuotesCount = resp?.count || 0;
    });

    return resp;
  }

  @action
  async loadRequestQuotesCount(filters = this.serializeFilters) {
    const requestFilters = pick(
      {
        ...filters,
        criteriaQuestions: omit(filters?.criteriaQuestions, ['location']) || {},
        isWiki: isWikiTableCategory(this.rootStore.currentCategory)
      },
      ['criteriaQuestions', 'categoryId', 'city', 'orderColumn', 'isWiki']
    );

    const resp = await requestsApi.quoteAgentCount(requestFilters);
    runInAction(() => {
      this.requestQuotesCount = resp?.count || 0;
    });

    return resp;
  }

  @action
  setPersonalizeFilterStatus(status) {
    this.personalizeFilterStatus = status;
  }

  @action
  setIsShortList(val) {
    this.isShortList = val;
  }

  @computed
  get hasInfinityMore() {
    return this.listInfinity.length < this.total;
  }

  @action
  setInfinityList(resp) {
    if (this.addInfinity) {
      this.listInfinity = [...this.listInfinity, ...resp];
    } else {
      this.listInfinity = resp;
    }
  }

  @action
  handleShowMore = () => {
    this.setIsShortList(false);
    this.load({ filters: { page: 1, perPage: COMPANIES_PER_PAGE } });
  };

  @action
  handleShowLess = () => {
    this.setIsShortList(true);
    this.load({ filters: { page: 1, perPage: FIRST_COMPANIES_PER_PAGE } });
  };

  @action
  loadPage = (page, perPage = COMPANIES_PER_PAGE) => {
    this.load({ filters: { ...this.filters, page, perPage }, infinityClear: false });
  };

  @action
  resetInfinityList() {
    this.listInfinity = [];
    this.filters.page = 1;
  }

  @action
  allowAddInfinity(val) {
    this.addInfinity = val;
  }

  @action
  loadMorePage = () => {
    if (this.hasInfinityMore && !this.loading) {
      this.loadPage(this.filters.page + 1, COMPANIES_PER_PAGE);
    }
  };

  @action
  create(attrs) {
    return api.createCompany(attrs).then(resp => {
      if (resp.errors.length) {
        setError(resp.errors);
        return Promise.reject(resp.errors);
      }
      return resp;
    });
  }

  @action
  createQuote(attrs) {
    return api.createQuoteRequest(attrs).then(resp => {
      if (resp.errors.length) {
        setError(resp.errors);
        return Promise.reject(resp.errors);
      }

      return resp;
    });
  }
}
