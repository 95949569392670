import React from 'react';

export const GeneralFinancial = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path d='M12 1C11.1151 1 10.3284 1.39067 9.7793 2H8.00195C7.31895 2 6.83673 2.66941 7.05273 3.31641L7.49805 4.6582C7.56605 4.8622 7.75961 5 7.97461 5H12H16.0254C16.2404 5 16.432 4.8622 16.5 4.6582L16.9473 3.31641C17.1643 2.66941 16.681 2 15.998 2H14.2207C13.6716 1.39067 12.8849 1 12 1ZM8 7C8 7 2 10.014 2 17C2 17.835 2.10287 18.5764 2.29688 19.2344C2.78787 20.8984 4.37242 22 6.10742 22H17.8926C19.6276 22 21.2121 20.8984 21.7031 19.2344C21.8971 18.5764 22 17.835 22 17C22 10.015 16 7 16 7H8ZM12.3203 8.71289H12.3262C12.5782 8.71289 12.7832 8.91792 12.7832 9.16992V10.041C13.0262 10.087 14.1939 10.3526 14.5859 11.8086C14.7299 12.3446 14.3138 12.8691 13.7598 12.8691C13.3638 12.8691 13.0137 12.596 12.9297 12.209C12.7867 11.542 12.4254 11.4199 12.2344 11.4199C12.0454 11.4199 11.5469 11.4505 11.5469 12.4785C11.5469 14.2725 14.7012 13.7137 14.7012 16.6387C14.7012 18.7627 12.9574 19.0489 12.6484 19.0879V19.8438C12.6484 20.0958 12.4434 20.3008 12.1914 20.3008C11.9394 20.3008 11.7344 20.0958 11.7344 19.8438V19.0879C11.4934 19.0549 10.0813 18.8981 9.65625 17.2461C9.51925 16.7141 9.93733 16.1992 10.4863 16.1992H10.4883C10.8823 16.1992 11.2383 16.4656 11.3203 16.8516C11.5093 17.7386 12.0835 17.6934 12.1855 17.6934C12.3195 17.6934 12.9785 17.6684 12.9785 16.6504C12.9785 14.6164 9.82227 15.5197 9.82227 12.4707C9.82227 10.3807 11.5543 10.0546 11.8633 10.0156V9.16992C11.8633 8.91792 12.0683 8.71289 12.3203 8.71289Z' />
      </g>
    </svg>
  );
};
