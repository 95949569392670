import React from 'react';
import cx from 'classnames';

const selectTypes = ['select', 'location', 'carMake', 'carModel', 'petType', 'autocomplete', 'childrenCount'];

export const FieldContainer = ({ children, remount, error, attr, className, type, multiple = false }) => {
  const isSelectType = type && selectTypes.includes(type);

  return (
    <div
      className={cx('field-container', className, {
        error,
        remount,
        isSelectType
      })}
      data-parent={attr}
      // datatype={type}
      data-multiple={multiple}
    >
      {!remount ? children : null}
    </div>
  );
};
