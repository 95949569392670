export default () => ({
  title: {
    mb: 3
  },
  filtersWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 2
  }
});
