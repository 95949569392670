import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import omit from 'lodash-es/omit';

// TODO use in TS
// const propTypes = {
//   input: object.isRequired,
//   placeholder: string,
//   type: string,
//   className: string,
//   disabled: bool,
//   meta: shape({
//     touched: bool,
//     error: oneOfType([string, array])
//   }).isRequired,
//   componentType: string,
//   controlError: string
// };

export const FieldWithErrors = ({
  type,
  meta,
  input,
  maxRows = 10,
  minRows = 10,
  multiline = false,
  size = 'medium',
  variant = 'standard',
  label = '',
  touched,
  onEnter,
  disabled = false,
  className,
  onKeyPress,
  placeholder,
  autoComplete,
  controlError,
  componentType = 'input',
  hidePlaceholder,
  wrapperClassName,
  disableFocusReset,
  maxLength = 0,
  nextTab = false,
  material = false,
  onlyNumber = false,
  nextTabCount = 6,
  inputRef = null
}) => {
  const [controlledPlaceholder, setPlaceholder] = useState(placeholder);

  useEffect(() => {
    setPlaceholder(placeholder);
  }, [placeholder]);

  const onFocusHandler = () => {
    hidePlaceholder ? setPlaceholder('') : null;
    disableFocusReset || material ? null : input.onChange('');
  };

  const onBlurHandler = () => {
    if (hidePlaceholder) setPlaceholder(placeholder);
  };

  const handleKeyPress = ev => {
    if (onKeyPress) {
      onKeyPress(ev);
    } else if (ev.key === 'Enter' && onEnter && !ev.shiftKey) {
      ev.preventDefault();
      onEnter(ev);
    }
  };

  const handleKeyUp = ev => {
    if (nextTab && (ev.key === 'Backspace' || ev.key === 'Delete')) {
      const { name } = ev.target;
      const [fieldName, fieldIndex] = name.split('_');
      const prevSibling = document.querySelector(`input[name=code_${parseInt(fieldIndex, 10) - 1}]`);

      if (prevSibling !== null) {
        prevSibling.focus();
      }
    }
  };

  const handleChange = e => {
    const reg = new RegExp('^[0-9]+$');

    if (onlyNumber) {
      if (reg.test(e.target.value) || e.target.value === '') {
        input.onChange(e);
      }
    } else {
      input.onChange(e);
    }
    if (nextTab) {
      const { maxLength, value, name } = e.target;
      const [fieldName, fieldIndex] = name.split('_');

      if (value.length >= maxLength && reg.test(value)) {
        if (parseInt(fieldIndex, 10) < nextTabCount) {
          const nextSibling = document.querySelector(`input[name=code_${parseInt(fieldIndex, 10) + 1}]`);

          if (nextSibling !== null) {
            nextSibling.focus();
          }
        }
      }
    }
  };
  const componentProps = {
    ...input,
    onFocus: onFocusHandler,
    onBlur: onBlurHandler,
    onChange: handleChange,
    className: cx('form-input', className, { 'error-input': (touched || meta?.touched) && meta?.error, disabled }),
    onKeyPress: handleKeyPress,
    onKeyUp: handleKeyUp,
    placeholder: controlledPlaceholder,
    autoComplete,
    disabled,
    type,
    multiline,
    maxRows,
    minRows
  };

  const inputProps = {
    ...omit(componentProps, 'maxRows', 'minRows', 'multiline'),
    maxrows: maxRows,
    multiline: multiline ? multiline : undefined,
    minrows: minRows,
    rows: minRows
  };

  if (maxLength) {
    inputProps.maxLength = maxLength;
  }

  if (inputRef) {
    inputProps.ref = inputRef;
  }

  const spanError = error => error && <span className='error-hint lh-5'>{error}</span>;

  // Show password btn (by type)
  const [showPassword, setShowPassword] = useState(false);

  if (material) {
    return (
      <TextField
        {...inputProps}
        label={label}
        helperText={(touched || meta?.touched) && meta?.error}
        variant={variant}
        error={(touched || meta?.touched) && meta?.error}
        size={size}
        type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
        InputProps={{
          endAdornment:
            type === 'password' ? (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={() => setShowPassword(show => !show)}
                  onMouseDown={e => e.preventDefault()}
                  edge='end'
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ) : null
        }}
      />
    );
  }

  return (
    <div className={wrapperClassName}>
      {componentType === 'textarea' ? <textarea {...inputProps} /> : <input {...inputProps} />}
      {controlError === undefined
        ? (touched || meta?.touched) && spanError(meta?.error)
        : (touched || meta?.touched) && spanError(controlError)}
    </div>
  );
};
