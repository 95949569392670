import React, { useEffect, useState } from 'react';
import { get, size } from 'lodash-es';
import cx from 'classnames';
import moment from 'moment';

import { RadioButtonField } from '@packs/components';
import { PriceField } from '@packs/components/PriceField';
import { EnergyAdvancedFields } from '@packs/components/WinnerModal/EnergyAdvancedFields';
import { SelectFieldAnyCompany } from '@packs/components/WinnerModal/SelectFieldAnyCompany';
import { SelectFieldProviderCompany } from '@packs/components/WinnerModal/SelectFieldProviderCompany';
import { useHandleFormSubmit } from '@packs/components/WinnerModal/useHandleFormSubmit';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { perMonthCategories, rateCostList } from '@packs/lib/constants';
import { toastr } from '@packs/lib/helpers';
import { getIsoCode } from '@packs/lib/utils';
import { useCategories } from '@packs/models/category/useCategories';
import { recurringOptions } from '@packs/models/company/constants';
import { useDetails } from '@packs/models/policy/useDetails';
import { useStore } from '@packs/stores';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import api from '../../apis/currentUser';
import { useModalsContext } from '../../layouts/AppLayer/context';
import AutoQuotes from '../AutoQuotes';
import { DateFieldSelect } from '../DateFieldSelect';
import { FieldWithErrors, Loader, SignUpModalLink, Switcher } from '../index';
import { RateField } from '../RatingFormData/components';
import { SelectField } from '../SelectField';

import { ThankYouState } from './components/ThankYouState';
import { getMonthsList, getPeriodsList, getYearsList } from './constants/constants';

import { getParamByISO } from 'iso-country-currency';
import { MacScrollbar } from 'mac-scrollbar';
import { observer } from 'mobx-react';
import AnimateHeight from 'react-animate-height';
import { Modal, ModalHeader } from 'reactstrap';
import { Field } from 'redux-form';

export const cantRemember = {
  id: 'i_cant_remember',
  label: "I can't remember",
  name: "I can't remember",
  value: 'i_cant_remember'
};

const contractList = [
  { value: '12', label: '1 year' },
  { value: '18', label: '18 month' },
  { value: '30', label: '2.5 years' },
  { value: '36', label: '3 years' }
];

export const WinnerModal = observer(props => {
  const { reset, change, toggle, validate, isModalOpen, formAttributes, hideModal } = props;
  const { currentPolicy } = useDetails();
  const { currentCountry, policiesListStore, policyStore } = useStore();
  const { currentCategory } = useCategories();
  const { currentUser } = useUserSelectors();
  const { toggleVerificationModalOpen } = useModalsContext();
  const { isDesktop, isMobile } = useWindowSize();
  const [contractLength, setContractLength] = useState(contractList[0]);
  const [advancedPriceIsOpen, setAdvancedPriceIsOpen] = useState(false);
  const [localData, setLocalData] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitData, setSubmitData] = useState({});
  const { mixOptions } = policiesListStore;

  const renewalDetail = currentPolicy;
  const allTypeOptions = [cantRemember, ...mixOptions];
  const formErrors = validate(formAttributes) || {};

  const currencySymbol = getParamByISO(getIsoCode(currentCountry), 'symbol');
  const { addSmartReminder, triggeredAt, pricesQuestions } = formAttributes || {};
  const monthsList = getMonthsList();
  const per_period = pricesQuestions?.price?.period;
  const winnerCompany = formAttributes?.winnerCompany;
  const providerCompany = formAttributes?.providerCompany;
  const isWinnerSource = winnerCompany?.companyType === 'comparison_site' || winnerCompany?.companyType === 'broker';

  const energyCategory = currentCategory?.root?.code === 'energy';
  const electricity = currentCategory?.code === 'electricity';
  const gas = currentCategory?.code === 'gas';
  const electricityAndGas = currentCategory?.code === 'gas_and_electricity';

  const handleSubmitVerification = data => {
    toggleVerificationModalOpen({ modalProps: {} }, true);
    submit(data);
  };

  const handleVerification = async (data, reminder) => {
    setLoading(true);

    const resp = await api.phoneVerify({ phoneNumber: data?.phoneNumber.replace(/\s+/g, '') });
    if (resp?.errors) {
      toastr.error(resp?.errors);
      setLoading(false);
    } else {
      toggleVerificationModalOpen({
        modalProps: {
          handleSubmitVerification: () => handleSubmitVerification(localData, reminder),
          phoneNumber: data?.phoneNumber
        }
      });
      setLoading(false);
    }
  };

  const submit = data => {
    useHandleFormSubmit(
      { ...props, renewalDetail, data },
      currentCategory,
      res => {
        if (renewalDetail?.id) {
          toastr.success('Updated');
        } else {
          toastr.success('Created');
        }

        policyStore.updateCurrentPolicy(res?.renewalDetail || {});
        setSubmitData(res);
      },
      resp => {
        if (resp?.errors) {
          toastr.error(resp?.errors);
        }
      }
    );
  };

  const handleFormSubmit = data => {
    setLocalData(data);
    const phoneVerification =
      data?.phoneNumber &&
      get(currentUser, 'verifiedNumbers', []).some(item => item === data?.phoneNumber.replace(/\s+/g, ''));

    if (data?.isAutoQuote && !phoneVerification) {
      handleVerification(data);

      return false;
    }

    submit(data);
  };

  const onDateFieldChange = date => {
    const dateSelect = moment(date).add(1, 'hour').toDate();

    change('triggeredAt', dateSelect);
  };

  const toggleEnableReminder = () => {
    change('addSmartReminder', !addSmartReminder);
  };

  useEffect(() => {
    if (isModalOpen) {
      if (
        perMonthCategories.includes(currentCategory?.code) ||
        perMonthCategories.includes(currentCategory?.root?.code)
      ) {
        change('pricesQuestions.price.period', getPeriodsList[0].value);
      } else {
        change('pricesQuestions.price.period', getPeriodsList[1].value);
      }

      if (electricity || electricityAndGas) {
        change('advancedPrice.electricity.includingTaxes', rateCostList[0].value);
      }

      if (gas || electricityAndGas) {
        change('advancedPrice.gas.includingTaxes', rateCostList[0].value);
      }
      const verifiedNumbers = currentUser?.verifiedNumbers;
      const phoneNumber = verifiedNumbers?.length ? verifiedNumbers[verifiedNumbers.length - 1] : null;
      change('phoneNumber', phoneNumber);
    }
  }, [isModalOpen]);

  useEffect(() => {
    return () => {
      reset();
      setContractLength(contractList[0]);
    };
  }, []);

  if (!formAttributes) {
    return null;
  }

  return (
    <Modal
      isOpen={isModalOpen}
      toggle={() => toggle()}
      className={cx('winner-modal', currentCategory?.root?.enName, {
        hideModal,
        'winner-thank-you-modal': size(submitData) > 0
      })}
    >
      {size(submitData) === 0 ? (
        <>
          {loading && <Loader local />}
          <ModalHeader toggle={toggle} charCode='x'>
            Pick your winner!
          </ModalHeader>
          <form className='form' onSubmit={props.handleSubmit(handleFormSubmit)}>
            <div className='text-center secondary-text fs-16 pb-24'>{currentCategory.name}</div>
            <MacScrollbar
              style={{ maxHeight: 'calc(100vh - 120px)' }}
              trackStyle={horizontal => ({
                [horizontal ? 'height' : 'width']: 0,
                right: 2,
                border: 0
              })}
              thumbStyle={horizontal => ({ [horizontal ? 'height' : 'width']: 4 })}
              suppressAutoHide
            >
              <div className='modal-content-inner'>
                <div className='form-row-list gap-16'>
                  <div className='input-field'>
                    <div className='label small-label'>My Winner</div>
                    <SelectFieldAnyCompany
                      companyId={winnerCompany?.id}
                      companyType={winnerCompany?.companyType}
                      allTypeOptions={allTypeOptions}
                      className='white-bg with-light-border'
                    />
                  </div>
                  {isWinnerSource && (
                    <div className='input-field'>
                      <div className='label small-label'>
                        Provider <span>(Optional)</span>
                      </div>
                      <SelectFieldProviderCompany
                        companyId={providerCompany?.id}
                        companyType='provider'
                        allTypeOptions={allTypeOptions}
                        className='white-bg with-light-border'
                      />
                    </div>
                  )}
                </div>

                <div className='form-row-list'>
                  <div className='input-field'>
                    <div className='label'>Please rate your shopping experience</div>
                    <Field
                      component={RateField}
                      name='purchaseScore'
                      controlError={formErrors?.purchaseScore}
                      likeText='Very Happy'
                      unLikeText='Very Unhappy'
                    />
                  </div>
                </div>

                <div className='form-row-list'>
                  <div className='input-field'>
                    <Field
                      component={FieldWithErrors}
                      componentType='textarea'
                      name='purchaseComment'
                      className='square light-border'
                      placeholder=' Please share any purchase insights / tips here (Optional)'
                    />
                  </div>
                </div>

                <div className='form-row-list'>
                  <div className='input-field'>
                    <div className='form-row-list align-items-center justify-content-between gap-16'>
                      <div className='flex-grow-1'>
                        <Field
                          name='pricesQuestions.price.value'
                          currencySymbol={currencySymbol}
                          component={PriceField}
                          placeholder='PYour new price...'
                          className='circle light-border'
                          customNumeric
                        />
                      </div>
                      <Field
                        name='pricesQuestions.price.period'
                        options={getPeriodsList}
                        component={RadioButtonField}
                        className='radio-tabs'
                      />
                    </div>
                  </div>
                </div>

                <div className='form-row-list'>
                  <div className='winner-switcher-container'>
                    <div
                      className={cx('d-flex align-items-center justify-content-between', {
                        active: advancedPriceIsOpen
                      })}
                    >
                      <span className='fs-14 fw-500'>
                        {energyCategory ? 'Track your unit prices (Optional)' : 'Advanced prices settings (Optional)'}
                      </span>
                      <Switcher
                        primary
                        value={advancedPriceIsOpen}
                        size='medium'
                        onChange={() => setAdvancedPriceIsOpen(!advancedPriceIsOpen)}
                      />
                    </div>
                    <AnimateHeight duration={250} height={advancedPriceIsOpen ? 'auto' : 0}>
                      {energyCategory ? (
                        <EnergyAdvancedFields {...{ gas, electricityAndGas }} />
                      ) : (
                        <div className='form-row-list gap-16'>
                          <div className='input-field'>
                            <div className='label'>
                              <span>{pricesQuestions?.price?.value || 0}€</span>
                              {per_period === 'per_year' ? ' for the first year' : ' per month for the first'}:
                            </div>
                            <Field
                              name='discountPrice.period'
                              className='select-ui white-bg with-light-border'
                              classNamePrefix='Select'
                              placeholder='Select period...'
                              options={per_period === 'per_year' ? getYearsList : monthsList}
                              component={SelectField}
                            />
                          </div>
                          <div className='input-field'>
                            <div className='label'>
                              {per_period === 'per_year' ? 'Per year after that' : 'After that'}:
                            </div>
                            <Field
                              name='discountPrice.priceAfter'
                              placeholder='Price...'
                              currencySymbol={currencySymbol}
                              component={PriceField}
                              className='circle light-border'
                            />
                          </div>
                        </div>
                      )}
                    </AnimateHeight>
                  </div>
                </div>
                <div className='form-row-list'>
                  <div className='winner-switcher-container'>
                    <div
                      className={cx('d-flex align-items-center justify-content-between', {
                        active: addSmartReminder
                      })}
                    >
                      <span className='fs-14 fw-500'>Set Reminder (Optional)</span>
                      <Switcher primary value={addSmartReminder} size='medium' onChange={toggleEnableReminder} />
                    </div>
                    <AnimateHeight duration={250} height={addSmartReminder ? 'auto' : 0}>
                      <div className='reminder-wrapper d-flex'>
                        <Field
                          component={DateFieldSelect}
                          name='triggeredAt'
                          classNamePrefix='Select'
                          className='white-bg with-light-border'
                          disabled={!addSmartReminder}
                          placeholder='Choose a month'
                          defaultMonthsNumber={+contractLength.value || null}
                          isEdit
                          onChange={onDateFieldChange}
                        />
                      </div>
                      <div className='reminder-wrapper mt-16'>
                        <Field
                          component={SelectField}
                          className='white-bg with-light-border no-scale'
                          classNamePrefix='Select'
                          name='recurringTime'
                          options={recurringOptions}
                          placeholder='Select period'
                          isSearchable={false}
                        />
                      </div>
                      <AutoQuotes
                        formAttributes={{ ...formAttributes, categoryId: currentCategory.id }}
                        {...{ change }}
                      />
                    </AnimateHeight>
                  </div>
                </div>

                <div className='form-row-list'>
                  <div className='input-field'>
                    <div className='d-flex align-items-center justify-content-center pt-16'>
                      {currentUser.id ? (
                        <button type='submit' className='btn full primary white'>
                          <span>Pick your winner!</span>
                        </button>
                      ) : (
                        <SignUpModalLink
                          className='btn full primary white'
                          onSignUpSuccess={props.handleSubmit(handleFormSubmit)}
                        >
                          <span>Pick your winner!</span>
                        </SignUpModalLink>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </MacScrollbar>
          </form>
        </>
      ) : (
        <ThankYouState {...{ toggle, submitModalData: submitData }} />
      )}
    </Modal>
  );
});
