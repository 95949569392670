export const fetchCategoryAgentsShort = `
  query categoryAgents(
    $categoryId: Int,
    $criteriaQuestions: JSON,
    $page: Int,
    $perPage: Int
  ) {
    categoryAgents(
      categoryId: $categoryId,
      criteriaQuestions: $criteriaQuestions,
      page: $page,
      perPage: $perPage
    ) {
    companies {
        id
        avatarUrl
    }
    totalCount
    errors
  }
}
`.trim();
