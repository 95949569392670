import React from 'react';

export const WasteCollectionCommercialBinCollection = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path
          id='Vector'
          d='M12.0001 1C11.4481 1 11.0001 1.448 11.0001 2V9H7.71685C6.72985 9 5.88834 9.71931 5.73834 10.6953L5.62701 11.4238C5.58001 11.7268 5.81515 12 6.12115 12H17.879C18.186 12 18.4201 11.7268 18.3731 11.4238L18.2618 10.6953C18.1118 9.71931 17.2703 9 16.2833 9H13.0001V2C13.0001 1.448 12.5521 1 12.0001 1ZM5.66021 14C5.41321 14 5.20307 14.1798 5.16607 14.4238L4.17779 20.8477C4.08379 21.4537 4.55307 22 5.16607 22H7.80475C8.32075 22 8.75284 21.6087 8.80084 21.0957L9.00006 19L9.41803 21.1875C9.50703 21.6595 9.9185 22 10.3985 22H13.5352C14.0432 22 14.4714 21.6183 14.5294 21.1133L15.0001 17L15.9649 21.2227C16.0689 21.6777 16.4735 22 16.9395 22H18.834C19.447 22 19.9163 21.4537 19.8223 20.8477L18.834 14.4238C18.797 14.1798 18.5869 14 18.3399 14H5.66021Z'
        />
      </g>
    </svg>
  );
};
