import { criteriaQuestionListQuery } from '@packs/graphql/queries/criteriaQuestion';
import {
  createRenewalDetail,
  fetchRenewalDetailFormOptions,
  fetchRenewalDetailFormOptionsCompanies,
  fetchRenewalDetailQuery,
  fetchRenewalDetailsList,
  fetchRenewalDetailsProfileList,
  fetchUserPreviewReviews,
  fetchUserRenewalDetails,
  quoteRequestRecommend,
  renewalDetailAveragePriceDifference,
  renewalDetailDynamicFormOptionsQuery,
  toggleHelpfulRenewalDetail,
  updateAdvancedPriceDetail,
  updateIsAutoQuote,
  updateOrCreateRenewalDetail,
  updateOrCreateWinnerDetail,
  updateRenewalDetail,
  updateRenewalDetailCriteria
} from '@packs/graphql/queries/renewalDetail';
import { ICompany, IPolicy } from '@packs/interfaces/rootInterfaces';
import { graphQLRequest } from '@packs/lib/utils';

export const renewalDetailApi = {
  fetchRenewalDetail: variables =>
    graphQLRequest({
      query: fetchRenewalDetailQuery as unknown as Record<string, string>,
      variables
    }) as Promise<IPolicy>,
  fetchUserRenewalDetails: variables =>
    graphQLRequest({
      query: fetchUserRenewalDetails as unknown as Record<string, string>,
      variables
    }) as Promise<IPolicy[]>,
  fetchUserPreviewReviews: variables =>
    graphQLRequest({
      query: fetchUserPreviewReviews as unknown as Record<string, string>,
      variables
    }),
  fetchRenewalDetailsList: variables =>
    graphQLRequest({
      query: fetchRenewalDetailsList as unknown as Record<string, string>,
      variables
    }),
  fetchRenewalDetailsProfileList: variables =>
    graphQLRequest({
      query: fetchRenewalDetailsProfileList as unknown as Record<string, string>,
      variables
    }),
  fetchRenewalDetailFormOptions: ({ categoryId }) =>
    graphQLRequest({
      query: fetchRenewalDetailFormOptions as unknown as Record<string, string>,
      variables: { categoryId }
    }) as Promise<{
      pricesQuestionsOptions: Record<string, string>;
      criteriaDetailsQuestionsOptions: Record<string, string>;
    }>,
  fetchRenewalDetailFormOptionsCompanies: ({ categoryId }) =>
    graphQLRequest({
      query: fetchRenewalDetailFormOptionsCompanies as unknown as Record<string, string>,
      variables: { categoryId }
    }) as Promise<{
      brokerCompanies: ICompany[];
      comparisonSiteCompanies: ICompany[];
      providerCompanies: ICompany[];
    }>,
  createRenewalDetail: variables =>
    graphQLRequest({
      query: createRenewalDetail as unknown as Record<string, string>,
      variables: { input: variables }
    }),
  updateRenewalDetail: variables =>
    graphQLRequest({
      query: updateRenewalDetail as unknown as Record<string, string>,
      variables: { input: variables }
    }),
  updateRenewalDetailCriteria: variables =>
    graphQLRequest({
      query: updateRenewalDetailCriteria as unknown as Record<string, string>,
      variables: { input: variables }
    }),
  updateIsAutoQuote: variables =>
    graphQLRequest({
      query: updateIsAutoQuote as unknown as Record<string, string>,
      variables: { input: variables }
    }),
  toggleHelpfulRenewalDetail: variables =>
    graphQLRequest({
      query: toggleHelpfulRenewalDetail as unknown as Record<string, string>,
      variables: { input: variables }
    }),
  updateOrCreateWinnerDetail: variables =>
    graphQLRequest({
      query: updateOrCreateWinnerDetail as unknown as Record<string, string>,
      variables: { input: variables }
    }),
  updateAdvancedPrice: variables =>
    graphQLRequest({
      query: updateAdvancedPriceDetail as unknown as Record<string, string>,
      variables: { input: variables }
    }),
  updateOrCreateBillDetails: variables =>
    graphQLRequest({
      query: updateOrCreateRenewalDetail as unknown as Record<string, string>,
      variables: { input: variables }
    }),
  fetchCriteriaQuestionList: variables =>
    graphQLRequest({
      query: criteriaQuestionListQuery as unknown as Record<string, string>,
      variables
    }),
  fetchRenewalDetailDynamicFormOptions: variables =>
    graphQLRequest({
      query: renewalDetailDynamicFormOptionsQuery as unknown as Record<string, string>,
      variables
    }),
  quoteRequestRecommend: variables =>
    graphQLRequest({
      query: quoteRequestRecommend as unknown as Record<string, string>,
      variables
    }),
  calcAveragePriceDifference: variables =>
    graphQLRequest({
      query: renewalDetailAveragePriceDifference as unknown as Record<string, string>,
      variables
    })
};
