import React from 'react';

export const BusinessPublicLiabilityInsurance = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <g>
          <path d='M20.5859 12C20.2239 12 19.8619 12.1381 19.5859 12.4141L15 17H11C10.724 17 10.5 16.776 10.5 16.5C10.5 16.224 10.724 16 11 16H13.5C14.328 16 15 15.328 15 14.5C15 13.672 14.328 13 13.5 13H8.65625C7.59525 13 6.57812 13.4209 5.82812 14.1719L2 18L6 22L8 20H15.1719C15.7019 20 16.2109 19.7891 16.5859 19.4141L21.5859 14.4141C22.1379 13.8621 22.1379 12.9661 21.5859 12.4141C21.3099 12.1381 20.9479 12 20.5859 12Z' />
          <path d='M18 4.35294V6.51287C18 9.89522 15.4667 11.5772 14.2222 11.9724C14.1511 11.9908 14.0711 12 14 12C13.9289 12 13.8489 11.9908 13.7778 11.9724C12.5333 11.5772 10 9.89522 10 6.51287V4.35294C10 3.98529 10.2044 3.6636 10.5244 3.51654L10.7467 3.41544L13.6356 2.08272C13.7511 2.02757 13.8756 2 14 2C14.1244 2 14.2489 2.02757 14.3644 2.08272L17.4756 3.51654C17.7956 3.6636 18 3.98529 18 4.35294Z' />
        </g>
      </g>
    </svg>
  );
};
