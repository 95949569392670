import colors from '@packs/assets/theme/base/colors';

const { white } = colors;

export default () => ({
  wrapper: {
    display: 'flex',
    flexDirection: { xs: 'column', lg: 'row' },
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 2
  },
  updateBtn: {
    order: {
      xs: 2,
      lg: 1
    },
    whiteSpace: 'nowrap',
    '&:hover': {
      color: white.main
    }
  },
  requestBtnWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    width: { xs: '100%', md: 'auto' },
    order: {
      xs: 1,
      lg: 2
    }
  },
  requestBtn: {
    whiteSpace: 'nowrap'
  }
});
