import { omit, size } from 'lodash-es';

import companyApi from '@packs/apis/company';
import { BASE_APP_URL } from '@packs/lib/constants';
import { getWindow } from '@shared/lib/utils';

import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

export const sortStates = (a, b, value) => {
  const aLower = a?.name.toLowerCase() || a?.label.toLowerCase();
  const bLower = b?.name.toLowerCase() || b?.label.toLowerCase();
  const valueLower = value.toLowerCase();
  const queryPosA = aLower.indexOf(valueLower);
  const queryPosB = bLower.indexOf(valueLower);
  if (queryPosA !== queryPosB) {
    return queryPosA - queryPosB;
  }
  return aLower < bLower ? -1 : 1;
};

export const convertEditorStateToHtml = data => {
  const options = {
    entityStyleFn: entity => {
      const entityType = entity.get('type').toLowerCase();
      if (entityType === 'mention') {
        const data = entity.getData();

        return {
          element: 'a',
          attributes: {
            href: data.mention.link,
            target: '_blank',
            className: 'm6zwb4v',
            spellcheck: false,
            'data-testid': 'mentionLink'
          }
        };
      }
    }
  };
  try {
    const descriptionState = convertFromRaw(JSON.parse(data));

    return stateToHTML(descriptionState, options);
  } catch (e) {
    return '';
  }
};

export const encodeAssociationUrl = associationName => {
  if (!associationName) {
    return '';
  }

  return associationName.replaceAll(' ', '_').replaceAll('&', '');
};

export const decodeAssociationUrl = associationName => {
  if (!associationName) {
    return '';
  }

  return associationName.replaceAll('_', ' ');
};

export const shortSecondName = displayName => {
  if (!displayName) {
    return '';
  }

  const name = displayName.charAt(0).toUpperCase() + displayName.slice(1);

  const splitName = name.split(' ');

  if (splitName.length === 1) {
    return name;
  }

  return splitName
    .map((item, index) => {
      if (splitName.length === index + 1) {
        return `${item.split('')[0].toUpperCase()}.`;
      }
      return item;
    })
    .join(' ');
};

export const shareOnTheFacebook = ({ link = BASE_APP_URL }) => {
  const url = `https://www.facebook.com/sharer/sharer.php?u=${BASE_APP_URL + link}`;
  const window_size = 'width=585,height=368';

  getWindow().open(url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,' + window_size);
};

export const setTourProgress = (tourName, keys, value) => {
  const myResultsTour = getWindow().localStorage.getItem(tourName);
  const tour = myResultsTour ? JSON.parse(myResultsTour) : {};
  tour[tourName] = {
    ...tour[tourName],
    ...keys.reduce((acc, item) => ({ ...acc, [item]: value }), {})
  };

  getWindow().localStorage.setItem(tourName, JSON.stringify(tour));
};

export const hasPolicyCriteria = renewalDetail => {
  const criteria = omit({ ...(renewalDetail?.criteriaQuestions || {}) }, 'location');
  return !!size(criteria) && renewalDetail?.policyType !== 'simple';
};

export const handleVisitCompany = async (company, category) => {
  await companyApi.companyVisitCounter({
    companyId: company.id,
    categoryId: category?.id || undefined
  });
};
