import { landingSectionFragment } from './fragments/landingSectionFragment';

export const categoryLandingPage = `
  query categoryLandingPage(
    $categoryId: Int,
  ) {
    categoryLandingPage(
      categoryId: $categoryId,
    ) {
    blockHero {
      ${landingSectionFragment}
    }
    blockHeroImgUrl
    blockFaq {
      ${landingSectionFragment}
    }
    blockGuide {
      ${landingSectionFragment}
    }
    blockGuideImgUrl
    blockLearnMore {
      ${landingSectionFragment}
    }
    blockPurchase {
      ${landingSectionFragment}
    }
    criteriaQuestions {
      id
      name
      type
      label
      index
      defaultValue
      options {
        label
        value
      }
    }
  }
}
`.trim();
