import colors from '@packs/assets/theme/base/colors';

const { text, dark } = colors;

export default () => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    mt: 0.5
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    color: dark.main,
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  with: {
    color: text.secondary
  }
});
