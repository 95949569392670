import Box from '@mui/material/Box';
import PaginationMUI from '@mui/material/Pagination';

export const Pagination = ({ count = 1, page = 1, shape = 'rounded', color = 'primary', onChange }) => {
  const handleChange = (event, value) => {
    if (onChange && value !== page) {
      onChange(value);
    }
  };

  if (!page || !count) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <PaginationMUI page={page} count={count} shape={shape} color={color} onChange={handleChange} />
    </Box>
  );
};
