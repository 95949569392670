import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import OfferLabelIcon from '@packs/images/svg/ui/offer-label.svg';

import useStyles from './styles';

import get from 'lodash-es/get';

export const CompanyOffer = ({ company }) => {
  const styles = useStyles();

  const offerMessage = get(company, 'partnerProvider.offerMessage', '');
  if (!offerMessage) {
    return null;
  }
  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.label}>
        <img src={OfferLabelIcon} alt='offer' />
      </Box>
      <Typography variant='body3' sx={styles.text}>
        {offerMessage}
      </Typography>
    </Box>
  );
};
