import React, { FC } from 'react';

import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';
import colors from '@packs/assets/theme/base/colors';
import { ICategory } from '@packs/interfaces/rootInterfaces';
import { SUB_ICONS } from '@shared/helpers/subCategoryIcon';

const { white } = colors;

type Props = {
  category: ICategory;
  borderRadius?: string;
  backgroundColor?: string;
  color?: string;
  containerSize?: string | number;
  iconSize?: string;
  sx?: SxProps;
};
export const CategoryIconBox: FC<Props> = ({
  category,
  borderRadius = '4px',
  backgroundColor = white.main,
  color = white.main,
  containerSize = '24px',
  iconSize = '75%',
  sx
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: containerSize,
        minWidth: containerSize,
        height: containerSize,
        borderRadius,
        backgroundColor,
        ...sx,
        '& svg': {
          width: `${iconSize} !important`,
          height: `${iconSize} !important`,
          fill: `${color} !important`,
          zIndex: 2
        }
      }}
    >
      {SUB_ICONS({ category })}
    </Box>
  );
};
