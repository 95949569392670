import React, { useRef, useState } from 'react';

import Button from '@mui/material/Button';
import { FieldWithErrors } from '@packs/components/FieldWithErrors';
import { SelectField } from '@packs/components/SelectField';
import { SignUpModalLink } from '@packs/components/SignUpModalLink';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { runFormSubmit, toastr } from '@packs/lib/helpers';
import { useCategories } from '@packs/models/category/useCategories';
import { useStore } from '@packs/stores';

import startCase from 'lodash-es/startCase';
import { observer } from 'mobx-react';
import { ModalBody, ModalFooter } from 'reactstrap';
import { Field } from 'redux-form';

export const NewProviderForm = observer(
  ({ handleSubmit, formAttributes, toggle, onCreate, validate, enableUnAuthBusiness }) => {
    const { companiesStore, countriesSelectOptions } = useStore();
    const { currentUser } = useUserSelectors();
    const { categoriesOptions } = useCategories();
    const [errors, setErrors] = useState(false);
    const formRef = useRef();

    const resetErrors = () => {
      setErrors(null);
    };

    const metaError = name => {
      const mess = errors && errors[name];
      if (!mess) return null;
      return { meta: { touched: !!mess, error: mess } };
    };

    const onSubmit = ev => {
      const { categoryId, companyModalType, location, name } = formAttributes || {};

      companiesStore
        .create({
          categoryId,
          countryCode: location,
          name,
          companyModalType
        })
        .then(res => {
          const company = res?.company;
          if (Object.keys(res?.errors)?.length === 0) {
            if (!currentUser.id && enableUnAuthBusiness) {
              toastr.success('Thanks for sharing! Your request will be reviewed soon.');
            } else {
              toastr.success(`New ${companyModalType} successed created`);
            }
            onCreate && onCreate(company);
            toggle();
          } else {
            Object.keys(res?.errors).map(key => {
              toastr.error(`${startCase(key)} ${res?.errors[key]}`);
            });
          }
        })
        .catch(_errors => {
          setErrors(_errors);
        });
    };

    const isFormValid = () => {
      const errors = validate(formAttributes);

      if (Object.keys(errors).length > 0) {
        setErrors(errors);

        return false;
      }
      return true;
    };

    return (
      <form id='new-provider-form' ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <label className='input-field'>
            <div className='input-field'>
              <Field
                component={SelectField}
                classNamePrefix='Select'
                name='location'
                options={countriesSelectOptions}
                material
                placeholder='Select Country'
                size='small'
                onChange={resetErrors}
                {...metaError('location')}
              />
            </div>
          </label>

          <div className='input-field webkit-scrollbar'>
            <Field
              component={SelectField}
              className='theme transparent'
              classNamePrefix='Select'
              name='categoryId'
              optionsType='nested'
              placeholder='Select Category'
              options={categoriesOptions}
              material
              size='small'
              onChange={resetErrors}
              {...metaError('categoryId')}
            />
          </div>

          <div className='input-field webkit-scrollbar'>
            <Field
              component={SelectField}
              className='theme transparent'
              classNamePrefix='Select'
              name='companyModalType'
              options={[
                {
                  value: 'provider',
                  label: 'Provider',
                  iconType: 'provider'
                },
                {
                  value: 'broker',
                  label: 'Broker',
                  iconType: 'broker'
                },
                {
                  value: 'comparison_site',
                  label: 'Comparison Site',
                  iconType: 'comparison_site'
                }
              ]}
              placeholder='Please select Business type'
              onChange={resetErrors}
              material
              size='small'
              {...metaError('companyModalType')}
            />
          </div>

          <label className='input-field'>
            <Field
              component={FieldWithErrors}
              type='text'
              name='name'
              material
              variant='outlined'
              size='medium'
              label='Business name'
              onChange={resetErrors}
              {...metaError('name')}
            />
          </label>
        </ModalBody>

        <ModalFooter>
          <Button variant='text' color='dark' onClick={toggle}>
            Cancel
          </Button>
          {currentUser?.id || enableUnAuthBusiness ? (
            <Button
              variant='contained'
              color='primary'
              // disabled={!confirmed}
              type='submit'
              onClick={ev => {
                if (!isFormValid()) {
                  ev.preventDefault();
                }
              }}
            >
              Add a Business
            </Button>
          ) : (
            <SignUpModalLink
              variant='contained'
              color='primary'
              validate={isFormValid}
              onSignUpSuccess={() => {
                runFormSubmit(formRef.current);
              }}
            >
              Add a Business
            </SignUpModalLink>
          )}
        </ModalFooter>
      </form>
    );
  }
);
