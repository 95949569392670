import React from 'react';

export const GeneralClothingAndFootwear = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path d='M12 5C10 5 9.00003 3 9.00003 3H8.38503C7.47903 3 6.58903 3.246 5.81203 3.713L2.60403 5.637C2.24203 5.855 2.05503 6.277 2.13803 6.691L2.83903 10.196C2.93303 10.664 3.34303 11 3.82003 11H5.00003V19C5.00003 20.105 5.89503 21 7.00003 21H17C18.105 21 19 20.105 19 19V11H20.18C20.657 11 21.067 10.664 21.161 10.196L21.862 6.691C21.945 6.277 21.758 5.855 21.396 5.637L18.188 3.712C17.41 3.246 16.521 3 15.615 3H15C15 3 14 5 12 5Z' />
      </g>
    </svg>
  );
};
