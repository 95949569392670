export default () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1.5
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    gap: 1
  }
});
