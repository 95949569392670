import _ from 'lodash-es';

import { getSessionToken } from '@packs/lib/utils';
import { getWindow } from '@shared/lib/utils';

import request from 'superagent';

const GRAPHQL_URL = '/:country/graphql';
const DEFAULT_COUNTRY_CODE = 'en-ie';

const getQueryBody = query => {
  if (!query) {
    return undefined;
  }

  return query.loc ? query.loc.source.body.trim() : query;
};

const getMatchQuery = query => {
  const name = query.match(/(?:query)\s*\w+/);
  return name ? `${name}`.replace('query ', 'q:') : null;
};

const getMatchMutation = query => {
  const name = query.match(/(?:mutation)\s*\w+/);
  return name ? `${name}`.replace('mutation ', 'm:') : null;
};

const getUrl = (query, queries) => {
  const queryName = queries?.length
    ? queries.map(q => getMatchQuery(q) || getMatchMutation(q)).join('-')
    : getMatchQuery(query) || getMatchMutation(query);
  return GRAPHQL_URL.concat(`?${queryName}`).replace(':country', retrieveCountryCode());
};

interface IGraphQLRequestProps {
  query: Record<string, string>;
  variables: Record<string, string>;
  type?: string;
  data?: string;
  queries?: Record<string, string>[];
}
export const graphQLRequest = ({ type, query, variables, data, queries }: IGraphQLRequestProps) => {
  const queryStr = getQueryBody(query);

  let result = null;
  if (type === 'multipart') {
    result = whenMultipart({ query: queryStr, data: data || {} });
  } else {
    const queriesDataStr = queries?.map(d => ({
      ...d,
      query: getQueryBody(d.query)
    }));

    result = whenJSON({ query: queryStr, variables, data: data || {}, queries: queriesDataStr });
  }

  return new Promise((resolve, reject) => {
    result.then(resolve).catch(error => {
      console.error(
        `${error.status} (${error.message})`,
        _.get(error, 'response.body.error.message'),
        (_.get(error, 'response.body.error.backtrace') || []).slice(0, 3)
      );
    });
  });
};

const whenMultipart = ({ query, data }) => {
  const url = getUrl(query, []);

  let requestObj = withRequestHeaders(request.post(url).field('query', query));

  if (data instanceof Array) {
    data.forEach(({ key, value }) => {
      if (value instanceof File || value instanceof Blob) {
        requestObj = requestObj.attach(key, value, value.name || value.filename);
      } else if (value) {
        requestObj = requestObj.field(key, value);
      }
    });
  } else {
    for (const key in data) {
      if (data[key] instanceof File || data[key] instanceof Blob) {
        requestObj = requestObj.attach(key, data[key], data[key].name || data[key].filename);
      } else if (data[key]) {
        requestObj = requestObj.field(key, data[key]);
      }
    }
  }

  return requestObj.then(parseResponse);
};

const whenJSON = ({ query, data, queries, variables }) => {
  const url = getUrl(
    query,
    queries?.map(s => s.query)
  );

  const requestObj = withRequestHeaders(request.post(url), 'json');

  return requestObj.send({ query, ...data, queries, variables }).then(res => parseResponse(res, queries));
};

const withRequestHeaders = (requestObj, type = '') => {
  if (type === 'json') {
    requestObj = requestObj.set('Accept', 'application/json');
  }

  if (getWindow().localStorage.getItem('X-Authorization')) {
    requestObj = requestObj.set('X-Authorization', getWindow().localStorage.getItem('X-Authorization'));
  }

  if (getSessionToken()) {
    requestObj = requestObj.set('SESSION-TOKEN', getSessionToken());
  }

  return requestObj;
};

const parseResponse = (res, queries) => {
  if (res.headers['set-authorization']) {
    getWindow().localStorage.setItem('X-Authorization', res.headers['set-authorization']);
  }

  if (queries) {
    const resp = {};
    let key;
    res.body.forEach((bodyElement, index) => {
      if (bodyElement.data) {
        key = Object.keys(bodyElement.data)[0];
        if (queries[index].responseKey) {
          resp[queries[index].responseKey] = bodyElement.data[key];
        } else {
          resp[key] = bodyElement.data[key];
        }
      } else {
        // eslint-disable-next-line
        console.error(bodyElement);
        return {};
      }
    });
    return resp;
  }

  if (res.body.data) {
    return Object.values(res.body.data)[0];
  }
  // eslint-disable-next-line
  console.error(res.body);
  return res.body || {};
};

const retrieveCountryCode = () => {
  const url = getWindow().location.pathname;
  const countryCode = url.split('/')[1];

  return countryCode || DEFAULT_COUNTRY_CODE;
  // return window.location.pathname.match(/^\/(\w{2}).*/)[1] || DEFAULT_COUNTRY_CODE;
};

// file download by url
const urlFileName = url => {
  const [first, _] = url.split('?');
  const data = first.split('/');
  return data[data.length - 1];
};

export const downloadFile = ({ url, filename }) => {
  return fetch(url)
    .then(resp => resp.blob())
    .then(blob => downloadBlobFile({ blob, filename }));
};

export const downloadBlobFile = ({ blob, filename }) => {
  const urlCreator = getWindow().URL || getWindow().webkitURL;
  const uri = urlCreator.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = uri;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
  urlCreator.revokeObjectURL(uri);
};
