import colors from '@packs/assets/theme/base/colors';

const { borderColor, white } = colors;

export default () => ({
  companyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: '16px',
    border: `2px solid ${borderColor.main}`,
    backgroundColor: white.main
  },
  mainSection: {
    display: 'flex',
    alignItems: 'stretch'
  },
  companyInfoSection: {
    flexGrow: 1,
    px: 3,
    pt: 3,
    pb: {
      xs: 2,
      lg: 3
    }
  },
  companyTopInfo: {
    display: 'flex',
    gap: 2
  },
  avatarContainer: {
    position: 'relative'
  },
  placeIcon: {
    position: 'absolute',
    top: '-8px',
    left: '-8px',
    zIndex: 2
  },
  companyName: {
    textDecoration: 'underline'
  },
  companyBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 1,
    mt: 1
  },
  ratingInfoSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 1,
    borderLeft: `2px solid ${borderColor.main}`,
    minWidth: {
      sx: '100%',
      md: '326px'
    },
    p: 3
  },
  mobileBottomSection: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: `1px solid ${borderColor.main}`,
    px: 3,
    py: 2
  },
  placeWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '28px',
    borderRadius: '4px',
    border: `1px solid ${borderColor.main}`,
    px: 0.5,
    background: white.main
  }
});
