import React from 'react';

export const BusinessFleetInsurance = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path d='M11.2285 2C10.3735 2 9.61489 2.54356 9.33789 3.35156L8.42969 6H10.5352L11.2285 4H18.7715L19.8125 7H15.3945C15.7195 7.286 16.0036 7.618 16.2246 8L17.6855 12C17.6855 12 17.998 13 17.998 14H19V15C19 15.552 19.448 16 20 16H21C21.552 16 22 15.552 22 15V13H21.998V7.25L20.6621 3.35156C20.3851 2.54356 19.6265 2 18.7715 2H11.2285ZM5.22852 8C4.37352 8 3.61489 8.54356 3.33789 9.35156L2 13.25V19V20V21C2 21.552 2.448 22 3 22H4C4.552 22 5 21.552 5 21V20H13V21C13 21.552 13.448 22 14 22H15C15.552 22 16 21.552 16 21V20V19V13.25L14.6621 9.35156C14.3851 8.54356 13.6265 8 12.7715 8H5.22852ZM19 9C19.552 9 20 9.448 20 10C20 10.552 19.552 11 19 11C18.448 11 18 10.552 18 10C18 9.448 18.448 9 19 9ZM5.22852 10H12.7715L13.8125 13H4.1875L5.22852 10ZM5 15C5.552 15 6 15.448 6 16C6 16.552 5.552 17 5 17C4.448 17 4 16.552 4 16C4 15.448 4.448 15 5 15ZM13 15C13.552 15 14 15.448 14 16C14 16.552 13.552 17 13 17C12.448 17 12 16.552 12 16C12 15.448 12.448 15 13 15Z' />
      </g>
    </svg>
  );
};
