import React from 'react';

export const Local_servicesHomeCleaners = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path d='M7.5957 3.00784C6.76108 3.07014 5.96445 3.50292 5.4707 4.24417C5.0417 4.88717 4.89622 5.68749 5.07422 6.43949C5.25222 7.19148 5.73916 7.84359 6.41016 8.22659L11.4043 9.96878L10.3047 11.791C9.8331 11.5646 9.34243 11.3623 8.82422 11.2032C8.64622 11.1492 8.2758 11.0846 8.0918 11.0586C6.4428 10.8256 4.82739 11.2865 3.65039 12.3125C2.58639 13.2375 2 14.5359 2 15.9649V17C2 19.209 3.791 21 6 21H11.5605C12.9955 21 14.2828 20.1163 14.8008 18.6973C15.1738 17.6713 14.9597 16.5448 14.4277 15.5918C13.8277 14.5169 13.0077 13.5877 12.0176 12.8321L13.1406 10.961C13.4476 10.424 13.4908 9.78688 13.2578 9.21488C13.0248 8.64188 12.5489 8.21653 12.0059 8.06253L7.33789 6.44925C7.17789 6.33725 7.06748 6.1745 7.02148 5.9805C6.97048 5.7625 7.01077 5.54055 7.13477 5.35355C7.36677 5.00655 7.83431 4.89855 8.19531 5.10355L13.8008 6.88089C13.9828 7.00889 14.1176 7.19228 14.1836 7.40628L18.1074 20.291C18.2354 20.712 18.6245 21 19.0645 21H21C21.552 21 22 20.552 22 20C22 19.448 21.552 19 21 19H19.7422L16.0938 6.82034C15.8687 6.08934 15.3808 5.47669 14.7168 5.09769L9.08008 3.30863C8.60945 3.066 8.09647 2.97047 7.5957 3.00784ZM6 15C7.105 15 8 15.895 8 17C8 18.105 7.105 19 6 19C4.895 19 4 18.105 4 17C4 15.895 4.895 15 6 15Z' />
      </g>
    </svg>
  );
};
