export default () => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '16px',
    rowGap: '10px',
    marginTop: '20px',
    marginBottom: '24px'
  },
  filePreview: {
    width: '85px',
    height: '85px',
    borderRadius: '6px',
    border: '1px solid #BCBCC1',
    '& img': {
      width: '100%',
      height: '100%',
      borderRadius: '6px',
      overflow: 'hidden',
      objectFit: 'cover'
    },
    '& a': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%'
    }
  }
});
