import { renewalDetailApi } from '@packs/apis';
import { ICategory, IPolicy } from '@packs/interfaces/rootInterfaces';
import { toastr } from '@packs/lib/helpers';
import { RootStore } from '@packs/stores';
import { getWindow } from '@shared/lib/utils';

import isNull from 'lodash-es/isNull';
import omitBy from 'lodash-es/omitBy';
import pick from 'lodash-es/pick';

type ILocalData = {
  props: {
    renewalDetail: IPolicy;
    category: ICategory;
  };
  rootMobxStore: RootStore;
  setRemount: (arg: boolean) => void;
  setStep: () => void;
  onDetailRetrieve: (renewalDetail: IPolicy) => void;
  setModalOpen: () => void;
};

type IPolicyFormParams = {
  renewalDetailId?: number;
  feedbackType: string;
  buyingType: string;
  lastBuy: string;
  advancedPrice: {
    electricity: string;
    gas: string;
  };
  sourceProviderCompanyId: number;
  anyCompanyId: number;
  anyCompanyRate: number;
  anyCompanyRateComment: string;
  overallRate: number;
  criteriaQuestions: Record<string, string>;
  pricesQuestions: Record<string, string>;
  categoryId: number;
  smartReminder?: {
    id: number | null;
    aliasName: string;
    triggeredAt: string;
    recurringTime: string;
    isAutoQuote: boolean;
    _delete?: boolean;
  };
  quoteRequest?: {
    phoneNumber: string;
  };
};

export const onSaveClick = (
  localData: ILocalData,
  successCallback: (signUpFlow, verified, create) => void,
  errorCallback: (error) => void,
  signUpFlow = false,
  verified = false
) => {
  const { props, rootMobxStore } = localData;
  const { detailsFormStore, policiesListStore, userStore, categoryStore } = rootMobxStore;
  const { submitFormValues, criteriaKeys } = detailsFormStore;
  const { mixOptions } = policiesListStore;

  let enableSaveCallback = true;

  const {
    lastBuy,
    buyingType,
    criteriaQuestions,
    pricesQuestions,
    advancedPrice,
    overallRate,
    triggeredAt,
    aliasName,
    addSmartReminder,
    recurringTime,
    anyCompanyRate,
    anyCompanyRateComment
  } = submitFormValues;

  let {
    // provider data
    sourceProviderCompanyId,
    // source data
    anyCompanyId
  } = submitFormValues;
  const isAutoQuote = submitFormValues?.isAutoQuote;
  const phoneNumber = submitFormValues?.phoneNumber;

  if (sourceProviderCompanyId === 'i_cant_remember') {
    sourceProviderCompanyId = undefined;
  }

  if (anyCompanyId === 'i_cant_remember') {
    anyCompanyId = undefined;
  }

  const { category, renewalDetail } = props;

  const params: IPolicyFormParams = {
    renewalDetailId: renewalDetail?.id || undefined,
    feedbackType: 'review',
    buyingType: buyingType || 'provider',
    lastBuy,
    advancedPrice: {
      electricity: advancedPrice?.electricity || {},
      gas: advancedPrice?.gas || {}
    },
    // id, rate , rate comment
    sourceProviderCompanyId,
    anyCompanyId,
    anyCompanyRate,
    anyCompanyRateComment,
    overallRate,
    criteriaQuestions: pick(omitBy(criteriaQuestions, isNull), criteriaKeys),
    pricesQuestions: omitBy(pricesQuestions, isNull),
    categoryId: category.id,
    smartReminder: {
      id: renewalDetail?.smartReminder?.id,
      aliasName,
      triggeredAt,
      recurringTime: recurringTime || null,
      isAutoQuote: isAutoQuote || false
    }
  };

  if (isAutoQuote && phoneNumber) {
    params.quoteRequest = {
      phoneNumber: phoneNumber || ''
    };
  }

  if (renewalDetail?.id && !addSmartReminder) {
    params.smartReminder!._delete = true;
  }

  if (!renewalDetail?.id && !addSmartReminder) {
    params.smartReminder = undefined;
  }

  const onSuccess = (_, create) => {
    userStore.fetchProfileStats({});

    if (getWindow()) {
      let myDetails: number[] = [];
      if (getWindow().localStorage.getItem('myDetails')) {
        myDetails = JSON.parse(getWindow().localStorage.getItem('myDetails')!);
      }

      if (!myDetails.includes(category.id)) {
        const allDetails = [...myDetails, category.id];
        getWindow().localStorage.setItem('myDetails', JSON.stringify(allDetails));
      }
    }
    categoryStore.setCategoryWithPolicy(category.id);
    successCallback && successCallback(signUpFlow, verified, create);
    if (enableSaveCallback) {
      enableSaveCallback = false;
    }
  };

  if (renewalDetail?.id) {
    renewalDetailApi
      .updateRenewalDetail(params)
      .then(resp => {
        if (resp.errors?.length) {
          toastr.error(resp.errors.join('; '));
          errorCallback(resp.errors);
        } else {
          onSuccess(resp, false);
        }
      })
      .catch(error => errorCallback(error));
  } else {
    renewalDetailApi
      .createRenewalDetail(params)
      .then(resp => {
        if (resp.errors?.length) {
          toastr.error(resp.errors.join('; '));
          errorCallback(resp.errors);
        } else {
          onSuccess(resp, true);
        }
      })
      .catch(error => errorCallback(error));
  }
};
