import React from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';

export const MaterialRadioGroup = ({ name, options, onChange, value, row = true, size = 'small' }) => {
  const handleChange = data => {
    return () => {
      onChange(data);
    };
  };

  return (
    <RadioGroup row={row} aria-labelledby='buttons-group-label' name={name}>
      {options.map(item => {
        return (
          <FormControlLabel
            key={item.value}
            value={item.value}
            control={<Radio checked={item?.value === value?.value} size={size} />}
            label={size === 'small' ? <Typography variant='body3'>{item.label}</Typography> : item.label}
            onChange={handleChange(item)}
          />
        );
      })}
    </RadioGroup>
  );
};
