import React from 'react';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { CategoryIconBox } from '@packs/components/MaterialUi/CategoryIconBox/CategoryIconBox';
import { useStore } from '@packs/stores';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import useStyles from './styles';

import { observer } from 'mobx-react';

const { secondary } = colors;

export const PersonalizeModalHeader = observer(({ percentage }) => {
  const styles = useStyles();
  const { currentCategory } = useStore();
  const { isMobile } = useWindowSize();

  return (
    <Box sx={styles.wrapper}>
      <CategoryIconBox
        containerSize={isMobile ? '40px' : '64px'}
        iconSize={isMobile ? '28px' : '40px'}
        category={currentCategory}
        backgroundColor={secondary.transparent}
        color={secondary.main}
        borderRadius='8px'
      />
      <Box sx={styles.wrapperMainPart}>
        <Box sx={styles.progressTopBar}>
          <Typography sx={styles.progressText}>{currentCategory.name}</Typography>
          <Typography sx={styles.progressText}>{`${percentage}%`}</Typography>
        </Box>
        <Box>
          <LinearProgress variant='determinate' value={percentage} sx={styles.progress} />
        </Box>
      </Box>
    </Box>
  );
});
