import React, { useEffect, useState } from 'react';
import { isNumber } from 'lodash-es';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@packs/images/icons/ArrowDown';
import { pathGen } from '@packs/layouts/constants';
import { useCategories } from '@packs/models/category/useCategories';
import { useStore } from '@packs/stores';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { SidebarAnswer } from './components/SidebarAnswer';
import { SidebarContentCriteria } from './components/SidebarContentCriteria';
import { SidebarContentWrapper } from './components/SidebarContentWrapper';
import { SelectField } from './formFields/SelectField';
import useStyles from './styles';

import { Link } from 'react-router-dom';

const initialExpand = {
  provider: false,
  criteria: false,
  reminder: false
};

const policyBenefits = [
  'See the best matched offers!',
  'All your details will be in one place!',
  'You can share your details with businesses to request quotes!',
  'See where members like you are finding their best value on our leaderboard!',
  'Set up auto. quote to request automatic quotes at renewal time!'
];

export const PersonalizeSidebar = ({ step, formOptions, policyOptions, percentage, editPolicyBtn }) => {
  const styles = useStyles();
  const { leaderboardStore, detailsFormStore, countryCode } = useStore();
  const { formValues } = detailsFormStore;
  const { currentCategory } = useCategories();

  const { isDesktop } = useWindowSize();
  const [isExpanded, setIsExpanded] = useState(initialExpand);

  const currentPolicy = policyOptions.find(p => p.value === formValues?.policySelect);
  const businessesCount = leaderboardStore.requestBusinesses.length;

  const toggleExpanded = key => {
    return () => {
      setIsExpanded({ ...isExpanded, [key]: !isExpanded[key] });
    };
  };

  useEffect(() => {
    const data = { ...initialExpand };
    if (step === 0) {
      data.provider = true;
    } else if (step === 1) {
      data.criteria = true;
    } else {
      data.reminder = true;
    }

    setIsExpanded(data);
  }, [step]);

  return (
    <Box sx={styles.sidebarWrapper}>
      {!!businessesCount && (
        <>
          <Box sx={styles.sidebarSection}>
            <Button
              variant='contained'
              color='primary'
              component={Link}
              to={pathGen('REQUEST_QUOTES', { countryCode, category: currentCategory })}
              fullWidth
            >
              Request Quotes
            </Button>
          </Box>
          <Divider />
        </>
      )}
      {isDesktop && (
        <>
          <Box sx={styles.sidebarSection}>
            <Box sx={{ mb: 0.5 }}>
              <Typography variant='body6'>Policy</Typography>
            </Box>
            <Box sx={styles.policyWrapper}>
              <SelectField
                name='policySelect'
                options={policyOptions}
                defaultValue={formValues?.policySelect}
                placeholder='Select Policy'
                classNamePrefix='Select'
                className='gray-bg light-border no-scale medium'
                value={formValues?.policySelect}
              />
              {editPolicyBtn}
            </Box>
          </Box>
          <Divider />
        </>
      )}
      <Box sx={{ p: 2 }}>
        <Typography variant='body2'>{`Goal Progress: ${currentPolicy?.label}`}</Typography>
        <Box sx={styles.progressWrapper}>
          <Box sx={styles.progressContainer}>
            <Box sx={{ ...styles.progress, width: `${percentage}%` }} />
          </Box>
          <Box sx={{ minWidth: '100px' }}>
            <Typography variant='body5'>{`${percentage}% completed!`}</Typography>
          </Box>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography variant='body5'>
            Please ensure that your details are accurate and up-to-date before obtaining a quote!{' '}
          </Typography>
        </Box>
        <Box sx={{ mb: 1 }}>
          <Typography variant='body5'>The benefits for keeping your details up-to-date!</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {policyBenefits.map(item => (
            <Box key={item} sx={{ display: 'flex', gap: 1 }}>
              <Box sx={{ color: '#2E7D32' }}>
                <CheckCircleRoundedIcon sx={{ fontSize: '18px' }} />
              </Box>
              <Typography variant='body5'>{item}</Typography>
            </Box>
          ))}
        </Box>
      </Box>

      {isDesktop && (
        <>
          <Divider />
          <Box sx={styles.sidebarAccordionSection}>
            <Accordion expanded={isExpanded.provider} onChange={toggleExpanded('provider')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box>
                  <Typography variant='body2'>1. Provider:</Typography>
                  <Typography variant='body5' sx={styles.sidebarAccordionSubTitle}>
                    Est. time to complete 1 min
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <SidebarContentWrapper
                  category={currentCategory}
                  {...{
                    formValues
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Divider />
          </Box>
          <Box sx={styles.sidebarAccordionSection}>
            <Accordion expanded={isExpanded.criteria} onChange={toggleExpanded('criteria')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box>
                  <Typography variant='body2'>2. Сriteria:</Typography>
                  <Typography variant='body5' sx={styles.sidebarAccordionSubTitle}>
                    Est. time to complete 2 min
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <SidebarContentCriteria
                  category={currentCategory}
                  {...{
                    formValues,
                    formOptions
                  }}
                />
              </AccordionDetails>
            </Accordion>
            <Divider />
          </Box>
          <Box sx={styles.sidebarAccordionSection}>
            <Accordion expanded={isExpanded.reminder} onChange={toggleExpanded('reminder')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box>
                  <Typography variant='body2'>3. Reminder & Auto. quote:</Typography>
                  <Typography variant='body5' sx={styles.sidebarAccordionSubTitle}>
                    Est. time to complete 1 min. (Optional)
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {formValues?.addSmartReminder && formValues?.triggeredAt && (
                  <SidebarAnswer answer={formValues?.triggeredAt} label='Do you wish to set a reminder' />
                )}
              </AccordionDetails>
            </Accordion>
          </Box>
        </>
      )}

      {!!isNumber(formValues?.policySelect) && (
        <>
          <Box sx={styles.sidebarSection}>
            <Button
              variant='filledTonal'
              color='primary'
              component='a'
              href={location?.origin + `/download-my-details/${formValues?.policySelect}`}
              target='_blank'
              rel='noreferrer'
              size='smallToMedium'
              fullWidth
              endIcon={<DownloadRoundedIcon />}
            >
              Download my details
            </Button>
          </Box>
          <Divider />
        </>
      )}
    </Box>
  );
};
