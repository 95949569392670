import _ from 'lodash-es';

import { toastr } from '@packs/lib/helpers';

export const isValid = (localData, simpleForm = false) => {
  const { nodes, step, nextStep, rootMobxStore } = localData;
  const { detailsFormStore } = rootMobxStore;

  const myProviderNames = ['buyingType', 'lastBuy', 'anyCompanyRate'];

  const { formValues } = detailsFormStore;
  const isBuyingTypeOptional = formValues.anyCompanyId === 'i_cant_remember';

  const optionalNames = [
    'policySelect',
    'isAutoQuote',
    'currentlyExisting',
    'sourceProviderCompanyId',
    'addSmartReminder',
    'triggeredAtDate.month',
    'triggeredAtDate.day',
    'anyCompanyRateComment',
    'pricesQuestions.price.value',
    'pricesQuestions.price.period',
    'criteriaQuestions.carTrim',
    'criteriaQuestions.trim',
    'criteriaQuestions.price.value',
    'criteriaQuestions.price.period',
    'advancedPrice.gas.includingTaxes',
    'advancedPrice.electricity.includingTaxes',
    ...[isBuyingTypeOptional ? 'anyCompanyRate' : null],
    ...[isBuyingTypeOptional ? 'anyCompanyId' : null],
    ...(isBuyingTypeOptional ? myProviderNames : [null]),
    ...(!formValues?.isAutoQuote ? ['phoneNumber'] : []),
    ...[formValues?.criteriaQuestions?.mobileMake === 'not_remember' ? 'criteriaQuestions.mobileModel' : null],
    ...[formValues?.criteriaQuestions?.havingDriverOnPolicy === false ? 'criteriaQuestions.yearsDrivingType' : null]
  ].filter(e => e);

  let allNames = _.keys(nodes);

  if (step === 0 || nextStep === 0) {
    allNames = allNames.filter(n => !n.match(/criteriaQuestions/));
  }

  const formErrors = {};
  _.each(allNames, name => {
    const isOptional = _.includes(optionalNames, name);
    const val = _.get(formValues, name);
    const emptyValue = _.isNil(val) || _.isUndefined(val) || (_.isEmpty(val) && !_.isNumber(val) && !_.isBoolean(val));
    const isPrice = name.match('pricesQuestions');

    if (!isOptional && emptyValue && !isPrice) {
      if (name === 'criteriaQuestions.claimRate') {
        if (formValues.criteriaQuestions.claimWithBussiness) {
          formErrors[name] = true;
        }
      } else if (formValues?.criteriaQuestions?.isCurrentCost === false) {
        delete formErrors['criteriaQuestions.price.value'];
        delete formErrors['criteriaQuestions.price.period'];
      } else {
        formErrors[name] = true;
      }
    }
  });

  if (!_.isEmpty(formErrors)) {
    detailsFormStore.setFormErrors(formErrors);
    !simpleForm && toastr.error('Please complete this step (Fill all required fields)');

    return false;
  }
  detailsFormStore.setFormErrors({});
  return true;
};
