import React, { useEffect, useState } from 'react';
import _ from 'lodash-es';
import moment from 'moment';

import { categoryApi } from '@packs/apis/category';
import clientsApi from '@packs/apis/client';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { pathGen, usePath } from '@packs/layouts/constants';
import { toastr } from '@packs/lib/helpers';
import { useCategories } from '@packs/models/category/useCategories';
import { useDetails } from '@packs/models/policy/useDetails';
import { DetailsFormProvider } from '@packs/screens/PersonalizeResults/DetailsFormProvider';
import { EditAliasNameModalReview } from '@packs/screens/PersonalizeResults/EditAliasNameModalReview';
import { useClickYesNo } from '@packs/screens/PersonalizeResults/formHooks/useClickYesNo';
import { useOnDetailRetrieve } from '@packs/screens/PersonalizeResults/formHooks/useOnDetailRetrieve';
import { RenewalDetailsFormReview } from '@packs/screens/PersonalizeResults/RenewalDetailsFormReview';
import { useStore } from '@packs/stores/base';
import { categoriesWithoutSimpleForm } from '@shared/constants/categories';

import { shouldOpenModal } from './formHooks/callbacks';
import { defaultFormValues, defaultHideBlock, defaultHideCliteria, defaultShowFields } from './formHooks/defaults';
import { RenewalDetailsModal } from './RenewalDetailsModal';

import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

const RenewalDetailsFormWithHooks = observer(props => {
  const rootMobxStore = useStore();
  const { detailsFormStore, policiesListStore, userStore, leaderboardStore, countryCode, categoryStore } =
    rootMobxStore;
  const [disabledSave, setDisabledBtn] = useState(false);
  const { currentUser } = useUserSelectors();
  const { currentCategory } = useCategories();
  const { LEADERBOARD_PATH } = usePath();
  const navigate = useNavigate();
  const { currentPolicy } = useDetails();
  const { modalState } = useModalsContext();

  const [step, setStep] = useState(0);
  const [disabledFields, setDisabledFields] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [remount, setRemount] = useState(false);
  const [isOpen, setModalOpen] = useState(false);
  const [isOpenAliasNameModal, setIsOpenAliasNameModal] = useState(false);

  const { onClickYes, onClickNo } = useClickYesNo({ setModalOpen });
  const { hideCliteria, hideBlock, showFields } = detailsFormStore;
  const { onDetailRetrieve } = useOnDetailRetrieve({ simpleForm: props.simpleForm });

  useEffect(() => {
    if (detailsFormStore.clear) detailsFormStore.setClear(false);
  }, [detailsFormStore.clear]);

  useEffect(() => {
    if (currentUser.id) {
      detailsFormStore.change('criteriaQuestions.location', currentUser.cityName || currentUser.state);
    }
  }, [currentUser.id]);

  const setHideCliteria = val => {
    detailsFormStore.setHideCliteria(val);
  };
  const setHideBlock = val => {
    detailsFormStore.setHideBlock(val);
  };
  const setShowFields = val => {
    detailsFormStore.setShowFields(val);
  };

  // componentDidMount
  useEffect(() => {
    window.moment = moment;
    if (currentCategory?.id) {
      detailsFormStore.setHideCliteria({
        ...defaultHideCliteria,
        pricesQuestions: currentCategory?.root?.code !== 'energy'
      });
      detailsFormStore.setHideBlock(defaultHideBlock);
      detailsFormStore.setShowFields(defaultShowFields);

      // location from user
      defaultFormValues.criteriaQuestions = { location: currentUser.cityName };

      if (!currentUser.id) {
        let eventUrl = 'details';
        if (leaderboardStore?.requestBusinesses?.length) {
          const requestBusinesses = leaderboardStore?.requestBusinesses.map(item => item.id).join(',');
          eventUrl = `/${countryCode}/categories/${currentCategory.root.code}/${currentCategory.slag}/request-quotes?requestBusinesses=${requestBusinesses}&initialStep=2`;
        }
        userStore.setRegisterEvent(eventUrl);
      }

      detailsFormStore.setClear(false);
      detailsFormStore.setFormErrors({});
      detailsFormStore.setFormValues(defaultFormValues);

      // load companiesClients ( associations )
      clientsApi
        .listClients({ countryCode })
        .then(data => data.map(s => ({ value: s.id, label: s.name })))
        .then(options => {
          _.set(detailsFormStore.formOptions, 'criteriaQuestions.companiesClients', options);
          detailsFormStore.setFormOptions(detailsFormStore.formOptions);
        });

      const { policyId } = location.search.queryObj();

      policiesListStore
        .loadPolicyData({
          categoryId: currentCategory?.id,
          renewalDetailId: policyId ? +policyId : undefined
        })
        .then(res => {
          onDetailRetrieve({ renewalDetail: res.renewalDetail });

          if (!props.simpleForm && shouldOpenModal(res.renewalDetail)) {
            res.renewalDetail?.buyingType !== 'other' && setModalOpen(true);
          }
          setIsLoaded(true);
        });
      // if (!props.simpleForm && currentUser.id) {
      //   navigate({ search: '' });
      // }
    }
  }, [currentCategory?.id]);

  useEffect(() => {
    return () => {
      detailsFormStore.setFormValues(defaultFormValues);
    };
  }, []);

  useEffect(() => {
    if (modalState?.isOpenVerificationModal && step !== 2) {
      setStep(2);
    }
  }, [modalState.isOpenVerificationModal]);

  if (!detailsFormStore) {
    return null;
  }

  const localData = {
    props: {
      ...props,
      category: currentCategory,
      renewalDetail: currentPolicy
    },
    onDetailRetrieve,
    hideCliteria,
    setHideCliteria,
    setIsLoaded,
    setRemount,
    setModalOpen,
    showFields,
    setShowFields,
    disabledFields,
    setDisabledFields,
    step,
    setStep,
    hideBlock,
    setHideBlock,
    rootMobxStore,
    currentUser
  };

  const successCallback = (signUpFlow, verified, create) => {
    categoryStore.markCategorySelect({ id: currentCategory?.id, selected: true });
    categoryApi.categorySubscribe({ categoryId: currentCategory.id, selected: true });
    const { callbackRedirect } = location.search.queryObj();
    if (!create) {
      toastr.success('You have successfully updated all your data.');
    }
    props.successSubmitCallback && props.successSubmitCallback();
    const isRedirectToLeaderboard =
      create &&
      (currentCategory?.root?.code === 'energy' || categoriesWithoutSimpleForm.includes(currentCategory.code));

    if (signUpFlow) {
      if (!verified) {
        navigate(pathGen('CONFIRM_EMAIL', { countryCode, category: currentCategory }));
      } else {
        if (callbackRedirect) {
          navigate(callbackRedirect);
        } else {
          if (isRedirectToLeaderboard) {
            navigate(pathGen('LEADERBOARD', { countryCode, category: currentCategory }));
          } else {
            navigate(pathGen('REQUEST_QUOTES', { countryCode, category: currentCategory }));
          }
        }
      }
    } else {
      setTimeout(() => {
        if (verified) {
          if (callbackRedirect) {
            navigate(callbackRedirect);
          } else if (isRedirectToLeaderboard) {
            navigate(pathGen('LEADERBOARD', { countryCode, category: currentCategory }));
          } else if (!!leaderboardStore?.requestBusinesses?.length || create) {
            navigate(pathGen('REQUEST_QUOTES', { countryCode, category: currentCategory }));
          } else {
            navigate(LEADERBOARD_PATH);
          }
        } else {
          navigate(pathGen('CONFIRM_EMAIL', { countryCode, category: currentCategory }));
        }
      }, 1500);
    }
  };

  const errorCallback = () => {
    setDisabledBtn(false);
  };

  return (
    <>
      <Helmet>
        <title>{props?.inModalFrame ? 'Get matched!' : 'Update your details'}</title>
      </Helmet>
      <DetailsFormProvider localData={localData}>
        <RenewalDetailsFormReview
          submitCallbacks={{
            successCallback,
            errorCallback
          }}
          {...{
            localData,
            step,
            setStep,
            remount,
            setRemount,
            isLoaded,
            setIsLoaded,
            disabledSave,
            setDisabledBtn,
            setModalOpen,
            setIsOpenAliasNameModal
          }}
        />

        {!remount && (
          <EditAliasNameModalReview
            isOpenAliasNameModal={isOpenAliasNameModal}
            setIsOpenAliasNameModal={setIsOpenAliasNameModal}
            setRemount={setRemount}
          />
        )}

        <RenewalDetailsModal
          isOpen={isOpen}
          closeModal={() => {
            setModalOpen(false);
            navigate(window?.location?.pathname, { replace: true });
          }}
          category={currentCategory}
          renewalDetail={currentPolicy}
          onClickYes={onClickYes}
          onClickNo={onClickNo}
        />
      </DetailsFormProvider>
    </>
  );
});

export default RenewalDetailsFormWithHooks;
