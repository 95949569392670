import React, { useMemo } from 'react';

import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { usePath } from '@packs/layouts/constants';
import { useCategories } from '@packs/models/category/useCategories';
import { useDetails } from '@packs/models/policy/useDetails';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import useStyles from './styles';

import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

export const ActionPanel = observer(({ handleBack }) => {
  const styles = useStyles();
  const { LEADERBOARD_PATH, DASHBOARD_LANDING_PATH_FN } = usePath();
  const { currentCategory } = useCategories();
  const { currentPolicy } = useDetails();
  const { callbackRedirect } = location.search.queryObj();

  const closeUrl = useMemo(() => {
    if (callbackRedirect) {
      return callbackRedirect;
    }
    return !currentPolicy?.id ? DASHBOARD_LANDING_PATH_FN(currentCategory) : LEADERBOARD_PATH;
  }, [currentPolicy?.id, currentCategory]);

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.btnWrapper}>
        {handleBack && (
          <Box sx={styles.btn} onClick={handleBack}>
            <ArrowBackRoundedIcon />
          </Box>
        )}
      </Box>
      <Typography sx={styles.title}>
        Smarter decisions powered by the {currentCategory?.name} community of Ireland!
      </Typography>
      <Box sx={styles.btnWrapper}>
        <Box sx={styles.btn} component={Link} to={closeUrl}>
          <CloseRoundedIcon />
        </Box>
      </Box>
    </Box>
  );
});
