import { getWindow } from '@shared/lib/utils';

export const scrollToTopPersonalize = () => {
  document.querySelector('.policy-form-container').scrollIntoView();
};

export const onOpenMonth = ev => {
  const windowScrollTopPosition = getWindow().scrollY;

  setTimeout(() => {
    const node = document.querySelector('.select__menu-list .select__option--is-selected');
    if (node) {
      node?.scrollIntoView();
    } else {
      const scrollToFirst = document.querySelector('.select__menu .scroll-to-first');
      scrollToFirst?.scrollIntoView();
    }

    getWindow().scrollTo(0, windowScrollTopPosition);
  }, 200);
};
