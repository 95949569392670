import React from 'react';

import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded';
import { Box, Button, Divider, Link, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { SignInModalLink } from '@packs/components/SignInModalLink/signInModalLink';
import { SignUpModalLink } from '@packs/components/SignUpModalLink';
import { CategoriesNavigation, SearchBusinessCategory, UserMenu } from '@packs/components/UI';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import LogoIcon from '@packs/images/icons/LogoBillWinner';
import LogoBlackIcon from '@packs/images/icons/LogoBillWinnerBlack';
import { usePath } from '@packs/layouts/constants';
// import { useStore } from '@packs/stores/base';
import { businessPageUrl } from '@shared/helpers/urlHelper';

import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';

const { white, dark, borderColor, primary, secondary } = colors;

export const DesktopHeader = observer(({ toggleDrawer, openDrawer, bgFilled }) => {
  // const { countryCode, currentCategory } = useStore();
  const { currentUser } = useUserSelectors();
  const { ROOT_PATH, VOTE_PATH, MY_HOME_PATH, PROFILE_MY_REMINDERS_PATH, MY_OFFERS_PATH } = usePath();

  const currentPage = location.pathname.split('/')[4];
  const disableCategoryNav =
    ['charge', 'charge-complete'].includes(currentPage) || location.pathname.match('request-quotes');
  const mainColor = bgFilled ? dark.main : white.main;
  const links = [
    {
      to: MY_HOME_PATH,
      label: 'Dashboard'
    },
    {
      to: MY_OFFERS_PATH,
      label: 'Offers'
    },
    {
      to: PROFILE_MY_REMINDERS_PATH,
      label: 'Reminders'
    }
  ];

  // const categoryName = currentCategory?.root?.enName;

  const handleToggleDrawer = () => {
    if (!openDrawer) {
      return null;
    }

    toggleDrawer();
  };

  return (
    <Box
      component='header'
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        minHeight: '98px',
        backgroundColor: bgFilled ? white.main : 'transparent',
        color: bgFilled ? dark.main : white.main,
        transition: 'background-color .2s',
        zIndex: 50,
        ...(bgFilled ? { boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)' } : {})
      }}
    >
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        sx={{ py: 1, px: 4, borderBottom: `1px solid ${bgFilled ? 'rgba(0, 0, 0, 0.16)' : 'transparent'}` }}
      >
        <Stack direction='row' alignItems='center' spacing={3} sx={{ flexGrow: 1 }}>
          <NavLink to={ROOT_PATH}>{bgFilled ? <LogoBlackIcon /> : <LogoIcon />}</NavLink>
          <SearchBusinessCategory overlaySearch bgFilled={bgFilled} fullWidth />
        </Stack>
        <Stack direction='row' alignItems='center' spacing={1}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {/* TODO: Hide 'For Business'*/}
            {/* <Divider*/}
            {/*  orientation='vertical'*/}
            {/*  flexItem*/}
            {/*  sx={{*/}
            {/*    alignSelf: 'center',*/}
            {/*    height: '16px',*/}
            {/*    borderRadius: '2px',*/}
            {/*    borderWidth: '1px',*/}
            {/*    borderColor: bgFilled ? dark.tonal : white.tonal*/}
            {/*  }}*/}
            {/*/ >*/}
            {/* <CountrySelect {...{ countryCode, categoryName, bgFilled }} arrowFontSize='24px' />*/}

            {currentUser?.id && (
              <>
                {links.map(item => (
                  <Typography
                    key={item.label}
                    variant='body4'
                    component={NavLink}
                    to={item.to}
                    onClick={toggleDrawer}
                    sx={{
                      position: 'relative',
                      color: mainColor,
                      px: 2,
                      '&[aria-current="page"]': {
                        '&:after': {
                          content: '""',
                          position: 'absolute',
                          top: 'calc(100% + 4px)',
                          left: '50%',
                          width: '24px',
                          height: '4px',
                          borderRadius: '4px',
                          backgroundColor: primary.main,
                          transform: 'translateX(-50%)'
                        }
                      },
                      '&:not([aria-current="page"]):hover': {
                        opacity: 0.7
                      }
                    }}
                  >
                    {item.label}
                  </Typography>
                ))}
                <Divider
                  orientation='vertical'
                  flexItem
                  sx={{
                    alignSelf: 'center',
                    borderColor: bgFilled ? borderColor.secondary : white.main,
                    height: '16px',
                    width: '2px',
                    borderRadius: '2px',
                    mx: 2
                  }}
                />
              </>
            )}
            <Typography
              variant='body4'
              component={NavLink}
              to={VOTE_PATH}
              onClick={toggleDrawer}
              sx={{
                position: 'relative',
                color: mainColor,
                px: 2,
                '&[aria-current="page"]': {
                  '&:after': {
                    content: '""',
                    position: 'absolute',
                    top: 'calc(100% + 4px)',
                    left: '50%',
                    width: '24px',
                    height: '4px',
                    borderRadius: '4px',
                    backgroundColor: primary.main,
                    transform: 'translateX(-50%)'
                  }
                },
                '&:not([aria-current="page"]):hover': {
                  opacity: 0.7
                }
              }}
            >
              Write a review!
            </Typography>
            {!currentUser?.id && (
              <Button
                component={Link}
                size='smallToMedium'
                color={bgFilled ? 'secondary' : 'white'}
                variant='outlined'
                href={businessPageUrl()}
                target='_blank'
                startIcon={<BusinessCenterRoundedIcon />}
                sx={{
                  mx: 2
                }}
              >
                For Business
              </Button>
            )}
            {currentUser?.id ? (
              <UserMenu />
            ) : (
              <Stack direction='row' alignItems='center' spacing={1}>
                <SignInModalLink
                  onClick={handleToggleDrawer}
                  variant='filledTonal'
                  color={bgFilled ? 'primary' : 'white'}
                  size='smallToMedium'
                >
                  Log in
                </SignInModalLink>
                <SignUpModalLink onClick={handleToggleDrawer} size='smallToMedium'>
                  Sign Up
                </SignUpModalLink>
              </Stack>
            )}
          </Box>
        </Stack>
      </Stack>
      {!disableCategoryNav && <CategoriesNavigation bgFilled={bgFilled} />}
    </Box>
  );
});
