import React from 'react';

import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { useCategories } from '@packs/models/category/useCategories';
import { useDetails } from '@packs/models/policy/useDetails';
import { useDetailsForm } from '@packs/screens/PersonalizeResults/DetailsFormProvider';
import { VIEW_DATE_FORMAT } from '@packs/screens/PersonalizeResults/formHooks/defaults';
import { setStepHandleLogic } from '@packs/screens/PersonalizeResults/formHooks/stepHandlers';
import { useDetailsOptions } from '@packs/screens/PersonalizeResults/formHooks/useDetailsOptions';
import { useDetailsSubmitActions } from '@packs/screens/PersonalizeResults/formHooks/useDetailsSubmitActions';
import { useOnDetailRetrieve } from '@packs/screens/PersonalizeResults/formHooks/useOnDetailRetrieve';
import { RenewalDetailsForm } from '@packs/screens/PersonalizeResults/RenewalDetailsForm';
import { useStore } from '@packs/stores';

import { observer } from 'mobx-react';
import moment from 'moment/moment';

export const RenewalDetailsFormReview = observer(
  ({
    localData,
    step,
    setStep,
    remount,
    setRemount,
    isLoaded,
    setIsLoaded,
    disabledSave,
    setDisabledBtn,
    submitCallbacks,
    setModalOpen,
    setIsOpenAliasNameModal
  }) => {
    const { change, isCriteriaLoaded } = useDetailsForm();
    const { currentPolicy } = useDetails();
    const { currentUser } = useUserSelectors();
    const { currentCategory } = useCategories();
    const { detailsFormStore } = useStore();

    const { onClickLastStepSave } = useDetailsSubmitActions({ localData, setDisabledBtn, submitCallbacks });
    const { onDetailRetrieve } = useOnDetailRetrieve({ simpleForm: localData.props.simpleForm });
    const { detailsOptions } = useDetailsOptions({
      setRemount,
      setStep,
      onDetailRetrieve,
      setModalOpen
    });

    const { hideCliteria, hideBlock, showFields, formValues, formOptions, formErrors } = detailsFormStore;

    const setStepHandle = index => {
      setStepHandleLogic(index, step, localData);
    };

    return (
      <RenewalDetailsForm
        lastUpdated={currentPolicy?.id && moment(currentPolicy?.updatedAt).format(VIEW_DATE_FORMAT)}
        policyOptions={detailsOptions}
        currentUser={currentUser}
        renewalDetail={currentPolicy}
        disabledFields={localData.disabledFields}
        setStep={setStepHandle}
        isLoaded={isLoaded}
        hideCliteria={detailsFormStore.hideCliteria}
        hideBlock={detailsFormStore.hideBlock}
        showFields={detailsFormStore.showFields}
        formValues={detailsFormStore.formValues}
        formOptions={detailsFormStore.formOptions}
        formErrors={detailsFormStore.formErrors}
        {...{
          remount,
          step,
          change,
          onClickLastStepSave,
          setIsOpenAliasNameModal,
          disabledSave,
          setIsLoaded
        }}
        localData={localData}
        category={currentCategory}
        {...localData.props}
      />
    );
  }
);
