import React from 'react';

export const BankingDeposits = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <path d='M19.76 9.50311H13.084C12.6724 9.50311 12.2776 9.66771 11.9865 9.96069C11.6955 10.2537 11.532 10.651 11.532 11.0654C11.532 11.4797 11.6955 11.8771 11.9865 12.1701C12.2776 12.463 12.6724 12.6276 13.084 12.6276H19.76C19.8237 12.6276 19.8847 12.6022 19.9297 12.5569C19.9747 12.5116 20 12.4501 20 12.3861V9.7447C20 9.68063 19.9747 9.61918 19.9297 9.57387C19.8847 9.52857 19.8237 9.50311 19.76 9.50311ZM13.316 11.6895C13.1935 11.6887 13.074 11.6514 12.9726 11.5823C12.8712 11.5133 12.7923 11.4155 12.746 11.3014C12.6997 11.1873 12.688 11.062 12.7124 10.9412C12.7367 10.8204 12.7961 10.7095 12.883 10.6227C12.9698 10.5358 13.0803 10.4767 13.2005 10.453C13.3206 10.4292 13.4451 10.4418 13.5582 10.4892C13.6712 10.5365 13.7678 10.6165 13.8358 10.7191C13.9037 10.8216 13.94 10.9421 13.94 11.0654C13.94 11.1477 13.9238 11.2292 13.8924 11.3051C13.861 11.3811 13.815 11.4501 13.757 11.5081C13.699 11.5661 13.6301 11.612 13.5545 11.6431C13.4788 11.6743 13.3977 11.69 13.316 11.6895Z' />
      <path d='M10.292 11.065C10.292 11.8125 10.587 12.5294 11.1121 13.058C11.6373 13.5865 12.3494 13.8835 13.0921 13.8835H18.0001V15.8484C17.999 16.0007 17.9682 16.1513 17.9093 16.2916C17.8505 16.4319 17.7647 16.5591 17.657 16.666C17.5493 16.773 17.4217 16.8575 17.2815 16.9148C17.1414 16.9721 16.9914 17.001 16.8401 17H1.156C0.850105 17 0.556675 16.8779 0.339997 16.6606C0.123319 16.4432 0.00105848 16.1483 0 15.8404V4.16364C0.00105429 3.85535 0.123186 3.55999 0.339751 3.342C0.556315 3.124 0.849738 3.00106 1.156 3H14.5361C14.7948 3.00121 15.0456 3.09004 15.248 3.2522C15.4504 3.41435 15.5928 3.64037 15.6521 3.89387H1.156C0.99157 3.89387 0.83387 3.95963 0.717597 4.07667C0.601324 4.19371 0.536002 4.35245 0.536002 4.51797C0.536002 4.68349 0.601324 4.84223 0.717597 4.95928C0.83387 5.07632 0.99157 5.14207 1.156 5.14207H16.8401C17.1453 5.14099 17.4385 5.26146 17.6559 5.47719C17.8732 5.69293 17.9969 5.98642 18.0001 6.29364V8.25452H13.0841C12.3442 8.25664 11.6353 8.55343 11.1121 9.08003C10.589 9.60664 10.2942 10.3203 10.292 11.065Z' />
    </svg>
  );
};
