import React, { useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SentimentAnalysisMetric } from '@packs/components/MaterialUi/CompanyPage/components/Sidebar/components/SentimentAnalysisMetric/SentimentAnalysisMetric';
import { Benefits } from '@packs/components/MaterialUi/Leaderboard/CompanyItem/components/AdditionInfo/components/Benefits/Benefits';
import { Ratings } from '@packs/components/MaterialUi/Leaderboard/CompanyItem/components/Ratings/Ratings';
import { RatingMUI } from '@shared/components/RatingMUI/RatingMUI';
import { getTotalRating } from '@shared/helpers/companyHelper';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import useStyles from './styles';

import size from 'lodash-es/size';
import { observer } from 'mobx-react';

export const CompanyAccordionsWrapper = observer(({ company, currentCategory }) => {
  const { isMobile } = useWindowSize();
  const styles = useStyles();

  const [expanded, setExpanded] = useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { totalRating } = getTotalRating(company);

  const benefits = company?.benefits || [];
  const sentimentAnalysis = company?.sentimentAnalysis || {};

  const AccordionItem = ({ children, title, name }) => {
    return (
      <Accordion expanded={expanded === name} onChange={handleChange(name)} sx={styles.accordionItem}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>{title}</AccordionSummary>
        <AccordionDetails sx={{ px: { xs: 2, md: 3 }, pt: 0 }}>{children}</AccordionDetails>
      </Accordion>
    );
  };

  if (!isMobile) {
    return null;
  }

  return (
    <Box sx={styles.accordionsWrapper}>
      <AccordionItem
        name='rating'
        title={
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Typography variant='body4'>Ratings</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <RatingMUI value={totalRating} />
              <Typography variant='body4'>{totalRating} out of 5</Typography>
            </Box>
          </Box>
        }
      >
        <Ratings {...{ company }} withOutTotal billWinnerIsLink />
      </AccordionItem>
      {size(benefits) > 0 && (
        <AccordionItem name='benfilts' title='Benefits'>
          <Benefits {...{ company }} />
        </AccordionItem>
      )}
      {(sentimentAnalysis?.score || sentimentAnalysis?.summary) && (
        <AccordionItem name='sentimentAnalysis' title='Sentiment analysis'>
          <SentimentAnalysisMetric {...{ sentimentAnalysis }} textVariant='body3' />
        </AccordionItem>
      )}
    </Box>
  );
});
