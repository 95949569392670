import React from 'react';

export const Twitter = () => {
  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path
          d='M23.1749 17.4494L35.5854 3.3335H32.6449L21.864 15.5876L13.2597 3.3335H3.33331L16.3477 21.8655L3.33331 36.6668H6.27379L17.6516 23.7231L26.7403 36.6668H36.6666L23.1749 17.4494ZM19.1462 22.0281L17.8256 20.1816L7.3342 5.5026H11.8514L20.3207 17.3534L21.6358 19.2L32.6435 34.6034H28.1263L19.1462 22.0281Z'
          fill='white'
        />
      </g>
    </svg>
  );
};
