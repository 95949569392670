import { assignClientUser, createClient, listClients, myAssociations } from '@packs/graphql/queries/client';
import { graphQLRequest } from '@packs/lib/utils';

const api = {
  createClient: variables =>
    graphQLRequest({
      query: createClient,
      variables: { input: variables }
    }),
  assignClientUser: variables =>
    graphQLRequest({
      query: assignClientUser,
      variables: { input: variables }
    }),
  listClients: variables =>
    graphQLRequest({
      query: listClients,
      variables
    }),
  listAssociationsCategories: variables =>
    graphQLRequest({
      query: myAssociations,
      variables
    })
};

export default api;
