import React from 'react';

import BrokerIcon from '@packs/images/icons/BrokerIcon';
import ComparisonIcon from '@packs/images/icons/ComparisonIcon';
import ProviderIcon from '@packs/images/icons/ProviderIcon';
import ShowAllIcon from '@packs/images/icons/ShowAllIcon';

export const ICONS = {
  all: <ShowAllIcon />,
  provider: <ProviderIcon />,
  broker: <BrokerIcon />,
  comparison_site: <ComparisonIcon />
};

export const SvgIcons = ({ iconType = '' }) => {
  if (iconType) {
    return <div className='icon-container'>{ICONS[iconType]}</div>;
  }

  return false;
};
