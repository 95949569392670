import React, { useEffect, useMemo, useState } from 'react';
import { get } from 'lodash-es';

import Box from '@mui/material/Box';
import { useStore } from '@packs/stores';

import { AnswerItem } from '../AnswerItem';

import { observer } from 'mobx-react';

export const AnswersList = observer(
  ({ postId, isOpen, questionsContainerOffset, questionsStore, currentUser, toggleSignUpModal }) => {
    const [loading, setLoading] = useState(false);

    const answers = useMemo(() => {
      return get(questionsStore.answers, `${postId}`, []);
    }, [questionsStore.answers]);

    useEffect(() => {
      if (isOpen) {
        setLoading(true);
        questionsStore.loadAnswers({ qaPostId: postId }).finally(() => {
          setLoading(false);
        });
      }
    }, [isOpen]);

    if (loading) {
      return <div>Loading</div>;
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
        {answers.map(answer => (
          <AnswerItem key={answer?.id} {...{ questionsStore, questionsContainerOffset, answer, postId, currentUser }} />
        ))}
      </Box>
    );
  }
);
