import React, { FC, useMemo } from 'react';

import { AutocompleteProps } from '@mui/material';
import { AutocompleteMUI } from '@packs/components/MaterialUi/AutocompleteMUI/AutocompleteMUI';
import { currentCountryRegionOptions } from '@packs/models/company/currentCountryRegionOptions';
import { useStore } from '@packs/stores';

import { observer } from 'mobx-react';

interface IProps extends Partial<AutocompleteProps<any, any, any, any>> {
  onChange: (val: any) => void;
  disabled?: boolean;
  withTitle: boolean;
}

export const DashboardLocationFilter: FC<IProps> = observer(
  ({ onChange, disabled = false, size = 'medium', withTitle, ...props }) => {
    const { companiesStore, currentCountry } = useStore();
    const regionOptions = currentCountryRegionOptions(currentCountry);

    const handleChangeLocation = data => {
      onChange(data);
    };

    const value = regionOptions.find(item => item.value === companiesStore.filters.city) || regionOptions[0];

    return (
      <AutocompleteMUI
        value={value}
        options={regionOptions}
        onChange={handleChangeLocation}
        isOptionEqualToValue={(option, value) => option?.value === value?.value}
        label={`Please select ${currentCountry?.regionType || 'region'}`}
        size={size}
        withTitle={withTitle}
        mobileSelect
        filter
        {...props}
      />
    );
  }
);
