import React from 'react';

import { createPlugin } from '@fullcalendar/core';
import { YearTable } from '@packs/components/Calendar/yearGridCustomPlugin/YearTable';

export const GridYearPlugin = createPlugin({
  views: {
    customGridYear: YearTable,
    customGridDayYear: {
      type: 'customGridYear',
      duration: { years: 1 }
    }
  }
});
