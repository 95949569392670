import { useState } from 'react';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import useStyles from './styles';

export const FiltersDrawer = ({
  children,
  buttonProps,
  showResultText = 'Show results',
  disabledResetFilters = false,
  handleResetFilters,
  hideFilterText = false
}) => {
  const { isMobile } = useWindowSize();
  const [isOpen, setIsOpen] = useState(false);
  const styles = useStyles();

  const toggleDrawer = open => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setIsOpen(open);
  };

  return (
    <>
      <Button size={isMobile ? 'medium' : 'smallToMedium'} {...buttonProps} onClick={toggleDrawer(true)}>
        {!hideFilterText && 'Filter'}
      </Button>
      <Drawer anchor='right' open={isOpen} onClose={toggleDrawer(false)}>
        <Box sx={styles.wrapper}>
          <Box sx={styles.headerWrapper}>
            <Box />
            <Typography variant='h6'>Filters</Typography>
            <IconButton onClick={toggleDrawer(false)} size='small'>
              <CloseRoundedIcon />
            </IconButton>
          </Box>
          <Box sx={styles.bodyWrapper}>{children}</Box>
          <Box sx={styles.btnContainer}>
            <Button
              variant='filledTonal'
              color='primary'
              size='medium'
              fullWidth
              disabled={disabledResetFilters}
              onClick={handleResetFilters}
            >
              Clear All
            </Button>
            <Button
              variant='contained'
              color='primary'
              size='medium'
              fullWidth
              onClick={toggleDrawer(false)}
              sx={{ px: 1 }}
            >
              {showResultText}
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};
