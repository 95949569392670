import ChatGptIcon from '@shared/images/rating/chat-gpt.svg';

export default colors => ({
  wrapper: {
    mb: {
      xs: 3,
      lg: 4
    },
    borderRadius: {
      xs: '16px',
      lg: '32px'
    },
    p: {
      xs: 2,
      lg: 3
    },
    backgroundImage: {
      xs: 'linear-gradient(180deg, #3273F5 0%, #06C 100%)',
      lg: `url(${ChatGptIcon}), linear-gradient(180deg, #3273F5 0%, #06C 100%)`
    },
    backgroundPosition: 'top right',
    backgroundRepeat: 'no-repeat'
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    mb: 2,
    color: colors.white.main
  },
  tabs: {
    mb: -1,
    p: 0,
    background: 'none',
    '& .MuiButtonBase-root': {
      maxWidth: '233px !important',
      height: '48px',
      pb: '18px',
      '& svg': {
        fill: colors.white.main
      },
      '&.Mui-selected': {
        '& svg': {
          fill: colors.dark.main
        }
      }
    }
  },
  tabsContent: {
    p: {
      xs: 2,
      lg: 3
    },
    borderBottomRightRadius: '12px',
    borderBottomLeftRadius: '12px',
    backgroundColor: colors.white.main
  },
  tabLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5
  }
});
