import { UnitPricesModal as UnitPricesModalC } from './UnitPricesModal';

import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';

const mapStateToProps = (state, props) => ({ formAttributes: getFormValues(props.form)(state) });

const createReduxForm = reduxForm({ form: 'winnerForm' });

export const UnitPricesModal = createReduxForm(connect(mapStateToProps)(UnitPricesModalC));
