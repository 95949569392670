import React, { useMemo } from 'react';
import cx from 'classnames';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CircleLoader } from '@packs/components/UI';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import EmailIcon from '@packs/images/ui/email-large.png';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import useStyles from './styles';

export const Summary = ({ simpleForm }) => {
  const styles = useStyles();
  const { isDesktop } = useWindowSize();
  const { currentUser } = useUserSelectors();
  const { toggleResendConfirmationModal } = useModalsContext();

  const userIsConfirmed = useMemo(() => {
    return currentUser?.isConfirmed;
  }, [currentUser]);

  if (!userIsConfirmed) {
    return (
      <div
        className={cx(
          'request-summary not-confirmed d-flex align-items-center justify-content-center',
          isDesktop ? 'text-left' : 'text-center',
          { simpleForm }
        )}
      >
        <div className='request-summary-confirm d-flex align-items-center justify-content-between'>
          <div className='body-container'>
            <div className='title'>Verify your email to continue!</div>
            <div className='description'>
              We sent a verification link to {currentUser?.email} <br /> Your email should arrive in less than 3
              minutes.
            </div>
            <div className='description'>If you don’t receive an email, click the button below to resend it.</div>
            <div className='btn-container d-flex mt-24'>
              <div className='btn full white' onClick={toggleResendConfirmationModal}>
                <span>Resend email</span>
              </div>
            </div>
          </div>
          <div className='img-container'>
            <img src={EmailIcon} alt='Email' />
          </div>
        </div>
      </div>
    );
  }

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.container}>
        <CircleLoader />
        <Typography variant='h6'>Loading...</Typography>
      </Box>
    </Box>
  );
};
