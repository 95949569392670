import React from 'react';

import { BusinessSidebarBox } from '@packs/components/UI/Business/BusinessSidebarBox';
import BusinessIcon from '@packs/images/svg/ui/business-icon.svg';
import { usePath } from '@packs/layouts/constants';
import { navActiveClass } from '@packs/lib/helpers/constants';

import { NavLink } from 'react-router-dom';

export const BecomePartner = () => {
  const { BECOME_A_PARTNER_PATH } = usePath();

  return (
    <BusinessSidebarBox title='Is this your business?' icon={BusinessIcon}>
      <div className='d-flex flex-column align-items-center'>
        <div className='fs-14 lh-5 mb-30'>
          Claim your business page (for free) so that you can activate your account and become part of our community!
        </div>
        <div className='d-flex'>
          <NavLink to={BECOME_A_PARTNER_PATH} className={navActiveClass('btn full')}>
            <span>Become a partner</span>
          </NavLink>
        </div>
      </div>
    </BusinessSidebarBox>
  );
};
