import React from 'react';
import cx from 'classnames';
import moment from 'moment';

import { SignInModalLink } from '@packs/components';
import { Avatar } from '@packs/components/UI';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import Like from '@packs/images/icons/LikeIconEmpty';
import { shortSecondName } from '@packs/lib/helpers/additionalHelpers';
import { useStore } from '@packs/stores';
import { useWindowSize } from '@shared/hooks/useWindowSize';

export const ReplyItemInsight = ({ answer, postId, commentId }) => {
  const { isMobile } = useWindowSize();
  const { currentUser } = useUserSelectors();
  const { insightsCompanyStore } = useStore();

  const { id, user, message, createdAt, isHelpful, companyUser, helpfulCount } = answer;

  const avatarUrl = companyUser?.company?.avatarUrl || user?.avatarUrl || '';
  const displayName = companyUser?.company?.name || user?.displayName || '';

  const onToggleHelpful = () => {
    insightsCompanyStore.toggleHelpfulInsightComment({
      attrs: { commentId: id },
      postId,
      rootCommentId: commentId,
      isHelpful
    });
  };

  return (
    <div className='answer-item reply-item'>
      <div className='answer-body d-flex align-items-start'>
        <div className='mr-15'>
          <Avatar avatarUrl={avatarUrl} placeholder={displayName} fullSize withBG />
        </div>
        <div>
          <div className='fs-14 lh-5 fw-600'>{shortSecondName(displayName)}</div>
          <div className='fs-11 lh-9 secondary-text'>{(createdAt && moment(createdAt).fromNow()) || '-'}</div>
        </div>
      </div>
      <div className='answer-message'>{message}</div>
      <div className='answer-footer d-flex justify-content-end'>
        <div className='d-flex align-items-center'>
          {currentUser?.id ? (
            <div className={cx('answer-action-btn', { active: isHelpful })} onClick={onToggleHelpful}>
              <Like />
              <span>
                {!isMobile && 'Helpful '}
                {helpfulCount || 0}
              </span>
            </div>
          ) : (
            <SignInModalLink>
              <div className='answer-action-btn'>
                <Like />
                <span>
                  {!isMobile && 'Helpful '}
                  {helpfulCount || 0}
                </span>
              </div>
            </SignInModalLink>
          )}
        </div>
      </div>
    </div>
  );
};
