import React from 'react';

import { EditAliasNameModal } from '@packs/components';
import { useCategories } from '@packs/models/category/useCategories';
import { useDetails } from '@packs/models/policy/useDetails';
import { useDetailsForm } from '@packs/screens/PersonalizeResults/DetailsFormProvider';
import { useStore } from '@packs/stores';

import { observer } from 'mobx-react';

export const EditAliasNameModalReview = observer(({ isOpenAliasNameModal, setIsOpenAliasNameModal, setRemount }) => {
  const { detailsFormStore } = useStore();
  const { change } = useDetailsForm();
  const { formValues, formOptions, formErrors } = detailsFormStore;
  const { currentCategory } = useCategories();
  const { currentPolicy } = useDetails();

  if (!isOpenAliasNameModal) {
    return null;
  }

  return (
    <EditAliasNameModal
      formValues={formValues}
      change={(field, val) => {
        change(field, val);
      }}
      category={currentCategory}
      renewalDetail={currentPolicy}
      refresh={() => {
        setRemount(true);
        setTimeout(() => setRemount(false), 10);
      }}
      isOpen={isOpenAliasNameModal}
      toggle={() => setIsOpenAliasNameModal(!isOpenAliasNameModal)}
    />
  );
});
