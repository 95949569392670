import React, { useEffect, useRef, useState } from 'react';
import { size } from 'lodash-es';
import cx from 'classnames';

import Button from '@mui/material/Button';
import api from '@packs/apis/currentUser';
import { Loader } from '@packs/components';
import { FieldWithErrors } from '@packs/components/FieldWithErrors';
import { Timer } from '@packs/components/UI';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { toastr } from '@packs/lib/helpers';
import { useStore } from '@packs/stores';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { Field } from 'redux-form';

const codesInputs = ['code_1', 'code_2', 'code_3', 'code_4', 'code_5', 'code_6'];

export const VerificationForm = ({
  handleSubmit,
  formAttributes,
  closeVerification,
  phoneNumber,
  handleSubmitVerification
}) => {
  const { userStore } = useStore();
  const { currentUser } = useUserSelectors();
  const { isDesktop } = useWindowSize();
  const [error, setError] = useState('');
  const [disableResend, setDisableResend] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const disabled = !size(formAttributes) || codesInputs.some(item => !formAttributes[item]) || isLoading;

  const firstInput = useRef(null);

  const onSubmit = async data => {
    setIsLoading(true);
    handleDisableResend(5000);
    const code = Object.keys(data)
      .map(key => data[key].trim())
      .join('');
    const resp = await api.phoneSmsCodeCheck({ phoneNumber: phoneNumber.replace(/\s+/g, ''), code });

    if (resp?.status === 'approved') {
      handleSubmitVerification();
      userStore.setCurrentUser({
        ...currentUser,
        verifiedNumbers: [...currentUser.verifiedNumbers, phoneNumber.replace(/\s+/g, '')]
      });
    } else if (resp?.errors) {
      toastr.error(resp?.errors);
      setError(resp?.errors[0]);
    } else {
      toastr.error('Invalid Code');
    }
    setIsLoading(false);
  };

  const onCancel = () => {
    closeVerification();
  };

  const handleDisableResend = (timeout = 20000) => {
    setDisableResend(true);

    setTimeout(() => {
      setDisableResend(false);
    }, timeout);
  };

  const sendNumber = async () => {
    if (phoneNumber) {
      const resp = await api.phoneVerify({ phoneNumber: phoneNumber.replace(/\s+/g, '') });
      if (resp?.errors) {
        toastr.error(resp?.errors);
      } else {
        handleDisableResend();
      }
    }
  };

  const resendCode = () => {
    if (!disableResend) {
      sendNumber();
    }
  };

  useEffect(() => {
    handleDisableResend();
    firstInput?.current && firstInput.current.focus();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isLoading && <Loader local />}
      <div className='verification-container d-flex flex-column align-items-center'>
        <div className={cx('fw-700 lh-5 mb-16 text-center', isDesktop ? 'fs-28' : 'fs-19')}>
          We sent you a code to verify your phone number
        </div>
        <div className={cx('lh-5 secondary-text', isDesktop ? 'fs-19' : 'fs-4')}>Sent to {phoneNumber}</div>
        <div className='d-flex align-items-center mt-30 mb-10'>
          {codesInputs.map((item, index) => (
            <div className='verification-input-holder' key={item}>
              <Field
                name={item}
                component={FieldWithErrors}
                type='text'
                placeholder='x'
                className='with-border verification-input'
                maxLength={1}
                nextTabCount={6}
                inputRef={index === 0 ? firstInput : null}
                nextTab
                onlyNumber
                hidePlaceholder
                disableFocusReset
              />
            </div>
          ))}
        </div>
        {error && <div className='error-hint mb-10'>{error}</div>}
        <div
          className={cx('lh-9 d-flex align-items-center secondary-text', isDesktop ? 'fs-19 mt-40' : 'fs-14 mt-16', {
            'flex-column': !isDesktop,
            'disable-text': disableResend
          })}
        >
          I didn’t receive a code!
          <span
            className={cx('cursor-pointer primary-text ml-5', { 'disable-text': disableResend })}
            onClick={resendCode}
          >
            Resend
          </span>
          {disableResend && (
            <div className='ml-10'>
              <Timer initialSeconds={20} title='Wait' />
            </div>
          )}
        </div>
      </div>
      <div className='btn-container d-flex align-items-center justify-content-center mt-24 gap-16'>
        <Button variant='outlined' color='primary' onClick={onCancel}>
          Cancel
        </Button>
        <Button type='submit' variant='contained' color='primary' disabled={disabled}>
          Verify
        </Button>
      </div>
    </form>
  );
};
