import React from 'react';

export const InsuranceLifeInsurance = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.188 4.47295L11.188 1.36195C11.705 1.13195 12.295 1.13195 12.812 1.36195L19.812 4.47295C20.535 4.79395 21 5.51095 21 6.30095V11.001C21 18.831 14.561 22.487 12 23.001C9.439 22.487 3 18.831 3 11.001V6.30095C3 5.50995 3.465 4.79395 4.188 4.47295ZM14.5 9.5C14.5 10.8813 13.3813 12 12 12C10.6187 12 9.5 10.8813 9.5 9.5C9.5 8.11875 10.6187 7 12 7C13.3813 7 14.5 8.11875 14.5 9.5ZM7 15.75C7 14.0875 10.3313 13.25 12 13.25C13.6687 13.25 17 14.0875 17 15.75V16.375C17 16.7188 16.7188 17 16.375 17H7.625C7.28125 17 7 16.7188 7 16.375V15.75Z'
      />
    </svg>
  );
};
