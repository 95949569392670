import React from 'react';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { TooltipMUI } from '@shared/components/TooltipMUI/TooltipMUI';

import useStyles from './styles';

import isNumber from 'lodash-es/isNumber';

const capitalizeFirstLetter = str => {
  if (isNumber(str)) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const AppliedFilters = ({ filters }) => {
  const styles = useStyles();
  const filledFilters = filters.filter(item => item?.label);

  if (filledFilters.length === 0) {
    return null;
  }

  return (
    <Box sx={styles.wrapper}>
      {filledFilters.map((item, index) => (
        <TooltipMUI key={item + index} title={item.title} placement='top' arrow>
          <Chip
            label={Array.isArray(item?.label) ? item?.label.join(', ') : capitalizeFirstLetter(item?.label)}
            sx={{ fontSize: '12px', lineHeight: 1.5 }}
          />
        </TooltipMUI>
      ))}
      <Typography variant='body3'>{`${filledFilters.length} tags applied`}</Typography>
    </Box>
  );
};
