import AtIcon from '@shared/images/flags/at.svg';
import AuIcon from '@shared/images/flags/au.svg';
import CaIcon from '@shared/images/flags/ca.svg';
import ChIcon from '@shared/images/flags/ch.svg';
import DeIcon from '@shared/images/flags/de.svg';
import IeIcon from '@shared/images/flags/ie.svg';
import InIcon from '@shared/images/flags/in.svg';
import NzIcon from '@shared/images/flags/nz.svg';
import SgIcon from '@shared/images/flags/sg.svg';
import UkIcon from '@shared/images/flags/uk.svg';
import UsIcon from '@shared/images/flags/us.svg';
import ZaIcon from '@shared/images/flags/za.svg';

export const getCountryFlag = countryCode => {
  switch (countryCode) {
    case 'en-ie':
    case 'ie':
      return IeIcon;
    case 'en-us':
    case 'us':
      return UsIcon;
    case 'en-au':
    case 'au':
      return AuIcon;
    case 'en-at':
    case 'at':
      return AtIcon;
    case 'en-de':
    case 'de':
      return DeIcon;
    case 'en-in':
    case 'in':
      return InIcon;
    case 'en-ca':
    case 'ca':
      return CaIcon;
    case 'en-nz':
    case 'nz':
      return NzIcon;
    case 'en-sg':
    case 'sg':
      return SgIcon;
    case 'en-za':
    case 'za':
      return ZaIcon;
    case 'en-uk':
    case 'uk':
      return UkIcon;
    case 'en-ch':
    case 'ch':
      return ChIcon;

    default:
      return '🇮🇪';
  }
};
