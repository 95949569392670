import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { usePath } from '@packs/layouts/constants';
import { useStore } from '@packs/stores';

import { bannerImage } from './constants';
import useStyles from './styles';

import { Link } from 'react-router-dom';

export const BannerWithAction = ({ category, onCloseMenu }) => {
  const { currentCountry } = useStore();
  const { DASHBOARD_ROOT_LANDING_PATH_FN } = usePath();
  const styles = useStyles();
  const categoryName = category?.name;

  return (
    <Box
      sx={{
        ...styles.wrapper,
        ...styles.bannerWrapper,
        backgroundImage: `url(${bannerImage(category.code)})`
      }}
    >
      <Box sx={styles.container}>
        <Box>
          <Typography variant='h6' sx={styles.title}>
            Member Benefits:
          </Typography>
        </Box>
        <Box sx={styles.list}>
          <Box>
            <Box>
              <Typography variant='body4' sx={styles.subTitle}>
                1. Compare the best deals!
              </Typography>
            </Box>
            <Box>
              <Typography variant='body3'>
                We compare the best deals from providers, brokers and price comparison websites!
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box>
              <Typography variant='body4' sx={styles.subTitle}>
                2. Personalized Leaderboards!
              </Typography>
            </Box>
            <Box>
              <Typography variant='body3'>
                Our {categoryName} leaderboards are powered by the people of {currentCountry?.name}.
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box>
              <Typography variant='body4' sx={styles.subTitle}>
                3. Personalized Offers!
              </Typography>
            </Box>
            <Box>
              <Typography variant='body3'>Get all the best offers for {categoryName} in one place!</Typography>
            </Box>
          </Box>
          <Box>
            <Box>
              <Typography variant='body4' sx={styles.subTitle}>
                4. Personalized Support!
              </Typography>
            </Box>
            <Box>
              <Typography variant='body3'>Get support and insights from our community!</Typography>
            </Box>
          </Box>
          <Box>
            <Button
              component={Link}
              to={DASHBOARD_ROOT_LANDING_PATH_FN({ root: { code: category.code } })}
              variant='contained'
              color='white'
              fullWidth
              sx={styles.button}
              onClick={onCloseMenu}
            >
              Find out in detail!
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
