import React from 'react';
import cx from 'classnames';

import TooltipPopoverWrapper from 'reactstrap/lib/TooltipPopoverWrapper';
import { getTarget } from 'reactstrap/lib/utils';

export const Popover = function Popover(props) {
  const newProps = {
    ...props,
    placement: props.placement || 'right',
    placementPrefix: props.placementPrefix || 'bs-popover',
    trigger: props.trigger || 'click'
  };

  const popperClasses = cx('popover', 'show');
  const classes = cx('popover-inner', newProps.innerClassName);

  TooltipPopoverWrapper.prototype.componentDidMount = function componentDidMount() {
    this._isMounted = true;

    try {
      this.updateTarget();
    } catch {
      // console.log('Popover: componentDidMount target  not defined');
    }
  };

  TooltipPopoverWrapper.prototype.shouldComponentUpdate = function shouldComponentUpdate() {
    let shouldRender = true;

    try {
      getTarget(this.props.target, true);
    } catch {
      shouldRender = false;
    }

    return shouldRender;
  };

  TooltipPopoverWrapper.prototype.updateTarget = function updateTarget() {
    try {
      const newTarget = (0, getTarget)(this.props.target, true);

      if (newTarget !== this._targets) {
        this.removeTargetEvents();
        this._targets = newTarget ? Array.from(newTarget) : [];
        this.currentTargetElement = this.currentTargetElement || this._targets[0];
        this.addTargetEvents();
      }
    } catch (err) {
      // console.error(err);
    }
  };

  return <TooltipPopoverWrapper popperClassName={popperClasses} innerClassName={classes} {...newProps} />;
};
