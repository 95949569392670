import typography from '@crm/assets/theme/base/typography';
import pxToRem from '@shared/assets/theme/functions/pxToRem';

const { size } = typography;

const dialogTitle = {
  styleOverrides: {
    root: {
      padding: pxToRem(16),
      fontSize: size.xl
    }
  }
};

export default dialogTitle;
