import { Form } from './Form';

import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';

const mapStateToProps = (state, props) => ({ formAttributes: getFormValues(props.form)(state) });

const createReduxForm = reduxForm({ form: 'createQuestion' });

export default createReduxForm(connect(mapStateToProps)(Form));
