import React, { useEffect, useMemo, useRef } from 'react';
import _ from 'lodash-es';
import cx from 'classnames';

import colors from '@packs/assets/theme/base/colors';
import { CheckBoxField, CustomReactSelect, EditorField, FieldWithErrors, SignUpModalLink } from '@packs/components';
import AttachedDocument from '@packs/components/UI/MyNotes/components/attachedDocument';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import DocumentIcon from '@packs/images/icons/Document';
import UploadFile from '@packs/images/icons/UploadFile';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { runFormSubmit } from '@packs/lib/helpers';
import { useCategories } from '@packs/models/category/useCategories';
import { useStore } from '@packs/stores';
import { SUB_ICONS } from '@shared/helpers/subCategoryIcon';
import { useWindowSize } from '@shared/hooks/useWindowSize';
import { postTypes } from '@shared/lib/constants';

import { observer } from 'mobx-react';
import { Field } from 'redux-form';

const generalCategory = {
  value: 'general',
  label: 'General'
};

export const Form = observer(
  ({
    files,
    toggle,
    change,
    disabled,
    editMode,
    category,
    onFileAdd,
    removeFile,
    editorValue,
    handleSubmit,
    onDestroyFile,
    formAttributes,
    disabledUpload,
    setEditorValue,
    additionalFiles,
    countryRegionName,
    withOutCategoryMode,
    isLoading = false
  }) => {
    const { toggleConnectSocialModalOpen } = useModalsContext();
    const { currentUser } = useUserSelectors();
    const { countryCode, currentCountry } = useStore();
    const { categoriesOptions, flattenCategories } = useCategories();
    const { isMobile } = useWindowSize();
    const formRef = useRef();

    const defaultCountryRegionOption = { label: currentCountry.name, value: currentCountry.isoA2Code };
    const regionOptions = [defaultCountryRegionOption].concat(
      _.get(currentCountry, 'regions', []).map(option => ({ label: option.name, value: option.name }))
    );

    const categoriesList = useMemo(() => {
      return isMobile ? flattenCategories.map(({ id, name }) => ({ value: id, label: name })) : categoriesOptions;
    }, [isMobile, flattenCategories, categoriesOptions]);

    const selectedCategory = useMemo(() => {
      return flattenCategories.find(
        item => item.id === (formAttributes?.categoryId?.value || formAttributes?.categoryId)
      );
    }, [formAttributes?.categoryId]);

    const postType = useMemo(() => {
      return (
        postTypes.find(({ value }) => formAttributes?.postType?.value === value || formAttributes?.postType === value)
          ?.label || ''
      );
    }, [formAttributes?.postType]);

    const postTitle = useMemo(() => {
      if (editMode) {
        return `Update a ${postType}`;
      }

      if (formAttributes?.postType?.value === 'recommendation') {
        return 'Ask for a Recommendation';
      }

      return `Create a ${postType}`;
    }, [editMode, postType]);

    const needSocialConnect = useMemo(() => {
      return (
        (formAttributes?.twitter && !currentUser?.socialProviders?.twitter) ||
        (formAttributes?.linkedin && !currentUser?.socialProviders?.linkedin)
      );
    }, [formAttributes?.twitter, formAttributes?.linkedin, currentUser]);

    const onConnectSocial = () => {
      if (needSocialConnect && !disabled) {
        toggleConnectSocialModalOpen({
          onSubmit: () => {
            runFormSubmit(formRef.current);
          },
          submitBtnText: 'Add Post',
          allowSocials: ['twitter', 'linkedin'].filter(key => formAttributes[key])
        });
      }
    };

    const onSignUpSuccess = () => {
      if (needSocialConnect && !editMode) {
        onConnectSocial();
      } else {
        runFormSubmit(formRef.current);
      }
    };

    useEffect(() => {
      if (withOutCategoryMode) {
        const subCat =
          category?.id === category?.root?.id ? generalCategory : { value: category.id, label: category.name };
        change('categoryId', subCat);
      }
      if (!editMode) {
        change('countryRegionName', countryRegionName ? countryRegionName : defaultCountryRegionOption);
        change('billWinner', true);
      }
    }, []);

    return (
      <form ref={formRef} onSubmit={handleSubmit}>
        <div className='modal-scroll-view'>
          <div className='title text-center'>{postTitle}</div>
          <div className='create-box-wrapper'>
            <div className='create-box'>
              <div className='top-panel d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                  {!withOutCategoryMode && (
                    <>
                      <div className='mr-15'>
                        {SUB_ICONS({ category, fill: colors?.category[category?.root?.enName]?.main })}
                      </div>
                      {category?.name}
                    </>
                  )}
                  {withOutCategoryMode && (
                    <div className={cx(!isMobile && 'ml-15')}>
                      <div className={cx('label fs-14 fw-700', isMobile && 'text-center')}>Select category</div>
                      <div className='d-flex align-items-center'>
                        {selectedCategory && (
                          <div className='mr-10'>
                            {SUB_ICONS({
                              category: selectedCategory,
                              fill: colors?.category[selectedCategory?.root?.enName]?.main
                            })}
                          </div>
                        )}
                        <Field
                          component={CustomReactSelect}
                          name='categoryId'
                          options={[generalCategory, ...categoriesList]}
                          classNamePrefix='Select'
                          placeholder='Category'
                          isSearchable={!isMobile}
                          className='flexible-width'
                          mobileDrawer
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className='selects-section d-flex align-items-center'>
                  <Field
                    component={CustomReactSelect}
                    name='countryRegionName'
                    options={regionOptions}
                    classNamePrefix='Select'
                    placeholder='Location'
                    isSearchable={!isMobile}
                    className={cx('flexible-width', isMobile ? 'left-position' : 'right-position')}
                  />
                  <Field
                    component={CustomReactSelect}
                    name='postType'
                    options={postTypes}
                    classNamePrefix='Select'
                    placeholder='Post type'
                    isSearchable={false}
                    className='flexible-width right-position'
                  />
                </div>
              </div>
              <div className='middle-panel'>
                <div className='form'>
                  <div className='title-wrapper title-input'>
                    <Field
                      className='form-input'
                      placeholder='Enter title'
                      name='title'
                      component={FieldWithErrors}
                      disableFocusReset
                    />
                  </div>

                  <EditorField
                    value={editorValue}
                    countryCode={countryCode}
                    placeholder='Enter description'
                    onChange={setEditorValue}
                  />
                  <div className='tags-info d-flex align-items-center'>
                    <div>#hashtag</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={cx('d-flex justify-content-between', isMobile && 'flex-column')}>
              <div className='files-container d-flex align-items-center'>
                <label className='d-flex justify-content-center mr-15'>
                  <div className={cx('upload-btn', { disabled: disabledUpload })}>
                    <UploadFile />
                  </div>
                  {!disabledUpload && (
                    <input
                      className='hidden d-none'
                      type='file'
                      name='atach'
                      id='atach'
                      accept='image/*, .pdf'
                      multiple
                      onChange={onFileAdd}
                    />
                  )}
                </label>
                <div className='attached-files d-flex align-items-center justify-content-center --new pt-0'>
                  {additionalFiles.map(({ id, url, contentType }) => (
                    <div key={id} className='image-box'>
                      <div className='delete-file' onClick={onDestroyFile(id)}>
                        x
                      </div>
                      {contentType?.match(/image/) ? (
                        <img src={url} alt={contentType} />
                      ) : (
                        <a href={url} target='_blank' rel='noreferrer'>
                          <DocumentIcon />
                        </a>
                      )}
                    </div>
                  ))}
                  {files.map((file, index) => (
                    <AttachedDocument
                      key={index}
                      isNew
                      hideName
                      {...{
                        index,
                        file,
                        removeFile
                      }}
                    />
                  ))}
                </div>
              </div>
              {!editMode && (
                <div
                  className={cx(
                    'post-share-buttons d-flex align-items-center justify-content-center',
                    !isMobile ? 'ml-15' : 'mt-20 fxw-w'
                  )}
                >
                  <div className='ws-nw'>Post to:</div>
                  <Field
                    component={CheckBoxField}
                    name='billWinner'
                    label='Bill Winner'
                    className='disabled'
                    disabled
                  />
                  <Field component={CheckBoxField} name='twitter' label='Twitter' />
                  <Field component={CheckBoxField} name='facebook' label='Facebook' />
                  <Field component={CheckBoxField} name='linkedin' label='Linkedin' />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='btn-container'>
          <div className='btn cancel' onClick={toggle}>
            Cancel
          </div>
          {!currentUser?.id ? (
            <SignUpModalLink
              className={cx('btn black empty submit', { disabled })}
              onSignUpSuccess={onSignUpSuccess}
              disabled={disabled}
              skipRedirect={true}
            >
              <span>
                {editMode ? 'Update' : 'Add'} {postType}
              </span>
            </SignUpModalLink>
          ) : (
            <button
              className={cx('btn empty black', { disabled: isLoading || disabled })}
              type={needSocialConnect && !editMode ? 'button' : 'submit'}
              disabled={isLoading || disabled}
              onClick={onConnectSocial}
            >
              {isLoading && <span className='spinner-border spinner-border-sm mr-3' role='status' aria-hidden='true' />}
              <span>
                {editMode ? 'Update' : 'Add'} {postType}
              </span>
            </button>
          )}
        </div>
      </form>
    );
  }
);
