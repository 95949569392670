import React, { useRef, useState } from 'react';
import moment from 'moment';

import colors from '@crm/assets/theme/base/colors';
import CommentRoundedIcon from '@mui/icons-material/CommentRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import { SignInModalLink } from '@packs/components';
import { commentableType, helpfulTypes } from '@packs/lib/constants';
import { convertEditorStateToHtml, shortSecondName } from '@packs/lib/helpers/additionalHelpers';
import { BadgeSimple } from '@shared/components/BadgeSimple/BadgetSimple';
import { AnswerForm } from '@shared/components/QA/AnswerForm/AnswerForm';
import { AnswersList } from '@shared/components/QA/AnswersList';
import { FilesPreview } from '@shared/components/QA/FilesPreview';
import { avatarPlaceholder, stringToColor } from '@shared/helpers/additionalHelpers';
import { SUB_ICONS } from '@shared/helpers/subCategoryIcon';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import useStyles from './styles';

import startCase from 'lodash-es/startCase';
import AnimateHeight from 'react-animate-height';

export const QuestionItem = ({
  question,
  withOutCategoryMode = null,
  questionsContainerOffset,
  owner = null,
  index,
  questionsStore,
  currentUser,
  toggleCreateQuestionModal,
  toggleConfirmModalOpen,
  closeConfirmModal,
  toggleSignUpModal,
  IS_CRM = false
}) => {
  const styles = useStyles();
  const questionItemRef = useRef(null);
  const descriptionRef = useRef(null);

  const { isMobile } = useWindowSize();
  const {
    id,
    user,
    title,
    files,
    company,
    postType,
    keywords,
    category,
    isAbused,
    updatedAt,
    isHelpful,
    targetSex,
    changeable,
    description,
    businessUser,
    helpfulsCount,
    commentsCount,
    countryRegionName
  } = question;
  const [isOpenAnswers, setIsOpenAnswers] = useState(false);
  const [descriptionIsOpen, setDescriptionIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);

  const open = anchorEl;
  const avatarUrl = businessUser?.company?.avatarUrl || owner?.avatarUrl || user?.avatarUrl || '';
  const displayName = businessUser?.businessName || owner?.displayName || user?.displayName || '';
  const descriptionToHtml = convertEditorStateToHtml(description);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDescription = () => {
    setDescriptionIsOpen(!descriptionIsOpen);
  };

  const toggleAnswers = () => {
    setIsOpenAnswers(!isOpenAnswers);
  };

  const onToggleHelpful = () => {
    questionsStore.toggleHelpful({
      attrs: {
        helpfulableType: commentableType.post,
        helpfulableId: id
      },
      postId: id,
      helpfulType: isHelpful ? helpfulTypes.destroy : helpfulTypes.create
    });
  };

  const submitCallback = () => {
    window.scrollTo({
      top: questionItemRef?.current?.offsetTop + questionItemRef?.current?.clientHeight - 100,
      behavior: 'smooth'
    });
  };

  const onShowUpdateModal = () => {
    toggleCreateQuestionModal({
      id,
      category,
      companyId: company?.id || null,
      withOutCategoryMode,
      uploadedFiles: files,
      initialValues: {
        postType,
        targetSex,
        title,
        description,
        countryRegionName
      }
    });
  };

  const onShowReportModal = () => {
    if (!isAbused) {
      toggleConfirmModalOpen({
        modalProps: {
          title: 'Report post',
          description: 'Are you sure you want to report this post?',
          submitBtnText: 'Report',
          onSubmit: () => questionsStore.report({ qaPostId: id })
        }
      });
    }
  };

  const onDeletePostModal = () => {
    toggleConfirmModalOpen({
      modalProps: {
        title: 'Delete post',
        description: 'Are you sure you want to delete this post?',
        submitBtnText: 'Delete',
        onSubmit: async () => {
          const res = await questionsStore.destroy({ id });
          if (IS_CRM) {
            closeConfirmModal();
          }
          return res;
        }
      }
    });
  };

  const cleanText = descriptionToHtml.replace(/<\/?[^>]+(>|$)/g, '');
  const descriptionSize = cleanText.length >= (isMobile ? 60 : 214);

  const allowActions =
    (+currentUser?.id === (+businessUser?.id || +user?.id || +currentUser?.user?.id) ||
      +owner?.id === +businessUser?.id) &&
    changeable;

  return (
    <Zoom timeout={500} in={true} style={{ transitionDelay: `${index * 200}ms` }}>
      <Box ref={questionItemRef} sx={styles.wrapper}>
        <Box sx={styles.topSection}>
          <Box sx={styles.userInfoSection}>
            <Avatar
              src={avatarUrl}
              variant='rounded'
              sx={{ width: '48px', height: '48px', backgroundColor: stringToColor(displayName) }}
            >
              {avatarPlaceholder(displayName)}
            </Avatar>
            <Box>
              <Typography variant='body2' sx={{ mb: 1 }}>
                {shortSecondName(displayName)}
              </Typography>
              <Typography variant='body5' sx={{ color: colors.text.secondary }}>
                {(updatedAt && moment(updatedAt).fromNow()) || '-'}
              </Typography>
            </Box>
          </Box>
          {(!IS_CRM || allowActions) && (
            <Box>
              <IconButton onClick={handleClick} size='small'>
                <MoreHorizRoundedIcon />
              </IconButton>
              <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {!IS_CRM && (
                  <MenuItem
                    onClick={() => {
                      onShowReportModal();
                      handleClose();
                    }}
                    disabled={isAbused}
                  >
                    Report
                  </MenuItem>
                )}
                {allowActions && [
                  <MenuItem
                    key={1}
                    onClick={() => {
                      onShowUpdateModal();
                      handleClose();
                    }}
                  >
                    Update post
                  </MenuItem>,
                  <MenuItem
                    key={2}
                    onClick={() => {
                      onDeletePostModal();
                      handleClose();
                    }}
                  >
                    Delete post
                  </MenuItem>
                ]}
              </Menu>
            </Box>
          )}
        </Box>
        <Box sx={styles.ratingCategorySection}>
          <Box sx={styles.ratingWrapper}>
            <BadgeSimple label={startCase(postType)} />
          </Box>
          {category?.code && (
            <Box sx={styles.ratingWrapper}>
              <Box sx={styles.categorySection}>
                {SUB_ICONS({ category, fill: colors.dark.main, size: '16px' })}
                <Typography variant='caption'>{category.name}</Typography>
              </Box>
            </Box>
          )}
        </Box>
        <Box>
          {title && (
            <Typography variant='body2' sx={styles.title}>
              {title}
            </Typography>
          )}

          {cleanText?.length > 0 && (
            <>
              {descriptionSize ? (
                <Typography variant='body1' ref={descriptionRef}>
                  {descriptionSize && (
                    <Typography
                      variant='body1'
                      ref={descriptionRef}
                      dangerouslySetInnerHTML={{ __html: descriptionToHtml }}
                      sx={styles.comment}
                      className={descriptionIsOpen ? 'full' : ''}
                    />
                  )}
                  {descriptionSize && !descriptionIsOpen && (
                    <Typography
                      variant='body2'
                      onClick={toggleDescription}
                      sx={{ textAlign: 'right', cursor: 'pointer' }}
                    >
                      Read more
                    </Typography>
                  )}
                </Typography>
              ) : (
                <Typography
                  variant='body1'
                  ref={descriptionRef}
                  dangerouslySetInnerHTML={{ __html: descriptionToHtml }}
                  sx={styles.comment}
                  className='full'
                />
              )}
            </>
          )}
        </Box>
        {keywords?.length > 0 && (
          <Box sx={styles.keyWordsWrapper}>
            {keywords.map((item, index) => (
              <Typography varian='body1' key={item + index}>
                #{item}
              </Typography>
            ))}
          </Box>
        )}
        {files?.length > 0 && <FilesPreview files={files} />}
        <Box sx={styles.actionPanelWrapper}>
          <Box sx={styles.actionPanelSection}>
            <Button
              variant='text'
              color='dark'
              size='smallToMedium'
              startIcon={<CommentRoundedIcon />}
              onClick={toggleAnswers}
            >
              {!isMobile && 'Comments '}
              {commentsCount}
            </Button>
          </Box>
          <Box sx={styles.actionPanelSection}>
            {currentUser?.id ? (
              <Button
                variant='text'
                color={isHelpful ? 'primary' : 'dark'}
                size='smallToMedium'
                startIcon={<ThumbUpAltRoundedIcon />}
                onClick={onToggleHelpful}
              >
                {!isMobile && 'Helpful'} {helpfulsCount || 0}
              </Button>
            ) : (
              <SignInModalLink variant='text' color='dark' size='smallToMedium'>
                {!isMobile && 'Helpful'} {helpfulsCount || 0}
              </SignInModalLink>
            )}
          </Box>
        </Box>
        <AnimateHeight duration={250} height={isOpenAnswers ? 'auto' : 0}>
          <AnswerForm
            form={`qa-from-${id}`}
            submitBtnText='Add a comment'
            placeholder='Add a comment...'
            commentableType={commentableType.post}
            commentableId={id}
            postId={id}
            submitCallback={submitCallback}
            {...{ questionsStore, toggleSignUpModal, currentUser }}
          />
          <AnswersList
            postId={id}
            isOpen={isOpenAnswers}
            {...{ questionsStore, questionsContainerOffset, currentUser, toggleSignUpModal }}
          />
        </AnimateHeight>
      </Box>
    </Zoom>
  );
};
