import colors from '@packs/assets/theme/base/colors';

const { text } = colors;

export default () => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    mb: 5
  },
  contentSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1
  },
  subTitle: {
    color: text.secondary
  }
});
