import React from 'react';

export default () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='14' height='11' viewBox='0 0 14 11'>
      <g fill='#AABACC' fillRule='nonzero'>
        <path d='M1.343 3.405c.177.124.71.494 1.6 1.11.89.616 1.572 1.09 2.046 1.422l.331.238c.17.122.31.22.422.296a8.1 8.1 0 0 0 .406.253c.159.094.308.164.449.21.14.047.27.07.39.07h.016c.12 0 .25-.023.39-.07.14-.046.29-.116.449-.21.159-.093.294-.178.406-.253.112-.075.252-.174.421-.296l.332-.238 3.654-2.533c.38-.264.697-.584.952-.958s.383-.766.383-1.177c0-.343-.124-.636-.371-.88a1.208 1.208 0 0 0-.878-.367H1.249c-.4 0-.71.135-.925.406C.108.698 0 1.035 0 1.44c0 .327.143.682.43 1.063.286.382.59.682.913.9z' />
        <path d='M13.209 4.238a162.257 162.257 0 0 0-3.888 2.689c-.296.218-.537.388-.722.51a4.46 4.46 0 0 1-.738.374 2.25 2.25 0 0 1-.858.191h-.016a2.25 2.25 0 0 1-.859-.19 4.461 4.461 0 0 1-.737-.375 18.489 18.489 0 0 1-.722-.51c-.703-.514-1.996-1.41-3.88-2.689A4.133 4.133 0 0 1 0 3.56v6.188c0 .343.122.636.367.88.244.245.539.367.882.367h11.492c.343 0 .637-.122.882-.367a1.2 1.2 0 0 0 .367-.88V3.56c-.224.25-.484.476-.781.678z' />
      </g>
    </svg>
  );
};
