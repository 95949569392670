import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import { SwipePicker } from '@packs/components';
import { useDetailsForm } from '@packs/screens/PersonalizeResults/DetailsFormProvider';

import { DebugName } from './debug';

import find from 'lodash-es/find';
import AsyncSelect from 'react-select/async';
import { Select } from 'react-select-virtualized';

export const SelectField = props => {
  const { getRef, onChange } = useDetailsForm();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [localOptions, setLocalOptions] = useState([]);
  const selectEl = useRef(null);

  const {
    name,
    async,
    search,
    question,
    isLoading,
    title,
    formatOptionLabel,
    isSearchable = true,
    checkScrollPosition,
    swipePicker = false,
    filterPlaceholderText,
    className,
    buttons
  } = props;

  const placeholder = props.placeholder || (question && question.placeholder) || '';
  const options = props.options?.length ? props.options.slice() : localOptions?.slice();
  const disabled = props.disabled || isDisabled;

  const value = options.find(item => item.value === props.value) || '';

  if (!name) {
    throw new Error('no name');
  }

  let ref = null;
  useEffect(() => {
    ref = getRef(
      new SNode({
        name,
        setLocalOptions,
        setIsOpen,
        setIsDisabled,
        value,
        options
      })
    );
  }, []);

  useEffect(() => {
    if (ref && props.options) ref.initOptions = props.options;
  }, [props.options, ref?.initOptions]);

  const onSelectChange = dataValue => {
    onChange({ target: { name, value: dataValue?.value } }, { isSliderSteps: props.isSliderSteps });

    props.onChange && props.onChange(dataValue?.value);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onOpen = p => {
    setIsOpen(true);
    props.onOpen && props.onOpen(p);
    checkScrollPosition && checkScrollPosition();
  };

  options.forEach(op => {
    op.label = String(op.label);
  });

  const SelectComponent = pps => {
    if (swipePicker) {
      // MobiScroll lib not allow custom filter and it unsupport GQL so it trick enable it
      const remoteData = {
        url: '/ie/search-companies/?search=',
        remoteFilter: true,
        dataType: 'json',
        processResponse: data => {
          const search = data.search;
          const options = props.retrieveSwipeOptions(search);
          return options.map(opt => ({
            ...opt,
            text: opt.label
          }));
        }
      };

      return (
        <SwipePicker
          remoteData={remoteData}
          onChange={onSelectChange}
          {...{
            options,
            value,
            placeholder,
            buttons
          }}
          filterPlaceholderText={filterPlaceholderText}
          useOptions
        />
      );
    }

    if (async) {
      return (
        <AsyncSelect
          {...pps}
          ref={selectEl}
          defaultOptions
          options={undefined}
          loadOptions={props.loadOptions}
          menuIsOpen={isOpen}
          isSearchable
          onMenuOpen={e => {
            onOpen(e);
            setTimeout(() => {
              if (selectEl.current) {
                selectEl.current.focus();
              }
            }, 200);
          }}
          onMenuClose={onClose}
        />
      );
    }

    return (
      <Select
        {...pps}
        ref={selectEl}
        enuIsOpen={isOpen}
        onMenuOpen={e => {
          onOpen(e);
          setTimeout(() => {
            if (selectEl.current) {
              selectEl.current.focus();
            }
          }, 200);
        }}
        onMenuClose={onClose}
      />
    );
  };

  return (
    <div className={cx('field', { disabled })}>
      <DebugName name={name} />

      {title && <div className='title'>{title}</div>}

      <SelectComponent
        classNamePrefix='Select'
        menuIsOpen={isOpen}
        className={cx('select-ui white-bg with-light-border', className, { search })}
        isDisabled={disabled}
        isLoading={isLoading}
        isClearable={false}
        optionHeight={44}
        onMenuOpen={onOpen}
        onMenuClose={onClose}
        onChange={onSelectChange}
        {...{
          options,
          value,
          placeholder,
          formatOptionLabel,
          isSearchable
        }}
      />
    </div>
  );
};

class SNode {
  constructor({ name, setLocalOptions, setIsOpen, setIsDisabled, value, options }) {
    this.name = name;
    this.type = 'select';
    this.initOptions = options;
    this.opValue = value;
    this.setIsDisabled = setIsDisabled;
    this.setLocalOptions = setLocalOptions;
    this.setIsOpen = setIsOpen;
  }

  set value(val) {
    // if (val === undefined) return;
    // if (val === this.initValue || val == this.initValue?.value) return;

    // if (this.initOptions.length) {
    const value = val ? find(this.initOptions, { value: val }) || { value: val } : null;

    this.initValue = value;
    this.opValue = val;
    // }
  }

  set options(val) {
    // if (val === undefined) return;
    // if (this.initOptions.length) return;
    this.setLocalOptions(val);
    this.initOptions = val;
  }

  set disabled(val) {
    this.setIsDisabled(val);
  }
}
