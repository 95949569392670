import { requestsQueries } from '@packs/graphql/queries';
import { graphQLRequest } from '@packs/lib/utils';

export const requestsApi = {
  list: variables =>
    graphQLRequest({
      query: requestsQueries.requestsList,
      variables
    }),
  quoteAgentCount: variables =>
    graphQLRequest({
      query: requestsQueries.quoteAgentCount,
      variables
    }),
  quoteRequestsList: variables =>
    graphQLRequest({
      query: requestsQueries.quoteRequestUserList,
      variables
    })
};
