import React, { useEffect, useState } from 'react';

import api from '@packs/apis/company';
import { Avatar } from '@packs/components/UI';
import { BusinessSidebarBox } from '@packs/components/UI/Business/BusinessSidebarBox';
import DefaultBusinessIcon from '@packs/images/svg/default_business.svg';
import MembersLockedIcon from '@packs/images/svg/ui/members-loked.svg';
import { pathGen } from '@packs/layouts/constants';
import { defaultBusinessImage } from '@packs/lib/constants';
import { divideStarRating } from '@packs/lib/helpers';
import { handleVisitCompany } from '@packs/lib/helpers/additionalHelpers';
import { useStore } from '@packs/stores';
import { RatingMUI } from '@shared/components/RatingMUI/RatingMUI';

import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

export const MembersAlsoLooked = observer(({ category, companyId }) => {
  const [companies, setCompanies] = useState([]);
  const { countryCode } = useStore();

  const onVisitCompanyPage = company => {
    handleVisitCompany(company);
  };

  const fetchData = () => {
    api
      .fetchCompaniesProduct({
        categoryId: category.id,
        page: 1,
        perPage: 3,
        excludeCompanyId: +companyId
      })
      .then(res => {
        if (res?.errors.length === 0) {
          setCompanies(res.companies);
        }
      });
  };

  useEffect(() => {
    fetchData();
  }, [companyId]);

  if (companies.length === 0) {
    return null;
  }

  return (
    <BusinessSidebarBox title='Members also looked at' icon={MembersLockedIcon}>
      {companies.map(item => {
        const avatarUrl = item.avatarUrl === defaultBusinessImage ? DefaultBusinessIcon : item.avatarUrl;

        return (
          <div key={item.id} className='company-item d-flex align-items-center'>
            <div className='mr-15'>
              <Avatar avatarUrl={avatarUrl} size={30} square />
            </div>
            <Link
              className='business-name'
              to={pathGen('BUSINESS', { countryCode, company: item })}
              onClick={onVisitCompanyPage(item)}
            >
              {item.name}
            </Link>
            <div className='d-flex align-items-center ml-a'>
              <span className='rating-count fs-14 fw-400 ml-10'>
                {item.additionalData.rating ? divideStarRating(item.additionalData.rating).toFixed(1) : '0.0'}
              </span>
              <RatingMUI value={1} max={1} />
            </div>
          </div>
        );
      })}
    </BusinessSidebarBox>
  );
});
