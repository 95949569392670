import colors from '@packs/assets/theme/base/colors';

const { dark } = colors;

export default () => ({
  additionalInfoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    pt: 1.5
  },
  additionalItems: {
    display: 'grid',
    gridTemplateColumns: {
      xs: 'repeat(2, 1fr)',
      md: 'repeat(3, 1fr)'
    },
    gap: 2
  },
  tabsWrapper: {
    borderBottom: `1px solid ${dark.transparent}`
  }
});
