import React from 'react';

import GoogleIcon from '@mui/icons-material/Google';
import StarIcon from '@mui/icons-material/Star';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { pathGen } from '@packs/layouts/constants';
import { handleVisitCompany } from '@packs/lib/helpers/additionalHelpers';
import { useCategories } from '@packs/models/category/useCategories';
import { useStore } from '@packs/stores/base';
import { RatingMUI } from '@shared/components/RatingMUI/RatingMUI';
import { getTotalRating } from '@shared/helpers/companyHelper';
import { RatingCup } from '@shared/images/rating/RatingCup';

import useStyles from './styles';

import { Link } from 'react-router-dom';

const { text, primary } = colors;

export const Ratings = ({ company, withOutTotal = false, withOutDivider = false, billWinnerIsLink = false }) => {
  const { currentCategory } = useCategories();
  const { countryCode } = useStore();
  const styles = useStyles();

  const billwinnerScores = company.billwinnerScores || {};
  const googleScores = company.googleScores || {};
  const trustpilotScores = company.trustpilotScores || {};

  const ratingList = [
    {
      icon: <RatingCup />,
      label: 'Bill Winner',
      rating: billwinnerScores.rating,
      reviews: billwinnerScores.reviews,
      value: 'billWinner'
    },
    {
      icon: <GoogleIcon sx={{ fontSize: '16px' }} />,
      label: 'Google',
      rating: googleScores.rating,
      reviews: googleScores.reviews,
      value: 'google'
    },
    {
      icon: <StarIcon sx={{ fontSize: '16px' }} />,
      label: 'Trustpilot',
      rating: trustpilotScores.rating,
      reviews: trustpilotScores.reviews,
      value: 'trustpilot'
    }
  ];

  const onVisitCompanyPage = () => {
    handleVisitCompany(company, currentCategory);
  };

  const { totalRating } = getTotalRating(company);

  return (
    <Box sx={styles.ratingsWrapper}>
      {!withOutTotal && (
        <Box sx={{ ...styles.ratingsItem, justifyContent: 'space-between', p: 0 }}>
          <Box sx={styles.ratingsItemContent}>
            <Typography variant='body4'>Total:</Typography>
          </Box>
          <Box sx={styles.ratingsItemContent}>
            <RatingMUI value={totalRating} />
            <Typography variant='body4'>{totalRating} out of 5</Typography>
          </Box>
        </Box>
      )}
      {!withOutDivider && <Divider sx={{ mt: withOutTotal ? 0 : 1, mb: 1 }} />}
      <table>
        <tbody>
          {ratingList.map(item => (
            <tr key={item.label}>
              <td width='100%'>
                <Box sx={styles.ratingsItemContent}>
                  {item.icon}
                  <Typography variant='body3'>{item.label}</Typography>
                </Box>
              </td>
              <td>
                <Box sx={styles.ratingsItemContent}>
                  <Box sx={styles.ratingsItemContent}>
                    <RatingMUI value={1} max={1} color={text.secondary} />
                    <Typography variant='body3' sx={{ ...styles.ratingsPoint }}>
                      {(item.rating || 0).toFixed(1)}
                    </Typography>
                  </Box>
                  <Typography variant='body3'>|</Typography>
                  <Box>
                    {item.value === 'billWinner' && billWinnerIsLink ? (
                      <Typography
                        component={Link}
                        to={pathGen('PRODUCT', {
                          countryCode,
                          category: currentCategory,
                          company
                        })}
                        variant='body3'
                        sx={{ color: primary.secondary, whiteSpace: 'nowrap', textDecoration: 'underline' }}
                        onClick={onVisitCompanyPage}
                      >
                        {`${item.reviews ?? 0} Reviews`}
                      </Typography>
                    ) : (
                      <Typography variant='body3' sx={{ color: text.secondary, whiteSpace: 'nowrap' }}>
                        {`${item.reviews ?? 0} Reviews`}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
};
