import React from 'react';

export const LocalServicesIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path
          d='M21.6268 17.6393C20.964 16.7408 19.7647 15.4444 19.7647 15.4444C19.7647 15.4444 18.5654 16.7408 17.9026 17.6393C16.939 18.9454 16.4706 19.9485 16.4706 20.7059C16.4706 22.5223 17.9483 24 19.7647 24C21.5811 24 23.0588 22.5223 23.0588 20.7059C23.0588 19.9485 22.5904 18.9454 21.6268 17.6393Z'
          fill='white'
        />
        <path
          d='M15.7423 7.03647L15.5041 7.03896C15.1973 7.04216 14.9088 6.92527 14.6909 6.70969C14.4729 6.49407 14.3529 6.20649 14.3529 5.89995V3.52941H12.2353V2.11765H15.7647V0.705882H12.2353V0H10.8235V0.705882H7.2941V2.11765H10.8235V3.52941H8.70587V5.72536C8.70587 6.46066 8.1077 7.05882 7.37241 7.05882H5.58681C5.62577 7.2664 5.64705 7.48009 5.64705 7.69878V11.36C5.64705 11.5787 5.62573 11.7924 5.58681 12H15.7647C16.5431 12 17.1765 12.6333 17.1765 13.4118V14.1176H22.1176V13.4118C22.1176 9.89642 19.2577 7.03647 15.7423 7.03647Z'
          fill='white'
        />
        <path
          d='M2.18356 5.64706H0.941162V13.4118H2.18356C3.31486 13.4118 4.23528 12.4913 4.23528 11.36V7.69878C4.23528 6.56748 3.31486 5.64706 2.18356 5.64706Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
