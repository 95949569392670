import React from 'react';

export const BusinessEventInsurance = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <g>
          <path d='M19 3H18V2C18 1.45 17.55 1 17 1C16.45 1 16 1.45 16 2V3H8V2C8 1.45 7.55 1 7 1C6.45 1 6 1.45 6 2V3H5C3.89 3 3.01 3.9 3.01 5L3 19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM18 19H6C5.45 19 5 18.55 5 18V8H19V18C19 18.55 18.55 19 18 19Z' />
          <path d='M11.6816 10.2715C11.7631 10.09 11.9321 10 12.1016 10C12.2711 10 12.44 10.09 12.5215 10.2715L13.3594 12.1465L15.4258 12.3379C15.8358 12.3759 15.9928 12.8913 15.6738 13.1523L14.0996 14.4414L14.625 16.4004C14.732 16.7974 14.3052 17.124 13.9492 16.918L12.1016 15.8477L10.2754 16.9336C9.92839 17.1396 9.50284 16.8335 9.58984 16.4395L10.0293 14.4414L8.5 13.1504C8.188 12.8874 8.34791 12.3778 8.75391 12.3398L10.8438 12.1465L11.6816 10.2715Z' />
        </g>
      </g>
    </svg>
  );
};
