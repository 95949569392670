import React from 'react';

export const WasteCleaningIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path
          d='M19.56 11.36L13 8.44002V7.00002C13 6.45002 12.55 6.00002 12 6.00002C11.45 6.00002 11 5.55002 11 5.00002C11 4.45002 11.45 4.00002 12 4.00002C12.38 4.00002 12.72 4.22002 12.88 4.53002C13.04 4.84002 13.39 5.00002 13.73 5.00002C14.47 5.00002 14.99 4.21002 14.64 3.56002C14.04 2.46002 12.78 1.78002 11.4 2.05002C10.23 2.28002 9.28 3.25002 9.06 4.42002C8.77 5.98002 9.67 7.35002 11 7.82002V8.45002L4.44 11.37C3.56 11.75 3 12.62 3 13.57V13.58C3 14.92 4.08 16 5.42 16H7V20C7 21.1 7.9 22 9 22H15C16.1 22 17 21.1 17 20V16H18.58C19.92 16 21 14.92 21 13.58V13.57C21 12.62 20.44 11.75 19.56 11.36ZM18.58 14H16.72C16.37 13.4 15.74 13 15 13H9C8.26 13 7.62 13.4 7.28 14H5.42C4.96 14 4.84 13.35 5.25 13.19L12 10.19L18.75 13.19C19.17 13.38 19.03 14 18.58 14Z'
          fill='white'
        />
      </g>
    </svg>
  );
};
