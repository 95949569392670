import React from 'react';

export default () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='11' height='7' viewBox='0 0 11 7'>
      {/* eslint-disable-next-line */}
      <path d='M10.036.666l.55.574-3.278 3.425-1.662 1.736.275-.287c-.152.159-.55 0-.55 0l.275.287-1.663-1.736L.705 1.24l.55-.574 4.39 4.587L10.037.666z' />
    </svg>
  );
};
