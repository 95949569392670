import colors from '@packs/assets/theme/base/colors';

const { text } = colors;

export default () => ({
  ratingsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    '& table': {
      width: '100%',
      '& td': {
        py: 0.4
      }
    }
  },
  ratingsList: {
    display: 'flex',
    flexDirection: 'column'
  },
  ratingsItem: {
    display: 'flex',
    alignItems: 'center',
    py: 1
  },
  ratingsItemContent: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5
  },
  ratingsPoint: {
    minWidth: '20px',
    color: text.secondary
  }
});
