import React from 'react';

export const TravelAirlines = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path d='M5 3C4.45 3 4 3.45 4 4V6.69922L2.16992 9.44922C2.05992 9.60922 2 9.8 2 10V11C2 11.55 2.45 12 3 12V20C3.00003 20.2652 3.10539 20.5195 3.29292 20.7071C3.48045 20.8946 3.73479 21 4 21H20C20.2652 21 20.5195 20.8946 20.7071 20.7071C20.8946 20.5195 21 20.2652 21 20V12C21.55 12 22 11.55 22 11V10C22 9.8 21.9401 9.60922 21.8301 9.44922L20 6.69922V4C20 3.45 19.55 3 19 3H5ZM7 5C7.55 5 8 5.45 8 6C8 6.55 7.55 7 7 7C6.45 7 6 6.55 6 6C6 5.45 6.45 5 7 5ZM10 5C10.55 5 11 5.45 11 6C11 6.55 10.55 7 10 7C9.45 7 9 6.55 9 6C9 5.45 9.45 5 10 5ZM5 12H19V19H5V12ZM10 13L10.5 15H9L8 14H7L7.77148 16.3164C7.90748 16.7254 8.2897 17 8.7207 17H16C16.552 17 17 16.552 17 16C17 15.448 16.552 15 16 15H13L11 13H10Z' />
      </g>
    </svg>
  );
};
