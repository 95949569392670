import React from 'react';
import moment from 'moment';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { usePath } from '@packs/layouts/constants';

import RequestDetails from './components/RequestDetails';
import useStyles from './styles';

import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

export const RequestSidebar = observer(({ renewalDetail }) => {
  const styles = useStyles();
  const { MY_DETAILS_PATH_FN } = usePath();
  const updatedAt = renewalDetail?.updatedAt;

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.wrapperItem}>
        <Typography variant='body2'>My Details:</Typography>
        <Typography variant='body5'>
          Last update {(updatedAt && moment(updatedAt).fromNow()) || ''}
          <Box component={Link} to={MY_DETAILS_PATH_FN()} sx={styles.updateLink}>
            Update
          </Box>
        </Typography>
      </Box>
      <Box sx={{ px: 2, pb: 2 }}>
        <RequestDetails />
      </Box>
    </Box>
  );
});
