import React from 'react';

import { Popover } from '@packs/components';
import { NotificationsList } from '@packs/components/Notifications/NotificationsList';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import CloseDark from '@packs/images/svg/close-dark.svg';
import { useStore } from '@packs/stores';

import { observer } from 'mobx-react';
import { bool, func, object } from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PopoverBody, PopoverHeader } from 'reactstrap';

const propTypes = {
  currentUser: object,
  toggle: func.isRequired,
  notificationsOpen: bool
};

const Notifications = observer(props => {
  const { toggle, notificationsOpen } = props;
  const { notificationsStore, countryCode } = useStore();

  const notificationList = notificationsStore.list.slice();
  const notificationListCount = notificationsStore.totalCount;
  const { currentUser } = useUserSelectors();

  const handleClickReadAll = () => {
    notificationsStore.bulkUpdate();
    toggle();
  };

  const deleteNotification = id => {
    notificationsStore.delete(id);
  };

  const loadMorePage = () => {
    const nextPage = notificationsStore.filters.page + 1;
    notificationsStore.load({ userId: +currentUser.id, page: nextPage });
    notificationsStore.setFilters({ page: nextPage });
  };

  return (
    <Popover
      type='button'
      trigger='legacy'
      placement='bottom-end'
      target={props.target}
      className='notifications-box'
      fade={false}
      isOpen={notificationsOpen}
      toggle={toggle}
    >
      <PopoverHeader className='p-0'>
        <div className='d-flex align-items-center notifications-title'>
          Notifications &nbsp;
          {notificationListCount > 0 && (
            <span className='d-flex align-items-center justify-content-center notifications-count'>
              {notificationListCount}
            </span>
          )}
          <div className='close' onClick={handleClickReadAll}>
            <img src={CloseDark} alt='Close' />
          </div>
        </div>
      </PopoverHeader>

      <PopoverBody className='p-0'>
        <div className='notifications-list'>
          {notificationListCount > 0 ? (
            <InfiniteScroll
              dataLength={notificationList.length}
              next={loadMorePage}
              height={180}
              scrollThreshold={0.9}
              hasMore
            >
              <NotificationsList
                countryCode={countryCode}
                toggle={toggle}
                list={notificationList}
                deleteNotification={deleteNotification}
              />
            </InfiniteScroll>
          ) : (
            <div className='empty-list'>You have no reminders</div>
          )}
        </div>
      </PopoverBody>
    </Popover>
  );
});

Notifications.propTypes = propTypes;

export { Notifications };
