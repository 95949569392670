import React, { Fragment, useState } from 'react';
import _ from 'lodash-es';
import cx from 'classnames';
import moment from 'moment';

import { useCalendarContext } from '@packs/components/Calendar/CalendarContext';
import { WeekRow } from '@packs/components/Calendar/yearGridCustomPlugin/WeekRow';
import DropDownArrow from '@packs/images/svg/dropdown-arrow-gray.svg';
import { MONTH_FORMAT } from '@packs/screens/PersonalizeResults/formHooks/defaults';

import { Collapse } from 'reactstrap';

export const MonthMobile = ({
  date,
  dateStr,
  eventsData,
  parentContext,
  openPopover,
  cells,
  eventsMonthData,
  monthIndex,
  weekData
}) => {
  const [openCollapse, setOpenCollapse] = useState(false);

  const toggle = () => {
    setOpenCollapse(!openCollapse);
  };

  const MonthHeader = () => {
    const classes = { 6: 'fc-last', 0: 'fc-first' };
    const allclasses = 'fc-day-header fc-year-weekly-head fc-mon fc-widget-header';

    return (
      <table className='fc-year-month-header'>
        <thead>
          <tr className='fc-year-week-days'>
            {_.map(weekData, ({ date }, inx) => (
              <th key={inx} className={cx(allclasses, `fc-${date.getDay()}`, classes[inx])}>
                {moment(date).format('dd')[0]}
              </th>
            ))}
          </tr>
        </thead>
      </table>
    );
  };

  const monthEvents = eventsMonthData[`${date.getFullYear()}-${date.getMonth()}`] || [];

  const eventsDataFiltered = _.pickBy(eventsData, (value, key) => {
    return new Date(key).getMonth() === monthIndex;
  });

  return (
    <div className='fc-month-view fc-year-monthly-td fc-widget-content'>
      <div className='fc-year-monthly-name d-flex align-items-center justify-content-between' onClick={() => toggle()}>
        <div className='d-flex flex-column'>
          <span className='month-title mb-10'>{moment(date).format(MONTH_FORMAT)}</span>

          {/* <span>(Reminders {monthEvents.length})</span>*/}
        </div>
        <img src={DropDownArrow} alt='Arrow' />
      </div>

      <Collapse isOpen={openCollapse}>
        {openCollapse && (
          <Fragment>
            <div className='fc-row fc-widget-header'>
              <MonthHeader />
            </div>

            <div className='fc-scroller fc-day-grid-container'>
              <div className='fc-day-grid fc-body fc-unselectable'>
                {_.map(cells, (obj, inx) => (
                  <WeekRow
                    key={inx}
                    weekDays={obj}
                    openPopover={openPopover}
                    trigger='click'
                    eventsData={eventsDataFiltered}
                    {...{
                      inx,
                      monthIndex
                    }}
                  />
                ))}
              </div>
            </div>
          </Fragment>
        )}
      </Collapse>
    </div>
  );
};
