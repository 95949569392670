import React, { FC } from 'react';

import { Avatar as MUIAvatar } from '@mui/material';
import { defaultAvatar, defaultAvatarCover } from '@packs/lib/constants';
import { stringToColor } from '@shared/helpers/additionalHelpers';

type AvatarProps = {
  avatarUrl: string;
  size?: number;
  placeholder?: string;
  variant?: 'circular' | 'rounded' | 'square';
  withBG?: boolean;
};
export const Avatar: FC<AvatarProps> = ({ size = 34, avatarUrl, placeholder, variant = 'rounded', withBG }) => {
  const imgMissing = placeholder && (!avatarUrl || avatarUrl === defaultAvatar || avatarUrl === defaultAvatarCover);
  const placeholderFirstLetter = placeholder && placeholder[0] ? placeholder[0] : 'U';

  return (
    <MUIAvatar
      variant={variant}
      src={avatarUrl}
      sx={{
        width: size,
        height: size,
        minWidth: size,
        backgroundColor: withBG ? stringToColor(placeholder) : 'transparent'
      }}
    >
      {imgMissing && placeholderFirstLetter}
    </MUIAvatar>
  );
};
