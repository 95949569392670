import React from 'react';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Box from '@mui/material/Box';
import { FiltersDrawer } from '@packs/components/MaterialUi/FiltersDrawer/FiltersDrawer';
import { useStore } from '@packs/stores';
import useStyles from '@packs/styles/Leaderboard/topBarStyles';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { observer } from 'mobx-react';

export const MyQATopBar = observer(({ questionsFilter, onResetFilters, disabledRemove }) => {
  const { isDesktop } = useWindowSize();
  const { questionsStore } = useStore();
  const styles = useStyles();

  return (
    <Box sx={styles.wrapper}>
      {!isDesktop && (
        <Box sx={styles.searchFilterWrapper}>
          <Box />
          <Box sx={styles.actionSectionWrapper}>
            <Box sx={styles.actionSection}>
              <FiltersDrawer
                buttonProps={{
                  variant: 'filledTonal',
                  color: 'primary',
                  startIcon: <FilterAltIcon />,
                  sx: { width: { xs: '100%', lg: 'auto' } }
                }}
                handleResetFilters={onResetFilters}
                disabledResetFilters={disabledRemove}
                showResultText={`Show ${questionsStore?.questionsTotalCount} messages`}
              >
                {questionsFilter()}
              </FiltersDrawer>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
});
