import React from 'react';

export const BroadbandBroadbandPhoneTv = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <path d='M2 5.88889C2 4.84528 2.81364 4 3.81818 4H20.1818C21.1864 4 22 4.84528 22 5.88889V14H20.1818V6.83333C20.1818 6.312 19.7745 5.88889 19.2727 5.88889H4.72727C4.22545 5.88889 3.81818 6.312 3.81818 6.83333V13H2V5.88889Z' />
      <path d='M18 17.2222V19.1111H15.6364V20.0556C15.6364 20.5769 15.2291 21 14.7273 21H9.27273C8.77091 21 8.36364 20.5769 8.36364 20.0556V19.1111H8V17.2222H18Z' />
      <path d='M8.16228 11.2225C8.35912 11.4248 8.67176 11.4446 8.88404 11.2621C10.6865 9.73884 13.3034 9.73884 15.1098 11.2582C15.3259 11.4407 15.6424 11.4248 15.8393 11.2225C16.067 10.9884 16.0515 10.5997 15.8007 10.3894C13.5968 8.53686 10.4086 8.53686 8.20087 10.3894C7.94999 10.5957 7.93069 10.9845 8.16228 11.2225ZM11.1574 14.3008L11.7248 14.884C11.8753 15.0387 12.1185 15.0387 12.269 14.884L12.8364 14.3008C13.0178 14.1144 12.9792 13.7931 12.7476 13.6701C12.2767 13.4202 11.7132 13.4202 11.2385 13.6701C11.0185 13.7931 10.976 14.1144 11.1574 14.3008ZM9.71774 12.8212C9.90687 13.0155 10.2041 13.0354 10.4241 12.8727C11.3658 12.1864 12.6318 12.1864 13.5736 12.8727C13.7936 13.0314 14.0908 13.0155 14.2799 12.8212L14.2838 12.8172C14.5154 12.5792 14.4999 12.1745 14.2336 11.9802C12.9059 10.9924 11.0957 10.9924 9.76406 11.9802C9.49774 12.1785 9.4823 12.5792 9.71774 12.8212Z' />
      <path d='M6.07895 14H0.921053C0.412632 14 0 14.4667 0 15.0417V22.9583C0 23.5333 0.412632 24 0.921053 24H6.07895C6.58737 24 7 23.5333 7 22.9583V15.0417C7 14.4667 6.58737 14 6.07895 14ZM3.5 23.5833C3.19421 23.5833 2.94737 23.3042 2.94737 22.9583C2.94737 22.6125 3.19421 22.3333 3.5 22.3333C3.80579 22.3333 4.05263 22.6125 4.05263 22.9583C4.05263 23.3042 3.80579 23.5833 3.5 23.5833ZM6.26316 21.9167H0.736842V15.25H6.26316V21.9167Z' />
      <path d='M23.1071 15H19.8929C19.4 15 19 15.4073 19 15.9091V22.0909C19 22.5927 19.4 23 19.8929 23H23.1071C23.6 23 24 22.5927 24 22.0909V15.9091C24 15.4073 23.6 15 23.1071 15ZM21.5 22.3182C21.2779 22.3182 21.0982 22.1353 21.0982 21.9091C21.0982 21.6829 21.2779 21.5 21.5 21.5C21.7221 21.5 21.9018 21.6829 21.9018 21.9091C21.9018 22.1353 21.7221 22.3182 21.5 22.3182ZM22.9286 21.1818H20.0714C19.8743 21.1818 19.7143 21.0189 19.7143 20.8182V16.4545C19.7143 16.2538 19.8743 16.0909 20.0714 16.0909H22.9286C23.1257 16.0909 23.2857 16.2538 23.2857 16.4545V20.8182C23.2857 21.0189 23.1257 21.1818 22.9286 21.1818Z' />
    </svg>
  );
};
