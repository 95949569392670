import ArrowDownIcon from '@shared/images/offers-general-points/arrow_down.svg';
import ArrowUpIcon from '@shared/images/offers-general-points/arrow_up.svg';
import CalendarIcon from '@shared/images/offers-general-points/calendar.svg';
import CallCenterIcon from '@shared/images/offers-general-points/call_center.svg';
import CardsIcon from '@shared/images/offers-general-points/cards.svg';
import CheckMarkIcon from '@shared/images/offers-general-points/check_mark.svg';
import ClockIcon from '@shared/images/offers-general-points/clock.svg';
import CupIcon from '@shared/images/offers-general-points/cup.svg';
import DealIcon from '@shared/images/offers-general-points/deal.svg';
import FeesIcon from '@shared/images/offers-general-points/fees.svg';
import FriendsIcon from '@shared/images/offers-general-points/friends.svg';
import GroupIcon from '@shared/images/offers-general-points/group.svg';
import HouseIcon from '@shared/images/offers-general-points/house.svg';
import LikeIcon from '@shared/images/offers-general-points/like.svg';
import LocationIcon from '@shared/images/offers-general-points/location.svg';
import LockIcon from '@shared/images/offers-general-points/lock.svg';
import MoneyIcon from '@shared/images/offers-general-points/money.svg';
import PercentIcon from '@shared/images/offers-general-points/percent.svg';
import PersonIcon from '@shared/images/offers-general-points/person.svg';
import PhoneIcon from '@shared/images/offers-general-points/phone.svg';
import PlanetIcon from '@shared/images/offers-general-points/planet.svg';
import SavingIcon from '@shared/images/offers-general-points/saving.svg';

import { GeneralAttributes } from '../../crm/layouts/dashboards/my-offers/components/MyOffersCreateEdit/components/GeneralAttributes/GeneralAttributes';

const icons = {
  arrow_down: ArrowDownIcon,
  arrow_up: ArrowUpIcon,
  calendar: CalendarIcon,
  call_center: CallCenterIcon,
  cards: CardsIcon,
  check_mark: CheckMarkIcon,
  clock: ClockIcon,
  cup: CupIcon,
  deal: DealIcon,
  fees: FeesIcon,
  friends: FriendsIcon,
  group: GroupIcon,
  house: HouseIcon,
  like: LikeIcon,
  location: LocationIcon,
  lock: LockIcon,
  money: MoneyIcon,
  percent: PercentIcon,
  person: PersonIcon,
  phone: PhoneIcon,
  planet: PlanetIcon,
  saving: SavingIcon
};

export const generalIcon = name => {
  return icons[name] || '';
};

export const generalAttributesList = [
  {
    label: 'Location',
    value: 'location',
    icon: icons.location
  },
  {
    label: 'Check',
    value: 'check_mark',
    icon: icons.check_mark
  },
  {
    label: 'Money',
    value: 'money',
    icon: icons.money
  },
  {
    label: 'File-minus',
    value: 'fees',
    icon: icons.fees
  },
  {
    label: 'Hand-coins-currency',
    value: 'saving',
    icon: icons.saving
  },
  {
    label: 'Money-world',
    value: 'planet',
    icon: icons.planet
  },
  {
    label: 'Money-banknote-handshake',
    value: 'deal',
    icon: icons.deal
  },
  {
    label: 'Mobile-devices',
    value: 'phone',
    icon: icons.phone
  },
  {
    label: 'Percent',
    value: 'percent',
    icon: icons.percent
  },
  {
    label: 'Calendar',
    value: 'calendar',
    icon: icons.calendar
  },
  {
    label: 'Arrow-down',
    value: 'arrow_down',
    icon: icons.arrow_down
  },
  {
    label: 'Arrow-up',
    value: 'arrow_up',
    icon: icons.arrow_up
  },
  {
    label: 'Cup',
    value: 'cup',
    icon: icons.cup
  },
  {
    label: 'Like',
    value: 'like',
    icon: icons.like
  },
  {
    label: 'Lock',
    value: 'lock',
    icon: icons.lock
  },
  {
    label: 'Support',
    value: 'call_center',
    icon: icons.call_center
  },
  {
    label: 'Clock',
    value: 'clock',
    icon: icons.clock
  },
  {
    label: 'Credit-card',
    value: 'cards',
    icon: icons.cards
  },
  {
    label: 'Home',
    value: 'house',
    icon: icons.house
  },
  {
    label: 'One-user',
    value: 'person',
    icon: icons.person
  },
  {
    label: 'Three-users',
    value: 'group',
    icon: icons.group
  },
  {
    label: 'Two-users',
    value: 'friends',
    icon: icons.friends
  }
];
