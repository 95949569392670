import { createFeedback } from '@packs/graphql/queries/feedback';
import { graphQLRequest } from '@packs/lib/utils';

export const feedbackApi = {
  createFeedback: variables =>
    graphQLRequest({
      query: createFeedback,
      variables: { input: variables }
    })
};
