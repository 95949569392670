import React from 'react';

export const GeneralGifts = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path d='M9.00002 2C7.35501 2 6.00002 3.35499 6.00002 5C6.00002 5.35185 6.07397 5.68511 6.18752 6H4.12502C3.05902 6 2.08988 6.77784 2.00588 7.83984C1.94288 8.64584 2.36202 9.35066 3.00002 9.72266V19C3.00002 20.1 3.90002 21 5.00002 21H11V11C11 10.448 11.448 10 12 10C12.552 10 13 10.448 13 11V21H19C20.1 21 21 20.1 21 19V9.72266C21.638 9.35066 22.0572 8.64584 21.9942 7.83984C21.9102 6.77784 20.941 6 19.875 6H17.8125C17.9261 5.68511 18 5.35185 18 5C18 3.35499 16.645 2 15 2C14.2314 2 13.5334 2.30413 13 2.78711V2.76562L12.8828 2.88281L12 3.76562L11.1172 2.88281L11 2.76562V2.78711C10.4667 2.30413 9.76863 2 9.00002 2ZM9.00002 4C9.56415 4 10 4.43587 10 5C10 5.56413 9.56415 6 9.00002 6C8.43589 6 8.00002 5.56413 8.00002 5C8.00002 4.43587 8.43589 4 9.00002 4ZM15 4C15.5642 4 16 4.43587 16 5C16 5.56413 15.5642 6 15 6C14.4359 6 14 5.56413 14 5C14 4.43587 14.4359 4 15 4Z' />
      </g>
    </svg>
  );
};
