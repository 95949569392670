import React, { useEffect, useMemo, useRef, useState } from 'react';
import { size } from 'lodash-es';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { SignUpModalLink } from '@packs/components';
import { FieldWithErrors } from '@packs/components/FieldWithErrors';
import { SelectField } from '@packs/components/SelectField';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { usePath } from '@packs/layouts/constants';
import { runFormSubmit } from '@packs/lib/helpers';
import { decodeAssociationUrl, encodeAssociationUrl } from '@packs/lib/helpers/additionalHelpers';
import { useStore } from '@packs/stores/base';

import useStyles from './styles';

import { observer } from 'mobx-react';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';
import { Field } from 'redux-form';

const mapOptions = arr =>
  arr.map(ob => ({
    value: ob.id,
    label: ob.name,
    object: ob
  }));

export const AssociationMemberFormComponent = observer(
  ({ mode, change, validate, inProcess, handleSubmit, formAttributes, associationInUrl, toggle = () => {} }) => {
    const styles = useStyles();
    const formRef = useRef();
    const { currentUser } = useUserSelectors();
    const { associationStore, userStore, currentCountry } = useStore();
    const { toggleNewAssociationModal } = useModalsContext();
    const { ASSOCIATION_PATH } = usePath();
    const [errors, setErrors] = useState(false);
    const navigate = useNavigate();

    const resetErrors = () => {
      setErrors(null);
    };

    const metaError = name => {
      const mess = errors && errors[name];
      if (!mess) return null;
      return { meta: { touched: !!mess, error: mess } };
    };

    const isFormValid = () => {
      const errors = validate(formAttributes);

      if (Object.keys(errors).length > 0) {
        if (partneredAssociation || (!partneredAssociation && errors?.associationId)) {
          setErrors(errors);

          return false;
        }
      }
      return true;
    };

    const partneredAssociation = useMemo(() => {
      return formAttributes?.associationId
        ? associationStore.associations.find(item => item.id === formAttributes.associationId)?.partnered
        : false;
    }, [formAttributes?.associationId]);

    useEffect(() => {
      associationStore.setParam({ countryCode: currentCountry.isoA2Code, userId: +currentUser.id });
      associationStore.fetchAssociations();
    }, [currentCountry.isoA2Code, currentUser.id]);

    useEffect(() => {
      if (errors && size(errors)) {
        resetErrors();
      }
    }, [formAttributes]);

    useEffect(() => {
      if (currentUser.registerEvent !== 'my-associations') {
        userStore.setRegisterEvent('my-associations');
      }
    }, [currentUser]);

    useEffect(() => {
      if (formAttributes?.associationId && associationInUrl) {
        const association = mapOptions(associationStore.associations).find(
          item => item.value === formAttributes?.associationId
        );

        if (association) {
          const associationName = encodeAssociationUrl(association.label);

          navigate(ASSOCIATION_PATH.concat(`?associationName=${associationName}`));
        }
      }
    }, [formAttributes?.associationId]);

    useEffect(() => {
      const { associationName } = queryString.parse(location.search);
      if (associationName && associationStore?.associations.length) {
        const prefillAssociation = mapOptions(associationStore.associations).find(
          item => item.label.replaceAll('&', '').toLowerCase() === decodeAssociationUrl(associationName).toLowerCase()
        );

        prefillAssociation && change('associationId', prefillAssociation.value);
      }
    }, [associationStore?.associations]);

    const SignUpBtn = () => (
      <SignUpModalLink
        variant='contained'
        validate={isFormValid}
        onSignUpSuccess={() => {
          runFormSubmit(formRef.current);
        }}
      >
        Submit
      </SignUpModalLink>
    );

    return (
      <Box component='form' ref={formRef} onSubmit={handleSubmit} sx={styles.wrapper}>
        <Field
          name='associationId'
          className='select-ui theme no-scale white-bg'
          classNamePrefix='Select'
          options={mapOptions(associationStore.associations)}
          component={SelectField}
          placeholder='Search Association...'
          isSearchable
          {...metaError('associationId')}
        />
        {partneredAssociation && (
          <Field
            type='text'
            component={FieldWithErrors}
            name='code'
            placeholder='Code / membership number'
            className='with-border'
            {...metaError('code')}
          />
        )}
        <Box sx={{ textAlign: 'center' }}>
          <div className='fs-12 lh-5'>
            If your association or group is not in our directory please add it
            <span className='ml-5 fw-700 cursor-pointer td-underline' onClick={toggleNewAssociationModal}>
              here
            </span>
          </div>
        </Box>

        {mode === 'modal' ? (
          <div className='btn-container'>
            <Button type='button' variant='contained' onClick={toggle}>
              Cancel
            </Button>
            {currentUser.id ? (
              <Button type='submit' variant='contained' disabled={inProcess}>
                <span>Submit</span>
              </Button>
            ) : (
              <SignUpBtn className='btn empty black submit' />
            )}
          </div>
        ) : (
          <div className='btn-container d-flex align-items-center justify-content-center'>
            {currentUser.id ? (
              <Button type='submit' variant='contained' disabled={inProcess}>
                submit
              </Button>
            ) : (
              <SignUpBtn className='btn full white' />
            )}
          </div>
        )}
      </Box>
    );
  }
);
