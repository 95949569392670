import { toastr } from '@packs/lib/helpers';

export const setError = error => {
  let errorMessages = error;
  if (errorMessages.stack) {
    throw error;
  }

  if (error.map(error => typeof error).includes('object')) {
    errorMessages = error.map(error => (error || {}).message);
  }

  if (errorMessages.join) {
    toastr.error(errorMessages.join('; '));
  } else {
    // eslint-disable-next-line
    console.log(errorMessages);
  }
};
