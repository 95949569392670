import React from 'react';

import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

/* eslint-disable */
const mobiScroll = function (v, a, e) {
  'use strict';
  function o(e, t, s) {
    return (
      t in e ? Object.defineProperty(e, t, { value: s, enumerable: !0, configurable: !0, writable: !0 }) : (e[t] = s), e
    );
  }
  function g() {
    return (g =
      Object.assign ||
      function (e) {
        for (var t = 1; t < arguments.length; t++) {
          var s = arguments[t];
          for (var n in s) Object.prototype.hasOwnProperty.call(s, n) && (e[n] = s[n]);
        }
        return e;
      }).apply(this, arguments);
  }
  function l(e, t) {
    (e.prototype = Object.create(t.prototype)), ((e.prototype.constructor = e).__proto__ = t);
  }
  function y(e, t) {
    if (null == e) return {};
    var s,
      n,
      i = {},
      a = Object.keys(e);
    for (n = 0; n < a.length; n++) (s = a[n]), 0 <= t.indexOf(s) || (i[s] = e[s]);
    return i;
  }
  function b(e) {
    if (void 0 === e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    return e;
  }
  (v = v && v.hasOwnProperty('default') ? v.default : v),
    (a = a && a.hasOwnProperty('default') ? a.default : a),
    (e = e && e.hasOwnProperty('default') ? e.default : e);
  var t,
    s,
    n,
    i,
    se,
    r,
    de,
    c,
    p,
    u,
    d,
    he,
    h,
    ne = ne || {},
    m = {},
    f = [],
    _ = 'undefined' != typeof window,
    w = _ && window.matchMedia && window.matchMedia('(prefers-color-scheme:dark)').matches,
    C = _ ? navigator.userAgent : '',
    x = _ ? navigator.platform : '',
    T = _ ? navigator.maxTouchPoints : 0,
    S = /Safari/.test(C),
    k = C.match(/Android|iPhone|iPad|iPod|Windows Phone|Windows|MSIE/i),
    me =
      (_ && window.requestAnimationFrame) ||
      function (e) {
        return setTimeout(e, 20);
      },
    fe =
      (_ && window.cancelAnimationFrame) ||
      function (e) {
        clearTimeout(e);
      };
  function M(e) {
    var t;
    for (t in e) if (void 0 !== r[e[t]]) return !0;
    return !1;
  }
  function be(e, t) {
    if ('touchstart' == e.type) t.__mbscTouched = 1;
    else if (t.__mbscTouched) return delete t.__mbscTouched, !1;
    return !0;
  }
  function ve(e, t) {
    for (var s, n, i = ['t', 'webkitT', 'MozT', 'OT', 'msT'], a = getComputedStyle(e[0]), o = 0; !s && o < i.length; )
      void 0 !== a[(n = i[o]) + 'ransform'] && (s = a[n + 'ransform']), o++;
    return (s = s.split(')')[0].split(', ')), t ? s[13] || s[5] : s[12] || s[4];
  }
  function ge(e, t, s, n) {
    e && e.addEventListener(t, s, n);
  }
  function ye(e, t, s, n) {
    e && e.removeEventListener(t, s, n);
  }
  function ie(e, t) {
    return (
      !(!t || !e || 1 !== e.nodeType) &&
      (e.matches || e.matchesSelector || e.webkitMatchesSelector || e.mozMatchesSelector || e.msMatchesSelector).call(
        e,
        t
      )
    );
  }
  function O(e, t, s) {
    var n;
    try {
      n = new CustomEvent(t, { detail: s, bubbles: !0, cancelable: !0 });
    } catch (e) {
      (n = document.createEvent('Event')).initEvent(t, !0, !0), (n.detail = s);
    }
    e.dispatchEvent(n);
  }
  /Android/i.test(k)
    ? ((t = 'android'), (s = C.match(/Android\s+([\d.]+)/i)) && (f = s[0].replace('Android ', '').split('.')))
    : /iPhone|iPad|iPod/i.test(k) || /iPhone|iPad|iPod/i.test(x) || ('MacIntel' === x && 1 < T)
    ? ((t = 'ios'), (s = C.match(/OS\s+([\d_]+)/i)) && (f = s[0].replace(/_/g, '.').replace('OS ', '').split('.')))
    : /Windows Phone/i.test(k)
    ? (t = 'wp')
    : /Windows|MSIE/i.test(k) && (t = 'windows'),
    (n = f[0]),
    (i = f[1]),
    _ &&
      ((h = window),
      document.createElement('canvas'),
      (r = document.createElement('modernizr').style),
      (de = (function () {
        var e,
          t = ['Webkit', 'Moz', 'O', 'ms'];
        for (e in t) if (M([t[e] + 'Transform'])) return '-' + t[e].toLowerCase() + '-';
        return '';
      })()),
      (he = de.replace(/^-/, '').replace(/-$/, '').replace('moz', 'Moz')),
      (se = void 0 !== r.animation ? 'animationend' : 'webkitAnimationEnd'),
      (p = void 0 !== r.transition),
      (d = (u = 'ios' === t && !S) && h.webkit && h.webkit.messageHandlers),
      (c = void 0 === r.touchAction || (u && !d)));
  var E = { 'column-count': 1, columns: 1, 'font-weight': 1, 'line-height': 1, opacity: 1, 'z-index': 1, zoom: 1 },
    N = { readonly: 'readOnly' },
    V = [],
    D = Array.prototype.slice;
  function P(e) {
    return 'function' == typeof e;
  }
  function I(e) {
    return 'object' == typeof e;
  }
  function $(e) {
    return 'number' == typeof e.length;
  }
  function L(e) {
    return e.replace(/-+(.)?/g, function (e, t) {
      return t ? t.toUpperCase() : '';
    });
  }
  function A(e) {
    return e
      .replace(/::/g, '/')
      .replace(/([A-Z]+)([A-Z][a-z])/g, '$1_$2')
      .replace(/([a-z\d])([A-Z])/g, '$1_$2')
      .replace(/_/g, '-')
      .toLowerCase();
  }
  function U(e, t) {
    return 'number' != typeof t || E[A(e)] ? t : t + 'px';
  }
  var F,
    W =
      ((F = function e(t, s) {
        var n,
          i,
          a,
          o = [],
          r = 0;
        if (t && !s && t instanceof R) return t;
        if (P(t)) return e(document).ready(t);
        if (t)
          if ('string' == typeof t)
            if (((t = a = t.trim()), 0 <= a.indexOf('<') && 0 <= a.indexOf('>'))) {
              var l = 'div';
              for (
                0 === a.indexOf('<li') && (l = 'ul'),
                  0 === a.indexOf('<tr') && (l = 'tbody'),
                  (0 !== a.indexOf('<td') && 0 !== a.indexOf('<th')) || (l = 'tr'),
                  0 === a.indexOf('<tbody') && (l = 'table'),
                  0 === a.indexOf('<option') && (l = 'select'),
                  (i = document.createElement(l)).innerHTML = a,
                  r = 0;
                r < i.childNodes.length;
                r++
              )
                o.push(i.childNodes[r]);
            } else
              for (
                n =
                  s || '#' !== t[0] || t.match(/[ .<>:~]/)
                    ? (s instanceof R && (s = s[0]), (s || document).querySelectorAll(t))
                    : [document.getElementById(t.split('#')[1])],
                  r = 0;
                r < n.length;
                r++
              )
                n[r] && o.push(n[r]);
          else if (t.nodeType || t === window || t === document) o.push(t);
          else if (0 < t.length && t[0].nodeType) for (r = 0; r < t.length; r++) o.push(t[r]);
          else e.isArray(t) && (o = t);
        return new R(o);
      }),
      (R.prototype = {
        ready: function (e) {
          return (
            (document.attachEvent ? 'complete' == document.readyState : 'loading' != document.readyState)
              ? e(F)
              : document.addEventListener(
                  'DOMContentLoaded',
                  function () {
                    e(F);
                  },
                  !1
                ),
            this
          );
        },
        concat: V.concat,
        empty: function () {
          return this.each(function () {
            this.innerHTML = '';
          });
        },
        map: function (s) {
          return F(
            F.map(this, function (e, t) {
              return s.call(e, t, e);
            })
          );
        },
        slice: function () {
          return F(D.apply(this, arguments));
        },
        addClass: function (e) {
          if (void 0 === e) return this;
          for (var t = e.split(' '), s = 0; s < t.length; s++)
            for (var n = 0; n < this.length; n++)
              void 0 !== this[n].classList && '' !== t[s] && this[n].classList.add(t[s]);
          return this;
        },
        removeClass: function (e) {
          if (void 0 === e) return this;
          for (var t = e.split(' '), s = 0; s < t.length; s++)
            for (var n = 0; n < this.length; n++)
              void 0 !== this[n].classList && '' !== t[s] && this[n].classList.remove(t[s]);
          return this;
        },
        hasClass: function (e) {
          return !!this[0] && this[0].classList.contains(e);
        },
        toggleClass: function (e) {
          for (var t = e.split(' '), s = 0; s < t.length; s++)
            for (var n = 0; n < this.length; n++) void 0 !== this[n].classList && this[n].classList.toggle(t[s]);
          return this;
        },
        closest: function (e, t) {
          var s = this[0],
            n = !1;
          for (I(e) && (n = F(e)); s && !(n ? 0 <= n.indexOf(s) : ie(s, e)); )
            s = s !== t && s.nodeType !== s.DOCUMENT_NODE && s.parentNode;
          return F(s);
        },
        attr: function (e, t) {
          var s;
          if (1 !== arguments.length || 'string' != typeof e) {
            for (var n = 0; n < this.length; n++)
              if (2 === arguments.length) this[n].setAttribute(e, t);
              else for (var i in e) (this[n][i] = e[i]), this[n].setAttribute(i, e[i]);
            return this;
          }
          if (this.length) return (s = this[0].getAttribute(e)), s || '' === s ? s : void 0;
        },
        removeAttr: function (e) {
          for (var t = 0; t < this.length; t++) this[t].removeAttribute(e);
          return this;
        },
        prop: function (e, t) {
          if (((e = N[e] || e), 1 === arguments.length && 'string' == typeof e)) return this[0] ? this[0][e] : void 0;
          for (var s = 0; s < this.length; s++) this[s][e] = t;
          return this;
        },
        val: function (e) {
          if (void 0 === e)
            return this.length && this[0].multiple
              ? F.map(this.find('option:checked'), function (e) {
                  return e.value;
                })
              : this[0]
              ? this[0].value
              : void 0;
          if (this.length && this[0].multiple)
            F.each(this[0].options, function () {
              this.selected = -1 != e.indexOf(this.value);
            });
          else for (var t = 0; t < this.length; t++) this[t].value = e;
          return this;
        },
        on: function (e, s, n, t) {
          var i,
            a,
            o,
            r,
            l,
            c = e.split(' ');
          function p(e) {
            for (var t = e.target; t; ) F(t).is(s) && n.call(t, e), (t = t !== this ? t.parentNode : null);
          }
          function u(e, t, s, n) {
            var i = t.split('.');
            e.DomNameSpaces || (e.DomNameSpaces = []),
              e.DomNameSpaces.push({ namespace: i[1], event: i[0], listener: s, capture: n }),
              e.addEventListener(i[0], s, n);
          }
          for (r = 0; r < this.length; r++)
            if (((a = this[r]), P(s) || !1 === s))
              for (P(s) && ((t = n || !1), (n = s)), l = 0; l < c.length; l++)
                -1 != (o = c[l]).indexOf('.') ? u(a, o, n, t) : a.addEventListener(o, n, t);
            else
              for (i = p.bind(a), l = 0; l < c.length; l++)
                (o = c[l]),
                  a.DomLiveListeners || (a.DomLiveListeners = []),
                  a.DomLiveListeners.push({ listener: n, liveListener: i }),
                  -1 != o.indexOf('.') ? u(a, o, i, t) : a.addEventListener(o, i, t);
          return this;
        },
        off: function (e, t, s, n) {
          var i,
            a,
            o,
            r,
            l,
            c,
            p,
            u = this;
          function d(e) {
            var t,
              s,
              n,
              i,
              a,
              o = e.split('.'),
              r = o[0],
              l = o[1];
            for (s = 0; s < u.length; ++s)
              if ((a = (t = u[s]).DomNameSpaces)) {
                for (n = 0; n < a.length; ++n)
                  (i = a[n]).namespace != l ||
                    (i.event != r && r) ||
                    (t.removeEventListener(i.event, i.listener, i.capture), (i.removed = !0));
                for (n = a.length - 1; 0 <= n; --n) a[n].removed && a.splice(n, 1);
              }
          }
          for (o = e.split(' '), r = 0; r < o.length; r++)
            for (a = o[r], l = 0; l < this.length; l++)
              if (((p = (i = this[l]).DomLiveListeners), P(t) || !1 === t))
                P(t) && ((n = s || !1), (s = t)),
                  0 === a.indexOf('.') ? d(a.substr(1)) : i.removeEventListener(a, s, n);
              else {
                if (p)
                  for (c = 0; c < p.length; c++) p[c].listener === s && i.removeEventListener(a, p[c].liveListener, n);
                i.DomNameSpaces && i.DomNameSpaces.length && a && d(a);
              }
          return this;
        },
        trigger: function (e, t) {
          for (var s = e.split(' '), n = 0; n < s.length; n++)
            for (var i = 0; i < this.length; i++) O(this[i], s[n], t);
          return this;
        },
        width: function (e) {
          return void 0 !== e
            ? this.css('width', e)
            : this[0] === window
            ? window.innerWidth
            : this[0] === document
            ? document.documentElement.scrollWidth
            : 0 < this.length
            ? parseFloat(this.css('width'))
            : null;
        },
        height: function (e) {
          if (void 0 !== e) return this.css('height', e);
          if (this[0] === window) return window.innerHeight;
          if (this[0] !== document) return 0 < this.length ? parseFloat(this.css('height')) : null;
          var t = document.body,
            s = document.documentElement;
          return Math.max(t.scrollHeight, t.offsetHeight, s.clientHeight, s.scrollHeight, s.offsetHeight);
        },
        innerWidth: function () {
          var t = this;
          if (0 < this.length) {
            if (this[0].innerWidth) return this[0].innerWidth;
            var s = this[0].offsetWidth;
            return (
              ['left', 'right'].forEach(function (e) {
                s -= parseInt(t.css(L('border-' + e + '-width')) || 0, 10);
              }),
              s
            );
          }
        },
        innerHeight: function () {
          var t = this;
          if (0 < this.length) {
            if (this[0].innerHeight) return this[0].innerHeight;
            var s = this[0].offsetHeight;
            return (
              ['top', 'bottom'].forEach(function (e) {
                s -= parseInt(t.css(L('border-' + e + '-width')) || 0, 10);
              }),
              s
            );
          }
        },
        offset: function () {
          if (0 < this.length) {
            var e = this[0].getBoundingClientRect(),
              t = document.documentElement;
            return { top: e.top + window.pageYOffset - t.clientTop, left: e.left + window.pageXOffset - t.clientLeft };
          }
        },
        hide: function () {
          for (var e = 0; e < this.length; e++) this[e].style.display = 'none';
          return this;
        },
        show: function () {
          for (var e = 0; e < this.length; e++)
            'none' == this[e].style.display && (this[e].style.display = ''),
              'none' == getComputedStyle(this[e], '').getPropertyValue('display') && (this[e].style.display = 'block');
          return this;
        },
        clone: function () {
          return this.map(function () {
            return this.cloneNode(!0);
          });
        },
        styles: function () {
          return this[0] ? window.getComputedStyle(this[0], null) : void 0;
        },
        css: function (e, t) {
          var s,
            n,
            i = this[0],
            a = '';
          if (arguments.length < 2) {
            if (!i) return;
            if ('string' == typeof e) return i.style[e] || getComputedStyle(i, '').getPropertyValue(e);
          }
          if ('string' == typeof e)
            t || 0 === t
              ? (a = A(e) + ':' + U(e, t))
              : this.each(function () {
                  this.style.removeProperty(A(e));
                });
          else
            for (n in e)
              if (e[n] || 0 === e[n]) a += A(n) + ':' + U(n, e[n]) + ';';
              else for (s = 0; s < this.length; s++) this[s].style.removeProperty(A(n));
          return this.each(function () {
            this.style.cssText += ';' + a;
          });
        },
        each: function (e) {
          for (var t = 0; t < this.length && !1 !== e.apply(this[t], [t, this[t]]); t++);
          return this;
        },
        filter: function (e) {
          for (var t = [], s = 0; s < this.length; s++)
            P(e) ? e.call(this[s], s, this[s]) && t.push(this[s]) : ie(this[s], e) && t.push(this[s]);
          return new R(t);
        },
        html: function (e) {
          if (void 0 === e) return this[0] ? this[0].innerHTML : void 0;
          this.empty();
          for (var t = 0; t < this.length; t++) this[t].innerHTML = e;
          return this;
        },
        text: function (e) {
          if (void 0 === e) return this[0] ? this[0].textContent.trim() : null;
          for (var t = 0; t < this.length; t++) this[t].textContent = e;
          return this;
        },
        is: function (e) {
          return 0 < this.length && ie(this[0], e);
        },
        not: function (t) {
          var s = [];
          if (P(t) && void 0 !== t.call)
            this.each(function (e) {
              t.call(this, e) || s.push(this);
            });
          else {
            var n = 'string' == typeof t ? this.filter(t) : $(t) && P(t.item) ? D.call(t) : F(t);
            I(n) &&
              (n = F.map(n, function (e) {
                return e;
              })),
              this.each(function (e, t) {
                n.indexOf(t) < 0 && s.push(t);
              });
          }
          return F(s);
        },
        indexOf: function (e) {
          for (var t = 0; t < this.length; t++) if (this[t] === e) return t;
        },
        index: function (e) {
          return e ? this.indexOf(F(e)[0]) : this.parent().children().indexOf(this[0]);
        },
        get: function (e) {
          return void 0 === e ? D.call(this) : this[0 <= e ? e : e + this.length];
        },
        eq: function (e) {
          if (void 0 === e) return this;
          var t,
            s = this.length;
          return new R(s - 1 < e ? [] : e < 0 ? ((t = s + e) < 0 ? [] : [this[t]]) : [this[e]]);
        },
        append: function (e) {
          var t, s;
          for (t = 0; t < this.length; t++)
            if ('string' == typeof e) {
              var n = document.createElement('div');
              for (n.innerHTML = e; n.firstChild; ) this[t].appendChild(n.firstChild);
            } else if (e instanceof R) for (s = 0; s < e.length; s++) this[t].appendChild(e[s]);
            else this[t].appendChild(e);
          return this;
        },
        appendTo: function (e) {
          return F(e).append(this), this;
        },
        prepend: function (e) {
          var t, s;
          for (t = 0; t < this.length; t++)
            if ('string' == typeof e) {
              var n = document.createElement('div');
              for (n.innerHTML = e, s = n.childNodes.length - 1; 0 <= s; s--)
                this[t].insertBefore(n.childNodes[s], this[t].childNodes[0]);
            } else if (e instanceof R) for (s = 0; s < e.length; s++) this[t].insertBefore(e[s], this[t].childNodes[0]);
            else this[t].insertBefore(e, this[t].childNodes[0]);
          return this;
        },
        prependTo: function (e) {
          return F(e).prepend(this), this;
        },
        insertBefore: function (e) {
          for (var t = F(e), s = 0; s < this.length; s++)
            if (1 === t.length) t[0].parentNode.insertBefore(this[s], t[0]);
            else if (1 < t.length)
              for (var n = 0; n < t.length; n++) t[n].parentNode.insertBefore(this[s].cloneNode(!0), t[n]);
          return this;
        },
        insertAfter: function (e) {
          for (var t = F(e), s = 0; s < this.length; s++)
            if (1 === t.length) t[0].parentNode.insertBefore(this[s], t[0].nextSibling);
            else if (1 < t.length)
              for (var n = 0; n < t.length; n++) t[n].parentNode.insertBefore(this[s].cloneNode(!0), t[n].nextSibling);
          return this;
        },
        next: function (e) {
          return 0 < this.length
            ? e
              ? this[0].nextElementSibling && F(this[0].nextElementSibling).is(e)
                ? new R([this[0].nextElementSibling])
                : new R([])
              : this[0].nextElementSibling
              ? new R([this[0].nextElementSibling])
              : new R([])
            : new R([]);
        },
        nextAll: function (e) {
          var t = [],
            s = this[0];
          if (!s) return new R([]);
          for (; s.nextElementSibling; ) {
            var n = s.nextElementSibling;
            e ? F(n).is(e) && t.push(n) : t.push(n), (s = n);
          }
          return new R(t);
        },
        prev: function (e) {
          return 0 < this.length
            ? e
              ? this[0].previousElementSibling && F(this[0].previousElementSibling).is(e)
                ? new R([this[0].previousElementSibling])
                : new R([])
              : this[0].previousElementSibling
              ? new R([this[0].previousElementSibling])
              : new R([])
            : new R([]);
        },
        prevAll: function (e) {
          var t = [],
            s = this[0];
          if (!s) return new R([]);
          for (; s.previousElementSibling; ) {
            var n = s.previousElementSibling;
            e ? F(n).is(e) && t.push(n) : t.push(n), (s = n);
          }
          return new R(t);
        },
        parent: function (e) {
          for (var t = [], s = 0; s < this.length; s++)
            null !== this[s].parentNode &&
              (e ? F(this[s].parentNode).is(e) && t.push(this[s].parentNode) : t.push(this[s].parentNode));
          return F(F.unique(t));
        },
        parents: function (e) {
          for (var t = [], s = 0; s < this.length; s++)
            for (var n = this[s].parentNode; n; ) e ? F(n).is(e) && t.push(n) : t.push(n), (n = n.parentNode);
          return F(F.unique(t));
        },
        find: function (e) {
          for (var t = [], s = 0; s < this.length; s++)
            for (var n = this[s].querySelectorAll(e), i = 0; i < n.length; i++) t.push(n[i]);
          return new R(t);
        },
        children: function (e) {
          for (var t = [], s = 0; s < this.length; s++)
            for (var n = this[s].childNodes, i = 0; i < n.length; i++)
              e ? 1 === n[i].nodeType && F(n[i]).is(e) && t.push(n[i]) : 1 === n[i].nodeType && t.push(n[i]);
          return new R(F.unique(t));
        },
        remove: function () {
          for (var e = 0; e < this.length; e++) this[e].parentNode && this[e].parentNode.removeChild(this[e]);
          return this;
        },
        add: function () {
          var e, t;
          for (e = 0; e < arguments.length; e++) {
            var s = F(arguments[e]);
            for (t = 0; t < s.length; t++) (this[this.length] = s[t]), this.length++;
          }
          return this;
        },
        before: function (e) {
          return F(e).insertBefore(this), this;
        },
        after: function (e) {
          return F(e).insertAfter(this), this;
        },
        scrollTop: function (e) {
          if (this.length) {
            var t = 'scrollTop' in this[0];
            return void 0 === e
              ? t
                ? this[0].scrollTop
                : this[0].pageYOffset
              : this.each(
                  t
                    ? function () {
                        this.scrollTop = e;
                      }
                    : function () {
                        this.scrollTo(this.scrollX, e);
                      }
                );
          }
        },
        scrollLeft: function (e) {
          if (this.length) {
            var t = 'scrollLeft' in this[0];
            return void 0 === e
              ? t
                ? this[0].scrollLeft
                : this[0].pageXOffset
              : this.each(
                  t
                    ? function () {
                        this.scrollLeft = e;
                      }
                    : function () {
                        this.scrollTo(e, this.scrollY);
                      }
                );
          }
        },
        contents: function () {
          return this.map(function (e, t) {
            return D.call(t.childNodes);
          });
        },
        nextUntil: function (e) {
          for (var t = this, s = []; t.length && !t.filter(e).length; ) s.push(t[0]), (t = t.next());
          return F(s);
        },
        prevUntil: function (e) {
          for (var t = this, s = []; t.length && !F(t).filter(e).length; ) s.push(t[0]), (t = t.prev());
          return F(s);
        },
        detach: function () {
          return this.remove();
        }
      }),
      (F.fn = R.prototype),
      F);
  function R(e) {
    var t = 0;
    for (t = 0; t < e.length; t++) this[t] = e[t];
    return (this.length = e.length), F(this);
  }
  var H = W;
  function ae() {}
  function te(e) {
    var t,
      s = [];
    for (t in e) s.push(e[t]);
    return s;
  }
  function oe(e) {
    var t,
      s = {};
    if (e) for (t = 0; t < e.length; t++) s[e[t]] = e[t];
    return s;
  }
  function _e(e) {
    return 0 <= e - parseFloat(e);
  }
  function we(e) {
    return 'string' == typeof e;
  }
  function Ce(e, t, s) {
    return Math.max(t, Math.min(e, s));
  }
  function Z(e, t, s) {
    return (100 * (e - t)) / (s - t);
  }
  function z(e, t, s) {
    var n = s.attr(e);
    return void 0 === n || '' === n ? t : 'true' === n;
  }
  (ne.$ = W),
    (H.inArray = function (e, t, s) {
      return V.indexOf.call(t, e, s);
    }),
    (H.extend = function (t) {
      var s,
        e = D.call(arguments, 1);
      return (
        'boolean' == typeof t && ((s = t), (t = e.shift())),
        (t = t || {}),
        e.forEach(function (e) {
          !(function e(t, s, n) {
            for (var i in s)
              n && (H.isPlainObject(s[i]) || H.isArray(s[i]))
                ? (((H.isPlainObject(s[i]) && !H.isPlainObject(t[i])) || (H.isArray(s[i]) && !H.isArray(t[i]))) &&
                    (t[i] = {}),
                  e(t[i], s[i], n))
                : void 0 !== s[i] && (t[i] = s[i]);
          })(t, e, s);
        }),
        t
      );
    }),
    (H.isFunction = P),
    (H.isArray = function (e) {
      return '[object Array]' === Object.prototype.toString.apply(e);
    }),
    (H.isPlainObject = function (e) {
      return I(e) && null !== e && e !== e.window && Object.getPrototypeOf(e) == Object.prototype;
    }),
    (H.each = function (e, t) {
      var s, n;
      if (I(e) && t) {
        if (H.isArray(e) || e instanceof W) for (s = 0; s < e.length && !1 !== t.call(e[s], s, e[s]); s++);
        else for (n in e) if (e.hasOwnProperty(n) && 'length' !== n && !1 === t.call(e[n], n, e[n])) break;
        return this;
      }
    }),
    (H.unique = function (e) {
      for (var t = [], s = 0; s < e.length; s++) -1 === t.indexOf(e[s]) && t.push(e[s]);
      return t;
    }),
    (H.map = function (e, t) {
      var s,
        n,
        i,
        a = [];
      if ($(e)) for (n = 0; n < e.length; n++) null !== (s = t(e[n], n)) && a.push(s);
      else for (i in e) null !== (s = t(e[i], i)) && a.push(s);
      return 0 < a.length ? H.fn.concat.apply([], a) : a;
    });
  var Y,
    X = 0;
  function re() {
    X++,
      setTimeout(function () {
        X--;
      }, 500);
  }
  function j(e, t) {
    if (!t.mbscClick) {
      var s = (e.originalEvent || e).changedTouches[0],
        n = document.createEvent('MouseEvents');
      n.initMouseEvent('click', !0, !0, window, 1, s.screenX, s.screenY, s.clientX, s.clientY, !1, !1, !1, !1, 0, null),
        (n.isMbscTap = !0),
        (n.isIonicTap = !0),
        (Y = !0),
        (t.mbscChange = !0),
        (t.mbscClick = !0),
        t.dispatchEvent(n),
        (Y = !1),
        re(),
        setTimeout(function () {
          delete t.mbscClick;
        });
    }
  }
  function xe(e, t, s) {
    var n = e.originalEvent || e,
      i = (s ? 'page' : 'client') + t;
    return n.targetTouches && n.targetTouches[0]
      ? n.targetTouches[0][i]
      : n.changedTouches && n.changedTouches[0]
      ? n.changedTouches[0][i]
      : e[i];
  }
  function q(e) {
    var t = ['switch', 'range', 'rating', 'segmented', 'stepper'],
      s = e[0],
      n = e.attr('data-role'),
      i = e.attr('type') || s.nodeName.toLowerCase();
    if (/(switch|range|rating|segmented|stepper|select)/.test(n)) i = n;
    else for (var a = 0; a < t.length; a++) e.is('[mbsc-' + t[a] + ']') && (i = t[a]);
    return i;
  }
  function B(s, e, t, n, i, a) {
    var o,
      r,
      l,
      c,
      p,
      u = (0, ne.$)(e);
    function d(e) {
      l || ((l = this), (o = xe(e, 'X')), (r = xe(e, 'Y')), (c = !1), (p = new Date()));
    }
    function h(e) {
      l && !c && (Math.abs(xe(e, 'X') - o) > i || Math.abs(xe(e, 'Y') - r) > i) && (c = !0);
    }
    function m(e) {
      l && ((a && new Date() - p < 100) || !c ? j(e, e.target) : re(), (l = !1));
    }
    function f(e) {
      n && e.preventDefault(), t.call(this, e, s);
    }
    function b() {
      l = !1;
    }
    (i = i || 9),
      u.each(function (e, t) {
        s.settings.tap &&
          (ge(t, 'touchstart', d, { passive: !0 }),
          ge(t, 'touchcancel', b),
          ge(t, 'touchmove', h, { passive: !0 }),
          ge(t, 'touchend', m)),
          ge(t, 'click', f),
          (t.__mbscOff = function () {
            ye(t, 'touchstart', d, { passive: !0 }),
              ye(t, 'touchcancel', b),
              ye(t, 'touchmove', h, { passive: !0 }),
              ye(t, 'touchend', m),
              ye(t, 'click', f),
              delete t.__mbscOff;
          });
      });
  }
  function le(e) {
    e && e[0] && e[0].__mbscOff && e[0].__mbscOff();
  }
  function G(e) {
    if (X && !Y && !e.isMbscTap && ('TEXTAREA' != e.target.nodeName || 'mousedown' != e.type))
      return e.stopPropagation(), e.preventDefault(), !1;
  }
  function K(e) {
    var t = e.theme,
      s = e.themeVariant;
    return (
      ('auto' != t && t) || (t = Q.autoTheme),
      'default' == t && (t = 'mobiscroll'),
      ('dark' === s || (w && 'auto' === s)) && Q.themes.form[t + '-dark']
        ? (t += '-dark')
        : 'light' === s && /.+-dark$/.test(t) && (t = t.replace(/-dark$/, '')),
      t
    );
  }
  function J(s, n, e) {
    _ &&
      Te(function () {
        Te(s).each(function () {
          new n(this, {});
        }),
          Te(document).on('mbsc-enhance', function (e, t) {
            Te(e.target).is(s)
              ? new n(e.target, t || {})
              : Te(s, e.target).each(function () {
                  new n(this, t || {});
                });
          }),
          e &&
            Te(document).on('mbsc-refresh', function (e) {
              var t;
              Te(e.target).is(s)
                ? (t = ce[e.target.id]) && t.refresh()
                : Te(s, e.target).each(function () {
                    (t = ce[this.id]) && t.refresh();
                  });
            });
      });
  }
  _ &&
    (['mouseover', 'mousedown', 'mouseup', 'click'].forEach(function (e) {
      document.addEventListener(e, G, !0);
    }),
    'android' == t &&
      n < 5 &&
      document.addEventListener(
        'change',
        function (e) {
          X && 'checkbox' == e.target.type && !e.target.mbscChange && (e.stopPropagation(), e.preventDefault()),
            delete e.target.mbscChange;
        },
        !0
      )),
    (ne.uid = '500d2ff0');
  var Q,
    Te = ne.$,
    ee = +new Date(),
    ce = {},
    pe = {},
    ue = {},
    Se = { xsmall: 0, small: 576, medium: 768, large: 992, xlarge: 1200 },
    ke = Te.extend;
  ke(m, {
    getCoord: xe,
    preventClick: re,
    vibrate: function (e) {
      'vibrate' in navigator && navigator.vibrate(e || 50);
    }
  }),
    (Q = ke(ne, {
      $: Te,
      version: '4.10.8',
      autoTheme: 'mobiscroll',
      themes: { form: {}, page: {}, frame: {}, scroller: {}, listview: {}, navigation: {}, progress: {}, card: {} },
      platform: { name: t, majorVersion: n, minorVersion: i },
      i18n: {},
      instances: ce,
      classes: pe,
      util: m,
      settings: {},
      setDefaults: function (e) {
        ke(this.settings, e);
      },
      customTheme: function (e, t) {
        var s,
          n = ne.themes,
          i = ['frame', 'scroller', 'listview', 'navigation', 'form', 'page', 'progress', 'card'];
        for (s = 0; s < i.length; s++) n[i[s]][e] = ke({}, n[i[s]][t], { baseTheme: t });
      }
    }));
  function Me(o, r) {
    var i,
      a,
      l,
      c,
      p,
      u,
      d,
      h,
      m,
      f = this;
    function b(e) {
      var s,
        t,
        n = ue;
      return (
        p.responsive &&
          ((s = e || (t = i)[0].innerWidth || t.innerWidth()),
          Te.each(p.responsive, function (e, t) {
            s >= (t.breakpoint || Se[e]) && (n = t);
          })),
        n
      );
    }
    (f.settings = {}),
      (f.element = o),
      (f._init = ae),
      (f._destroy = ae),
      (f._processSettings = ae),
      (f._checkResp = function (e) {
        if (f && f._responsive) {
          var t = b(e);
          if (c !== t) return (c = t), f.init({}), !0;
        }
      }),
      (f._getRespCont = function () {
        return Te('body' == p.context ? window : p.context);
      }),
      (f.init = function (e, t) {
        var s, n;
        for (s in (e && f.getVal && (n = f.getVal()), f.settings)) delete f.settings[s];
        (p = f.settings),
          ke(r, e),
          f._hasDef && (m = Q.settings),
          ke(p, f._defaults, m, r),
          (i = f._getRespCont()),
          f._responsive && ((c = c || b()), ke(p, c)),
          f._hasTheme && ((d = K(p)), (r.theme = d), (u = Q.themes[f._class] ? Q.themes[f._class][d] : {})),
          f._hasLang && (a = Q.i18n[p.lang]),
          ke(p, u, a, m, r, c),
          f._processSettings(c || {}),
          f._presets && (l = f._presets[p.preset]) && ((l = l.call(o, f, r)), ke(p, l, r, c)),
          f._init(e),
          e && f.setVal && f.setVal(void 0 === t ? n : t, !0),
          h('onInit');
      }),
      (f.destroy = function () {
        f && (f._destroy(), h('onDestroy'), delete ce[o.id], (f = null));
      }),
      (f.tap = function (e, t, s, n, i) {
        B(f, e, t, s, n, i);
      }),
      (f.trigger = function (e, t) {
        var s,
          n,
          i,
          a = [m, u, l, r];
        for (n = 0; n < 4; n++) (i = a[n]) && i[e] && (s = i[e].call(o, t || {}, f));
        return s;
      }),
      (f.option = function (e, t, s) {
        var n = {},
          i = ['data', 'invalid', 'valid', 'readonly'];
        /calendar|eventcalendar|range/.test(p.preset) && i.push('marked', 'labels', 'colors'),
          'object' == typeof e ? (n = e) : (n[e] = t),
          i.forEach(function (e) {
            r[e] = p[e];
          }),
          f.init(n, s);
      }),
      (f.getInst = function () {
        return f;
      }),
      (r = r || {}),
      (h = f.trigger),
      f.__ready ||
        (Te(o).addClass('mbsc-comp'),
        o.id ? ce[o.id] && ce[o.id].destroy() : (o.id = 'mobiscroll' + ++ee),
        ((ce[o.id] = f).__ready = !0));
  }
  var Oe = e.bool,
    Ee = e.string,
    Ne = e.func,
    Ve = e.number,
    De = e.object,
    Pe = e.oneOfType([De, Ee]),
    Ie = e.oneOfType([Ve, e.arrayOf(Ve)]),
    $e = {
      theme: Ee,
      themeVariant: e.oneOf(['auto', 'dark', 'light']),
      lang: Ee,
      rtl: Oe,
      responsive: De,
      context: e.oneOfType([Ee, De])
    },
    Le = {
      anchor: e.oneOfType([Ee, De]),
      animate: e.oneOfType([
        Oe,
        e.oneOf(['fade', 'flip', 'pop', 'swing', 'slidevertical', 'slidehorizontal', 'slidedown', 'slideup'])
      ]),
      buttons: e.array,
      closeOnOverlayTap: Oe,
      cssClass: Ee,
      disabled: Oe,
      display: e.oneOf(['top', 'bottom', 'bubble', 'inline', 'center']),
      focusOnClose: e.oneOfType([Oe, Ee, De]),
      focusTrap: Oe,
      headerText: e.oneOfType([Oe, Ee, Ne]),
      layout: e.oneOf(['liquid', 'fixed']),
      scrollLock: Oe,
      showOnFocus: Oe,
      showOnTap: Oe,
      showOnOverlay: Oe,
      touchUi: Oe,
      onBeforeClose: Ne,
      onBeforeShow: Ne,
      onCancel: Ne,
      onClose: Ne,
      onDestroy: Ne,
      onMarkupReady: Ne,
      onPosition: Ne,
      onShow: Ne
    },
    Ae = {
      circular: e.oneOfType([Oe, e.arrayOf(Oe)]),
      height: Ve,
      maxWidth: Ie,
      minWidth: Ie,
      multiline: Ve,
      readonly: e.oneOfType([Oe, e.arrayOf(Oe)]),
      rows: Ve,
      showLabel: Oe,
      showScrollArrows: Oe,
      wheels: e.array,
      width: Ve,
      onChange: Ne,
      validate: Ne,
      onSet: Ne,
      onItemTap: Ne,
      onClear: Ne,
      cancelText: Ee,
      clearText: Ee,
      selectedText: Ee,
      setText: Ee,
      formatValue: Ne,
      parseValue: Ne
    };
  e.array,
    e.oneOf(['iso8601', 'moment', 'locale', 'jsdate']),
    e.shape({ hour: Ve, minute: Ve, second: Ve, zeroBased: Oe }),
    e.array,
    e.oneOf(['jalali', 'hijri', 'gregorian']),
    e.arrayOf(Ee),
    e.arrayOf(Ee),
    e.arrayOf(Ee),
    e.arrayOf(Ee),
    e.oneOf(['horizontal', 'vertical']),
    e.oneOfType([Pe, e.array]),
    e.arrayOf(
      e.shape({ start: Pe, end: Pe, d: e.oneOfType([De, Ve, Ee]), text: Ee, color: Ee, background: Ee, cssClass: Ee })
    ),
    e.arrayOf(
      e.shape({ start: Pe, end: Pe, d: e.oneOfType([De, Ve, Ee]), text: Ee, color: Ee, background: Ee, cssClass: Ee })
    ),
    e.arrayOf(
      e.oneOfType([De, Ve, Ee, e.shape({ d: e.oneOfType([De, Ee, Ve]), color: Ee, background: Ee, cssClass: Ee })])
    ),
    e.arrayOf(e.shape({ d: e.oneOfType([De, Ee, Ve]), background: Ee, cssClass: Ee })),
    e.oneOf(['year', 'month']),
    e.oneOf(['full', 'short', 'min']),
    e.arrayOf(Ee),
    e.arrayOf(Ee);
  function Ue(e, t) {
    var s = a.findDOMNode(this),
      n = e.replace(/\s+/g, ' ').trim(),
      i = t.replace(/\s+/g, ' ').trim();
    n && s.classList.remove.apply(s.classList, n.split(' ')), i && s.classList.add.apply(s.classList, i.split(' '));
  }
  function Fe(e, t) {
    var o = [],
      r = [];
    return (function e(t, s, n) {
      var i;
      if (isNaN(t) && isNaN(s) && 'number' == typeof t && 'number' == typeof s) return !0;
      if (t === s) return !0;
      if ('function' == typeof t && 'function' == typeof s) return !1;
      if (
        (t instanceof Date && s instanceof Date) ||
        (t instanceof RegExp && s instanceof RegExp) ||
        (t instanceof String && s instanceof String) ||
        (t instanceof Number && s instanceof Number)
      )
        return t.toString() === s.toString();
      if (!(t instanceof Object && s instanceof Object)) return !1;
      if (t.isPrototypeOf(s) || s.isPrototypeOf(t)) return !1;
      if (t.constructor !== s.constructor) return !1;
      if (t.prototype !== s.prototype) return !1;
      if (-1 < o.indexOf(t) || -1 < r.indexOf(s)) return !1;
      for (i in s) {
        if (s.hasOwnProperty(i) !== t.hasOwnProperty(i)) return !1;
        if (typeof s[i] != typeof t[i]) return !1;
      }
      var a = n ? { $$typeof: 1, key: 1, props: 1, ref: 1, type: 1 } : t;
      for (i in a) {
        if (s.hasOwnProperty(i) !== t.hasOwnProperty(i)) return !1;
        if (typeof s[i] != typeof t[i]) return !1;
        switch (typeof t[i]) {
          case 'object':
          case 'function':
            if ((o.push(t), r.push(s), !e(t[i], s[i], t[i] && void 0 !== t[i].$$typeof))) return !1;
            o.pop(), r.pop();
            break;
          default:
            if (t[i] !== s[i]) return !1;
        }
      }
      return !0;
    })(e, t);
  }
  var We = (function (s) {
      function e(e) {
        var t;
        return ((t = s.call(this, e) || this).initialCssClass = t.props.className || ''), t;
      }
      l(e, s);
      var t = e.prototype;
      return (
        (t.render = function () {
          return null;
        }),
        (t.getSettingsFromProps = function (e, t) {
          var s = {};
          if (void 0 !== e) {
            var n = e.options,
              i =
                (e.children,
                e.value,
                e.checked,
                e.data,
                e.className,
                y(e, ['options', 'children', 'value', 'checked', 'data', 'className'])),
              a = n || '{}';
            (s = n || {}),
              void 0 !== n && 'string' == typeof a && (s = new Function('return ' + a + ';')()),
              (s = ke({}, s, i, t || {}));
          }
          return s;
        }),
        (t.componentWillUnmount = function () {
          this.instance.destroy(), delete this.instance;
        }),
        e
      );
    })(v.Component),
    Re = (function (s) {
      function e(e) {
        var t;
        return ((t = s.call(this, e) || this).updateForIonInput = t.updateForIonInput.bind(b(t))), t;
      }
      l(e, s);
      var t = e.prototype;
      return (
        (t.updateForIonInput = function () {
          this.valueState && ((this.optimizeUpdate = null), this.forceUpdate());
        }),
        (t.isIonInput = function (e) {
          return e && 1 == v.Children.count(e) && e.type && e.type.render && 'IonInput' === e.type.render.displayName;
        }),
        (t.componentDidUpdate = function () {
          var e = this.getSettingsFromProps(this.props);
          this.optimizeUpdate
            ? (this.optimizeUpdate.updateOptions && this.instance.option(e),
              this.optimizeUpdate.updateValue &&
                void 0 !== this.props.value &&
                !Fe(this.props.value, this.instance.getVal()) &&
                this.instance.setVal(this.props.value, !0),
              this.updateForIonInput())
            : null !== this.optimizeUpdate &&
              (this.instance.option(e), void 0 !== this.props.value && this.instance.setVal(this.props.value, !0));
        }),
        e
      );
    })(We),
    He = (function (t) {
      function e(e) {
        return t.call(this, e) || this;
      }
      return (
        l(e, t),
        (e.prototype.shouldComponentUpdate = function (e) {
          var t = !Fe(this.getSettingsFromProps(e), this.getSettingsFromProps(this.props)),
            s = !Fe(e.value, this.props.value),
            n = !Fe(e.children, this.props.children);
          return (this.optimizeUpdate = { updateOptions: t, updateValue: s, updateChildren: n }), t || s || n;
        }),
        e
      );
    })(Re),
    ze = (function (i) {
      function e() {
        for (var t, e = arguments.length, s = new Array(e), n = 0; n < e; n++) s[n] = arguments[n];
        return (
          o(b((t = i.call.apply(i, [this].concat(s)) || this)), 'element', null),
          o(b(t), 'inputCheckCount', 0),
          o(b(t), 'startInit', function () {
            t.element = a.findDOMNode(b(t));
            var e = Te(t.element).find('input');
            e.length && (t.element = e[0]),
              t.inputCheckCount++,
              !t.isIonInput(t.props.children) || e.length || 17 < t.inputCheckCount
                ? t.initInstance()
                : setTimeout(t.startInit, 30);
          }),
          o(b(t), 'initInstance', function () {
            var e = t.getSettingsFromProps(t.props, t.mbscInit);
            (t.instance = new pe[t.mbscInit.component || 'Scroller'](t.element, e)),
              void 0 !== t.props.value && (t.instance.setVal(t.props.value, !0), t.updateForIonInput());
          }),
          t
        );
      }
      l(e, i);
      var t = e.prototype;
      return (
        (t.render = function () {
          var e = this.props,
            t = e.type,
            s = e.readOnly,
            n = e.disabled,
            i = e.placeholder,
            a = e.children;
          if (((t = t || 'text'), this.isIonInput(a))) {
            this.valueState = !0;
            var o = this.instance ? this.instance._value : '';
            return v.cloneElement(a, g({ value: o }, a.props));
          }
          return a
            ? this.props.children
            : v.createElement('input', {
                className: this.initialCssClass,
                type: t,
                readOnly: s,
                disabled: n,
                placeholder: i
              });
        }),
        (t.componentDidMount = function () {
          this.startInit();
        }),
        e
      );
    })(He);
  function Ye(e, t, s, n, i, a) {
    var o,
      r,
      l,
      c,
      p,
      u,
      d,
      h,
      m,
      f = n || ae;
    function b(e) {
      var t;
      (o = Te(e.currentTarget)),
        (h = +o.attr('data-step')),
        (l = +o.attr('data-index')),
        (r = !0),
        i && e.stopPropagation(),
        'touchstart' == e.type && o.closest('.mbsc-no-touch').removeClass('mbsc-no-touch'),
        'mousedown' == e.type && e.preventDefault(),
        (t = 'keydown' != e.type ? ((u = xe(e, 'X')), (d = xe(e, 'Y')), be(e, this)) : 32 === e.keyCode),
        c ||
          !t ||
          o.hasClass('mbsc-disabled') ||
          (_(l, h, e) && (o.addClass('mbsc-active'), a && a.addRipple(o.find('.mbsc-segmented-content'), e)),
          'mousedown' == e.type && Te(document).on('mousemove', v).on('mouseup', g));
    }
    function v(e) {
      (7 < Math.abs(u - xe(e, 'X')) || 7 < Math.abs(d - xe(e, 'Y'))) && ((r = !0), y());
    }
    function g(e) {
      'touchend' == e.type && e.preventDefault(),
        y(),
        'mouseup' == e.type && Te(document).off('mousemove', v).off('mouseup', g);
    }
    function y() {
      (c = !1),
        clearInterval(m),
        o &&
          (o.removeClass('mbsc-active'),
          a &&
            setTimeout(function () {
              a.removeRipple();
            }, 100));
    }
    function _(e, t, s) {
      return c || f(e) || ((l = e), (h = t), (p = s), (r = !(c = !0)), setTimeout(w, 100)), c;
    }
    function w() {
      o && o.hasClass('mbsc-disabled')
        ? y()
        : ((!c && r) || ((r = !0), t(l, h, p, w)),
          c &&
            s &&
            (clearInterval(m),
            (m = setInterval(function () {
              t(l, h, p);
            }, s))));
    }
    return (
      e.each(function (e, t) {
        ge(t, 'touchstart', b, { passive: !0 }),
          ge(t, 'mousedown', b),
          ge(t, 'keydown', b),
          ge(t, 'touchmove', v, { passive: !0 }),
          ge(t, 'touchend', g),
          ge(t, 'touchcancel', g),
          ge(t, 'keyup', g);
      }),
      {
        start: _,
        stop: y,
        destroy: function () {
          e.each(function (e, t) {
            ye(t, 'touchstart', b, { passive: !0 }),
              ye(t, 'mousedown', b),
              ye(t, 'keydown', b),
              ye(t, 'touchmove', v, { passive: !0 }),
              ye(t, 'touchend', g),
              ye(t, 'touchcancel', g),
              ye(t, 'keyup', g);
          });
        }
      }
    );
  }
  o(ze, 'propTypes', g({}, $e, {}, Le, { type: e.string, placeholder: e.string })),
    o(
      (function (e) {
        function t() {
          return e.apply(this, arguments) || this;
        }
        l(t, e);
        var s = t.prototype;
        return (
          (s.render = function () {
            return v.createElement('ul', { className: this.initialCssClass + ' mbsc-cloak' }, this.props.children);
          }),
          (s.componentDidMount = function () {
            var e = this.getSettingsFromProps(this.props, this.mbscInit),
              t = a.findDOMNode(this);
            (this.instance = new pe[this.mbscInit.component || 'Scroller'](t, e)),
              void 0 !== this.props.value && this.instance.setVal(this.props.value, !0),
              (this.instance._markup || Te(t)).on('click', function (e) {
                e.stopPropagation();
              });
          }),
          (s.componentDidUpdate = function () {
            !this.optimizeUpdate.updateOptions &&
              this.optimizeUpdate.updateChildren &&
              this.instance.option(this.getSettingsFromProps(this.props));
            var e = a.findDOMNode(this);
            (this.instance._markup || Te(e)).on('click', function (e) {
              e.stopPropagation();
            });
          }),
          t
        );
      })(He),
      'propTypes',
      g({}, $e, {}, Le, {}, Ae)
    );
  var Xe,
    je = 'position:absolute;left:0;top:0;',
    qe = je + 'right:0;bottom:0;overflow:hidden;z-index:-1;',
    Be =
      '<div style="' +
      qe +
      '"><div style="' +
      je +
      '"></div></div><div style="' +
      qe +
      '"><div style="' +
      je +
      'width:200%;height:200%;"></div></div>',
    Ge = 0;
  function Ke(e) {
    e.preventDefault();
  }
  function Ze(s, n, e) {
    var S,
      p,
      u,
      k,
      T,
      M,
      O,
      E,
      N,
      V,
      i,
      D,
      P,
      I,
      $,
      L,
      d,
      A,
      U,
      F,
      W,
      h,
      R,
      H,
      z,
      t,
      m,
      Y,
      X,
      j,
      q,
      B,
      G,
      K = this,
      Z = Te(s),
      a = [],
      o = new Date();
    function J(e) {
      Y.stopProp && e.stopPropagation();
      var t = (function (e, t, s) {
        for (; t; ) {
          if (ie(t, s)) return t;
          t = t !== e ? t.parentNode : null;
        }
        return null;
      })(this, e.target, '.mbsc-fr-btn-e');
      t &&
        (i && i.removeClass('mbsc-active'),
        (i = Te(t)).hasClass('mbsc-disabled') || i.hasClass('mbsc-fr-btn-nhl') || i.addClass('mbsc-active'),
        'mousedown' === e.type && Te(document).on('mouseup', Q));
    }
    function Q(e) {
      i && (i.removeClass('mbsc-active'), (i = null)), 'mouseup' === e.type && Te(document).off('mouseup', Q);
    }
    function f(e) {
      X && A.contains(e.target) && e.preventDefault();
    }
    function b(e) {
      ne.activeInstance == K &&
        (13 != e.keyCode ||
        (Te(e.target).is('textarea,button,input[type="button"],input[type="submit"]') && !e.shiftKey)
          ? 27 == e.keyCode && K.cancel()
          : K.select());
    }
    function ee(e) {
      e || st || !K._activeElm || ((o = new Date()), K._activeElm.focus());
    }
    function r(e) {
      var t = Je,
        s = Y.focusOnClose;
      K._markupRemove(),
        k.remove(),
        $ &&
          (D.mbscModals--,
          Y.scrollLock && D.mbscLock--,
          D.mbscLock || u.removeClass('mbsc-fr-lock'),
          h &&
            (D.mbscIOSLock--,
            D.mbscIOSLock ||
              (u.removeClass('mbsc-fr-lock-ios'),
              S.css({ top: '', left: '' }),
              E.scrollLeft(D.mbscScrollLeft),
              E.scrollTop(D.mbscScrollTop))),
          D.mbscModals || u.removeClass('mbsc-fr-lock-ctx'),
          (D.mbscModals && !m) ||
            e ||
            ((t = t || Z),
            setTimeout(function () {
              void 0 === s || !0 === s ? ((Qe = !0), t[0].focus()) : s && Te(s)[0].focus();
            }, 200))),
        (m = void 0),
        (L = !1),
        q('onHide');
    }
    function te() {
      clearTimeout(t),
        (t = setTimeout(function () {
          K.position(!0) && ((z.style.visibility = 'hidden'), z.offsetHeight, (z.style.visibility = ''));
        }, 200));
    }
    function v(e) {
      ne.activeInstance == K &&
        e.target.nodeType &&
        !H.contains(e.target) &&
        100 < new Date() - o &&
        ((o = new Date()), K._activeElm.focus());
    }
    function g(e, t) {
      function s(e) {
        o || e.target != H || ((a = !(o = !0)), (r = xe(e, 'X')), (l = xe(e, 'Y')));
      }
      function n(e) {
        o && !a && (9 < Math.abs(xe(e, 'X') - r) || 9 < Math.abs(xe(e, 'Y') - l)) && (a = !0);
      }
      if (K._isVisible) {
        if ($) k.appendTo(S);
        else if (Z.is('div') && !K._hasContent) Z.empty().append(k);
        else if (Z.hasClass('mbsc-control')) {
          var i = Z.closest('.mbsc-control-w');
          k.insertAfter(i), i.hasClass('mbsc-select') && i.addClass('mbsc-select-inline');
        } else k.insertAfter(Z);
        var a, o, r, l, c, p, u, d, h, m, f, b, v, g, y;
        if (((L = !0), K._markupInserted(k), q('onMarkupInserted', { target: A }), $ && Y.closeOnOverlayTap))
          ge(H, 'touchstart', s, { passive: !0 }),
            ge(H, 'touchmove', n, { passive: !0 }),
            T.on('mousedown', s)
              .on('mousemove', n)
              .on('touchcancel', function () {
                o = !1;
              })
              .on('touchend click', function (e) {
                o && !a && (K.cancel(), 'touchend' == e.type && re()), (o = !1);
              });
        if (
          (k
            .on('mousedown', '.mbsc-btn-e,.mbsc-fr-btn-e', Ke)
            .on('keydown', '.mbsc-fr-btn-e', function (e) {
              32 == e.keyCode && (e.preventDefault(), e.stopPropagation(), this.click());
            })
            .on('keydown', function (e) {
              if (32 != e.keyCode || Te(e.target).is(at)) {
                if (9 == e.keyCode && $ && Y.focusTrap) {
                  var t = k.find('input,select,textarea,button,[tabindex="0"]').filter(function () {
                      return 0 < this.offsetWidth || 0 < this.offsetHeight;
                    }),
                    s = t.index(Te(':focus', k)),
                    n = t.length - 1,
                    i = 0;
                  e.shiftKey && ((n = 0), (i = -1)), s === n && (t.eq(i)[0].focus(), e.preventDefault());
                }
              } else e.preventDefault();
            })
            .on('touchend', '.mbsc-fr-btn-e', Q),
          ge(A, 'touchstart', J, { passive: !0 }),
          ge(A, 'mousedown', J),
          ge(
            A,
            'touchstart',
            function () {
              j || ((j = !0), S.find('.mbsc-no-touch').removeClass('mbsc-no-touch'));
            },
            { passive: !0, capture: !0 }
          ),
          Te.each(V, function (e, t) {
            K.tap(
              Te('.mbsc-fr-btn' + e, k),
              function (e) {
                (t = we(t) ? K.buttons[t] : t), (we(t.handler) ? K.handlers[t.handler] : t.handler).call(this, e, K);
              },
              !0
            );
          }),
          K._attachEvents(k),
          !1 !== K.position())
        )
          ($ || K._checkSize) &&
            ((c = A),
            (p = te),
            (u = Y.zone),
            (y = 0),
            window.ResizeObserver
              ? ((Xe =
                  Xe ||
                  new ResizeObserver(function (e) {
                    var t = e,
                      s = Array.isArray(t),
                      n = 0;
                    for (t = s ? t : t[Symbol.iterator](); ; ) {
                      var i;
                      if (s) {
                        if (n >= t.length) break;
                        i = t[n++];
                      } else {
                        if ((n = t.next()).done) break;
                        i = n.value;
                      }
                      i.target.__mbscResize();
                    }
                  })),
                Ge++,
                (c.__mbscResize = p),
                Xe.observe(c))
              : (((m = document.createElement('div')).innerHTML = Be),
                (m.dir = 'ltr'),
                (v = m.childNodes[1]),
                (d = m.childNodes[0]),
                (h = d.childNodes[0]),
                c.appendChild(m),
                d.addEventListener('scroll', C),
                v.addEventListener('scroll', C),
                u
                  ? u.runOutsideAngular(function () {
                      me(w);
                    })
                  : me(w)),
            (R = {
              detach: function () {
                Xe ? (Ge--, Xe.unobserve(c), Ge || (Xe = null)) : (c.removeChild(m), (g = !0));
              }
            })),
            $ &&
              (k.removeClass('mbsc-fr-pos'),
              P && !e
                ? k
                    .addClass('mbsc-anim-in mbsc-anim-trans mbsc-anim-trans-' + P)
                    .on(se, function e() {
                      k
                        .off(se, e)
                        .removeClass('mbsc-anim-in mbsc-anim-trans mbsc-anim-trans-' + P)
                        .find('.mbsc-fr-popup')
                        .removeClass('mbsc-anim-' + P),
                        ee(t);
                    })
                    .find('.mbsc-fr-popup')
                    .addClass('mbsc-anim-' + P)
                : ee(t)),
            q('onShow', { target: A, valueText: K._tempValue });
      }
      function _() {
        (h.style.width = '100000px'),
          (h.style.height = '100000px'),
          (d.scrollLeft = 1e5),
          (d.scrollTop = 1e5),
          (v.scrollLeft = 1e5),
          (v.scrollTop = 1e5);
      }
      function w() {
        var e = new Date();
        (f = 0), g || (200 < e - y && !d.scrollTop && !d.scrollLeft && ((y = e), _()), (f = f || me(w)));
      }
      function C() {
        b = b || me(x);
      }
      function x() {
        (b = 0), _(), p();
      }
    }
    function l(e, t) {
      K._isVisible || (e && e(), !1 !== K.show() && (Je = t));
    }
    function c() {
      K._fillValue(), q('onSet', { valueText: K._value });
    }
    function y() {
      q('onCancel', { valueText: K._value });
    }
    function _() {
      K.setVal(null, !0);
    }
    Me.call(this, s, n, !0),
      (K.position = function (e) {
        var t,
          s,
          n,
          i,
          a,
          o,
          r,
          l,
          c,
          p,
          u,
          d,
          h,
          m,
          f,
          b,
          v,
          g,
          y,
          _ = {},
          w = 0,
          C = 0,
          x = 0,
          T = 0;
        if (!L) return !1;
        if (
          ((b = B),
          (f = G),
          (h = Math.min(A.offsetHeight, I ? 1 / 0 : window.innerHeight)),
          (m = Math.min(A.offsetWidth, I ? 1 / 0 : window.innerWidth)) && h && (B !== m || G !== h || !e))
        ) {
          if (K._checkResp(m)) return !1;
          if (
            ((B = m),
            (G = h),
            K._isFullScreen || /top|bottom/.test(Y.display) ? O.width(m) : $ && N.width(''),
            K._position(k),
            !K._isFullScreen &&
              /center|bubble/.test(Y.display) &&
              (Te('.mbsc-w-p', k).each(function () {
                (v = this.getBoundingClientRect().width), (T += v), (x = x < v ? v : x);
              }),
              (d = m - 16 < T || !0 === Y.tabs),
              N.css({
                width: K._isLiquid ? Math.min(Y.maxPopupWidth, m - 16) : Math.ceil(d ? x : T),
                'white-space': d ? '' : 'nowrap'
              })),
            !1 !==
              q('onPosition', {
                target: A,
                popup: z,
                hasTabs: d,
                oldWidth: b,
                oldHeight: f,
                windowWidth: m,
                windowHeight: h
              }) && $)
          )
            return (
              W && ((w = E.scrollLeft()), (C = E.scrollTop()), B && M.css({ width: '', height: '' })),
              (U = z.offsetWidth),
              (F = z.offsetHeight),
              (X = F <= h && U <= m),
              'center' == Y.display
                ? ((y = Math.max(0, w + (m - U) / 2)), (g = Math.max(0, C + (h - F) / 2)))
                : 'bubble' == Y.display
                ? ((t = void 0 === Y.anchor ? Z : Te(Y.anchor)),
                  (r = Te('.mbsc-fr-arr-i', k)[0]),
                  (a = (i = t.offset()).top + (I ? C - S.offset().top : 0)),
                  (o = i.left + (I ? w - S.offset().left : 0)),
                  (s = t[0].offsetWidth),
                  (n = t[0].offsetHeight),
                  (l = r.offsetWidth),
                  (c = r.offsetHeight),
                  (y = Ce(o - (U - s) / 2, w + 3, w + m - U - 3)),
                  C + h < (g = a + n + c / 2) + F + 8 && C < a - F - c / 2
                    ? (O.removeClass('mbsc-fr-bubble-bottom').addClass('mbsc-fr-bubble-top'), (g = a - F - c / 2))
                    : O.removeClass('mbsc-fr-bubble-top').addClass('mbsc-fr-bubble-bottom'),
                  Te('.mbsc-fr-arr', k).css({ left: Ce(o + s / 2 - (y + (U - l) / 2), 0, l) }),
                  (X = C < g && w < y && g + F <= C + h && y + U <= w + m))
                : ((y = w), (g = 'top' == Y.display ? C : Math.max(0, C + h - F))),
              W &&
                ((p = Math.max(g + F, I ? D.scrollHeight : Te(document).height())),
                (u = Math.max(y + U, I ? D.scrollWidth : Te(document).width())),
                M.css({ width: u, height: p }),
                Y.scroll &&
                  'bubble' == Y.display &&
                  (C + h < g + F + 8 || C + h < a || a + n < C) &&
                  E.scrollTop(Math.min(a, g + F - h + 8, p - h))),
              (_.top = Math.floor(g)),
              (_.left = Math.floor(y)),
              O.css(_),
              !0
            );
        }
      }),
      (K.attachShow = function (e, t) {
        var s,
          n = Te(e).off('.mbsc'),
          i = n.prop('readonly');
        le(n),
          'inline' !== Y.display &&
            ((Y.showOnFocus || Y.showOnTap) &&
              n.is('input,select') &&
              (n
                .prop('readonly', !0)
                .on('mousedown.mbsc', function (e) {
                  e.preventDefault();
                })
                .on('focus.mbsc', function () {
                  K._isVisible && this.blur();
                }),
              (s = Te('label[for="' + n.attr('id') + '"]')).length || (s = n.closest('label'))),
            n.is('select') ||
              (Y.showOnFocus &&
                n.on('focus.mbsc', function () {
                  Qe ? (Qe = !1) : l(t, n);
                }),
              Y.showOnTap &&
                (n.on('keydown.mbsc', function (e) {
                  (32 != e.keyCode && 13 != e.keyCode) || (e.preventDefault(), e.stopPropagation(), l(t, n));
                }),
                K.tap(n, function (e) {
                  e.isMbscTap && (j = !0), l(t, n);
                }),
                s &&
                  s.length &&
                  K.tap(s, function (e) {
                    e.preventDefault(), e.target !== n[0] && l(t, n);
                  }))),
            a.push({ readOnly: i, el: n, lbl: s }));
      }),
      (K.select = function () {
        $ ? K.hide(!1, 'set', !1, c) : c();
      }),
      (K.cancel = function () {
        $ ? K.hide(!1, 'cancel', !1, y) : y();
      }),
      (K.clear = function () {
        K._clearValue(), q('onClear'), $ && K._isVisible && !K.live ? K.hide(!1, 'clear', !1, _) : _();
      }),
      (K.enable = function () {
        (Y.disabled = !1),
          Te.each(a, function (e, t) {
            t.el.is('input,select') && (t.el[0].disabled = !1);
          });
      }),
      (K.disable = function () {
        (Y.disabled = !0),
          Te.each(a, function (e, t) {
            t.el.is('input,select') && (t.el[0].disabled = !0);
          });
      }),
      (K.show = function (e, t) {
        var s, n, i, a;
        if (!Y.disabled && !K._isVisible) {
          if ((K._readValue(), !1 === q('onBeforeShow'))) return !1;
          if (
            ((Je = null),
            (P = Y.animate),
            (V = Y.buttons || []),
            (W = I || 'bubble' == Y.display),
            (h = tt && !W && Y.scrollLock),
            (s = 0 < V.length),
            !1 !== P &&
              ('top' == Y.display
                ? (P = P || 'slidedown')
                : 'bottom' == Y.display
                ? (P = P || 'slideup')
                : ('center' != Y.display && 'bubble' != Y.display) || (P = P || 'pop')),
            $ &&
              ((G = B = 0),
              h &&
                !u.hasClass('mbsc-fr-lock-ios') &&
                ((D.mbscScrollTop = a = Math.max(0, E.scrollTop())),
                (D.mbscScrollLeft = i = Math.max(0, E.scrollLeft())),
                S.css({ top: -a + 'px', left: -i + 'px' })),
              u.addClass(
                (Y.scrollLock ? 'mbsc-fr-lock' : '') + (h ? ' mbsc-fr-lock-ios' : '') + (I ? ' mbsc-fr-lock-ctx' : '')
              ),
              Te(document.activeElement).is('input,textarea') && document.activeElement.blur(),
              (m = ne.activeInstance),
              (ne.activeInstance = K),
              (D.mbscModals = (D.mbscModals || 0) + 1),
              h && (D.mbscIOSLock = (D.mbscIOSLock || 0) + 1),
              Y.scrollLock && (D.mbscLock = (D.mbscLock || 0) + 1)),
            (n =
              '<div lang="' +
              Y.lang +
              '" class="mbsc-fr mbsc-' +
              Y.theme +
              (Y.baseTheme ? ' mbsc-' + Y.baseTheme : '') +
              ' mbsc-fr-' +
              Y.display +
              ' ' +
              (Y.cssClass || '') +
              ' ' +
              (Y.compClass || '') +
              (K._isLiquid ? ' mbsc-fr-liq' : '') +
              ($ ? ' mbsc-fr-pos' + (Y.showOverlay ? '' : ' mbsc-fr-no-overlay') : '') +
              (d ? ' mbsc-fr-pointer' : '') +
              (it ? ' mbsc-fr-hb' : '') +
              (j ? '' : ' mbsc-no-touch') +
              (h ? ' mbsc-platform-ios' : '') +
              (s ? (3 <= V.length ? ' mbsc-fr-btn-block ' : '') : ' mbsc-fr-nobtn') +
              '">' +
              ($
                ? '<div class="mbsc-fr-persp">' +
                  (Y.showOverlay ? '<div class="mbsc-fr-overlay"></div>' : '') +
                  '<div role="dialog" class="mbsc-fr-scroll">'
                : '') +
              '<div class="mbsc-fr-popup' +
              (Y.rtl ? ' mbsc-rtl' : ' mbsc-ltr') +
              (Y.headerText ? ' mbsc-fr-has-hdr' : '') +
              '">' +
              ('bubble' === Y.display
                ? '<div class="mbsc-fr-arr-w"><div class="mbsc-fr-arr-i"><div class="mbsc-fr-arr"></div></div></div>'
                : '') +
              ($ ? '<div class="mbsc-fr-focus" tabindex="-1"></div>' : '') +
              '<div class="mbsc-fr-w">' +
              (Y.headerText ? '<div class="mbsc-fr-hdr">' + (we(Y.headerText) ? Y.headerText : '') + '</div>' : '') +
              '<div class="mbsc-fr-c">'),
            (n += K._generateContent()),
            (n += '</div>'),
            s)
          ) {
            var o,
              r,
              l,
              c = V.length;
            for (n += '<div class="mbsc-fr-btn-cont">', r = 0; r < V.length; r++)
              (l = Y.btnReverse ? c - r - 1 : r),
                'set' === (o = we((o = V[l])) ? K.buttons[o] : o).handler && (o.parentClass = 'mbsc-fr-btn-s'),
                'cancel' === o.handler && (o.parentClass = 'mbsc-fr-btn-c'),
                (n +=
                  '<div' +
                  (Y.btnWidth ? ' style="width:' + 100 / V.length + '%"' : '') +
                  ' class="mbsc-fr-btn-w ' +
                  (o.parentClass || '') +
                  '"><div tabindex="0" role="button" class="mbsc-fr-btn' +
                  l +
                  ' mbsc-fr-btn-e ' +
                  (void 0 === o.cssClass ? Y.btnClass : o.cssClass) +
                  (o.icon ? ' mbsc-ic mbsc-ic-' + o.icon : '') +
                  '">' +
                  (o.text || '') +
                  '</div></div>');
            n += '</div>';
          }
          (k = Te((n += '</div></div></div></div>' + ($ ? '</div></div>' : '')))),
            (M = Te('.mbsc-fr-persp', k)),
            (T = Te('.mbsc-fr-scroll', k)),
            (N = Te('.mbsc-fr-w', k)),
            (O = Te('.mbsc-fr-popup', k)),
            (p = Te('.mbsc-fr-hdr', k)),
            (A = k[0]),
            (H = T[0]),
            (z = O[0]),
            (K._activeElm = Te('.mbsc-fr-focus', k)[0]),
            (K._markup = k),
            (K._isVisible = !0),
            (K.markup = A),
            K._markupReady(k),
            q('onMarkupReady', { target: A }),
            $ &&
              (Te(window).on('keydown', b),
              Y.scrollLock &&
                (ge(document, 'touchmove', f, { passive: !1 }),
                ge(document, 'mousewheel', f, { passive: !1 }),
                ge(document, 'wheel', f, { passive: !1 })),
              Y.focusTrap && E.on('focusin', v)),
            $
              ? setTimeout(
                  function () {
                    g(e, t);
                  },
                  h ? 100 : 0
                )
              : g(e, t);
        }
      }),
      (K.hide = function (t, e, s, n) {
        if (
          !K._isVisible ||
          (!s && !K._isValid && 'set' == e) ||
          (!s && !1 === q('onBeforeClose', { valueText: K._tempValue, button: e }))
        )
          return !1;
        (K._isVisible = !1),
          R && (R.detach(), (R = null)),
          $ &&
            (Te(document.activeElement).is('input,textarea') &&
              z.contains(document.activeElement) &&
              document.activeElement.blur(),
            ne.activeInstance == K && (ne.activeInstance = m),
            Te(window).off('keydown', b),
            E.off('focusin', v),
            ye(document, 'touchmove', f, { passive: !1 }),
            ye(document, 'mousewheel', f, { passive: !1 }),
            ye(document, 'wheel', f, { passive: !1 })),
          k &&
            ($ && L && P && !t
              ? k
                  .addClass('mbsc-anim-out mbsc-anim-trans mbsc-anim-trans-' + P)
                  .on(se, function e() {
                    k.off(se, e), r(t);
                  })
                  .find('.mbsc-fr-popup')
                  .addClass('mbsc-anim-' + P)
              : r(t),
            K._detachEvents(k)),
          n && n(),
          Z.trigger('blur'),
          q('onClose', { valueText: K._value });
      }),
      (K.isVisible = function () {
        return K._isVisible;
      }),
      (K.setVal = ae),
      (K.getVal = ae),
      (K._generateContent = ae),
      (K._attachEvents = ae),
      (K._detachEvents = ae),
      (K._readValue = ae),
      (K._clearValue = ae),
      (K._fillValue = ae),
      (K._markupReady = ae),
      (K._markupInserted = ae),
      (K._markupRemove = ae),
      (K._position = ae),
      (K.__processSettings = ae),
      (K.__init = ae),
      (K.__destroy = ae),
      (K._destroy = function () {
        K.hide(!0, !1, !0),
          Z.off('.mbsc'),
          le(Z),
          Te.each(a, function (e, t) {
            t.el.off('.mbsc').prop('readonly', t.readOnly), le(t.el), t.lbl && (t.lbl.off('.mbsc'), le(t.lbl));
          }),
          K.__destroy();
      }),
      (K._updateHeader = function () {
        var e = Y.headerText,
          t = e ? ('function' == typeof e ? e.call(s, K._tempValue) : e.replace(/\{value\}/i, K._tempValue)) : '';
        p.html(t || '&nbsp;');
      }),
      (K._getRespCont = function () {
        return (
          (I = 'body' != Y.context),
          (E = Te(I ? Y.context : window)),
          'inline' == Y.display ? (Z.is('div') ? Z : Z.parent()) : E
        );
      }),
      (K._processSettings = function (e) {
        var t, s;
        for (
          K.__processSettings(e),
            (d = !Y.touchUi) &&
              ((Y.display = e.display || n.display || 'bubble'),
              (Y.buttons = e.buttons || n.buttons || []),
              (Y.showOverlay = e.showOverlay || n.showOverlay || !1)),
            Y.buttons = Y.buttons || ('inline' !== Y.display ? ['cancel', 'set'] : []),
            Y.headerText = void 0 === Y.headerText ? 'inline' !== Y.display && '{value}' : Y.headerText,
            V = Y.buttons || [],
            $ = 'inline' !== Y.display,
            S = Te(Y.context),
            u = I ? S : Te('body,html'),
            D = S[0],
            K.live = !0,
            s = 0;
          s < V.length;
          s++
        )
          ('ok' != (t = V[s]) && 'set' != t && 'set' != t.handler) || (K.live = !1);
        (K.buttons.set = { text: Y.setText, icon: Y.setIcon, handler: 'set' }),
          (K.buttons.cancel = { text: Y.cancelText, icon: Y.cancelIcon, handler: 'cancel' }),
          (K.buttons.close = { text: Y.closeText, icon: Y.closeIcon, handler: 'cancel' }),
          (K.buttons.clear = { text: Y.clearText, icon: Y.clearIcon, handler: 'clear' }),
          (K._isInput = Z.is('input'));
      }),
      (K._init = function (e) {
        var t = K._isVisible,
          s = t && !k.hasClass('mbsc-fr-pos');
        t && K.hide(!0, !1, !0),
          Z.off('.mbsc'),
          le(Z),
          K.__init(e),
          (K._isLiquid = 'liquid' == Y.layout),
          $ ? (K._readValue(), K._hasContent || Y.skipShow || K.attachShow(Z), t && K.show(s)) : K.show(),
          Z.removeClass('mbsc-cloak')
            .filter('input, select, textarea')
            .on('change.mbsc', function () {
              K._preventChange || K.setVal(Z.val(), !0, !1), (K._preventChange = !1);
            });
      }),
      (K.buttons = {}),
      (K.handlers = { set: K.select, cancel: K.cancel, clear: K.clear }),
      (K._value = null),
      (K._isValid = !0),
      (K._isVisible = !1),
      (Y = K.settings),
      (q = K.trigger),
      e || K.init();
  }
  var Je,
    Qe,
    et = ne.themes,
    tt = /(iphone|ipod)/i.test(C) && 7 <= n,
    st = 'android' == t,
    nt = 'ios' == t,
    it = nt && 7 < n,
    at = 'input,select,textarea,button';
  (Ze.prototype._defaults = {
    lang: 'en',
    setText: 'Set',
    selectedText: '{count} selected',
    closeText: 'Close',
    cancelText: 'Cancel',
    clearText: 'Clear',
    context: 'body',
    maxPopupWidth: 600,
    disabled: !1,
    closeOnOverlayTap: !0,
    showOnFocus: st || nt,
    showOnTap: !0,
    display: 'center',
    scroll: !0,
    scrollLock: !0,
    showOverlay: !0,
    tap: !0,
    touchUi: !0,
    btnClass: 'mbsc-fr-btn',
    btnWidth: !0,
    focusTrap: !0,
    focusOnClose: !(nt && 8 == n)
  }),
    (pe.Frame = Ze),
    (et.frame.mobiscroll = { headerText: !1, btnWidth: !1 }),
    (et.scroller.mobiscroll = ke({}, et.frame.mobiscroll, {
      rows: 5,
      showLabel: !1,
      selectedLineBorder: 1,
      weekDays: 'min',
      checkIcon: 'ion-ios7-checkmark-empty',
      btnPlusClass: 'mbsc-ic mbsc-ic-arrow-down5',
      btnMinusClass: 'mbsc-ic mbsc-ic-arrow-up5',
      btnCalPrevClass: 'mbsc-ic mbsc-ic-arrow-left5',
      btnCalNextClass: 'mbsc-ic mbsc-ic-arrow-right5'
    })),
    _ &&
      Te(window).on('focus', function () {
        Je && (Qe = !0);
      });
  function ot(i, e, t) {
    var n,
      s,
      a,
      o,
      c,
      r,
      l,
      p,
      u,
      d,
      h,
      m,
      f,
      b,
      v,
      g,
      y,
      _,
      w,
      C,
      x,
      T,
      S,
      k,
      M,
      O,
      E,
      N,
      V,
      D,
      P,
      I,
      $,
      L,
      A,
      U,
      F,
      W,
      R,
      H,
      z,
      Y,
      X,
      j,
      q,
      B = this,
      G = 0,
      K = 1,
      Z = e,
      J = Te(i);
    function Q(e) {
      X('onStart', { domEvent: e }),
        Z.stopProp && e.stopPropagation(),
        Z.prevDef && 'mousedown' == e.type && e.preventDefault(),
        Z.readonly ||
          (Z.lock && x) ||
          (be(e, this) &&
            !C &&
            (n && n.removeClass('mbsc-active'),
            (v = !1),
            x ||
              ((n = Te(e.target).closest('.mbsc-btn-e', this)).length &&
                !n.hasClass('mbsc-disabled') &&
                ((v = !0),
                (o = setTimeout(function () {
                  n.addClass('mbsc-active');
                }, 100)))),
            (T = V = !(C = !0)),
            (B.scrolled = x),
            (F = xe(e, 'X')),
            (W = xe(e, 'Y')),
            (m = F),
            (p = l = r = 0),
            (U = new Date()),
            (A = +ve(H, j) || 0),
            x && ue(A, rt ? 0 : 1),
            'mousedown' === e.type && Te(document).on('mousemove', ee).on('mouseup', se)));
    }
    function ee(e) {
      C &&
        (Z.stopProp && e.stopPropagation(),
        (m = xe(e, 'X')),
        (f = xe(e, 'Y')),
        (r = m - F),
        (l = f - W),
        (p = j ? l : r),
        v &&
          (Math.abs(l) > Z.thresholdY || Math.abs(r) > Z.thresholdX) &&
          (clearTimeout(o), n.removeClass('mbsc-active'), (v = !1)),
        (B.scrolled || (!T && Math.abs(p) > Y)) &&
          (V || X('onGestureStart', b), (B.scrolled = V = !0), k || ((k = !0), (S = me(te)))),
        j || Z.scrollLock
          ? e.preventDefault()
          : B.scrolled
          ? e.preventDefault()
          : 7 < Math.abs(l) && ((T = !0), (B.scrolled = !0), se()));
    }
    function te() {
      _ && (p = Ce(p, -$ * _, $ * _)), ue(Ce(A + p, w - h, y + h)), (k = !1);
    }
    function se(e) {
      if (C) {
        var t,
          s = new Date() - U;
        Z.stopProp && e && e.stopPropagation(),
          fe(S),
          (k = !1),
          !T &&
            B.scrolled &&
            (Z.momentum &&
              s < 300 &&
              ((t = p / s), (p = Math.max(Math.abs(p), (t * t) / Z.speedUnit) * (p < 0 ? -1 : 1))),
            pe(p)),
          v &&
            (clearTimeout(o),
            n.addClass('mbsc-active'),
            setTimeout(function () {
              n.removeClass('mbsc-active');
            }, 100),
            T || B.scrolled || X('onBtnTap', { target: n[0], domEvent: e })),
          e && 'mouseup' == e.type && Te(document).off('mousemove', ee).off('mouseup', se),
          (C = !1);
      }
    }
    function ne(e) {
      B.scrolled && ((B.scrolled = !1), e.stopPropagation(), e.preventDefault());
    }
    function ie(e) {
      if (
        i.contains(e.target) &&
        ((e = e.originalEvent || e),
        (p = j ? (null == e.deltaY ? e.wheelDelta || e.detail : e.deltaY) : e.deltaX),
        X('onStart', { domEvent: e }),
        Z.stopProp && e.stopPropagation(),
        p)
      ) {
        if (
          (e.preventDefault(), e.deltaMode && 1 == e.deltaMode && (p *= 15), (p = Ce(-p, -P, P)), (A = q), Z.readonly)
        )
          return;
        if (
          (V || ce(),
          A + p < w && ((A = w), (p = 0)),
          y < A + p && ((A = y), (p = 0)),
          k || ((k = !0), (S = me(te))),
          !p && V)
        )
          return;
        (V = !0),
          clearTimeout(D),
          (D = setTimeout(function () {
            fe(S), (V = k = !1), pe(p);
          }, 200));
      }
    }
    function ae(e) {
      X('onStart', { domEvent: e }),
        Z.readonly ||
          (e.stopPropagation(),
          (A = q),
          (V = !1),
          e.target == M
            ? ((W = xe(e, 'Y', !0)), Te(document).on('mousemove', oe).on('mouseup', re))
            : ((W = s.offset().top), oe(e), re()));
    }
    function oe(e) {
      var t = (xe(e, 'Y', !0) - W) / c;
      (p = g ? Ce((p = -(_ * $ * 2 + c) * t), -$ * _, $ * _) : (w - y - c) * t),
        V || ce(),
        (V = !0),
        ue(Ce(A + p, w - h, y + h));
    }
    function re() {
      (A = q), pe(0), Te(document).off('mousemove', oe).off('mouseup', re);
    }
    function le(e) {
      e.stopPropagation();
    }
    function ce() {
      X(
        'onGestureStart',
        (b = {
          posX: j ? 0 : q,
          posY: j ? q : 0,
          originX: j ? 0 : A,
          originY: j ? A : 0,
          direction: 0 < p ? (j ? 270 : 360) : j ? 90 : 180
        })
      );
    }
    function pe(e) {
      var t, s, n;
      if ((_ && (e = Ce(e, -$ * _, $ * _)), (n = Ce(Math.round((A + e) / $) * $, w, y)), L)) {
        if (e < 0) {
          for (t = L.length - 1; 0 <= t; t--)
            if (Math.abs(n) + c >= L[t].breakpoint) {
              (K = 2), (n = L[(G = t)].snap2);
              break;
            }
        } else if (0 <= e)
          for (t = 0; t < L.length; t++)
            if (Math.abs(n) <= L[t].breakpoint) {
              (K = 1), (n = L[(G = t)].snap1);
              break;
            }
        n = Ce(n, w, y);
      }
      (s = Z.time || (q < w || y < q ? 1e3 : Math.max(1e3, Math.abs(n - q) * Z.timeUnit))),
        (b.destinationX = j ? 0 : n),
        (b.destinationY = j ? n : 0),
        (b.duration = s),
        (b.transitionTiming = d),
        X('onGestureEnd', b),
        B.scroll(n, s);
    }
    function ue(t, e, s, n) {
      function i() {
        clearInterval(I),
          clearTimeout(z),
          (x = !1),
          (q = t),
          (b.posX = j ? 0 : t),
          (b.posY = j ? t : 0),
          o && X('onMove', b),
          r && X('onAnimationEnd', b),
          n && n();
      }
      var a,
        o = t != q,
        r = 1 < e,
        l = e ? de + 'transform ' + Math.round(e) + 'ms ' + d : '';
      (b = {
        posX: j ? 0 : q,
        posY: j ? q : 0,
        originX: j ? 0 : A,
        originY: j ? A : 0,
        direction: 0 < t - q ? (j ? 270 : 360) : j ? 90 : 180
      }),
        (q = t),
        r &&
          ((b.destinationX = j ? 0 : t),
          (b.destinationY = j ? t : 0),
          (b.duration = e),
          (b.transitionTiming = d),
          X('onAnimationStart', b)),
        (R[he + 'Transition'] = l),
        (R[he + 'Transform'] = 'translate3d(' + (j ? '0,' + t + 'px,' : t + 'px,0,') + '0)'),
        M &&
          O &&
          ((a = g ? (E - t) / (_ * $ * 2) : (t - y) / (w - y)),
          (M.style[he + 'Transition'] = l),
          (M.style[he + 'Transform'] = 'translate3d(0,' + Math.max(0, Math.min((c - O) * a, c - O)) + 'px,0)')),
        (!o && !x) || !e || e <= 1
          ? i()
          : e &&
            ((x = !s),
            clearInterval(I),
            (I = setInterval(function () {
              var e = +ve(H, j) || 0;
              (b.posX = j ? 0 : e), (b.posY = j ? e : 0), X('onMove', b), Math.abs(e - t) < 2 && i();
            }, 100)),
            clearTimeout(z),
            (z = setTimeout(function () {
              i();
            }, e))),
        Z.sync && Z.sync(t, e, d);
    }
    Me.call(this, i, e, !0),
      (B.scrolled = !1),
      (B.scroll = function (e, t, s, n) {
        (e = Ce(
          (e = _e(e)
            ? Math.round(e / $) * $
            : Math.ceil((Te(e, i).length ? Math.round(H.offset()[u] - Te(e, i).offset()[u]) : q) / $) * $),
          w,
          y
        )),
          (G = Math.round(e / $)),
          (A = q),
          (E = _ * $ + e),
          ue(e, t, s, n);
      }),
      (B.refresh = function (e) {
        var t;
        for (
          c = (void 0 === Z.contSize ? (j ? J.height() : J.width()) : Z.contSize) || 0,
            y = (void 0 === Z.maxScroll ? 0 : Z.maxScroll) || 0,
            w =
              Math.min(y, void 0 === Z.minScroll ? Math.min(0, j ? c - H.height() : c - H.width()) : Z.minScroll) || 0,
            L = null,
            !j && Z.rtl && ((t = y), (y = -w), (w = -t)),
            we(Z.snap) &&
              ((L = []),
              H.find(Z.snap).each(function () {
                var e = j ? this.offsetTop : this.offsetLeft,
                  t = j ? this.offsetHeight : this.offsetWidth;
                L.push({ breakpoint: e + t / 2, snap1: -e, snap2: c - e - t });
              })),
            $ = _e(Z.snap) ? Z.snap : 1,
            _ = Z.snap ? Z.maxSnapScroll : 0,
            d = Z.easing,
            h = Z.elastic ? (_e(Z.snap) ? $ : _e(Z.elastic) ? Z.elastic : 0) : 0,
            P = $;
          44 < P;

        )
          P /= 2;
        (P = Math.round(44 / P) * P),
          M &&
            ((g = w == -1 / 0 || y == 1 / 0),
            (O = w < y ? Math.max(20, (c * c) / (y - w + c)) : 0),
            (M.style.height = O + 'px'),
            (N.style.height = O ? '' : 0)),
          void 0 === q && ((q = Z.initialPos), (G = Math.round(q / $))),
          e || B.scroll(Z.snap ? (L && L[G] ? L[G]['snap' + K] : G * $) : q);
      }),
      (B._processSettings = function () {
        (j = 'Y' == Z.axis),
          (u = j ? 'top' : 'left'),
          (H = Z.moveElement || J.children().eq(0)),
          (R = H[0].style),
          (Y = j ? Z.thresholdY : Z.thresholdX),
          Z.scrollbar && ((a = Z.scrollbar), (s = a.find('.mbsc-sc-bar')), (M = s[0]), (N = a[0]));
      }),
      (B._init = function () {
        B.refresh(),
          ge(i, 'mousedown', Q),
          ge(i, 'touchstart', Q, { passive: !0 }),
          ge(i, 'touchend', se),
          ge(i, 'touchcancel', se),
          ge(i, 'click', ne, !0),
          ge(document, 'touchmove', ee, { passive: !1 }),
          Z.mousewheel &&
            (ge(document, 'wheel', ie, { passive: !1, capture: !0 }),
            ge(document, 'mousewheel', ie, { passive: !1, capture: !0 })),
          M && a.on('mousedown', ae).on('click', le);
      }),
      (B._destroy = function () {
        clearInterval(I),
          ye(i, 'mousedown', Q),
          ye(i, 'touchstart', Q, { passive: !0 }),
          ye(i, 'touchend', se),
          ye(i, 'touchcancel', se),
          ye(i, 'click', ne, !0),
          ye(document, 'touchmove', ee, { passive: !1 }),
          ye(document, 'wheel', ie, { passive: !1, capture: !0 }),
          ye(document, 'mousewheel', ie, { passive: !1, capture: !0 }),
          M && a.off('mousedown', ae).off('click', le);
      }),
      (Z = B.settings),
      (X = B.trigger),
      t || B.init();
  }
  var rt = 'ios' == t;
  ot.prototype = {
    _defaults: {
      speedUnit: 0.0022,
      timeUnit: 3,
      initialPos: 0,
      axis: 'Y',
      thresholdX: 10,
      thresholdY: 5,
      easing: 'cubic-bezier(0.190, 1.000, 0.220, 1.000)',
      stopProp: !0,
      momentum: !0,
      mousewheel: !0,
      elastic: !0
    }
  };
  var lt = {},
    ct = _ ? window.CSS : null,
    pt = ct && ct.supports && ct.supports('(transform-style: preserve-3d)');
  function ut(e) {
    return (e + '').replace(/"/g, '___');
  }
  function dt(f, t, e) {
    var s,
      d,
      g,
      b,
      y,
      c,
      i,
      _,
      w,
      p,
      u,
      v,
      C,
      x,
      T,
      S,
      o,
      k = 40,
      M = 1e3,
      O = this,
      r = Te(f);
    function n(e) {
      var t,
        s,
        n = +Te(this).attr('data-index');
      38 == e.keyCode
        ? ((t = !0), (s = -1))
        : 40 == e.keyCode
        ? ((t = !0), (s = 1))
        : 32 == e.keyCode && ((t = !0), l(n, Te(e.target))),
        t && (e.stopPropagation(), e.preventDefault(), s && i.start(n, s, e));
    }
    function a() {
      i.stop();
    }
    function l(e, t) {
      var s = S[e],
        n = +t.attr('data-index'),
        i = D(s, n),
        a = O._tempSelected[e],
        o = _e(s.multiple) ? s.multiple : 1 / 0;
      !1 === x('onItemTap', { target: t[0], index: e, value: i, selected: t.hasClass('mbsc-sc-itm-sel') }) ||
        O._prevItemTap ||
        (s.multiple &&
          !s._disabled[i] &&
          (void 0 !== a[i]
            ? (t.removeClass(y).removeAttr('aria-selected'), delete a[i])
            : (1 == o &&
                ((O._tempSelected[e] = a = {}),
                s._$markup.find('.mbsc-sc-itm-sel').removeClass(y).removeAttr('aria-selected')),
              te(a).length < o && (t.addClass(y).attr('aria-selected', 'true'), (a[i] = i)))),
        F(s, e, n, M, s._index < n ? 1 : 2, !0, s.multiple),
        O.live &&
          (!s.multiple || (1 === s.multiple && C.tapSelect)) &&
          (!0 === C.setOnTap || C.setOnTap[e]) &&
          setTimeout(
            function () {
              O.select();
            },
            C.tapSelect ? 0 : 200
          )),
        (O._prevItemTap = !1);
    }
    function h(e) {
      return -(e.max - e._offset - (e.multiple && !b ? Math.floor(C.rows / 2) : 0)) * w;
    }
    function m(e) {
      return -(e.min - e._offset + (e.multiple && !b ? Math.floor(C.rows / 2) : 0)) * w;
    }
    function E(e, t) {
      return (e._array ? e._map[t] : +e.getIndex(t, O)) || 0;
    }
    function N(e, t) {
      var s = e.data;
      if (t >= e.min && t <= e.max)
        return e._array ? (e.circular ? Te(s).get(t % e._length) : s[t]) : Te.isFunction(s) ? s(t, O) : '';
    }
    function V(e) {
      return Te.isPlainObject(e) ? (void 0 !== e.value ? e.value : e.display) : e;
    }
    function D(e, t) {
      return V(N(e, t));
    }
    function P(e, t, s) {
      var n = S[e];
      F(n, e, n._index + t, C.delay + 100, 1 == t ? 1 : 2, !1, !1, 'keydown' == s.type);
    }
    function I(e) {
      return Te.isArray(C.readonly) ? C.readonly[e] : C.readonly;
    }
    function $(e, t, s) {
      var n = ke(S[t] || {}, e),
        i = n._index - n._batch;
      return (
        (n.data = n.data || []),
        (n.key = void 0 !== n.key ? n.key : t),
        (n.label = void 0 !== n.label ? n.label : t),
        (n._map = {}),
        (n._array = Te.isArray(n.data)),
        n._array &&
          ((n._length = n.data.length),
          Te.each(n.data, function (e, t) {
            n._map[V(t)] = e;
          })),
        (n.circular =
          void 0 === C.circular
            ? void 0 === n.circular
              ? n._array && n._length > C.rows
              : n.circular
            : Te.isArray(C.circular)
            ? C.circular[t]
            : C.circular),
        (n.min = n._array ? (n.circular ? -1 / 0 : 0) : void 0 === n.min ? -1 / 0 : n.min),
        (n.max = n._array ? (n.circular ? 1 / 0 : n._length - 1) : void 0 === n.max ? 1 / 0 : n.max),
        (n._nr = t),
        (n._index = E(n, _[t])),
        (n._disabled = {}),
        (n._batch = 0),
        (n._current = n._index),
        (n._first = n._index - k),
        (n._last = n._index + k),
        (n._offset = n._first),
        s ? ((n._offset -= n._margin / w + (n._index - i)), (n._margin += (n._index - i) * w)) : (n._margin = 0),
        (n._refresh = function (e) {
          ke(n._scroller.settings, { minScroll: h(n), maxScroll: m(n) }), n._scroller.refresh(e);
        }),
        (o[n.key] = n)
      );
    }
    function L(e, t, s, n, i) {
      var a,
        o,
        r,
        l,
        c,
        p,
        u,
        d,
        h,
        m,
        f = '',
        b = O._tempSelected[t],
        v = e._disabled || {};
      for (a = s; a <= n; a++)
        (r = N(e, a)),
          (h = r),
          (c = void 0 === (m = Te.isPlainObject(h) ? h.display : h) ? '' : m),
          (l = V(r)),
          (o = r && void 0 !== r.cssClass ? r.cssClass : ''),
          (p = r && void 0 !== r.label ? r.label : ''),
          (u = r && r.invalid),
          (d = void 0 !== l && l == _[t] && !e.multiple),
          (f +=
            '<div role="option" tabindex="-1" aria-selected="' +
            !!b[l] +
            '" class="mbsc-sc-itm ' +
            (i ? 'mbsc-sc-itm-3d ' : '') +
            o +
            ' ' +
            (d ? 'mbsc-sc-itm-sel ' : '') +
            (b[l] ? y : '') +
            (void 0 === l ? ' mbsc-sc-itm-ph' : ' mbsc-btn-e') +
            (u ? ' mbsc-sc-itm-inv-h mbsc-disabled' : '') +
            (v[l] ? ' mbsc-sc-itm-inv mbsc-disabled' : '') +
            '" data-index="' +
            a +
            '" data-val="' +
            ut(l) +
            '"' +
            (p ? ' aria-label="' + p + '"' : '') +
            (d ? ' aria-selected="true"' : '') +
            ' style="height:' +
            w +
            'px;line-height:' +
            w +
            'px;' +
            (i
              ? de +
                'transform:rotateX(' +
                (((e._offset - a) * g) % 360) +
                'deg) translateZ(' +
                (w * C.rows) / 2 +
                'px);'
              : '') +
            '">' +
            (1 < T
              ? '<div class="mbsc-sc-itm-ml" style="line-height:' +
                Math.round(w / T) +
                'px;font-size:' +
                Math.round((w / T) * 0.8) +
                'px;">'
              : '') +
            c +
            (1 < T ? '</div>' : '') +
            '</div>');
      return f;
    }
    function A(e, t, s, n) {
      var i,
        a = S[e],
        o = n || a._disabled,
        r = E(a, t),
        l = D(a, r),
        c = l,
        p = l,
        u = 0,
        d = 0;
      if (!0 === o[l]) {
        for (i = 0; r - u >= a.min && o[c] && i < 100; ) i++, (c = D(a, r - ++u));
        for (i = 0; r + d < a.max && o[p] && i < 100; ) i++, (p = D(a, r + ++d));
        l = ((d < u && d && 2 !== s) || !u || r - u < 0 || 1 == s) && !o[p] ? p : c;
      }
      return l;
    }
    function U(n, i, a, e, o, t, r) {
      var l,
        c,
        p,
        u,
        s,
        d,
        h,
        m = O._isVisible;
      (v = !0),
        (u = C.validate.call(f, { values: _.slice(0), index: i, direction: a }, O) || {}),
        (v = !1),
        u.valid && (O._tempWheelArray = _ = u.valid.slice(0)),
        t ||
          Te.each(S, function (e, s) {
            if (
              (m && s._$markup.find('.mbsc-sc-itm-inv').removeClass('mbsc-sc-itm-inv mbsc-disabled'),
              (s._disabled = {}),
              u.disabled &&
                u.disabled[e] &&
                Te.each(u.disabled[e], function (e, t) {
                  (s._disabled[t] = !0),
                    m &&
                      s._$markup
                        .find('.mbsc-sc-itm[data-val="' + ut(t) + '"]')
                        .addClass('mbsc-sc-itm-inv mbsc-disabled');
                }),
              (_[e] = s.multiple ? _[e] : A(e, _[e], a)),
              m)
            ) {
              if (
                ((s.multiple && void 0 !== i) ||
                  s._$markup.find('.mbsc-sc-itm-sel').removeClass(y).removeAttr('aria-selected'),
                (c = E(s, _[e])),
                (l = c - s._index + s._batch),
                Math.abs(l) > 2 * k + 1 &&
                  ((p = l + (2 * k + 1) * (0 < l ? -1 : 1)), (s._offset += p), (s._margin -= p * w), s._refresh()),
                (s._index = c + s._batch),
                s.multiple)
              ) {
                if (void 0 === i)
                  for (var t in O._tempSelected[e])
                    s._$markup
                      .find('.mbsc-sc-itm[data-val="' + ut(t) + '"]')
                      .addClass(y)
                      .attr('aria-selected', 'true');
              } else
                s._$markup
                  .find('.mbsc-sc-itm[data-val="' + ut(_[e]) + '"]')
                  .addClass('mbsc-sc-itm-sel')
                  .attr('aria-selected', 'true');
              s._$active && s._$active.attr('tabindex', -1),
                (s._$active = s._$markup
                  .find('.mbsc-sc-itm[data-index="' + s._index + '"]')
                  .eq(b && s.multiple ? 1 : 0)
                  .attr('tabindex', 0)),
                r && i === e && s._$active.length && (s._$active[0].focus(), s._$scroller.parent().scrollTop(0)),
                s._scroller.scroll(-(c - s._offset + s._batch) * w, i === e || void 0 === i ? n : M, o);
            }
          }),
        x('onValidated', { index: i, time: n }),
        (O._tempValue = C.formatValue.call(f, _, O)),
        m && O._updateHeader(),
        O.live &&
          ((d = t),
          (h = S[(s = i)]) && (!h.multiple || (1 !== h.multiple && d && (!0 === C.setOnTap || C.setOnTap[s])))) &&
          ((O._hasValue = e || O._hasValue), W(e, e, 0, !0), e && x('onSet', { valueText: O._value })),
        e && x('onChange', { index: i, valueText: O._tempValue });
    }
    function F(e, t, s, n, i, a, o, r) {
      var l = D(e, s);
      void 0 !== l &&
        ((_[t] = l),
        (e._batch = e._array ? Math.floor(s / e._length) * e._length : 0),
        (e._index = s),
        setTimeout(function () {
          U(n, t, i, !0, a, o, r);
        }, 10));
    }
    function W(e, t, s, n, i) {
      if ((n ? (O._tempValue = C.formatValue.call(f, O._tempWheelArray, O)) : U(s), !i)) {
        O._wheelArray = [];
        for (var a = 0; a < _.length; a++)
          O._wheelArray[a] = S[a] && S[a].multiple ? Object.keys(O._tempSelected[a] || {})[0] : _[a];
        (O._value = O._hasValue ? O._tempValue : null), (O._selected = ke(!0, {}, O._tempSelected));
      }
      e &&
        (O._isInput && r.val(O._hasValue ? O._tempValue : ''),
        x('onFill', { valueText: O._hasValue ? O._tempValue : '', change: t }),
        t && ((O._preventChange = !0), r.trigger('change')));
    }
    Ze.call(this, f, t, !0),
      (O.setVal = O._setVal =
        function (e, t, s, n, i) {
          (O._hasValue = null != e),
            (O._tempWheelArray = _ = Te.isArray(e) ? e.slice(0) : C.parseValue.call(f, e, O) || []),
            W(t, void 0 === s ? t : s, i, !1, n);
        }),
      (O.getVal = O._getVal =
        function (e) {
          var t = O._hasValue || e ? O[e ? '_tempValue' : '_value'] : null;
          return _e(t) ? +t : t;
        }),
      (O.setArrayVal = O.setVal),
      (O.getArrayVal = function (e) {
        return e ? O._tempWheelArray : O._wheelArray;
      }),
      (O.changeWheel = function (e, t, s) {
        var n, i, a;
        Te.each(e, function (e, t) {
          (a = o[e]) &&
            ((n = a._nr),
            (i = $(t, n, !0)),
            O._isVisible &&
              (b && i._$3d.html(L(i, n, i._first + k - d + 1, i._last - k + d, !0)),
              i._$scroller.html(L(i, n, i._first, i._last)).css('margin-top', i._margin + 'px'),
              i._refresh(v)));
        }),
          !O._isVisible || O._isLiquid || v || O.position(),
          v || U(t, void 0, void 0, s);
      }),
      (O.getValidValue = A),
      (O._generateContent = function () {
        var n,
          i = 0,
          a = '',
          o = b ? de + 'transform: translateZ(' + ((w * C.rows) / 2 + 3) + 'px);' : '',
          r =
            '<div class="mbsc-sc-whl-l" style="' +
            o +
            'height:' +
            w +
            'px;margin-top:-' +
            (w / 2 + (C.selectedLineBorder || 0)) +
            'px;"></div>',
          l = 0;
        return (
          Te.each(C.wheels, function (e, t) {
            (a +=
              '<div class="mbsc-w-p mbsc-sc-whl-gr-c' +
              (b ? ' mbsc-sc-whl-gr-3d-c' : '') +
              (C.showLabel ? ' mbsc-sc-lbl-v' : '') +
              '">' +
              r +
              '<div class="mbsc-sc-whl-gr' +
              (b ? ' mbsc-sc-whl-gr-3d' : '') +
              (c ? ' mbsc-sc-cp' : '') +
              (C.width || C.maxWidth ? '"' : '" style="max-width:' + C.maxPopupWidth + 'px;"') +
              '>'),
              Te.each(t, function (e, t) {
                O._tempSelected[l] = ke({}, O._selected[l]);
                var s = $(t, l);
                (S[l] = s),
                  (i += C.maxWidth ? C.maxWidth[l] || C.maxWidth : C.width ? C.width[l] || C.width : 0),
                  (n = void 0 !== s.label ? s.label : e),
                  (a +=
                    '<div class="mbsc-sc-whl-w ' +
                    (s.cssClass || '') +
                    (s.multiple ? ' mbsc-sc-whl-multi' : '') +
                    '" style="' +
                    (C.width
                      ? 'width:' + (C.width[l] || C.width) + 'px;'
                      : (C.minWidth ? 'min-width:' + (C.minWidth[l] || C.minWidth) + 'px;' : '') +
                        (C.maxWidth ? 'max-width:' + (C.maxWidth[l] || C.maxWidth) + 'px;' : '')) +
                    '">' +
                    (u ? '<div class="mbsc-sc-bar-c"><div class="mbsc-sc-bar"></div></div>' : '') +
                    '<div class="mbsc-sc-whl-o" style="' +
                    o +
                    '"></div>' +
                    r +
                    '<div aria-live="off" aria-label="' +
                    n +
                    '"' +
                    (s.multiple ? ' aria-multiselectable="true"' : '') +
                    ' role="listbox" data-index="' +
                    l +
                    '" class="mbsc-sc-whl" style="height:' +
                    C.rows * w * (b ? 1.1 : 1) +
                    'px;">' +
                    (c
                      ? '<div data-index="' +
                        l +
                        '" data-step="1" class="mbsc-sc-btn mbsc-sc-btn-plus ' +
                        (C.btnPlusClass || '') +
                        '"></div><div data-index="' +
                        l +
                        '" data-step="-1" class="mbsc-sc-btn mbsc-sc-btn-minus ' +
                        (C.btnMinusClass || '') +
                        '"></div>'
                      : '') +
                    '<div class="mbsc-sc-lbl">' +
                    n +
                    '</div><div class="mbsc-sc-whl-c" style="height:' +
                    p +
                    'px;margin-top:-' +
                    (p / 2 + 1) +
                    'px;' +
                    o +
                    '"><div class="mbsc-sc-whl-sc" style="top:' +
                    (p - w) / 2 +
                    'px;">'),
                  (a += L(s, l, s._first, s._last) + '</div></div>'),
                  b &&
                    ((a += '<div class="mbsc-sc-whl-3d" style="height:' + w + 'px;margin-top:-' + w / 2 + 'px;">'),
                    (a += L(s, l, s._first + k - d + 1, s._last - k + d, !0)),
                    (a += '</div>')),
                  (a += '</div></div>'),
                  l++;
              }),
              (a += '</div></div>');
          }),
          i && (C.maxPopupWidth = i),
          a
        );
      }),
      (O._attachEvents = function (e) {
        (i = Ye(Te('.mbsc-sc-btn', e), P, C.delay, I, !0)), Te('.mbsc-sc-whl', e).on('keydown', n).on('keyup', a);
      }),
      (O._detachEvents = function () {
        i && i.stop();
        for (var e = 0; e < S.length; e++) S[e]._scroller.destroy();
      }),
      (O._markupReady = function (e) {
        Te('.mbsc-sc-whl-w', (s = e)).each(function (p) {
          var i,
            e = Te(this),
            u = S[p];
          (u._$markup = e),
            (u._$scroller = Te('.mbsc-sc-whl-sc', this)),
            (u._$3d = Te('.mbsc-sc-whl-3d', this)),
            (u._scroller = new ot(this, {
              mousewheel: C.mousewheel,
              moveElement: u._$scroller,
              scrollbar: Te('.mbsc-sc-bar-c', this),
              initialPos: (u._first - u._index) * w,
              contSize: C.rows * w,
              snap: w,
              minScroll: h(u),
              maxScroll: m(u),
              maxSnapScroll: k,
              prevDef: !0,
              stopProp: !0,
              timeUnit: 3,
              easing: 'cubic-bezier(0.190, 1.000, 0.220, 1.000)',
              sync: function (e, t, s) {
                var n = t ? de + 'transform ' + Math.round(t) + 'ms ' + s : '';
                b &&
                  ((u._$3d[0].style[he + 'Transition'] = n),
                  (u._$3d[0].style[he + 'Transform'] = 'rotateX(' + (-e / w) * g + 'deg)'));
              },
              onStart: function (e, t) {
                t.settings.readonly = I(p);
              },
              onGestureStart: function () {
                e.addClass('mbsc-sc-whl-a mbsc-sc-whl-anim'), x('onWheelGestureStart', { index: p });
              },
              onGestureEnd: function (e) {
                var t = 90 == e.direction ? 1 : 2,
                  s = e.duration,
                  n = e.destinationY;
                (i = Math.round(-n / w) + u._offset), F(u, p, i, s, t);
              },
              onAnimationStart: function () {
                e.addClass('mbsc-sc-whl-anim');
              },
              onAnimationEnd: function () {
                e.removeClass('mbsc-sc-whl-a mbsc-sc-whl-anim'),
                  x('onWheelAnimationEnd', { index: p }),
                  u._$3d.find('.mbsc-sc-itm-del').remove();
              },
              onMove: function (e) {
                var t, s, n, i, a, o, r, l, c;
                (t = u),
                  (s = p),
                  (n = e.posY),
                  (i = Math.round(-n / w) + t._offset),
                  (a = i - t._current),
                  (o = t._first),
                  (r = t._last),
                  (l = o + k - d + 1),
                  (c = r - k + d),
                  a &&
                    ((t._first += a),
                    (t._last += a),
                    (t._current = i),
                    0 < a
                      ? (t._$scroller.append(L(t, s, Math.max(r + 1, o + a), r + a)),
                        Te('.mbsc-sc-itm', t._$scroller)
                          .slice(0, Math.min(a, r - o + 1))
                          .remove(),
                        b &&
                          (t._$3d.append(L(t, s, Math.max(c + 1, l + a), c + a, !0)),
                          Te('.mbsc-sc-itm', t._$3d)
                            .slice(0, Math.min(a, c - l + 1))
                            .attr('class', 'mbsc-sc-itm-del')))
                      : a < 0 &&
                        (t._$scroller.prepend(L(t, s, o + a, Math.min(o - 1, r + a))),
                        Te('.mbsc-sc-itm', t._$scroller)
                          .slice(Math.max(a, o - r - 1))
                          .remove(),
                        b &&
                          (t._$3d.prepend(L(t, s, l + a, Math.min(l - 1, c + a), !0)),
                          Te('.mbsc-sc-itm', t._$3d)
                            .slice(Math.max(a, l - c - 1))
                            .attr('class', 'mbsc-sc-itm-del'))),
                    (t._margin += a * w),
                    t._$scroller.css('margin-top', t._margin + 'px'));
              },
              onBtnTap: function (e) {
                l(p, Te(e.target));
              }
            }));
        }),
          U();
      }),
      (O._fillValue = function () {
        W((O._hasValue = !0), !0, 0, !0);
      }),
      (O._clearValue = function () {
        Te('.mbsc-sc-whl-multi .mbsc-sc-itm-sel', s).removeClass(y).removeAttr('aria-selected');
      }),
      (O._readValue = function () {
        var e = r.val() || '',
          s = 0;
        '' !== e && (O._hasValue = !0),
          (O._tempWheelArray = _ =
            O._hasValue && O._wheelArray ? O._wheelArray.slice(0) : C.parseValue.call(f, e, O) || []),
          (O._tempSelected = ke(!0, {}, O._selected)),
          Te.each(C.wheels, function (e, t) {
            Te.each(t, function (e, t) {
              (S[s] = $(t, s)), s++;
            });
          }),
          W(!1, !1, 0, !0),
          x('onRead');
      }),
      (O.__processSettings = function (e) {
        (C = O.settings),
          (x = O.trigger),
          (T = C.multiline),
          (y = 'mbsc-sc-itm-sel mbsc-ic mbsc-ic-' + C.checkIcon),
          (u = !C.touchUi) && ((C.tapSelect = !0), (C.circular = !1), (C.rows = e.rows || t.rows || 7));
      }),
      (O.__init = function (e) {
        e && (O._wheelArray = null),
          (S = []),
          (o = {}),
          (c = C.showScrollArrows),
          (b = C.scroll3d && pt && !c && !u && ('ios' == C.theme || 'ios' == C.baseTheme)),
          (w = C.height),
          (p = b ? 2 * Math.round((w - 0.03 * ((w * C.rows) / 2 + 3)) / 2) : w),
          (d = Math.round(1.8 * C.rows)),
          (g = 360 / (2 * d)),
          c && (C.rows = Math.max(3, C.rows));
      }),
      (O._getItemValue = V),
      (O._tempSelected = {}),
      (O._selected = {}),
      e || O.init();
  }
  (dt.prototype = {
    _hasDef: !0,
    _hasTheme: !0,
    _hasLang: !0,
    _responsive: !0,
    _class: 'scroller',
    _presets: lt,
    _defaults: ke({}, Ze.prototype._defaults, {
      minWidth: 80,
      height: 40,
      rows: 3,
      multiline: 1,
      delay: 200,
      readonly: !1,
      showLabel: !0,
      setOnTap: !1,
      wheels: [],
      preset: '',
      speedUnit: 0.0012,
      timeUnit: 0.08,
      checkIcon: 'checkmark',
      compClass: 'mbsc-sc',
      validate: function () {},
      formatValue: function (e) {
        return e.join(' ');
      },
      parseValue: function (e, s) {
        var n,
          i,
          a = [],
          o = [],
          r = 0;
        return (
          null != e && (a = (e + '').split(' ')),
          Te.each(s.settings.wheels, function (e, t) {
            Te.each(t, function (e, t) {
              (i = t.data),
                (n = s._getItemValue(i[0])),
                Te.each(i, function (e, t) {
                  if (a[r] == s._getItemValue(t)) return (n = s._getItemValue(t)), !1;
                }),
                o.push(n),
                r++;
            });
          }),
          o
        );
      }
    })
  }),
    (pe.Scroller = dt);
  var ht = 0;
  function mt(e, t, s) {
    var n, i, a, o, r, l, c;
    'jsonp' == s
      ? ((o = e),
        (r = t),
        (l = document.createElement('script')),
        (c = 'mbscjsonp' + ++ht),
        (window[c] = function (e) {
          l.parentNode.removeChild(l), delete window[c], e && r(e);
        }),
        (l.src = o + (0 <= o.indexOf('?') ? '&' : '?') + 'callback=' + c),
        document.body.appendChild(l))
      : ((n = e),
        (i = t),
        (a = new XMLHttpRequest()).open('GET', n, !0),
        (a.onload = function () {
          200 <= this.status && this.status < 400 && i(JSON.parse(this.response));
        }),
        (a.onerror = function () {}),
        a.send());
  }
  m.getJson = mt;
  var ft = {
    inputClass: '',
    rtl: !1,
    showInput: !0,
    groupLabel: 'Groups',
    dataHtml: 'html',
    dataText: 'text',
    dataValue: 'value',
    dataGroup: 'group',
    dataDisabled: 'disabled',
    filterPlaceholderText: 'Type to filter',
    filterEmptyText: 'No results',
    filterClearIcon: 'material-close'
  };
  lt.select = function (r, e) {
    var l,
      h,
      c,
      p,
      s,
      m,
      n,
      f,
      u,
      d,
      i,
      b,
      a,
      v,
      o,
      t = '',
      g = {},
      y = 1e3,
      _ = this,
      w = Te(_),
      C = ke({}, r.settings),
      x = ke(r.settings, ft, C),
      T = Te('<div class="mbsc-sel-empty">' + x.filterEmptyText + '</div>'),
      S = x.readonly,
      k = {},
      M = x.layout || (/top|bottom|inline/.test(x.display) || x.filter ? 'liquid' : ''),
      O = 'liquid' == M || !x.touchUi,
      E = _e(x.select) ? x.select : 'multiple' == x.select || w.prop('multiple'),
      N = E || (!(!x.filter && !x.tapSelect) && 1),
      V = this.id + '_dummy',
      D = Te('label[for="' + this.id + '"]').attr('for', V),
      P = void 0 !== x.label ? x.label : D.length ? D.text() : w.attr('name'),
      I = x.group,
      $ = !!x.data,
      L = $ ? !!x.group : Te('optgroup', w).length,
      A = L && I && !1 !== I.groupWheel,
      U = L && I && A && !0 === I.clustered,
      F = L && (!I || (!1 !== I.header && !U)),
      W = w.val() || (E ? [] : ['']),
      R = [];
    function H(s) {
      var n,
        i,
        a,
        o,
        r,
        l,
        c = 0,
        p = 0,
        u = {};
      if (((k = {}), (f = {}), (b = []), (m = []), (R.length = 0), $))
        Te.each(h, function (e, t) {
          (r = t[x.dataText] + ''),
            (i = t[x.dataHtml]),
            (l = t[x.dataValue]),
            (a = t[x.dataGroup]),
            (o = { value: l, html: i, text: r, index: e, cssClass: F ? 'mbsc-sel-gr-itm' : '' }),
            (k[l] = o),
            (s && !K(r, s)) ||
              (b.push(o),
              L &&
                (void 0 === u[a]
                  ? ((n = { text: a, value: p, options: [], index: p }), (f[p] = n), (u[a] = p), m.push(n), p++)
                  : (n = f[u[a]]),
                U && (o.index = n.options.length),
                (o.group = u[a]),
                n.options.push(o)),
              t[x.dataDisabled] && R.push(l));
        });
      else if (L) {
        var d = !0;
        Te('optgroup', w).each(function (t) {
          (f[t] = { text: this.label, value: t, options: [], index: t }),
            (d = !0),
            Te('option', this).each(function (e) {
              (o = {
                value: this.value,
                text: this.text,
                index: U ? e : c++,
                group: t,
                cssClass: F ? 'mbsc-sel-gr-itm' : ''
              }),
                (k[this.value] = o),
                (s && !K(this.text, s)) ||
                  (d && (m.push(f[t]), (d = !1)), b.push(o), f[t].options.push(o), this.disabled && R.push(this.value));
            });
        });
      } else
        Te('option', w).each(function (e) {
          (o = { value: this.value, text: this.text, index: e }),
            (k[this.value] = o),
            (s && !K(this.text, s)) || (b.push(o), this.disabled && R.push(this.value));
        });
      (t = x.defaultValue ? x.defaultValue : b.length ? b[0].value : ''),
        F &&
          ((b = []),
          (c = 0),
          Te.each(f, function (e, t) {
            t.options.length &&
              ((l = '__group' + e),
              (o = { text: t.text, value: l, group: e, index: c++, cssClass: 'mbsc-sel-gr' }),
              (k[l] = o),
              b.push(o),
              R.push(o.value),
              Te.each(t.options, function (e, t) {
                (t.index = c++), b.push(t);
              }));
          })),
        T && (b.length ? T.removeClass('mbsc-sel-empty-v') : T.addClass('mbsc-sel-empty-v'));
    }
    function z(e, t, s, n, i) {
      var a,
        o = [];
      if (e)
        for (a = 0; a < e.length; a++)
          o.push({ value: e[a].value, display: e[a].html || e[a].text, cssClass: e[a].cssClass });
      return {
        circular: !1,
        multiple: t && !n ? 1 : n,
        cssClass: (t && !n ? 'mbsc-sel-one' : '') + ' ' + i,
        data: o,
        label: s
      };
    }
    function Y() {
      return z(U && f[s] ? f[s].options : b, N, P, E, '');
    }
    function X() {
      var e = [[]];
      return (
        A && ((n = z(m, N, x.groupLabel, !1, 'mbsc-sel-gr-whl')), O ? (e[0][u] = n) : (e[u] = [n])),
        (a = Y()),
        O ? (e[0][v] = a) : (e[v] = [a]),
        e
      );
    }
    function j(e) {
      E && (e && we(e) && (e = e.split(',')), Te.isArray(e) && (e = e[0])),
        !k[(i = null == e || '' === e ? t : e)] && b && b.length && (i = b[0].value),
        A && (s = k[i] ? k[i].group : null);
    }
    function q(e) {
      return g[e] || (k[e] ? k[e].text : '');
    }
    function B() {
      var e,
        t = '',
        s = r.getVal(),
        n = x.formatValue.call(_, r.getArrayVal(), r, !0);
      if (((x.filter && 'inline' == x.display) || l.val(n), w.is('select') && $)) {
        if (E) for (e = 0; e < s.length; e++) t += '<option value="' + s[e] + '">' + q(s[e]) + '</option>';
        else t = '<option value="' + (null === s ? '' : s) + '">' + n + '</option>';
        w.html(t);
      }
      _ !== l[0] && w.val(s);
    }
    function G() {
      var e = {};
      (e[v] = Y()), (o = !0), r.changeWheel(e);
    }
    function K(e, t) {
      return (t = t.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')), e.match(new RegExp(t, 'ig'));
    }
    function Z(e) {
      return x.data.dataField ? e[x.data.dataField] : x.data.processResponse ? x.data.processResponse(e) : e;
    }
    function J(e) {
      var t = {};
      H(e),
        j(i),
        (x.wheels = X()),
        (t[v] = a),
        (r._tempWheelArray[v] = i),
        A && ((t[u] = n), (r._tempWheelArray[u] = s)),
        r.changeWheel(t, 0, !0),
        B();
    }
    function Q(e) {
      return r.trigger('onFilter', { filterText: e });
    }
    function ee(e) {
      e[u] != s && ((s = e[u]), (i = f[s].options[0].value), (e[v] = i), U ? G() : r.setArrayVal(e, !1, !1, !0, y));
    }
    return (
      (r.setVal = function (e, t, s, n, i) {
        if (
          N &&
          (null == e || E || (e = [e]),
          e && we(e) && (e = e.split(',')),
          (r._tempSelected[v] = oe(e)),
          n || (r._selected[v] = oe(e)),
          (e = e ? e[0] : null),
          A)
        ) {
          var a = k[e],
            o = a && a.group;
          (r._tempSelected[u] = oe([o])), n || (r._selected[u] = oe([o]));
        }
        r._setVal(e, t, s, n, i);
      }),
      (r.getVal = function (e, t) {
        var s;
        return (
          (s = N
            ? ((s = te(e ? r._tempSelected[v] : r._selected[v])), E ? s : s.length ? s[0] : null)
            : (s = e ? r._tempWheelArray : r._hasValue ? r._wheelArray : null)
            ? s[v]
            : null),
          E ? s : void 0 !== s ? (L && t ? [k[s] ? k[s].group : null, s] : s) : null
        );
      }),
      (r.refresh = function (e, t, s) {
        (s = s || ae),
          e ? ((h = e), d || (x.data = e)) : Te.isArray(x.data) && (h = x.data),
          !e && d && void 0 === t
            ? mt(
                x.data.url,
                function (e) {
                  (h = Z(e)), J(), s();
                },
                x.data.dataType
              )
            : (J(t), s());
      }),
      e.invalid || (x.invalid = R),
      (v = A ? ((u = 0), 1) : ((u = -1), 0)),
      N && (E && w.is('select') && w.prop('multiple', !0), W && we(W) && (W = W.split(',')), (r._selected[v] = oe(W))),
      r._$input && r._$input.remove(),
      w.next().is('.mbsc-select-input')
        ? (l = w.next().removeAttr('tabindex'))
        : x.input
        ? (l = Te(x.input))
        : (x.filter && 'inline' == x.display
            ? (r._$input = Te(
                '<div class="mbsc-sel-input-wrap"><input type="text" id="' +
                  V +
                  '" class="mbsc-select-input mbsc-control ' +
                  x.inputClass +
                  '" readonly /></div>'
              ))
            : ((l = Te(
                '<input type="text" id="' +
                  V +
                  '" class="mbsc-select-input mbsc-control ' +
                  x.inputClass +
                  '" readonly />'
              )),
              (r._$input = l)),
          x.showInput && (r._$input.insertAfter(w), (l = l || r._$input.find('#' + V)))),
      r.attachShow(l.attr('placeholder', x.placeholder || '')),
      l[0] !== _ && (w.addClass('mbsc-sel-hdn').attr('tabindex', -1), x.showInput || w.attr('data-enhance', !1)),
      !N || x.rows % 2 || (x.rows = x.rows - 1),
      x.filter && (c = x.filter.minLength || 0),
      (d = x.data && x.data.url) ? r.refresh() : ($ && (h = x.data), H(), j(w.val())),
      {
        layout: M,
        headerText: !1,
        anchor: l,
        compClass: 'mbsc-sc mbsc-sel' + (N ? ' mbsc-sel-multi' : ''),
        setOnTap: !A || [!1, !0],
        formatValue: function (e, t, s) {
          var n,
            i = [],
            a = s ? t._selected : t._tempSelected;
          if (N) {
            for (n in a[v]) i.push(q(n));
            return i.join(', ');
          }
          return q(e[v]);
        },
        tapSelect: N,
        parseValue: function (e) {
          return j(void 0 === e ? w.val() : e), A ? [s, i] : [i];
        },
        validate: function (e) {
          var t = e.index,
            s = [];
          return (s[v] = x.invalid), U && !o && void 0 === t && G(), (o = !1), { disabled: s };
        },
        onRead: B,
        onFill: B,
        onMarkupReady: function (e, t) {
          if (x.filter) {
            var s,
              n,
              i,
              a = Te('.mbsc-fr-w', e.target),
              o = Te('<span class="mbsc-sel-filter-clear mbsc-ic mbsc-ic-' + x.filterClearIcon + '"></span>');
            'inline' == x.display
              ? (s = l).parent().find('.mbsc-sel-filter-clear').remove()
              : (a
                  .find('.mbsc-fr-c')
                  .before(
                    '<div class="mbsc-input mbsc-sel-filter-cont mbsc-control-w mbsc-' +
                      x.theme +
                      (x.baseTheme ? ' mbsc-' + x.baseTheme : '') +
                      '"><span class="mbsc-input-wrap"><input tabindex="0" type="text" class="mbsc-sel-filter-input mbsc-control"/></span></div>'
                  ),
                (s = a.find('.mbsc-sel-filter-input'))),
              (p = null),
              (i = s[0]),
              s.prop('readonly', !1).attr('placeholder', x.filterPlaceholderText).parent().append(o),
              a.find('.mbsc-fr-c').prepend(T),
              (t._activeElm = i),
              t.tap(o, function () {
                (p = null), (i.value = ''), t.refresh(), o.removeClass('mbsc-sel-filter-show-clear'), Q('');
              }),
              s
                .on('keydown', function (e) {
                  (13 != e.keyCode && 27 != e.keyCode) || (e.stopPropagation(), i.blur());
                })
                .on('input', function () {
                  clearTimeout(n),
                    i.value.length
                      ? o.addClass('mbsc-sel-filter-show-clear')
                      : o.removeClass('mbsc-sel-filter-show-clear'),
                    (n = setTimeout(function () {
                      p !== i.value &&
                        !1 !== Q(i.value) &&
                        ((p = i.value).length >= c || !p.length) &&
                        (d && x.data.remoteFilter
                          ? mt(
                              x.data.url + encodeURIComponent(p),
                              function (e) {
                                t.refresh(Z(e));
                              },
                              x.data.dataType
                            )
                          : t.refresh(void 0, p));
                    }, 500));
                });
          }
        },
        onBeforeShow: function () {
          E &&
            x.counter &&
            (x.headerText = function () {
              var e = 0;
              return (
                Te.each(r._tempSelected[v], function () {
                  e++;
                }),
                ((1 < e && x.selectedPluralText) || x.selectedText).replace(/{count}/, e)
              );
            }),
            j(w.val()),
            N && A && (r._selected[u] = oe([s])),
            x.filter && H(void 0),
            (r.settings.wheels = X()),
            (o = !0);
        },
        onWheelGestureStart: function (e) {
          e.index == u && (x.readonly = [!1, !0]);
        },
        onWheelAnimationEnd: function (e) {
          var t = r.getArrayVal(!0);
          e.index == u
            ? ((x.readonly = S), N || ee(t))
            : e.index == v &&
              t[v] != i &&
              ((i = t[v]),
              A &&
                k[i] &&
                k[i].group != s &&
                ((s = k[i].group), (t[u] = s), (r._tempSelected[u] = oe([s])), r.setArrayVal(t, !1, !1, !0, y)));
        },
        onItemTap: function (e) {
          var t;
          if (e.index == v && ((g[e.value] = k[e.value].text), N && !E && e.selected)) return !(r._prevItemTap = !0);
          if (e.index == u && N) {
            if (e.selected) return !(r._prevItemTap = !0);
            ((t = r.getArrayVal(!0))[u] = e.value), ee(t);
          }
        },
        onClose: function () {
          d && x.data.remoteFilter && p && r.refresh();
        },
        onDestroy: function () {
          r._$input && r._$input.remove(), w.removeClass('mbsc-sel-hdn').removeAttr('tabindex');
        }
      }
    );
  };
  var bt = e.bool,
    vt = e.string,
    gt = e.array,
    yt = e.number,
    _t = (function (t) {
      function e(e) {
        return t.call(this, e) || this;
      }
      l(e, t);
      var s = e.prototype;
      return (
        (s.componentDidMount = function () {
          var e,
            t = a.findDOMNode(this);
          (e = ne.$(t).find('input')).length && (t = e);
          var s = this.getSettingsFromProps(this.props, {
            preset: 'select',
            data: null === this.props.data ? [] : this.props.data,
            input: this.isIonInput(this.props.children) ? t : void 0
          });
          (this.instance = new dt(t, s)),
            void 0 !== this.props.value && (this.instance.setVal(this.props.value, !0), this.updateForIonInput());
        }),
        (s.componentDidUpdate = function () {
          if (this.optimizeUpdate) {
            var e = this.optimizeUpdate,
              t = e.updateChildren,
              s = e.updateData,
              n = e.updateOptions,
              i = e.updateValue,
              a = this.props.data;
            if (n && (s || t)) {
              var o = this.getSettingsFromProps(this.props, s ? { data: a } : {});
              this.instance.option(o);
            } else
              s || (t && !a)
                ? this.instance.refresh(a && a.url ? void 0 : a)
                : n && this.instance.option(this.getSettingsFromProps(this.props));
            i && (this.instance.setVal(this.props.value, !0), this.updateForIonInput());
          }
        }),
        (s.shouldComponentUpdate = function (e) {
          var t = this.getSettingsFromProps(this.props),
            s = !Fe(this.getSettingsFromProps(e), t),
            n = !Fe(e.data, this.props.data),
            i = !Fe(e.children, this.props.children),
            a = !Fe(e.value, this.props.value) && void 0 !== e.value && !Fe(e.value, this.instance.getVal());
          return (
            (this.optimizeUpdate = { updateOptions: s, updateData: n, updateValue: a, updateChildren: i }),
            s || n || a || i
          );
        }),
        (s.isSelectOrInput = function (e) {
          return 1 == v.Children.count(e) && ('select' == e.type || 'input' == e.type);
        }),
        (s.render = function () {
          var e = this.props,
            t = e.readOnly,
            s = e.disabled,
            n = e.data,
            i = e.value,
            a = e.children,
            o = i;
          if (this.isIonInput(a)) {
            this.valueState = !0;
            var r = this.instance ? this.instance._value : '';
            return v.cloneElement(a, g({ value: r }, a.props));
          }
          return this.isSelectOrInput(a)
            ? a
            : void 0 !== n
            ? 0 < v.Children.count(a)
              ? a
              : v.createElement('input', {
                  defaultValue: o,
                  type: 'text',
                  className: this.initialCssClass,
                  readOnly: t,
                  disabled: s
                })
            : v.createElement(
                'select',
                { className: this.initialCssClass + ' mbsc-cloak', readOnly: t, disabled: s },
                this.props.children
              );
        }),
        e
      );
    })(Re);
  (_t.propTypes = g({}, _t.propTypes, {}, $e, {}, Le, {}, Ae, {
    counter: bt,
    data: e.oneOfType([
      gt,
      e.shape({
        url: vt,
        dataField: vt,
        dataType: e.oneOf(['json', 'jsonp']),
        processResponse: e.func,
        remoteFilter: bt
      })
    ]),
    dataText: vt,
    dataGroup: vt,
    dataValue: vt,
    group: e.oneOfType([bt, e.shape({ groupedWheel: bt, header: bt, clustered: bt })]),
    filter: bt,
    filterPlaceholderText: vt,
    filterEmptyText: vt,
    groupLabel: vt,
    input: e.oneOfType([vt, e.object]),
    inputClass: vt,
    invalid: gt,
    label: vt,
    placeholder: vt,
    select: e.oneOfType([yt, e.oneOf(['single', 'multiple'])]),
    showInput: bt,
    onFilter: e.func
  })),
    (ne.Select = _t);
  var wt = (function (s) {
    function e(e) {
      var t;
      return ((t = s.call(this, e) || this).mbscInit = { component: 'Scroller' }), t;
    }
    return l(e, s), e;
  })(ze);
  (wt.propTypes = g({}, wt.propTypes, {}, Ae)), (ne.Scroller = wt);
  var Ct,
    xt = 'mbsc-input-wrap',
    Tt = ['touchend', 'touchcancel', 'mousedown', 'mousemove', 'mouseup', 'mouseleave'],
    St = { tap: c };
  function kt(e, t) {
    var s = {},
      n = e[0],
      i = e.parent(),
      a = i.find('.mbsc-err-msg'),
      o = e.attr('data-icon-align') || 'left',
      r = e.attr('data-icon');
    i.hasClass(xt)
      ? (i = i.parent())
      : Te('<span class="' + xt + '"></span>')
          .insertAfter(e)
          .append(e),
      a && i.find('.' + xt).append(a),
      r && (-1 !== r.indexOf('{') ? (s = JSON.parse(r)) : (s[o] = r)),
      'file' == n.type && (s.right = e.attr('data-icon-upload') || 'upload'),
      (r || t) &&
        (ke(s, t),
        i
          .addClass((s.right ? 'mbsc-ic-right ' : '') + (s.left ? ' mbsc-ic-left' : ''))
          .find('.' + xt)
          .append('<span class="mbsc-input-fill"></span>')
          .append(s.left ? '<span class="mbsc-input-ic mbsc-left-ic mbsc-ic mbsc-ic-' + s.left + '"></span>' : '')
          .append(s.right ? '<span class="mbsc-input-ic mbsc-right-ic mbsc-ic mbsc-ic-' + s.right + '"></span>' : ''));
  }
  function Mt(e, t, s, n, i) {
    'segmented' == t
      ? e
          .closest('.mbsc-segmented')
          .addClass('box' == s ? 'mbsc-input-box' : '')
          .addClass('outline' == s ? 'mbsc-input-outline' : '')
      : 'button' != t &&
        'submit' != t &&
        (e
          .addClass('mbsc-control-w')
          .addClass('box' == s ? 'mbsc-input-box' : '')
          .addClass('outline' == s ? 'mbsc-input-outline' : '')
          .addClass('inline' == n ? 'mbsc-label-inline' : '')
          .addClass('stacked' == n ? 'mbsc-label-stacked' : '')
          .addClass('floating' == n ? 'mbsc-label-floating' : '')
          .addClass('floating' == n && i.value ? 'mbsc-label-floating-active' : '')
          .find('label')
          .addClass('mbsc-label')
          .each(function (e, t) {
            Te(t).attr('title', Te(t).text());
          }),
        e
          .contents()
          .filter(function () {
            return 3 == this.nodeType && this.nodeValue && /\S/.test(this.nodeValue);
          })
          .each(function () {
            Te('<span class="mbsc-label" title="' + this.textContent.trim() + '"></span>')
              .insertAfter(this)
              .append(this);
          }));
  }
  function Ot(e) {
    var t = ne.themes.form[e];
    return t && t.addRipple ? t : null;
  }
  function Et(e, t, s) {
    var n = e.attr(t);
    return void 0 === n || '' === n ? s : n;
  }
  function Nt(e) {
    var t = K(e),
      s = ne.themes.form[t].baseTheme;
    return 'mbsc-' + t + (s ? ' mbsc-' + s : '') + (e.rtl ? ' mbsc-rtl' : ' mbsc-ltr');
  }
  var Vt = (function () {
    function e(e, t) {
      var s = this,
        n = ke({}, St, ne.settings, t),
        i = Te(e),
        a = i.parent(),
        o = a.hasClass('mbsc-input-wrap') ? a.parent() : a,
        r = i.next().hasClass('mbsc-fr') ? i.next() : null,
        l = q(i),
        c = Et(i, 'data-input-style', n.inputStyle),
        p = Et(i, 'data-label-style', n.labelStyle);
      e.mbscInst && e.mbscInst.destroy(),
        r && r.insertAfter(o),
        (n.theme = K(n)),
        void 0 === n.rtl && n.lang && ne.i18n[n.lang] && (n.rtl = ne.i18n[n.lang].rtl),
        Mt(o, l, c, p, e),
        i.addClass('mbsc-control'),
        (this._handle = this._handle.bind(this)),
        Tt.forEach(function (e) {
          i.on(e, s._handle);
        }),
        ge(e, 'touchstart', this._handle, { passive: !0 }),
        ge(e, 'touchmove', this._handle, { passive: !0 }),
        (this.settings = n),
        (this._type = l),
        (this._elm = e),
        (this._$elm = i),
        (this._$parent = o),
        (this._$frame = r),
        (this._ripple = Ot(n.theme)),
        (this._isFloating = 'floating' == p || o.hasClass('mbsc-label-floating')),
        (this.cssClass = Nt(n)),
        this.getClassElm().addClass(this.cssClass),
        (e.mbscInst = this);
    }
    var t = e.prototype;
    return (
      (t.getClassElm = function () {
        return this._$parent;
      }),
      (t.destroy = function () {
        var t = this,
          s = this._$elm,
          e = this._elm;
        s.removeClass('mbsc-control'),
          this.getClassElm().removeClass(this.cssClass),
          Tt.forEach(function (e) {
            s.off(e, t._handle);
          }),
          ye(e, 'touchstart', this._handle, { passive: !0 }),
          ye(e, 'touchmove', this._handle, { passive: !0 }),
          delete e.mbscInst;
      }),
      (t.option = function (e) {
        ke(this.settings, e);
        var t = this.getClassElm();
        this.cssClass && t.removeClass(this.cssClass),
          (this.cssClass = Nt(this.settings)),
          t.addClass(this.cssClass),
          (this._ripple = Ot(this.settings.theme));
      }),
      (t._handle = function (e) {
        switch (e.type) {
          case 'touchstart':
          case 'mousedown':
            this._onStart(e);
            break;
          case 'touchmove':
          case 'mousemove':
            this._onMove(e);
            break;
          case 'touchend':
          case 'touchcancel':
          case 'mouseup':
          case 'mouseleave':
            this._onEnd(e);
        }
      }),
      (t._addRipple = function (e) {
        this._ripple && this._$rippleElm && this._ripple.addRipple(this._$rippleElm, e);
      }),
      (t._removeRipple = function () {
        this._ripple && this._$rippleElm && this._ripple.removeRipple();
      }),
      (t._onStart = function (e) {
        var t = this._elm;
        be(e, t) &&
          ((this._startX = xe(e, 'X')),
          (this._startY = xe(e, 'Y')),
          Ct && Ct.removeClass('mbsc-active'),
          t.disabled || ((this._isActive = !0), (Ct = this._$elm).addClass('mbsc-active'), this._addRipple(e))),
          'touchstart' == e.type && this._$elm.closest('.mbsc-no-touch').removeClass('mbsc-no-touch');
      }),
      (t._onMove = function (e) {
        ((this._isActive && 9 < Math.abs(xe(e, 'X') - this._startX)) || 9 < Math.abs(xe(e, 'Y') - this._startY)) &&
          (this._$elm.removeClass('mbsc-active'), this._removeRipple(), (this._isActive = !1));
      }),
      (t._onEnd = function (e) {
        var t,
          s,
          n,
          i = this,
          a = this._elm,
          o = this._type;
        this._isActive &&
          this.settings.tap &&
          'touchend' == e.type &&
          !a.readOnly &&
          ((s = o),
          (n = e),
          (t = a).focus(),
          /(button|submit|checkbox|switch|radio)/.test(s) && n.preventDefault(),
          /select/.test(s) || j(n, t)),
          this._isActive &&
            setTimeout(function () {
              i._$elm.removeClass('mbsc-active'), i._removeRipple();
            }, 100),
          (this._isActive = !1),
          (Ct = null);
      }),
      e
    );
  })();
  ne.themes.form.mobiscroll = {};
  var Dt = ['focus', 'change', 'blur', 'animationstart'],
    Pt = (function (f) {
      function e(e, t) {
        var s,
          n,
          i,
          a,
          o,
          r,
          l,
          c,
          p,
          u = (s = f.call(this, e, t) || this)._$elm,
          d = s._$parent,
          h = d.find('.mbsc-select-input, .mbsc-color-input');
        if (
          ((n = b(s)),
          (i = d),
          (o = {}),
          (r = (a = u)[0]),
          (l = a.attr('data-password-toggle')),
          (c = a.attr('data-icon-show') || 'eye'),
          (p = a.attr('data-icon-hide') || 'eye-blocked'),
          l && (o.right = 'password' == r.type ? c : p),
          kt(a, o),
          l &&
            B(n, i.find('.mbsc-right-ic').addClass('mbsc-input-toggle'), function () {
              'text' == r.type
                ? ((r.type = 'password'),
                  Te(this)
                    .addClass('mbsc-ic-' + c)
                    .removeClass('mbsc-ic-' + p))
                : ((r.type = 'text'),
                  Te(this)
                    .removeClass('mbsc-ic-' + c)
                    .addClass('mbsc-ic-' + p));
            }),
          (s._checkLabel = s._checkLabel.bind(b(s))),
          (s._mouseDown = s._mouseDown.bind(b(s))),
          (s._setText = s._setText.bind(b(s))),
          'file' == e.type)
        ) {
          var m = d.find('.mbsc-file-input');
          (s._$input = m.length
            ? m
            : Te(
                '<input type="text" class="' +
                  (u.attr('class') || '') +
                  ' mbsc-file-input" placeholder="' +
                  (u.attr('placeholder') || '') +
                  '"/>'
              ).insertAfter(u)),
            u.on('change', s._setText);
        }
        return (
          d.addClass('mbsc-input').on('mousedown', s._mouseDown),
          Dt.forEach(function (e) {
            u.on(e, s._checkLabel);
          }),
          h.length && (u.after(h), h.hasClass('mbsc-select-input') && ((s._delm = h[0]), s.refresh())),
          s
        );
      }
      l(e, f);
      var t = e.prototype;
      return (
        (t._setText = function (e) {
          for (var t = e.target.files, s = [], n = 0; n < t.length; ++n) s.push(t[n].name);
          this._$input.val(s);
        }),
        (t._checkLabel = function (e) {
          if (this._isFloating) {
            var t = this._delm || this._elm;
            t.value ||
            document.activeElement === t ||
            (e && ('focus' == e.type || ('animationstart' == e.type && this._$elm.is('*:-webkit-autofill'))))
              ? this._$parent.addClass('mbsc-label-floating-active')
              : this._$parent.removeClass('mbsc-label-floating-active');
          }
        }),
        (t._mouseDown = function (e) {
          document.activeElement === this._elm && e.target !== this._elm && e.preventDefault();
        }),
        (t.refresh = function () {
          this._checkLabel();
        }),
        (t.destroy = function () {
          var t = this;
          f.prototype.destroy.call(this),
            this._$parent
              .off('mousedown', this._mouseDown)
              .removeClass('mbsc-ic-left mbsc-ic-right')
              .find('.mbsc-input-ic')
              .remove(),
            this._$parent.find('.mbsc-input-fill').remove(),
            Dt.forEach(function (e) {
              t._$elm.off(e, t._checkLabel);
            }),
            this._$elm.off('change', this._setText);
        }),
        e
      );
    })(Vt);
  J('[mbsc-input]', Pt);
  var It = (function (a) {
    function e(e, t) {
      var s,
        n = (s = a.call(this, e, t) || this)._$elm,
        i = n.attr('data-icon');
      return (
        n.addClass('mbsc-btn mbsc-no-touch').find('.mbsc-btn-ic').remove(),
        i &&
          (n.prepend('<span class="mbsc-btn-ic mbsc-ic mbsc-ic-' + i + '"></span>'),
          '' === n.text() && n.addClass('mbsc-btn-icon-only')),
        (s._$rippleElm = n),
        s
      );
    }
    return (
      l(e, a),
      (e.prototype.getClassElm = function () {
        return this._$elm;
      }),
      e
    );
  })(Vt);
  J('[mbsc-button]', It);
  var $t = (function (n) {
    function e(e, t) {
      var s;
      return (
        (s = n.call(this, e, t) || this)._$parent
          .prepend(s._$elm)
          .addClass('mbsc-checkbox mbsc-control-w')
          .find('.mbsc-checkbox-box')
          .remove(),
        s._$elm.after('<span class="mbsc-checkbox-box"></span>'),
        s
      );
    }
    return l(e, n), e;
  })(Vt);
  J('[mbsc-checkbox]', $t);
  var Lt = (function (n) {
    function e(e, t) {
      var s;
      return (
        (s = n.call(this, e, t) || this)._$parent
          .addClass('mbsc-radio mbsc-control-w')
          .find('.mbsc-radio-box')
          .remove(),
        s._$elm.after('<span class="mbsc-radio-box"><span></span></span>'),
        s
      );
    }
    return l(e, n), e;
  })(Vt);
  J('[mbsc-radio]', Lt);
  var At = (function (r) {
    function e(e, t) {
      var s,
        n = (s = r.call(this, e, t) || this)._$elm,
        i = s._$parent,
        a = i.find('.mbsc-select-input'),
        o = a.length ? a : Te('<input tabindex="-1" class="mbsc-select-input mbsc-control" readonly>');
      return (
        (s._$input = o),
        (s._delm = o[0]),
        (s._setText = s._setText.bind(b(s))),
        i.addClass('mbsc-select' + (s._$frame ? ' mbsc-select-inline' : '')),
        n.after(o),
        o.after('<span class="mbsc-select-ic mbsc-ic mbsc-ic-arrow-down5"></span>'),
        n.on('change', s._setText),
        s._setText(),
        s
      );
    }
    l(e, r);
    var t = e.prototype;
    return (
      (t.destroy = function () {
        r.prototype.destroy.call(this),
          this._$parent.find('.mbsc-select-ic').remove(),
          this._$elm.off('change', this._setText);
      }),
      (t._setText = function () {
        var e = this._elm,
          t = Te(e);
        t.is('select') &&
          !t.hasClass('mbsc-comp') &&
          this._$input.val(-1 != e.selectedIndex ? e.options[e.selectedIndex].text : ''),
          this.refresh();
      }),
      e
    );
  })(Pt);
  J('[mbsc-dropdown]', At);
  var Ut,
    Ft = ['change', 'keydown', 'input', 'scroll'];
  function Wt() {
    clearTimeout(Ut),
      (Ut = setTimeout(function () {
        Te('textarea.mbsc-control').each(function () {
          Rt(this);
        });
      }, 100));
  }
  function Rt(e) {
    var t,
      s,
      n,
      i = Te(e).attr('rows') || 6;
    e.offsetHeight &&
      ((e.style.height = ''),
      (n = e.scrollHeight - e.offsetHeight),
      (t = e.offsetHeight + (0 < n ? n : 0)),
      i < (s = Math.round(t / 24))
        ? ((t = 24 * i + (t - 24 * s)), Te(e).addClass('mbsc-textarea-scroll'))
        : Te(e).removeClass('mbsc-textarea-scroll'),
      t && (e.style.height = t + 'px'));
  }
  _ && Te(window).on('resize orientationchange', Wt);
  var Ht = (function (n) {
    function e(e, t) {
      var s;
      return (
        (s = n.call(this, e, t) || this)._$parent.addClass('mbsc-textarea'),
        Ft.forEach(function (e) {
          s._$elm.on(e, s._handle);
        }),
        Rt(e),
        s
      );
    }
    l(e, n);
    var t = e.prototype;
    return (
      (t.destroy = function () {
        var t = this;
        n.prototype.destroy.call(this),
          Ft.forEach(function (e) {
            t._$elm.off(e, t._handle);
          });
      }),
      (t.refresh = function () {
        n.prototype.refresh.call(this), clearTimeout(this._debounce), Rt(this._elm);
      }),
      (t._handle = function (e) {
        switch ((n.prototype._handle.call(this, e), e.type)) {
          case 'change':
            Rt(this._elm);
            break;
          case 'keydown':
          case 'input':
            this._onInput(e);
            break;
          case 'scroll':
            !(function (e) {
              var t = Te(e);
              if (!t.hasClass('mbsc-textarea-scroll')) {
                var s = e.scrollHeight - e.offsetHeight,
                  n = e.offsetHeight + s;
                Math.round(n / 24) <= (t.attr('rows') || 6) && ((e.scrollTop = 0), (e.style.height = n + 'px'));
              }
            })(this._elm);
        }
      }),
      (t._onInput = function () {
        var e = this;
        clearTimeout(this._debounce),
          (this._debounce = setTimeout(function () {
            Rt(e._elm);
          }, 100));
      }),
      e
    );
  })(Pt);
  J('[mbsc-textarea]', Ht);
  var zt = (function (r) {
    function e(e, t) {
      var s,
        n,
        i,
        a = (s = r.call(this, e, t) || this)._$elm,
        o = s._$parent;
      return (
        o.hasClass('mbsc-segmented-item-ready') ||
          ((n = Te('<div class="mbsc-segmented mbsc-segmented-group mbsc-no-touch"></div>')),
          o.after(n),
          o
            .parent()
            .find('input[name="' + a.attr('name') + '"]')
            .each(function () {
              var e = Te(this);
              (i = e.parent().addClass('mbsc-segmented-item mbsc-segmented-item-ready')),
                Te(
                  '<span class="mbsc-segmented-content">' +
                    (e.attr('data-icon') ? '<span class="mbsc-ic mbsc-ic-' + e.attr('data-icon') + '"></span>' : '') +
                    '</span>'
                )
                  .append(i.contents())
                  .appendTo(i),
                i.prepend(e),
                n.append(i);
            })),
        (s._$rippleElm = a.next()),
        s
      );
    }
    return (
      l(e, r),
      (e.prototype.getClassElm = function () {
        return this._$elm.closest('.mbsc-segmented');
      }),
      e
    );
  })(Vt);
  J('[mbsc-segmented]', zt);
  function Yt(t, e) {
    var n,
      i,
      a,
      s,
      o,
      r,
      l,
      c,
      p,
      u,
      d,
      h,
      m,
      f,
      b,
      v,
      g = '',
      y = this,
      _ = Te(t),
      w = f;
    function C() {
      var e;
      t.disabled || ((e = parseFloat(Te(this).val())), S(isNaN(e) ? f : e));
    }
    function x() {
      return t.disabled;
    }
    function T(e, t) {
      S(f + t * u);
    }
    function S(e, t, s) {
      (w = f),
        void 0 === t && (t = !0),
        void 0 === s && (s = t),
        (f = M(e)),
        a.removeClass('mbsc-disabled'),
        t && _.val(f),
        f == r ? i.addClass('mbsc-disabled') : f == o && n.addClass('mbsc-disabled'),
        f !== w && s && _.trigger('change');
    }
    function k(e, t, s) {
      var n = _.attr(e);
      return void 0 === n || '' === n ? t : s ? n : +n;
    }
    function M(e) {
      return +Math.min(o, Math.max(Math.round(e / u) * u, r)).toFixed(p);
    }
    Me.call(this, t, e, !0),
      (y.getVal = function () {
        var e = parseFloat(_.val());
        return M((e = isNaN(e) ? f : e));
      }),
      (y.setVal = function (e, t, s) {
        (e = parseFloat(e)), S(isNaN(e) ? f : e, t, s);
      }),
      (y._init = function () {
        (b = _.parent().hasClass('mbsc-stepper')),
          (v = b ? _.closest('.mbsc-stepper-cont') : _.parent()),
          (h = y.settings),
          (r = void 0 === e.min ? k('min', h.min) : e.min),
          (o = void 0 === e.max ? k('max', h.max) : e.max),
          (u = void 0 === e.step ? k('step', h.step) : e.step),
          (p = Math.abs(u) < 1 ? (u + '').split('.')[1].length : 0),
          (l = void 0 === e.inputStyle ? k('data-input-style', h.inputStyle, !0) : e.inputStyle),
          (s = _.attr('data-val') || h.val),
          (f = M(+t.value || 0)),
          (m = ne.themes.form[h.theme]),
          (c = m && m.addRipple ? m : null),
          b ||
            v
              .addClass('mbsc-stepper-cont mbsc-no-touch mbsc-control-w')
              .addClass('box' == l ? 'mbsc-input-box' : '')
              .addClass('outline' == l ? 'mbsc-input-outline' : '')
              .append('<span class="mbsc-segmented mbsc-stepper"></span>')
              .find('.mbsc-stepper')
              .append(
                '<span class="mbsc-segmented-item mbsc-stepper-control mbsc-stepper-minus ' +
                  (f == r ? 'mbsc-disabled' : '') +
                  '" data-step="-1" tabindex="0"><span class="mbsc-segmented-content"><span class="mbsc-ic mbsc-ic-minus"></span></span></span>'
              )
              .append(
                '<span class="mbsc-segmented-item mbsc-stepper-control mbsc-stepper-plus ' +
                  (f == o ? 'mbsc-disabled' : '') +
                  '"  data-step="1" tabindex="0"><span class="mbsc-segmented-content"> <span class="mbsc-ic mbsc-ic-plus"></span></span></span>'
              )
              .prepend(_),
          g && v.removeClass(g).find('.mbsc-segmented').removeClass(g),
          (g = 'mbsc-' + h.theme + (m.baseTheme ? ' mbsc-' + m.baseTheme : '') + (h.rtl ? ' mbsc-rtl' : ' mbsc-ltr')),
          v.addClass(g).find('.mbsc-segmented').addClass(g),
          (i = Te('.mbsc-stepper-minus', v)),
          (n = Te('.mbsc-stepper-plus', v)),
          (a = Te('.mbsc-stepper-control', v)),
          b ||
            ('left' == s
              ? (v.addClass('mbsc-stepper-val-left'),
                _.after('<span class="mbsc-segmented-item"><span class="mbsc-segmented-content"></span></span>'))
              : 'right' == s
              ? (v.addClass('mbsc-stepper-val-right'),
                n.after('<span class="mbsc-segmented-item"><span class="mbsc-segmented-content"></span></span>'))
              : i.after(
                  '<span class="mbsc-segmented-item"><span class="mbsc-segmented-content mbsc-stepper-val"></span></span>'
                )),
          d || (_.on('change', C), (d = Ye(a, T, 150, x, !1, c))),
          _.val(f).attr('data-role', 'stepper').attr('min', r).attr('max', o).attr('step', u).addClass('mbsc-control'),
          (t.mbscInst = y);
      }),
      (y._destroy = function () {
        _.removeClass('mbsc-control').off('change', C), d.destroy(), delete t.mbscInst;
      }),
      y.init();
  }
  (Yt.prototype = {
    _class: 'stepper',
    _hasDef: !0,
    _hasTheme: !0,
    _hasLang: !0,
    _defaults: { min: 0, max: 100, step: 1 }
  }),
    J('[mbsc-stepper]', (pe.Stepper = Yt));
  function Xt(t, e, s) {
    var n,
      i,
      a,
      o,
      r = this;
    Me.call(this, t, e, !0),
      (r.__init = ae),
      (r.__destroy = ae),
      (r._init = function () {
        var e;
        (o = r.settings),
          (n = Te(t)),
          (e = !!i),
          (i = (i = n.parent()).hasClass('mbsc-input-wrap') ? i.parent() : i),
          (r._$parent = i),
          a && i.removeClass(a),
          (a = r._css + ' mbsc-progress-w mbsc-control-w ' + Nt(o)),
          i.addClass(a),
          n.addClass('mbsc-control'),
          r.__init(),
          e || r._attachChange(),
          r.refresh(),
          (t.mbscInst = r);
      }),
      (r._destroy = function () {
        r.__destroy(), i.removeClass(a), n.removeClass('mbsc-control'), delete t.mbscInst;
      }),
      s || r.init();
  }
  function jt(s, e, t) {
    var n,
      i,
      a,
      l,
      o,
      r,
      c,
      p,
      u,
      d,
      h,
      m,
      f,
      b,
      v,
      g,
      y,
      _,
      w,
      C,
      x,
      T,
      S,
      k,
      M,
      O,
      E,
      N,
      V,
      D,
      P,
      I,
      $,
      L,
      A = this,
      U = new Date();
    function F(e) {
      'mousedown' === e.type && e.preventDefault(),
        !be(e, this) ||
          (p && !y) ||
          s.disabled ||
          s.readOnly ||
          (E.stopProp && e.stopPropagation(),
          (d = S = !(p = !0)),
          (V = xe(e, 'X')),
          (D = xe(e, 'Y')),
          (b = V),
          c.removeClass('mbsc-progress-anim'),
          (i = k ? Te('.mbsc-slider-handle', this) : l),
          a && a.removeClass('mbsc-handle-curr'),
          (a = i.parent().addClass('mbsc-active mbsc-handle-curr')),
          n.addClass('mbsc-active'),
          (g = +i.attr('data-index')),
          ($ = c[0].offsetWidth),
          (f = c[0].getBoundingClientRect().left),
          'mousedown' === e.type && ((_ = !0), Te(document).on('mousemove', W).on('mouseup', R)),
          'mouseenter' === e.type && ((y = !0), Te(document).on('mousemove', W)));
    }
    function W(e) {
      p &&
        ((b = xe(e, 'X')),
        (v = xe(e, 'Y')),
        (h = b - V),
        (m = v - D),
        5 < Math.abs(h) && (S = !0),
        (S || _ || y) && 50 < Math.abs(U - new Date()) && ((U = new Date()), G(b, E.round, C && (!y || _))),
        S ? e.preventDefault() : 7 < Math.abs(m) && 'touchmove' == e.type && B());
    }
    function R(e) {
      p &&
        (e.preventDefault(),
        k || c.addClass('mbsc-progress-anim'),
        y && !_ ? K(L[g], g, !1, !1, !0) : G(b, !0, !0),
        S || d || ('touchend' == e.type && re(), A._onTap(L[g])),
        'mouseup' == e.type && (_ = !1),
        'mouseleave' == e.type && (y = !1),
        y || B());
    }
    function H() {
      p && B();
    }
    function z() {
      var e = A._readValue(Te(this)),
        t = +Te(this).attr('data-index');
      e !== L[t] && ((L[t] = e), K((M[t] = e), t));
    }
    function Y(e) {
      e.stopPropagation();
    }
    function X(e) {
      e.preventDefault();
    }
    function j(e) {
      var t;
      if (!s.disabled) {
        switch (e.keyCode) {
          case 38:
          case 39:
            t = 1;
            break;
          case 40:
          case 37:
            t = -1;
        }
        t &&
          (e.preventDefault(),
          I ||
            ((g = +Te(this).attr('data-index')),
            K(L[g] + O * t, g, !0),
            (I = setInterval(function () {
              K(L[g] + O * t, g, !0);
            }, 200))));
      }
    }
    function q(e) {
      e.preventDefault(), clearInterval(I), (I = null);
    }
    function B() {
      (p = !1),
        a.removeClass('mbsc-active'),
        n.removeClass('mbsc-active'),
        Te(document).off('mousemove', W).off('mouseup', R);
    }
    function G(e, t, s) {
      var n = t
        ? Math.min(
            (Math[A._rounding || 'round'](Math.max((100 * (e - f)) / $, 0) / N / O) * O * 100) / (x - T + u),
            100
          )
        : Math.max(0, Math.min((100 * (e - f)) / $, 100));
      w && (n = 100 - n), K(Math.round((T - u + n / N) * P) / P, g, s, n);
    }
    function K(e, t, s, n, i, a) {
      var o = l.eq(t),
        r = o.parent();
      (e = Math.min(x, Math.max(e, T))),
        void 0 === a && (a = s),
        A._update
          ? (e = A._update(e, L, t, n, k, i, r))
          : r.css({ left: w ? 'auto' : (n || Z(e, T, x)) + '%', right: w ? (n || Z(e, T, x)) + '%' : 'auto' }),
        T < e ? r.removeClass('mbsc-slider-start') : (L[t] > T || i) && r.addClass('mbsc-slider-start'),
        s && (L[t] = e),
        s && M[t] != e && ((d = !0), (M[t] = e), A._fillValue(e, t, a)),
        o.attr('aria-valuenow', e);
    }
    Xt.call(this, s, e, !0),
      (A._onTap = ae),
      (A.___init = ae),
      (A.___destroy = ae),
      (A._attachChange = function () {
        n.on(E.changeEvent, z);
      }),
      (A.__init = function () {
        var e;
        l && ((e = !0), l.parent().remove()),
          A.___init(),
          (r = A._$parent),
          (c = A._$track),
          (n = r.find('input')),
          (E = A.settings),
          (T = A._min),
          (x = A._max),
          (u = A._base || 0),
          (O = A._step),
          (C = A._live),
          (P = O % 1 != 0 ? 100 / (100 * (O % 1).toFixed(2)) : 1),
          (N = 100 / (x - T + u) || 100),
          (k = 1 < n.length),
          (w = E.rtl),
          (L = []),
          (M = []),
          n.each(function (e) {
            (L[e] = A._readValue(Te(this))), Te(this).attr('data-index', e);
          }),
          (l = r.find('.mbsc-slider-handle')),
          (o = r.find(k ? '.mbsc-slider-handle-cont' : '.mbsc-progress-cont')),
          l.on('keydown', j).on('keyup', q).on('blur', q),
          o.each(function (e, t) {
            ge(t, 'touchstart', F, { passive: !0 }),
              ge(t, 'mousedown', F),
              ge(t, 'touchend', R),
              ge(t, 'touchcancel', R),
              ge(t, 'pointercancel', H),
              E.hover && (ge(t, 'mouseenter', F), ge(t, 'mouseleave', R));
          }),
          e || (n.on('click', Y), r.on('click', X), ge(document, 'touchmove', W, { passive: !1 }));
      }),
      (A.__destroy = function () {
        r.off('click', X),
          n.off(E.changeEvent, z).off('click', Y),
          l.off('keydown', j).off('keyup', q).off('blur', q),
          o.each(function (e, t) {
            ye(t, 'touchstart', F, { passive: !0 }),
              ye(t, 'mousedown', F),
              ye(t, 'touchend', R),
              ye(t, 'touchcancel', R),
              ye(t, 'pointercancel', H),
              ye(t, 'mouseenter', F),
              ye(t, 'mouseleave', R),
              ye(document, 'touchmove', W, { passive: !1 });
          }),
          A.___destroy();
      }),
      (A.refresh = function () {
        n.each(function (e) {
          K(A._readValue(Te(this)), e, !0, !1, !0, !1);
        });
      }),
      (A.getVal = function () {
        return k ? L.slice(0) : L[0];
      }),
      (A.setVal = A._setVal =
        function (e, t, s) {
          Te.isArray(e) || (e = [e]),
            Te.each(e, function (e, t) {
              L[e] = t;
            }),
            Te.each(e, function (e, t) {
              K(t, e, !0, !1, !0, s);
            });
        }),
      t || A.init();
  }
  function qt(e, t) {
    var n,
      s,
      i,
      a,
      o = this;
    ke((t = t || {}), { changeEvent: 'click', round: !1 }),
      jt.call(this, e, t, !0),
      (o._readValue = function () {
        return e.checked ? 1 : 0;
      }),
      (o._fillValue = function (e, t, s) {
        n.prop('checked', !!e), s && n.trigger('change');
      }),
      (o._onTap = function (e) {
        o._setVal(e ? 0 : 1);
      }),
      (o.___init = function () {
        (i = o.settings),
          (n = Te(e)),
          (s = n.parent()).find('.mbsc-switch-track').remove(),
          s.prepend(n),
          n
            .attr('data-role', 'switch')
            .after(
              '<span class="mbsc-progress-cont mbsc-switch-track"><span class="mbsc-progress-track mbsc-progress-anim"><span class="mbsc-slider-handle-cont"><span class="mbsc-slider-handle mbsc-switch-handle" data-index="0"><span class="mbsc-switch-txt-off">' +
                i.offText +
                '</span><span class="mbsc-switch-txt-on">' +
                i.onText +
                '</span></span></span></span></span>'
            ),
          a && a.destroy(),
          (a = new Vt(e, i)),
          (o._$track = s.find('.mbsc-progress-track')),
          (o._min = 0),
          (o._max = 1),
          (o._step = 1);
      }),
      (o.___destroy = function () {
        a.destroy();
      }),
      (o.getVal = function () {
        return e.checked;
      }),
      (o.setVal = function (e, t, s) {
        o._setVal(e ? 1 : 0, t, s);
      }),
      o.init();
  }
  (qt.prototype = {
    _class: 'switch',
    _css: 'mbsc-switch',
    _hasTheme: !0,
    _hasLang: !0,
    _hasDef: !0,
    _defaults: { stopProp: !0, offText: 'Off', onText: 'On' }
  }),
    J('[mbsc-switch]', (pe.Switch = qt));
  function Bt(i, a, e) {
    var o,
      r,
      l,
      c,
      p,
      u,
      d,
      h,
      m,
      f,
      b,
      v,
      g,
      t,
      y = this;
    function s() {
      var e = _('value', d);
      e !== g && n(e);
    }
    function _(e, t, s) {
      var n = r.attr(e);
      return void 0 === n || '' === n ? t : s ? n : +n;
    }
    function n(e, t, s, n) {
      (e = Math.min(h, Math.max(e, d))),
        c.css('width', (100 * (e - d)) / (h - d) + '%'),
        void 0 === s && (s = !0),
        void 0 === n && (n = s),
        (e === g && !t) || y._display(e),
        e !== g && ((g = e), s && r.attr('value', g), n && r.trigger('change'));
    }
    Xt.call(this, i, a, !0),
      (y._display = function (e) {
        (t = v && b.returnAffix ? v.replace(/\{value\}/, e).replace(/\{max\}/, h) : e), p && p.html(t), o && o.html(t);
      }),
      (y._attachChange = function () {
        r.on('change', s);
      }),
      (y.__init = function () {
        var e, t, s, n;
        if (
          ((b = y.settings),
          (r = Te(i)),
          (n = !!l),
          (l = y._$parent),
          (d = y._min = void 0 === a.min ? _('min', b.min) : a.min),
          (h = y._max = void 0 === a.max ? _('max', b.max) : a.max),
          (m = void 0 === a.inputStyle ? _('data-input-style', b.inputStyle, !0) : a.inputStyle),
          (f = void 0 === a.labelStyle ? _('data-label-style', b.labelStyle, !0) : a.labelStyle),
          (g = _('value', d)),
          (e = r.attr('data-val') || b.val),
          (s = (s = r.attr('data-step-labels')) ? JSON.parse(s) : b.stepLabels),
          (v = r.attr('data-template') || (100 != h || b.template ? b.template : '{value}%')),
          n
            ? (e && (o.remove(), l.removeClass('mbsc-progress-value-' + ('right' == e ? 'right' : 'left'))),
              s && Te('.mbsc-progress-step-label', u).remove())
            : (Mt(l, null, m, f, i),
              kt(r),
              l
                .find('.mbsc-input-wrap')
                .append(
                  '<span class="mbsc-progress-cont"><span class="mbsc-progress-track mbsc-progress-anim"><span class="mbsc-progress-bar"></span></span></span>'
                ),
              (c = y._$progress = l.find('.mbsc-progress-bar')),
              (u = y._$track = l.find('.mbsc-progress-track'))),
          r.attr('min', d).attr('max', h),
          e &&
            ((o = Te('<span class="mbsc-progress-value"></span>')),
            l
              .addClass('mbsc-progress-value-' + ('right' == e ? 'right' : 'left'))
              .find('.mbsc-input-wrap')
              .append(o)),
          s)
        )
          for (t = 0; t < s.length; ++t)
            u.append(
              '<span class="mbsc-progress-step-label" style="' +
                (b.rtl ? 'right' : 'left') +
                ': ' +
                (100 * (s[t] - d)) / (h - d) +
                '%" >' +
                s[t] +
                '</span>'
            );
        p = Te(r.attr('data-target') || b.target);
      }),
      (y.__destroy = function () {
        l.removeClass('mbsc-ic-left mbsc-ic-right').find('.mbsc-progress-cont').remove(),
          l.find('.mbsc-input-ic').remove(),
          r.off('change', s);
      }),
      (y.refresh = function () {
        n(_('value', d), !0, !1);
      }),
      (y.getVal = function () {
        return g;
      }),
      (y.setVal = function (e, t, s) {
        n(e, !0, t, s);
      }),
      e || y.init();
  }
  (Bt.prototype = {
    _class: 'progress',
    _css: 'mbsc-progress',
    _hasTheme: !0,
    _hasLang: !0,
    _hasDef: !0,
    _defaults: { min: 0, max: 100, returnAffix: !0 }
  }),
    J('[mbsc-progress]', (pe.Progress = Bt));
  function Gt(e, t, s) {
    var n,
      i,
      r,
      l,
      a,
      c,
      p,
      u,
      d,
      h,
      m,
      o,
      f,
      b = this;
    Bt.call(this, e, t, !0);
    var v = b.__init,
      g = b.__destroy;
    jt.call(this, e, t, !0);
    var y = b.__init,
      _ = b.__destroy;
    (b.__init = function () {
      v(), y();
    }),
      (b.__destroy = function () {
        g(), _();
      }),
      (b._update = function (e, t, s, n, i, a, o) {
        return (
          u
            ? 0 === s
              ? ((e = Math.min(e, t[1])),
                r.css({
                  width: Z(t[1], m, h) - Z(e, m, h) + '%',
                  left: d ? 'auto' : Z(e, m, h) + '%',
                  right: d ? Z(e, m, h) + '%' : 'auto'
                }))
              : ((e = Math.max(e, t[0])), r.css({ width: Z(e, m, h) - Z(t[0], m, h) + '%' }))
            : i || !c
            ? o.css({ left: d ? 'auto' : (n || Z(e, m, h)) + '%', right: d ? (n || Z(e, m, h)) + '%' : 'auto' })
            : r.css('width', (n || Z(e, m, h)) + '%'),
          p && l.eq(s).html(e),
          i || (t[s] == e && !a) || b._display(e),
          e
        );
      }),
      (b._readValue = function (e) {
        return +e.val();
      }),
      (b._fillValue = function (e, t, s) {
        n.eq(t).val(e), s && n.eq(t).trigger('change');
      }),
      (b._markupReady = function () {
        var e, t;
        if ((p && i.addClass('mbsc-slider-has-tooltip'), 1 != o))
          for (t = (h - m) / o, e = 0; e <= t; ++e)
            a.append(
              '<span class="mbsc-slider-step" style="' + (d ? 'right' : 'left') + ':' + (100 / t) * e + '%"></span>'
            );
        n.each(function (e, t) {
          var s = +t.value;
          'range' == t.type && Te(t).attr('min', m).attr('max', h).attr('step', o),
            (c ? r : a).append(
              '<span class="mbsc-slider-handle-cont' +
                (u && !e ? ' mbsc-slider-handle-left' : '') +
                (u && !e && (h - m) / 2 < s ? ' mbsc-handle-curr' : '') +
                '"><span tabindex="0" class="mbsc-slider-handle" aria-valuemin="' +
                m +
                '" aria-valuemax="' +
                h +
                '" data-index="' +
                e +
                '"></span>' +
                (p ? '<span class="mbsc-slider-tooltip"></span>' : '') +
                '</span>'
            );
        }),
          (l = i.find('.mbsc-slider-tooltip'));
      }),
      (b.___init = function () {
        i && (i.removeClass('mbsc-slider-has-tooltip'), 1 != o && Te('.mbsc-slider-step', a).remove()),
          (i = b._$parent),
          (a = b._$track),
          (r = b._$progress),
          (n = i.find('input')),
          (f = b.settings),
          (m = b._min),
          (h = b._max),
          (b._step = o = void 0 === t.step ? +n.attr('step') || f.step : t.step),
          (b._live = z('data-live', f.live, n)),
          (p = z('data-tooltip', f.tooltip, n)),
          (c = z('data-highlight', f.highlight, n) && n.length < 3),
          (u = c && 2 == n.length),
          (d = f.rtl),
          b._markupReady();
      }),
      s || b.init();
  }
  (Gt.prototype = {
    _class: 'progress',
    _css: 'mbsc-progress mbsc-slider',
    _hasTheme: !0,
    _hasLang: !0,
    _hasDef: !0,
    _defaults: {
      changeEvent: 'change',
      stopProp: !0,
      min: 0,
      max: 100,
      step: 1,
      live: !0,
      highlight: !0,
      round: !0,
      returnAffix: !0
    }
  }),
    J('[mbsc-slider]', (pe.Slider = Gt));
  function Kt(e, t, s) {
    var o,
      n,
      r,
      i,
      a,
      l,
      c,
      p = this,
      u = Te(e);
    Gt.call(this, e, t, !0),
      (p._update = function (e, t, s, n, i, a) {
        return o.css('width', Z(e, 0, r) + '%'), i || (t[s] == e && !a) || p._display(e), e;
      }),
      (p._markupReady = function () {
        var e,
          t = '',
          s = '';
        for (
          n = p._$track,
            o = p._$progress,
            c = p.settings,
            i = p._min,
            r = p._max,
            p._base = i,
            p._rounding = c.rtl ? 'floor' : 'ceil',
            a = u.attr('data-empty') || c.empty,
            l = u.attr('data-filled') || c.filled,
            e = 0;
          e < r;
          ++e
        )
          (t += '<span class="mbsc-ic mbsc-ic-' + a + '"></span>'),
            (s += '<span class="mbsc-ic mbsc-ic-' + l + '"></span>');
        n.html(t),
          n.append(o),
          o.html(s),
          n.append(
            '<span class="mbsc-rating-handle-cont"><span tabindex="0" class="mbsc-slider-handle" aria-valuemin="' +
              i +
              '" aria-valuemax="' +
              r +
              '" data-index="0"></span></span>'
          );
      }),
      s || p.init();
  }
  (Kt.prototype = {
    _class: 'progress',
    _css: 'mbsc-progress mbsc-rating',
    _hasTheme: !0,
    _hasLang: !0,
    _hasDef: !0,
    _defaults: {
      changeEvent: 'change',
      stopProp: !0,
      min: 1,
      max: 5,
      step: 1,
      live: !0,
      round: !0,
      hover: !0,
      highlight: !0,
      returnAffix: !0,
      empty: 'star',
      filled: 'star3'
    }
  }),
    J('[mbsc-rating]', (pe.Rating = Kt));
  var Zt = 1,
    Jt = (function () {
      function e(e, t) {
        var s,
          n,
          i,
          a = this,
          o = Te(e);
        if (
          ((this.settings = t),
          (this._isOpen = t.isOpen || !1),
          o.addClass('mbsc-collapsible ' + (this._isOpen ? 'mbsc-collapsible-open' : '')),
          (s = (i = o.hasClass('mbsc-card')
            ? ((n = o.find('.mbsc-card-header').eq(0).addClass('mbsc-collapsible-header')),
              o.find('.mbsc-card-content').eq(0).addClass('mbsc-collapsible-content'))
            : o.hasClass('mbsc-form-group') || o.hasClass('mbsc-form-group-inset')
            ? ((n = o.find('.mbsc-form-group-title').eq(0).addClass('mbsc-collapsible-header')),
              o.find('.mbsc-form-group-content').eq(0).addClass('mbsc-collapsible-content'))
            : ((n = o.find('.mbsc-collapsible-header').eq(0)), o.find('.mbsc-collapsible-content').eq(0)))[0]) &&
            !s.id &&
            (s.id = 'mbsc-collapsible-' + Zt++),
          n.length && s)
        ) {
          var r = Te('<span class="mbsc-collapsible-icon mbsc-ic mbsc-ic-arrow-down5"></span>');
          B(this, n, function () {
            a.collapse();
          }),
            n
              .attr('role', 'button')
              .attr('aria-expanded', this._isOpen)
              .attr('aria-controls', s.id)
              .attr('tabindex', '0')
              .on('mousedown', this.onMouseDown)
              .on('keydown', this.onKeyDown)
              .append(r);
        }
        ((e.mbscInst = this)._$header = n),
          (this._$content = i),
          (this._$elm = o),
          (this._$accordionParent = o.parent('[mbsc-accordion], mbsc-accordion, .mbsc-accordion')),
          (this.show = this.show.bind(this)),
          (this.hide = this.hide.bind(this)),
          (this.toggle = this.toggle.bind(this)),
          (this.onKeyDown = this.onKeyDown.bind(this)),
          (this.onMouseDown = this.onMouseDown.bind(this));
      }
      var t = e.prototype;
      return (
        (t.collapse = function (e) {
          var t = this._$elm,
            s = this._$content;
          void 0 === e && (e = !this._isOpen),
            (e && this._isOpen) ||
              (!e && !this._isOpen) ||
              !s.length ||
              (e
                ? (p &&
                    s
                      .on('transitionend', function e() {
                        s.off('transitionend', e).css('height', '');
                      })
                      .css('height', s[0].scrollHeight),
                  t.addClass('mbsc-collapsible-open'))
                : (p && s.css('height', getComputedStyle(s[0]).height),
                  setTimeout(function () {
                    s.css('height', 0), t.removeClass('mbsc-collapsible-open');
                  }, 50)),
              e &&
                this._$accordionParent &&
                this._$accordionParent.find('.mbsc-collapsible-open').each(function () {
                  this !== t[0] && this.mbscInst.hide();
                }),
              (this._isOpen = e),
              this._$header.attr('aria-expanded', this._isOpen));
        }),
        (t.show = function () {
          this.collapse(!0);
        }),
        (t.hide = function () {
          this.collapse(!1);
        }),
        (t.toggle = function () {
          this.collapse();
        }),
        (t.destroy = function () {
          this._$elm.removeClass('mbsc-collapsible mbsc-collapsible-open'),
            this._$content.removeClass('mbsc-collapsible-content'),
            this._$header
              .removeClass('mbsc-collapsible-header')
              .off('mousedown', this.onMouseDown)
              .off('keydown', this.onKeyDown)
              .find('.mbsc-collapsible-icon')
              .remove(),
            le(this._$header);
        }),
        (t.onKeyDown = function (e) {
          (32 !== e.which && 13 != e.keyCode) || (e.preventDefault(), this.collapse());
        }),
        (t.onMouseDown = function (e) {
          e.preventDefault();
        }),
        e
      );
    })();
  pe.CollapsibleBase = Jt;
  var Qt = 0;
  var es = function (e, t, s) {
    function n(e) {
      Te('.mbsc-fr-c', e).hasClass('mbsc-wdg-c') ||
        (Te('.mbsc-fr-c', e).addClass('mbsc-wdg-c').append(o.show()),
        Te('.mbsc-w-p', e).length || Te('.mbsc-fr-c', e).addClass('mbsc-w-p'));
    }
    var i,
      a,
      o = Te(e),
      r = this;
    Ze.call(this, e, t, !0),
      (r._generateContent = function () {
        return '';
      }),
      (r._markupReady = function (e) {
        'inline' != i.display && n(e);
      }),
      (r._markupInserted = function (e) {
        'inline' == i.display && n(e), e.trigger('mbsc-enhance', [{ theme: i.theme, lang: i.lang }]);
      }),
      (r._markupRemove = function () {
        o.hide(), a && a.parent().length && a.after(o);
      }),
      (r.__processSettings = function () {
        (i = r.settings),
          (r.buttons.ok = { text: i.okText, icon: i.okIcon, handler: 'set' }),
          (i.buttons = i.buttons || ('inline' == i.display ? [] : ['ok'])),
          !a && o.parent().length && ((a = Te(document.createComment('popup'))), o.before(a)),
          o.hide();
      }),
      s || r.init();
  };
  (es.prototype = {
    _hasDef: !0,
    _hasTheme: !0,
    _hasContent: !0,
    _hasLang: !0,
    _responsive: !0,
    _class: 'popup',
    _defaults: ke({}, Ze.prototype._defaults, { compClass: 'mbsc-wdg', okText: 'OK', headerText: !1 })
  }),
    (pe.Popup = es),
    (pe.Widget = es),
    (ne.themes.popup = ne.themes.frame);
  var ts,
    ss = _ && !!window.Promise,
    ns = [],
    is = [];
  function as(e) {
    ns.length || e.show(), ns.push(e);
  }
  function os(e, s, n, t) {
    return ke(
      {
        display: s.display || 'center',
        cssClass: 'mbsc-alert',
        okText: s.okText,
        cancelText: s.cancelText,
        context: s.context,
        theme: s.theme,
        closeOnOverlayTap: !1,
        onBeforeClose: function () {
          e.shift();
        },
        onHide: function (e, t) {
          n && n(t._resolve),
            s.callback && s.callback(t._resolve),
            t && t.destroy(),
            ns.length ? ns[0].show() : is.length && is[0].show(!1, !0);
        }
      },
      t
    );
  }
  function rs(e) {
    return (e.title ? '<h2>' + e.title + '</h2>' : '') + '<p>' + (e.message || '') + '</p>';
  }
  function ls(e, t, s) {
    as(new es(e, os(ns, t, s)));
  }
  function cs(e, t, s) {
    var n = new es(
      e,
      os(ns, t, s, {
        buttons: ['cancel', 'ok'],
        onSet: function () {
          n._resolve = !0;
        }
      })
    );
    (n._resolve = !1), as(n);
  }
  function ps(e, t, s) {
    var n,
      i = new es(
        e,
        os(ns, t, s, {
          buttons: ['cancel', 'ok'],
          onMarkupReady: function (e, t) {
            var s = t.settings;
            t._markup.find('label').addClass('mbsc-' + s.theme + (s.baseTheme ? ' mbsc-' + s.baseTheme : '')),
              (n = t._markup.find('input')[0]),
              setTimeout(function () {
                n.focus(), n.setSelectionRange(0, n.value.length);
              }, 300);
          },
          onSet: function () {
            i._resolve = n.value;
          }
        })
      );
    (i._resolve = null), as(i);
  }
  function us(e, s, t, n, i) {
    var a,
      o,
      r,
      l = new es(
        e,
        os(is, s, t, {
          display: s.display || 'bottom',
          animate: i,
          cssClass: (n || 'mbsc-snackbar') + (s.color ? ' mbsc-' + s.color : ''),
          scrollLock: !1,
          focusTrap: !1,
          buttons: [],
          onMarkupReady: function (e, t) {
            var s = t.settings;
            t._markup.find('button').addClass('mbsc-' + s.theme + (s.baseTheme ? ' mbsc-' + s.baseTheme : ''));
          },
          onShow: function (e, t) {
            (ts = t),
              !1 !== s.duration &&
                (a = setTimeout(function () {
                  t && t.hide();
                }, s.duration || 3e3)),
              s.button &&
                t.tap(Te('.mbsc-snackbar-btn', e.target), function () {
                  t.hide(), s.button.action && s.button.action.call(this);
                });
          },
          onClose: function () {
            (ts = null), clearTimeout(a);
          }
        })
      );
    (o = l), (r = is.length), is.push(o), ns.length || (r ? is[0].hide() : o.show(!1, !0));
  }
  function ds(e, t, s) {
    us(e, t, s, 'mbsc-toast', 'fade');
  }
  function hs(t, s, n) {
    var e;
    return (
      ss
        ? (e = new Promise(function (e) {
            t(s, n, e);
          }))
        : t(s, n),
      e
    );
  }
  (ne.alert = function (e) {
    var t = document.createElement('div');
    return (t.innerHTML = rs(e)), hs(ls, t, e);
  }),
    (ne.confirm = function (e) {
      var t = document.createElement('div');
      return (t.innerHTML = rs(e)), hs(cs, t, e);
    }),
    (ne.prompt = function (e) {
      var t = document.createElement('div');
      return (
        (t.innerHTML =
          rs(e) +
          '<label class="mbsc-input">' +
          (e.label ? '<span class="mbsc-label">' + e.label + '</span>' : '') +
          '<input class="mbsc-control" tabindex="0" type="' +
          (e.inputType || 'text') +
          '" placeholder="' +
          (e.placeholder || '') +
          '" value="' +
          (e.value || '') +
          '"></label>'),
        hs(ps, t, e)
      );
    }),
    (ne.snackbar = function (e) {
      var t = document.createElement('div'),
        s = e.button;
      return (
        (t.innerHTML =
          '<div class="mbsc-snackbar-cont"><div class="mbsc-snackbar-msg">' +
          (e.message || '') +
          '</div>' +
          (s
            ? '<button class="mbsc-snackbar-btn mbsc-btn mbsc-btn-flat">' +
              (s.icon
                ? '<span class="mbsc-ic ' + (s.text ? 'mbsc-btn-ic ' : '') + 'mbsc-ic-' + s.icon + '"></span>'
                : '') +
              (s.text || '') +
              '</button>'
            : '') +
          '</div>'),
        hs(us, t, e)
      );
    }),
    (ne.toast = function (e) {
      var t = document.createElement('div');
      return (t.innerHTML = '<div class="mbsc-toast-msg">' + (e.message || '') + '</div>'), hs(ds, t, e);
    }),
    (ne.notification = {
      dismiss: function () {
        ts && ts.hide();
      }
    });
  function ms(e, t) {
    var a,
      s = '',
      o = Te(e),
      r = {},
      n = this;
    function i() {
      o.removeClass('mbsc-no-touch');
    }
    Me.call(this, e, t, !0),
      (n.refresh = function (e) {
        var t, n, i, s;
        a.enhance &&
          ((n = r),
          (i = a),
          (s = e),
          Te('input,select,textarea,progress,button', (t = o)).each(function () {
            var e = this,
              t = Te(e),
              s = q(t);
            if ('false' != t.attr('data-enhance'))
              if (t.hasClass('mbsc-control'))
                e.mbscInst &&
                  e.mbscInst.option({
                    theme: i.theme,
                    lang: i.lang,
                    rtl: i.rtl,
                    onText: i.onText,
                    offText: i.offText,
                    stopProp: i.stopProp
                  });
              else
                switch ((e.id || (e.id = 'mbsc-form-control-' + ++Qt), s)) {
                  case 'button':
                  case 'submit':
                    n[e.id] = new It(e, { theme: i.theme, rtl: i.rtl, tap: i.tap });
                    break;
                  case 'switch':
                    n[e.id] = new qt(e, {
                      theme: i.theme,
                      lang: i.lang,
                      rtl: i.rtl,
                      tap: i.tap,
                      onText: i.onText,
                      offText: i.offText,
                      stopProp: i.stopProp
                    });
                    break;
                  case 'checkbox':
                    n[e.id] = new $t(e, { tap: i.tap, theme: i.theme, rtl: i.rtl });
                    break;
                  case 'range':
                    Te(e).parent().hasClass('mbsc-slider') ||
                      (n[e.id] = new Gt(e, {
                        theme: i.theme,
                        lang: i.lang,
                        rtl: i.rtl,
                        stopProp: i.stopProp,
                        labelStyle: i.labelStyle
                      }));
                    break;
                  case 'rating':
                    n[e.id] = new Kt(e, { theme: i.theme, lang: i.lang, rtl: i.rtl, stopProp: i.stopProp });
                    break;
                  case 'progress':
                    n[e.id] = new Bt(e, { theme: i.theme, lang: i.lang, rtl: i.rtl, labelStyle: i.labelStyle });
                    break;
                  case 'radio':
                    n[e.id] = new Lt(e, { tap: i.tap, theme: i.theme, rtl: i.rtl });
                    break;
                  case 'select':
                  case 'select-one':
                  case 'select-multiple':
                    n[e.id] = new At(e, {
                      tap: i.tap,
                      inputStyle: i.inputStyle,
                      labelStyle: i.labelStyle,
                      theme: i.theme,
                      rtl: i.rtl
                    });
                    break;
                  case 'textarea':
                    n[e.id] = new Ht(e, {
                      tap: i.tap,
                      inputStyle: i.inputStyle,
                      labelStyle: i.labelStyle,
                      theme: i.theme,
                      rtl: i.rtl
                    });
                    break;
                  case 'segmented':
                    n[e.id] = new zt(e, { theme: i.theme, rtl: i.rtl, tap: i.tap, inputStyle: i.inputStyle });
                    break;
                  case 'stepper':
                    n[e.id] = new Yt(e, { theme: i.theme, rtl: i.rtl });
                    break;
                  case 'hidden':
                    return;
                  default:
                    n[e.id] = new Pt(e, {
                      tap: i.tap,
                      inputStyle: i.inputStyle,
                      labelStyle: i.labelStyle,
                      theme: i.theme,
                      rtl: i.rtl
                    });
                }
          }),
          Te('[data-collapsible]:not(.mbsc-collapsible)', t).each(function () {
            var e = this,
              t = Te(e).attr('data-open');
            e.id || (e.id = 'mbsc-form-control-' + ++Qt),
              (n[e.id] = new Jt(e, { isOpen: void 0 !== t && 'false' != t })),
              (ce[e.id] = n[e.id]);
          }),
          s || Wt());
      }),
      (n._init = function () {
        ne.themes.form[a.theme] || (a.theme = 'mobiscroll'),
          o.hasClass('mbsc-form') || (o.show(), ge(o[0], 'touchstart', i, { passive: !0 })),
          s && o.removeClass(s),
          (s =
            'mbsc-form mbsc-no-touch mbsc-' +
            a.theme +
            (fs ? ' mbsc-form-hb' : '') +
            (a.baseTheme ? ' mbsc-' + a.baseTheme : '') +
            (a.rtl ? ' mbsc-rtl' : ' mbsc-ltr') +
            ('box' == a.inputStyle ? ' mbsc-form-box' : '') +
            ('outline' == a.inputStyle ? ' mbsc-form-outline' : '')),
          o.addClass(s).removeClass('mbsc-cloak'),
          n.refresh();
      }),
      (n._destroy = function () {
        for (var e in (o.removeClass(s), ye(o[0], 'touchstart', i, { passive: !0 }), r)) r[e].destroy();
      }),
      (n.controls = r),
      (a = n.settings),
      n.init();
  }
  var fs = 'ios' == t && 7 < n;
  (ms.prototype = {
    _hasDef: !0,
    _hasTheme: !0,
    _hasLang: !0,
    _class: 'form',
    _defaults: { tap: c, stopProp: !0, rtl: !1, enhance: !0 }
  }),
    J('[mbsc-enhance],[mbsc-form]', (pe.Form = ms), !0);
  function bs(e, t) {
    var a = '',
      o = Te(e),
      s = this,
      r = s.settings;
    Me.call(this, e, t, !0),
      (s._init = function () {
        var e = r.context,
          t = Te(e),
          s = t.find('.mbsc-ms-top .mbsc-ms'),
          n = t.find('.mbsc-ms-bottom .mbsc-ms'),
          i = {};
        'body' == e ? Te('body,html').addClass('mbsc-page-ctx') : t.addClass('mbsc-page-ctx'),
          a && o.removeClass(a),
          s.length && (i.paddingTop = s[0].offsetHeight),
          n.length && (i.paddingBottom = n[0].offsetHeight),
          (a =
            'mbsc-page mbsc-' +
            r.theme +
            (r.baseTheme ? ' mbsc-' + r.baseTheme : '') +
            (r.rtl ? ' mbsc-rtl' : ' mbsc-ltr')),
          o.addClass(a).removeClass('mbsc-cloak').css(i);
      }),
      (s._destroy = function () {
        o.removeClass(a);
      }),
      (r = s.settings),
      s.init();
  }
  (bs.prototype = { _hasDef: !0, _hasTheme: !0, _hasLang: !0, _class: 'page', _defaults: { context: 'body' } }),
    (pe.Page = bs),
    (ne.themes.page.mobiscroll = {}),
    J('[mbsc-page]', bs);
  var vs = (function (t) {
    function e(e) {
      return t.call(this, e) || this;
    }
    l(e, t);
    var s = e.prototype;
    return (
      (s.componentDidMount = function () {
        var e = this.getSettingsFromProps(this.props);
        this.instance = new bs(a.findDOMNode(this), e);
      }),
      (s.shouldComponentUpdate = function (e) {
        var t = !Fe(this.getSettingsFromProps(this.props), this.getSettingsFromProps(e));
        return (this.optimizeUpdate = { updateOptions: t }), !0;
      }),
      (s.render = function () {
        return v.createElement('div', { className: this.initialCssClass }, this.props.children);
      }),
      e
    );
  })(Re);
  vs.propTypes = g({}, vs.propTypes, {}, $e, { onInit: e.func });
  var gs = (function (t) {
    function e(e) {
      return t.call(this, e) || this;
    }
    return (
      l(e, t),
      (e.prototype.render = function () {
        var e = 'mbsc-note mbsc-note-' + this.props.color;
        return v.createElement('div', { className: e }, this.props.children);
      }),
      e
    );
  })(v.Component);
  o(gs, 'propTypes', { color: e.string }), o(gs, 'defaultProps', { color: 'primary' });
  var ys = (function (t) {
    function e(e) {
      return t.call(this, e) || this;
    }
    return (
      l(e, t),
      (e.prototype.render = function () {
        return v.createElement('img', { className: 'mbsc-avatar', src: this.props.src, alt: this.props.alt });
      }),
      e
    );
  })(v.Component);
  (ne.Page = vs), (ne.Note = gs), (ne.Avatar = ys);
  var _s = e.number,
    ws = e.string,
    Cs = e.func,
    xs = e.bool,
    Ts = {
      onInit: Cs,
      onChange: Cs,
      value: _s,
      disabled: xs,
      min: _s,
      max: _s,
      step: _s,
      val: e.oneOf(['left', 'right'])
    },
    Ss = { onInit: Cs, onChange: Cs, checked: xs, disabled: xs, value: xs },
    ks = (function (t) {
      function e(e) {
        return t.call(this, e) || this;
      }
      l(e, t);
      var s = e.prototype;
      return (
        (s.componentDidMount = function () {
          var e = this.getSettingsFromProps(this.props);
          this.instance = new ms(a.findDOMNode(this), e);
        }),
        (s.componentDidUpdate = function () {
          if (!this.optimizeUpdate.updateOptions && this.optimizeUpdate.updateChildren) this.instance.refresh(!0);
          else if (this.optimizeUpdate.updateOptions) {
            var e = this.getSettingsFromProps(this.props);
            this.instance.option(e);
          }
        }),
        (s.checkFormWrapper = function (e) {
          return 1 == v.Children.count(e.props.children) && 'form' == e.props.children.type;
        }),
        (s.render = function () {
          var e = this.props,
            t = e.action,
            s = e.method,
            n = e.noValidate,
            i = e.renderForm,
            a = e.name,
            o = e.target,
            r = e.autoComplete,
            l = e.onSubmit;
          return this.checkFormWrapper(this) || !i
            ? this.props.children
            : v.createElement(
                'form',
                {
                  className: this.initialCssClass,
                  action: t,
                  name: a,
                  target: o,
                  method: s,
                  autoComplete: r,
                  noValidate: n,
                  onSubmit: l
                },
                this.props.children
              );
        }),
        e
      );
    })(He);
  o(ks, 'defaultProps', { renderForm: !0 }),
    o(ks, 'propTypes', g({}, $e, { enhance: xs, labelStyle: ws, inputStyle: ws, onInit: Cs })),
    (ne.Form = ks);
  var Ms = (function (t) {
    function e(e) {
      return t.call(this, e) || this;
    }
    l(e, t);
    var s = e.prototype;
    return (
      (s.componentDidMount = function () {
        Ue.call(this, '', this.getClasses(this.props));
      }),
      (s.componentDidUpdate = function () {
        this.cssClassUpdate && Ue.call(this, this.cssClassUpdate.prev, this.cssClassUpdate.next);
      }),
      (s.shouldComponentUpdate = function (e) {
        var t = this.getClasses(e),
          s = this.getClasses(this.props);
        return (this.cssClassUpdate = s !== t ? { prev: s, next: t } : null), !Fe(this.props, e);
      }),
      (s.getClasses = function (e) {
        var t = e.valid,
          s = e.className,
          n = e.color,
          i = e.presetName,
          a = e.inputStyle,
          o = e.labelStyle,
          r = [];
        n && r.push('mbsc-' + i + '-' + n),
          s && r.push(s),
          a && r.push('mbsc-input-' + a),
          o && r.push('mbsc-label-' + o),
          void 0 === t || t || r.push('mbsc-err');
        var l = '';
        return (
          r.length &&
            (l = r
              .reduce(function (e, t) {
                return e + ' ' + t;
              })
              .replace(/\s+/g, ' ')
              .trim()),
          l
        );
      }),
      (s.render = function () {
        var e = this.props,
          t = (e.valid, e.className, e.color, e.children),
          s =
            (e.presetName,
            e.inputStyle,
            e.labelStyle,
            y(e, ['valid', 'className', 'color', 'children', 'presetName', 'inputStyle', 'labelStyle']));
        return v.createElement('label', s, t);
      }),
      e
    );
  })(v.Component);
  o(Ms, 'propTypes', {
    valid: e.bool,
    color: e.string,
    presetName: e.string,
    inputStyle: e.string,
    labelStyle: e.string
  }),
    (ne.Form.Label = Ms),
    (ne.Label = Ms);
  var Os = (function (s) {
    function e(e) {
      var t;
      return ((t = s.call(this, e) || this).inputMounted = t.inputMounted.bind(b(t))), t;
    }
    l(e, s);
    var t = e.prototype;
    return (
      (t.componentDidMount = function () {
        var e = this.getSettingsFromProps(this.props);
        this.instance = new Pt(this.inputNode, e);
      }),
      (t.inputMounted = function (e) {
        this.inputNode = e;
      }),
      (t.render = function () {
        var e = this.props,
          t = e.valid,
          s = e.errorMessage,
          n = e.type,
          i = e.icon,
          a = e.iconAlign,
          o = e.passwordToggle,
          r = e.iconShow,
          l = e.iconHide,
          c = e.iconUpload,
          p = e.inputStyle,
          u = e.labelStyle,
          d = e.children,
          h = e.dropdown,
          m = y(e, [
            'valid',
            'errorMessage',
            'type',
            'icon',
            'iconAlign',
            'passwordToggle',
            'iconShow',
            'iconHide',
            'iconUpload',
            'inputStyle',
            'labelStyle',
            'children',
            'dropdown'
          ]),
          f = null;
        s && !t && (f = v.createElement('span', { className: 'mbsc-err-msg' }, s));
        var b = null;
        return (
          h && (b = v.createElement('span', { className: 'mbsc-select-ic mbsc-ic mbsc-ic-arrow-down5' })),
          (n = n || 'text'),
          v.createElement(
            Ms,
            { valid: t, inputStyle: p, labelStyle: u, className: h ? 'mbsc-select' : '' },
            d,
            v.createElement(
              'span',
              { className: 'mbsc-input-wrap' },
              v.createElement(
                'input',
                g(
                  {
                    ref: this.inputMounted,
                    type: n,
                    'data-icon': i,
                    'data-icon-align': a,
                    'data-password-toggle': o,
                    'data-icon-show': r,
                    'data-icon-hide': l,
                    'data-icon-upload': c
                  },
                  m
                )
              ),
              b,
              f
            )
          )
        );
      }),
      e
    );
  })(We);
  o(Os, 'propTypes', {
    disabled: e.bool,
    valid: e.bool,
    errorMessage: e.string,
    type: e.string,
    icon: e.string,
    iconAlign: e.string,
    passwordToggle: e.bool,
    iconShow: e.string,
    iconHide: e.string,
    iconUpload: e.string,
    name: e.string,
    dropdown: e.bool,
    inputStyle: e.string,
    labelStyle: e.string
  }),
    (ne.Input = Os);
  var Es = (function (s) {
    function e(e) {
      var t;
      return ((t = s.call(this, e) || this).textMounted = t.textMounted.bind(b(t))), t;
    }
    l(e, s);
    var t = e.prototype;
    return (
      (t.componentDidUpdate = function () {
        var e = this.getSettingsFromProps(this.props);
        this.optimizeUpdate
          ? (this.optimizeUpdate.updateOptions && this.instance.option(e),
            this.optimizeUpdate.updateValue && this.instance.refresh())
          : (this.instance.option(e), void 0 !== this.props.value && this.instance.refresh());
      }),
      (t.componentDidMount = function () {
        var e = this.getSettingsFromProps(this.props);
        this.instance = new Ht(this.inputNode, e);
      }),
      (t.textMounted = function (e) {
        this.inputNode = e;
      }),
      (t.render = function () {
        var e = this.props,
          t = e.valid,
          s = e.errorMessage,
          n = e.icon,
          i = e.iconAlign,
          a = (e.inputStyle, e.labelStyle, e.children),
          o = y(e, ['valid', 'errorMessage', 'icon', 'iconAlign', 'inputStyle', 'labelStyle', 'children']),
          r = null;
        return (
          s && !t && (r = v.createElement('span', { className: 'mbsc-err-msg' }, s)),
          v.createElement(
            Ms,
            { valid: t },
            a,
            v.createElement(
              'span',
              { className: 'mbsc-input-wrap' },
              v.createElement('textarea', g({ ref: this.textMounted, 'data-icon': n, 'data-icon-align': i }, o)),
              r
            )
          )
        );
      }),
      e
    );
  })(He);
  o(Es, 'propTypes', {
    disabled: e.bool,
    valid: e.bool,
    errorMessage: e.string,
    icon: e.string,
    iconAlign: e.string,
    name: e.string,
    inputStyle: e.string,
    labelStyle: e.string
  }),
    (ne.Textarea = Es);
  var Ns = (function (s) {
    function e(e) {
      var t;
      return ((t = s.call(this, e) || this).selectMounted = t.selectMounted.bind(b(t))), t;
    }
    l(e, s);
    var t = e.prototype;
    return (
      (t.componentDidMount = function () {
        var e = this.getSettingsFromProps(this.props);
        this.instance = new At(this.selectNode, e);
      }),
      (t.selectMounted = function (e) {
        this.selectNode = e;
      }),
      (t.componentDidUpdate = function () {
        this.instance._setText();
      }),
      (t.render = function () {
        var e = this.props,
          t = e.label,
          s = e.valid,
          n = e.errorMessage,
          i = e.icon,
          a = e.iconAlign,
          o = (e.inputStyle, e.labelStyle, e.children),
          r = y(e, ['label', 'valid', 'errorMessage', 'icon', 'iconAlign', 'inputStyle', 'labelStyle', 'children']),
          l = null;
        return (
          (l = n && !s ? v.createElement('span', { className: 'mbsc-err-msg' }, n) : v.createElement('span', null)),
          v.createElement(
            Ms,
            { valid: s },
            t,
            v.createElement(
              'span',
              { className: 'mbsc-input-wrap' },
              v.createElement('select', g({ ref: this.selectMounted, 'data-icon': i, 'data-icon-align': a }, r), o),
              l
            )
          )
        );
      }),
      e
    );
  })(We);
  o(Ns, 'propTypes', {
    label: e.string,
    disabled: e.bool,
    valid: e.bool,
    errorMessage: e.string,
    icon: e.string,
    iconAlign: e.string,
    name: e.string,
    inputStyle: e.string,
    labelStyle: e.string
  }),
    (ne.Dropdown = Ns);
  var Vs = (function (s) {
    function e(e) {
      var t;
      return ((t = s.call(this, e) || this).btnMounted = t.btnMounted.bind(b(t))), t;
    }
    l(e, s);
    var t = e.prototype;
    return (
      (t.componentDidMount = function () {
        var e = this.getSettingsFromProps(this.props);
        (this.instance = new It(this.btnNode, e)), Ue.call(this, '', this.getCssClasses(this.props));
      }),
      (t.componentDidUpdate = function () {
        this.cssClassUpdate && Ue.call(this, this.cssClassUpdate.prev, this.cssClassUpdate.next);
      }),
      (t.shouldComponentUpdate = function (e) {
        var t = this.getCssClasses(e),
          s = this.getCssClasses(this.props);
        return (this.cssClassUpdate = s !== t ? { prev: s, next: t } : null), !0;
      }),
      (t.btnMounted = function (e) {
        this.btnNode = e;
      }),
      (t.getCssClasses = function (e) {
        var t = e.className,
          s = e.color,
          n = e.flat,
          i = e.block,
          a = e.outline,
          o = [];
        n && o.push('mbsc-btn-flat'),
          i && o.push('mbsc-btn-block'),
          a && o.push('mbsc-btn-outline'),
          s && o.push('mbsc-btn-' + s),
          t && o.push(t);
        var r = '';
        return (
          o.length &&
            (r = o
              .reduce(function (e, t) {
                return e + ' ' + t;
              })
              .replace(/\s+/g, ' ')
              .trim()),
          r
        );
      }),
      (t.render = function () {
        var e = this.props,
          t = e.type,
          s = e.children,
          n = (e.className, e.color, e.flat, e.block, e.outline, e.icon),
          i = y(e, ['type', 'children', 'className', 'color', 'flat', 'block', 'outline', 'icon']);
        return (
          (t = t || 'button'), v.createElement('button', g({ ref: this.btnMounted, type: t, 'data-icon': n }, i), s)
        );
      }),
      e
    );
  })(We);
  o(Vs, 'propTypes', {
    type: e.string,
    color: e.string,
    flat: e.bool,
    block: e.bool,
    outline: e.bool,
    icon: e.string,
    disabled: e.bool,
    name: e.string
  }),
    (ne.Button = Vs);
  var Ds = (function (s) {
    function e(e) {
      var t;
      return ((t = s.call(this, e) || this).inputMounted = t.inputMounted.bind(b(t))), t;
    }
    l(e, s);
    var t = e.prototype;
    return (
      (t.componentDidMount = function () {
        var e = this.getSettingsFromProps(this.props);
        this.instance = new $t(this.inputNode, e);
      }),
      (t.inputMounted = function (e) {
        this.inputNode = e;
      }),
      (t.render = function () {
        var e = this.props,
          t = e.color,
          s = e.children,
          n = e.errorMessage,
          i = (e.inputStyle, e.valid),
          a = y(e, ['color', 'children', 'errorMessage', 'inputStyle', 'valid']),
          o = null;
        return (
          n && !i && (o = v.createElement('span', { className: 'mbsc-err-msg' }, n)),
          v.createElement(
            Ms,
            { valid: i, color: t, presetName: 'checkbox' },
            v.createElement('input', g({ ref: this.inputMounted, type: 'checkbox' }, a)),
            s,
            o
          )
        );
      }),
      e
    );
  })(We);
  o(Ds, 'propTypes', {
    color: e.string,
    disabled: e.bool,
    valid: e.bool,
    errorMessage: e.string,
    name: e.string,
    inputStyle: e.string
  }),
    (ne.Checkbox = Ds);
  var Ps = (function (s) {
    function e(e) {
      var t;
      return ((t = s.call(this, e) || this).inputMounted = t.inputMounted.bind(b(t))), t;
    }
    l(e, s);
    var t = e.prototype;
    return (
      (t.componentDidMount = function () {
        var e = this.getSettingsFromProps(this.props);
        this.instance = new Lt(this.inputNode, e);
      }),
      (t.inputMounted = function (e) {
        this.inputNode = e;
      }),
      (t.render = function () {
        var e = this.props,
          t = e.color,
          s = e.children,
          n = (e.inputStyle, e.valid),
          i = e.errorMessage,
          a = y(e, ['color', 'children', 'inputStyle', 'valid', 'errorMessage']),
          o = null;
        return (
          i && !n && (o = v.createElement('span', { className: 'mbsc-err-msg' }, i)),
          v.createElement(
            Ms,
            { valid: n, color: t, presetName: 'radio' },
            v.createElement('input', g({ ref: this.inputMounted, type: 'radio' }, a)),
            s,
            o
          )
        );
      }),
      e
    );
  })(We);
  o(Ps, 'propTypes', {
    color: e.string,
    name: e.string,
    disabled: e.bool,
    inputStyle: e.string,
    valid: e.bool,
    errorMessage: e.string
  }),
    (ne.Radio = Ps);
  var Is = (function (s) {
    function e(e) {
      var t;
      return ((t = s.call(this, e) || this).inputMounted = t.inputMounted.bind(b(t))), t;
    }
    l(e, s);
    var t = e.prototype;
    return (
      (t.componentDidMount = function () {
        var e = this.getSettingsFromProps(this.props);
        this.instance = new zt(this.inputNode, e);
      }),
      (t.inputMounted = function (e) {
        this.inputNode = e;
      }),
      (t.render = function () {
        var e = this.props,
          t = e.color,
          s = e.children,
          n = e.multiSelect,
          i = e.icon,
          a = y(e, ['color', 'children', 'multiSelect', 'icon']),
          o = n ? 'checkbox' : 'radio';
        return v.createElement(
          Ms,
          { color: t, presetName: 'segmented' },
          v.createElement('input', g({ ref: this.inputMounted, type: o, 'data-icon': i, 'data-role': 'segmented' }, a)),
          s
        );
      }),
      e
    );
  })(We);
  o(Is, 'propTypes', { color: e.string, name: e.string, disabled: e.bool, multiSelect: e.bool, icon: e.string }),
    (ne.Segmented = Is);
  var $s = (function (n) {
    function e(e, t) {
      var s;
      return ((s = n.call(this, e) || this).presetName = t), (s.inputMounted = s.inputMounted.bind(b(s))), s;
    }
    l(e, n);
    var t = e.prototype;
    return (
      (t.componentDidMount = function () {
        var e = this.getSettingsFromProps(this.props, this.mbscInit);
        (this.instance = new pe[this.mbscInit.component || 'Scroller'](this.inputNode, e)),
          Te(this.inputNode).on('change', this.props.onChange || function () {}),
          Ue.call(this, '', this.getCssClasses(this.props));
      }),
      (t.shouldComponentUpdate = function (e) {
        var t = this.getCssClasses(e),
          s = this.getCssClasses(this.props);
        return (
          (this.cssClassUpdate = s !== t ? { prev: s, next: t } : null),
          n.prototype.shouldComponentUpdate.call(this, e) || this.cssClassUpdate
        );
      }),
      (t.componentDidUpdate = function () {
        if (
          (this.cssClassUpdate && Ue.call(this, this.cssClassUpdate.prev, this.cssClassUpdate.next),
          this.optimizeUpdate.updateOptions)
        ) {
          var e = this.getSettingsFromProps(this.props);
          this.instance.option(e);
        }
      }),
      (t.inputMounted = function (e) {
        this.inputNode = e;
      }),
      (t.getCssClasses = function (e) {
        var t = e.className,
          s = e.color,
          n = [];
        return (
          s && n.push('mbsc-' + this.presetName + '-' + s),
          t && n.push(t),
          n.length
            ? n
                .reduce(function (e, t) {
                  return e + ' ' + t;
                })
                .replace(/\s+/g, ' ')
                .trim()
            : ''
        );
      }),
      (t.render = function () {
        var e = this.props,
          t = (e.className, e.children),
          s = (e.value, e.valid),
          n = e.errorMessage,
          i = (e.checked, e.onChange, e.name),
          a = (e.color, e.labelStyle),
          o = e.inputStyle,
          r = y(e, [
            'className',
            'children',
            'value',
            'valid',
            'errorMessage',
            'checked',
            'onChange',
            'name',
            'color',
            'labelStyle',
            'inputStyle'
          ]),
          l = null;
        n && !s && (l = v.createElement('span', { className: 'mbsc-err-msg' }, n));
        var c = this.inputType || 'text';
        return v.createElement(
          Ms,
          { valid: s, inputStyle: o, labelStyle: a },
          t,
          v.createElement('input', g({ ref: this.inputMounted, type: c, 'data-role': i, 'data-enhance': 'false' }, r)),
          l
        );
      }),
      e
    );
  })(He);
  o($s, 'propTypes', g({}, $e, { color: ws, labelStyle: ws, inputStyle: ws }));
  var Ls = (function (n) {
    function e(e) {
      var t;
      return (
        ((t = n.call(this, e, 'switch') || this).mbscInit = { component: 'Switch' }), (t.inputType = 'checkbox'), t
      );
    }
    l(e, n);
    var t = e.prototype;
    return (
      (t.componentDidMount = function () {
        n.prototype.componentDidMount.call(this),
          void 0 !== this.props.checked && this.instance.setVal(this.props.checked, !0, !1);
      }),
      (t.shouldComponentUpdate = function (e) {
        var t = n.prototype.shouldComponentUpdate.call(this, e),
          s = (this.optimizeUpdate.updateChecked = !Fe(e.checked, this.props.checked));
        return t || s;
      }),
      (t.componentDidUpdate = function () {
        n.prototype.componentDidUpdate.call(this),
          this.optimizeUpdate.updateChecked &&
            !Fe(this.instance.getVal(), this.props.checked) &&
            this.instance.setVal(this.props.checked);
      }),
      e
    );
  })($s);
  (Ls.propTypes = g({}, Ls.propTypes, {}, Ss)), (ne.Switch = Ls);
  var As = (function (s) {
    function e(e) {
      var t;
      return ((t = s.call(this, e, 'stepper') || this).mbscInit = { component: 'Stepper' }), t;
    }
    l(e, s);
    var t = e.prototype;
    return (
      (t.componentDidMount = function () {
        s.prototype.componentDidMount.call(this),
          void 0 !== this.props.value && this.instance.setVal(this.props.value, !0, !1);
      }),
      (t.componentDidUpdate = function () {
        s.prototype.componentDidUpdate.call(this),
          this.optimizeUpdate.updateValue &&
            !Fe(this.props.value, this.instance.getVal()) &&
            this.instance.setVal(this.props.value);
      }),
      e
    );
  })($s);
  (As.propTypes = g({}, As.propTypes, {}, Ts)), (ne.Stepper = As);
  var Us = (function (n) {
      function e(e) {
        return n.call(this, e) || this;
      }
      l(e, n);
      var t = e.prototype;
      return (
        (t.componentDidMount = function () {
          Ue.call(this, '', this.getCssClasses(this.props));
        }),
        (t.componentDidUpdate = function () {
          n.prototype.componentDidUpdate.call(this),
            this.cssClassUpdate && Ue.call(this, this.cssClassUpdate.prev, this.cssClassUpdate.next);
        }),
        (t.shouldComponentUpdate = function (e) {
          var t = this.getCssClasses(e),
            s = this.getCssClasses(this.props);
          return (
            (this.cssClassUpdate = s !== t ? { prev: s, next: t } : null),
            n.prototype.shouldComponentUpdate.call(this, e) || this.cssClassUpdate
          );
        }),
        (t.getCssClasses = function (e) {
          var t = e.className,
            s = e.color,
            n = [];
          return (
            s && n.push('mbsc-' + this.presetName + '-' + s),
            t && n.push(t),
            n.length
              ? n
                  .reduce(function (e, t) {
                    return e + ' ' + t;
                  })
                  .replace(/\s+/g, ' ')
                  .trim()
              : ''
          );
        }),
        e
      );
    })(He),
    Fs = (function (s) {
      function e(e) {
        var t;
        return (
          ((t = s.call(this, e) || this).presetName = 'progress'), (t.progressMounted = t.progressMounted.bind(b(t))), t
        );
      }
      l(e, s);
      var t = e.prototype;
      return (
        (t.componentDidMount = function () {
          var e = this.getSettingsFromProps(this.props);
          (this.instance = new Bt(this.progressNode, e)),
            void 0 !== this.props.value && this.instance.setVal(this.props.value, !0),
            s.prototype.componentDidMount.call(this);
        }),
        (t.progressMounted = function (e) {
          this.progressNode = e;
        }),
        (t.render = function () {
          var e = this.props,
            t = (e.className, e.children),
            s = (e.value, e.color, e.inputStyle),
            n = e.labelStyle,
            i = y(e, ['className', 'children', 'value', 'color', 'inputStyle', 'labelStyle']);
          return v.createElement(
            Ms,
            { labelStyle: n, inputStyle: s },
            t,
            v.createElement('progress', g({ ref: this.progressMounted }, i))
          );
        }),
        e
      );
    })(Us);
  o(
    Fs,
    'propTypes',
    g({}, $e, {
      'data-icon': ws,
      'data-icon-align': e.oneOf(['left', 'right']),
      val: e.oneOf(['left', 'right']),
      disabled: xs,
      max: _s,
      value: _s,
      color: ws,
      inputStyle: ws,
      labelStyle: ws
    })
  ),
    (ne.Progress = Fs);
  var Ws = (function (s) {
    function e(e) {
      var t;
      return (
        ((t = s.call(this, e) || this).presetName = 'slider'),
        (t.firstInputMounted = t.firstInputMounted.bind(b(t))),
        (t.parentMounted = t.parentMounted.bind(b(t))),
        t
      );
    }
    l(e, s);
    var t = e.prototype;
    return (
      (t.componentDidMount = function () {
        var e = this.getSettingsFromProps(this.props);
        (this.instance = new Gt(this.firstInput, e)),
          void 0 !== this.props.value && this.instance.setVal(this.props.value, !0);
        var t = this;
        Te(this.label).on('change', function () {
          if (t.props.onChange) {
            var e = t.instance.getVal();
            t.props.onChange(e);
          }
        }),
          s.prototype.componentDidMount.call(this);
      }),
      (t.firstInputMounted = function (e) {
        this.firstInput = e;
      }),
      (t.parentMounted = function (e) {
        this.label = a.findDOMNode(e);
      }),
      (t.onValueChanged = function () {}),
      (t.render = function () {
        var e = this.props,
          t = e.children,
          s = e.value,
          n = (e.onChange, e.className, e.icon),
          i = e.live,
          a = (e.stepLabels, e.tooltip, e.color, e.inputStyle),
          o = e.labelStyle,
          r = y(e, [
            'children',
            'value',
            'onChange',
            'className',
            'icon',
            'live',
            'stepLabels',
            'tooltip',
            'color',
            'inputStyle',
            'labelStyle'
          ]),
          l = s || [];
        return (
          (i = i || this.props['data-live'] || !1),
          (n = n || this.props['data-icon']),
          void 0 === s || Array.isArray(s) || (l = [s]),
          v.createElement(
            Ms,
            { ref: this.parentMounted, labelStyle: o, inputStyle: a },
            t,
            l.map(function (e, t) {
              return 0 === t
                ? v.createElement(
                    'input',
                    g(
                      {
                        ref: this.firstInputMounted,
                        'data-label-style': o,
                        'data-input-style': a,
                        'data-icon': n,
                        'data-live': i,
                        key: t,
                        type: 'range'
                      },
                      r
                    )
                  )
                : v.createElement('input', {
                    key: t,
                    type: 'range',
                    'data-live': i,
                    'data-index': t,
                    'data-label-style': o,
                    'data-input-style': a
                  });
            }, this)
          )
        );
      }),
      e
    );
  })(Us);
  o(
    Ws,
    'propTypes',
    g({}, $e, {
      highlight: xs,
      live: xs,
      stepLabels: e.arrayOf(_s),
      'data-icon': ws,
      icon: ws,
      tooltip: xs,
      val: e.oneOf(['left', 'right']),
      disabled: xs,
      max: _s,
      min: _s,
      step: _s,
      value: e.oneOfType([_s, e.arrayOf(_s)]),
      color: ws,
      inputStyle: ws,
      labelStyle: ws
    })
  ),
    (ne.Slider = Ws);
  var Rs = (function (s) {
    function e(e) {
      var t;
      return (
        ((t = s.call(this, e) || this).presetName = 'rating'),
        (t.inputMounted = t.inputMounted.bind(b(t))),
        (t.parentMounted = t.parentMounted.bind(b(t))),
        t
      );
    }
    l(e, s);
    var t = e.prototype;
    return (
      (t.componentDidMount = function () {
        var t = this,
          e = this.getSettingsFromProps(this.props);
        (this.instance = new Kt(this.inputNode, e)),
          void 0 !== this.props.value && this.instance.setVal(this.props.value, !0),
          Te(this.label).on('change', function () {
            if (t.props.onChange) {
              var e = t.instance.getVal();
              t.props.onChange(e);
            }
          }),
          s.prototype.componentDidMount.call(this);
      }),
      (t.inputMounted = function (e) {
        this.inputNode = e;
      }),
      (t.parentMounted = function (e) {
        this.label = a.findDOMNode(e);
      }),
      (t.render = function () {
        var e = this.props,
          t = (e.className, e.children),
          s = (e.onChange, e.value, e.empty),
          n = e.filled,
          i = e.template,
          a = e.val,
          o = (e.color, e.inputStyle),
          r = e.labelStyle,
          l = y(e, [
            'className',
            'children',
            'onChange',
            'value',
            'empty',
            'filled',
            'template',
            'val',
            'color',
            'inputStyle',
            'labelStyle'
          ]);
        return v.createElement(
          Ms,
          { ref: this.parentMounted, labelStyle: r, inputStyle: o },
          t,
          v.createElement(
            'input',
            g(
              {
                type: 'rating',
                'data-role': 'rating',
                'data-val': a,
                'data-template': i,
                'data-empty': s,
                'data-filled': n,
                ref: this.inputMounted
              },
              l
            )
          )
        );
      }),
      e
    );
  })(Us);
  o(
    Rs,
    'propTypes',
    g({}, $e, {
      val: e.oneOf(['left', 'right']),
      disabled: xs,
      max: _s,
      min: _s,
      step: _s,
      template: ws,
      empty: ws,
      filled: ws,
      value: _s,
      color: ws,
      inputStyle: ws,
      labelStyle: ws
    })
  ),
    (ne.Rating = Rs);
  var Hs = (function (t) {
    function e(e) {
      return t.call(this, e) || this;
    }
    l(e, t);
    var s = e.prototype;
    return (
      (s.componentDidMount = function () {
        if (void 0 !== this.props.collapsible) {
          var e = this.props.open || !1;
          this.instance = new Jt(a.findDOMNode(this), { isOpen: e });
        }
      }),
      (s.componentDidUpdate = function (e) {
        void 0 !== this.props.open &&
          this.props.open != e.open &&
          (this.props.open ? this.instance.show() : this.instance.hide());
      }),
      (s.render = function () {
        var e = this.props,
          t = e.children,
          s = e.inset,
          n = (e.collapsible, e.className),
          i = y(e, ['children', 'inset', 'collapsible', 'className']),
          a = 'mbsc-form-group' + (void 0 !== s ? '-inset' : '') + ' ' + (n || '');
        return v.createElement('div', g({ className: a }, i), t);
      }),
      e
    );
  })(v.Component);
  o(Hs, 'propTypes', { collapsible: e.bool, open: e.bool, inset: e.any }), (ne.FormGroup = Hs), (ne.MbscFormGroup = Hs);
  var zs = (function (s) {
    function e(e) {
      var t;
      return ((t = s.call(this, e) || this).cssClasses = 'mbsc-form-group-title ' + (t.props.className || '')), t;
    }
    return (
      l(e, s),
      (e.prototype.render = function () {
        return v.createElement('div', { className: this.cssClasses }, this.props.children);
      }),
      e
    );
  })(v.Component);
  (ne.FormGroupTitle = zs), (ne.MbscFormGroupTitle = zs);
  var Ys = (function (s) {
    function e(e) {
      var t;
      return ((t = s.call(this, e) || this).cssClasses = 'mbsc-form-group-content ' + (t.props.className || '')), t;
    }
    return (
      l(e, s),
      (e.prototype.render = function () {
        return v.createElement('div', { className: this.cssClasses }, this.props.children);
      }),
      e
    );
  })(v.Component);
  (ne.FormGroupContent = Ys), (ne.MbscFormGroupContent = Ys);
  var Xs = (function (s) {
    function e(e) {
      var t;
      return ((t = s.call(this, e) || this).cssClasses = 'mbsc-accordion ' + (t.props.className || '')), t;
    }
    return (
      l(e, s),
      (e.prototype.render = function () {
        return v.createElement('div', { className: this.cssClasses }, this.props.children);
      }),
      e
    );
  })(v.Component);
  ne.Accordion = Xs;
  var js = ne.themes;
  (js.frame.ios = {
    display: 'bottom',
    headerText: !1,
    btnWidth: !1,
    deleteIcon: 'ios-backspace',
    scroll3d: 'wp' != t && ('android' != t || 7 < n)
  }),
    (js.scroller.ios = ke({}, js.frame.ios, {
      rows: 5,
      height: 34,
      minWidth: 55,
      selectedLineHeight: !0,
      selectedLineBorder: 1,
      showLabel: !1,
      useShortLabels: !0,
      btnPlusClass: 'mbsc-ic mbsc-ic-arrow-down5',
      btnMinusClass: 'mbsc-ic mbsc-ic-arrow-up5',
      checkIcon: 'ion-ios7-checkmark-empty',
      filterClearIcon: 'ion-close-circled',
      dateDisplay: 'MMdyy',
      btnCalPrevClass: 'mbsc-ic mbsc-ic-arrow-left5',
      btnCalNextClass: 'mbsc-ic mbsc-ic-arrow-right5'
    })),
    (js.listview.ios = {
      leftArrowClass: 'mbsc-ic-ion-ios7-arrow-back',
      rightArrowClass: 'mbsc-ic-ion-ios7-arrow-forward'
    }),
    (js.form.ios = {}),
    ne.customTheme('mobiscroll-dark', 'mobiscroll'),
    ne.customTheme('ios-dark', 'ios');
  var qs = ne.themes,
    Bs = 'mobiscroll';
  return (
    'android' == t ? (Bs = 'material') : 'ios' == t ? (Bs = 'ios') : 'wp' == t && (Bs = 'windows'),
    Te.each(qs.frame, function (e, t) {
      if (Bs && t.baseTheme == Bs && e != Bs + '-dark') return (ne.autoTheme = e), !1;
      e == Bs && (ne.autoTheme = e);
    }),
    ne
  );
};

export default mobiScroll(React, ReactDOM, PropTypes);
