import { action, computed, observable, runInAction } from 'mobx';

import shoppingApis from '@packs/apis/shoppingCart';
import { toastr } from '@packs/lib/helpers';

export class ShoppingCartStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @computed get isAuth() {
    return !!this.rootStore.userStore.currentUser?.id;
  }

  @observable shoppingCart = { cartItems: [] };
  @observable isLoading = false;

  @action fetchShoppingCart = async attrs => {
    this.isLoading = true;

    const res = await shoppingApis.shoppingCartRetrieve(attrs);

    if (!res) {
      runInAction(() => {
        this.shoppingCart = { cartItems: [] };
      });
    } else if (res?.errors?.length) {
      toastr.error(JSON.stringify(res.errors));
      return await Promise.reject(res.errors);
    } else if (res) {
      runInAction(() => {
        this.shoppingCart = res || { cartItems: [] };
      });
    }

    runInAction(() => {
      this.isLoading = false;
    });

    return res;
  };

  @action createShoppingCartItems = async attrs => {
    if (this.shoppingCart?.status === 'is_off' && this.isAuth) {
      return;
    }

    if (!this.isAuth) {
      delete attrs.policyId;
    }

    const createShoppingCartItemApi = this.isAuth
      ? shoppingApis.createShoppingCartItems
      : shoppingApis.createShoppingCartItemsUnauthorized;

    // api call
    const res = await createShoppingCartItemApi({ ...attrs, shoppingCartId: this.shoppingCart?.id });

    if (res?.errors?.length) {
      toastr.error(JSON.stringify(res.errors));
      return await Promise.reject(res.errors);
    } else if (res) {
      const newShoppingCart = { ...this.shoppingCart };
      const items = res?.shoppingCartItems;
      const companyName = items && items[0].company?.name;

      newShoppingCart.cartItems = [...(newShoppingCart?.cartItems?.slice() || []), ...(res?.shoppingCartItems || [])];

      runInAction(() => {
        this.shoppingCart = newShoppingCart;
      });
      toastr.success(`${items?.length === 1 ? companyName : 'Companies'} was successfully added to your shortlist!`);
      return res;
    }
  };

  @action removeShoppingCartItem = async attrs => {
    if (this.isAuth) {
      delete attrs.categoryId;
    }
    const removeShoppingCartItemApi = this.isAuth
      ? shoppingApis.removeShoppingCartItem
      : shoppingApis.removeShoppingCartItemUnauthorized;

    const res = await removeShoppingCartItemApi(attrs);

    if (res?.errors?.length) {
      toastr.error(JSON.stringify(res.errors));
      return await Promise.reject(res.errors);
    } else if (res) {
      const newShoppingCart = { ...this.shoppingCart };
      const targetCompany = newShoppingCart?.cartItems.find(item => item.id === attrs.shoppingCartItemId);
      const companyName = targetCompany?.company?.name;

      newShoppingCart.cartItems = newShoppingCart?.cartItems.filter(item => item.id !== attrs.shoppingCartItemId);

      runInAction(() => {
        this.shoppingCart = newShoppingCart;
      });
      toastr.success(`${companyName ? companyName : 'Company'} was removed from your shortlist!`);
    }
  };

  @action updateShoppingCart = (attrs, onSuccess, onError) => {
    shoppingApis.shoppingCartUpdate(attrs).then(res => {
      if (res?.errors?.length) {
        toastr.error(res.errors);
        onError && onError(res.errors);
      } else if (res) {
        const newShoppingCart = { ...this.shoppingCart };

        newShoppingCart.status = res?.shoppingCart.status;

        runInAction(() => {
          this.shoppingCart = newShoppingCart;
        });
        toastr.success('Shopping Cart was successfully updated');
        onSuccess && onSuccess(res);
      }
    });
  };
}
