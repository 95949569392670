import { action, computed, observable, runInAction } from 'mobx';

import { signUpApi } from '@packs/apis/signUp';
import { ICategory, ICategoryOption } from '@packs/interfaces/rootInterfaces';
import { EXCLUDED_CATEGORIES, EXCLUDED_VIEW_CATEGORIES } from '@packs/models/category/constants';
import { buildSyncTreeCategories } from '@packs/models/category/helpers';
import { RootStore } from '@packs/stores/index';

import filter from 'lodash-es/filter';
import get from 'lodash-es/get';
import sortBy from 'lodash-es/sortBy';

const broadbandCodes = {
  broadband_subcat: 0,
  broadband_phone: 1,
  broadband_tv: 2,
  broadband_phone_tv: 3,
  broadband_phone_tv_mobile: 4,
  tv_only: 5
};

const electricityCodes = {
  electricity: 0,
  gas: 1,
  gas_and_electricity: 2
};

const excludeByRoot = category => {
  return category.root && !EXCLUDED_VIEW_CATEGORIES.includes(get(category, 'root.code', category.code));
};

export class CategoryStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable categories: ICategory[] = [];
  @observable currentCategory: ICategory;
  @observable categoriesWithPolicy: number[] = [];

  @action setCurrentCategory(category) {
    this.currentCategory = category;
  }

  @action setCategories(data) {
    this.categories = buildSyncTreeCategories(data) || [];
  }

  @action loadCategories(countryCode) {
    signUpApi.fetchCategories({ countryCode }).then(res => {
      if (!res?.errors?.length) {
        this.setCategories(res);
      }
    });
  }

  @action loadCategoriesWithPolicy() {
    signUpApi.fetchCategoriesWithPolicy().then(res => {
      runInAction(() => {
        if (!res?.errors?.length) {
          this.categoriesWithPolicy = res?.categoryIds;
        }
      });
    });
  }

  @action setCategoryWithPolicy(id) {
    this.categoriesWithPolicy = [...this.categoriesWithPolicy, id];
  }

  @action markCategorySelect({ id, selected }) {
    if (this.currentCategory.id === id) {
      this.currentCategory.selected = selected;
    }
    this.categories.forEach(root => {
      root.leafChildren.forEach(c => {
        if (+c.id === +id) {
          c.selected = selected;
        }
      });
    });
    this.categories = [...this.categories];
  }

  @action markCategoriesSelect({ parentCode, selected }) {
    const newCategories: ICategory[] = [];
    if (parentCode) {
      this.categories.forEach(root => {
        if (parentCode === root.code) {
          root.leafChildren.forEach(c => (c.selected = selected));
          newCategories.push(...root.leafChildren);
        }
      });
    } else {
      this.categories.forEach(root => {
        root.leafChildren.forEach(c => (c.selected = selected));
        newCategories.push(...root.leafChildren);
      });
    }
    this.categories = [...this.categories];
    return newCategories;
  }

  @computed get sortedCategories() {
    const categoriesCollection = this.categories
      .slice()
      .filter(category => !EXCLUDED_CATEGORIES.includes(category.enName));

    return categoriesCollection.map(item => {
      let leafChildren = [...item.leafChildren];
      if (item?.code === 'broadband') {
        leafChildren = sortBy(leafChildren, c => broadbandCodes[c.code]);
      }
      if (item?.code === 'energy') {
        leafChildren = sortBy(leafChildren, c => electricityCodes[c.code]);
      }
      return { ...item, leafChildren };
    });
  }

  @computed get flattenCategories(): ICategory[] {
    // [].concat(el.leafChildren.slice(), el)
    return this.categories.slice().flatMap(el => [...el.leafChildren, el]);
  }

  @computed get categoriesOptions(): ICategoryOption[] {
    return this.categories.slice().map(root => ({
      label: root.name,
      code: root.code,
      options: root.leafChildren.map(({ id, name, code }) => ({
        value: id,
        label: name,
        rootCode: root.code,
        code
      }))
    }));
  }

  @computed get flattenCategoriesOptions() {
    return this.flattenCategories.filter(excludeByRoot).map(c => ({
      label: c?.name,
      value: c?.id,
      code: c?.code,
      rootCode: c?.root?.code
    }));
  }

  @computed get categoriesAttributes() {
    return this.flattenCategories.filter(c => c.selected).map(c => ({ categoryId: c.id }));
  }

  // first current category options
  @computed get flattenCategoriesSortedOptions() {
    const categories = this.flattenCategoriesOptions.slice();
    return [
      ...filter(categories, c => c.rootCode === this.currentCategory?.root?.code),
      ...filter(categories, c => c.rootCode !== this.currentCategory?.root?.code)
    ];
  }
}
