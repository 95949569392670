import moment from 'moment';

import { WithAutoQuotes as WithAutoQuotesForm } from './WithAutoQuotes';

import { isValidPhoneNumber } from 'react-phone-number-input';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';

const validate = ({ companyRate, categoryId, rootCategoryCode, isAutoQuote, phoneNumber, triggeredAt }) => {
  const errors = {};
  if (!companyRate) errors.companyRate = 'Required';
  if (!categoryId) errors.categoryId = 'Required';
  if (!rootCategoryCode) errors.rootCategoryCode = 'Required';
  if (isAutoQuote && !phoneNumber) errors.phoneNumber = 'Required';
  if (isAutoQuote && phoneNumber && !isValidPhoneNumber(phoneNumber)) {
    errors.phoneNumber = 'Phone number is invalid';
  }
  if (moment(triggeredAt).diff(moment(), 'hours') < 0) {
    errors.triggeredAt = 'Reminder date must be in the future';
  }

  return errors;
};

const mapStateToProps = (state, props) => ({ formAttributes: getFormValues(props.form)(state) });

const createReduxForm = reduxForm({ form: 'smartReminderForm', validate });

export const WithAutoQuotes = createReduxForm(connect(mapStateToProps)(WithAutoQuotesForm));
