import React, { FC, useEffect, useState } from 'react';
import cx from 'classnames';

import Phone from '@shared/components/PhoneInput/PhoneInput';

import PhoneInput, { Country } from 'react-phone-number-input';
import styled from 'styled-components';

interface IPhoneInputComponentProps {
  input: {
    onChange: (ev: Event) => void;
    value: string;
  };
  placeholder?: string;
  type?: string;
  className?: string;
  disabled?: boolean;
  meta: {
    touched: boolean;
    error: string;
  };

  controlError?: string;
  defaultCountry: Country;
  international?: boolean;
  countryCallingCodeEditable?: boolean;
}

const PhoneInputContainer = styled.div`
  .PhoneInputWrapper {
    position: relative;
    display: flex !important;
    align-items: center;
    gap: 4px;

    .PhoneInputCountry {
      position: relative;
      align-self: stretch;
      display: flex;
      align-items: center;
    }

    .PhoneInputCountrySelect {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      min-width: 22px;
      z-index: 1;
      border: 0;
      opacity: 0;
      cursor: pointer;
    }

    .PhoneInputCountryIcon {
      width: 22px;
    }

    .PhoneInputInput {
      border: none !important;
      outline: none !important;
    }

    .MuiOutlinedInput-input {
      height: 24px;
    }
  }
`;

const PhoneInputComponent: FC<IPhoneInputComponentProps> = ({
  input,
  disabled = false,
  placeholder,
  className,
  meta: { touched, error },
  controlError,
  defaultCountry = '',
  international = true,
  countryCallingCodeEditable
}) => {
  const [controlledPlaceholder, setPlaceholder] = useState(placeholder);

  useEffect(() => {
    setPlaceholder(placeholder);
  }, [placeholder]);

  const handleKeyPress = ev => {
    input.onChange(ev);
  };
  const inputProps = {
    ...input,
    className: cx(className, { 'error-input': touched && error, disabled }),
    onChange: handleKeyPress,
    placeholder: controlledPlaceholder,
    disabled,
    international,
    defaultCountry: defaultCountry.toUpperCase() as Country,
    countryCallingCodeEditable
  };

  const spanError = error => error && <span className='error-hint'>{error}</span>;

  return (
    <PhoneInputContainer>
      <PhoneInput {...inputProps} className={cx('PhoneInputWrapper')} inputComponent={Phone} />
      {controlError === undefined ? touched && spanError(error) : touched && spanError(controlError)}
    </PhoneInputContainer>
  );
};

export default PhoneInputComponent;
