import React from 'react';

export const BankingCertificatesDeposit = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_3:409)'>
        <path d='M19.5775 16.4894C19.8028 16.4894 20 16.2906 20 16.0634V7.54275C20 7.31553 19.8028 7.11672 19.5775 7.11672H12.4507C12.2817 7.11672 12.1408 6.97471 12.1408 6.8043V0.811472C12.1408 0.527452 11.7746 0.385442 11.6056 0.612658C8.31895 4.3797 5.2844 6.19781 3.20038 7.10918C3.18879 7.11424 3.17885 7.12197 3.16784 7.12818C3.13781 7.14512 3.09413 7.14512 3.07042 7.14512H2.16901H0.422535C0.197183 7.14512 0 7.34394 0 7.57115V16.0918V19.074C0 19.3012 0.197183 19.5 0.422535 19.5H2.16901H19.5775C19.8028 19.5 20 19.3012 20 19.074C20 18.8468 19.8028 18.6479 19.5775 18.6479H2.92958C2.76056 18.6479 2.61972 18.5059 2.61972 18.3355C2.61972 18.1651 2.76056 18.0231 2.92958 18.0231H19.5775C19.8028 18.0231 20 17.8243 20 17.5971C20 17.3698 19.8028 17.171 19.5775 17.171H2.92958C2.76056 17.171 2.61972 17.029 2.61972 16.8586C2.61972 16.6882 2.76056 16.5462 2.92958 16.5462L19.5775 16.4894ZM19.1549 8.30961V15.3249C19.1549 15.4953 19.0141 15.6373 18.8451 15.6373H2.73239C2.64789 15.6373 2.59155 15.5805 2.59155 15.4953C2.59155 15.4385 2.61972 15.3817 2.70423 15.3533C8.8169 13.5924 11.4366 9.92852 12.0282 8.99125C12.1127 8.84924 12.169 8.67883 12.169 8.53682V8.30961C12.169 8.13919 12.3099 7.99718 12.4789 7.99718H18.8451C19.0141 7.99718 19.1549 8.13919 19.1549 8.30961Z' />
      </g>
      <defs>
        <clipPath>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
