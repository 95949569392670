import React, { useMemo } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { getCurrency, numberWithCommas } from '@packs/lib/constants';
import { isCompanyPerMonth } from '@packs/models/category/functions';
import { useStore } from '@packs/stores';
import { TooltipMUI } from '@shared/components/TooltipMUI/TooltipMUI';

const { text } = colors;

export const AveragePrice = ({ company, category }) => {
  const { countryCode } = useStore();

  const isPerMonth = isCompanyPerMonth(category);

  const averagePrice = useMemo(() => {
    if (isPerMonth) {
      return company?.additionalData.averagePriceData?.averageMonthly
        ? numberWithCommas(company?.additionalData.averagePriceData.averageMonthly)
        : null;
    }

    return company?.additionalData.averagePriceData?.averageYearly
      ? numberWithCommas(company?.additionalData.averagePriceData.averageYearly)
      : null;
  }, [company?.additionalData.averagePriceData]);

  const currency = getCurrency(countryCode);

  if (!averagePrice) {
    return null;
  }

  return (
    <Box>
      <TooltipMUI
        title={`Average premium per ${isPerMonth ? 'month' : 'year'} for members like you!`}
        arrow
        placement='top'
      >
        <Typography variant='h6' sx={{ textAlign: { md: 'right' } }}>{`${currency + averagePrice} p/${
          isPerMonth ? 'm' : 'y'
        }`}</Typography>
      </TooltipMUI>
      <Box sx={{ mt: 1, textAlign: { md: 'right' } }}>
        <Typography variant='body5' sx={{ mt: 1, color: text.secondary }}>
          Average premium <br />
          for members
        </Typography>
      </Box>
    </Box>
  );
};
