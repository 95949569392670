import { action, observable, runInAction } from 'mobx';

import companyApi from '@packs/apis/company';
import leaderboardApi from '@packs/apis/leaderboard';
import { isWikiTableCategory } from '@packs/models/category/functions';

import keys from 'lodash-es/keys';

const serializeFilters = filters => {
  return keys(filters).reduce((memo, key) => {
    if (filters[key] === 'all') {
      memo[key] = null;
    } else if (key === 'criteriaQuestions') {
      if (Object.keys(filters[key]).length) {
        memo[key] = filters[key];
      } else {
        memo[key] = undefined;
      }
    } else {
      memo[key] = filters[key];
    }
    return memo;
  }, {});
};

export class LeaderboardStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  // My stats business
  @observable businessData = [];
  @observable dataCompanies = [];
  @observable suggestedList = {
    provider: [],
    broker: [],
    comparison_site: []
  };
  @observable leaderboardCounter = {
    offersCount: 0,
    communityCount: 0,
    docsCount: 0
  };
  @observable isLoadingBusiness = false;
  @observable isLoadingAgents = false;
  @observable isBillBuddyOpen = false;

  @action fetchBusiness(attrs, onSuccess, onError) {
    this.isLoadingBusiness = true;

    leaderboardApi.fetchBusinessList(attrs).then(res => {
      if (res?.errors?.length) {
        onError && onError(res.errors);
      } else if (res) {
        runInAction(() => {
          this.businessData = res.data;
          this.dataCompanies = res.dataCompanies;
          this.isLoadingBusiness = false;
        });

        onSuccess && onSuccess(res);
      }
    });
  }

  @action fetchCategoryAgents(attrs, selectedIds = []) {
    const wikiLogic = isWikiTableCategory(this.rootStore?.currentCategory);
    const apiCall = wikiLogic ? companyApi.fetchAgentsAllTypesWiki : companyApi.fetchAgentsAllTypes;
    this.isLoadingAgents = true;
    apiCall(attrs).then(res => {
      if (res?.errors?.length) {
        runInAction(() => {
          this.suggestedList = {
            provider: [],
            broker: [],
            comparison_site: []
          };
          this.isLoadingAgents = false;
        });
      } else {
        runInAction(() => {
          this.suggestedList = {
            provider: res?.provider?.companies || [],
            broker: res?.broker?.companies || [],
            comparison_site: res?.comparison_site?.companies || []
          };

          if (selectedIds.length > 0) {
            [...(res?.provider?.companies || []), ...(res?.broker?.companies || [])].forEach(item => {
              if (selectedIds.includes(item.id.toString())) {
                this.addRequestBusiness(item);
              }
            });
          }

          this.isLoadingAgents = false;
        });
      }
    });
  }

  @action cleanCategoryAgents() {
    this.suggestedList = {
      provider: [],
      broker: [],
      comparison_site: []
    };
  }

  // My Stats Price
  @observable statsData = {
    userData: [],
    membersData: []
  };
  @observable statsLabels = [];
  @observable isLoadingStats = false;

  @action fetchStats(attrs, onSuccess, onError) {
    this.isLoadingStats = true;

    leaderboardApi
      .fetchStatsList(attrs)
      .then(res => {
        if (res?.errors?.length) {
          onError && onError(res.errors);
        } else if (res) {
          runInAction(() => {
            this.statsData = res.data;
            this.statsLabels = res.labels;
            this.isLoadingStats = false;
          });
          onSuccess && onSuccess(res);
        }
      })
      .catch(e => {
        this.statsData = [];
        this.statsLabels = [];
      });
  }

  // My Stats Unit Prices
  @observable unitPricesData = {
    userData: [],
    membersData: []
  };
  @observable unitPricesLabels = [];
  @observable unitPricesCompanies = [];
  @observable isLoadingUnitPrices = false;

  @action fetchUnitPrices(attrs, onSuccess, onError) {
    this.isLoadingStats = true;

    leaderboardApi.fetchUnitPricesList(attrs).then(res => {
      if (res?.errors?.length) {
        onError && onError(res.errors);
      } else if (res) {
        runInAction(() => {
          this.unitPricesData = res.data;
          this.unitPricesLabels = res.labels;
          this.unitPricesCompanies = res.companies;
          this.isLoadingUnitPrices = false;
        });
        onSuccess && onSuccess(res);
      }
    });
  }

  // My products
  @observable myProductsTotalCount = 0;

  @action fetchLeaderboardCounter = async attrs => {
    this.isLoadingBusiness = true;

    const res = await leaderboardApi.fetchCategoryLeaderboardCounter(attrs);

    if (res?.errors?.length) {
      return Promise.reject(res.errors);
    }

    runInAction(() => {
      this.leaderboardCounter = res;
    });

    return res;
  };

  // Request Businesses
  @observable requestBusinesses = [];

  @action resetRequestBusinesses() {
    runInAction(() => {
      this.requestBusinesses = [];
    });
  }

  @action addRequestBusiness(business) {
    runInAction(() => {
      if (this.requestBusinesses.some(item => item?.id === business?.id)) {
        this.requestBusinesses = this.requestBusinesses.filter(item => +item.id !== +business.id);
      } else {
        this.requestBusinesses.push(business);
      }
    });
  }

  @action removeRequestBusiness(business) {
    runInAction(() => {
      this.requestBusinesses = this.requestBusinesses.filter(item => +item.id !== +business.id);
    });
  }

  @action showBillBuddy(value = false) {
    runInAction(() => {
      this.isBillBuddyOpen = value;
    });
  }
}
