const BillBuddyIconV2 = ({ fill = '#212529' }) => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.3334 2.00008H12.6667V1.33341C12.6667 0.966748 12.3667 0.666748 12 0.666748C11.6334 0.666748 11.3334 0.966748 11.3334 1.33341V2.00008L4.66671 2.00008V1.33341C4.66671 0.966748 4.36671 0.666748 4.00004 0.666748C3.63337 0.666748 3.33337 0.966748 3.33337 1.33341V2.00008H2.66671C1.93337 2.00008 1.33337 2.60008 1.33337 3.33341L1.33337 14.0001C1.33337 14.7334 1.93337 15.3334 2.66671 15.3334L13.3334 15.3334C14.0667 15.3334 14.6667 14.7334 14.6667 14.0001L14.6667 3.33341C14.6667 2.60008 14.0667 2.00008 13.3334 2.00008ZM12.6667 14.0001L3.33337 14.0001C2.96671 14.0001 2.66671 13.7001 2.66671 13.3334L2.66671 5.33341L13.3334 5.33341L13.3334 13.3334C13.3334 13.7001 13.0334 14.0001 12.6667 14.0001Z'
        fill='white'
      />
    </svg>
  );
};

export default BillBuddyIconV2;
