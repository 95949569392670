import { useMemo } from 'react';
import { toJS } from 'mobx';

import { useStore } from '@/packs/stores';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { maxRequestQuotes } from '@packs/lib/constants';

import { observer } from 'mobx-react';

const { text } = colors;

export const RequestCheckbox = observer(({ company }) => {
  const { companiesStore, leaderboardStore } = useStore();
  const isBusinessChecked = leaderboardStore.requestBusinesses.some(item => item.id === company.id);
  const disabledCheckbox = leaderboardStore.requestBusinesses.length >= maxRequestQuotes && !isBusinessChecked;

  const inRegion = useMemo(() => {
    const filterCity = companiesStore.filters.city || null;

    return !filterCity || filterCity === 'all' || toJS(company?.quoteAgent?.regions)?.includes(filterCity);
  }, [company?.quoteAgent?.regions]);

  const handleAddRequestBusiness = () => {
    if (!disabledCheckbox) {
      leaderboardStore.addRequestBusiness(company);
    }
  };

  if (!(company?.quoteAgent?.id && inRegion)) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1 }}>
      <FormControlLabel
        control={<Checkbox name='request' size='medium' sx={{ p: 0.5 }} />}
        label={
          <Typography variant='body3' sx={{ color: text.secondary }}>
            Request Quote
          </Typography>
        }
        labelPlacement='start'
        checked={isBusinessChecked}
        disabled={disabledCheckbox}
        onChange={handleAddRequestBusiness}
      />
    </Box>
  );
});
