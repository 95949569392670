import React from 'react';

import { FormControl, FormControlLabel, FormHelperText, Switch } from '@mui/material';

export const SwitchTempField = ({
  input: { name, value, onChange },
  meta: { error, touched },
  label,
  disabled,
  color = 'primary',
  size,
  labelPlacement = 'end'
}) => {
  return (
    <FormControl error={error}>
      <FormControlLabel
        control={<Switch />}
        label={label}
        color={color}
        name={name}
        checked={!!value}
        size={size}
        onChange={onChange}
        labelPlacement={labelPlacement}
        disabled={disabled}
      />
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};
