import React from 'react';

export default () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='19' viewBox='0 0 18 19'>
      <g fillRule='nonzero'>
        <path d='M14.091 3.64C13.308 1.514 11.321 0 9 0 6.678 0 4.692 1.514 3.909 3.64H.03v4.117h3.915c.089.228.191.45.308.665H.03v9.202h7.161v1.339h3.616v-1.339h7.071V8.422h-4.133c.117-.215.22-.437.308-.665h3.915V3.64h-3.878zM.574 7.193v-2.99h3.159a5.772 5.772 0 0 0-.064.28l-.007.037a5.726 5.726 0 0 0-.044.252l-.012.084-.01.075a5.97 5.97 0 0 0-.016.152l-.006.062a5.878 5.878 0 0 0-.017.283l-.002.054a5.907 5.907 0 0 0 0 .338l.002.047.007.15.006.083.005.06c.03.35.091.696.183 1.033H.574zm6.988 5.927h-.37v3.941H.574V8.985h4.03v.002l.065.09.004.005.06.08.014.017.009.012.012.016A5.42 5.42 0 0 0 7.562 11.1v2.02zm.174 5.28v-4.716h2.529V18.4h-2.53zm9.6-9.415v8.076h-6.528V13.12h-.37V11.1a5.42 5.42 0 0 0 2.794-1.893l.012-.016.01-.012.013-.017.06-.08.004-.006a5.71 5.71 0 0 0 .066-.09h3.94zM13.614 7.38l-.066.187c-.116.3-.26.587-.428.856h-.005l-.081.121a4.901 4.901 0 0 1-2.806 2.032l-.013.003c-.036.01-.072.02-.109.028l-.21.05v2.464H8.104v-2.464l-.21-.05a4.93 4.93 0 0 1-.11-.028l-.013-.003a4.9 4.9 0 0 1-2.806-2.032l-.08-.121H4.88a5.122 5.122 0 0 1-.428-.856l-.065-.187a5.24 5.24 0 0 1-.288-1.916l.002-.042c.002-.059.006-.118.01-.177l.004-.042a5.25 5.25 0 0 1 .014-.144l.005-.05a5.354 5.354 0 0 1 .143-.73l.005-.02.032-.113.007-.022C4.948 2.062 6.808.563 9 .563s4.052 1.499 4.679 3.56l.007.023.032.112.006.024a5.155 5.155 0 0 1 .142.727l.005.048.014.145.004.042c.004.06.008.118.01.177l.002.042c.002.062.004.124.004.187 0 .594-.098 1.176-.291 1.73zm3.812-.186h-3.184A5.807 5.807 0 0 0 14.43 6.1l.006-.083c.003-.05.006-.099.007-.149l.002-.047a5.76 5.76 0 0 0 0-.338l-.002-.054a6.163 6.163 0 0 0-.017-.283l-.005-.062a6.07 6.07 0 0 0-.017-.152l-.01-.076-.012-.082a5.522 5.522 0 0 0-.044-.253l-.007-.035a5.719 5.719 0 0 0-.063-.282h3.158v2.99z' />
        <path d='M9.207 14.171h1v1h-1zM9.207 15.568h1v1.994h-1zM12.783 5.588l-.003-.09V5.49a3.968 3.968 0 0 0-1.087-2.55l-.035-.034A3.705 3.705 0 0 0 9 1.773c-1.04 0-1.984.438-2.669 1.145l-.024.022a3.964 3.964 0 0 0-1.08 2.452v.006a4.299 4.299 0 0 0-.006.086l-.001.014a4.21 4.21 0 0 0-.003.08v.02a4.208 4.208 0 0 0 0 .2v.02c0 .027.002.054.003.08v.015l.006.086v.005a3.964 3.964 0 0 0 1.081 2.452l.08.084h.005A3.702 3.702 0 0 0 9 9.623c1.01 0 1.93-.412 2.609-1.083h.004l.08-.084a3.968 3.968 0 0 0 1.087-2.55V5.9l.003-.09v-.01a4.274 4.274 0 0 0 0-.201v-.01zM9 2.336c.637 0 1.232.192 1.734.523L7.27 2.857A3.14 3.14 0 0 1 9 2.337zM9 9.06c-.636 0-1.23-.19-1.73-.52h3.46c-.5.33-1.094.52-1.73.52zm3.242-3.36l-.003.144-.001.02a3.653 3.653 0 0 1-.009.131c0 .012-.002.023-.003.034a3.494 3.494 0 0 1-.048.335l-.01.047a3.339 3.339 0 0 1-.028.125l-.001.004a3.397 3.397 0 0 1-.757 1.437H6.618c-.356-.401-.62-.891-.756-1.435l-.002-.007-.028-.12-.01-.052a3.473 3.473 0 0 1-.048-.335l-.003-.032a3.497 3.497 0 0 1-.009-.133v-.018a3.525 3.525 0 0 1 0-.294v-.018c.002-.044.005-.088.01-.133 0-.01 0-.021.002-.032a3.396 3.396 0 0 1 .048-.335l.01-.05.028-.121.002-.007c.135-.544.4-1.034.756-1.435H9v.348c1.026 0 1.861.866 1.861 1.93h.543c0-1.015-.588-1.89-1.43-2.278h1.408c.356.4.62.892.757 1.436v.005c.011.04.02.082.029.123l.01.049a3.606 3.606 0 0 1 .048.335l.003.033.009.132v.02c.002.047.004.095.004.143V5.7zM11.304 12.336h1.48v1h-1.48zM13.167 12.336h3.766v1h-3.766zM14.525 14.82h2.408v1h-2.408zM11.39 13.634h5.543v1H11.39z' />
      </g>
    </svg>
  );
};
