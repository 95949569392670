import React, { useMemo } from 'react';

import { BecomePartner } from './BecomePartner';
import { Contact } from './Contact';
import { MembersAlsoLooked } from './MembersAlsoLooked';
import { Offer } from './Offer';
import OtherCategories from './OtherCategories';
import { RatingPerformance } from './RatingPerformance';

import { observer } from 'mobx-react';

export const BusinessSidebar = observer(({ category, company, IS_BUSINESS_PAGE = false, countryCode, companyId }) => {
  const isPartnered = useMemo(() => {
    return company?.quoteAgents?.some(agent => agent?.isPartner);
  }, [company?.quoteAgents]);

  return (
    <div className='business-sidebar'>
      <RatingPerformance />
      {!isPartnered && <BecomePartner />}
      <Contact {...{ company }} />

      {!IS_BUSINESS_PAGE && <Offer {...{ category, company }} />}

      <OtherCategories {...{ company, countryCode }} />

      {companyId && category?.id && (
        <MembersAlsoLooked
          {...{
            category,
            company
          }}
          companyId={companyId}
        />
      )}
    </div>
  );
});
