export const replaceHttps = url => (url ? url.replace(/https?:\/\//g, '') : '');

export const addHttps = url => {
  if (url && !url.startsWith('http://') && !url.startsWith('https://')) {
    return 'https://' + url;
  }

  return url;
};

export const businessPageUrl = () => {
  if (import.meta.env.VITE_SERVER_TYPE === 'develop') {
    return 'http://business.lvh.me:3000/';
  }
  if (import.meta.env.VITE_SERVER_TYPE === 'stag') {
    return 'https://business.stg.renewaldiary.com/';
  }
  if (import.meta.env.VITE_SERVER_TYPE === 'prod') {
    return 'https://www.business.billwinner.com/';
  }

  return 'https://www.business.billwinner.com/';
};

export const websitePageUrl = () => {
  if (import.meta.env.VITE_SERVER_TYPE === 'develop') {
    return 'http://localhost:3000/';
  }
  if (import.meta.env.VITE_SERVER_TYPE === 'stag') {
    return 'https://stg.renewaldiary.com/';
  }
  if (import.meta.env.VITE_SERVER_TYPE === 'prod') {
    return 'https://www.billwinner.com/';
  }

  return 'https://www.billwinner.com/';
};
