import React from 'react';
import cx from 'classnames';

export const BusinessSidebarBox = ({ icon, title, children, yellowBox = false }) => {
  return (
    <div className={cx('business-sidebar-box', { 'yellow-box': yellowBox })}>
      <div className='title d-flex align-items-center mb-20 pb-10'>
        {icon && <img src={icon} alt='Ico' className='mr-10' />}
        <div className='fs-14 fw-600 tt-uc'>{title}</div>
      </div>
      {children}
    </div>
  );
};
