import moment from 'moment';

import { WinnerModal as WinnerModalC } from './WinnerModal';

import { isValidPhoneNumber } from 'react-phone-number-input';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';

const validate = ({
  winnerCompany,
  pricesQuestions,
  discountPrice,
  purchaseScore,
  purchaseComment,
  isAutoQuote,
  phoneNumber,
  addSmartReminder,
  triggeredAt,
  topProvider,
  topAgencyBroker,
  topComparisonSite
} = {}) => {
  const errors = {};
  if (!winnerCompany) errors.winnerCompany = 'Required';
  if (!purchaseScore) errors.purchaseScore = 'Required';
  if (!pricesQuestions?.price?.value && discountPrice?.priceAfter) {
    errors.pricesQuestions = { price: { value: 'Please set the price' } };
  }

  if (discountPrice?.priceAfter && !discountPrice?.period) {
    errors.discountPrice = { period: 'Please set the period' };
  }

  if (!discountPrice?.priceAfter && discountPrice?.period) {
    errors.discountPrice = { priceAfter: 'Please set the price' };
  }

  if (purchaseComment && purchaseComment.length < 25) {
    errors.purchaseComment = 'Minimum 25 characters';
  }

  if (isAutoQuote && !phoneNumber) errors.phoneNumber = 'Required';
  if (isAutoQuote && phoneNumber && !isValidPhoneNumber(phoneNumber)) {
    errors.phoneNumber = 'Phone number is invalid';
  }

  if (addSmartReminder && moment(triggeredAt).diff(moment(), 'hours') < 0) {
    errors.triggeredAt = 'Reminder date must be in the future';
  }

  if (isAutoQuote && !topProvider && !topAgencyBroker && !topComparisonSite) {
    errors.topComparisonSite = 'Choose at least one source type';
  }

  return errors;
};

const mapStateToProps = (state, props) => ({ formAttributes: getFormValues(props.form)(state) });

const createReduxForm = reduxForm({ form: 'winnerForm', validate });

export const WinnerModal = createReduxForm(connect(mapStateToProps)(WinnerModalC));
