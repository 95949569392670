import _ from 'lodash-es';

import { INSURANCE_ORDER_OPTIONS, ORDER_OPTIONS, TRUST_NETWORK_ORDER_OPTION } from '@packs/models/company/constants';

export const sortSubcategoriesDesktop = (category, count = 7) => {
  if (category?.leafChildren) {
    return _(category?.leafChildren).value().eachSlice(count);
  }
  return [];
};

export const sliceSubCategories = (category, count = 7) => {
  return _(category.leafChildren).value().eachSlice(count);
};

export const sortOptions = ({ currentUser, isInsuranceCategory }) => {
  let list = isInsuranceCategory ? INSURANCE_ORDER_OPTIONS : ORDER_OPTIONS;
  if (currentUser.id) {
    list = [...list, TRUST_NETWORK_ORDER_OPTION];
  }

  return list;
};
