import React from 'react';

import Switch from '@mui/material/Switch';

export const Switcher = ({ value, onChange, label = '', size = 'small', disabled = false, name }) => {
  const handleChange = ev => {
    if (!disabled) {
      onChange(ev);
    }
  };

  return (
    <Switch checked={!!value} onChange={handleChange} name={name} size={size} sx={{ mr: 0 }} disabled={disabled} />
  );
};
