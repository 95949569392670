import {
  fetchBusiness,
  fetchCategoryLeaderboardCounter,
  fetchStats,
  fetchUnitPrices
} from '@packs/graphql/queries/leaderboard';
import { graphQLRequest } from '@packs/lib/utils';

const api = {
  fetchStatsList: variables =>
    graphQLRequest({
      query: fetchStats,
      variables
    }),
  fetchBusinessList: variables =>
    graphQLRequest({
      query: fetchBusiness,
      variables
    }),
  fetchUnitPricesList: variables =>
    graphQLRequest({
      query: fetchUnitPrices,
      variables,
      apiPath: '/profiles'
    }),
  fetchCategoryLeaderboardCounter: variables =>
    graphQLRequest({
      query: fetchCategoryLeaderboardCounter,
      variables
    })
};

export default api;
