import React from 'react';

const CommentIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' stroke='#6E7F95'>
      <path
        d='M20.9999 11.291C20.9999 15.83 16.9519 19.473 11.9999 19.473C11.3589 19.473 10.7349 19.41 10.1309 19.294'
        stroke='#6E7F95'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.599 17.826C4.42 16.336 3 13.974 3 11.291'
        stroke='#6E7F95'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.59925 17.8262C6.59825 18.6802 6.60025 19.8322 6.60025 21.0412'
        stroke='#6E7F95'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3 11.2914C3 6.75235 7.048 3.11035 12 3.11035C16.952 3.11035 21 6.75335 21 11.2924'
        stroke='#6E7F95'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.1296 19.29L6.59961 21.04'
        stroke='#6E7F95'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.1768 11.3232C12.2744 11.4209 12.2744 11.5791 12.1768 11.6768C12.0791 11.7744 11.9209 11.7744 11.8232 11.6768C11.7256 11.5791 11.7256 11.4209 11.8232 11.3232C11.9209 11.2256 12.0791 11.2256 12.1768 11.3232'
        stroke='#6E7F95'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.1768 11.3232C16.2744 11.4209 16.2744 11.5791 16.1768 11.6768C16.0791 11.7744 15.9209 11.7744 15.8232 11.6768C15.7256 11.5791 15.7256 11.4209 15.8232 11.3232C15.9209 11.2256 16.0791 11.2256 16.1768 11.3232'
        stroke='#6E7F95'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.17678 11.3232C8.27441 11.4209 8.27441 11.5791 8.17678 11.6768C8.07915 11.7744 7.92085 11.7744 7.82322 11.6768C7.72559 11.5791 7.72559 11.4209 7.82322 11.3232C7.92085 11.2256 8.07915 11.2256 8.17678 11.3232'
        stroke='#6E7F95'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default CommentIcon;
