import { action, observable } from 'mobx';

export class ModalStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable type;
  @observable props;

  @observable subType;
  @observable subProps;

  @action
  open({ type, props }) {
    this.type = type;
    this.props = props;
  }

  @action
  close() {
    this.type = null;
    this.props = null;
  }

  @action
  openSub({ type, props }) {
    this.subType = type;
    this.subProps = props;
  }

  @action
  closeSub() {
    this.subType = null;
    this.subProps = null;
  }
}
