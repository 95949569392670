import React, { useMemo } from 'react';
import { get } from 'lodash-es';

import Box from '@mui/material/Box';
import { ReplyItem } from '@shared/components/QA/ReplyItem';

import { observer } from 'mobx-react';

export const RepliesList = observer(({ postId, commentId, questionsStore, currentUser, toggleSignUpModal }) => {
  const replies = useMemo(() => {
    const comments = get(questionsStore.answers, `${postId}`, []);
    const comment = comments.find(item => item?.id === commentId);
    return get(comment, 'comments', []);
  }, [commentId, questionsStore.answers]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
      {replies.map(reply => (
        <ReplyItem
          key={reply?.id}
          answer={reply}
          {...{ questionsStore, currentUser, toggleSignUpModal, commentId, postId }}
        />
      ))}
    </Box>
  );
});
