import React from 'react';

import { BusinessSidebarBox } from '@packs/components/UI';
import OfferIcon from '@packs/images/svg/ui/offer.svg';
import { useDetails } from '@packs/models/policy/useDetails';
import { useStore } from '@packs/stores';

import { observer } from 'mobx-react';

export const Offer = observer(({ category, company }) => {
  const { shoppingCartStore, companiesStore } = useStore();
  const { currentPolicy } = useDetails();

  const categoryName = category?.name;

  const offerLink = company?.partnerProvider?.customWebLink || '';
  const affinityLink = company?.affinityLink?.url || '';
  const websiteLink = affinityLink || offerLink || company.url;

  const addToShoppingCart = () => {
    const opts = {
      categoryId: +category?.id,
      companyIds: [+company?.id],
      policyId: currentPolicy?.id
    };

    shoppingCartStore.createShoppingCartItems(opts).catch(() => {});
  };

  const handleClickWebsiteLink = company => {
    companiesStore.companyUpdateClicks({ companyId: +company.id, categoryId: +category?.id });
  };

  const handleVisitWebsite = () => {
    handleClickWebsiteLink(company);
    addToShoppingCart();
  };

  return (
    <BusinessSidebarBox title='OFFER' icon={OfferIcon} yellowBox>
      <div className='fs-14 fw-600 lh-5 mb-15 tt-uc'>{`ENJOY 20% OFF YOUR ${categoryName} BY BUYING ONLINE*`}</div>
      <div className='fs-14 lh-5'>{`*20% online discount automatically applied to all new business ${company?.name} Direct ${categoryName} quotes. Subject to minimum premium.`}</div>
      {websiteLink && (
        <div className='d-flex justify-content-center mt-25'>
          <a
            href={websiteLink}
            target='_blank'
            rel='noopener noreferrer'
            className='btn full white-bg'
            onClick={handleVisitWebsite}
          >
            <span>Click to buy</span>
          </a>
        </div>
      )}
    </BusinessSidebarBox>
  );
});
