import React from 'react';

export const Local_servicesHardware = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <g>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M2 13.02C2.00004 13.2234 2.08007 13.4184 2.22248 13.5622L2.53692 13.8797C2.67934 14.0234 2.87247 14.1042 3.07384 14.1042C3.27521 14.1042 3.46834 14.0234 3.61076 13.8797L6.78036 10.6791L8.96037 12.8806L11.7935 10.051L9.4872 7.94588L11.9315 5.47771C12.0739 5.3339 12.1538 5.13889 12.1538 4.93555C12.1538 4.73221 12.0739 4.5372 11.9315 4.39339L9.78384 2.22476C9.64193 2.08152 9.44966 2.00081 9.24901 2.00025C9.04837 1.99969 8.85565 2.07932 8.71297 2.22176L6.25382 4.67945L5.65609 4.07589C5.35916 3.77606 4.87842 3.77606 4.58225 4.07589L2.94925 5.72483C2.65232 6.02388 2.65232 6.51009 2.94925 6.80914L3.53363 7.39923L2.22545 8.70669C2.15404 8.778 2.09735 8.86289 2.05865 8.95647C2.01995 9.05004 2.00002 9.15044 2 9.25185V13.02ZM3.5188 11.8039L3.5188 9.57235L9.24395 3.84824L10.3208 4.93555L3.5188 11.8039Z'
          />
          <path d='M17.0312 4.13719C17.3467 4.05801 17.6563 4.02029 17.957 4.01702C18.2576 4.01374 18.549 4.0456 18.83 4.10764C19.212 4.19236 19.3336 4.68915 19.0566 4.96854L17.9999 6.03434C17.4479 6.59112 17.4479 7.49487 17.9999 8.05165C18.5519 8.60844 19.4479 8.60844 19.9999 8.05165L21.0566 6.98586C21.3336 6.70647 21.8261 6.82908 21.9101 7.21439C22.0331 7.78126 22.0388 8.39233 21.8808 9.02879C21.4898 10.6073 20.1429 11.839 18.5429 12.0508C17.7586 12.1545 17.0113 12.0236 16.3574 11.7258L14.871 13.225L20.1972 18.5953C20.9542 19.3589 20.9542 20.5986 20.1972 21.3632C19.4392 22.1268 18.2101 22.1268 17.4531 21.3632L12.1288 15.9909L6.58587 21.5819C6.03387 22.1387 5.13788 22.1387 4.58588 21.5819C4.03387 21.0251 4.03387 20.1213 4.58588 19.5646L14.3574 9.70846C14.0619 9.04878 13.932 8.29529 14.0351 7.50398C14.2451 5.89013 15.4662 4.53157 17.0312 4.13719Z' />
        </g>
      </g>
    </svg>
  );
};
