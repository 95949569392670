import React from 'react';
import moment from 'moment';

import colors from '@packs/assets/theme/base/colors';
import { Avatar, PostBadge } from '@packs/components/UI';
import { shortSecondName } from '@packs/lib/helpers/additionalHelpers';
import { SUB_ICONS } from '@shared/helpers/subCategoryIcon';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { QuoteRequestsTable } from '../RequestsAgentsTable/QuoteRequestsTable';

export const RequestsItem = ({ request, postType = 'request', owner = null }) => {
  const { isMobile } = useWindowSize();

  const { user, createdAt, category, quoteRequests = [] } = request;

  const avatarUrl = owner?.avatarUrl || user?.avatarUrl || '';
  const displayName = owner?.displayName || user?.displayName || '';
  const categoryImg = category?.code && category?.root?.enName;

  return (
    <div className='requests-item'>
      <div className='top-part d-flex align-items-start justify-content-between'>
        <div className='user-info d-flex align-items-center'>
          <div className='img-container'>
            <Avatar avatarUrl={avatarUrl} placeholder={displayName} size={48} fullSize withBorder withBG />
          </div>
          <div className='pl-15 flex-grow-1'>
            <div className='user-name fs-14 fw-600 lh-5 position-relative'>
              <div className='name'>{shortSecondName(displayName)}</div>
            </div>
            <div className='fs-12 lh-5 secondary-text'>{(createdAt && moment(createdAt).fromNow()) || '-'}</div>
          </div>
        </div>
      </div>
      <div className='d-flex align-items-center justify-content-between mb-16'>
        <PostBadge type={postType} />
        {categoryImg && (
          <div className='d-flex align-items-center'>
            {SUB_ICONS({ category, fill: colors?.category[category?.root?.enName]?.main })}
            {!isMobile && <div className='ml-10 fs-14'>{category?.name}</div>}
          </div>
        )}
      </div>
      <QuoteRequestsTable {...{ quoteRequests }} />
    </div>
  );
};
