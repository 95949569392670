import React, { useRef, useState } from 'react';

import { FieldWithErrors } from '@packs/components/FieldWithErrors';
import { SignUpModalLink } from '@packs/components/SignUpModalLink';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { runFormSubmit, toastr } from '@packs/lib/helpers';
import { useStore } from '@packs/stores';

import { ModalBody, ModalFooter } from 'reactstrap';
import { Field } from 'redux-form';

const NewAssociationModalForm = ({
  formAttributes,
  toggle,
  handleSubmit,
  onCreate,
  validate,
  enableUnAuthBusiness
}) => {
  const { toggleNewAssociationModal } = useModalsContext();

  const [state, setStateLocal] = useState({ errors: {} });
  const { currentUser } = useUserSelectors();
  const { errors } = state;
  const { associationStore, currentCountry } = useStore();
  const formRef = useRef();

  const setState = newState => setStateLocal(st => ({ ...st, ...newState }));

  const resetErrors = () => {
    setState({ errors: null });
  };

  const metaError = name => {
    const mess = errors && errors[name];
    if (!mess) return null;
    return { meta: { touched: !!mess, error: mess } };
  };

  const onSubmit = ev => {
    const { name } = formAttributes || {};

    const callback = ({ client }) => {
      toastr.success(`New ${name} successed created`);
      onCreate && onCreate(client);
      toggleNewAssociationModal();
    };

    const errorCallback = ({ errors }) => {
      this.setState({ errors });
      toggleNewAssociationModal();
    };

    associationStore.create(
      {
        ...formAttributes,
        countryCode: currentCountry.isoA2Code
      },
      callback,
      errorCallback
    );
  };

  const isFormValid = () => {
    const errors = validate(formAttributes);

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });

      return false;
    }
    return true;
  };

  return (
    <form id='new-association-form' ref={formRef} onSubmit={handleSubmit(onSubmit)}>
      <ModalBody>
        <label className='input-field'>
          <div className='input-label'> Association name</div>
          <Field
            component={FieldWithErrors}
            type='text'
            name='name'
            placeholder='Association name'
            className='form-input with-border'
            onChange={resetErrors}
            {...metaError('name')}
          />
        </label>
      </ModalBody>

      <ModalFooter>
        <div className='btn cancel' onClick={toggle}>
          Cancel
        </div>
        {currentUser?.id || enableUnAuthBusiness ? (
          <button
            className='btn empty black'
            // disabled={!confirmed}
            type='submit'
            onClick={ev => {
              if (!isFormValid()) {
                ev.preventDefault();
              }
            }}
          >
            <span>Create Association</span>
          </button>
        ) : (
          <SignUpModalLink
            validate={isFormValid}
            onSignUpSuccess={() => {
              runFormSubmit(formRef.current);
            }}
          >
            <button className='btn empty black' type='button'>
              <span>Create Association</span>
            </button>
          </SignUpModalLink>
        )}
      </ModalFooter>
    </form>
  );
};

export default NewAssociationModalForm;
