import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { usePath } from '@packs/layouts/constants';
import { useStore } from '@packs/stores';
import { EmptyPlaceholder } from '@shared/components/EmptyPlaceholder/EmptyPlaceholder';

import { ShortListSlider } from '../../ShortListSlider';
import { CategoryTopBar } from '../CategoryTopBar/CategoryTopBar';

import size from 'lodash-es/size';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

export const ShortList = observer(({ setActiveStep, detailsIsLoading }) => {
  const { DASHBOARD_MY_RESULTS_PATH } = usePath();
  const { leaderboardStore, currentCategory } = useStore();
  const fullList = [...leaderboardStore.suggestedList.provider, ...leaderboardStore.suggestedList.broker];

  if (!leaderboardStore?.isLoadingAgents && !detailsIsLoading && size(fullList) === 0) {
    return (
      <EmptyPlaceholder
        description={`We apologize, unfortunately there are no Agents and Providers in the ${currentCategory.name} category yet. We are working to ensure that they appear here soon.`}
      >
        <Button variant='contained' color='primary' component={Link} to={DASHBOARD_MY_RESULTS_PATH}>
          Go to Leaderboard
        </Button>
      </EmptyPlaceholder>
    );
  }

  return (
    <div>
      <CategoryTopBar description='Select one or more businesses to request quotes!' />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4
        }}
      >
        <ShortListSlider companyType='provider' title='Top providers' setActiveStep={setActiveStep} />

        <ShortListSlider companyType='broker' title='Top Broker' setActiveStep={setActiveStep} />
      </Box>
    </div>
  );
});
