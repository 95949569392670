import React from 'react';

import EmptyIcon from '@packs/images/svg/ui/empty-posts.svg';

export const EmptyState = ({ title = '', description = '' }) => {
  return (
    <div className='empty-posts d-flex flex-column align-items-center justify-content-center'>
      <div className='img-container mb-24'>
        <img src={EmptyIcon} alt='Empty' />
      </div>
      <div className='title'>{title}</div>
      <div className='description'>{description}</div>
    </div>
  );
};
