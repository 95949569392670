import React from 'react';

export default () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
      <g fillRule='nonzero'>
        <path d='M6.64 2.399a.417.417 0 0 0-.287-.273L4.648 1.66 3.678.182c-.154-.235-.542-.235-.696 0l-.97 1.478-1.705.466a.417.417 0 0 0-.216.662l1.107 1.379-.085 1.766a.416.416 0 0 0 .564.409l1.653-.626 1.654.626a.416.416 0 0 0 .563-.41l-.084-1.765 1.106-1.38a.417.417 0 0 0 .07-.388zm-1.926 1.37a.417.417 0 0 0-.09.28l.061 1.29-1.208-.457a.414.414 0 0 0-.294 0l-1.208.457.062-1.29a.416.416 0 0 0-.091-.28l-.808-1.007 1.246-.34a.416.416 0 0 0 .238-.174L3.33 1.17l.708 1.08c.056.084.14.146.239.173l1.245.34-.808 1.007zM8.325 2.075h7.076v1H8.325zM8.325 4.157H19.98v1H8.325zM6.64 9.201a.417.417 0 0 0-.287-.273l-1.705-.465-.97-1.478c-.154-.235-.542-.235-.696 0l-.97 1.478-1.705.465a.417.417 0 0 0-.216.662l1.107 1.38-.085 1.765a.416.416 0 0 0 .564.41l1.653-.626 1.654.626a.416.416 0 0 0 .563-.41l-.084-1.765 1.106-1.38a.417.417 0 0 0 .07-.389zm-1.926 1.37a.417.417 0 0 0-.09.28l.061 1.29-1.208-.457a.414.414 0 0 0-.294 0l-1.208.457.062-1.29a.416.416 0 0 0-.091-.28l-.808-1.007 1.246-.34a.416.416 0 0 0 .238-.173l.708-1.079.708 1.08c.056.084.14.146.239.173l1.245.34-.808 1.007zM8.325 8.735h7.076v1H8.325zM8.325 10.817H19.98v1H8.325zM6.64 16.004a.417.417 0 0 0-.287-.273l-1.705-.465-.97-1.479c-.154-.234-.542-.234-.696 0l-.97 1.479-1.705.465a.417.417 0 0 0-.216.662l1.107 1.38-.085 1.765a.416.416 0 0 0 .564.41l1.653-.626 1.654.625a.416.416 0 0 0 .563-.409l-.084-1.766 1.106-1.379a.417.417 0 0 0 .07-.389zm-1.926 1.37a.417.417 0 0 0-.09.28l.061 1.29-1.208-.457a.414.414 0 0 0-.294 0l-1.208.457.062-1.29a.416.416 0 0 0-.091-.28l-.808-1.007 1.246-.34a.416.416 0 0 0 .238-.173l.708-1.08.708 1.08c.056.085.14.147.239.173l1.245.34-.808 1.007zM8.325 15.812h7.076v1H8.325zM8.325 17.893H19.98v1H8.325z' />
      </g>
    </svg>
  );
};
