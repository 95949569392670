import React, { useContext } from 'react';
import cx from 'classnames';

import { useDetailsForm } from '@packs/screens/PersonalizeResults/DetailsFormProvider';

import { DebugName } from './debug';

import DatePicker from 'react-datepicker';

export const DateField = props => {
  const { getRef, onChange } = useDetailsForm();
  const { name, type, value, question } = props;

  if (!name) {
    throw new Error('no name');
  }

  const placeholder = props.placeholder || (question && question.placeholder) || '';

  const handleChange = date => {
    const value = date.toString();
    onChange({ target: { name, value } }, { isSliderSteps: props.isSliderSteps });
    props.onChange && props.onChange(value);
  };

  const handleDateChangeRaw = e => {
    e.preventDefault();
  };

  return (
    <div className={cx('field field-input')}>
      <DebugName name={name} />

      <DatePicker
        selected={value ? new Date(value) : null}
        dateFormat='MMMM d, yyyy'
        placeholderText={placeholder}
        customInputRef={getRef}
        onChange={handleChange}
        onChangeRaw={handleDateChangeRaw}
        showYearDropdown
        scrollableYearDropdown
        yearDropdownItemNumber={25}
      />
    </div>
  );
};
