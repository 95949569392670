import React from 'react';
import cx from 'classnames';

import { SelectField } from '@packs/components/SelectField';
import { useCompanyFilterOptions } from '@packs/lib/helpers/useCompanyFilterOptions';
import { useCategories } from '@packs/models/category/useCategories';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { observer } from 'mobx-react';
import { Field } from 'redux-form';

export const SelectFieldProviderCompany = observer(({ companyId, companyType, allTypeOptions, className }) => {
  const { currentCategory } = useCategories();
  const { isMobile } = useWindowSize();

  const { loadOptions, defaultOptions, formatOptionLabel } = useCompanyFilterOptions({
    category: currentCategory,
    buyingType: companyType,
    value: companyId,
    type: 'provider',
    allTypeOptions,
    isSetCallback: true,
    isFetchCompanies: !isMobile
  });

  return (
    <Field
      component={SelectField}
      name='providerCompany'
      className={cx('select-ui', className)}
      classNamePrefix='Select'
      formatOptionLabel={formatOptionLabel}
      loadOptions={loadOptions}
      options={defaultOptions}
      returnObjectValue
    />
  );
});
