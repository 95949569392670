import { action, observable, toJS } from 'mobx';
import _ from 'lodash-es';

import { renewalDetailApi } from '@packs/apis';
import {
  defaultHideBlock,
  defaultHideCliteria,
  defaultShowFields
} from '@packs/screens/PersonalizeResults/formHooks/defaults';

import { apply } from 'json-logic-js';

export class DetailsFormStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable formOptions = {};
  @observable formValues = {};
  @observable hideCliteria = defaultHideCliteria || {};
  @observable hideBlock = defaultHideBlock || {};
  @observable showFields = defaultShowFields || {};
  @observable showSkipButton = {};
  @observable loadingsComponentOptions = {};
  @observable formErrors = {};
  @observable submitFormValues = {};
  @observable nodes = {};
  @observable clear = false;
  @observable previousReviews = {
    sourceProviderCompanyList: [],
    anyCompanyList: []
  };
  @observable prevChangedField = null;
  @observable changedField = null;
  @observable criteriaKeys = [];

  @action setFormValues(formValues) {
    this.formValues = _.cloneDeep(formValues);
  }

  @action setFormValuesForSubmit() {
    this.submitFormValues = _.cloneDeep(this.formValues);
  }

  @action setFormOptions(formOptions) {
    this.formOptions = formOptions;
  }

  @action setCriteriaKeys(keys) {
    this.criteriaKeys = keys || [];
  }

  @action setFormErrors(formErrors) {
    this.formErrors = formErrors;
    if (!_.isEmpty(this.formErrors) && import.meta.env.MODE !== 'production') {
      // eslint-disable-next-line no-console
      console.log('setFormErrors', JSON.stringify(this.formErrors, null, 2));
    }
  }

  @action setHideCliteria(hideCliteria) {
    this.hideCliteria = hideCliteria;
  }

  @action setHideBlock(hideBlock) {
    this.hideBlock = hideBlock;
  }

  @action setShowFields(showFields) {
    this.showFields = showFields;
  }

  @action setShowSkipButton(showFields) {
    this.showSkipButton = showFields;
  }

  @action setLoadingsComponentOptions(obj) {
    this.loadingsComponentOptions = obj;
  }

  @action setNodes(nodes) {
    this.nodes = nodes;
  }

  @action setClear(val) {
    this.clear = val;
  }

  @action change(name, value) {
    const formValues = _.clone(this.formValues);
    _.set(formValues, name, value);
    this.formValues = formValues;
  }

  @action fetchPreviewReviews(companyId, name) {
    this.previousReviews[`${name}List`] = [];

    renewalDetailApi.fetchUserPreviewReviews({ companyId }).then(result => {
      const reviews = _.clone(this.previousReviews);
      reviews[`${name}List`] = result;
      this.setPreviousReviews(reviews);
    });
  }

  @action setPreviousReviews(values) {
    this.previousReviews = values;
  }

  @action setChanged(val) {
    this.prevChangedField = this.changedField;
    this.changedField = val;
  }

  criteriaVisibleByConditions = criteria => {
    if (!criteria.conditions) {
      return true;
    }
    // const varKeys = jsonLogic.uses_data(criteria.conditions);
    const criteriaValues = toJS(this.formValues.criteriaQuestions);
    return apply(criteria.conditions, criteriaValues);
  };
}
