import {
  countryCategories,
  countryZipCities,
  enableAccessSite,
  subscribeToInvitation,
  userAvailableRenewalDetailCategories,
  userZipSubscribeMutation
} from '@packs/graphql/queries/signUp';
import { graphQLRequest } from '@packs/lib/utils';

export const signUpApi = {
  fetchCategories: ({ countryCode }) =>
    graphQLRequest({
      query: countryCategories,
      variables: { countryCode }
    }),
  fetchCategoriesWithPolicy: () =>
    graphQLRequest({
      query: userAvailableRenewalDetailCategories
    }),
  enableAccessSite: variables =>
    graphQLRequest({
      query: enableAccessSite,
      variables
    }),
  subscribeToInvitation: variables =>
    graphQLRequest({
      query: subscribeToInvitation,
      variables
    }),
  userZipSubscribe: data =>
    graphQLRequest({
      query: userZipSubscribeMutation,
      variables: { input: data }
    }),
  countryZipCities: variables =>
    graphQLRequest({
      query: countryZipCities,
      variables
    })
};
