import React, { Fragment } from 'react';
import cx from 'classnames';

import { NumberInput } from '@packs/components/NumberInput';

import { SYMBOLS_AFTER_DOT } from './constants';

import NumericInput from 'react-numeric-input';

export const PriceField = ({
  input,
  meta: { touched, error },
  currencySymbol,
  disabled = false,
  withDot = false,
  incrementStep,
  placeholder,
  symbolsAfterDot,
  maxValue = false,
  className,
  handleChange = null,
  innerClassName,
  customNumeric = false
}) => {
  const onFocusHandler = () => input.onChange('');
  const onInputChange = data => {
    input.onChange(data);
    if (handleChange) handleChange(data, input.name);
  };

  if (customNumeric) {
    return (
      <>
        <div className={cx('price-input', className)}>
          <NumericInput
            {...{ placeholder }}
            style={false}
            precision={2}
            name={input.name}
            value={input.value}
            className={cx(className, 'reminder-input', innerClassName)}
            onChange={onInputChange}
          />
          <span className='price-currency'>{currencySymbol}</span>
        </div>
        {touched && error && <span className='error-hint'>{error}</span>}
      </>
    );
  }

  return (
    <Fragment>
      <div className={cx('price-input', className)}>
        {incrementStep ? (
          <NumericInput
            {...{ placeholder }}
            step={incrementStep}
            min={0}
            name={input.name}
            value={input.value}
            className={cx(className, 'reminder-input', innerClassName)}
            onChange={onInputChange}
          />
        ) : (
          <NumberInput
            {...{
              disabled,
              placeholder,
              maxValue
            }}
            className={cx(className, innerClassName)}
            name={input.name}
            value={input.value}
            symbolsAfterDot={withDot ? (symbolsAfterDot ? symbolsAfterDot : SYMBOLS_AFTER_DOT) : 0}
            inputProps={{ onFocus: onFocusHandler }}
            onChange={onInputChange}
          />
        )}
        <span className='price-currency'>{currencySymbol}</span>
      </div>
      {touched && error && <span className='error-hint'>{error}</span>}
    </Fragment>
  );
};
