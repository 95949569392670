import { numberWithCommas } from '@packs/lib/constants';
import {
  COMPANY_PER_MONTH,
  COMPANY_PER_YEAR,
  NO_PERSONALIZATION_CATEGORIES,
  NO_WIKI_TABLE_CATEGORIES,
  WIKI_TABLE_CATEGORIES
} from '@packs/models/category/constants';

export const isWikiTableCategory = category => {
  return (
    !NO_WIKI_TABLE_CATEGORIES.some(item => item === category?.code || item === category?.root?.code) ||
    WIKI_TABLE_CATEGORIES.some(item => item === category?.code || item === category?.root?.code)
  );
};

export const isCompanyPerMonth = category => {
  return (
    COMPANY_PER_MONTH.some(item => item === category?.code || item === category?.root?.code) &&
    !COMPANY_PER_YEAR.some(item => item === category?.code || item === category?.root?.code)
  );
};

export const priceWithCommas = price => {
  if (!price) {
    return '';
  }
  return numberWithCommas(price.toFixed(2));
};

export const noPersonalizationCategory = category => {
  return !NO_PERSONALIZATION_CATEGORIES.some(item => item === category?.code || item === category?.root?.code);
};

export const categoryHavePolicy = (category, categories, currentUser) => {
  if (!currentUser.id) {
    return false;
  }

  return categories.some(item => item === category?.id);
};
