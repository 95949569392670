export default () => ({
  wrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 2
  },
  bodyWrapper: {
    flexGrow: 1
  },
  bodyContainer: {
    p: 2,
    borderRadius: '8px',
    backgroundColor: '#F4F4F4'
  },
  actionPanelWrapper: {
    display: 'flex',
    alignItems: 'center',
    mt: 1
  }
});
