export const SYMBOLS_AFTER_DOT = 3;
export const CURRENCY_OPTIONS = [
  { value: 'ALL', label: 'Lek(ALL)' },
  { value: 'AFN', label: '؋(AFN)' },
  { value: 'ARS', label: '$(ARS)' },
  { value: 'AWG', label: 'ƒ(AWG)' },
  { value: 'AUD', label: '$(AUD)' },
  { value: 'AZN', label: '₼(AZN)' },
  { value: 'BSD', label: '$(BSD)' },
  { value: 'BBD', label: '$(BBD)' },
  { value: 'BYN', label: 'Br(BYN)' },
  { value: 'BZD', label: 'BZ$(BZD)' },
  { value: 'BMD', label: '$(BMD)' },
  { value: 'BOB', label: '$(BOB)' },
  { value: 'BAM', label: 'KM(BAM)' },
  { value: 'BWP', label: 'P(BWP)' },
  { value: 'BGN', label: 'лв(BGN)' },
  { value: 'BRL', label: 'R$(BRL)' },
  { value: 'BND', label: '$(BND)' },
  { value: 'KHR', label: '៛(KHR)' },
  { value: 'CAD', label: '$(CAD)' },
  { value: 'KYD', label: '$(KYD)' },
  { value: 'CLP', label: '$(CLP)' },
  { value: 'CNY', label: '¥(CNY)' },
  { value: 'COP', label: '$(COP)' },
  { value: 'CRC', label: '₡(CRC)' },
  { value: 'HRK', label: 'k(HRK)' },
  { value: 'CUP', label: '₱(CUP)' },
  { value: 'CZK', label: 'Kč(CZK)' },
  { value: 'DKK', label: 'kr(DKK)' },
  { value: 'DOP', label: 'RD$(DOP)' },
  { value: 'XCD', label: '$(XCD)' },
  { value: 'EGP', label: '£(EGP)' },
  { value: 'SVC', label: '$(SVC)' },
  { value: 'EUR', label: '€(EUR)' },
  { value: 'FKP', label: '£(FKP)' },
  { value: 'FJD', label: '$(FJD)' },
  { value: 'GHS', label: '¢(GHS)' },
  { value: 'GIP', label: '£(GIP)' },
  { value: 'GTQ', label: 'Q(GTQ)' },
  { value: 'GGP', label: '£(GGP)' },
  { value: 'GYD', label: '$(GYD)' },
  { value: 'HNL', label: 'L(HNL)' },
  { value: 'HKD', label: '$(HKD)' },
  { value: 'HUF', label: 'Ft(HUF)' },
  { value: 'ISK', label: 'kr(ISK)' },
  { value: 'IDR', label: 'Rp(IDR)' },
  { value: 'IRR', label: '﷼	(IRR)' },
  { value: 'IMP', label: '£(IMP)' },
  { value: 'ILS', label: '₪(ILS)' },
  { value: 'JMD', label: 'J$(JMD)' },
  { value: 'JPY', label: '¥(JPY)' },
  { value: 'JEP', label: '£(JEP)' },
  { value: 'KZT', label: 'лв(KZT)' },
  { value: 'KPW', label: '₩(KPW)' },
  { value: 'KRW', label: '₩(KRW)' },
  { value: 'KGS', label: 'лв(KGS)' },
  { value: 'LAK', label: '₭(LAK)' },
  { value: 'LBP', label: '£(LBP)' },
  { value: 'LRD', label: '$(LRD)' },
  { value: 'MKD', label: 'ден(MKD)' },
  { value: 'MYR', label: 'RM(MYR)' },
  { value: 'MUR', label: '₨(MUR)' },
  { value: 'MXN', label: '$(MXN)' },
  { value: 'MNT', label: '₮(MNT)' },
  { value: 'MZN', label: 'MT(MZN)' },
  { value: 'NAD', label: '$(NAD)' },
  { value: 'NPR', label: '₨(NPR)' },
  { value: 'ANG', label: 'ƒ(ANG)' },
  { value: 'NZD', label: '$(NZD)' },
  { value: 'NIO', label: 'C$(NIO)' },
  { value: 'NGN', label: '₦(NGN)' },
  { value: 'NOK', label: 'kr(NOK)' },
  { value: 'OMR', label: '﷼	(OMR)' },
  { value: 'PKR', label: '₨(PKR)' },
  { value: 'PAB', label: 'B(PAB)' },
  { value: 'PYG', label: 'Gs(PYG)' },
  { value: 'PEN', label: 'S(PEN)' },
  { value: 'PHP', label: '₱(PHP)' },
  { value: 'PLN', label: 'zł(PLN)' },
  { value: 'QAR', label: '﷼	(QAR)' },
  { value: 'RON', label: 'lei(RON)' },
  { value: 'RUB', label: '₽(RUB)' },
  { value: 'SHP', label: '£(SHP)' },
  { value: 'SAR', label: '﷼	(SAR)' },
  { value: 'RSD', label: 'Дин(RSD)' },
  { value: 'SCR', label: '₨(SCR)' },
  { value: 'SGD', label: '$(SGD)' },
  { value: 'SBD', label: '$(SBD)' },
  { value: 'SOS', label: 'S(SOS)' },
  { value: 'ZAR', label: 'R(ZAR)' },
  { value: 'LKR', label: '₨(LKR)' },
  { value: 'SEK', label: 'kr(SEK)' },
  { value: 'CHF', label: 'CHF' },
  { value: 'SRD', label: '$(SRD)' },
  { value: 'SYP', label: '£(SYP)' },
  { value: 'TWD', label: 'NT$(TWD)' },
  { value: 'THB', label: '฿(THB)' },
  { value: 'TTD', label: 'TT$(TTD)' },
  { value: 'TVD', label: '$(TVD)' },
  { value: 'UAH', label: '₴(UAH)' },
  { value: 'GBP', label: '£(GBR)' },
  { value: 'USD', label: '$(USD)' },
  { value: 'UYU', label: '$U(UYU)' },
  { value: 'UZS', label: 'лв(UZS)' },
  { value: 'VEF', label: 'Bs(VEF)' },
  { value: 'VND', label: '₫(VND)' },
  { value: 'YER', label: '﷼	(YER)' },
  { value: 'ZWD', label: 'Z$(ZWD)' }
];
