import colors from '@packs/assets/theme/base/colors';

const { borderColor, grey } = colors;
export default () => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    width: '100%',
    maxWidth: '560px',
    mx: 'auto',
    px: {
      xs: 2,
      sm: 0
    }
  },
  wrapperMainPart: {
    flexGrow: 1
  },
  progressTopBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 1
  },
  progress: {
    height: '16px',
    borderRadius: '16px',
    border: `1px solid ${borderColor.main}`,
    backgroundColor: grey[50]
  },
  progressText: {
    fontSize: {
      xs: '14px',
      md: '16px'
    },
    lineHeight: {
      xs: '21px',
      md: '24px'
    }
  }
});
