import colors from '@packs/assets/theme/base/colors';

import { NoDetailsCategory } from './components/NoDetailsCategory';

const { primary, dark, borderColor } = colors;

export default () => ({
  wrapper: {
    pb: {
      xs: 4,
      md: 8
    }
  },
  container: {
    display: 'flex',
    grow: 4
  },
  actionsBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 3,
    mb: 3
  },
  toolbarWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: {
      xs: 'column',
      sm: 'row'
    },
    mb: '3px'
  },
  typeSwitcherWrapper: {
    display: 'flex',
    alignItems: 'center',
    pr: {
      xs: 0,
      sm: 2
    }
  },
  activeTypeSwitcher: {
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: '100%',
      left: 0,
      right: '0',
      height: '4px',
      borderRadius: '4px 4px 0 0',
      backgroundColor: primary.main,
      zIndex: 2
    }
  },
  reminderCategoryHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  reminderCategoryWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    borderRadius: '4px',
    p: 0.5,
    backgroundColor: dark.transparent
  },
  reminderCategoryLink: {
    color: dark.main
  },
  reminderCategoryPolicy: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    pt: 1,
    pb: 2,
    borderBottom: `1px solid ${borderColor.main}`
  },
  autoQuoteWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    my: 1.5
  },
  noDetailsWrapper: {
    mb: 3
  },
  noDetailsContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1,
    gap: 2
  }
});
