import React from 'react';

export default () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.944 19H5.056C4.473 19 4 18.527 4 17.944V10.556C4 9.973 4.473 9.5 5.056 9.5H6.944C7.527 9.5 8 9.973 8 10.556V17.944C8 18.527 7.527 19 6.944 19V19Z'
      stroke='#6E7F95'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8 10.5716L11.649 5.82058C12.328 4.93558 13.654 4.91358 14.363 5.77458V5.77458C14.628 6.09558 14.772 6.49958 14.772 6.91558V10.1866H17.868C18.469 10.1866 19.03 10.4866 19.364 10.9856L19.693 11.4766C19.988 11.9176 20.074 12.4666 19.927 12.9756L18.568 17.6976C18.346 18.4686 17.641 18.9996 16.839 18.9996H10.55C10.05 18.9996 9.572 18.7916 9.232 18.4256L8 17.0996'
      stroke='#6E7F95'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
