import React from 'react';

import Box from '@mui/material/Box';
import colors from '@packs/assets/theme/base/colors';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const { borderColor } = colors;
export const NotesListSkeleton = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
      {[...Array(10)].map((_, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 3,
            borderRadius: '16px',
            border: `1px solid ${borderColor.main}`
          }}
        >
          <SkeletonTheme color='#E4EEFA' highlightColor='#fff'>
            <Box sx={{ display: 'flex', mb: 3 }}>
              <Box sx={{ mr: 2 }}>
                <Skeleton count={1} height={40} maxWidth={40} width={'100%'} />
              </Box>
              <Skeleton count={1} height={40} maxWidth={100} width={'100%'} />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Skeleton count={1} height={30} maxWidth={400} width={'100%'} />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Skeleton count={1} height={90} width='100%' />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flexWrap: 'wrap' }}>
              <Skeleton count={1} height={85} width={85} />
              <Skeleton count={1} height={85} width={85} />
              <Skeleton count={1} height={85} width={85} />
              <Skeleton count={1} height={85} width={85} />
              <Skeleton count={1} height={85} width={85} />
            </Box>
          </SkeletonTheme>
        </Box>
      ))}
    </Box>
  );
};
