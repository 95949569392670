import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { AutocompleteMUI } from '@packs/components/MaterialUi/AutocompleteMUI/AutocompleteMUI';
import { MaterialRadioGroup } from '@packs/components/MaterialUi/MaterialRadioGroup/MaterialRadioGroup';
import { PageTitleWrapper } from '@packs/components/MaterialUi/PageTitleWrapper/PageTitleWrapper';
import { MyNotesActionPanel } from '@packs/components/UI/MyNotes/components/MyNotesActionPanel/MyNotesActionPanel';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { useCategories } from '@packs/models/category/useCategories';
import { useStore } from '@packs/stores';
import useLeaderboardStyles from '@packs/styles/Leaderboard/leaderboardWrapper';
import { useWindowSize } from '@shared/hooks/useWindowSize';
import { getWindow } from '@shared/lib/utils';

import { NotesList } from './components/NotesList/NotesList';
import { NotesTopBar } from './components/NotesTopBar/NotesTopBar';
import useStyles from './styles';

import { observer } from 'mobx-react';
import { Lightbox } from 'react-modal-image';

export interface IOption {
  label: string;
  value: string;
}

export const MyNotes = observer(({ general = false }) => {
  const { isDesktop } = useWindowSize();
  const styles = useStyles();
  const { notesStore, currentCategory } = useStore();
  const { toggleNoteModalOpen } = useModalsContext();
  const { categoriesOptions } = useCategories();
  const stylesLeaderboard = useLeaderboardStyles();

  const [loading, setLoading] = useState(false);

  const categoriesList: IOption[] = [
    { value: '', label: 'All categories' },
    ...categoriesOptions.map(({ code, label }) => ({ value: code, label }))
  ];
  const currentYear: IOption = { value: moment().year(), label: moment().year() };
  const yearsList: IOption[] = [
    { value: 'all', label: 'All years' },
    currentYear,
    { value: moment().subtract(1, 'years').year(), label: moment().subtract(1, 'years').year() },
    { value: moment().subtract(2, 'years').year(), label: moment().subtract(2, 'years').year() },
    { value: moment().subtract(3, 'years').year(), label: moment().subtract(3, 'years').year() }
  ];

  const INITIAL_FILTERS = {
    selectedCategory: categoriesList[0] as IOption,
    selectedYear: yearsList[0] as IOption
  };

  const [selectedCategory, setSelectedCategory] = useState<IOption>(INITIAL_FILTERS.selectedCategory);
  const [selectedYear, setSelectedYear] = useState<IOption>(INITIAL_FILTERS.selectedYear);
  const [openImage, setOpenImage] = useState<File | null>(null);

  const notes = notesStore?.list;
  const disabledRemove = useMemo(() => {
    return (
      selectedYear.value === INITIAL_FILTERS.selectedYear.value &&
      selectedCategory.value === INITIAL_FILTERS.selectedCategory.value
    );
  }, [
    INITIAL_FILTERS.selectedCategory.value,
    INITIAL_FILTERS.selectedYear.value,
    selectedCategory.value,
    selectedYear.value
  ]);

  const onResetFilters = () => {
    if (!disabledRemove) {
      setSelectedCategory(INITIAL_FILTERS.selectedCategory);
      setSelectedYear(INITIAL_FILTERS.selectedYear);
    }
  };

  const fetchNotes = () => {
    setLoading(true);

    notesStore.setNoteYear(selectedYear.value.toString());
    const filters: Record<string, string> = {};

    if (notesStore.noteYear && notesStore.noteYear !== 'all') {
      filters.year = notesStore.noteYear;
    }

    if (selectedCategory.value) {
      filters.categoryCode = selectedCategory.value;
    }

    if (!general && currentCategory?.id) {
      filters.categoryId = currentCategory?.id;
    }

    notesStore
      .load({ filters })
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const toggle = () => {
    toggleNoteModalOpen({ modalProps: { category: currentCategory, general } });
  };

  const DocumentFilters = sidebarFilters => (
    <Box sx={styles.filtersWrapper}>
      {general && (
        <>
          <AutocompleteMUI
            value={categoriesList.find(item => item.value === selectedCategory.value)}
            options={categoriesList}
            onChange={val => setSelectedCategory(val)}
            label='Category'
            size={sidebarFilters ? 'small' : 'medium'}
            withTitle={sidebarFilters}
            mobileSelect
            filter
          />
          {!sidebarFilters && <Divider />}
        </>
      )}
      <Box>
        <Typography variant='body6'>Year:</Typography>
        <MaterialRadioGroup
          row={false}
          name='selectedYear'
          options={yearsList}
          value={yearsList.find(item => item.value === selectedYear?.value)}
          onChange={val => setSelectedYear(val)}
        />
      </Box>
    </Box>
  );

  useEffect(() => {
    fetchNotes();
  }, [selectedCategory, selectedYear]);

  const openLightbox = file => {
    setOpenImage(file);
  };

  const closeLightbox = () => {
    setOpenImage(null);
  };

  const handleDownload = ({ url, filename, isNew, file }) => {
    const document = getWindow().document;
    const a = document.createElement('a');
    a.setAttribute('download', filename);

    if (isNew) {
      const b = new Blob([file]);
      a.href = URL.createObjectURL(b);
      a.click();
    } else {
      fetch(url).then(t =>
        t.blob().then(b => {
          a.href = URL.createObjectURL(b);
          a.click();
        })
      );
    }
  };

  return (
    <>
      {general && (
        <PageTitleWrapper
          homeIsLanding
          title='Documents & Notes'
          currentPage='Documents & Notes'
          subTitle='Save your notes and documents for easy access.'
        />
      )}
      <Box sx={{ ...styles.wrapper, ...(general ? { pt: '0 !important' } : {}) }}>
        <NotesTopBar
          documentFilters={DocumentFilters}
          filters={{ selectedCategory, selectedYear }}
          {...{ INITIAL_FILTERS, onResetFilters, disabledRemove, general }}
        />
        <Box sx={stylesLeaderboard.container}>
          {isDesktop && (
            <Box sx={stylesLeaderboard.filterContainer}>
              <Box sx={stylesLeaderboard.filterContainerInner}>
                <Box sx={stylesLeaderboard.filterTitle}>
                  <Typography variant='body4'>Filter by:</Typography>
                  <Button
                    variant='text'
                    size='xs'
                    color='dark'
                    sx={{ minWidth: '44px' }}
                    disabled={disabledRemove}
                    onClick={onResetFilters}
                  >
                    Reset
                  </Button>
                </Box>
                {DocumentFilters(true)}
              </Box>
            </Box>
          )}
          <Box sx={stylesLeaderboard.content}>
            <MyNotesActionPanel
              filters={{ selectedCategory, selectedYear }}
              {...{ INITIAL_FILTERS }}
              toggleCreateModal={toggle}
            />
            {!general && (
              <Typography variant='h6' sx={{ mb: 2 }}>
                Documents & Notes
              </Typography>
            )}
            <NotesList
              {...{
                notes,
                openLightbox,
                handleDownload,
                toggle,
                general,
                loading
              }}
            />
          </Box>
        </Box>
        {openImage && (
          <Lightbox medium={openImage.url} large={openImage.url} alt={openImage.filename} onClose={closeLightbox} />
        )}
      </Box>
    </>
  );
});
