import React from 'react';

import Button from '@mui/material/Button';
import { useDetailsForm } from '@packs/screens/PersonalizeResults/DetailsFormProvider';

import { DebugName } from './debug';

export const SkipButton = props => {
  const { getRef, onChange } = useDetailsForm();
  const { btnText = 'NEXT / SKIP', value, name, multiple } = props;

  if (!name) {
    throw new Error('no name');
  }

  const onChangeRadio = () => {
    onChange({
      target: {
        name,
        multiple,
        value
      }
    });
    props.onChange && props.onChange(value);
  };

  return (
    <>
      <DebugName name={name} />
      <Button variant='contained' color='primary' onClick={onChangeRadio}>
        <span>{btnText}</span>
      </Button>
    </>
  );
};
