import React, { useEffect, useState } from 'react';
import _ from 'lodash-es';
import cx from 'classnames';

import { renewalDetailApi } from '@packs/apis';
import { FieldWithErrors } from '@packs/components/FieldWithErrors';
import PercentageField from '@packs/components/PercentageField';
import { PriceField } from '@packs/components/PriceField';
import { RadioButtonField } from '@packs/components/RadioButtonField';
import { StarRateField } from '@packs/components/RatingFormData/components';
import { SelectField } from '@packs/components/SelectField';
import { TitleComponent } from '@packs/components/UI';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { useCategories } from '@packs/models/category/useCategories';
import { locationOptions } from '@packs/screens/PersonalizeResults/formHooks/options';
import { useStore } from '@packs/stores';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { observer } from 'mobx-react';
import { Field } from 'redux-form';

const inputTypeFields = ['savingMetrePrice', 'string', 'priceType', 'textarea', 'occupationSelector', 'percentage'];
const selectTypeFields = [
  'location',
  'select',
  'carMake',
  'carModel',
  'petType',
  'carTrim',
  'bikeTrim',
  'bikeMake',
  'bikeModel',
  'mobileMake',
  'mobileModel',
  'autocomplete',
  'childrenCount'
];

const types = {
  select: SelectField,
  radio: RadioButtonField,
  star: StarRateField,
  location: SelectField,
  savingMetrePrice: FieldWithErrors,
  number: PriceField,
  // eslint-disable-next-line id-denylist
  string: FieldWithErrors,

  carMake: SelectField,
  carModel: SelectField,
  petType: SelectField,
  carTrim: SelectField,
  bikeTrim: SelectField,
  bikeMake: SelectField,
  bikeModel: SelectField,
  mobileMake: SelectField,
  mobileModel: SelectField,

  autocomplete: SelectField,
  childrenCount: SelectField,
  priceType: FieldWithErrors,
  textarea: FieldWithErrors,
  occupationSelector: FieldWithErrors,
  percentage: PercentageField
};

export const PersonalizeQuestions = observer(({ formAttributes }) => {
  const { currentUser } = useUserSelectors();
  const { currentCategory } = useCategories();
  const appContext = useModalsContext();
  const { isMobile } = useWindowSize();
  const { countryCode, currentCountry } = useStore();

  const [simpleCriterias, setSimpleCriterias] = useState([]);

  const criteriaQuestions = formAttributes?.criteriaQuestions || {};

  const fetchCriteria = async () => {
    const data = await renewalDetailApi.fetchCriteriaQuestionList({
      categoryId: +currentCategory.id,
      formType: 'leaderboard_simple'
    });
    if (!data.errors) {
      setSimpleCriterias(data);
    }
  };

  useEffect(() => {
    fetchCriteria();
  }, []);

  return (
    <div className='personalize-unauthorized-questions position-relative'>
      {_.map(simpleCriterias, question => {
        const { type, title, name, options, withDot, placeholder, currencySymbol, symbolsAfterDot } = question;
        const Component = types[type] || 'div';

        if (
          !question.type ||
          (type === 'childrenCount' && formAttributes?.criteriaQuestions?.typeOfCoverRequired !== 'family')
        ) {
          return null;
        }

        if (question.type === 'group') {
          return <TitleComponent title={question.title} />;
        }
        const sanitizedTitle = `${title}`.replace(':category_name', currentCategory.name);
        const inputField = inputTypeFields.includes(type);
        const selectField = selectTypeFields.includes(type);
        let selectOptions = options || [];
        if (type === 'location') {
          selectOptions = locationOptions(currentCountry);
        }
        const showTitle = currentCategory.code === 'pet_insurance';

        return (
          <div key={question.id} className='question-field'>
            {showTitle && <div className='question-title'>{sanitizedTitle}</div>}
            <div className={cx('field-types-list', question.className)}>
              <Field
                category={currentCategory}
                {...{
                  type,
                  question,
                  withDot,
                  appContext,
                  placeholder,
                  countryCode,
                  currentUser,
                  currentCountry,
                  currencySymbol,
                  symbolsAfterDot
                }}
                className={cx(
                  'question-field-element',
                  inputField && 'form-input',
                  selectField && 'select-ui white-bg with-out-border no-scale'
                )}
                innerClassName={type === 'number' && 'form-input'}
                classNamePrefix='Select'
                component={Component}
                name={`criteriaQuestions.${name}`}
                options={selectOptions}
                minLabel='Very poor'
                maxLabel='Excellent'
                swipePicker={isMobile}
                customValue={criteriaQuestions[name] || ''}
                multiple={question.multiple}
                mobileDrawer
                // search
              />
            </div>
            {/* { showError && <div className="field-error">Required</div> }*/}
          </div>
        );
      })}
    </div>
  );
});
