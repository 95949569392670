/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React base styles
import borders from '@crm/assets/theme/base/borders';
import boxShadows from '@crm/assets/theme/base/boxShadows';
import colors from '@crm/assets/theme/base/colors';
// Material Dashboard 2 PRO React helper functions
import pxToRem from '@shared/assets/theme/functions/pxToRem';

const { grey, white } = colors;
const { borderRadius } = borders;
const { tabsBoxShadow } = boxShadows;

const tabs = {
  variants: [
    {
      props: { variant: 'buttonTabs' },
      style: {
        position: 'relative',
        backgroundColor: '#F0F2F5',
        borderRadius,
        minHeight: 'unset',
        padding: pxToRem(4),

        '& .MuiTabs-flexContainer': {
          height: '100%',
          position: 'relative',
          zIndex: 10
        },

        '&.MuiTabs-fixed': {
          overflow: 'unset !important',
          overflowX: 'unset !important'
        },

        '&.MuiTabs-vertical .MuiTabs-indicator': {
          width: '100%'
        },

        '& .MuiTabs-indicator': {
          height: '100%',
          borderRadius: '4px',
          backgroundColor: white.main,
          transition: 'all 500ms ease'
        }
      }
    }
  ]
};

export default tabs;
