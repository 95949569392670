import React, { useEffect, useState } from 'react';
import { toJS } from 'mobx';
import { map } from 'lodash-es';

import { Avatar, Stack, Typography } from '@mui/material';
import { AutocompleteMUI } from '@packs/components/MaterialUi/AutocompleteMUI/AutocompleteMUI';
import { toastr } from '@packs/lib/helpers';
import { fetchCompanyFilter } from '@packs/lib/helpers/useCompanyFilterOptions';
import { useDetailsForm } from '@packs/screens/PersonalizeResults/DetailsFormProvider';
import { cantRemember } from '@packs/screens/PersonalizeResults/stepComponents/MyProvider';
import { useStore } from '@packs/stores/base';

import { observer } from 'mobx-react';

export const CompanyProviderSelect = observer(
  ({ category, name, label, isSliderSteps, onChange, disabled, widthOutReCache = false }) => {
    const { detailsFormStore } = useStore();

    const formValues = toJS(detailsFormStore.formValues, { recurseEverything: true });
    const value = formValues[name];
    const categoryId = category?.id;

    const { onChange: onDetailsFormChange } = useDetailsForm();

    const onSelectChange = dataValue => {
      onDetailsFormChange({ target: { name, value: dataValue?.value } }, { isSliderSteps });

      onChange && onChange(dataValue?.value);
    };

    const [loading, setLoading] = useState(true);

    const [search, setSearch] = useState('');

    const [options, setOptions] = useState<any[]>([]);

    const [open, setOpen] = useState(false);

    const [initialValueInitialized, setInitialValueInitialized] = useState(false);
    const [initialValue, setInitialValue] = useState(null);

    const [autoCompleteValue, setAutoCompleteValue] = useState(null);

    useEffect(() => {
      setSearch('');
      setInitialValue(value);
      setInitialValueInitialized(false);
      if (!value) {
        setAutoCompleteValue(null);
      }
    }, [value]);

    useEffect(() => {
      setLoading(true);
      fetchCompanyFilter(search, initialValue, categoryId, 'provider')
        .then((resp: any) => {
          if (resp.errors?.length) {
            toastr.error(map(resp.errors, 'message').join(', '));
          }

          const opts = resp.companies.map(c => ({ value: c.id, label: c.name, ...c }));

          setOptions([cantRemember, ...opts]);
        })
        .finally(() => {
          setLoading(false);
        });
    }, [categoryId, search, initialValue]);

    useEffect(() => {
      if (loading || initialValueInitialized || options.length === 0) {
        return;
      }

      const targetVal = options.find(v => `${v['value']}` === `${initialValue}`);

      if (targetVal) {
        setAutoCompleteValue(targetVal);
        setInitialValueInitialized(true);
      }
    }, [initialValueInitialized, loading, options, initialValue]);

    return (
      <AutocompleteMUI
        value={autoCompleteValue}
        onChange={value => {
          setAutoCompleteValue(value);
          onSelectChange(value);
        }}
        sx={{ minWidth: 230 }}
        fullWidth
        width={'100%'}
        widthOutReCache={widthOutReCache}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => {
          if (!value?.value) {
            return false;
          }
          return option.value === value.value;
        }}
        getOptionLabel={option => {
          if (Object.keys(option).length) {
            return option.label;
          }
          return '';
        }}
        options={options}
        loading={loading}
        label={label}
        disabled={disabled}
        onInputChange={(_, newInputValue) => {
          setSearch(newInputValue);
        }}
        onFilter={setSearch}
        renderOption={(props, option) => (
          <li {...props}>
            <Stack direction='row' spacing={1} alignItems='center'>
              {option.value !== 'i_cant_remember' && (
                <Avatar src={option.avatarUrl} variant='rounded' sx={{ width: 28, height: 28 }} />
              )}
              <Typography>{option.name}</Typography>
            </Stack>
          </li>
        )}
      />
    );
  }
);
