import { AssociationMemberFormComponent } from './AssociationMemberForm';

import { connect } from 'react-redux';
import { getFormValues, reduxForm, reset } from 'redux-form';

const onSubmitSuccess = (result, dispatch) => dispatch(reset('associationEnquiry'));

const validate = ({ associationId = '', code = '' }) => {
  const errors = {};
  if (!associationId) errors.associationId = 'Required';
  if (!code) errors.code = 'Required';

  return errors;
};

const mapStateToProps = (state, props) => ({ formAttributes: getFormValues(props.form)(state) });

const createReduxForm = reduxForm({ form: 'associationMember', validate, onSubmitSuccess });

export const AssociationMemberForm = createReduxForm(connect(mapStateToProps)(AssociationMemberFormComponent));
