import React from 'react';

export const GovernmentPropertyTax = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <g>
          <path d='M10.5 10.75C10.5 10.3299 10.1701 10 9.75 10C9.32986 10 9 10.3299 9 10.75C9 11.1701 9.32986 11.5 9.75 11.5C10.1701 11.5 10.5 11.1701 10.5 10.75Z' />
          <path d='M15 15.25C15 14.8299 14.6701 14.5 14.25 14.5C13.8299 14.5 13.5 14.8299 13.5 15.25C13.5 15.6701 13.8299 16 14.25 16C14.6701 16 15 15.6701 15 15.25Z' />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M22.797 11.098L22.794 11.106C22.916 11.198 23 11.336 23 11.5C23 11.776 22.776 12 22.5 12H20V20C20 20.552 19.552 21 19 21H5C4.448 21 4 20.552 4 20V12H1.5C1.224 12 1 11.776 1 11.5C1 11.336 1.084 11.198 1.206 11.106L1.203 11.098L11.29 2.297C11.472 2.114 11.722 2 12 2C12.278 2 12.528 2.113 12.71 2.296L22.797 11.098ZM8 10.75C8 9.78942 8.78942 9 9.75 9C10.7106 9 11.5 9.78942 11.5 10.75C11.5 11.7106 10.7106 12.5 9.75 12.5C8.78942 12.5 8 11.7106 8 10.75ZM13.7589 9.55817C13.8296 9.51835 13.9091 9.49638 13.9902 9.49414C14.0825 9.4916 14.1736 9.51464 14.2536 9.56071C14.3336 9.60678 14.3992 9.67408 14.4433 9.75517C14.4874 9.83625 14.5082 9.92795 14.5034 10.0201C14.4985 10.1123 14.4683 10.2013 14.416 10.2773L10.416 16.2773C10.3801 16.3331 10.3334 16.3812 10.2788 16.4187C10.2241 16.4563 10.1625 16.4826 10.0976 16.4961C10.0326 16.5096 9.96565 16.5101 9.90054 16.4974C9.83542 16.4848 9.77347 16.4594 9.71828 16.4226C9.66309 16.3858 9.61578 16.3384 9.57908 16.2831C9.54239 16.2279 9.51706 16.1659 9.50456 16.1007C9.49207 16.0356 9.49265 15.9686 9.50629 15.9037C9.51992 15.8388 9.54633 15.7773 9.58398 15.7227L13.584 9.72266C13.6281 9.65444 13.6881 9.59798 13.7589 9.55817ZM12.5 15.25C12.5 14.2894 13.2894 13.5 14.25 13.5C15.2106 13.5 16 14.2894 16 15.25C16 16.2106 15.2106 17 14.25 17C13.2894 17 12.5 16.2106 12.5 15.25Z'
          />
        </g>
      </g>
    </svg>
  );
};
