import React, { useState } from 'react';
import _ from 'lodash-es';

import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { Avatar } from '@packs/components/UI';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { usePath } from '@packs/layouts/constants';
import { toastr } from '@packs/lib/helpers';
import { useCategories } from '@packs/models/category/useCategories';
import { useStore } from '@packs/stores';
import { TooltipMUI } from '@shared/components/TooltipMUI/TooltipMUI';

import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

export const UserMenu = observer(() => {
  const { currentUser } = useUserSelectors();
  const { categories } = useCategories();
  const { userStore, categoryStore } = useStore();
  const { ROOT_PATH, PROFILE_SLAG_PATH_FN, MY_NOTES_PATH, ASSOCIATION_PATH, INVITE_FRIENDS_PATH } = usePath();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { avatarUrl, displayName } = currentUser;
  const navigate = useNavigate();

  const defaultCategorySlag = _.get(categories, '[0].leafChildren[0]');

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOutHandler = () => {
    userStore.logOut().then(() => {
      toastr.success('Signed out successfully.');
      navigate(ROOT_PATH);
    });
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <TooltipMUI title='Account menu' arrow>
          <Box onClick={handleClick} display='flex' alignItems='center' gap={2} sx={{ cursor: 'pointer' }}>
            <Avatar size={40} variant='rounded' placeholder={displayName} avatarUrl={avatarUrl} fullSize withBG />
          </Box>
        </TooltipMUI>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            // px: 1,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box sx={{ px: 2 }}>
          <Typography
            variant='body3'
            sx={{ maxWidth: '190px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {displayName}
          </Typography>
        </Box>
        <Divider sx={{ my: 1 }} />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          <MenuItem
            onClick={() => {
              navigate(PROFILE_SLAG_PATH_FN(defaultCategorySlag));
            }}
          >
            <Typography variant='body4'>Profile</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              categoryStore.setCurrentCategory(defaultCategorySlag);
              navigate(MY_NOTES_PATH);
            }}
          >
            <Typography variant='body4'>Documents & Notes</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate(INVITE_FRIENDS_PATH);
            }}
          >
            <Typography variant='body4'>Trust Network</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              categoryStore.setCurrentCategory(defaultCategorySlag);
              navigate(ASSOCIATION_PATH);
            }}
          >
            <Typography variant='body4'>Associations</Typography>
          </MenuItem>
          <Divider sx={{ my: 1 }} />
          <MenuItem onClick={logOutHandler}>
            <ListItemIcon>
              <LogoutRoundedIcon fontSize='small' color='dark' />
            </ListItemIcon>
            <Typography variant='body4'>Logout</Typography>
          </MenuItem>
        </Box>
      </Menu>
    </>
  );
});
