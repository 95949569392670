import React, { useEffect, useState } from 'react';
import _ from 'lodash-es';

import CloseIcon from '@packs/images/icons/CloseIcon';
import StarIcon from '@packs/images/icons/StarIcon';
import PlusCircleIcon from '@packs/images/icons/UI/PlusCircleIcon';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { useStore } from '@packs/stores/base';
import { getWindow } from '@shared/lib/utils';

import { SwipePicker } from '../../../components';

import { observer } from 'mobx-react';
import Select from 'react-select';

export const AssociationField = observer(props => {
  const windowSize = getWindow().innerWidth;

  const { options, category, currentUser, setRerender, rerender, swipePicker } = props;
  const { associationStore, currentCountry } = useStore();
  const { toggleNewAssociationModal } = useModalsContext();

  if (!associationStore) return null;

  useEffect(() => {
    associationStore.setParam({
      countryCode: currentCountry.isoA2Code,
      userId: +currentUser.id,
      categoryId: category.id
    });

    if (currentUser.id) associationStore.fetchUserAssociations();
    associationStore.fetchAssociations();
    // associationStore.fetchPopularAssociations();
  }, [currentCountry.isoA2Code, currentUser.id, category.id]);

  const [selectValue, setValue] = useState(null);

  const onSelect = value => {
    setValue(value);
    addAssociationSelect();
  };

  const addAssociation = item => {
    associationStore.setMyAssociation(item);
    setValue('');
    setTimeout(() => {
      setRerender(!rerender);
    }, 150);
  };

  const removeAssociation = item => {
    associationStore.removeMyAssociation(item);
    setRerender(!rerender);
  };

  const addAssociationSelect = value => {
    if (value?.object) {
      addAssociation(value.object);
    } else {
      const findObj = mapOptions(associationStore.getAssociations).find(item => item.value === value?.value);

      if (findObj) {
        addAssociation(findObj?.object);
      }
    }
  };

  const onInputChange = value => {
    associationStore.fetchAssociations({ name: value });
  };

  const mapOptions = arr =>
    arr.map(ob => ({
      value: ob.id,
      label: ob.name,
      object: ob
    }));

  return (
    <div className='association-container'>
      <div className='field'>
        <div className='top-association-box d-flex align-items-center'>
          <div className='select-holder'>
            {windowSize > 767 ? (
              <Select
                classNamePrefix='Select'
                className='react-select select-ui white-bg with-out-border'
                value=''
                filterOption={all => all}
                options={mapOptions(associationStore.getAssociations)}
                onInputChange={onInputChange}
                onChange={addAssociationSelect}
              />
            ) : (
              <SwipePicker
                options={mapOptions(associationStore.getAssociations)}
                value={{ value: null }}
                placeholder='Select...'
                useOptions
                onChange={addAssociationSelect}
              />
            )}
            {selectValue && selectValue.value && (
              <div className='plus-item' onClick={addAssociationSelect}>
                +
              </div>
            )}
            <div className='d-flex align-items-center justify-content-center fs-12 w-100 mt-20 add-association-title'>
              Can’t find your association in search?
              <div className='cursor-pointer ml-20' onClick={toggleNewAssociationModal}>
                <PlusCircleIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
      {_.size(associationStore.myAssociations) > 0 && (
        <div className='field'>
          <div className='title'>My Associations</div>
          <div className='association-list'>
            {associationStore.myAssociations.map(item => (
              <div className='association-item' key={item.id}>
                <div className='name'>{item.name}</div>
                <div className='delete-item' onClick={() => removeAssociation(item)}>
                  <CloseIcon />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {associationStore.popularAssociations.length > 0 && (
        <div className='field'>
          <div className='title d-flex align-items-center'>
            <StarIcon />
            Popular associations
          </div>
          <div className='association-list'>
            {associationStore.popularAssociations.map(item => (
              <div className='association-item' key={item.id}>
                <div className='name'>{item.name}</div>
                <div className='plus-item' onClick={() => addAssociation(item)}>
                  +
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
});
