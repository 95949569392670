import React from 'react';

import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { getTotalRating } from '@shared/helpers/companyHelper';

import useStyles from './styles';

export const RatingsBusiness = ({ company, colors, children }) => {
  const styles = useStyles({ colors });

  const { totalRating, totalReviews } = getTotalRating(company);

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.mainSection}>
        <Typography variant='body4' sx={styles.ratingsTitle}>
          Total ratings score:
        </Typography>
        <Typography variant='h5'>{totalRating} out of 5</Typography>
        <Box sx={styles.mainRatingStars}>
          <Rating value={totalRating} max={5} readOnly size='large' />
        </Box>
        <Typography variant='body3' sx={{ color: colors.text.secondary }}>
          Ratings based on {totalReviews} Insights
        </Typography>

        <Box sx={{ width: '100%', mt: 2 }}>{children}</Box>
      </Box>
    </Box>
  );
};
