import React, { useRef, useState } from 'react';
import moment from 'moment';

import CommentRoundedIcon from '@mui/icons-material/CommentRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { SignInModalLink } from '@packs/components';
import { commentableType } from '@packs/lib/constants';
import { shortSecondName } from '@packs/lib/helpers/additionalHelpers';
import { avatarPlaceholder, stringToColor } from '@shared/helpers/additionalHelpers';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { RepliesListInsight } from '../RepliesListInsight/RepliesListInsight';
import { ReviewForm } from '../ReviewForm/ReviewForm';

import useStyles from './styles';

import AnimateHeight from 'react-animate-height';

export const CommentItem = ({
  comment,
  postId,
  withoutComments,
  insightsCompanyStore,
  currentUser,
  toggleSignUpModal
}) => {
  const styles = useStyles();
  const answerItemRef = useRef(null);
  const { isMobile } = useWindowSize();
  const [showReplies, setShowReplies] = useState(false);

  const { id, user, message, createdAt, isHelpful, replies, helpfulCount, businessUser } = comment;
  const commentsCount = replies.length;

  const avatarUrl = businessUser?.company?.avatarUrl || user?.avatarUrl || '';
  const displayName = businessUser?.businessName || user?.displayName || '';

  const toggleReplies = () => {
    setShowReplies(!showReplies);
  };

  const onToggleHelpful = () => {
    insightsCompanyStore.toggleHelpfulInsightComment({
      attrs: { commentId: id },
      postId,
      rootCommentId: id
    });
  };

  const submitCallback = () => {
    window.scrollTo({
      top: answerItemRef?.current?.offsetTop + answerItemRef?.current?.clientHeight - 100,
      behavior: 'smooth'
    });
  };

  return (
    <Box sx={styles.wrapper} ref={answerItemRef}>
      <Box sx={{ pt: 2 }}>
        <Avatar
          sx={{ width: '40px', height: '40px', backgroundColor: stringToColor(displayName) }}
          src={avatarUrl}
          variant='rounded'
        >
          {avatarPlaceholder(displayName)}
        </Avatar>
      </Box>
      <Box sx={styles.bodyWrapper}>
        <Box sx={styles.bodyContainer}>
          <Box sx={{ mb: 1 }}>
            <Typography variant='body2'>{shortSecondName(displayName)}</Typography>
            <Typography variant='body3' sx={{ color: 'rgba(0, 0, 0, 0.60)' }}>
              {(createdAt && moment(createdAt).fromNow()) || '-'}
            </Typography>
          </Box>
          <Box>
            <Typography variant='body1'>{message}</Typography>
          </Box>
        </Box>
        <Box sx={styles.actionPanelWrapper}>
          <Button
            variant='text'
            color='dark'
            size='smallToMedium'
            startIcon={<CommentRoundedIcon />}
            onClick={toggleReplies}
          >
            {!isMobile && 'Replies '}
            {commentsCount}
          </Button>
          {currentUser?.id ? (
            <Button
              variant='text'
              color={isHelpful ? 'primary' : 'dark'}
              size='smallToMedium'
              startIcon={<ThumbUpAltRoundedIcon />}
              onClick={onToggleHelpful}
            >
              {!isMobile && 'Helpful '}
              {helpfulCount || 0}
            </Button>
          ) : (
            <SignInModalLink variant='text' color='dark' size='smallToMedium' startIcon={<ThumbUpAltRoundedIcon />}>
              {!isMobile && 'Helpful '}
              {helpfulCount || 0}
            </SignInModalLink>
          )}
        </Box>
        {!withoutComments && (
          <Box>
            <AnimateHeight duration={250} height={showReplies ? 'auto' : 0}>
              <ReviewForm
                submitBtnText='Add a comment'
                placeholder='Add a reply...'
                commentableType={commentableType.comment}
                commentableId={id}
                postId={postId}
                submitCallback={submitCallback}
                createComment={insightsCompanyStore?.createCommentInsight}
                currentUser={currentUser}
                toggleSignUpModal={toggleSignUpModal}
              />
              <RepliesListInsight
                postId={postId}
                commentId={id}
                isOpen={showReplies}
                insightsCompanyStore={insightsCompanyStore}
                currentUser={currentUser}
              />
            </AnimateHeight>
          </Box>
        )}
      </Box>
    </Box>
  );
};
