import React from 'react';

export const BusinessCyberInsurance = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path d='M12 1C11.8764 1.00022 11.7538 1.0234 11.6387 1.06836L2.63867 4.56836C2.45067 4.64111 2.28906 4.76902 2.17508 4.93529C2.0611 5.10156 2.00006 5.29841 2 5.5V11C2 14.2822 4.624 20.4277 11.6641 22.9414C11.8149 22.9954 11.9766 23.0122 12.1353 22.9906C12.2941 22.969 12.4453 22.9096 12.5763 22.8173C12.7072 22.725 12.8141 22.6026 12.8878 22.4603C12.9616 22.3181 13.0001 22.1602 13 22V17H16V18.2695C15.6959 18.4451 15.4434 18.6976 15.2679 19.0018C15.0923 19.3059 14.9999 19.6508 15 20.002C15.0001 20.3531 15.0926 20.6981 15.2682 21.0021C15.4439 21.3061 15.6965 21.5586 16.0007 21.734C16.3049 21.9095 16.6498 22.0018 17.001 22.0016C17.3521 22.0014 17.697 21.9088 18.001 21.733C18.305 21.5573 18.5574 21.3046 18.7327 21.0004C18.9081 20.6962 19.0003 20.3511 19 20C18.9994 19.6495 18.9067 19.3054 18.7312 19.002C18.5557 18.6986 18.3035 18.4467 18 18.2715V16C18 15.7348 17.8946 15.4805 17.7071 15.2929C17.5195 15.1054 17.2652 15 17 15H13V13H19.2676C19.4876 13.3815 19.8274 13.6796 20.2343 13.8483C20.6411 14.0169 21.0922 14.0465 21.5176 13.9325C21.943 13.8185 22.3188 13.5674 22.5869 13.2179C22.8549 12.8685 23.0001 12.4404 23 12C23 11.4696 22.7893 10.9609 22.4142 10.5858C22.0391 10.2107 21.5304 10 21 10C20.6495 10.0006 20.3054 10.0933 20.002 10.2688C19.6986 10.4443 19.4467 10.6965 19.2715 11H13V9H17C17.2652 8.99997 17.5195 8.89461 17.7071 8.70708C17.8946 8.51955 18 8.26521 18 8V5.72852C18.3035 5.55328 18.5557 5.30136 18.7312 4.998C18.9067 4.69463 18.9994 4.35047 19 4C19 3.46957 18.7893 2.96086 18.4142 2.58579C18.0391 2.21071 17.5304 2 17 2C16.5602 2.0006 16.1329 2.14613 15.7842 2.41406C15.4355 2.68199 15.1848 3.05739 15.0709 3.48216C14.9571 3.90692 14.9864 4.35738 15.1543 4.76381C15.3223 5.17024 15.6195 5.50999 16 5.73047V7H13V2C13.0001 1.83725 12.9605 1.67693 12.8846 1.53295C12.8087 1.38898 12.6988 1.26571 12.5645 1.17383C12.3981 1.06028 12.2014 0.999688 12 1Z' />
      </g>
    </svg>
  );
};
