import React from 'react';

export const BusinessCorporatePensions = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path d='M21.525 16.214C21.059 15.914 20.441 16.047 20.142 16.511C19.841 16.977 19.495 17.4 19.131 17.801C18.792 16.977 18.517 16.105 18.327 15.184C18.055 13.865 17.972 12.55 18.057 11.301C18.63 11.653 19.017 12.28 19.017 13.001C19.017 13.554 19.464 14.001 20.017 14.001C20.57 14.001 21.017 13.554 21.017 13.001C21.017 10.795 19.223 9.00103 17.017 9.00103H7.817C7.407 6.19703 6.369 3.56803 4.789 1.41003C4.464 0.964029 3.836 0.865029 3.392 1.19303C2.946 1.52003 2.85 2.14603 3.176 2.59103C5.008 5.09403 6.018 8.33403 6.018 11.716C6.018 13.833 5.6 15.897 4.843 17.773C4.476 17.365 4.128 16.936 3.826 16.462C3.529 15.997 2.912 15.859 2.445 16.157C1.98 16.454 1.843 17.072 2.14 17.538C4.32 20.958 8.007 23 12 23C15.965 23 19.638 20.979 21.822 17.597C22.122 17.132 21.989 16.514 21.525 16.214ZM6.424 19.203C6.887 18.185 7.256 17.112 7.519 16H16.47C16.732 17.132 17.096 18.209 17.564 19.211C15.954 20.359 14.023 21 12 21C9.969 21 8.036 20.355 6.424 19.203Z' />
      </g>
    </svg>
  );
};
