import { IUser } from '@packs/interfaces/rootInterfaces';
import { IUserFormAttributes } from '@packs/layouts/AppLayer/components/AuthModal/AuthModal';
import { useStore } from '@packs/stores';

export const useUserSelectors = (): {
  profileStats: {
    reviewsCount: number;
    helpfullCount: number;
    remindersCount: number;
    tipBonusPoints: number;
    qaBonusPoints: number;
    reviewBonusPoints: number;
  };
  currentUser: IUser;
  resendConfirmationInstructionsFormErrors: string[];
  resendUnlockInstructionsFormErrors: string[];
  getSignUpFormInitialValues: (
    countryCode: string,
    user: IUser,
    modalProps: Record<string, string | boolean>
  ) => IUserFormAttributes;
} => {
  const { userStore } = useStore();

  const getSignUpFormInitialValues = (
    countryCode: string,
    user: IUser,
    modalProps = {} as Record<string, string | boolean>
  ): IUserFormAttributes => {
    const {
      registerBusiness,
      email,
      registerEvent,
      password,
      firstName,
      lastName,
      address,
      state,
      city,
      eirCodeAlias
    } = user as IUser;

    // return {
    //   email: 'test-test@com.com',
    //   password: 'QWe1231qwe',
    //   firstName: 'Tester',
    //   lastName: 'Testosterone',
    //   state: { value: 'Dublin', label: 'Dublin' },
    //   registerEvent,
    //   address: address || '',
    //   countryCode: countryCode || '',
    //   city,
    //   eirCodeAlias,
    //   registerBusiness: false
    // };

    return {
      registerBusiness,
      email,
      password,
      firstName,
      lastName,
      registerEvent,
      state,
      showSalesCode: !!modalProps?.signUpWithUniqCode,
      address: address || '',
      city,
      eirCodeAlias,
      countryCode: countryCode || ''
    };
  };

  return {
    profileStats: userStore.userProfileStats,
    currentUser: userStore.currentUser as IUser,
    resendConfirmationInstructionsFormErrors: userStore?.resendConfirmationInstructionsFormErrors,
    resendUnlockInstructionsFormErrors: userStore?.resendUnlockInstructionsFormErrors,
    getSignUpFormInitialValues
  };
};
