import colors from '@crm/assets/theme/base/colors';
import typography from '@crm/assets/theme/base/typography';
import pxToRem from '@shared/assets/theme/functions/pxToRem';

const { text, info, secondary, transparent } = colors;
const { size } = typography;

const buttonText = {
  base: {
    backgroundColor: transparent.main,
    minHeight: pxToRem(40),
    color: text.main,
    boxShadow: 'none',
    padding: `${pxToRem(10)} ${pxToRem(24)}`,

    '&:focus': {
      boxShadow: 'none'
    },

    '&:active, &:active:focus, &:active:hover': {
      opacity: 0.85,
      boxShadow: 'none'
    },

    '&:disabled': {
      boxShadow: 'none'
    },

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(16)} !important`
    }
  },

  small: {
    minHeight: pxToRem(32),
    padding: `${pxToRem(6)} ${pxToRem(16)}`,
    fontSize: `${pxToRem(13)}`,

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(16)} !important`
    }
  },

  smallToMedium: {
    minHeight: pxToRem(40),
    padding: `${pxToRem(6)} ${pxToRem(16)}`,
    fontSize: size.sm,

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(16)} !important`
    }
  },

  medium: {
    minHeight: pxToRem(48),
    padding: `${pxToRem(12)} ${pxToRem(28)}`,
    fontSize: pxToRem(16),

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(22)} !important`
    }
  },

  large: {
    minHeight: pxToRem(56),
    padding: `${pxToRem(14)} ${pxToRem(28)}`,
    fontSize: pxToRem(18),

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(22)} !important`
    }
  },

  primary: {
    color: info.main,

    '&:focus:not(:hover)': {
      color: info.focus,
      boxShadow: 'none'
    }
  },

  secondary: {
    color: secondary.main,

    '&:focus:not(:hover)': {
      color: secondary.focus,
      boxShadow: 'none'
    }
  }
};

export default buttonText;
