import { preferenceUpdateMutation } from '@packs/graphql/queries/preference';
import { graphQLRequest } from '@packs/lib/utils';

export const preferenceApi = {
  update: variables =>
    graphQLRequest({
      query: preferenceUpdateMutation,
      variables: { input: variables }
    })
};
