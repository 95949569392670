import React, { useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { useStore } from '@packs/stores';
import { getWindow } from '@shared/lib/utils';

import _find from 'lodash-es/find';
import orderBy from 'lodash-es/orderBy';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import Flag from 'react-world-flags';

const { white, dark } = colors;

export const CountrySelect = observer(
  ({ bgFilled, triggerStyles = {}, showCountryName = false, arrowFontSize = '16px' }) => {
    const { countryCode, countriesSelectOptions } = useStore();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = event => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const handleChange = selectedOption => {
      const currentPath = location.pathname;
      const newPath = currentPath.replace(/^\/\w{2}-\w{2}/, `/en-${selectedOption.value}`);
      handleMenuClose();
      navigate(newPath);
      location.reload();
      getWindow().localStorage.removeItem('region');
    };

    const countryCodeIso = countryCode.replace('en-', '');
    const options = orderBy(countriesSelectOptions.slice(), ['label'], ['asc']);
    const value = _find(options, { value: countryCodeIso });

    return (
      <Box>
        <Stack
          direction='row'
          alignItems='center'
          spacing={1}
          sx={{ ...triggerStyles, cursor: 'pointer' }}
          onClick={handleMenuOpen}
        >
          <Stack direction='row' alignItems='center' spacing={1}>
            <Box sx={{ width: '20px' }}>
              <Flag code={value.value === 'uk' ? 'gb' : value.value} />
            </Box>
            {showCountryName && <Typography variant='body4'>{value.label}</Typography>}
          </Stack>

          <KeyboardArrowDownIcon sx={{ color: bgFilled ? dark.main : white.main, fontSize: arrowFontSize }} />
        </Stack>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          {options.map((country, index) => (
            <MenuItem
              key={country.value}
              selected={country.value === value.value}
              onClick={() => handleChange(country)}
            >
              <ListItemIcon>
                <Flag code={country.value === 'uk' ? 'gb' : country.value} />
              </ListItemIcon>
              <Typography variant='body2' sx={{ ml: 2 }}>
                {country.label}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
    );
  }
);
