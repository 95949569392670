import _ from 'lodash-es';

export const pickPersonalizedCriteriaQuestionsFromOptions = (policyCriteria, formOptions, onlyMatches = false) => {
  const criteriaQuestions = policyCriteria || {};
  const criteriaOptions = formOptions || {};

  const keys = Object.keys(criteriaOptions || {});

  return keys.reduce((memo, key) => {
    let value = criteriaQuestions[key];

    if (isAgeCriteria(key)) {
      value = findValueInRange(criteriaQuestions.age, criteriaOptions[key].options)?.value;
    }

    memo[key] = value || (onlyMatches ? null : criteriaOptions[key].defaultValue) || null;

    return memo;
  }, {});
};

export const GetPolicyNameFromDetailsCollection = (details, next = false) => {
  if (details === undefined) return 'Policy 1';

  const ids = _.map(details, e => +(e.smartReminder?.aliasName || '').match(/\d+/));
  let policyIndex = 1;

  if (ids.length && (_.get(details, '0.id') || next)) {
    let num = 0;
    let i = 0;
    while (!num && i < 500) {
      if (!ids.includes(i + 1)) {
        num = i + 1;
      }
      i++;
    }
    policyIndex = num;
  }

  return `Policy ${policyIndex}`;
};

export const findValueInRange = (val, opts) =>
  _.find(opts, opt => {
    if (opt.value.match('less_')) {
      return val <= +opt.value.replace('less_', '');
    } else if (opt.value.match('over_')) {
      return val > +opt.value.replace('over_', '');
    }
    const spl = opt.value.split(/_+/);
    return val > +spl[0] && val <= +spl[1];
  });

export const isAgeCriteria = key => {
  return ['ageTypeOne', 'ageTypeTwo', 'ageTypeMotorbike'].includes(key);
};
