import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { pathGen } from '@packs/layouts/constants';
import { useStore } from '@packs/stores';

import { Link } from 'react-router-dom';

const { borderColor, dark } = colors;

export const SelectedCompany = ({ company }) => {
  const { countryCode } = useStore();

  if (!company) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '227px',
        py: '5.5px',
        px: 1,
        borderRadius: '4px',
        border: `1px solid ${borderColor.main}`,
        color: dark.main
      }}
      component={Link}
      to={pathGen('BUSINESS', { countryCode, company })}
    >
      <Box>
        <Typography variant='body4' sx={{ textDecoration: 'underline' }}>
          {company?.name}
        </Typography>
      </Box>
      <Box>
        <Typography variant='body5'>More Info</Typography>
      </Box>
    </Box>
  );
};
