import colors from '@packs/assets/theme/base/colors';

const { borderColor, text, white, grey, secondary } = colors;

export default () => ({
  sidebarWrapper: {
    minWidth: { xs: '200px', lg: '323px' },
    width: { xs: '100%', lg: '323px' },
    borderRadius: '16px',
    border: `1px solid ${borderColor.main}`,
    backgroundColor: white.main,
    mt: { xs: 5, lg: 0 },
    mx: { xs: 'auto', lg: '0' }
  },
  sidebarSection: {
    p: 2
  },
  policyWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    '& .field': {
      width: '100%'
    }
  },
  sidebarAccordionSection: {
    '& .MuiAccordion-rounded': {
      borderRadius: '16px !important',
      boxShadow: 'none'
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      marginBottom: 1
    }
  },
  sidebarAccordionSubTitle: {
    pt: 0.5,
    color: text.secondary
  },
  progressWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    mb: 1
  },
  progressContainer: {
    flexGrow: 1,
    height: '8px',
    borderRadius: '60px',
    backgroundColor: grey[100],
    overflow: 'hidden'
  },
  progress: {
    height: '100%',
    backgroundColor: secondary.main
  }
});
