import React from 'react';

export const Local_servicesDryCleaner = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path d='M12.7832 2.01374C12.5295 1.99504 12.2802 2.00485 12.0371 2.03914C10.5784 2.24483 9.36372 3.35382 9.07031 4.80085C9.03922 4.93134 9.03469 5.06674 9.057 5.19901C9.07931 5.33127 9.128 5.4577 9.20017 5.57077C9.27234 5.68383 9.36652 5.78122 9.4771 5.85715C9.58768 5.93307 9.7124 5.98597 9.84384 6.01271C9.97529 6.03944 10.1108 6.03946 10.2422 6.01277C10.3737 5.98607 10.4984 5.9332 10.609 5.85731C10.7196 5.78142 10.8138 5.68406 10.886 5.57101C10.9582 5.45797 11.0069 5.33155 11.0293 5.19929C11.1961 4.37667 11.9849 3.77548 12.9824 4.07234C13.4239 4.20384 13.8176 4.61004 13.9395 5.06062V5.06257C14.2352 6.151 13.4786 7.00007 12.5 7.00007H12C11.7348 7.0001 11.4805 7.10546 11.2929 7.293C11.1054 7.48053 11 7.73486 11 8.00007V8.94734L3.67383 12.6055C2.65832 13.0569 2 14.0705 2 15.1817C2.00002 15.314 2.02627 15.4449 2.07723 15.5669C2.12819 15.6889 2.20284 15.7997 2.29688 15.8927C2.64452 17.0551 3.54999 18.0001 4.81836 18.0001H6V21.0001C6 21.5521 6.448 22.0001 7 22.0001H17C17.552 22.0001 18 21.5521 18 21.0001V18.0001H19.1816C20.451 18.0001 21.3559 17.0541 21.7031 15.8907C21.7969 15.7979 21.8714 15.6875 21.9224 15.5659C21.9733 15.4442 21.9997 15.3136 22 15.1817C22 14.0705 21.3417 13.0569 20.3262 12.6055L13 8.94734V8.87898C14.9747 8.56844 16.4378 6.63002 15.8691 4.53718C15.5604 3.39901 14.6883 2.49456 13.5527 2.15632C13.2952 2.07968 13.0369 2.03245 12.7832 2.01374ZM12 10.6837L19.4727 14.4141C19.4862 14.421 19.4999 14.4275 19.5137 14.4337C19.8122 14.5663 20 14.8549 20 15.1817C20 15.6452 19.6467 16.0001 19.1816 16.0001H17.7227C17.3764 15.4044 16.7389 15.0001 16 15.0001H8C7.26105 15.0001 6.62361 15.4044 6.27734 16.0001H4.81836C4.35486 16.0001 4 15.6452 4 15.1817C4 14.8549 4.18783 14.5663 4.48633 14.4337C4.50015 14.4275 4.51382 14.421 4.52734 14.4141L12 10.6837Z' />
      </g>
    </svg>
  );
};
