import { fetchCategoryAgentsShort, fetchCategoryAgentsWikiShort } from '@packs/graphql/queries/company';
import {
  changeUserResetedPassword,
  checkUserFieldsUniqueness,
  connectSocial,
  createUserByCodeMutation,
  createUserMutation,
  dashboardPinCategory,
  dashboardUnPinCategory,
  deleteUser,
  disconnectSocial,
  fetchUserCategories,
  fetchUserCategoriesPricePerPeriod,
  friendshipAccept,
  inviteUsers,
  logOutUser,
  profileContributionCounter,
  resendCurrentUserConfirmationInstructions,
  resendCurrentUserUnlockInstructions,
  resetPassword,
  signInUser,
  trustNetworkFriendList,
  updateUser,
  userAcceptInvitationByEmail,
  userAcceptInvitationBySocial,
  userPhoneSmsCodeCheckMutation,
  userPhoneVerifyMutation,
  userProfileActivity,
  userProfileStats,
  userUnsubscribeEmail
} from '@packs/graphql/queries/currentUser';
import {
  fetchLeaderboardFilters,
  fetchUserCategoryByPolicy
} from '@packs/graphql/queries/currentUser/fetchUserCategories';
import { fetchMyOffersShort } from '@packs/graphql/queries/myOffers';
import { qaPostListShort } from '@packs/graphql/queries/qa';
import { graphQLRequest } from '@packs/lib/utils';

import omit from 'lodash-es/omit';

export const userApi = {
  logOut: () =>
    graphQLRequest({
      query: logOutUser,
      variables: { input: {} }
    }),
  signIn: ({ email, password }) =>
    graphQLRequest({
      query: signInUser,
      variables: { input: { type: 'web' } },
      data: { user: { email, password } }
    }),
  updateCurrentUser: variables => {
    const data = {};

    Object.keys(variables).forEach(item => {
      if (variables[item]) {
        data[`input/${item}`] = variables[item];
      }
    });

    return graphQLRequest({
      query: updateUser,
      type: 'multipart',
      data: {
        variables_keys:
          'input/avatar;input/backgroundImage;input/firstName;input/lastName;input/password;input/passwordConfirmation',
        ...data
      }
    });
  },
  fetchUserCategories: variables =>
    graphQLRequest({
      query: fetchUserCategories,
      variables
    }),
  dashboardPinCategory: variables =>
    graphQLRequest({
      query: dashboardPinCategory,
      variables: { input: variables }
    }),
  dashboardUnPinCategory: variables =>
    graphQLRequest({
      query: dashboardUnPinCategory,
      variables: { input: variables }
    }),
  fetchUserCategoriesPricePerPeriod: variables =>
    graphQLRequest({
      query: fetchUserCategoriesPricePerPeriod,
      variables
    }),
  fetchLeaderboardFilters: variables =>
    graphQLRequest({
      query: fetchLeaderboardFilters,
      variables
    }),
  fetchUserCategoryByPolicy: variables =>
    graphQLRequest({
      query: fetchUserCategoryByPolicy,
      variables
    }),
  usersResetPassword: ({ email }) =>
    graphQLRequest({
      query: resetPassword,
      variables: { input: { email } }
    }),
  deleteCurrentUser: () =>
    graphQLRequest({
      query: deleteUser,
      variables: { input: {} }
    }),
  changeUserResetedPassword: ({ password, passwordConfirmation, resetPasswordToken }) =>
    graphQLRequest({
      query: changeUserResetedPassword,
      variables: {
        input: {
          password,
          passwordConfirmation,
          resetPasswordToken
        }
      }
    }),
  createUser: attributes =>
    graphQLRequest({
      query: createUserMutation,
      variables: { input: attributes }
    }),
  createUserByCode: attributes =>
    graphQLRequest({
      query: createUserByCodeMutation,
      variables: { input: attributes }
    }),
  resendCurrentUserConfirmationInstructions: ({ email }) =>
    graphQLRequest({
      query: resendCurrentUserConfirmationInstructions,
      variables: { input: { email } }
    }),
  resendCurrentUserUnlockInstructions: ({ email }) =>
    graphQLRequest({
      query: resendCurrentUserUnlockInstructions,
      variables: { input: { email } }
    }),
  userAcceptInvitationByEmail: variables =>
    graphQLRequest({
      query: userAcceptInvitationByEmail,
      variables: { input: variables }
    }),
  userAcceptInvitationBySocial: variables =>
    graphQLRequest({
      query: userAcceptInvitationBySocial,
      variables: { input: variables }
    }),
  userProfileStats: variables =>
    graphQLRequest({
      query: userProfileStats,
      variables
    }),
  profileContributionCounter: variables =>
    graphQLRequest({
      query: profileContributionCounter,
      variables
    }),
  userProfileActivity: variables =>
    graphQLRequest({
      query: userProfileActivity,
      variables
    }),
  checkUserFieldsUniqueness: ({ email, displayName, businessName, registerBusiness }) => {
    const queries = [
      {
        query: checkUserFieldsUniqueness,
        variables: { input: { fieldName: 'email', fieldValue: email } },
        responseKey: 'emailValidation'
      }
    ];

    if (registerBusiness) {
      queries.push({
        query: checkUserFieldsUniqueness,
        variables: { input: { fieldName: 'businessName', fieldValue: businessName } },
        responseKey: 'businessNameValidation'
      });
    } else {
      queries.push({
        query: checkUserFieldsUniqueness,
        variables: { input: { fieldName: 'displayName', fieldValue: displayName } },
        responseKey: 'displayNameValidation'
      });
    }

    return graphQLRequest({ queries });
  },
  inviteUsers: attributes =>
    graphQLRequest({
      query: inviteUsers,
      variables: { input: attributes }
    }),
  phoneVerify: ({ phoneNumber }) =>
    graphQLRequest({
      query: userPhoneVerifyMutation,
      variables: { input: { phoneNumber } }
    }),
  phoneSmsCodeCheck: attributes =>
    graphQLRequest({
      query: userPhoneSmsCodeCheckMutation,
      variables: { input: attributes }
    }),
  connectSocial: attributes =>
    graphQLRequest({
      query: connectSocial,
      variables: { input: attributes }
    }),
  disconnectSocial: attributes =>
    graphQLRequest({
      query: disconnectSocial,
      variables: { input: attributes }
    }),
  userUnsubscribeEmail: attributes =>
    graphQLRequest({
      query: userUnsubscribeEmail,
      variables: { input: attributes }
    }),
  trustNetworkFriendList: attributes =>
    graphQLRequest({
      query: trustNetworkFriendList,
      variables: attributes
    }),
  friendshipAccept: attributes =>
    graphQLRequest({
      query: friendshipAccept,
      variables: { input: attributes }
    }),
  fetchAdditionalData: variables =>
    graphQLRequest({
      queries: [
        {
          query: variables?.quotes?.isWiki ? fetchCategoryAgentsWikiShort : fetchCategoryAgentsShort,
          variables: { ...omit(variables?.quotes, ['type']) },
          responseKey: 'quotes'
        },
        {
          query: fetchMyOffersShort,
          variables: { ...variables?.offers },
          responseKey: 'offers'
        },
        {
          query: qaPostListShort,
          variables: { ...variables?.posts },
          responseKey: 'posts'
        }
      ]
    })
};

export default userApi;
