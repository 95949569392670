import TimerRoundedIcon from '@mui/icons-material/TimerRounded';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { ICompany } from '@packs/interfaces/rootInterfaces';

import useStyles from './styles';

const { text } = colors;

export const BusinessResponse = ({ company }: { company: ICompany }) => {
  const styles = useStyles();

  const { responseTime, quoteRequestsCount } = company;

  if (!responseTime) {
    return null;
  }

  return (
    <Box sx={styles.wrapper}>
      <Box sx={{ pt: '2px' }}>
        <TimerRoundedIcon sx={{ fontSize: '18px' }} />
      </Box>
      <Box sx={styles.content}>
        <Typography variant='body3'>
          Responds in about{' '}
          <Box component='span' sx={{ fontWeight: 700 }}>
            {responseTime}!
          </Box>
        </Typography>
        {quoteRequestsCount && (
          <Typography variant='body3' sx={{ color: text.secondary }}>
            {quoteRequestsCount} locals requested a quote.
          </Typography>
        )}
      </Box>
    </Box>
  );
};
