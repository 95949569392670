import React, { useMemo } from 'react';
import { toJS } from 'mobx';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { BusinessResponse } from '@packs/components/MaterialUi/Leaderboard/CompanyItem/components/BusinessResponse/BusinessResponse';
import { PartnerProvider } from '@packs/components/MaterialUi/Leaderboard/CompanyItem/components/PartnerProvider/PartnerProvider';
import FirstPlaceIcon from '@packs/images/svg/ui/first-plcae.svg';
import SecondPlaceIcon from '@packs/images/svg/ui/second-place.svg';
import ThirdPlaceIcon from '@packs/images/svg/ui/third-place.svg';
import { pathGen } from '@packs/layouts/constants';
import { handleVisitCompany } from '@packs/lib/helpers/additionalHelpers';
import { isWikiTableCategory } from '@packs/models/category/functions';
import { useStore } from '@packs/stores/base';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { AdditionInfo } from './components/AdditionInfo/AdditionInfo';
import { AveragePrice } from './components/AveragePrice/AveragePrice';
import { CompanyAccordionsWrapper } from './components/CompanyAccordionsWrapper/CompanyAccordionsWrapper';
import { CompanyOffer } from './components/CompanyOffer/CompanyOffer';
import { Price } from './components/Price/Price';
import { Ratings } from './components/Ratings/Ratings';
import { RequestButton } from './components/RequestButton/RequestButton';
import { RequestCheckbox } from './components/RequestCheckbox/RequestCheckbox';
import { VoteButton } from './components/VoteButton/VoteButton';
import { WebsiteLink } from './components/WebsiteLink/WebsiteLink';
import useStyles from './styles';

import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

export const CompanyItem = observer(
  ({
    company,
    index,
    withAnimation = false,
    requestButtonCallback = null,
    showPlaceLabel = false,
    showBlockedLeaderboard = false
  }) => {
    const { isMobile } = useWindowSize();
    const { currentCategory, companiesStore } = useStore();
    const styles = useStyles();
    const { countryCode } = useStore();

    const placeIcon = index => {
      switch (index) {
        case 0:
          return <img src={FirstPlaceIcon} alt='place' />;
        case 1:
          return <img src={SecondPlaceIcon} alt='place' />;
        case 2:
          return <img src={ThirdPlaceIcon} alt='place' />;
        default:
          return (
            <Box sx={styles.placeWrapper}>
              <Typography variant='body2'>{index + 1}th</Typography>
            </Box>
          );
      }
    };

    const onVisitCompanyPage = () => {
      handleVisitCompany(company, currentCategory);
    };

    const CompanyLinkWrapper = ({ children }) => (
      <Link
        to={pathGen('PRODUCT', {
          countryCode,
          category: currentCategory,
          company
        })}
        onClick={onVisitCompanyPage}
      >
        {children}
      </Link>
    );

    const isWiki = useMemo(() => {
      return isWikiTableCategory(currentCategory);
    }, [currentCategory]);

    const inRegion = useMemo(() => {
      const filterCity = companiesStore.filters.city || null;
      return !filterCity || toJS(company?.quoteAgent?.regions)?.includes(filterCity);
    }, [company?.quoteAgent?.regions]);

    const place = showBlockedLeaderboard ? placeIcon(index + 3) : placeIcon(index);

    return (
      <Box sx={styles.companyWrapper}>
        <CompanyOffer {...{ company }} />
        <Box sx={styles.mainSection}>
          <Box sx={styles.companyInfoSection}>
            <Box sx={styles.companyTopInfo}>
              <Box sx={styles.avatarContainer}>
                {place && showPlaceLabel && <Box sx={styles.placeIcon}>{place}</Box>}
                <CompanyLinkWrapper>
                  <Avatar
                    sx={{ width: { xs: 64, lg: 104 }, height: { xs: 64, lg: 104 } }}
                    variant='rounded'
                    src={company?.avatarUrl}
                  />
                </CompanyLinkWrapper>
              </Box>
              <Box>
                <CompanyLinkWrapper>
                  <Typography variant='h6' sx={styles.companyName}>
                    {company.name}
                  </Typography>
                </CompanyLinkWrapper>
                <PartnerProvider {...{ company, currentCategory, countryCode }} />
                <Box sx={styles.companyBtnContainer}>
                  <VoteButton company={company} />
                  {company?.quoteAgent?.id && inRegion && <WebsiteLink company={company} />}
                </Box>
              </Box>
              {!isMobile && (
                <Box sx={{ ml: 'auto' }}>
                  {isWiki ? (
                    <Price {...{ company }} category={currentCategory} extended />
                  ) : (
                    <AveragePrice {...{ company }} category={currentCategory} extended />
                  )}
                </Box>
              )}
            </Box>
            <AdditionInfo
              {...{ company, currentCategory, countryCode }}
              showCompanyType={companiesStore.filters.companyType === 'all'}
            />
          </Box>
          {!isMobile && (
            <Box sx={styles.ratingInfoSection}>
              <Box>
                <RequestCheckbox {...{ company }} />
                <Ratings {...{ company }} billWinnerIsLink />
              </Box>
              <BusinessResponse company={company} />
              <RequestButton {...{ company, requestButtonCallback }} />
            </Box>
          )}
        </Box>
        {isMobile && (
          <Box sx={styles.mobileBottomSection}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <Box>
                {isWiki ? (
                  <Price {...{ company }} category={currentCategory} extended />
                ) : (
                  <AveragePrice {...{ company }} category={currentCategory} extended />
                )}
              </Box>
              <Box>
                <RequestCheckbox {...{ company }} />
              </Box>
            </Box>
            <BusinessResponse company={company} />
            <RequestButton {...{ company, requestButtonCallback }} />
          </Box>
        )}
        <CompanyAccordionsWrapper {...{ company, currentCategory }} />
      </Box>
    );
  }
);
