import { reviewCommentQueries } from '@packs/graphql/queries';
import { graphQLRequest } from '@packs/lib/utils';

export const reviewCommentApi = {
  list: variables =>
    graphQLRequest({
      query: reviewCommentQueries.reviewCommentList,
      variables
    }),
  create: variables =>
    graphQLRequest({
      query: reviewCommentQueries.reviewCommentCreate,
      variables: { input: variables }
    }),
  update: variables =>
    graphQLRequest({
      query: reviewCommentQueries.reviewCommentUpdate,
      variables: { input: variables }
    }),
  toggleHelpful: variables =>
    graphQLRequest({
      query: reviewCommentQueries.reviewCommentToggleHelpful,
      variables: { input: variables }
    }),
  destroy: variables =>
    graphQLRequest({
      query: reviewCommentQueries.reviewCommentDestroy,
      variables: { input: variables }
    })
};
