import { action, observable, runInAction } from 'mobx';

import { requestsApi } from '@packs/apis';
import { setError } from '@packs/lib/utils';

import dayjs from 'dayjs';

export class RequestsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable requests = [];
  @observable totalCount = 0;
  @observable loading = false;
  @observable createdAtFirstYear = dayjs().year();

  @action load(attrs, more) {
    this.loading = true;
    if (!more) {
      this.requests = [];
    }

    requestsApi.list(attrs).then(resp => {
      if (resp?.errors?.length) {
        setError(resp.errors);
        return Promise.reject(resp.errors);
      }

      runInAction(() => {
        if (more) {
          this.requests = [...this.requests, ...(resp?.list || [])];
        } else {
          this.requests = resp?.list;
        }

        this.totalCount = resp?.totalCount;
        this.loading = false;
        this.createdAtFirstYear = resp?.createdAtFirstYear;
      });
    });
  }
}
