import React, { useState } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { All_CATEGORIES_INDEX } from '@packs/lib/constants';
import { useCategories } from '@packs/models/category/useCategories';

import { CategoriesMenu } from './components/CategoriesMenu';

import orderBy from 'lodash-es/orderBy';
import size from 'lodash-es/size';
import { observer } from 'mobx-react';

const { white, dark } = colors;

const popularRootCategories = ['insurance', 'broadband', 'mobile', 'energy', 'banking'];

export const CategoriesNavigation = observer(({ bgFilled }) => {
  const [showMenu, setShowMenu] = useState({});
  const { categories } = useCategories();

  const mainColor = bgFilled ? dark.main : white.main;
  const secondaryColor = bgFilled ? dark.main : white.main;

  const handleMenuToggle = (code, value) => {
    if (!showMenu[code]) {
      setShowMenu({ [code]: value });
    }
  };

  const hideNav = () => {
    setShowMenu({});
  };

  const sortedCategories = orderBy(
    categories.slice().filter(item => popularRootCategories.some(popular => popular === item.code)),
    cat => All_CATEGORIES_INDEX[cat?.code]
  );

  return (
    <Box sx={{ py: 1, px: 4 }}>
      {size(showMenu) > 0 && <div className='categories-navigation-overlay' />}
      <Stack component='nav' direction='row' alignItems='center' spacing={3}>
        <CategoriesMenu showAllCategories>
          <Stack direction='row' alignItems='center' sx={{ cursor: 'pointer', color: mainColor }} spacing={1}>
            <MenuIcon />
            <Typography variant='body4' sx={{ color: secondaryColor }}>
              All Categories
            </Typography>
          </Stack>
        </CategoriesMenu>

        {sortedCategories.map((ctg, index) => (
          <CategoriesMenu key={ctg.code} initialRootCategory={ctg} index={index}>
            <Box
              onClick={() => {
                handleMenuToggle(ctg.code, true);
              }}
              onMouseLeave={hideNav}
              position='relative'
              sx={{ cursor: 'pointer', color: secondaryColor }}
            >
              <Typography variant='body4' sx={{ color: secondaryColor }}>
                {ctg.name}
              </Typography>
            </Box>
          </CategoriesMenu>
        ))}
      </Stack>
    </Box>
  );
});
