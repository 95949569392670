import colors from '@packs/assets/theme/base/colors';

const { white, dark } = colors;

export default () => ({
  wrapper: {
    position: 'relative',
    width: '280px',
    minWidth: '280px',
    minHeight: '498px',
    borderRadius: '8px',
    p: 3,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  bannerWrapper: {
    color: white.main,
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.64)',
      zIndex: 1
    },
    overflow: 'hidden'
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    zIndex: 2
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2
  },
  title: {
    display: 'block',
    mb: 1,
    color: white.main
  },
  subTitle: {
    display: 'block',
    color: white.main
  },
  button: {
    color: dark.main,
    '&:hover': {
      color: dark.main
    }
  }
});
