export const allTypes = {
  value: 'all',
  label: 'All posts types'
};

export const postTypes = [
  {
    value: 'general',
    label: 'General post'
  },
  {
    value: 'question',
    label: 'Question'
  },
  {
    value: 'compliment',
    label: 'Compliment'
  },
  {
    value: 'complaint',
    label: 'Complaint'
  },
  {
    value: 'tip',
    label: 'Tip'
  },
  {
    value: 'business_review',
    label: 'Business Review'
  },
  {
    value: 'product_review',
    label: 'Product Review'
  },
  {
    value: 'recommendation',
    label: 'Recommendation'
  },
  {
    value: 'news_story',
    label: 'News story'
  },
  {
    value: 'event',
    label: 'Event'
  }
];
