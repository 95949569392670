export const HEAD_TITLE = 'Bill Winner';

export const infoEmail = 'info@billwinner.com';

export const BASE_APP_URL = 'https://www.billwinner.com';

export const inviteMessage =
  'Hey, I would like to invite you to my trust network on Bill Winner, the social network for bills!';

export const oauthUrls = {
  FACEBOOK: '/users/auth/facebook',
  TWITTER: '/users/auth/twitter',
  GOOGLE: '/users/auth/google_oauth2',
  LINKEDIN: '/users/auth/linkedin'
};

export const currentYear = 2021;

export const maxRequestQuotes = 3;

export const countriesCoinsCurrency = [
  {
    code: 'en-ie',
    name: 'cent',
    coin: '¢'
  },
  {
    code: 'en-uk',
    name: 'pence',
    coin: 'p'
  },
  {
    code: 'en-de',
    name: 'cent',
    coin: '¢'
  },
  {
    code: 'en-at',
    name: 'cent',
    coin: '¢'
  },
  {
    code: 'en-au',
    name: 'cent',
    coin: '¢'
  },
  {
    code: 'en-in',
    name: 'paisa',
    coin: '₹'
  },
  {
    code: 'en-nz',
    name: 'cent',
    coin: '¢'
  },
  {
    code: 'en-sg',
    name: 'cent',
    coin: '¢'
  },
  {
    code: 'en-za',
    name: 'cent',
    coin: '¢'
  },
  {
    code: 'en-ch',
    name: 'centime',
    coin: 'c'
  }
];

export const FILTER_CATEGORIES = [
  {
    label: 'All',
    value: 'CATEGORIES',
    type: 'categories'
  },
  {
    label: 'Insurance',
    value: 'insurance',
    type: 'categories'
  },
  {
    label: 'Broadband',
    value: 'broadband',
    type: 'categories'
  },
  {
    label: 'Mobile',
    value: 'mobile',
    type: 'categories'
  },
  {
    label: 'Energy',
    value: 'energy',
    type: 'categories'
  },
  {
    label: 'Subscriptions',
    value: 'subscriptions',
    type: 'categories'
  },
  {
    label: 'Banking',
    value: 'banking',
    type: 'categories'
  },
  {
    label: 'Business',
    value: 'business',
    type: 'categories'
  },
  {
    label: 'Government',
    value: 'government',
    type: 'categories'
  }
];

export const COST_OPTIONS = [
  { label: 'Per year', value: 'renewalCostYearly' },
  { label: 'Per month', value: 'renewalCostMonthly' }
];

export const dateFilterOptions = [
  { value: 'year', label: 'Year' },
  { value: 'month', label: 'Month' }
];

export const commentableType = {
  post: 'post',
  comment: 'comment'
};

export const helpfulTypes = {
  create: 'create',
  destroy: 'destroy'
};

export const numberWithCommas = x => {
  if (!x) {
    return '';
  }

  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const All_CATEGORIES = [
  'insurance',
  'broadband',
  'mobile',
  'energy',
  'subscriptions',
  'banking',
  'business',
  'government'
];

export const perMonthCategories = [
  'health_insurance',
  'pet_insurance',
  'life_insurance',
  'bike_insurance',
  'gadget_insurance',
  'travel_insurance',
  'subscriptions',
  'mobile',
  'broadband'
];

export const All_CATEGORIES_INDEX = {
  insurance: 0,
  broadband: 1,
  mobile: 2,
  energy: 3,
  subscriptions: 4,
  banking: 5,
  business: 6,
  general: 7,
  government: 8,
  waste_collection: 9
};

export const VALUE_REACTIONS = ['poor', 'fair', 'good', 'great', 'excellent'];

export const energyAdvancedPricesList = [
  {
    value: 'dayPrice',
    label: 'Cost per day'
  },
  {
    value: 'nightPrice',
    label: 'Cost per night'
  },
  {
    value: 'standingPrice',
    label: 'Standing charge'
  }
];

export const ORDER_TITLE = {
  reviewed: 'Most reviewed',
  best_claims: 'Best Claims RatingsBusiness',
  higher_raited: 'Highest rated',
  most_popular: 'Most Popular',
  short_list: 'Most Popular',
  wiki_price: 'Best Priced',
  best_avg_prices: 'Best Priced'
};

export const LAUNCH_COUNTRIES = [
  'en-in',
  'en-au',
  'en-za',
  'en-nz',
  'en-uk',
  'en-us',
  'en-ca',
  'en-de',
  'en-at',
  'en-sg',
  'en-ch',
  'en-ie'
];

export const satisfactionOptions = [
  {
    label: 'Excellent',
    value: 'excellent'
  },
  {
    label: 'Great',
    value: 'great'
  },
  {
    label: 'Good',
    value: 'good'
  },
  {
    label: 'Fair',
    value: 'fair'
  },
  {
    label: 'Poor',
    value: 'poor'
  }
];

export const satisfactionOptionsExtended = [
  {
    label: 'All reviews',
    value: 'all_reviews'
  },
  ...satisfactionOptions
];

export const satisfactionStars = {
  excellent: '5.0',
  great: '4.0',
  good: '3.0',
  fair: '2.0',
  poor: '1.0'
};

export const rateCostList = [
  { value: true, label: 'Including taxes' },
  { value: false, label: 'Excluding taxes' }
];

export const feedbackTypesList = [
  {
    value: 'all',
    label: 'All Insights'
  },
  {
    value: 'review',
    label: 'Review'
  },
  {
    value: 'purchase',
    label: 'Purchase'
  },
  {
    value: 'claim',
    label: 'Claims'
  }
];

export const getCurrency = countryCode => {
  switch (countryCode) {
    case 'us':
    case 'ca':
    case 'nz':
    case 'sg':
    case 'au':
      return '$';
    case 'in':
      return '₹';
    case 'za':
      return 'R';
    case 'uk':
      return '£';
    case 'ch':
      return 'CHF';
    default:
      return '€';
  }
};

export const businessTabs = [
  { value: 'insights', label: 'Insights' },
  { value: 'q_a', label: 'Q&A' },
  { value: 'tips', label: 'Tips' }
];

export const monthsList = [
  { value: 'jan', label: 'January' },
  { value: 'feb', label: 'February' },
  { value: 'mar', label: 'March' },
  { value: 'apr', label: 'April' },
  { value: 'may', label: 'May' },
  { value: 'jun', label: 'June' },
  { value: 'jul', label: 'July' },
  { value: 'aug', label: 'August' },
  { value: 'sept', label: 'September' },
  { value: 'oct', label: 'October' },
  { value: 'nov', label: 'November' },
  { value: 'dec', label: 'December' }
];

export const contributionTypes = {
  totalCount: 'totalCount',
  renewalDetailListCount: 'renewalDetailListCount',
  qaPostListCount: 'qaPostListCount',
  qaTipListCount: 'qaTipListCount',
  userCategoryQuoteRequestListCount: 'userCategoryQuoteRequestListCount'
};

const isVehicleCategories = ['car_insurance', 'motorbike_insurance', 'van_insurance'];
export const availableCategoriesForLocationModal = [
  'broadband',
  'mobile',
  'home_insurance',
  'mortgages',
  'pet_insurance',
  'health_insurance',
  ...isVehicleCategories
];
export const countriesWithAllAvailableCategories = ['us', 'ca'];

export const defaultAvatar = '/images/user-default-avatar.svg';
export const defaultAvatarCover = '/images/default-cover-photo.jpg';
export const defaultBusinessImage = '/images/default-company-avatar.svg';
export const MINIMUM_PASSWORD_CHARACTERS = 6;

export const autoQuoteTooltip =
  'Add your policy details and turn on ‘auto. quote’ so that you can automatically request quotes from the best matched business or businesses prior to your next renewal! ';

export const autoQuoteTooltipNoPolicy =
  'Easily find the best-matched provider for a quote with our Auto. quote feature. Complete 100% personalization to receive automatic quotes before your next renewal date.';
