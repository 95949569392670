import { createNoteMutation, destroyNote, noteListQuery, updateNote } from '@packs/graphql/queries/notes';
import { graphQLRequest } from '@packs/lib/utils';

export const notesApi = {
  fetchList: variables =>
    graphQLRequest({
      query: noteListQuery,
      variables
    }),
  createNote: ({ categoryId, title, body, files }) =>
    graphQLRequest({
      query: createNoteMutation,
      type: 'multipart',
      data: [
        { key: 'variables[input][categoryId]', value: categoryId },
        { key: 'variables[input][title]', value: title },
        { key: 'variables[input][body]', value: body },
        ...files.map(file => ({ key: 'variables[input][files][]', value: file }))
      ]
    }),
  updateNote: ({ id, categoryId, title, body, files, deleteFilesIds }) =>
    graphQLRequest({
      query: updateNote,
      type: 'multipart',
      data: [
        { key: 'variables[input][id]', value: id },
        { key: 'variables[input][categoryId]', value: categoryId },
        { key: 'variables[input][title]', value: title },
        { key: 'variables[input][body]', value: body },
        ...(deleteFilesIds || []).map(file => ({ key: 'variables[input][deleteFilesIds][]', value: file })),
        ...files.map(file => ({ key: 'variables[input][files][]', value: file }))
      ]
    }),
  deleteNote: variables =>
    graphQLRequest({
      query: destroyNote,
      variables
    })
};
