import React from 'react';

export default () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='13' height='16' viewBox='0 0 13 16' fill='#379C94'>
      <g fillRule='nonzero'>
        <path d='M8.719 5.807a.373.373 0 0 0-.374.373v7.049a.373.373 0 0 0 .747 0V6.18a.373.373 0 0 0-.373-.373zM4.31 5.807a.373.373 0 0 0-.373.373v7.049a.373.373 0 0 0 .747 0V6.18a.373.373 0 0 0-.373-.373z' />
        <path d='M1.098 4.778v9.19c0 .542.2 1.053.548 1.419.347.366.83.575 1.335.576h7.068c.505-.001.988-.21 1.335-.576.348-.366.548-.877.548-1.42V4.778a1.425 1.425 0 0 0-.367-2.801H9.653V1.51A1.464 1.464 0 0 0 8.173.037H4.856A1.469 1.469 0 0 0 3.377 1.51v.467H1.464a1.425 1.425 0 0 0-.366 2.802zm8.95 10.439H2.982c-.639 0-1.136-.548-1.136-1.25V4.811h9.34v9.156c0 .702-.497 1.25-1.136 1.25zM4.125 1.51a.717.717 0 0 1 .732-.727h3.317a.72.72 0 0 1 .733.727v.467H4.124V1.51zm-2.66 1.213h10.101a.672.672 0 1 1 0 1.342h-10.1a.672.672 0 1 1 0-1.342z' />
        <path d='M6.515 5.807a.373.373 0 0 0-.374.373v7.049a.373.373 0 0 0 .747 0V6.18a.373.373 0 0 0-.373-.373z' />
      </g>
    </svg>
  );
};
