import React from 'react';

export const BusinessMarineInsurance = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path d='M13 9V7.82C14.16 7.4 15 6.3 15 5C15 3.35 13.65 2 12 2C10.35 2 9 3.35 9 5C9 6.3 9.84 7.4 11 7.82V9H9C8.45 9 8 9.45 8 10C8 10.55 8.45 11 9 11H11V19.92C8.78 19.59 6.41 18.24 5.45 16.55L6.59 15.41C6.81 15.19 6.78 14.84 6.54 14.66L3.8 12.6C3.47 12.35 3 12.59 3 13V15C3 18.88 7.92 22 12 22C16.08 22 21 18.88 21 15V13C21 12.59 20.53 12.35 20.2 12.6L17.46 14.65C17.22 14.83 17.19 15.19 17.41 15.4L18.55 16.54C17.59 18.23 15.22 19.58 13 19.91V11H15C15.55 11 16 10.55 16 10C16 9.45 15.55 9 15 9H13ZM12 4C12.55 4 13 4.45 13 5C13 5.55 12.55 6 12 6C11.45 6 11 5.55 11 5C11 4.45 11.45 4 12 4Z' />
      </g>
    </svg>
  );
};
