import React from 'react';

import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useDetails } from '@packs/models/policy/useDetails';
import { useStore } from '@packs/stores/base';

export const WebsiteLink = ({ company, variant = 'text', size = 'small', color = 'dark', fullWidth = false }) => {
  const { currentCategory, companiesStore, shoppingCartStore } = useStore();
  const { currentPolicy } = useDetails();

  const offerLink = company?.partnerProvider?.customWebLink || '';
  const affinityLink = company?.affinityLink?.url || '';
  const websiteLink = affinityLink || offerLink || company.url;

  const addToShoppingCart = () => {
    const opts = {
      categoryId: currentCategory?.id,
      companyIds: [+company?.id],
      policyId: currentPolicy?.id
    };

    if (shoppingCartStore.shoppingCart.cartItems.some(item => item?.company?.id === company?.id)) {
      return null;
    }

    shoppingCartStore.createShoppingCartItems(opts).catch(() => {});
  };

  const handleClickWebsiteLink = company => {
    companiesStore.companyUpdateClicks({ companyId: +company.id, categoryId: currentCategory?.id });
  };

  const handleVisitWebsite = () => {
    handleClickWebsiteLink(company);
    addToShoppingCart();
  };

  return (
    <Button
      variant={variant}
      size={size}
      color={color}
      fullWidth={fullWidth}
      component={Link}
      href={websiteLink}
      target='_blank'
      rel='noopener noreferrer'
      onClick={handleVisitWebsite}
    >
      Go to Website
    </Button>
  );
};
