import React from 'react';

export default () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21'>
      <g fillRule='nonzero'>
        <path d='M7.7 7.7v.7h4.2c.21 0 .35-.14.35-.35V6.3c0-.21-.14-.35-.35-.35H2.45A2.435 2.435 0 0 0 0 8.4v4.9h.7V8.4c0-.98.77-1.75 1.75-1.75h.455l.98 2.59c.035.105.175.21.315.21s.28-.105.385-.21l.98-2.59h5.985V7.7H7.7zm-3.465.42l-.56-1.47h1.12l-.56 1.47z' />
        <path d='M5.95 20.3h-1.4v-6.65h-.7v6.65h-1.4V8.75h-.7v11.9c0 .21.14.35.35.35h4.2c.21 0 .35-.14.35-.35V8.75h-.7V20.3z' />
        <path d='M3.85 10.15h1v1h-1zM3.85 11.55h1v1h-1zM3.85 5.6h.7c.98 0 1.75-.77 1.75-1.75V2.8c0-.98-.77-1.75-1.75-1.75h-.7c-.98 0-1.75.77-1.75 1.75v1.05c0 .98.77 1.75 1.75 1.75zM2.8 2.8c0-.595.455-1.05 1.05-1.05h.7c.595 0 1.05.455 1.05 1.05v1.05c0 .595-.455 1.05-1.05 1.05h-.7c-.595 0-1.05-.455-1.05-1.05V2.8zM10.5 4.9h7.7c.21 0 .35-.14.35-.35V.35c0-.21-.14-.35-.35-.35h-7.7c-.21 0-.35.14-.35.35v4.2c0 .21.14.35.35.35zm.35-4.2h7v3.5h-7V.7zM13.3 12.95H8.4c-.21 0-.35.14-.35.35v2.8c0 .21.14.35.35.35h2.1v1.05H8.4c-.21 0-.35.14-.35.35v2.8c0 .21.14.35.35.35h4.9c.21 0 .35-.14.35-.35v-2.8c0-.21-.14-.35-.35-.35h-2.1v-1.05h2.1c.21 0 .35-.14.35-.35v-2.8c0-.21-.14-.35-.35-.35zm-.35 7.35h-4.2v-2.1h4.2v2.1zm0-4.55h-4.2v-2.1h4.2v2.1zM20.65 16.45c.21 0 .35-.14.35-.35v-2.8c0-.21-.14-.35-.35-.35h-4.9c-.21 0-.35.14-.35.35v2.8c0 .21.14.35.35.35h2.1v1.05h-2.1c-.21 0-.35.14-.35.35v2.8c0 .21.14.35.35.35h4.9c.21 0 .35-.14.35-.35v-2.8c0-.21-.14-.35-.35-.35h-2.1v-1.05h2.1zm-.35 3.85h-4.2v-2.1h4.2v2.1zm-4.2-6.65h4.2v2.1h-4.2v-2.1zM18.55 12.25V11.2c0-.21-.14-.35-.35-.35h-3.5V5.6H14v5.25h-3.15c-.21 0-.35.14-.35.35v1.05h.7v-.7h6.65v.7h.7z' />
        <path d='M11.55 1.4h4.2v1h-4.2zM11.55 2.8h5.6v1h-5.6zM9.8 14.35h1v1h-1zM11.2 14.35h1v1h-1zM9.8 18.9h1v1h-1zM11.2 18.9h1v1h-1zM17.15 18.9h1v1h-1zM18.55 18.9h1v1h-1zM17.15 14.35h1v1h-1zM18.55 14.35h1v1h-1z' />
      </g>
    </svg>
  );
};
