import { action, computed, observable, toJS } from 'mobx';

import { renewalDetailApi } from '@packs/apis';
import { feedbackApi } from '@packs/apis/feedback';
import { IPolicy } from '@packs/interfaces/rootInterfaces';
import { toastr } from '@packs/lib/helpers';
import { pickPersonalizedCriteriaQuestionsFromOptions } from '@packs/lib/helpers/renewalDetails';
import { RootStore } from '@packs/stores/index';

export const initDefaultPolicy = (categoryId: number, index = 1): IPolicy => {
  return {
    id: null,
    buyingType: 'provider',
    firstTime: true,
    dataMonthPrices: {},
    criteriaQuestions: undefined,
    pricesQuestions: undefined,
    categoryId,
    smartReminder: {
      id: null,
      aliasName: `Policy ${index}`,
      triggeredAt: '',
      name: `Policy ${index}`,
      status: 'red',
      categoryId,
      isAutoQuote: false
    }
  };
};

export class PolicyStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable
  currentPolicy: IPolicy | undefined = undefined;

  @observable
  formOptions = {};

  @action async loadPolicy(renewalDetailId: number) {
    const obj = await renewalDetailApi.fetchRenewalDetail({
      renewalDetailId
    });

    return this.setCurrentPolicy(obj);
  }

  @action setCurrentPolicy(obj?: IPolicy) {
    this.currentPolicy = obj || undefined;
    return this.currentPolicy;
  }

  @action updateCurrentPolicy(obj) {
    return (this.currentPolicy = {
      ...this.currentPolicy,
      ...obj
    });
  }

  // this is search criteria from BE yml
  @action setFormOptions(obj) {
    this.formOptions = obj || {};
  }

  @computed get searchCriteria() {
    const policyCriteria = toJS(this.currentPolicy?.criteriaQuestions);
    return pickPersonalizedCriteriaQuestionsFromOptions(policyCriteria, toJS(this.formOptions));
  }

  @action
  toggleIsAutoQuote(isAutoQuote) {
    renewalDetailApi.updateIsAutoQuote({ id: this.currentPolicy?.smartReminder?.id, isAutoQuote }).then(res => {
      if (!res?.errors?.length) {
        this.setCurrentPolicy({
          ...this.currentPolicy,
          smartReminder: {
            ...(this.currentPolicy?.smartReminder || {}),
            isAutoQuote
          }
        });
        toastr.success(`Auto. quote is turned ${isAutoQuote ? 'on' : 'off'}`);
      }
    });
  }

  @action
  async createFeedback(attrs) {
    const resp = await feedbackApi.createFeedback({
      ...attrs,
      year: new Date().getFullYear()
    });

    if (resp.errors.length) {
      return Promise.reject(resp.errors);
    }

    const profileStats = this.rootStore.userStore.userProfileStats || {};
    this.rootStore.userStore.setUserProfileStats({
      remindersCount: profileStats.remindersCount,
      reviewsCount: (profileStats.reviewsCount || 0) + 1,
      helpfullCount: profileStats.helpfullCount,
      reviewBonusPoints: (profileStats.reviewBonusPoints || 0) + 5
    });
    return resp;
  }

  @action
  async createRenewalDetail({ params, categoryId }) {
    const resp = await renewalDetailApi.createRenewalDetail({
      ...params,
      categoryId
    });

    if (resp.errors.length) {
      return Promise.reject(resp.errors);
    }

    this.setCurrentPolicy(resp.renewalDetail);

    return resp;
  }
}
