import React, { useEffect } from 'react';

import { Box, Button, ButtonProps, FormHelperText, Grid, Stack, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import colors from '@packs/assets/theme/base/colors';
import { BusinessVoteModalForm } from '@packs/components/BusinessVoteModal/BusinessVoteModal';
import { IconBox } from '@shared/components/IconBox/IconBox';
import { ICONS } from '@shared/helpers/categoriesIconsHelper';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { Controller, useFormContext } from 'react-hook-form';

const CategorySelect = ({ categories, subCategories }) => {
  const { control, watch, setValue } = useFormContext<BusinessVoteModalForm>();
  const { isMobile } = useWindowSize();
  const categoryId = watch('categoryId');
  const rootCategoryId = watch('rootCategoryId');

  useEffect(() => {
    if (rootCategoryId) {
      setValue('categoryId', null);
    }
  }, [rootCategoryId, setValue]);

  useEffect(() => {
    if (subCategories.length > 0 && !categoryId) {
      setValue('categoryId', subCategories[0].value);
    }
  }, [categoryId, setValue, rootCategoryId, subCategories]);

  return (
    <Stack spacing={3}>
      <Controller
        name='rootCategoryId'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <FormControl error={!!error?.message}>
            <Typography variant='body2' sx={{ mb: 1 }}>
              Please choose the category you purchased from this business
            </Typography>
            {isMobile ? (
              <Box sx={{ w: 1, maxWidth: 410 }}>
                <Select
                  displayEmpty
                  value={field.value}
                  onChange={field.onChange}
                  renderValue={selected => {
                    if (!selected) {
                      return 'Choose category';
                    }
                    return categories.find(el => el.id === selected)?.name || '';
                  }}
                  fullWidth
                >
                  {categories.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            ) : (
              <Grid container spacing={2}>
                {categories.map(option => (
                  <Grid key={option.value} item xs={4}>
                    <Button
                      variant='outlined'
                      color={'defaultGrey' as ButtonProps['color']}
                      fullWidth
                      size='small'
                      startIcon={
                        <IconBox
                          containerSize='32px'
                          icon={ICONS[option.enName]}
                          borderRadius='8px'
                          color={colors.dark.main}
                          backgroundColor={colors.defaultGrey.transparent}
                          rootIcon
                        />
                      }
                      onClick={() => field.onChange(option.id)}
                      sx={{
                        justifyContent: 'flex-start',
                        px: 2,
                        borderColor: field.value === option.id ? colors.primary.main : colors.borderColor.main
                      }}
                    >
                      {option.name}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            )}

            <FormHelperText>{error?.message}</FormHelperText>
          </FormControl>
        )}
      />
      <Controller
        name='categoryId'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <FormControl error={!!error?.message} disabled={subCategories.length === 0}>
            <Typography variant='body2' sx={{ mb: 1 }}>
              Product that you bought from this business
            </Typography>
            <Box sx={{ w: 1, maxWidth: 410 }}>
              <Select
                displayEmpty
                value={field.value}
                onChange={field.onChange}
                renderValue={selected => {
                  if (!selected) {
                    return 'Choose subcategory';
                  }
                  return subCategories.find(el => el.value === selected)?.label || '';
                }}
                fullWidth
              >
                {subCategories.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{error?.message}</FormHelperText>
            </Box>
          </FormControl>
        )}
      />
    </Stack>
  );
};

export default CategorySelect;
