import React, { FC } from 'react';

import Box from '@mui/material/Box';
import Rating, { RatingProps } from '@mui/material/Rating';

interface IRatingMUIProps extends Omit<RatingProps, 'onChange'> {
  half?: boolean;
  onChange?: (val: string) => void;
}

export const RatingMUI: FC<IRatingMUIProps> = ({
  value = 0,
  max = 5,
  readOnly = true,
  color = '#faaf00',
  onChange,
  half = true,
  size = 'medium',
  disabled
}) => {
  const handleChange = (event, newValue) => {
    if (!readOnly && onChange) {
      onChange(newValue);
    }
  };

  return (
    <Box
      sx={{
        '& .MuiRating-iconFilled': {
          color
        },
        '& .MuiRating-iconHover': {
          color
        }
      }}
    >
      <Rating
        {...{ value, max, readOnly, size, disabled }}
        precision={half ? 0.5 : 1}
        onChange={handleChange}
        size={size}
      />
    </Box>
  );
};
