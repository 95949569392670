import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/CloseRounded';
import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  PaperProps,
  Select,
  Stack,
  Typography
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { renewalDetailApi } from '@packs/apis';
import api from '@packs/apis/currentUser';
import { AutoQuotesNew } from '@packs/components/AutoQuotesNew/AutoQuotesNew';
import { EstimateCostInput, PERIOD } from '@packs/components/EstimateCostInput/EstimateCostInput';
import { AutocompleteMUI } from '@packs/components/MaterialUi/AutocompleteMUI/AutocompleteMUI';
import RatingNormalIcon from '@packs/images/svg/ui/smile.svg';
import RatingBadIcon from '@packs/images/svg/ui/smile-bad.svg';
import RatingGoodIcon from '@packs/images/svg/ui/smile-good.svg';
import { ICategoryOptionOption, IPolicy, IRootCategoryOption } from '@packs/interfaces/rootInterfaces';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { toastr } from '@packs/lib/helpers';
import { GetPolicyNameFromDetailsCollection } from '@packs/lib/helpers/renewalDetails';
import { CATEGORY_PER_MONTH } from '@packs/models/category/constants';
import { useCategories } from '@packs/models/category/useCategories';
import { recurringOptions } from '@packs/models/company/constants';
import { SAVE_DATE_FORMAT } from '@packs/screens/PersonalizeResults/formHooks/defaults';
import { useStore } from '@packs/stores';

import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';

export interface ReminderForm {
  smartReminderId: number | null;
  rootCategory: IRootCategoryOption | undefined;
  category: ICategoryOptionOption;
  triggeredAt: Dayjs;
  renewalDetailId: string | number | null;
  recurringTime: string;
  price: number;
  period: PERIOD;
  isAutoQuote: boolean;
  aliasName: string;
  name: string;
  phoneNumber: string;
  companyRate: number;
  reminderType: string;
  formPhoneVerified: boolean;
}

const ratingSmileIcon = index => {
  if (index <= 5) {
    return RatingBadIcon;
  }

  if (index <= 7) {
    return RatingNormalIcon;
  }

  return RatingGoodIcon;
};

const rateList = [...Array(10)].map((_, index) => ({
  value: index + 1,
  label: index + 1,
  icon: <img src={ratingSmileIcon(index + 1)} alt='Smile' />
}));

export const ReminderFormModal = observer(
  ({
    selectedReminder,
    shouldShowDetails,
    // aliasName,
    reminderType = 'select',
    // parentType,
    selectedDate,
    toggle,
    currentUser,
    onSuccess,
    disabledCategory = false,
    selectedCategory,
    company,
    isCreateBusinessReminder,
    hidePolicy
  }) => {
    const { toggleVerificationModalOpen } = useModalsContext();

    const { userStore, currentCategory, smartRemindersStore } = useStore();

    const isEditType = !!selectedReminder;

    const prefilledCategory =
      company?.id && !selectedReminder?.id
        ? selectedCategory
        : selectedReminder?.category || selectedCategory || currentCategory;

    useEffect(() => {
      if (!currentUser.id) {
        userStore.setRegisterEvent('home');
      }
    }, [currentUser.id, userStore]);

    const [isDetailsLoading, setDetailsLoading] = useState(false);
    const [userDetails, setUserDetails] = useState<IPolicy[]>([]);

    // New one
    const { rootCategoriesOptions, categoriesOptions } = useCategories();

    // Get root category code
    const initRootCategoryCode = prefilledCategory?.root?.code || null;
    // Get root category option
    const initialRootCategoryOption = rootCategoriesOptions.find(el => el.value === initRootCategoryCode);
    // Get category options
    const initialCategoryOptions = initialRootCategoryOption
      ? categoriesOptions.find(el => el.code === initialRootCategoryOption.value)?.options || []
      : [];

    const initialCategoryOption =
      initialCategoryOptions.find(el => el.value === prefilledCategory?.id) || initialCategoryOptions[0];

    const [tmpCategoriesOptions, setTmpCategoriesOptions] = useState<ICategoryOptionOption[]>(
      () => initialCategoryOptions
    );

    // Get initial period option
    let initialPeriod;
    if (company?.id) {
      if (
        CATEGORY_PER_MONTH.includes(prefilledCategory?.code) ||
        CATEGORY_PER_MONTH.includes(prefilledCategory?.root?.code)
      ) {
        initialPeriod = 'per_month';
      } else {
        initialPeriod = 'per_year';
      }
    }

    const ReminderValidationSchema = yup.object({
      rootCategory: yup.object().required('Category is required'),
      category: yup.object().required('Sub category is required'),
      triggeredAt: yup.date().min(new Date(), 'Reminder should be in the future').required('Reminder is required'),
      isAutoQuote: yup.boolean(),
      phoneNumber: yup
        .string()
        .when('isAutoQuote', { is: true, then: schema => schema.required('Phone number is required') })
    });

    // Phone number
    const verifiedNumbers = currentUser?.verifiedNumbers;
    const phoneNumber = verifiedNumbers?.length ? verifiedNumbers[verifiedNumbers.length - 1] : null;

    // Init form
    const methods = useForm<ReminderForm>({
      mode: 'onSubmit',
      resolver: yupResolver(ReminderValidationSchema),
      defaultValues: {
        smartReminderId: selectedReminder?.id || null,
        rootCategory: initialRootCategoryOption,
        category: initialCategoryOption,
        triggeredAt: selectedReminder?.triggeredAt
          ? dayjs(selectedReminder?.triggeredAt)
          : selectedDate
          ? dayjs(selectedDate)
          : dayjs(),
        recurringTime: selectedReminder?.recurringTime || null,
        price: selectedReminder?.renewalDetail?.pricesQuestions?.price?.value || 0,
        period: selectedReminder?.renewalDetail?.pricesQuestions?.price?.period || initialPeriod,
        reminderType: reminderType && reminderType.length > 0 ? reminderType : 'select',
        renewalDetailId: selectedReminder?.renewalDetailId || null,
        isAutoQuote: selectedReminder?.isAutoQuote || false,
        phoneNumber: (selectedReminder?.quoteRequest?.phoneNumber || phoneNumber)?.replace(/\s+/g, '') || '',
        aliasName: selectedReminder?.aliasName || '',
        name: selectedReminder?.aliasName || '',
        companyRate: selectedReminder?.renewalDetail?.anyCompanyRate || null,
        formPhoneVerified: false
      }
    });

    // Flat extract methods from form control
    const { watch, setValue, handleSubmit } = methods;

    const renewalDetailId = watch('renewalDetailId');

    const category = watch('category');
    const categoryId = category?.value;

    const period = watch('period');

    const onChangeRootCategory = (data: { code: string }) => {
      const options = categoriesOptions.find(el => el.code === data?.code)?.options || [];
      setTmpCategoriesOptions(options);

      setValue('category', options[0]);
    };

    // Set category item by default

    const generateDefaultAliasName = useCallback((): string => {
      let aliasName = '';

      if (isEditType) {
        return '';
      }
      const detail = userDetails.find(el => el.id === renewalDetailId);
      if (detail) {
        return detail.smartReminder.aliasName;
      }
      aliasName = GetPolicyNameFromDetailsCollection(userDetails);
      return aliasName;
    }, [isEditType, renewalDetailId, userDetails]);

    const onClickAddNewPolicy = useCallback(
      userDetails => {
        const obj = userDetails.find(el => !el.id || el.id < 0);

        if (obj) {
          toastr.warning(`Please save '${obj.smartReminder?.aliasName}'`);
          return;
        }

        const tmpUserDetails = [...userDetails];

        const aliasName = GetPolicyNameFromDetailsCollection(tmpUserDetails, true);

        tmpUserDetails.push({ id: -tmpUserDetails.length, smartReminder: { aliasName } });

        setValue('renewalDetailId', tmpUserDetails[tmpUserDetails.length - 1]?.id);
        setValue('smartReminderId', null);
        setValue('aliasName', tmpUserDetails[tmpUserDetails.length - 1]?.smartReminder?.aliasName);

        setUserDetails(tmpUserDetails);
      },
      [setValue]
    );

    useEffect(() => {
      const fetchDetails = categoryId => {
        setDetailsLoading(true);
        renewalDetailApi
          .fetchUserRenewalDetails({ categoryId })
          .then(details => {
            setUserDetails(details);

            const selectDetail = details.find(el => el.id === selectedReminder?.renewalDetailId);

            // add new policy automaticly
            if (isCreateBusinessReminder && details.length && details[0].id) {
              onClickAddNewPolicy([...details]);
              return;
            }

            if (selectDetail && selectedReminder) {
              setValue('smartReminderId', selectedReminder.id);
              setValue('renewalDetailId', selectDetail.id);
              setValue('aliasName', selectDetail?.aliasName || '');
            } else {
              setValue('renewalDetailId', details[details.length - 1]?.id || 'new-0');
            }
          })
          .catch(err => {
            toastr.error(err.message);
          })
          .finally(() => {
            setDetailsLoading(false);
          });
      };

      if (categoryId) {
        fetchDetails(categoryId);
      }
    }, [
      categoryId,
      // generateDefaultAliasName,
      isCreateBusinessReminder,
      // onClickAddNewPolicy,
      selectedDate,
      selectedReminder,
      setValue
    ]);

    // Policy options
    const policyOptions = userDetails.map((el, index) => ({
      value: el.id || `new-${index}`,
      isNew: !el.id || el.id < 0,
      isReminderDeleted: !el.smartReminder.id,
      label: `${el.smartReminder?.aliasName || el.smartReminder?.name}`
    }));

    const isEmptyPolicy = userDetails.filter(item => item.id).length === 0 || hidePolicy;
    const currentPolicy = userDetails.find(item => item.id === renewalDetailId);

    // Auto update form by policy change
    useEffect(() => {
      const smartReminder = userDetails.find(el => el.id === renewalDetailId)?.smartReminder;

      if (smartReminder) {
        setValue('smartReminderId', smartReminder.id);
        setValue('recurringTime', smartReminder?.recurringTime || '');

        if (!selectedDate && smartReminder.triggeredAt && smartReminder.id) {
          const triggeredAt = dayjs(smartReminder?.triggeredAt).isAfter()
            ? dayjs(smartReminder?.triggeredAt)
            : dayjs().add(1, 'year');

          setValue('triggeredAt', triggeredAt);
        } else {
          setValue('triggeredAt', dayjs(selectedDate).add(1, 'year'));
        }

        const quoteRequest = smartReminder?.quoteRequest;
        if (quoteRequest) {
          setValue('isAutoQuote', true);
          setValue('phoneNumber', quoteRequest?.phoneNumber || '');
        }

        setValue('aliasName', smartReminder.aliasName);
        setValue('name', smartReminder?.aliasName);
      } else {
        setValue('aliasName', generateDefaultAliasName());
        setValue('triggeredAt', dayjs(selectedDate).add(1, 'year'));
      }

      // if (currentDetail) {
      //   setValue('aliasName', currentDetail.smartReminder?.aliasName || generateDefaultAliasName());
      //   setValue('name', currentDetail.smartReminder?.aliasName);
      //   setValue('smartReminderId', currentDetail.smartReminder?.id);
      //

      //
      //   if (triggeredAt) {
      //     setValue('triggeredAt', triggeredAt);
      //   }
      // }
    }, [generateDefaultAliasName, renewalDetailId, selectedDate, setValue, userDetails]);

    const [loading, setLoading] = useState<boolean>(false);
    const handleSubmitVerification = data => {
      toggleVerificationModalOpen({ modalProps: {} }, true);
      onSubmit({ ...data, formPhoneVerified: true });
    };

    const handleVerification = async data => {
      setLoading(true);

      const resp = (await api.phoneVerify({
        phoneNumber: data?.phoneNumber.replace(/\s+/g, '')
      })) as Record<string, any>;

      if (resp?.errors) {
        toastr.error(resp?.errors);
        setLoading(false);
      } else {
        toggleVerificationModalOpen({
          modalProps: {
            handleSubmitVerification: () => handleSubmitVerification(data),
            phoneNumber: data?.phoneNumber
          }
        });
        setLoading(false);
      }
    };

    const onSubmit: SubmitHandler<ReminderForm> = async data => {
      // Form callbacks
      const callback = resp => {
        if (selectedReminder) {
          toastr.success('Smart reminder updated');
        } else {
          toastr.success('Smart reminder created');
        }
        toggle();
        onSuccess && onSuccess(resp, data.renewalDetailId);
        toggle();
      };

      const errorCallback = errors => {
        const errorsData = errors?.errors || errors || {};
        if (Object.keys(errorsData).length > 0) {
          toastr.error(Object.values(errorsData));
        }
      };

      // Async validation
      const userVerifiedNumbers = currentUser.verifiedNumbers || [];
      const targetPhoneNumber = data.phoneNumber ? data.phoneNumber.replace(/\s+/g, '') : '';
      const phoneVerification = data.phoneNumber && userVerifiedNumbers.some(item => item === targetPhoneNumber);

      if (data.isAutoQuote && !phoneVerification && !data.formPhoneVerified) {
        handleVerification(data);

        return;
      }

      // Payload generation
      const payload: Record<string, any> = {
        categoryId: data.category.value,
        reminderType: data.reminderType,
        triggeredAt: dayjs(data.triggeredAt).format(SAVE_DATE_FORMAT),
        recurringTime: data.triggeredAt ? data.recurringTime || null : null,
        aliasName: data.aliasName,
        renewalDetailId: data.renewalDetailId,
        companyRate: null,
        companyId: null,
        pricesQuestions: null,
        isAutoQuote: data.isAutoQuote,
        phoneNumber: data.phoneNumber?.replace(/\s+/g, '') || null
      };

      if (payload.renewalDetailId && (payload.renewalDetailId === 'new-0' || payload.renewalDetailId <= 0)) {
        payload.renewalDetailId = null;
      }

      if (company?.id) {
        payload.companyId = company?.id;
        payload.companyRate = data.companyRate;
        payload.pricesQuestions = {
          price: {
            value: data.price,
            period: data.period
          }
        };
      }

      // Create or update request
      const reminderId = data.smartReminderId;
      if (reminderId) {
        return smartRemindersStore.update({ id: reminderId, ...payload }, callback, errorCallback);
      } else {
        return smartRemindersStore.create(payload, callback, errorCallback);
      }
    };

    return (
      <FormProvider {...methods}>
        <Dialog
          open={true}
          onClose={() => toggle()}
          fullWidth
          maxWidth='xs'
          scroll='paper'
          PaperProps={
            {
              component: 'form',
              onSubmit: handleSubmit(onSubmit)
            } as PaperProps // cast with an MUI issue
          }
        >
          <DialogTitle sx={{ position: 'relative' }}>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
              <Typography variant='h6'>My Renewal Date</Typography>
              <IconButton edge='end' color='inherit' sx={{ p: 0 }} onClick={() => toggle()}>
                <CloseIcon />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent dividers>
            <Stack spacing={2}>
              {company?.id && <Typography variant='subtitle1'>{company.name}</Typography>}

              <Controller
                name='rootCategory'
                render={({ field }) => (
                  <AutocompleteMUI
                    value={field.value}
                    options={rootCategoriesOptions}
                    onChange={data => {
                      field.onChange(data);
                      onChangeRootCategory(data);
                    }}
                    label='Сategory'
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    mobileSelect
                    filter
                    disabled={disabledCategory}
                  />
                )}
              />
              <Controller
                name='category'
                render={({ field }) => (
                  <AutocompleteMUI
                    value={field.value}
                    options={tmpCategoriesOptions}
                    onChange={field.onChange}
                    label='Sub Category'
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    mobileSelect
                    filter
                    disabled={disabledCategory}
                  />
                )}
              />
              <Controller
                name='triggeredAt'
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!error?.message}>
                    <DatePicker label='Renewal date' value={field.value} onChange={field.onChange} />
                    <FormHelperText>{error?.message}</FormHelperText>
                  </FormControl>
                )}
              />
              <Controller
                name='recurringTime'
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel id='select-renewal-label'>Select renewal period</InputLabel>
                    <Select
                      label='Select renewal period'
                      labelId='select-renewal-label'
                      id='select-renewal'
                      value={field.value || ''}
                      onChange={field.onChange}
                    >
                      {recurringOptions.map(option => (
                        <MenuItem key={option.value} value={option.value || ''}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
              {company?.id && (
                <Controller
                  name='price'
                  render={({ field }) => (
                    <EstimateCostInput
                      value={field.value}
                      onChange={field.onChange}
                      period={period}
                      estimateCostsEnabled={true}
                      fullWidth
                      directOnChange={val => setValue('period', val)}
                    />
                  )}
                />
              )}

              {shouldShowDetails ? (
                !isEmptyPolicy && (
                  <Box>
                    <Controller
                      name='renewalDetailId'
                      render={({ field }) => (
                        <FormControl fullWidth disabled={policyOptions.length === 1}>
                          <InputLabel id='select-policy-label'>Policy name:</InputLabel>
                          <Select
                            label='Policy name:'
                            labelId='select-policy-label'
                            id='select-policy'
                            value={field.value || ''}
                            onChange={field.onChange}
                          >
                            {policyOptions.map(option => (
                              <MenuItem key={option.value} value={option.value || ''}>
                                {`${option.label}${!option.isReminderDeleted ? ' 🕙' : ''}${option.isNew ? '*' : ''}`}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                    <Box>
                      <Button
                        variant='text'
                        size='small'
                        color={'dark' as ButtonProps['color']}
                        onClick={() => onClickAddNewPolicy(userDetails)}
                      >
                        + Add another policy
                      </Button>
                    </Box>
                  </Box>
                )
              ) : (
                // <div className='input-field' onMouseOut={handleMouseOnReminder}>
                <Controller
                  name='aliasName'
                  render={({ field }) => (
                    <TextField
                      label={'Reminder name <span>(optional)</span>'}
                      value={field.value}
                      onChange={field.onChange}
                      fullWidth
                    />
                  )}
                />
                // </div>
              )}

              {company?.id && (
                <Controller
                  name='companyRate'
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel id='select-companyRate-label'>Please rate this business</InputLabel>
                      <Select
                        label='Please rate this business'
                        labelId='select-companyRate-label'
                        id='select-renewal'
                        value={field.value || ''}
                        onChange={field.onChange}
                      >
                        {rateList.map(option => (
                          <MenuItem key={option.value} value={option.value || ''}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              )}

              {!company && (
                <Grid item xs={12}>
                  <Divider />
                  <AutoQuotesNew currentPolicy={currentPolicy} />
                </Grid>
              )}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button color='inherit' variant='text' size={'smallToMedium' as ButtonProps['size']} onClick={toggle}>
              Cancel
            </Button>

            <Button
              type='submit'
              variant='contained'
              size={'smallToMedium' as ButtonProps['size']}
              disabled={loading || isDetailsLoading}
            >
              {isEditType ? 'Update Reminder' : 'Add reminder'}
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    );
  }
);
