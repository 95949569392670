import React from 'react';

export const InsuranceScooterInsurance = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <path d='M19 7C19 5.9 18.1 5 17 5H15C14.45 5 14 5.45 14 6C14 6.55 14.45 7 15 7H17V9.65L13.52 14H10V10C10 9.45 9.55 9 9 9H6C3.79 9 2 10.79 2 13V16H4C4 17.66 5.34 19 7 19C8.66 19 10 17.66 10 16H14.48L19 10.35V7ZM7 17C6.45 17 6 16.55 6 16H8C8 16.55 7.55 17 7 17Z' />
      <path d='M9 6H6C5.45 6 5 6.45 5 7C5 7.55 5.45 8 6 8H9C9.55 8 10 7.55 10 7C10 6.45 9.55 6 9 6Z' />
      <path d='M19 13C17.34 13 16 14.34 16 16C16 17.66 17.34 19 19 19C20.66 19 22 17.66 22 16C22 14.34 20.66 13 19 13ZM19 17C18.45 17 18 16.55 18 16C18 15.45 18.45 15 19 15C19.55 15 20 15.45 20 16C20 16.55 19.55 17 19 17Z' />
    </svg>
  );
};
