import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import ComingSoonIcon from '@packs/images/ui/banners/coming-soon.webp';
import { COMING_SOON_CATEGORIES } from '@packs/models/category/constants';
import { useStore } from '@packs/stores';

import { bannerImage } from './constants';
import useStyles from './styles';

import startCase from 'lodash-es/startCase';

const { primary, text } = colors;

export const SimpleBanner = ({ category }) => {
  const { currentCountry } = useStore();
  const styles = useStyles();
  const categoryName = category?.name;

  if (COMING_SOON_CATEGORIES.includes(category?.code)) {
    return (
      <Box
        sx={{
          ...styles.wrapper,
          backgroundImage: `url(${ComingSoonIcon})`
        }}
      >
        <Chip label='Coming Soon' sx={{ mb: 1.5, backgroundColor: primary.tonal }} />
        <Typography variant='h6' sx={{ mb: 1 }}>
          We are creating something amazing
        </Typography>
        <Typography variant='body3' sx={{ color: text.secondary }}>
          We will soon be launching {startCase(category.name)}!
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        ...styles.wrapper,
        ...styles.bannerWrapper,
        backgroundImage: `url(${bannerImage(category.code)})`
      }}
    >
      <Box sx={styles.container}>
        <Box>
          <Typography variant='h6' sx={styles.title}>
            Member Benefits:
          </Typography>
          <Typography variant='body3'>
            Our {categoryName} leaderboards are powered by the people of {currentCountry?.name}.
          </Typography>
        </Box>
        <Box sx={{ ...styles.list, mt: 3 }}>
          <Box>
            <Box>
              <Typography variant='body4' sx={styles.title}>
                1. Personalized Leaderboards!
              </Typography>
            </Box>
            <Box>
              <Typography variant='body3'>
                Our {categoryName} leaderboards are powered by the people of Ireland.
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box>
              <Typography variant='body4' sx={styles.title}>
                2. Personalized Offers!
              </Typography>
            </Box>
            <Box>
              <Typography variant='body3'>Get all the best offers for {categoryName} in one place!</Typography>
            </Box>
          </Box>
          <Box>
            <Box>
              <Typography variant='body4' sx={styles.title}>
                3. Personalized Support!
              </Typography>
            </Box>
            <Box>
              <Typography variant='body3'>Get support and insights from our community!</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
