import { landingSectionFragment } from './fragments/landingSectionFragment';

export const categoryRootPage = `
  query categoryRootPage(
    $categoryId: Int,
  ) {
    categoryRootPage(
      categoryId: $categoryId,
    ) {
    blockHero {
      ${landingSectionFragment}
    }
    blockHeroImgUrl
    blockPurchase {
      ${landingSectionFragment}
    }
  }
}
`.trim();
