import {
  createSmartReminder,
  destroySmartReminders,
  fetchSmartReminders,
  fetchSmartRemindersCount,
  updateSmartReminder
} from '@packs/graphql/queries/smartReminder';
import { graphQLRequest } from '@packs/lib/utils';

const api = {
  createSmartReminder: variables =>
    graphQLRequest({
      query: createSmartReminder,
      variables: { input: variables }
    }),
  updateSmartReminder: variables =>
    graphQLRequest({
      query: updateSmartReminder,
      variables: { input: variables }
    }),
  destroySmartReminders: variables =>
    graphQLRequest({
      query: destroySmartReminders,
      variables: { input: variables }
    }),
  fetchSmartReminders: variables =>
    graphQLRequest({
      query: fetchSmartReminders,
      variables
    }),
  fetchSmartRemindersCount: variables =>
    graphQLRequest({
      query: fetchSmartRemindersCount,
      variables
    })
};

export default api;
