import React from 'react';

export const SubscriptionsDating = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_39_211)'>
        <path d='M4.75 3C2.04 3 0 5.00016 0 7.66016C0 10.9402 3.57016 14.07 4.91016 15.25L7.65625 17.707C8.08625 18.155 8.82125 18.0835 9.15625 17.5605L9.29688 17.3047C8.55688 16.2107 8 14.9403 8 13.5293C8 10.4293 10.53 8 13.75 8C14.68 8 15.62 8.22969 16.5 8.67969C16.64 8.60969 16.7799 8.54047 16.9199 8.48047C16.9699 8.21047 17 7.94016 17 7.66016C17 5.00016 14.96 3 12.25 3C10.61 3 9.29 3.89961 8.5 4.59961C7.71 3.89961 6.39 3 4.75 3ZM13.75 10C11.61 10 10 11.5193 10 13.5293C10 14.4793 10.4205 15.4007 10.9805 16.2207C11.8505 17.4907 13.0909 18.5191 13.7109 19.0391L15.9004 20.791C16.2514 21.071 16.7496 21.071 17.0996 20.791L19.2891 19.0391C20.2991 18.1891 23 15.9593 23 13.5293C23 11.5193 21.39 10 19.25 10C18.08 10 17.12 10.5491 16.5 11.0391C16.37 10.9391 16.2203 10.8402 16.0703 10.7402C15.4703 10.3402 14.68 10 13.75 10Z' />
      </g>
      <defs>
        <clipPath id='clip0_39_211'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
