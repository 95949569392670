import React, { useEffect, useState } from 'react';

export const Timer = ({ initialMinutes = 0, initialSeconds = 0, title = '' }) => {
  const [minutes, setMinutes] = useState(initialMinutes);
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <div className='d-flex align-items-center'>
      {minutes === 0 && seconds === 0 ? null : (
        <>
          {title && <div className='mr-5'>{title}</div>}
          <div>
            {initialMinutes ? `${minutes} : ` : ''}
            {seconds < 10 ? `0${seconds}` : seconds} sec.
          </div>
        </>
      )}
    </div>
  );
};
