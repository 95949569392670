import colors from '@crm/assets/theme/base/colors';
import typography from '@crm/assets/theme/base/typography';
import pxToRem from '@shared/assets/theme/functions/pxToRem';

const { transparent, light, info, secondary } = colors;
const { size } = typography;

const outlined = {
  base: {
    minHeight: pxToRem(40),
    color: light.main,
    borderColor: light.main,
    padding: `${pxToRem(10)} ${pxToRem(24)}`,

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(16)} !important`
    }
  },

  small: {
    minHeight: pxToRem(32),
    padding: `${pxToRem(6)} ${pxToRem(16)}`,
    fontSize: `${pxToRem(13)}`,

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(16)} !important`
    }
  },

  smallToMedium: {
    minHeight: pxToRem(40),
    padding: `${pxToRem(6)} ${pxToRem(16)}`,
    fontSize: size.sm,

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(16)} !important`
    }
  },

  medium: {
    minHeight: pxToRem(48),
    padding: `${pxToRem(12)} ${pxToRem(28)}`,
    fontSize: pxToRem(16),

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(22)} !important`
    }
  },

  large: {
    minHeight: pxToRem(56),
    padding: `${pxToRem(14)} ${pxToRem(24)}`,
    fontSize: pxToRem(18),

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(22)} !important`
    }
  },

  primary: {
    backgroundColor: transparent.main,
    borderColor: info.main
  },

  secondary: {
    backgroundColor: transparent.main,
    borderColor: secondary.main
  }
};

export default outlined;
