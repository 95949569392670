import React, { createContext, useContext, useEffect, useState } from 'react';
import { toJS } from 'mobx';
import _ from 'lodash-es';

import { renewalDetailApi } from '@packs/apis';
import { useCategories } from '@packs/models/category/useCategories';
import { useDetails } from '@packs/models/policy/useDetails';
import { handleOnChangeLogic } from '@packs/screens/PersonalizeResults/formHooks/changeCallbacks';
import { locationOptions } from '@packs/screens/PersonalizeResults/formHooks/options';
import { useDetailsOptions } from '@packs/screens/PersonalizeResults/formHooks/useDetailsOptions';
import { getTargetValue, setInitValues } from '@packs/screens/PersonalizeResults/formHooks/values';
import { useStore } from '@packs/stores/base';

export const DetailsFormContext = createContext();
export let nodes = {};
export const DetailsFormProvider = ({ children, localData }) => {
  const { detailsFormStore, currentCountry } = useStore();
  const rootMobxStore = useStore();
  const { currentCategory } = useCategories();
  const { currentPolicy } = useDetails();
  const [isCriteriaLoaded, setCriteriaLoaded] = useState(false);
  const [categoryCriteriaQuestions, setCategoryCriteriaQuestions] = useState([]);
  const { formValues } = detailsFormStore;

  const { onChangeDetailsOptions } = useDetailsOptions({
    setRemount: localData.setRemount,
    setStep: localData.setStep,
    onDetailRetrieve: localData.onDetailRetrieve,
    setModalOpen: localData.setModalOpen
  });

  useEffect(() => {
    if (currentCategory?.id) {
      nodes = {};

      // criteriaQuestions
      renewalDetailApi
        .fetchCriteriaQuestionList({
          categoryId: currentCategory.id,
          formType: 'personalize'
        })
        .then(dataCriterias => {
          if (dataCriterias.length && !dataCriterias.errors) {
            const options = { ...detailsFormStore.formOptions };
            _.set(detailsFormStore.formOptions, 'criteriaQuestions.location', locationOptions(currentCountry));
            _.each(dataCriterias, (obj, inx) => {
              if (obj.options?.length) options[`criteriaQuestions.${obj.name}`] = obj.options;
            });
            detailsFormStore.setCriteriaKeys(dataCriterias.map(({ name }) => name));
            detailsFormStore.setFormOptions(detailsFormStore.formOptions);

            setCategoryCriteriaQuestions(dataCriterias);
            setCriteriaLoaded(true);
          }
        });
    }
  }, [currentCategory?.id]);

  const change = (name, value, eventType = 'change', opts = {}) => {
    const node = nodes[name];
    const values = toJS(formValues, { recurseEverything: true });

    if (node?.type === 'checkbox' && node?.multiple) {
      let prevValue = _.get(values, name);
      if (!_.isArray(prevValue)) prevValue = [];

      if (_.includes(prevValue, value)) {
        prevValue = _.filter(prevValue, v => v !== value);
      } else {
        prevValue.push(value);
      }
      value = prevValue;
    }
    if (name === 'pricesQuestions.price.value') {
      if (eventType === 'focusIn') {
        value = null;
      } else {
        value = +value;
      }
    }

    _.set(values, name, value);
    detailsFormStore.setFormValues(values);
    handleOnChangeLogic(name, value, { localData, onChangeDetailsOptions, componentOptions: opts });
    return values;
  };

  const getRef = node => {
    if (!node) return;
    nodes[node.name] = node;

    // if (isLoaded) {
    setInitValues(node, { renewalDetail: currentPolicy, rootMobxStore });
    // }
  };

  const onChange = ({ target }, opts = {}) => {
    const name = target.name;
    const value = getTargetValue(target);
    change(name, value, 'change', opts);
  };

  const onBlur = ({ target }, opts = {}) => {
    const name = target.name;
    const value = getTargetValue(target);
    change(name, value, 'focusOut', opts);
  };

  const onFocus = ({ target }, opts = {}) => {
    const name = target.name;
    const value = getTargetValue(target);
    change(name, value, 'focusIn', opts);
  };

  return (
    <DetailsFormContext.Provider
      value={{
        nodes,
        getRef,
        onChange,
        change,
        onBlur,
        onFocus,
        isCriteriaLoaded,
        categoryCriteriaQuestions
      }}
    >
      {children}
    </DetailsFormContext.Provider>
  );
};
export const useDetailsForm = () => useContext(DetailsFormContext);
