export const getMonthsList = () => {
  const array = Array.from(Array(36).keys());

  return array.map((item, index) => ({
    label: `${index + 1} ${index === 0 ? 'month' : 'months'}`,
    value: `${index + 1}_${index === 0 ? 'month' : 'months'}`
  }));
};

export const getPeriodsList = [
  { value: 'per_month', label: 'Per month' },
  { value: 'per_year', label: 'Per year' }
];
