import colors from '@packs/assets/theme/base/colors';

const { borderColor } = colors;

export default () => ({
  container: {
    display: 'flex',
    gap: 5
  },
  containerWithOutFilters: {
    gap: 0
  },
  filterContainer: {
    width: '218px',
    minWidth: '218px',
    transition: 'all .2s'
  },
  filterContainerHide: {
    width: 0,
    minWidth: 0,
    height: 0,
    overflow: 'hidden'
  },
  filterContainerInner: {
    minWidth: '218px'
  },
  filterTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    mb: 2,
    borderBottom: `1px solid ${borderColor.main}`,
    pb: 1,
    pt: 1
  },
  content: {
    flex: 1,
    maxWidth: {
      xs: '100%',
      lg: '900px'
    }
  }
});
