import colors from '@packs/assets/theme/base/colors';

const { white, secondary, dark } = colors;

export default () => ({
  showAllWrapper: {
    width: {
      xs: '220px',
      xl: '240px'
    },
    minWidth: {
      xs: '220px',
      xl: '240px'
    },
    minHeight: '548px',
    borderRadius: '16px',
    border: '1px solid rgba(0, 0, 0, 0.16)',
    backgroundColor: white.main,
    p: 3,
    boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)'
  },
  showAllColumn: (selectedRootCategory, root) => ({
    position: 'relative',
    p: 1,
    borderRadius: '8px',
    backgroundColor: selectedRootCategory.code === root.code ? secondary.transparent : white.main,
    cursor: 'pointer',
    '&::before': {
      content: selectedRootCategory.code === root.code ? '""' : 'none',
      position: 'absolute',
      top: '50%',
      right: '-24px',
      borderTop: '40px solid transparent',
      borderBottom: '40px solid transparent',
      borderRight: '70px solid transparent',
      transform: 'translateY(-50%)',
      zIndex: '1'
    }
  }),
  subCategoriesWrapper: showAllCategories => ({
    display: 'flex',
    gap: 2,
    borderRadius: '16px',
    border: '1px solid rgba(0, 0, 0, 0.16)',
    p: 3,
    backgroundColor: white.main,
    boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)'
  }),
  subCategoryColumnList: (repeat = 1) => ({
    display: 'grid',
    gridTemplateColumns: `repeat(${repeat}, minmax(280px, 1fr))`,
    gap: 3,
    width: '100%'
    // maxHeight: '498px',
    // overflowY: 'auto'
  }),
  column: {
    minWidth: '280px'
  },
  categoryLink: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    '&:hover': {
      textDecoration: 'underline',
      color: dark.main // Set color for underline
    }
  },
  categoryName: {
    color: dark.main
  }
});
