import { isNull } from 'lodash-es';
import moment from 'moment';

import { renewalDetailApi } from '@packs/apis';
import { SAVE_DATE_FORMAT } from '@packs/screens/PersonalizeResults/formHooks/defaults';

export const useHandleFormSubmit = (props, currentCategory, onSuccess, onError) => {
  const {
    renewalDetail,
    data: {
      winnerCompany,
      triggeredAt,
      pricesQuestions,
      discountPrice,
      providerCompany,
      purchaseScore,
      purchaseComment,
      addSmartReminder,
      advancedPrice,
      recurringTime,
      isAutoQuote,
      phoneNumber
    }
  } = props;

  const calculateStartEnd = period => {
    const isMonth = pricesQuestions?.price?.period === 'per_month';
    const addDate = isMonth ? 'months' : 'years';
    const periodMonthCount = +period?.match(/\d+/);

    return {
      periodStartDate: moment().toDate(),
      periodEndDate: moment().add(periodMonthCount, addDate).toDate()
    };
  };

  const advancedPriceGroup = () => {
    let advancedPriceNew = { ...advancedPrice };

    if (
      advancedPriceNew?.electricity &&
      isNull(advancedPriceNew?.electricity?.dayPrice) &&
      isNull(advancedPriceNew?.electricity?.nightPrice) &&
      isNull(advancedPriceNew?.electricity?.standingPrice)
    ) {
      advancedPriceNew.electricity = null;
    }

    if (
      advancedPriceNew?.gas &&
      isNull(advancedPriceNew?.gas?.dayPrice) &&
      isNull(advancedPriceNew?.gas?.nightPrice) &&
      isNull(advancedPriceNew?.gas?.standingPrice)
    ) {
      advancedPriceNew.electricity = null;
    }

    if (isNull(advancedPriceNew?.electricity) && isNull(advancedPriceNew?.gas)) {
      advancedPriceNew = null;
    }

    return advancedPriceNew;
  };

  const params = {
    feedbackType: 'purchase',
    buyingType: winnerCompany?.companyType,
    lastBuy: 'today',
    pricesQuestions: pricesQuestions?.price?.value ? pricesQuestions : {},
    discountPrice: discountPrice
      ? {
          ...discountPrice,
          ...calculateStartEnd(discountPrice.period?.value)
        }
      : {},
    advancedPrice: advancedPrice ? advancedPriceGroup() : {},
    smartReminder: {
      id: renewalDetail?.smartReminder?.id,
      aliasName: renewalDetail?.smartReminder?.aliasName,
      triggeredAt: moment(triggeredAt).format(SAVE_DATE_FORMAT),
      recurringTime: recurringTime || null
    },
    purchaseScore,
    purchaseComment,
    anyCompanyId: winnerCompany.id,
    sourceProviderCompanyId: winnerCompany?.companyType !== 'provider' ? providerCompany.id : null
  };

  if (!addSmartReminder) {
    params.smartReminder = {
      ...params.smartReminder,
      _delete: true
    };
  }

  if (isAutoQuote) {
    params.quoteRequest = {
      phoneNumber
    };
  }

  renewalDetailApi
    .updateOrCreateWinnerDetail({
      ...params,
      categoryId: currentCategory.id,
      renewalDetailId: renewalDetail?.id
    })
    .then(resp => {
      if (resp.errors.length) {
        onError(resp);
      } else {
        onSuccess(resp);
      }
    })
    .catch(onError);
};
