import { action, computed, observable, runInAction } from 'mobx';
import { sortBy, uniqBy } from 'lodash-es';

import { renewalDetailApi } from '@packs/apis';
import { ICompany } from '@packs/interfaces/rootInterfaces';
import { RootStore } from '@packs/stores';
import { initDefaultPolicy } from '@packs/stores/PolicyStore';

import isNull from 'lodash-es/isNull';
import omitBy from 'lodash-es/omitBy';

export class PoliciesListStore {
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }
  rootStore: RootStore;
  @observable
  loading = false;
  @observable
  userRenewalDetails = [];
  @observable
  formDataOptions = {
    brokerCompanies: [] as ICompany[],
    comparisonSiteCompanies: [] as ICompany[],
    providerCompanies: [] as ICompany[],
    pricesQuestionsOptions: {} as Record<string, string>
  };
  @action setLoading(loading) {
    this.loading = loading;
  }

  @action setUserRenewalDetails(data) {
    this.userRenewalDetails = data;
  }

  @action async loadPolicyData(attrs: { renewalDetailId?: number; categoryId: number }) {
    if (attrs.renewalDetailId) {
      await this.rootStore.policyStore.loadPolicy(attrs.renewalDetailId);
    }
    if (this.rootStore.userStore.currentUser.id) {
      await this.loadUserRenewalDetails(attrs.categoryId, attrs.renewalDetailId);
    } else {
      const policy = initDefaultPolicy(attrs.categoryId);
      this.rootStore.policyStore.setCurrentPolicy(policy);
      this.setUserRenewalDetails([policy]);
    }
    await this.loadRenewalDetailCriteriaDetailsFormOptions(attrs.categoryId);
    await this.loadRenewalDetailFormOptionsCompanies(attrs.categoryId);

    return {
      renewalDetail: this.rootStore.policyStore.currentPolicy,
      userRenewalDetails: this.userRenewalDetails
    };
  }

  @action async loadUserRenewalDetails(categoryId: number, renewalDetailId: number) {
    const userRenewalDetails = await renewalDetailApi.fetchUserRenewalDetails({ categoryId });

    this.setUserRenewalDetails(userRenewalDetails);
    // set current policy
    if (userRenewalDetails[0]) {
      if (!renewalDetailId) {
        this.rootStore.policyStore.setCurrentPolicy(userRenewalDetails[0]);
      }
    } else {
      const policy = initDefaultPolicy(categoryId);
      if (!renewalDetailId) {
        this.rootStore.policyStore.setCurrentPolicy(policy);
      }
      this.setUserRenewalDetails([policy]);
    }
  }

  @action async loadRenewalDetailCriteriaDetailsFormOptions(categoryId: number) {
    const res = await renewalDetailApi.fetchRenewalDetailFormOptions({
      categoryId
    });

    this.rootStore.policyStore.setFormOptions(res.criteriaDetailsQuestionsOptions);
    runInAction(() => {
      this.formDataOptions = {
        ...this.formDataOptions,
        pricesQuestionsOptions: omitBy(res.pricesQuestionsOptions, isNull)
      };
    });
  }

  @action async loadRenewalDetailFormOptionsCompanies(categoryId: number) {
    const res = await renewalDetailApi.fetchRenewalDetailFormOptionsCompanies({ categoryId });

    runInAction(() => {
      this.formDataOptions = {
        ...this.formDataOptions,
        brokerCompanies: res.brokerCompanies,
        comparisonSiteCompanies: res.comparisonSiteCompanies,
        providerCompanies: res.providerCompanies
      };
    });
  }

  @computed get providersOptions() {
    const providers = this.formDataOptions.providerCompanies;
    if (!providers) return [];

    return providers.map(provider => ({
      value: provider.id,
      label: provider.name,
      ...provider
    }));
  }

  @computed get mixOptions() {
    const data = this.formDataOptions;

    if (Object.entries(data).length === 0) return [];
    const items = [
      ...(sortBy(data.providerCompanies, 'name') || []),
      ...(sortBy(data.brokerCompanies, 'name') || []),
      ...(sortBy(data.comparisonSiteCompanies, 'name') || [])
    ];

    return uniqBy(items, 'name').map(item => ({
      value: item.id,
      label: item.name,
      ...item
    }));
  }

  @computed get brokerOptions() {
    const data = this.formDataOptions;

    if (Object.entries(data).length === 0) return [];
    const items = [...(data.brokerCompanies || [])];
    return sortBy(items, 'name').map(item => ({
      value: item.id,
      label: item.name,
      ...item
    }));
  }
  @computed get comparisonSiteOptions() {
    const data = this.formDataOptions;

    if (Object.entries(data).length === 0) return [];
    const items = [...(data.comparisonSiteCompanies || [])];
    return sortBy(items, 'name').map(item => ({
      value: item.id,
      label: item.name,
      ...item
    }));
  }

  @computed get pricesQuestionsOptions() {
    return this.formDataOptions.pricesQuestionsOptions;
  }
}
