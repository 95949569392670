import React from 'react';

import { toastr } from '@packs/lib/helpers';
import { copyToClipboard } from '@packs/lib/utils';
import { getWindow } from '@shared/lib/utils';

import styled from 'styled-components';

const StyledDebugName = styled.span`
  z-index: 100;
  position: absolute;
  margin-left: -180px;
  opacity: 0.8;
  margin-top: -75px;
  font-size: 12px;
  background: #dedede;
  padding: 9px;
  border-radius: 10px;
  border: 1px solid #ff0000;
`;

export const DebugName = ({ name }) => {
  if (!getWindow().localStorage.getItem('debug-name')) return null;

  const onClick = () =>
    copyToClipboard(name, () => {
      toastr.info(`copied '${name}'`);
    });

  return <StyledDebugName onClick={onClick}>{name}</StyledDebugName>;
};
