import { filter, find, get } from 'lodash-es';

import { toastr } from '@packs/lib/helpers';
import { GetPolicyNameFromDetailsCollection } from '@packs/lib/helpers/renewalDetails';
import { useDetails } from '@packs/models/policy/useDetails';
import { nodes } from '@packs/screens/PersonalizeResults/DetailsFormProvider';
import { shouldOpenModal } from '@packs/screens/PersonalizeResults/formHooks/callbacks';
import { getDetailsOptions } from '@packs/screens/PersonalizeResults/formHooks/options';
import { useStore } from '@packs/stores';

export const useDetailsOptions = ({ setRemount, setStep, setModalOpen, onDetailRetrieve }) => {
  const { currentPolicy, userRenewalDetails } = useDetails();
  const { policyStore, detailsFormStore } = useStore();
  const { formValues, formErrors } = detailsFormStore;

  const aliasName = GetPolicyNameFromDetailsCollection(userRenewalDetails);
  const newSize = userRenewalDetails?.length + 1;

  const defaultDetail = {
    id: null,
    type: 'new',
    smartReminder: {
      id: null,
      name: aliasName,
      aliasName
    }
  };

  const detailsOptions = getDetailsOptions({ userRenewalDetails });
  const onChangeDetailsOptions = value => {
    const object = find(detailsOptions.slice(), { value })?.object;
    const type = value === 'new-detail' && 'new-detail';
    const objs = filter(userRenewalDetails.slice(), { id: null });

    if (currentPolicy?.id === value) {
      return; // do nothing if same detail selected
    }

    if (type === 'new-detail') {
      if (objs.length) {
        toastr.warning(`Please save '${get(objs, '0.smartReminder.name')}' before`);
      }

      // TODO add new policy to userDetails store
      policyStore.setCurrentPolicy(defaultDetail);

      const options = getDetailsOptions({ userRenewalDetails: [...userRenewalDetails, defaultDetail] });
      if (nodes.policySelect) {
        nodes.policySelect.options = options;
        nodes.policySelect.value = options[newSize - 1].value;
      }
      detailsFormStore.setClear(true);
      onDetailRetrieve({ renewalDetail: defaultDetail });
    } else {
      if (object.id) {
        policyStore
          .loadPolicy(object.id)
          .then(policy => {
            if (shouldOpenModal(policy) && policy?.buyingType !== 'other') setModalOpen(true);
            onDetailRetrieve({ renewalDetail: policy });
          })
          .catch(() => {
            setRemount && setRemount(true);
            setTimeout(() => setRemount && setRemount(false), 10);
          });
      } else {
        if (objs.length && get(objs, '0.index') !== object.index) {
          toastr.warning(`Please save '${get(objs, '0.smartReminder.name')}' before`);
        }

        policyStore.setCurrentPolicy({});
        formValues.lastBuy = null;
        formValues.buyingType = null;
        formValues.sourceProviderCompanyId = null;
        detailsFormStore.setFormValues(formValues);
        onDetailRetrieve({ renewalDetail: object });
      }
    }

    setStep(0); // always set first step if detail changed
  };

  return {
    defaultDetail,
    detailsOptions,
    onChangeDetailsOptions
  };
};
