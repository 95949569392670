import { FC } from 'react';

import Box from '@mui/material/Box';
import { ICategory } from '@packs/interfaces/rootInterfaces';
import { CategoryDefaultIcon } from '@shared/images/categories/CategoryDefaultIcon';
import { BankingAccountUs } from '@shared/images/sub-categories-icons/banking-account-us';
import { BankingAccounts } from '@shared/images/sub-categories-icons/banking-accounts';
import { BankingCertificatesDeposit } from '@shared/images/sub-categories-icons/banking-certificates-deposit';
import { BankingCreditCards } from '@shared/images/sub-categories-icons/banking-credit-cards';
import { BankingDeposits } from '@shared/images/sub-categories-icons/banking-deposits';
import { BankingIncomeInvestments } from '@shared/images/sub-categories-icons/banking-income-investments';
import { BankingIncomeProtection } from '@shared/images/sub-categories-icons/banking-income-protection';
import { BankingLoans } from '@shared/images/sub-categories-icons/banking-loans';
import { BankingMortgages } from '@shared/images/sub-categories-icons/banking-mortgages';
import { BankingMortgagesProtection } from '@shared/images/sub-categories-icons/banking-mortgages-protection';
import { BankingPensions } from '@shared/images/sub-categories-icons/banking-pensions';
import { BankingPrepaidCards } from '@shared/images/sub-categories-icons/banking-prepaid-cards';
import { BankingProtection } from '@shared/images/sub-categories-icons/banking-protection';
import { BankingSaving } from '@shared/images/sub-categories-icons/banking-saving';
import { BroadbandBroadbandPhone } from '@shared/images/sub-categories-icons/broadband-broadband-phone';
import { BroadbandBroadbandPhoneTv } from '@shared/images/sub-categories-icons/broadband-broadband-phone-tv';
import { BroadbandBroadbandPhoneTvMobile } from '@shared/images/sub-categories-icons/broadband-broadband-phone-tv-mobile';
import { BroadbandBroadbandSubcat } from '@shared/images/sub-categories-icons/broadband-broadband-subcat';
import { BroadbandBroadbandTv } from '@shared/images/sub-categories-icons/broadband-broadband-tv';
import { BroadbandBroadbandTvOnly } from '@shared/images/sub-categories-icons/broadband-broadband-tv-only';
import { BusinessAssetInvoiceFinance } from '@shared/images/sub-categories-icons/business-asset-invoice-finance';
import { BusinessBusinessBroadbandSubcat } from '@shared/images/sub-categories-icons/business-business-broadband-subcat';
import { BusinessBusinessCards } from '@shared/images/sub-categories-icons/business-business-cards';
import { BusinessBusinessCurrentAccounts } from '@shared/images/sub-categories-icons/business-business-current-accounts';
import { BusinessBusinessDeposits } from '@shared/images/sub-categories-icons/business-business-deposits';
import { BusinessBusinessElectricity } from '@shared/images/sub-categories-icons/business-business-electricity';
import { BusinessBusinessGadgetInsurance } from '@shared/images/sub-categories-icons/business-business-gadget-insurance';
import { BusinessBusinessGas } from '@shared/images/sub-categories-icons/business-business-gas';
import { BusinessBusinessGasElectricity } from '@shared/images/sub-categories-icons/business-business-gas-electricity';
import { BusinessBusinessInterruptionInsurance } from '@shared/images/sub-categories-icons/business-business-interruption-insurance';
import { BusinessBusinessLoans } from '@shared/images/sub-categories-icons/business-business-loans';
import { BusinessBusinessMobile } from '@shared/images/sub-categories-icons/business-business-mobile';
import { BusinessCardPaymentSolutions } from '@shared/images/sub-categories-icons/business-card-payment-solutions';
import { BusinessCommercialPropertyInsurance } from '@shared/images/sub-categories-icons/business-commercial-property-insurance';
import { BusinessCorporatePensions } from '@shared/images/sub-categories-icons/business-corporate-pensions';
import { BusinessCyberInsurance } from '@shared/images/sub-categories-icons/business-cyber-insurance';
import { BusinessEPayments } from '@shared/images/sub-categories-icons/business-e-payments';
import { BusinessEmployerLiabilityInsurance } from '@shared/images/sub-categories-icons/business-employer-liability-insurance';
import { BusinessEventInsurance } from '@shared/images/sub-categories-icons/business-event-insurance';
import { BusinessFarmInsurance } from '@shared/images/sub-categories-icons/business-farm-insurance';
import { BusinessFleetInsurance } from '@shared/images/sub-categories-icons/business-fleet-insurance';
import { BusinessInternationalPayments } from '@shared/images/sub-categories-icons/business-international-payments';
import { BusinessMarineInsurance } from '@shared/images/sub-categories-icons/business-marine-insurance';
import { BusinessProfessionalIndemnityInsurance } from '@shared/images/sub-categories-icons/business-professional-indemnity-insurance';
import { BusinessPublicLiabilityInsurance } from '@shared/images/sub-categories-icons/business-public-liability-insurance';
import { BusinessTaxiInsurance } from '@shared/images/sub-categories-icons/business-taxi-insurance';
import { EnergyElectricity } from '@shared/images/sub-categories-icons/energy-electricity';
import { EnergyGas } from '@shared/images/sub-categories-icons/energy-gas';
import { EnergyGasElectricity } from '@shared/images/sub-categories-icons/energy-gas-electricity';
import { FoodGrocerySupermarket } from '@shared/images/sub-categories-icons/food-grocery-supermarket';
import { FoodRestaurants } from '@shared/images/sub-categories-icons/food-restaurants';
import { FoodTakeawayDelivery } from '@shared/images/sub-categories-icons/food-takeaway-delivery';
import { GeneralBooks } from '@shared/images/sub-categories-icons/general-books';
import { GeneralBusinessServices } from '@shared/images/sub-categories-icons/general-business_services';
import { GeneralClothingAndFootwear } from '@shared/images/sub-categories-icons/general-clothing_and_footwear';
import { GeneralComputersAndElectronics } from '@shared/images/sub-categories-icons/general-computers_and_electronics';
import { GeneralCreditCardPayments } from '@shared/images/sub-categories-icons/general-credit_card_payments';
import { GeneralEducation } from '@shared/images/sub-categories-icons/general-education';
import { GeneralFeesAndCharge } from '@shared/images/sub-categories-icons/general-fees_and_charge';
import { GeneralFinancial } from '@shared/images/sub-categories-icons/general-financial';
import { GeneralGifts } from '@shared/images/sub-categories-icons/general-gifts';
import { GeneralGovernmentLicences } from '@shared/images/sub-categories-icons/general-government_licences';
import { GeneralHealthAndFitness } from '@shared/images/sub-categories-icons/general-health_and_fitness';
import { GeneralKids } from '@shared/images/sub-categories-icons/general-kids';
import { GeneralMedicalExpenses } from '@shared/images/sub-categories-icons/general-medical_expenses';
import { GeneralMisc } from '@shared/images/sub-categories-icons/general-misc';
import { GeneralMotor } from '@shared/images/sub-categories-icons/general-motor';
import { GeneralPersonalCare } from '@shared/images/sub-categories-icons/general-personal_care';
import { GeneralPets } from '@shared/images/sub-categories-icons/general-pets';
import { GeneralRent } from '@shared/images/sub-categories-icons/general-rent';
import { GeneralShopping } from '@shared/images/sub-categories-icons/general-shopping';
import { GeneralSocialAndEntertainment } from '@shared/images/sub-categories-icons/general-social_and_entertainment';
import { GeneralTakeAways } from '@shared/images/sub-categories-icons/general-take_aways';
import { GeneralTax } from '@shared/images/sub-categories-icons/general-tax';
import { GeneralVacation } from '@shared/images/sub-categories-icons/generalVacation';
import { GovernmentCarAutoTax } from '@shared/images/sub-categories-icons/government-car_auto_tax';
import { GovernmentLicences } from '@shared/images/sub-categories-icons/government-licences';
import { GovernmentPassport } from '@shared/images/sub-categories-icons/government-passport';
import { GovernmentPropertyTax } from '@shared/images/sub-categories-icons/government-property_tax';
import { GovernmentTvLicense } from '@shared/images/sub-categories-icons/government-tv_license';
import { InsuranceBikeInsurance } from '@shared/images/sub-categories-icons/insurance-bike-insurance';
import { InsuranceCarHomeInsurance } from '@shared/images/sub-categories-icons/insurance-car-home-insurance';
import { InsuranceCarInsurance } from '@shared/images/sub-categories-icons/insurance-car-insurance';
import { InsuranceCaravanRvInsurance } from '@shared/images/sub-categories-icons/insurance-caravan-rv-insurance';
import { InsuranceGadgetInsurance } from '@shared/images/sub-categories-icons/insurance-gadget-insurance';
import { InsuranceGapInsurance } from '@shared/images/sub-categories-icons/insurance-gap-insurance';
import { InsuranceHealthInsurance } from '@shared/images/sub-categories-icons/insurance-health-insurance';
import { InsuranceHolidayHomeInsurance } from '@shared/images/sub-categories-icons/insurance-holiday-home-insurance';
import { InsuranceHomeInsurance } from '@shared/images/sub-categories-icons/insurance-home-insurance';
import { InsuranceLifeInsurance } from '@shared/images/sub-categories-icons/insurance-life-insurance';
import { InsuranceMotorbikeInsurance } from '@shared/images/sub-categories-icons/insurance-motorbike-insurance';
import { InsurancePetInsurance } from '@shared/images/sub-categories-icons/insurance-pet-insurance';
import { InsuranceProfessionalIndemnityInsurance } from '@shared/images/sub-categories-icons/insurance-professional-indemnity-insurance';
import { InsuranceScooterInsurance } from '@shared/images/sub-categories-icons/insurance-scooter-insurance';
import { InsuranceStudentInsurance } from '@shared/images/sub-categories-icons/insurance-student-insurance';
import { InsuranceTaxiInsurance } from '@shared/images/sub-categories-icons/insurance-taxi-insurance';
import { InsuranceTelematicsInsurance } from '@shared/images/sub-categories-icons/insurance-telematics-insurance';
import { InsuranceTenantContentInsurance } from '@shared/images/sub-categories-icons/insurance-tenant-content-insurance';
import { InsuranceTravelInsurance } from '@shared/images/sub-categories-icons/insurance-travel-insurance';
import { InsuranceVanInsurance } from '@shared/images/sub-categories-icons/insurance-van-insurance';
import { Local_servicesBarsPubs } from '@shared/images/sub-categories-icons/local_services-bars-pubs';
import { Local_servicesContractors } from '@shared/images/sub-categories-icons/local_services-contractors';
import { Local_servicesDryCleaner } from '@shared/images/sub-categories-icons/local_services-dry-cleaner';
import { Local_servicesElectricians } from '@shared/images/sub-categories-icons/local_services-electricians';
import { Local_servicesGym } from '@shared/images/sub-categories-icons/local_services-gym';
import { Local_servicesHairSalons } from '@shared/images/sub-categories-icons/local_services-hair-salons';
import { Local_servicesHardware } from '@shared/images/sub-categories-icons/local_services-hardware';
import { Local_servicesHeatingOil } from '@shared/images/sub-categories-icons/local_services-heating-oil';
import { Local_servicesHomeCleaners } from '@shared/images/sub-categories-icons/local_services-home-cleaners';
import { Local_servicesHomeRemovals } from '@shared/images/sub-categories-icons/local_services-home-removals';
import { Local_servicesLocksmiths } from '@shared/images/sub-categories-icons/local_services-locksmiths';
import { Local_servicesPainters } from '@shared/images/sub-categories-icons/local_services-painters';
import { Local_servicesPetSittersMinders } from '@shared/images/sub-categories-icons/local_services-pet-sitters-minders';
import { Local_servicesPhoneRepair } from '@shared/images/sub-categories-icons/local_services-phone-repair';
import { Local_servicesPlumbers } from '@shared/images/sub-categories-icons/local_services-plumbers';
import { Local_servicesRealEstateAgentsPropertyAgents } from '@shared/images/sub-categories-icons/local_services-real-estate-agents-property-agents';
import { MobileMobileContract } from '@shared/images/sub-categories-icons/mobile-mobile-contract';
import { MobileMobilePhones } from '@shared/images/sub-categories-icons/mobile-mobile-phones';
import { MobileMobilePrePay } from '@shared/images/sub-categories-icons/mobile-mobile-pre-pay';
import { MobileMobileSubcat } from '@shared/images/sub-categories-icons/mobile-mobile-subcat';
import { SubscriptionsAntiVirus } from '@shared/images/sub-categories-icons/subscriptions-anti-virus';
import { SubscriptionsBeautySubscriptions } from '@shared/images/sub-categories-icons/subscriptions-beauty-subscriptions';
import { SubscriptionsDating } from '@shared/images/sub-categories-icons/subscriptions-dating';
import { SubscriptionsDrinkCoffee } from '@shared/images/sub-categories-icons/subscriptions-drink-coffee';
import { SubscriptionsFoodDeliveryApps } from '@shared/images/sub-categories-icons/subscriptions-food-delivery-apps';
import { SubscriptionsFoodMealKit } from '@shared/images/sub-categories-icons/subscriptions-food-meal-kit';
import { SubscriptionsFoodMealKitSubscriptions } from '@shared/images/sub-categories-icons/subscriptions-food-meal-kit-subscriptions';
import { SubscriptionsGames } from '@shared/images/sub-categories-icons/subscriptions-games';
import { SubscriptionsMovie } from '@shared/images/sub-categories-icons/subscriptions-movie';
import { SubscriptionsMusic } from '@shared/images/sub-categories-icons/subscriptions-music';
import { SubscriptionsNewspapers } from '@shared/images/sub-categories-icons/subscriptions-newspapers';
import { SubscriptionsPetSubscriptions } from '@shared/images/sub-categories-icons/subscriptions-pet-subscriptions';
import { SubscriptionsSubscriptionBoxes } from '@shared/images/sub-categories-icons/subscriptions-subscription-boxes';
import { TravelAccomodation } from '@shared/images/sub-categories-icons/travel-accomodation';
import { TravelAirlines } from '@shared/images/sub-categories-icons/travel-airlines';
import { TravelCarRental } from '@shared/images/sub-categories-icons/travel-car-rental';
import { WasteCollectionCommercialBinCollection } from '@shared/images/sub-categories-icons/waste_collection-commercial_bin_collection';
import { WasteCollectionCommercialCleaningService } from '@shared/images/sub-categories-icons/waste_collection-commercial_cleaning_service';
import { WasteCollectionCommercialWasteCollection } from '@shared/images/sub-categories-icons/waste_collection-commercial_waste_collection';
import { WasteCollectionDomesticBinCollection } from '@shared/images/sub-categories-icons/waste_collection-domestic_bin_collection';

const ICONS = {
  'insurance-car_insurance': <InsuranceCarInsurance />,
  'insurance-bike_insurance': <InsuranceBikeInsurance />,
  'insurance-car_home_insurance': <InsuranceCarHomeInsurance />,
  'insurance-caravan_rv_insurance': <InsuranceCaravanRvInsurance />,
  'insurance-gadget_insurance': <InsuranceGadgetInsurance />,
  'insurance-gap_insurance': <InsuranceGapInsurance />,
  'insurance-health_insurance': <InsuranceHealthInsurance />,
  'insurance-holiday_home_insurance': <InsuranceHolidayHomeInsurance />,
  'insurance-home_insurance': <InsuranceHomeInsurance />,
  'insurance-life_insurance': <InsuranceLifeInsurance />,
  'insurance-motorbike_insurance': <InsuranceMotorbikeInsurance />,
  'insurance-pet_insurance': <InsurancePetInsurance />,
  'insurance-professional_indemnity_insurance': <InsuranceProfessionalIndemnityInsurance />,
  'insurance-scooter_insurance': <InsuranceScooterInsurance />,
  'insurance-student_insurance': <InsuranceStudentInsurance />,
  'insurance-taxi_insurance': <InsuranceTaxiInsurance />,
  'insurance-telematics_insurance': <InsuranceTelematicsInsurance />,
  'insurance-tenant_content_insurance': <InsuranceTenantContentInsurance />,
  'insurance-travel_insurance': <InsuranceTravelInsurance />,
  'insurance-van_insurance': <InsuranceVanInsurance />,

  'broadband-broadband_subcat': <BroadbandBroadbandSubcat />,
  'broadband-broadband_phone': <BroadbandBroadbandPhone />,
  'broadband-broadband_tv': <BroadbandBroadbandTv />,
  'broadband-broadband_phone_tv': <BroadbandBroadbandPhoneTv />,
  'broadband-broadband_phone_tv_mobile': <BroadbandBroadbandPhoneTvMobile />,
  'broadband-tv_only': <BroadbandBroadbandTvOnly />,

  'mobile-mobile_contract': <MobileMobileContract />,
  'mobile-mobile_phones': <MobileMobilePhones />,
  'mobile-mobile_pre_pay': <MobileMobilePrePay />,
  'mobile-mobile_subcat': <MobileMobileSubcat />,

  'energy-electricity': <EnergyElectricity />,
  'energy-gas': <EnergyGas />,
  'energy-gas_and_electricity': <EnergyGasElectricity />,

  'subscriptions-anti_virus': <SubscriptionsAntiVirus />,
  'subscriptions-beauty_subscriptions': <SubscriptionsBeautySubscriptions />,
  'subscriptions-dating': <SubscriptionsDating />,
  'subscriptions-drink_coffee': <SubscriptionsDrinkCoffee />,
  'subscriptions-food_meal_kit': <SubscriptionsFoodMealKit />,
  'subscriptions-food_delivery_apps': <SubscriptionsFoodDeliveryApps />,
  'subscriptions-food_meal_kit_subscriptions': <SubscriptionsFoodMealKitSubscriptions />,
  'subscriptions-games': <SubscriptionsGames />,
  'subscriptions-movie': <SubscriptionsMovie />,
  'subscriptions-music': <SubscriptionsMusic />,
  'subscriptions-newspapers': <SubscriptionsNewspapers />,
  'subscriptions-pet_subscriptions': <SubscriptionsPetSubscriptions />,
  'subscriptions-subscription_boxes': <SubscriptionsSubscriptionBoxes />,

  'banking-certificates_deposit': <BankingCertificatesDeposit />,
  'banking-account_us': <BankingAccounts />,
  'banking-credit_cards': <BankingCreditCards />,
  'banking-accounts': <BankingAccountUs />,
  'banking-deposits': <BankingDeposits />,
  'banking-income_protection': <BankingIncomeProtection />,
  'banking-investments': <BankingIncomeInvestments />,
  'banking-loans': <BankingLoans />,
  'banking-mortgages_protection': <BankingMortgagesProtection />,
  'banking-mortgages': <BankingMortgages />,
  'banking-pensions': <BankingPensions />,
  'banking-prepaid_cards': <BankingPrepaidCards />,
  'banking-protection': <BankingProtection />,
  'banking-savings': <BankingSaving />,

  'business-asset_invoice_finance': <BusinessAssetInvoiceFinance />,
  'business-business_broadband_subcat': <BusinessBusinessBroadbandSubcat />,
  'business-business_current_accounts': <BusinessBusinessCurrentAccounts />,
  'business-business_gadget_insurance': <BusinessBusinessGadgetInsurance />,
  'business-business_mobile': <BusinessBusinessMobile />,
  'business-business_professional_indemnity_insurance': <BusinessProfessionalIndemnityInsurance />,
  'business-business_taxi_insurance': <BusinessTaxiInsurance />,
  'business-business_cards': <BusinessBusinessCards />,
  'business-business_deposits': <BusinessBusinessDeposits />,
  'business-business_interruption_insurance': <BusinessBusinessInterruptionInsurance />,
  'business-business_loans': <BusinessBusinessLoans />,
  'business-card_payment_solutions': <BusinessCardPaymentSolutions />,
  'business-commercial_property_insurance': <BusinessCommercialPropertyInsurance />,
  'business-business_electricity': <BusinessBusinessElectricity />,
  'business-business_gas': <BusinessBusinessGas />,
  'business-business_gas_and_electricity': <BusinessBusinessGasElectricity />,
  'business-corporate_pensions': <BusinessCorporatePensions />,
  'business-cyber_insurance': <BusinessCyberInsurance />,
  'business-employer_liability_insurance': <BusinessEmployerLiabilityInsurance />,
  'business-event_insurance': <BusinessEventInsurance />,
  'business-farm_insurance': <BusinessFarmInsurance />,
  'business-fleet_insurance': <BusinessFleetInsurance />,
  'business-gadget_insurance': <BusinessBusinessGadgetInsurance />,
  'business-international_payments': <BusinessInternationalPayments />,
  'business-marine_insurance': <BusinessMarineInsurance />,
  'business-professional_indemnity_insurance': <BusinessProfessionalIndemnityInsurance />,
  'business-public_liability_insurance': <BusinessPublicLiabilityInsurance />,
  'business-taxi_insurance': <BusinessTaxiInsurance />,
  'business-e_payments': <BusinessEPayments />,

  'travel-accomodation': <TravelAccomodation />,
  'travel-airlines': <TravelAirlines />,
  'travel-car_rental': <TravelCarRental />,

  'food-grocery_supermarkets': <FoodGrocerySupermarket />,
  'food-restaurants': <FoodRestaurants />,
  'food-takeaway_delivery': <FoodTakeawayDelivery />,

  'local_services-bars_and_pubs': <Local_servicesBarsPubs />,
  'local_services-contractors': <Local_servicesContractors />,
  'local_services-dry_cleaner': <Local_servicesDryCleaner />,
  'local_services-electricians': <Local_servicesElectricians />,
  'local_services-gym': <Local_servicesGym />,
  'local_services-hair_salons': <Local_servicesHairSalons />,
  'local_services-hardware': <Local_servicesHardware />,
  'local_services-heating_oil': <Local_servicesHeatingOil />,
  'local_services-home_cleaners': <Local_servicesHomeCleaners />,
  'local_services-home_removals': <Local_servicesHomeRemovals />,
  'local_services-locksmiths': <Local_servicesLocksmiths />,
  'local_services-painters': <Local_servicesPainters />,
  'local_services-pet_sitters_and_minders': <Local_servicesPetSittersMinders />,
  'local_services-phone_repair': <Local_servicesPhoneRepair />,
  'local_services-plumbers': <Local_servicesPlumbers />,
  'local_services-real_estate_agents_and_property_agents': <Local_servicesRealEstateAgentsPropertyAgents />,

  'waste_collection-commercial_cleaning_service': <WasteCollectionCommercialCleaningService />,
  'waste_collection-commercial_waste_collection': <WasteCollectionCommercialWasteCollection />,
  'waste_collection-commercial_bin_collection': <WasteCollectionCommercialBinCollection />,
  'waste_collection-domestic_bin_collection': <WasteCollectionDomesticBinCollection />,

  'government-car_auto_tax': <GovernmentCarAutoTax />,
  'government-licences': <GovernmentLicences />,
  'government-passport': <GovernmentPassport />,
  'government-property_tax': <GovernmentPropertyTax />,
  'government-tv_license': <GovernmentTvLicense />,

  'general-books': <GeneralBooks />,
  'general-business_services': <GeneralBusinessServices />,
  'general-clothing_and_footwear': <GeneralClothingAndFootwear />,
  'general-computers_and_electronics': <GeneralComputersAndElectronics />,
  'general-credit_card_payments': <GeneralCreditCardPayments />,
  'general-education': <GeneralEducation />,
  'general-fees_and_charges': <GeneralFeesAndCharge />,
  'general-financial': <GeneralFinancial />,
  'general-gifts': <GeneralGifts />,
  'general-government_licences': <GeneralGovernmentLicences />,
  'general-groceries': <GeneralGovernmentLicences />,
  'general-health_and_fitness': <GeneralHealthAndFitness />,
  'general-home_and_garden': <GeneralHealthAndFitness />,
  'general-kids': <GeneralKids />,
  'general-medical_expenses': <GeneralMedicalExpenses />,
  'general-misc': <GeneralMisc />,
  'general-motor': <GeneralMotor />,
  'general-personal_care': <GeneralPersonalCare />,
  'general-pets': <GeneralPets />,
  'general-rent': <GeneralRent />,
  'general-shopping': <GeneralShopping />,
  'general-social_and_entertainment': <GeneralSocialAndEntertainment />,
  'general-take_aways': <GeneralTakeAways />,
  'general-tax': <GeneralTax />,
  'general-vacation': <GeneralVacation />
};

type SUB_ICONSProps = {
  category: ICategory;
  fill?: string;
  size?: string;
};
export const SUB_ICONS: FC<SUB_ICONSProps> = ({ category, fill = 'white', size = '24px' }) => (
  <Box
    display='flex'
    alignItems='center'
    justifyContent='center'
    sx={{
      '& svg': {
        width: size,
        height: size,
        fill
      }
    }}
  >
    {ICONS[category?.root?.enName + '-' + category.code] || <CategoryDefaultIcon />}
  </Box>
);
