import React from 'react';

export default () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='21' height='20' viewBox='0 0 21 20'>
      <g fillRule='nonzero'>
        <path d='M9.8 12.241H8.4V7.414C8.4 6.069 7.315 5 5.95 5h-3.5C1.085 5 0 6.069 0 7.414v4.827h.7V7.414c0-.966.77-1.724 1.75-1.724h.455l.945 2.448v3.069h.7V8.276l.035-.035.98-2.551h.385c.98 0 1.75.758 1.75 1.724v4.827H6.65V7.76h-.7V19.138h-1.4V12.93h.7v-.69h-2.1v.69h.7v6.207h-1.4V7.758h-.7v11.725c0 .207.14.345.35.345h4.2c.21 0 .35-.138.35-.345v-3.104H9.8c.21 0 .35-.138.35-.345v-3.448c0-.207-.14-.345-.35-.345zM4.235 7.138l-.56-1.448h1.12l-.56 1.448zM9.45 12.93v1.38h-2.8v-1.38h2.8zm-2.8 2.759V15h2.8v.69h-2.8zM3.85 4.655h.7c.98 0 1.75-.758 1.75-1.724V1.897C6.3.93 5.53.172 4.55.172h-.7c-.98 0-1.75.759-1.75 1.725V2.93c0 .966.77 1.724 1.75 1.724zM2.8 1.897c0-.587.455-1.035 1.05-1.035h.7c.595 0 1.05.448 1.05 1.035V2.93c0 .586-.455 1.035-1.05 1.035h-.7c-.595 0-1.05-.449-1.05-1.035V1.897zM20.65 12.241h-1.4V7.414C19.25 6.069 18.165 5 16.8 5h-3.5c-1.365 0-2.45 1.069-2.45 2.414v4.827h.7V7.414c0-.966.77-1.724 1.75-1.724h.455l.945 2.448v3.069h.7V8.276l.035-.035.98-2.551h.385c.98 0 1.75.758 1.75 1.724v4.827H17.5V7.76h-.7V19.138h-1.4V12.93h.7v-.69H14v.69h.7v6.207h-1.4V7.758h-.7v11.725c0 .207.14.345.35.345h4.2c.21 0 .35-.138.35-.345v-3.104h3.15c.21 0 .35-.138.35-.345v-3.448c0-.207-.14-.345-.35-.345zm-5.565-5.103l-.56-1.448h1.12l-.56 1.448zM20.3 12.93v1.38h-2.8v-1.38h2.8zm-2.8 2.759V15h2.8v.69h-2.8zM14.7 4.655h.7c.98 0 1.75-.758 1.75-1.724V1.897c0-.966-.77-1.725-1.75-1.725h-.7c-.98 0-1.75.759-1.75 1.725V2.93c0 .966.77 1.724 1.75 1.724zm-1.05-2.758c0-.587.455-1.035 1.05-1.035h.7c.595 0 1.05.448 1.05 1.035V2.93c0 .586-.455 1.035-1.05 1.035h-.7c-.595 0-1.05-.449-1.05-1.035V1.897z' />
      </g>
    </svg>
  );
};
