import React, { useMemo } from 'react';
import cx from 'classnames';

import { Avatar } from '@packs/components/UI';
import { BusinessReminder } from '@packs/components/UI/Business/BusinessReminder';
import InsuranceIcon from '@packs/images/icons/InsuranceIcon';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { pathGen } from '@packs/layouts/constants';
import { divideStarRating } from '@packs/lib/helpers';
import { handleVisitCompany } from '@packs/lib/helpers/additionalHelpers';
import { useDetails } from '@packs/models/policy/useDetails';
import { useStore } from '@packs/stores';
import { RatingMUI } from '@shared/components/RatingMUI/RatingMUI';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

export const BusinessInfo = observer(
  ({ company, category = null, IS_BUSINESS_PAGE = false, fetchDetailsAndInsights }) => {
    const { toggleBusinessVoteModal } = useModalsContext();
    const { isDesktop } = useWindowSize();
    const { countryCode, companiesStore } = useStore();
    const { currentPolicy } = useDetails();
    const { avatarUrl = '', name = '', additionalData } = company;

    const divRating = useMemo(() => {
      return divideStarRating(company?.additionalData?.rating) || 0;
    }, [company?.additionalData?.rating]);

    const isPartnered = useMemo(() => {
      return company?.quoteAgents?.some(agent => agent?.isPartner);
    }, [company?.quoteAgents]);
    const offerLink = company?.partnerProvider?.customWebLink || '';
    const affinityLink = company?.affinityLink?.url || '';
    const websiteLink = affinityLink || offerLink || company.url;

    const handleVoteNowClick = () => {
      toggleBusinessVoteModal({
        company,
        category: IS_BUSINESS_PAGE ? null : category,
        IS_BUSINESS_PAGE,
        fetchDetailsAndInsights
      });
    };

    const handleClickWebsiteLink = () => {
      companiesStore.companyUpdateClicks({ companyId: +company.id, categoryId: category?.id });
    };

    const onVisitCompanyPage = () => {
      handleVisitCompany(company);
    };

    return (
      <div
        className={cx(
          'business-info d-flex justify-content-between',
          !isDesktop ? 'align-items-center' : 'align-items-stretch'
        )}
      >
        <div className='d-flex flex-column justify-content-between w-100'>
          <div className='business-info-main d-flex flex-grow-1'>
            <div className={cx(!isDesktop ? 'd-flex justify-content-center' : 'mr-16')}>
              <Avatar avatarUrl={avatarUrl} size={80} withBorder fullSize />
            </div>
            <div
              className={cx(
                'd-flex flex-column flex-grow-1 pt-10',
                !isDesktop ? 'align-items-center' : 'align-items-start'
              )}
            >
              <Link
                className={cx('fw-700 mb-4 lh-9 main-text', !isDesktop ? 'fs-18' : 'fs-22')}
                to={pathGen('BUSINESS', { countryCode, company })}
                onClick={onVisitCompanyPage}
              >
                {name}
              </Link>
              <a
                href={websiteLink}
                target='_blank'
                rel='noopener noreferrer'
                onClick={handleClickWebsiteLink}
                className={cx('btn empty black', isDesktop && 'small')}
              >
                Visit website
              </a>
              {isPartnered && (
                <div className='verified-business mt-16'>
                  <InsuranceIcon />
                  <span>Verified Company</span>
                </div>
              )}
            </div>
          </div>
          <BusinessReminder
            renewalDetail={currentPolicy}
            {...{
              category,
              company,
              IS_BUSINESS_PAGE
            }}
          />
        </div>
        <div className='secondary-info d-flex'>
          <div className='secondary-info-box rating-box d-flex flex-column justify-content-between text-center'>
            <div className='fs-16 fw-600 mb-5'>Overall ratings score</div>
            <div className='fs-22 fw-300 lh-5 mb-5'>{divRating}</div>
            <div className='d-flex justify-content-center mb-10'>
              <RatingMUI value={divRating || 0} />
            </div>
            <div className='secondary-text fs-12'>Ratings based on {additionalData?.insights || 0} insights</div>
          </div>
          <div className='secondary-info-box vote-box d-flex flex-column align-items-center justify-content-center'>
            <div>
              <div className='fs-16 fw-600 mb-10'>Are you a customer?</div>
              <div className='fs-12 mb-20'>Please vote for us, thank you!</div>
            </div>
            <div className='btn full white-bg' onClick={handleVoteNowClick}>
              <span>VOTE NOW</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
