import React from 'react';

import Typography from '@mui/material/Typography';
import { FieldContainer } from '@packs/screens/PersonalizeResults/FieldContainer';
import { RatingBlock } from '@packs/screens/PersonalizeResults/stepComponents/components/RatingBlock';
import { useStore } from '@packs/stores/base';

import { observer } from 'mobx-react';

const ratingTitle = 'Would you recommend this business to other members of our community?';

export const RateField = observer(props => {
  const { detailsFormStore } = useStore();
  const { formErrors } = detailsFormStore;
  const { remount } = props;

  return (
    <FieldContainer
      remount={remount}
      error={formErrors.anyCompanyRate || formErrors.anyCompanyRateComment}
      attr='anyCompanyRate'
      className='active p-0'
    >
      <Typography variant={props?.simpleForm ? 'h3' : 'h6'} className='title'>
        {ratingTitle}
      </Typography>
      <RatingBlock namePrefix='anyCompany' {...props} />
    </FieldContainer>
  );
});
