import { BankingIcon } from '@shared/images/categories/BankingIcon';
import { BroadbandIcon } from '@shared/images/categories/BroadbandIcon';
import { BusinessIcon } from '@shared/images/categories/BusinessIcon';
import { EnergyIcon } from '@shared/images/categories/EnergyIcon';
import { FoodIcon } from '@shared/images/categories/FoodIcon';
import { GeneralIcon } from '@shared/images/categories/GeneralIcon';
import { GovernmentIcon } from '@shared/images/categories/GovernmentIcon';
import { InsuranceIcon } from '@shared/images/categories/InsuranceIcon';
import { LocalServicesIcon } from '@shared/images/categories/LocalServicesIcon';
import { MobileIcon } from '@shared/images/categories/MobileIcon';
import { SubscriptionsIcon } from '@shared/images/categories/SubscriptionsIcon';
import { TravelIcon } from '@shared/images/categories/TravelIcon';
import { WasteCleaningIcon } from '@shared/images/categories/WasteCleaningIcon';

export const ICONS = {
  insurance: <InsuranceIcon />,
  broadband: <BroadbandIcon />,
  energy: <EnergyIcon />,
  subscriptions: <SubscriptionsIcon />,
  business: <BusinessIcon />,
  mobile: <MobileIcon />,
  government: <GovernmentIcon />,
  banking: <BankingIcon />,
  waste_collection: <WasteCleaningIcon />,
  general: <GeneralIcon />,
  local_services: <LocalServicesIcon />,
  travel: <TravelIcon />,
  food: <FoodIcon />
};
