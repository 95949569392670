export default () => ({
  wrapper: {
    display: 'flex',
    gap: 0.5,
    mt: 'auto',
    mb: {
      xs: 1,
      md: 0
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    pt: '2px'
  }
});
