import React from 'react';

export const InsuranceIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.188 1.36098L4.188 4.47198C3.465 4.79298 3 5.50898 3 6.29998V11C3 18.83 9.439 22.486 12 23C14.561 22.486 21 18.83 21 11V6.29998C21 5.50998 20.535 4.79298 19.812 4.47198L12.812 1.36098C12.295 1.13098 11.705 1.13098 11.188 1.36098ZM10.293 15.707L7.523 12.937C7.133 12.547 7.133 11.913 7.523 11.523C7.913 11.133 8.547 11.133 8.937 11.523L11 13.586L16.085 8.50098C16.475 8.11098 17.109 8.11098 17.499 8.50098C17.889 8.89098 17.889 9.52498 17.499 9.91498L11.707 15.707C11.317 16.097 10.683 16.097 10.293 15.707Z'
        fill='white'
      />
    </svg>
  );
};
