import BankingIcon from '@packs/images/ui/banners/banking.webp';
import BroadbandIcon from '@packs/images/ui/banners/broadband.webp';
import BusinessIcon from '@packs/images/ui/banners/business.webp';
import EnergyIcon from '@packs/images/ui/banners/energy.webp';
import FoodIcon from '@packs/images/ui/banners/food.webp';
import InsuranceIcon from '@packs/images/ui/banners/insurance.webp';
import LocalServicesIcon from '@packs/images/ui/banners/local_services.webp';
import MobileIcon from '@packs/images/ui/banners/mobile.webp';
import SubscriptionsIcon from '@packs/images/ui/banners/subscriptions.webp';
import TravelIcon from '@packs/images/ui/banners/travel.webp';

const images = {
  insurance: InsuranceIcon,
  broadband: BroadbandIcon,
  mobile: MobileIcon,
  energy: EnergyIcon,
  subscriptions: SubscriptionsIcon,
  business: BusinessIcon,
  banking: BankingIcon,
  travel: TravelIcon,
  food: FoodIcon,
  local_services: LocalServicesIcon
};

export const bannerImage = code => images[code];
