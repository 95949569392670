import { companiesFragment } from './fetchCompaniesList';

export const companyFeaturedBusinesses = `
  query companyFeaturedBusinesses(
    $categoryId: Int,
    $page: Int,
    $perPage: Int,
    $city: String,
  ) {
    companyFeaturedBusinesses(
      categoryId: $categoryId,
      city: $city,
      perPage: $perPage,
      page: $page,
    ) {
    ${companiesFragment}
    errors
    totalCount
  }
}
`.trim();
