import { action, computed, observable, runInAction } from 'mobx';

import { preferenceApi } from '@packs/apis';
import { setError } from '@packs/lib/utils';

export class PreferenceStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable
  object = {};

  @action
  update(attrs) {
    return preferenceApi.update(attrs).then(resp => {
      if (resp.errors.length) {
        setError(resp.errors);
        return Promise.reject(resp.errors);
      }
      runInAction(() => {
        this.object = resp.preference;
      });
      return resp.preference;
    });
  }
}
