import { action, observable, runInAction } from 'mobx';

import { notificationApi } from '@packs/apis';
import { setError } from '@packs/lib/utils';

const orderNotifications = list => {
  return list.sort((a, b) => {
    return new Date(b?.createdAt) - new Date(a?.createdAt);
  });
};

export class NotificationsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable
  list = [];
  @observable
  loading = false;
  @observable
  totalCount = 0;
  @observable
  unreadTotalCount = 0;
  @observable
  filters = { page: 0 };

  @action
  setLoading(state) {
    runInAction(() => {
      this.loading = state;
    });
  }

  @action
  load(filters) {
    return notificationApi.fetchList(filters).then(resp => {
      if (resp?.errors?.length) {
        setError(resp.errors);
        return Promise.reject(resp.errors);
      }

      runInAction(() => {
        this.list = resp.list;
        this.totalCount = resp.totalCount;
        this.unreadTotalCount = resp.unreadTotalCount;
      });

      return this.list;
    });
  }

  @action
  bulkUpdate(attrs = {}) {
    return notificationApi.notificationUpdate(attrs).then(resp => {
      if (resp.errors.length) {
        setError(resp.errors);
        return Promise.reject(resp.errors);
      }

      const notificationList = [...resp.list, ...this.list];

      runInAction(() => {
        this.list = notificationList;
      });
      return resp.notification;
    });
  }

  @action
  delete(id) {
    return notificationApi.notificationDelete({ id }).then(resp => {
      if (resp.errors.length) {
        setError(resp.errors);
        return Promise.reject(resp.errors);
      }
      const notificationList = this.list.filter(item => item.id !== id);
      runInAction(() => {
        this.list = notificationList;
        this.totalCount = this.totalCount - 1;
      });

      return resp;
    });
  }

  @action
  pushNotificationAndOrder(notification) {
    const notificationList = this.list;
    this.list = orderNotifications(notificationList.concat(notification));
    this.totalCount = this.totalCount + 1;
  }

  @action
  setFilters(filters) {
    this.filters = { ...this.filters, ...filters };
  }
}
