import React from 'react';

export const Local_servicesHomeRemovals = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path d='M5.75 3C5.57531 3.00009 5.4037 3.04594 5.25223 3.13298C5.10077 3.22002 4.97475 3.34521 4.88672 3.49609L3.13672 6.49609C3.04733 6.64899 3.00015 6.82289 3 7V19C3 20.0931 3.90694 21 5 21H19C20.0931 21 21 20.0931 21 19V7C20.9999 6.82289 20.9527 6.64899 20.8633 6.49609L19.1133 3.49609C19.0252 3.34521 18.8992 3.22002 18.7478 3.13298C18.5963 3.04594 18.4247 3.00009 18.25 3H5.75ZM6.32422 5H17.6758L18.8418 7H5.1582L6.32422 5ZM10 9H14C14.552 9 15 9.448 15 10C15 10.552 14.552 11 14 11H10C9.448 11 9 10.552 9 10C9 9.448 9.448 9 10 9Z' />
      </g>
    </svg>
  );
};
