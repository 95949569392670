import React, { useState } from 'react';

import DocumentIcon from '@packs/images/icons/Document';

import { Lightbox } from 'react-modal-image';

const AttachedDocument = ({ file, index, isNew, removeFile, handleDownload, hideName, additional }) => {
  const [openImage, setOpenImage] = useState(null);

  const openLightbox = file => {
    setOpenImage(file);
  };

  const closeLightbox = () => {
    setOpenImage(null);
  };

  const filename = isNew ? file.name : file.filename;
  const filetype = isNew ? file.type : file.contentType;
  const url = isNew ? URL.createObjectURL(file) : file.url;

  const isImage = filetype.match(/image/);
  const removeIcon = removeFile ? (
    <span
      className='delete-file'
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        removeFile(additional ? file?.id : index);
      }}
    >
      X
    </span>
  ) : null;

  return (
    <>
      {isImage ? (
        <div className='image-box'>
          {removeIcon}
          <img src={url} alt='image' onClick={() => openLightbox({ url, filename })} />
        </div>
      ) : (
        <>
          <a className='uploaded-file' href={url} target='_blank' rel='noreferrer' title={filename}>
            <div className='image-box with-border'>
              {removeIcon}
              <DocumentIcon />
            </div>
            {!hideName && (
              <span
                onClick={() =>
                  handleDownload &&
                  handleDownload({
                    url,
                    filename,
                    isNew,
                    file
                  })
                }
              >
                {' '}
                {filename}
              </span>
            )}
          </a>
        </>
      )}
      {openImage && (
        <Lightbox medium={openImage.url} large={openImage.url} alt={openImage.filename} onClose={closeLightbox} />
      )}
    </>
  );
};

export default AttachedDocument;
