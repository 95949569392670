import React, { useEffect, useState } from 'react';
import { size } from 'lodash-es';
import cx from 'classnames';

import { categoryApi } from '@packs/apis/category';
import { contributionTypes } from '@packs/lib/constants';
import { toastr } from '@packs/lib/helpers';
import { shareOnTheFacebook } from '@packs/lib/helpers/additionalHelpers';
import { useCategories } from '@packs/models/category/useCategories';
import { useStore } from '@packs/stores';
import { postTypes } from '@shared/lib/constants';

import Form from './components/Form';

import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { observer } from 'mobx-react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const uploadMediaLimit = 10;

const convert = value => {
  const convertedState = convertFromRaw(JSON.parse(value));

  return EditorState.createWithContent(convertedState);
};

const getStrippedString = value => {
  return (value.replace(/(<([^>]+)>|&nbsp;)/gi, '') || '').trim();
};

export const CreateQuestionModal = observer(
  ({
    toggle,
    isOpen,
    hideModal,
    modalProps: {
      category,
      myProfile,
      companyId,
      initialTab,
      rootUrl,
      initialValues,
      id = null,
      postTypeFilter,
      countryRegionName,
      withOutCategoryMode,
      postType = null,
      uploadedFiles = []
    }
  }) => {
    const { questionsStore, categoryStore, userStore } = useStore();
    const { flattenCategories } = useCategories();
    const [files, setFiles] = useState([]);
    const [deleteFilesIds, setDeleteFilesIds] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [editorValue, setEditorValue] = useState(
      initialValues?.description ? convert(initialValues?.description) : EditorState.createEmpty()
    );

    const editMode = !!id;
    const additionalFiles = uploadedFiles.filter(additional => !deleteFilesIds.includes(additional.id));
    const disabledUpload = files.length + additionalFiles.length >= uploadMediaLimit;
    let eventUrl = `${location?.pathname}${initialTab ? `?initialTab=${initialTab}` : ''}`;
    if (rootUrl && category) {
      eventUrl = `${location?.pathname}/categories/${category.root.code}/${category.slag}/leaderboard/q-a`;
    }
    const desc = stateToHTML(editorValue.getCurrentContent());
    const disabled = getStrippedString(desc) === '';

    const onFileAdd = ev => {
      if (!disabledUpload) {
        const newFiles = [...files]
          .concat(Array.from(ev.target.files))
          .sort((a, b) => (a.type.match(/image/) ? -1 : 1));
        if (newFiles.length > uploadMediaLimit) {
          toastr.error(`Maximum number of files ${uploadMediaLimit}`);
        } else {
          setFiles(newFiles);
        }
      }
    };

    const removeFile = index => {
      let newFiles = [...files];
      delete newFiles[index];
      newFiles = newFiles.filter(f => f);
      setFiles(newFiles);
    };

    const onDestroyFile = id => {
      return () => {
        setDeleteFilesIds([...deleteFilesIds, id]);
      };
    };

    const onError = errors => {
      setIsLoading(false);
      toastr.error(errors);
    };

    const subscribeCategory = ({ selectedCategoryId }) => {
      const cat = selectedCategoryId ? flattenCategories.find(item => +item.id === +selectedCategoryId) : category;

      categoryApi.categorySubscribe({ categoryId: cat.id, selected: true });
      categoryStore.markCategorySelect({ id: cat.id, selected: true });
    };

    const onSuccess = ({ selectedCategoryId }) => {
      setIsLoading(false);
      selectedCategoryId && subscribeCategory({ selectedCategoryId });
      toggle();
    };

    const submitHandler = data => {
      setIsLoading(true);
      const newData = { ...data };

      const mentionedCompanyIds = [];
      const options = {
        entityStyleFn: entity => {
          const entityType = entity.get('type').toLowerCase();
          if (entityType === 'mention') {
            const data = entity.getData();
            mentionedCompanyIds.push(data.mention.title);
          }
        }
      };
      const descriptionToHtml = stateToHTML(editorValue.getCurrentContent(), options);

      const hashTags = descriptionToHtml.match(/#[a-z]+/gi);
      const keywords = hashTags ? hashTags.map(item => item.replace('#', '')) : [];
      newData.description = JSON.stringify(convertToRaw(editorValue.getCurrentContent()));

      delete newData?.billWinner;

      if (!newData?.title) {
        const strippedString = getStrippedString(descriptionToHtml);

        newData.title = strippedString.length > 50 ? `${strippedString.substring(0, 50)}...` : strippedString;
      }
      if (deleteFilesIds.length) newData.deleteFilesIds = deleteFilesIds;
      if (mentionedCompanyIds.length) newData.mentionedCompanyIds = mentionedCompanyIds;
      if (keywords.length) newData.keywords = keywords;
      if (companyId) newData.companyId = +companyId;
      if (newData.postType) newData.postType = newData.postType.value || newData.postType;
      if (newData.targetSex) newData.targetSex = newData.targetSex.value || newData.targetSex;
      if (newData.countryRegionName) {
        newData.countryRegionName = newData.countryRegionName.value || newData.countryRegionName;
      }
      if (files.length) newData.files = files;
      if ((newData?.categoryId?.value || newData?.categoryId) === 'general') {
        newData.categoryId = null;
      } else {
        newData.categoryId = category?.id || newData?.categoryId?.value || newData?.categoryId;
      }
      if (eventUrl) {
        newData.url = eventUrl;
      }

      if (editMode) {
        newData.id = id;
        questionsStore
          .update(newData)
          .then(() => onSuccess({ selectedCategoryId: newData.categoryId }))
          .catch(onError);
        return false;
      }

      questionsStore
        .create(newData, postTypeFilter)
        .then(res => {
          onSuccess({ selectedCategoryId: newData.categoryId });
          if (newData?.facebook) {
            shareOnTheFacebook({ link: res?.post?.url });
          }
          if (myProfile) {
            const contributionType =
              newData.postType === 'tip' ? contributionTypes.qaTipListCount : contributionTypes.qaPostListCount;
            userStore.updateContributionCounter(contributionType, true);
          }
        })
        .catch(onError);
    };

    useEffect(() => {
      userStore.setRegisterEvent(eventUrl);
    }, []);

    return (
      <Modal isOpen={isOpen} toggle={() => toggle()} className={cx('create-question-modal', { hideModal })}>
        <ModalHeader toggle={() => toggle()} charCode='x' />
        <ModalBody>
          <div className='middle-panel'>
            <Form
              {...{
                files,
                toggle,
                category,
                editMode,
                disabled,
                isLoading,
                onFileAdd,
                removeFile,
                onDestroyFile,
                disabledUpload,
                additionalFiles,
                withOutCategoryMode,
                editorValue,
                countryRegionName,
                setEditorValue
              }}
              initialDescription={initialValues?.description}
              initialValues={
                initialValues && size(initialValues)
                  ? {
                      ...initialValues,
                      postType: initialValues.postType || postTypes[1]
                    }
                  : { postType: postTypes.find(item => item.value === postType) || postTypes[1] }
              }
              onSubmit={submitHandler}
            />
          </div>
        </ModalBody>
      </Modal>
    );
  }
);
