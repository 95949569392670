import React from 'react';

import { NotificationBody } from '@packs/components/Notifications/NotificationBody';
import CloseDark from '@packs/images/svg/close-dark.svg';

export const NotificationsList = ({ list, toggle, countryCode, deleteNotification }) => {
  const handleDeleteClick = item => () => {
    deleteNotification(item.id);
  };

  return (
    <>
      {list.map((item, index) => (
        <div key={index} className='notification-item'>
          <div className='item-avatar'>
            <img src={item.creatorAvatarUrl} alt='Logo' />
          </div>
          <NotificationBody item={item} countryCode={countryCode} toggle={toggle} />
          <div className='notice-close' onClick={handleDeleteClick(item)}>
            <img src={CloseDark} alt='Close' />
          </div>
        </div>
      ))}
    </>
  );
};
