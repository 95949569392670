export const getTotalRating = company => {
  const billwinnerScores = company.billwinnerScores || {};
  const googleScores = company.googleScores || {};
  const trustpilotScores = company.trustpilotScores || {};
  const ratings = [
    {
      rating: billwinnerScores.rating,
      reviews: billwinnerScores.reviews
    },
    {
      rating: googleScores.rating,
      reviews: googleScores.reviews
    },
    {
      rating: trustpilotScores.rating,
      reviews: trustpilotScores.reviews
    }
  ];

  const list = ratings.filter(item => !!item.reviews);
  const total = list.reduce((acc, item) => acc + item.rating, 0);
  const totalReviews = list.reduce((acc, item) => acc + item.reviews, 0);

  return {
    totalRating: +(total / list.length || 0).toFixed(1),
    totalReviews
  };
};

export const transformToHTML = (originalText, staticBoldPhrases = []) => {
  let transformedText = originalText;

  // Add <strong> tags to the specified phrases
  staticBoldPhrases.forEach(phrase => {
    const regex = new RegExp(`\\b${phrase}\\b`, 'gi');
    transformedText = transformedText.replace(regex, `<strong>${phrase}</strong>`);
  });

  // Add <strong> tags around numbers
  const numberRegex = /\b(\d+(\.\d+)?)\b/g;
  transformedText = transformedText.replace(numberRegex, '<strong>$1</strong>');
  transformedText = transformedText.replace(/\n/g, '<br>');

  return { __html: transformedText };
};
