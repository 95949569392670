import { action, observable } from 'mobx';

import categoryApi from '@packs/apis/category';

export class LandingStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable categoryCompanies = [];
  @observable isLoading = false;

  @action fetchCategoryCompanies(attrs, onSuccess, onError) {
    this.setCategoryCompanies([]);
    this.setLoading(true);

    categoryApi.fetchCategoryCompanies(attrs).then(res => {
      if (res?.errors?.length) {
        onError && onError(res.errors);
      } else if (res) {
        this.setCategoryCompanies(res);
        this.setLoading(false);
        onSuccess && onSuccess(res);
      }
    });
  }

  @action setLoading(loading) {
    this.isLoading = loading;
  }

  @action setCategoryCompanies(arr) {
    this.categoryCompanies = arr;
  }
}
