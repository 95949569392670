import React from 'react';

import { Box, Button, ButtonProps, FormHelperText, Grid, Radio, Stack, Typography } from '@mui/material';
import colors from '@packs/assets/theme/base/colors';
import { BusinessVoteModalForm } from '@packs/components/BusinessVoteModal/BusinessVoteModal';
import { CURRENT_CUSTOMER_OPTIONS } from '@packs/models/policy/constants';

import { Controller, useFormContext } from 'react-hook-form';

const CurrentCustomer = () => {
  const { control } = useFormContext<BusinessVoteModalForm>();

  return (
    <Stack spacing={3}>
      <Controller
        name='currentCustomer'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Box>
            <Typography variant='body2' sx={{ mb: 1 }}>
              Are you currently a customer of Aviava?
            </Typography>
            <Grid container spacing={2}>
              {CURRENT_CUSTOMER_OPTIONS.map(option => (
                <Grid key={option.value} item xs={12} md={6}>
                  <Button
                    variant='outlined'
                    color={'defaultGrey' as ButtonProps['color']}
                    fullWidth
                    startIcon={<Radio sx={{ p: 0 }} disableRipple checked={field.value === option.value} />}
                    onClick={() => field.onChange(option.value)}
                    sx={{
                      justifyContent: 'flex-start',
                      px: 2,
                      borderColor: field.value === option.value ? colors.primary.main : colors.borderColor.main
                    }}
                  >
                    {option.label}
                  </Button>
                </Grid>
              ))}
            </Grid>
            <FormHelperText>{error?.message}</FormHelperText>
          </Box>
        )}
      />
    </Stack>
  );
};

export default CurrentCustomer;
