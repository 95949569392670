import React from 'react';

export const BankingIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 3C11.8616 3 11.7233 3.02844 11.5938 3.08594L3.90039 6.50586C3.35339 6.74886 3 7.29163 3 7.89062C3 8.72762 3.67862 9.40625 4.51562 9.40625H5V16.4062C5 16.9583 5.448 17.4062 6 17.4062C6.552 17.4062 7 16.9583 7 16.4062V9.40625H9V16.4062C9 16.9583 9.448 17.4062 10 17.4062C10.552 17.4062 11 16.9583 11 16.4062V9.40625H13V16.4062C13 16.9583 13.448 17.4062 14 17.4062C14.552 17.4062 15 16.9583 15 16.4062V9.40625H17V16.4062C17 16.9583 17.448 17.4062 18 17.4062C18.552 17.4062 19 16.9583 19 16.4062V9.40625H19.4844C20.3214 9.40625 21 8.72762 21 7.89062C21 7.29163 20.6466 6.74886 20.0996 6.50586L12.4062 3.08594C12.2773 3.02844 12.1384 3 12 3ZM4 19.4062C3.448 19.4062 3 19.8542 3 20.4062C3 20.9583 3.448 21.4062 4 21.4062H20C20.552 21.4062 21 20.9583 21 20.4062C21 19.8542 20.552 19.4062 20 19.4062H4Z'
        fill='white'
      />
    </svg>
  );
};
