import React, { useContext } from 'react';
import cx from 'classnames';

import { NumberInput } from '@packs/components/NumberInput';
import { SYMBOLS_AFTER_DOT } from '@packs/components/PriceField/constants';
import { useDetailsForm } from '@packs/screens/PersonalizeResults/DetailsFormProvider';

import { DebugName } from './debug';

export const InputField = props => {
  const { getRef, onChange, onFocus } = useDetailsForm();
  const { name, type, question, symbolsAfterDot, withDot, maxLength, value, numeric = false } = props;
  const placeholder = props.placeholder || (question && question.placeholder) || '';
  const currencySymbol = props.currencySymbol || (question && question.currencySymbol) || false;
  const inputType = currencySymbol ? 'number' : type;
  const inputPlaceholder = currencySymbol ? 'Price...' : placeholder;

  if (!name) {
    throw new Error('no name');
  }

  return (
    <div className={cx('field field-input', { withIcon: currencySymbol })}>
      <DebugName name={name} />
      {question && question.type === 'textarea' ? (
        <label>
          <textarea ref={getRef} name={name} onFocus={onFocus} onChange={onChange} placeholder={placeholder} />
        </label>
      ) : (
        <label>
          {numeric ? (
            <NumberInput
              value={value}
              name={name}
              symbolsAfterDot={withDot ? (symbolsAfterDot ? symbolsAfterDot : SYMBOLS_AFTER_DOT) : 0}
              placeholder={placeholder}
              maxValue={maxLength}
              detailsNumeric
              onChange={onChange}
              onFocus={onFocus}
            />
          ) : (
            <input
              ref={getRef}
              name={name}
              type={inputType}
              placeholder={inputPlaceholder}
              onChange={onChange}
              onFocus={onFocus}
            />
          )}
          {currencySymbol && <span className='currency'>{currencySymbol}</span>}
        </label>
      )}
    </div>
  );
};
