import { notificationQueries } from '@packs/graphql/queries';
import { graphQLRequest } from '@packs/lib/utils';

export const notificationApi = {
  fetchList: variables =>
    graphQLRequest({
      query: notificationQueries.notificationList,
      variables
    }),
  notificationUpdate: variables =>
    graphQLRequest({
      query: notificationQueries.notificationUpdate,
      variables: { input: variables }
    }),
  notificationDelete: variables =>
    graphQLRequest({
      query: notificationQueries.notificationDelete,
      variables: { input: variables }
    })
};
