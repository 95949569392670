import React, { Fragment } from 'react';
import _ from 'lodash-es';
import cx from 'classnames';
import moment from 'moment';

import { useCalendarContext } from '@packs/components/Calendar/CalendarContext';
import { CustomEventPopover } from '@packs/components/Calendar/CustomEventPopover';

export const WeekRow = ({ weekDays, eventsData, inx, monthIndex, trigger }) => {
  const { dateClick, eventClick, onCalendarDeleteReminder, onCalendarEditReminder, onCalendarAddNewReminder } =
    useCalendarContext();

  const dateClassName = (day, categoryCode, events) =>
    cx('year-calendar-day fc-day-top fc-past', categoryCode, {
      'fc-past': day.isPast,
      'fc-feature': day.isFeature,
      'fc-today fc-state-highlight': day.isToday,
      'fc-other-month fc-prev-month': !day.isThatMonth,
      'has-events': events.length !== 0
    });

  // fc-day-top fc-fri fc-past fc-other-month fc-next-month

  const BackGround = () => (
    <div className='fc-bg'>
      <table>
        <tbody>
          <tr>
            {_.map(weekDays, (day, inx) => (
              <td
                key={inx}
                className={cx('fc-day fc-widget-content', {
                  'fc-past': !!day.date,
                  'fc-disabled-day': !day.date
                })}
              />
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );

  const SkeletonLayer = () => (
    <div className='fc-content-skeleton'>
      <table>
        <thead>
          <tr>
            {_.map(weekDays, (day, ix) => {
              const events = eventsData[day.dateStr] || [];
              const categoryCode = _.get(events, '0.category.root.code');
              const idDate = `Popover-${monthIndex}-${day.date ? moment(day.date).format('Y-D-M') : ''}`;
              const handleClick = () => {
                if (!day.date || !day.isThatMonth) return;
                if (events.length === 0) {
                  dateClick({
                    date: day.date,
                    view: null
                  });
                } else {
                  eventClick({
                    date: day.date,
                    view: null,
                    events
                  });
                }
              };

              return (
                <Fragment key={ix}>
                  <td
                    id={(events.length !== 0 && idDate) || undefined}
                    onClick={handleClick}
                    className={dateClassName(day, categoryCode, events)}
                  >
                    {day.date && <span className='fc-day-number'>{day.date.getDate()}</span>}
                  </td>

                  {day.date && events.length !== 0 && (
                    <CustomEventPopover
                      yearType
                      isMiddleDay={day.date.getDay() === 3}
                      onCalendarDeleteReminder={onCalendarDeleteReminder}
                      onCalendarEditReminder={onCalendarEditReminder}
                      onCalendarAddNewReminder={() => onCalendarAddNewReminder(day.date)}
                      target={idDate}
                      events={events}
                      trigger={trigger}
                    />
                  )}
                </Fragment>
              );
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            {_.times(7, ix => (
              <td key={ix} />
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
  return (
    <div
      key={inx}
      className={cx('fc-row fc-week fc-widget-content', {
        'fc-last': inx === 5
      })}
    >
      <BackGround />
      <SkeletonLayer />
    </div>
  );
};
