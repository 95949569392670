import { useState } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Tooltip from '@mui/material/Tooltip';
import { useWindowSize } from '@shared/hooks/useWindowSize';

export const TooltipMUI = ({ title, children, ...rest }) => {
  const { isDesktop } = useWindowSize();

  const [open, setOpen] = useState(false);

  const handleTouch = () => {
    setOpen(!open);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  if (!isDesktop) {
    return (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          {...rest}
          title={title}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          onClick={handleTouch}
        >
          {children}
        </Tooltip>
      </ClickAwayListener>
    );
  }

  return (
    <Tooltip title={title} {...rest}>
      {children}
    </Tooltip>
  );
};
