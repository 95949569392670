import React from 'react';

export default () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17'>
      {/* eslint-disable-next-line */}
      <path d='M15.69 10.664a.424.424 0 0 0-.423.424v3.769c0 .702-.569 1.272-1.27 1.273H2.117c-.701 0-1.27-.57-1.27-1.273V3.796c0-.703.569-1.273 1.27-1.273h3.76a.424.424 0 0 0 0-.85h-3.76A2.122 2.122 0 0 0 0 3.797v11.06a2.122 2.122 0 0 0 2.117 2.123h11.88a2.122 2.122 0 0 0 2.117-2.122v-3.769a.424.424 0 0 0-.424-.424z' />
      {/* eslint-disable-next-line */}
      <path d='M15.946.582a1.902 1.902 0 0 0-2.694 0L5.698 8.154a.426.426 0 0 0-.109.187l-.993 3.595a.425.425 0 0 0 .521.522l3.586-.996a.423.423 0 0 0 .187-.109l7.553-7.572a1.916 1.916 0 0 0 0-2.701l-.497-.498zM6.621 8.43l6.181-6.198 1.994 1.999-6.182 6.198-1.993-2zm-.399.801l1.593 1.597-2.203.612.61-2.209zm9.622-6.05l-.449.45-1.994-2 .45-.45a1.057 1.057 0 0 1 1.496 0l.497.499a1.065 1.065 0 0 1 0 1.5z' />
    </svg>
  );
};
