import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useModalsContext } from '@packs/layouts/AppLayer/context';

export const SignInModalLink = ({
  children,
  onSignUpSuccess,
  validate,
  onClick,
  variant = 'text',
  color = 'dark',
  component = null,
  sx,
  ...rest
}) => {
  const { toggleSignInModal } = useModalsContext();

  const componentProps = {
    ...rest,
    sx: {
      ...sx,
      cursor: 'pointer'
    },
    color,
    variant,
    onClick: ev => {
      ev.preventDefault();
      onClick && onClick();

      if (validate) {
        if (validate()) {
          toggleSignInModal({ onSignUpSuccess });
        }
      } else {
        toggleSignInModal({ onSignUpSuccess });
      }
    }
  };

  if (!component) {
    return <Button {...componentProps}>{children}</Button>;
  }

  return (
    <Box component={component} {...componentProps}>
      {children}
    </Box>
  );
};
