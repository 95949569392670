import { NewProviderForm } from './form';

import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';

const validate = ({ location, categoryId, name, url }) => {
  const errors = {};

  if (!location) errors.location = 'Location Required';
  if (!categoryId) errors.categoryId = 'Category Required';
  if (!name) errors.name = 'Name must be filled';

  return errors;
};

const mapStateToProps = (state, props) => ({ formAttributes: getFormValues(props.form)(state) });

const createReduxForm = reduxForm({ form: 'newProviderForm', validate });

export default createReduxForm(connect(mapStateToProps)(NewProviderForm));
