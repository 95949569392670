import { action } from 'mobx';

import { reviewCommentApi } from '@packs/apis';

export class ReviewCommentStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action reviewCommentList = async attrs => {
    const res = await reviewCommentApi.list(attrs);
    if (res?.errors?.length) {
      return Promise.reject(res.errors);
    }
    return res;
  };

  @action reviewCommentCreate = async attrs => {
    const res = await reviewCommentApi.create(attrs);
    if (res?.errors?.length) {
      return Promise.reject(res.errors);
    }
    return res;
  };

  @action reviewCommentUpdate = async attrs => {
    const res = await reviewCommentApi.update(attrs);
    if (res?.errors?.length) {
      return Promise.reject(res.errors);
    }
    return res;
  };

  @action reviewCommentDestroy = async attrs => {
    const res = await reviewCommentApi.destroy(attrs);
    if (res?.errors?.length) {
      return Promise.reject(res.errors);
    }
    return res;
  };

  @action reviewCommentToggleHelpful = async attrs => {
    const res = await reviewCommentApi.toggleHelpful(attrs);
    if (res?.errors?.length) {
      return Promise.reject(res.errors);
    }
    return res;
  };
}
