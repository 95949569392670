import React from 'react';
import cx from 'classnames';

import colors from '@packs/assets/theme/base/colors';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { useStore } from '@packs/stores';
import { SUB_ICONS } from '@shared/helpers/subCategoryIcon';

import Form from './components/form';

import { observer } from 'mobx-react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

export const PersonalizeUnAuthorizedModal = observer(({ toggle, isOpen }) => {
  const { togglePersonalizeConfirmModal } = useModalsContext();
  const { currentCountry, currentCategory } = useStore();

  const handleClose = () => {
    toggle();
    togglePersonalizeConfirmModal();
  };

  const title = ['us', 'ca'].includes(currentCountry.isoA2Code)
    ? 'Personalize your results!'
    : 'Personalise your results!';

  return (
    <Modal
      isOpen={isOpen}
      toggle={handleClose}
      className={cx('personalize-unauthorized-modal', currentCategory.root.enName)}
    >
      <ModalHeader toggle={handleClose} charCode='x' />
      <ModalBody>
        <div className='d-flex flex-column align-items-center justify-content-center'>
          <div className='category-container mb-20 d-flex align-items-center justify-content-center'>
            {SUB_ICONS({ category: currentCategory, fill: colors?.category[currentCategory?.root?.enName]?.main })}
          </div>
          <div className='mb-30 fs-14'>{currentCategory.name}</div>
        </div>
        <div className='title fs-25 lh-5 mb-40 fw-700 text-center'>{title}</div>
        <Form toggle={toggle} />
      </ModalBody>
    </Modal>
  );
});
