import React, { useCallback, useMemo, useRef, useState } from 'react';

import createLinkPlugin from '@draft-js-plugins/anchor';
import {
  BoldButton,
  CodeButton,
  ItalicButton,
  OrderedListButton,
  UnderlineButton,
  UnorderedListButton
} from '@draft-js-plugins/buttons';
import Editor from '@draft-js-plugins/editor';
import createEmojiPlugin from '@draft-js-plugins/emoji';
import createHashtagPlugin from '@draft-js-plugins/hashtag';
import createMentionPlugin from '@draft-js-plugins/mention';
import createToolbarPlugin from '@draft-js-plugins/static-toolbar';
import api from '@packs/apis/company';
import { pathGen } from '@packs/layouts/constants';
import { useStore } from '@packs/stores';

import { CustomMention } from './CustomMention';

import { EditorState } from 'draft-js';

export const EditorField = ({ value, onChange, placeholder = '' }) => {
  const ref = useRef(null);
  const { countryCode } = useStore();
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const { MentionSuggestions, Toolbar, linkPlugin, EmojiSuggestions, EmojiSelect, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin();
    const toolbarPlugin = createToolbarPlugin();
    const hashtagPlugin = createHashtagPlugin();
    const linkPlugin = createLinkPlugin({ placeholder: 'http://…' });
    const emojiPlugin = createEmojiPlugin({
      useNativeArt: true,
      toneSelectOpenDelay: 0,
      selectGroups: [
        {
          title: 'Emoji',
          categories: ['people']
        }
      ]
    });
    const { MentionSuggestions } = mentionPlugin;
    const { Toolbar } = toolbarPlugin;
    const { EmojiSuggestions, EmojiSelect } = emojiPlugin;

    const plugins = [emojiPlugin, mentionPlugin, toolbarPlugin, hashtagPlugin, linkPlugin];
    return {
      plugins,
      MentionSuggestions,
      hashtagPlugin,
      Toolbar,
      linkPlugin,
      EmojiSuggestions,
      EmojiSelect
    };
  }, []);

  const onOpenChange = useCallback(_open => {
    // setOpen(_open);
    setOpen(true);
  }, []);
  const onSearchChange = useCallback(({ value }) => {
    api
      .fetchCompaniesByCountry({
        countryCode,
        keyWord: value,
        autocompleate: 'true'
      })
      .then(res => {
        if (res?.errors.length === 0) {
          setSuggestions(
            res?.companies.map(({ id, name, urlId, avatarUrl }) => ({
              title: id,
              avatar: avatarUrl,
              name,
              link: pathGen('BUSINESS', { countryCode, company: { id, urlId } })
            }))
          );
        } else {
          setSuggestions([]);
        }
      });
  }, []);

  return (
    <div
      className='editor'
      onClick={() => {
        ref?.current?.focus();
      }}
    >
      <Editor
        editorKey='editor'
        editorState={value || EditorState.createEmpty()}
        plugins={plugins}
        ref={ref}
        placeholder={placeholder}
        onChange={onChange}
      />
      <MentionSuggestions
        open={open}
        suggestions={suggestions}
        entryComponent={CustomMention}
        onOpenChange={onOpenChange}
        onSearchChange={onSearchChange}
        onAddMention={() => setSuggestions([])}
      />
      <EmojiSuggestions />
      <Toolbar>
        {externalProps => (
          <div className='toolbar-list'>
            <BoldButton {...externalProps} />
            <ItalicButton {...externalProps} />
            <UnderlineButton {...externalProps} />
            <CodeButton {...externalProps} />
            <UnorderedListButton {...externalProps} />
            <OrderedListButton {...externalProps} />
            <linkPlugin.LinkButton {...externalProps} />
            <EmojiSelect closeOnEmojiSelect />
          </div>
        )}
      </Toolbar>
    </div>
  );
};
