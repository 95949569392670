import React, { FC } from 'react';

import Box from '@mui/material/Box';
import colors from '@packs/assets/theme/base/colors';

type ProgressBarProps = {
  progress?: number;
};
const ProgressBar: FC<ProgressBarProps> = ({ progress = 0 }) => {
  return (
    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, height: 8, backgroundColor: colors.grey[100] }}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          backgroundColor: colors.primary.main,
          width: `${progress < 90 ? progress : 90}%`,
          borderTopRightRadius: 4,
          borderBottomRightRadius: 4
        }}
      />
    </Box>
  );
};

export default ProgressBar;
