import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import useStyles from './styles';

export const ReviewItemSkeleton = () => {
  const styles = useStyles();

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.topSection}>
        <Box sx={styles.userInfoSection}>
          <Skeleton variant='rectangular' width={48} height={48} />
          <Box>
            <Skeleton variant='rectangular' width={77} height={24} sx={{ mb: 1 }} />
            <Skeleton variant='rectangular' width={66} height={18} />
          </Box>
        </Box>
      </Box>
      <Box sx={styles.ratingCategorySection}>
        <Skeleton variant='rectangular' width={200} height={24} />
        <Skeleton variant='rectangular' width={104} height={24} />
      </Box>
      <Box>
        <Skeleton variant='rectangular' width='100%' height={72} />
      </Box>
      <Box sx={styles.actionPanelWrapper}>
        <Box sx={styles.actionPanelSection}>
          <Skeleton variant='rectangular' width={143} height={40} />
        </Box>
        <Box sx={styles.actionPanelSection}>
          <Skeleton variant='rectangular' width={126} height={40} />
        </Box>
      </Box>
    </Box>
  );
};
