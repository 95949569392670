import colors from '@packs/assets/theme/base/colors';

const { borderColor } = colors;

export default () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  questionsWrapper: {
    width: '100%',
    maxWidth: '934px',
    mx: 'auto',
    mb: 4
  },
  typeFilterWrapper: {
    display: 'flex',
    justifyContent: {
      xs: 'center',
      lg: 'flex-end'
    },
    mb: 4
  },
  questionsFiltersWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    borderRadius: '16px',
    border: `1px solid ${borderColor.main}`,
    mb: 4,
    py: 4,
    px: 3
  }
});
