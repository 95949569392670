import { action, observable, runInAction } from 'mobx';
import moment from 'moment';

import { mixedPostsApi } from '../apis';

const concatPostTypes = res => {
  return [
    ...(res?.renewalDetailList.map(item => ({ type: 'insight', ...item })) || []),
    ...(res?.userCategoryQuoteRequestList.map(item => ({ type: 'request', ...item })) || []),
    ...(res?.qaPostList.map(item => ({ type: 'qa', ...item })) || []),
    ...(res?.voteList.map(item => ({ type: 'vote', ...item })) || [])
  ].sort((a, b) => moment(b.updatedAt) - moment(a.updatedAt));
};

export class MixedPostsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable posts = [];
  @observable postsTotalCount = 0;
  @observable answers = {};
  @observable loading = false;

  @action load = async (attrs, loadMore) => {
    this.loading = true;
    if (!loadMore) {
      this.posts = [];
    }

    const res = await mixedPostsApi.list(attrs);

    runInAction(() => {
      this.loading = false;
    });

    if (res?.errors?.length) {
      return Promise.reject(res.errors);
    }

    runInAction(() => {
      if (loadMore) {
        const list = concatPostTypes(res);

        this.posts = [...this.posts, ...list];
      } else {
        this.posts = concatPostTypes(res);
      }

      this.postsTotalCount = res?.totalCount;
    });

    return res;
  };
}
