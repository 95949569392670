import React from 'react';
import { toString } from 'lodash-es';

import GoogleIcon from '@mui/icons-material/Google';
import Typography from '@mui/material/Typography';
import { CustomSocialButton, SocialButton } from '@packs/components/UI/SocialButton';
import FaceBookIcon from '@packs/images/icons/Facebook/FaceBook';
import LinkedinIcon from '@packs/images/icons/LinkedIn';
import { Twitter } from '@packs/images/icons/Twitter';

export const SocialButtonGroup = ({ setSocialUser, change }) => {
  const handleSocialLogin = user => {
    const type = user.provider;
    const email = user.profile.email;
    const firstName = user?.profile?.firstName || '';
    const lastName = user?.profile?.lastName || '';
    const avatarUrl = user.profile.profilePicURL;

    const userData = {
      type,
      email,
      firstName,
      lastName,
      avatarUrl
    };

    // change('email', email);
    // change('firstName', firstName);
    // change('lastName', lastName);
    // change('provider', type);

    if (type === 'google') {
      // change('googleUid', user.profile.id);
      userData.googleUid = user.profile.id;
    } else if (type === 'facebook') {
      // change('facebookUid', user.profile.id);
      // change('socialName', 'facebook');
      // change('accessToken', user?.token?.accessToken);

      userData.facebookUid = user.profile.id;
      userData.socialName = 'facebook';
      userData.accessToken = user?.token?.accessToken;
    } else if (type === 'twitter') {
      // change('twitterUid', toString(user.profile.id));
      // change('socialName', 'twitter');
      // change('secret', user?.token?.secret);
      // change('accessToken', user?.token?.accessToken);

      userData.twitterUid = toString(user.profile.id);
      userData.socialName = 'twitter';
      userData.secret = user?.token?.secret;
      userData.accessToken = user?.token?.accessToken;
    } else if (type === 'linkedin') {
      // change('linkedinUid', user.profile.id);
      // change('socialName', 'linkedin');
      // change('accessToken', user?.token?.accessToken);

      userData.linkedinUid = user.profile.id;
      userData.socialName = 'linkedin';
      userData.accessToken = user?.token?.accessToken;
    }

    setSocialUser(userData);
  };

  const handleSocialLoginFailure = error => {
    console.error('handleSocialLoginFailure', error);
  };

  return (
    <>
      {!localStorage.getItem('hideGoogleSocial') && (
        <SocialButton
          provider='google'
          appId={import.meta.env.VITE_GOOGLE_APP_ID}
          className='social-link facebook'
          onLoginSuccess={handleSocialLogin}
          onLoginFailure={handleSocialLoginFailure}
        >
          <GoogleIcon />
          <Typography>Continue with Google</Typography>
        </SocialButton>
      )}

      <SocialButton
        provider='facebook'
        appId={import.meta.env.VITE_FACEBOOK_APP_ID}
        version={'v19.0'}
        className='social-link facebook'
        onLoginSuccess={handleSocialLogin}
        onLoginFailure={handleSocialLoginFailure}
      >
        <FaceBookIcon />
        <Typography>Continue with Facebook</Typography>
      </SocialButton>

      <CustomSocialButton
        provider='twitter'
        className='social-link twitter'
        onLoginSuccess={handleSocialLogin}
        onLoginFailure={handleSocialLoginFailure}
      >
        <Twitter />
        <Typography>Continue with X</Typography>
      </CustomSocialButton>

      <CustomSocialButton
        provider='linkedin'
        redirect={`${location.origin}/auth/linkedin/callback`}
        appId={import.meta.env.VITE_LINKEDIN_APP_ID}
        className='social-link linkedin'
        onLoginSuccess={handleSocialLogin}
        onLoginFailure={handleSocialLoginFailure}
      >
        <LinkedinIcon />
        <Typography>Continue with Linkedin</Typography>
      </CustomSocialButton>
    </>
  );
};
