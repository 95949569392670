import { ICategory, ICompany, IPolicy } from '@packs/interfaces/rootInterfaces';
import { useStore } from '@packs/stores';

const COUNTRY_PREFIX = '/:country';
const COMPANY_PREFIX = ':urlId';
const COUNTRY_CATEGORY_PREFIX = `${COUNTRY_PREFIX}/categories/:root_code/:slag`;
export const paths = {
  COUNTRY_PREFIX,
  ROOT: COUNTRY_PREFIX,
  PROFILE: `${COUNTRY_PREFIX}/profile`,
  PROFILE_MY_REVIEWS: `${COUNTRY_PREFIX}/profile/my-reviews`,
  PROFILE_MY_ALL_CONTRIBUTIONS: `${COUNTRY_PREFIX}/profile/all-contributions`,
  PROFILE_MY_REMINDERS: `${COUNTRY_PREFIX}/my-reminders`,
  PROFILE_TIPS: `${COUNTRY_PREFIX}/profile/tips`,
  PROFILE_QA: `${COUNTRY_PREFIX}/profile/q-a`,
  PROFILE_REQUESTS: `${COUNTRY_PREFIX}/profile/requests`,
  PROFILE_MY_ACTIVITY: `${COUNTRY_PREFIX}/profile/my-activity`,
  PROFILE_MY_CONTRIBUTIONS: `${COUNTRY_PREFIX}/profile/my-contributions`,
  USERS_SIGN_IN: `${COUNTRY_PREFIX}/users/sign_in`,
  USERS_FORGOT_PASSWORD: `${COUNTRY_PREFIX}/users/password/new`,
  USERS_FORGOT_PASSWORD_UPDATE: `${COUNTRY_PREFIX}/users/password/edit`,
  USERS_RESEND_CONFIRMATION: `${COUNTRY_PREFIX}/users/confirmation/new`,
  USERS_RESEND_CONFIRMATION_ERROR: `${COUNTRY_PREFIX}/users/confirmation`,
  USERS_RESEND_UNLOCK: `${COUNTRY_PREFIX}/users/unlock/new`,
  USERS_RESEND_UNLOCK_ERROR: `${COUNTRY_PREFIX}/users/unlock`,
  USERS_INVITATION_ACCEPT: `${COUNTRY_PREFIX}/users/invitation-accept`,
  BECOME_A_PARTNER: `${COUNTRY_PREFIX}/become-a-partner`,
  ABOUT_US: `${COUNTRY_PREFIX}/about-us`,
  ASSOCIATION: `${COUNTRY_PREFIX}/association`,
  VOTE: `${COUNTRY_PREFIX}/vote`,
  OUR_SHARED_MISSION: `${COUNTRY_PREFIX}/our-community`,
  MY_NOTES: `${COUNTRY_PREFIX}/my-notes`,
  UNSUBSCRIBE: `${COUNTRY_PREFIX}/users/unsubscribe`,
  INVITE_FRIENDS: `${COUNTRY_PREFIX}/invite-friends`,
  MY_OFFERS: `${COUNTRY_PREFIX}/my-offers`,
  MY_OFFERS_DETAILED: `${COUNTRY_PREFIX}/offer/:id`,
  CATEGORY_PAGE: `${COUNTRY_PREFIX}/:root_code/compare`,

  CATEGORY: COUNTRY_CATEGORY_PREFIX,
  ADD_DETAILS: `${COUNTRY_CATEGORY_PREFIX}/details`,
  LEADERBOARD: `${COUNTRY_CATEGORY_PREFIX}/leaderboard`,
  LEADERBOARD_MY_RESULTS: `${COUNTRY_CATEGORY_PREFIX}/leaderboard/my-results`,
  DASHBOARD_LANDING: `${COUNTRY_PREFIX}/:root_code/:slag/:location`,
  DASHBOARD_ROOT_LANDING: `${COUNTRY_PREFIX}/:root_code/personalization`,
  LEADERBOARD_MY_STATS: `${COUNTRY_CATEGORY_PREFIX}/leaderboard/my-stats`,
  LEADERBOARD_MY_PRODUCTS: `${COUNTRY_CATEGORY_PREFIX}/leaderboard/my-products`,
  LEADERBOARD_MY_OFFERS: `${COUNTRY_CATEGORY_PREFIX}/leaderboard/my-offers`,
  LEADERBOARD_MY_QUOTE_REQUESTS: `${COUNTRY_CATEGORY_PREFIX}/leaderboard/my-quote-requests`,
  LEADERBOARD_MY_NOTES: `${COUNTRY_CATEGORY_PREFIX}/leaderboard/my-notes`,
  LEADERBOARD_TIPS: `${COUNTRY_CATEGORY_PREFIX}/leaderboard/tips`,
  LEADERBOARD_QA: `${COUNTRY_CATEGORY_PREFIX}/leaderboard/q-a`,
  PRODUCT: `${COUNTRY_CATEGORY_PREFIX}/product/${COMPANY_PREFIX}`,
  REVIEW: `${COUNTRY_CATEGORY_PREFIX}/review`,
  DETAILS: `${COUNTRY_CATEGORY_PREFIX}/my-details`,
  REQUEST_QUOTES: `${COUNTRY_CATEGORY_PREFIX}/request-quotes`,
  MY_HOME: `${COUNTRY_PREFIX}/my-home`,
  MY_ASSOCIATIONS: `${COUNTRY_PREFIX}/my-associations`,
  BUSINESS: `${COUNTRY_PREFIX}/business/${COMPANY_PREFIX}`,
  CHARGE: `${COUNTRY_PREFIX}/business/:company/charge`,
  CHARGE_COMPLETE: `${COUNTRY_PREFIX}/business/:company/charge-complete`,
  CONFIRM_EMAIL: `${COUNTRY_PREFIX}/confirm-email`
};

export const pathGen = (
  name: string,
  options: { countryCode?: string; category?: ICategory; company?: { id?: ICompany['id']; urlId?: ICompany['urlId'] } }
) => {
  const { countryCode, category, company } = options;

  const path = paths[name] || '';

  return path
    .replace(':country', countryCode)
    .replace(':company', company?.id)
    .replace(':urlId', company?.urlId)
    .replace(':slag', category?.slag)
    .replace(':root_code', category?.root?.code);
};

export const replacePath = (
  path: string,
  options: {
    countryCode?: string;
    category?: ICategory;
    company?: ICompany;
    policyOrId?: IPolicy | string;
    id?: string;
    location?: string;
    callbackRedirect?: string | null;
  }
) => {
  const {
    countryCode = '',
    category = null,
    company = null,
    policyOrId = null,
    id = '',
    location = '',
    callbackRedirect = null
  } = options;

  let newPath = path.replace(':country', countryCode);

  if (id) {
    newPath = newPath.replace(':id', id);
  }

  if (company) {
    newPath = newPath.replace(':company', company.id.toString()).replace(':urlId', company.urlId);
  }
  if (category) {
    newPath = newPath.replace(':slag', category.slag).replace(':root_code', category.root.code);
  }
  if (location) {
    newPath = newPath.replace(':location', location);
  }

  if (policyOrId && !newPath.includes('?policyId')) {
    const policyId = typeof policyOrId === 'string' ? policyOrId : policyOrId.id;
    if (policyId) {
      newPath = newPath.concat(`?policyId=${policyId}`);
    }
  }

  if (callbackRedirect) {
    if (newPath.includes('?')) {
      newPath = newPath.concat(`&callbackRedirect=${callbackRedirect}`);
    } else {
      newPath = newPath.concat(`?callbackRedirect=${callbackRedirect}`);
    }
  }

  return newPath;
};

export const usePath = () => {
  const { countryCode, currentCategory, currentPolicy, currentCountry } = useStore();
  const ROOT_PATH = replacePath(paths.ROOT, { countryCode });
  const ROOT_PATH_FN = code => replacePath(paths.ROOT, { countryCode: code });

  const PROFILE_SLAG_PATH_FN = cat => replacePath(paths.PROFILE, { countryCode, category: cat });
  const PROFILE_MY_REVIEWS_PATH = replacePath(paths.PROFILE_MY_REVIEWS, { countryCode });
  const PROFILE_MY_ALL_CONTRIBUTIONS_PATH = replacePath(paths.PROFILE_MY_ALL_CONTRIBUTIONS, { countryCode });

  const PROFILE_MY_REMINDERS_PATH = replacePath(paths.PROFILE_MY_REMINDERS, { countryCode });
  const PROFILE_TIPS_PATH = replacePath(paths.PROFILE_TIPS, { countryCode });
  const PROFILE_QA_PATH = replacePath(paths.PROFILE_QA, { countryCode });
  const PROFILE_REQUESTS_PATH = replacePath(paths.PROFILE_REQUESTS, { countryCode });
  const PROFILE_MY_ACTIVITY_PATH = replacePath(paths.PROFILE_MY_ACTIVITY, { countryCode });
  const PROFILE_MY_CONTRIBUTIONS_PATH = replacePath(paths.PROFILE_MY_CONTRIBUTIONS, { countryCode });

  const USERS_SIGN_IN_PATH = replacePath(paths.USERS_SIGN_IN, { countryCode });
  const USERS_FORGOT_PASSWORD_PATH = replacePath(paths.USERS_FORGOT_PASSWORD, { countryCode });
  const USERS_FORGOT_PASSWORD_UPDATE_PATH = replacePath(paths.USERS_FORGOT_PASSWORD_UPDATE, { countryCode });
  const USERS_RESEND_CONFIRMATION_PATH = replacePath(paths.USERS_RESEND_CONFIRMATION, { countryCode });
  const USERS_RESEND_CONFIRMATION_ERROR_PATH = replacePath(paths.USERS_RESEND_CONFIRMATION_ERROR, { countryCode });
  const USERS_RESEND_UNLOCK_PATH = replacePath(paths.USERS_RESEND_UNLOCK, { countryCode });
  const USERS_RESEND_UNLOCK_ERROR_PATH = replacePath(paths.USERS_RESEND_UNLOCK_ERROR, { countryCode });
  const USERS_INVITATION_ACCEPT_PATH = replacePath(paths.USERS_INVITATION_ACCEPT, { countryCode });

  const BECOME_A_PARTNER_PATH = replacePath(paths.BECOME_A_PARTNER, { countryCode });
  const ABOUT_US_PATH = replacePath(paths.ABOUT_US, { countryCode });
  const ASSOCIATION_PATH = replacePath(paths.ASSOCIATION, { countryCode });
  const VOTE_PATH = replacePath(paths.VOTE, { countryCode });
  const OUR_SHARED_MISSION_PATH = replacePath(paths.OUR_SHARED_MISSION, { countryCode });
  const MY_NOTES_PATH = replacePath(paths.MY_NOTES, { countryCode });
  const UNSUBSCRIBE_PATH = replacePath(paths.UNSUBSCRIBE, { countryCode });
  const INVITE_FRIENDS_PATH = replacePath(paths.INVITE_FRIENDS, { countryCode });
  const MY_OFFERS_PATH = replacePath(paths.MY_OFFERS, { countryCode });
  const MY_OFFERS_DETAILED_PATH_FN = id => replacePath(paths.MY_OFFERS_DETAILED, { countryCode, id });
  const MY_HOME_PATH = replacePath(paths.MY_HOME, { countryCode });

  const CATEGORY_PATH = replacePath(paths.CATEGORY, { countryCode, category: currentCategory });
  const ADD_DETAILS_PATH = replacePath(paths.ADD_DETAILS, {
    countryCode,
    category: currentCategory,
    policyOrId: currentPolicy
  });
  const ADD_DETAILS_PATH_FN = (cat, callbackRedirect) =>
    replacePath(paths.ADD_DETAILS, { countryCode, category: cat, callbackRedirect });
  const MY_DETAILS_PATH_FN = (
    policyOrId: IPolicy | string | undefined = currentPolicy,
    cat = currentCategory,
    callbackRedirect
  ) => replacePath(paths.DETAILS, { countryCode, category: cat, policyOrId, callbackRedirect });

  const REQUEST_QUOTES_PATH = replacePath(paths.REQUEST_QUOTES, {
    countryCode,
    category: currentCategory,
    policyOrId: currentPolicy
  });

  const REQUEST_QUOTES_PATH_FN = (cat, currentPolicy) =>
    replacePath(paths.REQUEST_QUOTES, {
      countryCode,
      category: cat,
      policyOrId: currentPolicy
    });

  const LEADERBOARD_PATH = replacePath(paths.LEADERBOARD, { countryCode, category: currentCategory });
  const LEADERBOARD_PATH_FN = cat => replacePath(paths.LEADERBOARD, { countryCode, category: cat });

  const DASHBOARD_MY_RESULTS_PATH = replacePath(paths.LEADERBOARD_MY_RESULTS, {
    countryCode,
    category: currentCategory
  });
  const DASHBOARD_MY_RESULTS_PATH_FN = cat => replacePath(paths.LEADERBOARD_MY_RESULTS, { countryCode, category: cat });
  const DASHBOARD_MY_STATS_PATH = replacePath(paths.LEADERBOARD_MY_STATS, { countryCode, category: currentCategory });
  const DASHBOARD_MY_PRODUCTS_PATH = replacePath(paths.LEADERBOARD_MY_PRODUCTS, {
    countryCode,
    category: currentCategory
  });
  const DASHBOARD_MY_OFFERS_PATH = replacePath(paths.LEADERBOARD_MY_OFFERS, { countryCode, category: currentCategory });
  const DASHBOARD_QA_GENERAL = replacePath(paths.LEADERBOARD_QA, { countryCode, category: currentCategory });
  const DASHBOARD_QA_GENERAL_PATH_FN = cat => replacePath(paths.LEADERBOARD_QA, { countryCode, category: cat });
  const DASHBOARD_MY_OFFERS_PATH_FN = cat => replacePath(paths.LEADERBOARD_MY_OFFERS, { countryCode, category: cat });
  const MY_NOTES_DASHBOARD_PATH = replacePath(paths.LEADERBOARD_MY_NOTES, { countryCode, category: currentCategory });
  const DASHBOARD_MY_QUOTE_REQUESTS_PATH = replacePath(paths.LEADERBOARD_MY_QUOTE_REQUESTS, {
    countryCode,
    category: currentCategory
  });

  const DASHBOARD_LANDING_PATH = replacePath(paths.DASHBOARD_LANDING, { countryCode, category: currentCategory });
  const DASHBOARD_LANDING_PATH_FN = (cat, location = currentCountry.name) =>
    replacePath(paths.DASHBOARD_LANDING, { countryCode, category: cat, location });

  const DASHBOARD_ROOT_LANDING_PATH = replacePath(paths.DASHBOARD_ROOT_LANDING, {
    countryCode,
    category: currentCategory
  });
  const DASHBOARD_ROOT_LANDING_PATH_FN = cat =>
    replacePath(paths.DASHBOARD_ROOT_LANDING, { countryCode, category: cat });
  const BUSINESS_PATH_FN = company => replacePath(paths.BUSINESS, { countryCode, company });

  return {
    ROOT_PATH,
    ROOT_PATH_FN,

    PROFILE_SLAG_PATH_FN,
    PROFILE_MY_REVIEWS_PATH,
    PROFILE_MY_ALL_CONTRIBUTIONS_PATH,
    PROFILE_MY_REMINDERS_PATH,
    PROFILE_TIPS_PATH,
    PROFILE_QA_PATH,
    PROFILE_REQUESTS_PATH,
    PROFILE_MY_ACTIVITY_PATH,
    PROFILE_MY_CONTRIBUTIONS_PATH,

    USERS_SIGN_IN_PATH,
    USERS_FORGOT_PASSWORD_PATH,
    USERS_FORGOT_PASSWORD_UPDATE_PATH,
    USERS_RESEND_CONFIRMATION_PATH,
    USERS_RESEND_CONFIRMATION_ERROR_PATH,
    USERS_RESEND_UNLOCK_PATH,
    USERS_RESEND_UNLOCK_ERROR_PATH,
    USERS_INVITATION_ACCEPT_PATH,

    BECOME_A_PARTNER_PATH,
    ABOUT_US_PATH,
    ASSOCIATION_PATH,
    VOTE_PATH,
    OUR_SHARED_MISSION_PATH,
    MY_NOTES_PATH,
    UNSUBSCRIBE_PATH,
    INVITE_FRIENDS_PATH,
    MY_OFFERS_PATH,
    MY_OFFERS_DETAILED_PATH_FN,
    MY_HOME_PATH,
    BUSINESS_PATH_FN,

    CATEGORY_PATH,
    ADD_DETAILS_PATH,
    ADD_DETAILS_PATH_FN,

    REQUEST_QUOTES_PATH,
    REQUEST_QUOTES_PATH_FN,

    MY_DETAILS_PATH_FN,

    LEADERBOARD_PATH,
    LEADERBOARD_PATH_FN,

    DASHBOARD_MY_RESULTS_PATH,
    DASHBOARD_MY_RESULTS_PATH_FN,
    DASHBOARD_MY_STATS_PATH,
    DASHBOARD_MY_PRODUCTS_PATH,
    DASHBOARD_MY_OFFERS_PATH,
    DASHBOARD_MY_OFFERS_PATH_FN,
    DASHBOARD_QA_GENERAL_PATH_FN,
    DASHBOARD_MY_QUOTE_REQUESTS_PATH,
    MY_NOTES_DASHBOARD_PATH,
    DASHBOARD_QA_GENERAL,

    DASHBOARD_LANDING_PATH,
    DASHBOARD_LANDING_PATH_FN,
    DASHBOARD_ROOT_LANDING_PATH,
    DASHBOARD_ROOT_LANDING_PATH_FN
  };
};
