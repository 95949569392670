import React from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { pathGen } from '@packs/layouts/constants';
import { handleVisitCompany } from '@packs/lib/helpers/additionalHelpers';
import { TooltipMUI } from '@shared/components/TooltipMUI/TooltipMUI';

import useStyles from './styles';

import { Link } from 'react-router-dom';

export const PartnerProvider = ({ company, currentCategory, countryCode }) => {
  const styles = useStyles();
  const providerCompany = company?.wikiTable?.providerCompany;

  const onVisitCompanyPage = () => {
    handleVisitCompany(providerCompany, currentCategory);
  };

  if (!providerCompany?.id) {
    return null;
  }

  return (
    <Box sx={styles.wrapper}>
      <Typography variant='body5' sx={styles.with}>
        with
      </Typography>
      <Box
        component={Link}
        to={pathGen('PRODUCT', {
          countryCode,
          category: currentCategory,
          company: providerCompany
        })}
        sx={styles.link}
        onClick={onVisitCompanyPage}
      >
        <Avatar src={providerCompany.avatarUrl} variant='rounded' sx={{ width: '32px', height: '32px' }} />
        <Typography variant='body6'>{providerCompany?.name}</Typography>
      </Box>
      <TooltipMUI
        title='This is the lowest cost provider on this website as per the last checked date. While ever effort is made to ensure accuracy, our members are encouraged to check and report all price changes! Thank you.'
        arrow
        placement='top'
      >
        <InfoOutlinedIcon fontSize='small' />
      </TooltipMUI>
    </Box>
  );
};
