import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { useDetailsForm } from '@packs/screens/PersonalizeResults/DetailsFormProvider';
import Phone from '@shared/components/PhoneInput/PhoneInput';

import { DebugName } from './debug';

import PhoneInput from 'react-phone-number-input';
import styled from 'styled-components';

export const PhoneInputField = props => {
  const { currentUser } = useUserSelectors();
  const { getRef, onChange, onFocus } = useDetailsForm();
  const { name, question, value = '', className, withBorder, wrapperClassName } = props;
  const placeholder = props.placeholder || (question && question.placeholder) || '';

  const [controlledPlaceholder, setPlaceholder] = useState(placeholder);

  const defaultCountry = currentUser?.country?.isoA2Code || 'ie';

  useEffect(() => {
    setPlaceholder(placeholder);
  }, [placeholder]);

  const handleKeyPress = value => {
    onChange({ target: { name, value } });
  };

  if (!name) {
    throw new Error('no name');
  }

  const inputProps = {
    value,
    name,
    className,
    ref: getRef,
    onFocus,
    onChange: handleKeyPress,
    placeholder: controlledPlaceholder,
    international: true,
    defaultCountry: defaultCountry.toUpperCase(),
    countryCallingCodeEditable: false
  };

  return (
    <PhoneInputContainer className={wrapperClassName}>
      <DebugName name={name} />
      <PhoneInput
        {...inputProps}
        inputComponent={Phone}
        className={cx('PhoneInputWrapper', className, { 'with-border': withBorder })}
      />
    </PhoneInputContainer>
  );
};

const PhoneInputContainer = styled.div`
  .PhoneInputWrapper {
    position: relative;
    display: flex !important;
    align-items: center;
    gap: 4px;

    .PhoneInputCountry {
      position: relative;
      align-self: stretch;
      display: flex;
      align-items: center;
    }

    .PhoneInputCountrySelect {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      min-width: 22px;
      z-index: 1;
      border: 0;
      opacity: 0;
      cursor: pointer;
    }

    .PhoneInputCountryIcon {
      width: 22px;
    }

    .PhoneInputInput {
      border: none !important;
      outline: none !important;
    }

    .MuiOutlinedInput-input {
      height: 24px;
    }
  }
`;
