export const CURRENT_CUSTOMER_OPTIONS = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No, I’m previous customer' }
];

export const LAST_BUY_OPTIONS = [
  { value: 'today', label: 'Today' },
  { value: 'this_week', label: 'Past 7 days' },
  { value: 'this_month', label: 'Past 30 days' },
  { value: 'this_quarter', label: 'Past 90 days' },
  { value: 'this_year', label: 'Past year' },
  { value: 'year_or_more', label: 'One year or more' }
];

export const SHOPPED_AROUND_OPTIONS = [
  { value: undefined, label: 'All Shoppers' },
  { value: 'two_places', label: 'Who shopped around' },
  { value: 'one_place', label: 'Who did not shop around' }
];

export enum DYNAMIC_OPTIONS_TYPES {
  mobileModel = 'mobileModel',
  model = 'carModel',
  trim = 'carTrim',
  carModel = 'carModel',
  carTrim = 'carTrim',
  bikeModel = 'bikeModel',
  bikeTrim = 'bikeTrim',
  petBreed = 'petBreed',
  bikeEngineSize = 'bikeEngineSize'
}
