import cx from 'classnames';

export const RENEWAL_DETAIL_FORM_INITIAL_VALUES = {
  lastBuy: undefined, // LAST_BUY_OPTIONS[0].value
  buyingType: undefined,
  claimWithBussiness: false
};

export const navActiveClass = (classes = '', obClasses = {}) => {
  return d => cx(classes ? classes : 'nav-link', { active: d.isActive, ...obClasses });
};
