import React from 'react';
import cx from 'classnames';

export const CircleLoader = ({ theme }) => {
  return (
    <div className={cx('circle-loader', { theme })}>
      <div className='circle' />
      <div className='circle' />
      <div className='circle' />
      <div className='circle' />
      <div className='circle' />
    </div>
  );
};
