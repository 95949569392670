import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { NoteItem } from '@packs/components/UI/MyNotes/components/NoteItem/NoteItem';
import { NotesListSkeleton } from '@packs/components/UI/MyNotes/components/NotesListSkeleton/NotesListSkeleton';
import { EmptyPlaceholder } from '@shared/components/EmptyPlaceholder/EmptyPlaceholder';

import useStyles from './styles';

export const NotesList = ({ toggle, notes, general, loading, openLightbox, handleDownload }) => {
  const styles = useStyles();
  const [isShort, setIsShort] = useState(true);

  const showMoreHandler = () => {
    setIsShort(false);
  };

  const showLessHandler = () => {
    setIsShort(true);
  };

  const list = isShort ? notes.slice(0, 3) : notes;

  if (loading) {
    return (
      <Box sx={styles.wrapper}>
        <NotesListSkeleton />
      </Box>
    );
  }

  if (list.length === 0 && !loading) {
    return (
      <Box sx={styles.wrapper}>
        <EmptyPlaceholder
          title='You have not added any documents or notes yet!'
          description='Let’s get started (Notes & documents are private to you!)'
        >
          <Button variant='contained' size='smallToMedium' onClick={toggle}>
            Add My Docs /Notes
          </Button>
        </EmptyPlaceholder>
      </Box>
    );
  }

  return (
    <Box sx={styles.wrapper}>
      {list.map(note => (
        <NoteItem key={note.id} {...{ note, general, openLightbox, handleDownload }} />
      ))}
      {notes.length > 3 && (
        <Box sx={styles.btnContainer}>
          {isShort ? (
            <Button key='more' variant='outlined' color='dark' onClick={showMoreHandler}>
              Show more
            </Button>
          ) : (
            <Button key='less' variant='outlined' color='dark' onClick={showLessHandler}>
              Show less
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};
