import React from 'react';

import { CopyOrShare } from '@packs/components/CopyOrShare';
import Email from '@packs/images/icons/Email';
import Facebook from '@packs/images/icons/Facebook';
import LinkedIn from '@packs/images/icons/LinkedIn';
import { Twitter } from '@packs/images/icons/Twitter';
import WhatsApp from '@packs/images/icons/WhatsApp';
import { BASE_APP_URL } from '@packs/lib/constants';

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share';

export const SocialBlock = props => (
  <div className='leaderboard-social-list-share'>
    <EmailShareButton className='social-link email' url={props.url} body={`${props.url}`}>
      <span className='icon'>
        <Email />
      </span>
      <span className='text'>Email</span>
    </EmailShareButton>
    <FacebookShareButton url={props.url} className='social-link facebook'>
      <span className='icon'>
        <Facebook />
      </span>
      <span className='text'>Facebook</span>
    </FacebookShareButton>
    <TwitterShareButton url={props.url} className='social-link twitter'>
      <span className='icon'>
        <Twitter />
      </span>
      <span className='text'>Twitter</span>
    </TwitterShareButton>
    <LinkedinShareButton url={props.url} className='social-link linkedin'>
      <span className='icon'>
        <LinkedIn />
      </span>
      <span className='text'>Linkedin</span>
    </LinkedinShareButton>
    <WhatsappShareButton url={BASE_APP_URL} className='social-link whatsApp'>
      <span className='icon'>
        <WhatsApp />
      </span>
      <span className='text'>WhatsApp</span>
    </WhatsappShareButton>
    <div className='SocialMediaShareButton'>
      <CopyOrShare title='Copy link' onlyIcon modalTooltip />
    </div>
  </div>
);
