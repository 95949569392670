import React from 'react';

export const BankingProtection = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_3:451)'>
        <path d='M10 6.13018C7.51861 6.13018 5.5 8.06809 5.5 10.4503C5.5 12.8325 7.51861 14.7704 10 14.7704C12.4814 14.7704 14.5 12.8325 14.5 10.4503C14.5 8.06809 12.4814 6.13018 10 6.13018ZM10.4737 12.724V13.4062C10.4737 13.6572 10.2615 13.8609 10 13.8609C9.73853 13.8609 9.52632 13.6572 9.52632 13.4062V12.724H8.81579C8.55432 12.724 8.34211 12.5203 8.34211 12.2693C8.34211 12.0183 8.55432 11.8145 8.81579 11.8145H10.4737C10.7349 11.8145 10.9474 11.6106 10.9474 11.3598C10.9474 11.109 10.7349 10.905 10.4737 10.905H9.52632C8.74261 10.905 8.10526 10.2932 8.10526 9.54079C8.10526 8.78841 8.74261 8.17655 9.52632 8.17655V7.49442C9.52632 7.2434 9.73853 7.03967 10 7.03967C10.2615 7.03967 10.4737 7.2434 10.4737 7.49442V8.17655H11.1842C11.4457 8.17655 11.6579 8.38027 11.6579 8.63129C11.6579 8.88231 11.4457 9.08604 11.1842 9.08604H9.52632C9.26508 9.08604 9.05263 9.29 9.05263 9.54079C9.05263 9.79158 9.26508 9.99554 9.52632 9.99554H10.4737C11.2574 9.99554 11.8947 10.6074 11.8947 11.3598C11.8947 12.1122 11.2574 12.724 10.4737 12.724ZM18.8444 4.20227C18.7459 4.11632 18.6151 4.07449 18.4804 4.08608C18.2978 4.10246 13.9413 4.44738 10.3699 0.161834C10.1904 -0.0539445 9.80982 -0.0539445 9.63029 0.161834C6.05895 4.44693 1.70224 4.10268 1.52082 4.08608C1.38747 4.07221 1.25508 4.11519 1.15584 4.20159C1.05661 4.28754 1 4.41009 1 4.53856V9.83137C1 13.4721 3.10695 16.7449 6.49876 18.3729L9.78826 19.9518C9.85482 19.9841 9.92729 20 10 20C10.0727 20 10.1452 19.9841 10.212 19.952L13.5015 18.3731C16.8931 16.7451 19 13.4721 19 9.83137V4.53856C19 4.41055 18.9432 4.28822 18.8444 4.20227ZM10 15.6799C6.99613 15.6799 4.55263 13.3341 4.55263 10.4503C4.55263 7.5665 6.99613 5.22068 10 5.22068C13.0039 5.22068 15.4474 7.5665 15.4474 10.4503C15.4474 13.3341 13.0039 15.6799 10 15.6799Z' />
      </g>
      <defs>
        <clipPath>
          <rect width='20' height='20' />
        </clipPath>
      </defs>
    </svg>
  );
};
