import React, { FC, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/CloseRounded';
import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  PaperProps,
  Stack,
  Typography
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { renewalDetailApi } from '@packs/apis';
import { categoryApi } from '@packs/apis/category';
import colors from '@packs/assets/theme/base/colors';
import { SignUpModalLink } from '@packs/components';
import CategorySelect from '@packs/components/BusinessVoteModal/components/CategorySelect';
import CurrentCustomer from '@packs/components/BusinessVoteModal/components/CurrentCustomer';
import LastBuy from '@packs/components/BusinessVoteModal/components/LastBuy';
import ProgressBar from '@packs/components/BusinessVoteModal/components/ProgressBar';
import ServiceRate from '@packs/components/BusinessVoteModal/components/ServiceRate';
import TotalRate from '@packs/components/BusinessVoteModal/components/TotalRate';
import VoteCriteria from '@packs/components/BusinessVoteModal/components/VoteCriteria';
import { Avatar } from '@packs/components/UI';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { ICategory, ICompany, ICriteriaOption, IOption } from '@packs/interfaces/rootInterfaces';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { toastr } from '@packs/lib/helpers';
import { useCategories } from '@packs/models/category/useCategories';
import { useStore } from '@packs/stores';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { observer } from 'mobx-react';
import queryString from 'query-string';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

type BusinessVoteModalProps = {
  isOpen: boolean;
  hideModal: () => void;
  toggle: () => void;
  modalProps: {
    IS_BUSINESS_PAGE: boolean;
    company: ICompany;
    category: ICategory;
  };
};

export type BusinessVoteModalForm = {
  rootCategoryId: number | null;
  categoryId: number | null;
  currentCustomer: string;
  lastBuy: string;
  customerServiceRate: number | null;
  valueForMoneyRate: number | null;
  staffKnowledgeRate: number | null;
  claimRate: number | null;
  criteriaQuestions: Record<string, unknown>;
  anyCompanyRate: number | null;
  anyCompanyRateComment: string;
};

export const BusinessVoteModal: FC<BusinessVoteModalProps> = observer(
  ({ toggle, isOpen, hideModal, modalProps: { IS_BUSINESS_PAGE, company, category } }) => {
    const { userStore, insightsCompanyStore } = useStore();

    const { toggleThankVoteModal } = useModalsContext();

    const { currentUser } = useUserSelectors();
    const { isMobile } = useWindowSize();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      let url = `vote-${company?.urlId}`;
      if (!IS_BUSINESS_PAGE) {
        url = `vote-${category?.root?.code}-${category?.code}-${company?.urlId}`;
      }
      userStore.setRegisterEvent(url);
    }, [IS_BUSINESS_PAGE, category?.code, category?.root?.code, company?.urlId, userStore]);

    const firstStepIndex = category?.id ? 1 : 0;
    const [currentStep, setCurrentStep] = useState<number>(() => firstStepIndex);
    const lastStepIndex = 5;

    const stepValidationSchema = useMemo(() => {
      switch (currentStep) {
        case 0:
          return Yup.object().shape({
            rootCategoryId: Yup.number().nullable().required().label('Category'),
            categoryId: Yup.number().nullable().required().label('Subcategory')
          });
        case 1:
          return Yup.object().shape({
            currentCustomer: Yup.string().required().label('Current customer')
          });
        case 2:
          return Yup.object().shape({
            lastBuy: Yup.string().required().label('Last buy')
          });
        case 3:
          return Yup.object().shape({
            customerServiceRate: Yup.number().nullable().required().label('Customer service'),
            valueForMoneyRate: Yup.number().nullable().required().label('Value for money'),
            staffKnowledgeRate: Yup.number().nullable().required().label('Staff Knowledge'),
            claimRate: Yup.number().nullable().label('Claims experience')
          });
        case 4:
          return Yup.object().shape({
            criteriaQuestions: Yup.object()
          });
        case 5:
          return Yup.object().shape({
            anyCompanyRate: Yup.number().nullable().required().label('Company rate'),
            anyCompanyRateComment: Yup.string().min(25).max(200).required().label('Comment')
          });

        default:
          return Yup.object().shape({});
      }
    }, [currentStep]);

    const methods = useForm<BusinessVoteModalForm>({
      mode: 'onChange',
      resolver: yupResolver(stepValidationSchema),
      defaultValues: {
        rootCategoryId: category?.root?.id || null,
        categoryId: category?.id || null,
        currentCustomer: 'yes',
        lastBuy: 'this_month',
        customerServiceRate: null,
        valueForMoneyRate: null,
        staffKnowledgeRate: null,
        claimRate: null,
        criteriaQuestions: {},
        anyCompanyRate: null,
        anyCompanyRateComment: ''
      }
    });

    // Flat extract methods from form control
    const {
      watch,
      handleSubmit,
      formState: { isValid }
    } = methods;

    const rootCategoryId = watch('rootCategoryId');
    const categoryId = watch('categoryId');
    const currentCustomer = watch('currentCustomer');

    const { categories } = useCategories();

    // Sub categories store
    const [subCategories, setSubCategories] = useState<IOption[]>([]);

    // Fetch subcategories by category
    useEffect(() => {
      if (!rootCategoryId) {
        return;
      }

      setSubCategories([]);

      categoryApi
        .fetchLeftCategories({ rootCategoryId, availableCompanyId: categoryId })
        .then((resp: { id: number; name: string }[]) => {
          setSubCategories(resp.map(el => ({ label: el.name, value: el.id })));
        })
        .catch(() => {
          toastr.error('Cant obtain categories!');
        });
    }, [categoryId, rootCategoryId]);

    // Category name for title
    const categoryName = useMemo(() => {
      if (categoryId && subCategories.length) {
        return subCategories.find(el => el.value === categoryId)?.label || '';
      } else if (category) {
        return category.name;
      }
      return '';
    }, [category, categoryId, subCategories]);

    // Vote criteria questions
    const [voteCriteriaQuestions, setVoteCriteriaQuestions] = useState<ICriteriaOption[]>([]);

    // Fetch vote criteria questions
    useEffect(() => {
      if (!categoryId) {
        return;
      }

      renewalDetailApi
        .fetchCriteriaQuestionList({
          categoryId,
          formType: 'personalize'
        })
        .then((response: Record<string, any>) => {
          const isVoteFormQuestions = response.filter(item => item.voteForm);
          setVoteCriteriaQuestions(isVoteFormQuestions);
        })
        .catch(() => {
          // console.log(error);
        });
    }, [categoryId]);

    function handleNextStep() {
      // Over jump step 3 (index 2) when it was stale experience
      if (currentStep === 1 && currentCustomer === 'no') {
        setCurrentStep(3);
        return;
      }
      // Over jump step 5 (index 4) when criteria question is empty of it was stale experience
      if (currentStep === 3 && (currentCustomer === 'no' || voteCriteriaQuestions.length === 0)) {
        setCurrentStep(5);
        return;
      }

      setCurrentStep(prev => prev + 1);
    }

    function handlePrevStep() {
      // Over jump step 3 (index 2) when it was stale experience
      if (currentStep === 3 && currentCustomer === 'no') {
        setCurrentStep(1);

        return;
      }
      // Over jump step 5 (index 4) when criteria question is empty of it was stale experience
      if (currentStep === 5 && (currentCustomer === 'no' || voteCriteriaQuestions.length === 0)) {
        setCurrentStep(3);

        return;
      }

      if (currentStep > 0) {
        setCurrentStep(prev => prev - 1);
      }
    }

    const onSubmit = (attributes: BusinessVoteModalForm) => {
      if (currentStep !== lastStepIndex) {
        handleNextStep();
        return;
      }

      setIsLoading(true);

      const token = queryString.parse(location.search)?.token || '';

      const renewalDetailAttributes = {
        anyCompanyId: company.id,
        buyingType: company.companyType, // TODO REMOVE Optional
        token,
        feedbackType: attributes.currentCustomer === 'yes' ? 'review' : 'review_without_policy', // TODO move to enum
        // smartReminder: {}, // TODO REMOVE For test

        categoryId: attributes.categoryId,
        lastBuy: attributes.lastBuy,

        customerServiceRate: attributes.customerServiceRate ? attributes.customerServiceRate * 2 : null,
        valueForMoneyRate: attributes.valueForMoneyRate ? attributes.valueForMoneyRate * 2 : null,
        staffKnowledgeRate: attributes.staffKnowledgeRate ? attributes.staffKnowledgeRate * 2 : null,
        claimRate: attributes.claimRate ? attributes.claimRate * 2 : null,

        criteriaQuestions: attributes.criteriaQuestions,

        anyCompanyRate: attributes.anyCompanyRate,
        anyCompanyRateComment: attributes.anyCompanyRateComment
      };

      renewalDetailApi
        .createRenewalDetail(renewalDetailAttributes)
        .then((res: Record<string, any>) => {
          navigate(location.pathname);

          if (res?.renewalDetail) {
            insightsCompanyStore.addPost(res?.renewalDetail);
          }

          toggle();
          toggleThankVoteModal({ modalProps: { company, IS_BUSINESS_PAGE, category } });
        })
        .catch(error => {
          toastr.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    return (
      <FormProvider {...methods}>
        <Dialog
          open={isOpen}
          onClose={toggle}
          fullWidth
          maxWidth='md'
          scroll='paper'
          PaperProps={
            {
              component: 'form',
              onSubmit: handleSubmit(onSubmit),
              sx: { minHeight: 592 }
            } as PaperProps // cast with an MUI issue
          }
        >
          <DialogTitle sx={{ position: 'relative', pt: 3 }}>
            <ProgressBar progress={(currentStep / lastStepIndex) * 100} />
            <Stack direction='row' alignItems='flex-start' justifyContent='space-between'>
              <Stack direction='row' spacing={2}>
                <Avatar avatarUrl={company?.avatarUrl} size={64} />
                <Stack>
                  <Typography variant='h6' sx={{ mb: isMobile ? 0.5 : 1 }}>
                    Write a review for {company?.name}
                  </Typography>
                  {categoryName && (
                    <Typography variant='body1' color={colors.text.secondary}>
                      {categoryName}
                    </Typography>
                  )}
                </Stack>
              </Stack>
              <IconButton edge='end' color='inherit' onClick={() => toggle()}>
                <CloseIcon />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent dividers>
            {currentStep === 0 && <CategorySelect categories={categories} subCategories={subCategories} />}
            {currentStep === 1 && <CurrentCustomer />}
            {currentStep === 2 && <LastBuy />}
            {currentStep === 3 && <ServiceRate />}
            {currentStep === 4 && <VoteCriteria company={company} voteCriteriaQuestions={voteCriteriaQuestions} />}
            {currentStep === 5 && <TotalRate />}
          </DialogContent>
          <DialogActions>
            {currentStep === firstStepIndex ? (
              <Button color='inherit' variant='text' size={'smallToMedium' as ButtonProps['size']} onClick={toggle}>
                Cancel
              </Button>
            ) : (
              <Button
                color='inherit'
                variant='text'
                size={'smallToMedium' as ButtonProps['size']}
                onClick={handlePrevStep}
                startIcon={<ArrowBackIosIcon />}
              >
                Back
              </Button>
            )}
            {!currentUser?.id && currentStep === lastStepIndex ? (
              <SignUpModalLink
                disabled={!isValid}
                size={'smallToMedium' as ButtonProps['size']}
                onSignUpSuccess={() => {
                  handleSubmit(onSubmit)();
                }}
              >
                Vote
              </SignUpModalLink>
            ) : (
              <Button
                type='submit'
                variant='contained'
                size={'smallToMedium' as ButtonProps['size']}
                disabled={isLoading}
              >
                {currentStep === lastStepIndex ? 'Vote' : 'Next'}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </FormProvider>
    );
  }
);
