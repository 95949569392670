import React from 'react';

export const Local_servicesContractors = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path d='M11.9844 1.98645C11.7195 1.99059 11.4671 2.09965 11.2825 2.28969C11.098 2.47974 10.9964 2.73524 11 3.00012V3.10168C9.04434 3.50056 7.50044 5.04447 7.10156 7.00012H7C6.86749 6.99825 6.73593 7.02273 6.61296 7.07214C6.48999 7.12156 6.37807 7.19492 6.2837 7.28796C6.18933 7.38101 6.11439 7.49188 6.06324 7.61414C6.0121 7.73639 5.98576 7.8676 5.98576 8.00012C5.98576 8.13265 6.0121 8.26385 6.06324 8.38611C6.11439 8.50837 6.18933 8.61924 6.2837 8.71228C6.37807 8.80533 6.48999 8.87869 6.61296 8.9281C6.73593 8.97752 6.86749 9.002 7 9.00012H8C8 11.1975 9.80267 13.0001 12 13.0001C14.1973 13.0001 16 11.1975 16 9.00012H17C17.1325 9.002 17.2641 8.97752 17.387 8.9281C17.51 8.87869 17.6219 8.80533 17.7163 8.71228C17.8107 8.61924 17.8856 8.50837 17.9368 8.38611C17.9879 8.26385 18.0142 8.13265 18.0142 8.00012C18.0142 7.8676 17.9879 7.73639 17.9368 7.61414C17.8856 7.49188 17.8107 7.38101 17.7163 7.28796C17.6219 7.19492 17.51 7.12156 17.387 7.07214C17.2641 7.02273 17.1325 6.99825 17 7.00012H16.8984C16.4996 5.04447 14.9557 3.50056 13 3.10168V3.00012C13.0018 2.86633 12.9768 2.73354 12.9264 2.6096C12.876 2.48566 12.8012 2.37309 12.7065 2.27857C12.6118 2.18405 12.4991 2.10949 12.375 2.05932C12.251 2.00914 12.1182 1.98436 11.9844 1.98645ZM10 9.00012H14C14 10.1168 13.1167 11.0001 12 11.0001C10.8833 11.0001 10 10.1168 10 9.00012ZM12 15.0001C11.252 15.0001 10.3492 15.0917 9.4082 15.2657C9.1722 15.3097 9 15.5199 9 15.7599V21.0001H15V15.7599C15 15.5199 14.8278 15.3097 14.5918 15.2657C13.6508 15.0917 12.748 15.0001 12 15.0001ZM6.54883 16.1154C6.4675 16.1067 6.38208 16.1185 6.29883 16.1525C4.45283 16.9085 3 18.0291 3 19.5001C3 20.3281 3.672 21.0001 4.5 21.0001H7V16.6115C7 16.3414 6.79281 16.1412 6.54883 16.1154ZM17.4512 16.1173C17.2072 16.1428 17 16.3414 17 16.6115V21.0001H19.5C20.328 21.0001 21 20.3281 21 19.5001C21 18.0291 19.5472 16.9075 17.7012 16.1525C17.6179 16.1182 17.5325 16.1088 17.4512 16.1173Z' />
      </g>
    </svg>
  );
};
