export default () => ({
  wrapper: {
    width: '100%',
    p: 3,
    borderRadius: '16px',
    border: '1px solid  rgba(0, 0, 0, 0.16)',
    background: '#FFF'
  },
  topSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    mb: 2
  },
  userInfoSection: {
    display: 'flex',
    alignItems: 'center',
    gap: 2
  },
  ratingCategorySection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 2,
    mb: 1
  },
  ratingWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5
  },
  categorySection: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
    borderRadius: '4px',
    p: 0.5,
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    color: 'rgba(0, 0, 0, 0.87)',
    '& svg': {
      width: '16px',
      height: '16px',
      fill: 'rgba(0, 0, 0, 0.87)'
    }
  },
  title: {
    mb: 1,
    wordBreak: 'break-word'
  },
  comment: {
    wordBreak: 'break-word'
  },
  actionPanelWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    p: 1,
    borderRadius: '8px',
    mt: 2,
    backgroundColor: '#F4F4F4'
  },
  actionPanelSection: {
    display: 'flex',
    alignItems: 'center',
    gap: 1
  },
  verified: {
    display: 'flex',
    alignItems: 'center',
    height: '26px',
    borderRadius: '4px',
    px: '4px',
    background: '#C9E7CA',
    color: '#2E7D32',
    fontSize: '12px',
    fontWeight: 600
  }
});
