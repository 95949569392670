import React, { useState } from 'react';
import _ from 'lodash-es';
import moment from 'moment';

import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import useStyles from '@packs/components/Calendar/styles';
import { CategoryIconBox } from '@packs/components/MaterialUi/CategoryIconBox/CategoryIconBox';
import { Switcher } from '@packs/components/Switcher';
import Delete from '@packs/images/icons/Delete';
import MyNotesIcon from '@packs/images/icons/MyNotesIcon';
import { usePath } from '@packs/layouts/constants';
import { autoQuoteTooltip, autoQuoteTooltipNoPolicy } from '@packs/lib/constants';
import { hasPolicyCriteria } from '@packs/lib/helpers/additionalHelpers';
import { recurringOptions } from '@packs/models/company/constants';
import { VIEW_DATE_FORMAT } from '@packs/screens/PersonalizeResults/formHooks/defaults';
import { useStore } from '@packs/stores';
import { TooltipMUI } from '@shared/components/TooltipMUI/TooltipMUI';

import { observer } from 'mobx-react';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

const { dark } = colors;

export const EventItem = observer(({ event, onCalendarDeleteReminder, onCalendarEditReminder, disableDropdown }) => {
  const styles = useStyles();
  const { smartRemindersStore, categoryStore } = useStore();
  const { ADD_DETAILS_PATH_FN, DASHBOARD_MY_RESULTS_PATH_FN } = usePath();

  const [dropdownOpen, setDropdownOpen] = useState();
  const { currentCategory } = useStore();
  const navigate = useNavigate();

  const category = event?.category || currentCategory;
  const isAutoQuote = event?.isAutoQuote;
  const quoteRequest = !!event?.quoteRequest;
  const notPersonalized = !hasPolicyCriteria(event?.renewalDetail);
  const recurringTime = recurringOptions.find(({ value }) => value === event?.recurringTime)?.label || null;

  const toggleIsAutoQuote = () => {
    if (quoteRequest) {
      smartRemindersStore.toggleIsAutoQuote({ isAutoQuote: !isAutoQuote, smartReminder: event });
    } else {
      onClickEdit();
    }
  };

  const onChangeCategory = () => {
    categoryStore.setCurrentCategory(event?.category);
    navigate(ADD_DETAILS_PATH_FN(event?.category));
  };

  const toggle = () => setDropdownOpen(!dropdownOpen);

  const LeaderboardLink = () => {
    return (
      <Link
        to={DASHBOARD_MY_RESULTS_PATH_FN(category)}
        onClick={() => {
          event?.category && categoryStore.setCurrentCategory(category);
        }}
      >
        <Typography variant='caption' sx={styles.reminderCategoryLink}>
          {`${_.startCase(category?.name)}`}
        </Typography>
      </Link>
    );
  };

  const onClickEdit = () => {
    onCalendarEditReminder(event, { parentType: 'calendar', category });
  };

  const onClickDelete = () => {
    onCalendarDeleteReminder(event);
  };

  return (
    <>
      <Box className='item-header'>
        <Box sx={styles.reminderCategoryHeader}>
          <Box>
            {_.get(event, 'category.code', '') && _.get(event, 'category.root.enName', '') && (
              <Box sx={styles.reminderCategoryWrapper}>
                <CategoryIconBox
                  category={category}
                  backgroundColor='transparent'
                  color={dark.main}
                  containerSize='18px'
                  iconSize='18px'
                />
                <LeaderboardLink />
              </Box>
            )}
          </Box>

          {!disableDropdown && (
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle tag='span'>
                <IconButton>
                  <MoreHorizRoundedIcon />
                </IconButton>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={onClickEdit}>
                  <span>
                    <MyNotesIcon />
                  </span>
                  Edit
                </DropdownItem>

                <DropdownItem onClick={onClickDelete}>
                  <span className='delete-icon'>
                    <Delete />
                  </span>
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
        </Box>

        <Box sx={styles.reminderCategoryPolicy}>
          <Typography variant='body4'>{event?.name}</Typography>
          <div className='d-flex align-items-center justify-content-between'>
            <Typography variant='body5'>{moment(event.triggeredAt).format(VIEW_DATE_FORMAT)}</Typography>
            <Typography variant='body5' className='status'>
              Status
            </Typography>
          </div>
          {recurringTime && event?.recurringTime && <div className='fs-13 secondary-text mt-4'>{recurringTime}</div>}
        </Box>
        <Box sx={styles.autoQuoteWrapper}>
          <TooltipMUI
            title={
              <Typography variant='caption'>
                {hasPolicyCriteria(event?.renewalDetail) ? autoQuoteTooltip : autoQuoteTooltipNoPolicy}
              </Typography>
            }
            placement='top'
            arrow
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Switcher
                textOff='off'
                textOn='on'
                primary
                value={isAutoQuote}
                onChange={toggleIsAutoQuote}
                disabled={notPersonalized}
              />
              <Typography variant='body5'>Auto. quote</Typography>
            </Box>
          </TooltipMUI>
        </Box>
        {notPersonalized && (
          <TooltipMUI title='Add your details so you can get matched with the best businesses to quote you!' arrow>
            <Button fullWidth variant='filledTonal' size='small' onClick={onChangeCategory}>
              Get Matched!
            </Button>
          </TooltipMUI>
        )}
      </Box>
    </>
  );
});
