import React from 'react';

export default () => {
  return (
    <svg width='160' height='32' viewBox='0 0 160 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M47.2115 24.2759H42.5974C41.8951 24.2759 41.4246 24.228 41.1858 24.1323C40.947 24.0366 40.8276 23.8861 40.8276 23.681V10.4913C40.8276 9.83485 42.8573 9.50665 46.9165 9.50665C49.2481 9.50665 50.8564 9.72545 51.7413 10.1631C52.6402 10.6143 53.2161 11.1887 53.4689 11.8861C53.6094 12.2964 53.6796 12.775 53.6796 13.322C53.6796 14.3887 53.49 15.1887 53.1108 15.722C52.7456 16.2554 52.3453 16.5562 51.9098 16.6246C52.57 16.7613 53.1178 17.0759 53.5532 17.5682C53.9886 18.0605 54.2063 18.8947 54.2063 20.0708C54.2063 21.4383 53.7779 22.4502 52.9211 23.1066C52.1767 23.681 51.039 24.0434 49.508 24.1938C48.8619 24.2485 48.0964 24.2759 47.2115 24.2759ZM47.2326 15.3323C47.9489 15.3323 48.3071 14.8673 48.3071 13.9374C48.3071 13.4725 48.2087 13.1306 48.0121 12.9118C47.8295 12.6793 47.6188 12.5631 47.38 12.5631H46.7058V15.3323H47.2326ZM46.7058 21.2195H47.2326C47.9489 21.2195 48.3071 20.7545 48.3071 19.8246C48.3071 19.3596 48.2087 19.0178 48.0121 18.799C47.8295 18.5665 47.6188 18.4502 47.38 18.4502H46.7058V21.2195Z'
        fill='white'
      />
      <path
        d='M61.1656 13.6708V23.7836C61.1656 23.934 60.7583 24.0571 59.9436 24.1528C59.143 24.2349 58.3143 24.2759 57.4575 24.2759C56.6148 24.2759 56.067 24.2417 55.8142 24.1733C55.5754 24.0913 55.456 23.9613 55.456 23.7836V14.04C55.456 13.7391 55.8493 13.4451 56.6358 13.1579C57.4365 12.8571 58.3073 12.7066 59.2484 12.7066C60.5265 12.7066 61.1656 13.028 61.1656 13.6708ZM58.3003 8.27588C59.2273 8.27588 59.9507 8.41263 60.4704 8.68613C61.0041 8.94596 61.271 9.4246 61.271 10.122C61.271 11.3528 60.2878 11.9682 58.3213 11.9682C56.3549 11.9682 55.3717 11.3528 55.3717 10.122C55.3717 8.89126 56.3479 8.27588 58.3003 8.27588Z'
        fill='white'
      />
      <path
        d='M68.4261 9.21947V23.7836C68.4261 23.934 68.0258 24.0571 67.2252 24.1528C66.4246 24.2349 65.5959 24.2759 64.7391 24.2759C63.8964 24.2759 63.3486 24.2417 63.0957 24.1733C62.857 24.0913 62.7376 23.9613 62.7376 23.7836V9.5887C62.7376 9.28784 63.1309 8.99383 63.9174 8.70665C64.718 8.41947 65.5748 8.27588 66.4878 8.27588C67.78 8.27588 68.4261 8.59041 68.4261 9.21947Z'
        fill='white'
      />
      <path
        d='M75.5863 9.21947V23.7836C75.5863 23.934 75.186 24.0571 74.3853 24.1528C73.5847 24.2349 72.756 24.2759 71.8992 24.2759C71.0565 24.2759 70.5087 24.2417 70.2558 24.1733C70.0171 24.0913 69.8977 23.9613 69.8977 23.7836V9.5887C69.8977 9.28784 70.291 8.99383 71.0775 8.70665C71.8781 8.41947 72.7349 8.27588 73.6479 8.27588C74.9401 8.27588 75.5863 8.59041 75.5863 9.21947Z'
        fill='white'
      />
      <path
        d='M84.2032 24.0663C83.5307 24.0663 83.0893 24.0174 82.8791 23.9197C82.669 23.8219 82.5219 23.6543 82.4378 23.4168L77.7932 10.6364C77.7932 10.371 78.2906 10.0637 79.2854 9.71452C80.2941 9.36533 81.3099 9.19073 82.3327 9.19073C83.6497 9.19073 84.3503 9.60278 84.4344 10.4269L85.1489 17.0685H85.4431L86.0316 10.4269C86.1016 9.60278 87.0474 9.19073 88.8688 9.19073C89.5693 9.19073 90.0387 9.2885 90.2769 9.48405C90.5151 9.66563 90.6482 9.91007 90.6762 10.2174L91.3697 17.0685H91.6639L92.2104 10.4269C92.2524 10.0218 92.7288 9.71452 93.6395 9.505C94.5642 9.29549 95.4749 9.19073 96.3716 9.19073C97.2823 9.19073 97.8988 9.32342 98.221 9.58881C98.5573 9.8542 98.7254 10.1335 98.7254 10.4269L94.2279 23.4168C94.0738 23.8498 93.037 24.0663 91.1175 24.0663C90.5431 24.0663 90.1578 24.0174 89.9616 23.9197C89.7655 23.8219 89.6324 23.6543 89.5623 23.4168L88.4695 19.7713H88.2173L87.1034 23.4168C86.9773 23.8498 86.0106 24.0663 84.2032 24.0663Z'
        fill='#F8B500'
      />
      <path
        d='M105.443 13.2344V23.5635C105.443 23.7171 105.036 23.8428 104.224 23.9406C103.425 24.0244 102.599 24.0663 101.744 24.0663C100.903 24.0663 100.357 24.0314 100.105 23.9616C99.8664 23.8778 99.7473 23.7451 99.7473 23.5635V13.6115C99.7473 13.3042 100.14 13.0039 100.924 12.7106C101.723 12.4033 102.592 12.2497 103.53 12.2497C104.805 12.2497 105.443 12.5779 105.443 13.2344ZM102.585 7.72412C103.509 7.72412 104.231 7.8638 104.749 8.14315C105.282 8.40854 105.548 8.89741 105.548 9.60976C105.548 10.8669 104.567 11.4954 102.606 11.4954C100.644 11.4954 99.6633 10.8669 99.6633 9.60976C99.6633 8.35267 100.637 7.72412 102.585 7.72412Z'
        fill='#F8B500'
      />
      <path
        d='M119.684 17.1104V23.5635C119.684 23.7171 119.284 23.8428 118.486 23.9406C117.687 24.0244 116.86 24.0663 116.006 24.0663C115.165 24.0663 114.619 24.0314 114.366 23.9616C114.128 23.8778 114.009 23.7451 114.009 23.5635V16.9009C114.009 16.2724 113.981 15.8603 113.925 15.6648C113.883 15.4692 113.841 15.2946 113.799 15.141C113.701 14.8756 113.526 14.7429 113.274 14.7429C112.881 14.7429 112.685 14.9315 112.685 15.3086V23.5635C112.685 23.7171 112.286 23.8428 111.487 23.9406C110.703 24.0244 109.883 24.0663 109.028 24.0663C108.188 24.0663 107.641 24.0314 107.389 23.9616C107.137 23.8778 107.011 23.7451 107.011 23.5635V13.2344C107.011 12.9411 107.41 12.6477 108.209 12.3544C109.021 12.0611 109.876 11.9144 110.773 11.9144C111.669 11.9144 112.174 12.1728 112.286 12.6896L112.475 13.6115H112.622C113.028 12.8014 113.729 12.2357 114.724 11.9144C115.13 11.7887 115.578 11.7259 116.069 11.7259C117.484 11.7259 118.437 12.1519 118.927 13.0039C119.431 13.842 119.684 15.2108 119.684 17.1104Z'
        fill='#F8B500'
      />
      <path
        d='M133.824 17.1104V23.5635C133.824 23.7171 133.425 23.8428 132.626 23.9406C131.828 24.0244 131.001 24.0663 130.147 24.0663C129.306 24.0663 128.759 24.0314 128.507 23.9616C128.269 23.8778 128.15 23.7451 128.15 23.5635V16.9009C128.15 16.2724 128.122 15.8603 128.066 15.6648C128.024 15.4692 127.982 15.2946 127.94 15.141C127.842 14.8756 127.667 14.7429 127.414 14.7429C127.022 14.7429 126.826 14.9315 126.826 15.3086V23.5635C126.826 23.7171 126.427 23.8428 125.628 23.9406C124.843 24.0244 124.024 24.0663 123.169 24.0663C122.328 24.0663 121.782 24.0314 121.53 23.9616C121.278 23.8778 121.152 23.7451 121.152 23.5635V13.2344C121.152 12.9411 121.551 12.6477 122.349 12.3544C123.162 12.0611 124.017 11.9144 124.913 11.9144C125.81 11.9144 126.315 12.1728 126.427 12.6896L126.616 13.6115H126.763C127.169 12.8014 127.87 12.2357 128.865 11.9144C129.271 11.7887 129.719 11.7259 130.21 11.7259C131.625 11.7259 132.577 12.1519 133.068 13.0039C133.572 13.842 133.824 15.2108 133.824 17.1104Z'
        fill='#F8B500'
      />
      <path
        d='M140.82 19.3313C140.82 20.2392 141.254 20.6931 142.123 20.6931C142.627 20.6931 143.286 20.5255 144.098 20.1903C144.911 19.8411 145.338 19.6665 145.38 19.6665C145.646 19.6665 145.997 20.0646 146.431 20.8607C146.865 21.6569 147.082 22.2435 147.082 22.6207C147.082 23.1654 146.522 23.5775 145.401 23.8568C144.28 24.1362 143.18 24.2758 142.102 24.2758C141.037 24.2758 140.14 24.192 139.412 24.0244C138.697 23.8568 138.109 23.6473 137.646 23.3959C137.198 23.1305 136.799 22.8092 136.448 22.4321C136.112 22.055 135.86 21.6988 135.692 21.3636C135.524 21.0144 135.39 20.6303 135.292 20.2112C135.152 19.5967 135.082 18.9611 135.082 18.3047C135.082 15.7625 135.748 14.0096 137.079 13.0458C137.737 12.5569 138.473 12.2147 139.285 12.0192C140.098 11.8236 141.058 11.7259 142.165 11.7259C145.709 11.7259 147.482 12.955 147.482 15.4133C147.482 18.0253 145.492 19.3313 141.513 19.3313H140.82ZM140.778 16.2724V17.6552C141.184 17.6552 141.513 17.4945 141.765 17.1733C142.032 16.852 142.165 16.4609 142.165 16C142.165 15.1619 141.948 14.7429 141.513 14.7429C141.023 14.7429 140.778 15.2527 140.778 16.2724Z'
        fill='#F8B500'
      />
      <path
        d='M154.41 17.4456V23.5635C154.41 23.7171 154.01 23.8428 153.212 23.9406C152.427 24.0244 151.608 24.0663 150.753 24.0663C149.912 24.0663 149.366 24.0314 149.114 23.9616C148.861 23.8778 148.735 23.7451 148.735 23.5635V13.2344C148.735 12.969 149.121 12.6827 149.891 12.3754C150.676 12.0681 151.524 11.9144 152.434 11.9144C153.359 11.9144 153.877 12.1658 153.989 12.6687L154.2 13.6325H154.347C154.543 13.1017 155.012 12.6547 155.755 12.2916C156.497 11.9144 157.24 11.7259 157.983 11.7259C158.725 11.7259 159.244 11.8446 159.538 12.082C159.846 12.3195 160 12.6268 160 13.0039C160 13.3671 159.972 13.835 159.916 14.4077C159.86 14.9803 159.72 15.6019 159.496 16.2724C159.286 16.9428 159.005 17.278 158.655 17.278C158.515 17.278 158.074 17.1733 157.331 16.9638C156.588 16.7542 155.972 16.6495 155.482 16.6495C155.005 16.6495 154.704 16.7193 154.578 16.859C154.466 16.9987 154.41 17.1942 154.41 17.4456Z'
        fill='#F8B500'
      />
      <path
        d='M30.4 1.6H25.6V0H6.4V1.6H1.6C0.64 1.6 0 2.24 0 3.2V7.04C0 10.72 2.72 13.76 6.4 14.24V14.4C6.4 19.04 9.6 22.88 13.92 23.84L12.8 27.2H9.12C8.48 27.2 7.84 27.68 7.68 28.32L6.4 32H25.6L24.32 28.32C24.16 27.68 23.52 27.2 22.88 27.2H19.2L18.08 23.84C22.4 22.88 25.6 19.04 25.6 14.4V14.24C29.28 13.76 32 10.72 32 7.04V3.2C32 2.24 31.36 1.6 30.4 1.6ZM6.4 11.04C4.64 10.56 3.2 8.96 3.2 7.04V4.8H6.4V11.04ZM19.2 16L16 14.24L12.8 16L13.6 12.8L11.2 9.6H14.56L16 6.4L17.44 9.6H20.8L18.4 12.8L19.2 16ZM28.8 7.04C28.8 8.96 27.36 10.72 25.6 11.04V4.8H28.8V7.04Z'
        fill='#F8B500'
      />
    </svg>
  );
};
