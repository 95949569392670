import colors from '@packs/assets/theme/base/colors';
import PageNavigationBGIcon from '@packs/images/svg/ui/page-navigation-bg.svg';

const { secondary, white } = colors;

export default () => ({
  wrapper: {
    backgroundImage: secondary.gradient,
    mb: {
      xs: 3,
      lg: 6
    }
  },
  innerWrapper: {
    backgroundImage: {
      xs: 'none',
      lg: `url(${PageNavigationBGIcon})`
    },
    backgroundSize: 'cover',
    backgroundPosition: 'bottom center',
    backgroundRepeat: 'no-repeat'
  },
  container: {
    pt: {
      xs: 2,
      lg: 3
    },
    pb: {
      xs: 2,
      lg: 6
    }
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    justifyContent: 'space-between',
    alignItems: { xs: 'flex-start', md: 'flex-end' },
    gap: 3
  },
  title: {
    color: white.main,
    mt: {
      xs: 3,
      md: 5
    }
  },
  subTitle: {
    mt: 2,
    color: white.main
  }
});
