import React, { useEffect, useState } from 'react';

import {
  CloseRounded as CloseIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  Logout as LogoutIcon
} from '@mui/icons-material';
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded';
import { Box, Button, Divider, Link, Stack, SwipeableDrawer, Typography } from '@mui/material';
import colors from '@packs/assets/theme/base/colors';
import { SignInModalLink } from '@packs/components/SignInModalLink';
import { SignUpModalLink } from '@packs/components/SignUpModalLink';
import { Avatar } from '@packs/components/UI';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { usePath } from '@packs/layouts/constants';
import { toastr } from '@packs/lib/helpers';
import { sortSubcategoriesDesktop } from '@packs/lib/helpers/sortSubcategoriesDesktop';
import { EXCLUDED_SUB_CATEGORIES } from '@packs/models/category/constants';
import { useCategories } from '@packs/models/category/useCategories';
import { useStore } from '@packs/stores';
import { IconBox } from '@shared/components/IconBox/IconBox';
import { ICONS } from '@shared/helpers/categoriesIconsHelper';
import { businessPageUrl } from '@shared/helpers/urlHelper';

import useStyle from './styles';
import SubCategoryMenu from './SubCategoryMenu';

import { observer } from 'mobx-react';
import AnimateHeight from 'react-animate-height';
import { NavLink, useNavigate } from 'react-router-dom';

const { secondary, text, dark, error, white } = colors;

export const MobileMenu = observer(({ openDrawer, toggleDrawer }) => {
  const styles = useStyle();
  const { categoryStore } = useStore();
  const { currentUser } = useUserSelectors();
  const { categories } = useCategories();

  const [categoriesIsOpen, setCategoriesIsOpen] = useState({});
  const navigate = useNavigate();

  const {
    ROOT_PATH,
    PROFILE_MY_REVIEWS_PATH,
    INVITE_FRIENDS_PATH,
    MY_OFFERS_PATH,
    MY_HOME_PATH,
    PROFILE_MY_REMINDERS_PATH,
    ASSOCIATION_PATH,
    MY_NOTES_PATH,
    VOTE_PATH
  } = usePath();
  const { userStore, countryCode } = useStore();

  const toggleOpenCategory = code => {
    const newCategories = { ...categoriesIsOpen };
    newCategories[code] = !categoriesIsOpen[code];

    setCategoriesIsOpen(newCategories);
  };

  const logOutHandler = () => {
    userStore.logOut().then(() => {
      toastr.success('Signed out successfully.');
      navigate(ROOT_PATH);
    });
  };

  useEffect(() => {
    if (!openDrawer) {
      setCategoriesIsOpen({});
    }
  }, [openDrawer]);

  return (
    <SwipeableDrawer
      anchor='right'
      open={!!openDrawer}
      onClose={toggleDrawer}
      sx={{
        '& .MuiDrawer-paper': {
          width: '82vw',
          borderRadius: '8px 0 0 6px'
        }
      }}
    >
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
        sx={{ p: 2, backgroundColor: '#F6F6F6' }}
      >
        <Stack direction='row' spacing={1.5}>
          <Avatar
            size={40}
            square
            placeholder={currentUser?.displayName}
            avatarUrl={currentUser?.avatarUrl}
            withBorder
            fullSize
            withBG
          />
          <Box>
            {currentUser?.id ? (
              <>
                <Box>
                  <Typography variant='body5' color={text.main}>
                    Hello
                  </Typography>
                </Box>
                <Box>
                  <Typography variant='body4'>{currentUser?.displayName}</Typography>
                </Box>
              </>
            ) : (
              <>
                <Stack direction='row' spacing={1}>
                  <SignInModalLink
                    onClick={toggleDrawer}
                    variant='body4'
                    color={secondary.main}
                    component={Typography}
                    sx={{ textDecoration: 'underline' }}
                  >
                    Log in
                  </SignInModalLink>
                  <Typography variant='body3'>or</Typography>
                  <SignUpModalLink
                    onClick={toggleDrawer}
                    variant='body4'
                    color={secondary.main}
                    component={Typography}
                    sx={{ textDecoration: 'underline' }}
                  >
                    Sign Up
                  </SignUpModalLink>
                </Stack>
                <Box>
                  <Typography variant='body5' color={text.main}>
                    To access your account
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Stack>
        <IconBox
          containerSize='32px'
          icon={<CloseIcon />}
          borderRadius='8px'
          color={secondary.main}
          backgroundColor={secondary.transparent}
          onClick={toggleDrawer}
        />
      </Stack>
      <Stack direction='column' alignItems='flex-start' spacing={1} sx={{ px: 1, py: 1 }}>
        {currentUser.id && (
          <>
            <Typography variant='body4' component={NavLink} to={MY_HOME_PATH} sx={styles.link} onClick={toggleDrawer}>
              Dashboard
            </Typography>
            <Typography
              variant='body4'
              component={NavLink}
              to={PROFILE_MY_REMINDERS_PATH}
              sx={styles.link}
              onClick={toggleDrawer}
            >
              Reminders
            </Typography>
            <Typography
              variant='body4'
              component={NavLink}
              to={ASSOCIATION_PATH}
              sx={styles.link}
              onClick={toggleDrawer}
            >
              Associations
            </Typography>
            <Typography variant='body4' component={NavLink} to={MY_OFFERS_PATH} sx={styles.link} onClick={toggleDrawer}>
              Offers
            </Typography>
            <Typography variant='body4' component={NavLink} to={MY_NOTES_PATH} sx={styles.link} onClick={toggleDrawer}>
              Documents & Notes
            </Typography>
            <Typography
              variant='body4'
              component={NavLink}
              to={PROFILE_MY_REVIEWS_PATH}
              sx={styles.link}
              onClick={toggleDrawer}
            >
              Profile
            </Typography>
            <Typography
              variant='body4'
              component={NavLink}
              to={INVITE_FRIENDS_PATH}
              sx={styles.link}
              onClick={toggleDrawer}
            >
              Trust Network
            </Typography>
          </>
        )}
        <Box
          variant='body4'
          component={NavLink}
          p={1}
          color={dark.main}
          fontWeight='700'
          to={VOTE_PATH}
          onClick={toggleDrawer}
        >
          Write a review!
        </Box>
        {!currentUser?.id && (
          <Box sx={{ width: '100%', px: 1 }}>
            <Button
              component={Link}
              size='smallToMedium'
              color='secondary'
              variant='filledTonal'
              href={businessPageUrl()}
              target='_blank'
              startIcon={<BusinessCenterRoundedIcon />}
              fullWidth
              sx={{
                border: '2px solid rgba(0, 85, 217, 0.16)',
                backgroundColor: 'rgba(0, 85, 217, 0.20)',
                textDecoration: 'none',
                color: secondary.dark,
                '&:hover': {
                  color: white.main
                }
              }}
            >
              For Business
            </Button>
          </Box>
        )}
      </Stack>
      <Divider />
      <Stack direction='column' sx={{ px: 2, py: 1 }}>
        {categories.map(ctg => {
          const subCategories = sortSubcategoriesDesktop(ctg);

          return (
            <Box key={ctg.code}>
              <Box
                display='flex'
                alignItems='center'
                gap={1}
                sx={{ py: 1 }}
                onClick={() => toggleOpenCategory(ctg.code)}
              >
                <IconBox
                  containerSize='32px'
                  icon={ICONS[ctg.enName]}
                  borderRadius='8px'
                  color={secondary.main}
                  backgroundColor={secondary.transparent}
                  rootIcon
                />
                <Typography variant='body4'>{ctg.name}</Typography>
                <Box ml='auto'>{categoriesIsOpen[ctg.code] ? <KeyboardArrowUpIcon /> : <KeyboardArrowRightIcon />}</Box>
              </Box>
              <AnimateHeight duration={250} height={categoriesIsOpen[ctg.code] ? 'auto' : 0}>
                <Box>
                  {subCategories.map((subCategoriesArray, index) => {
                    return (
                      <Stack direction='column' key={`sub-category-${index}`}>
                        {subCategoriesArray.map(subCategory => {
                          if (EXCLUDED_SUB_CATEGORIES.includes(subCategory.code)) {
                            return null;
                          }

                          return (
                            <Box key={subCategory.id}>
                              <SubCategoryMenu
                                category={ctg}
                                subCategory={subCategory}
                                countryCode={countryCode}
                                onRedirect={e => {
                                  e.stopPropagation();
                                  toggleDrawer();
                                  categoryStore.setCurrentCategory(subCategory);
                                }}
                              />
                            </Box>
                          );
                        })}
                      </Stack>
                    );
                  })}
                </Box>
              </AnimateHeight>
            </Box>
          );
        })}
      </Stack>

      <Divider />
      <Stack direction='column' sx={{ px: 2, pb: 1, mt: 2 }}>
        {/* <CountrySelect*/}
        {/*  {...{ countryCode }}*/}
        {/*  bgFilled*/}
        {/*  showCountryName*/}
        {/*  arrowFontSize='24px'*/}
        {/*  triggerStyles={{*/}
        {/*    justifyContent: 'space-between',*/}
        {/*    width: '100%',*/}
        {/*    py: 2*/}
        {/*  }}*/}
        {/*/ >*/}
        {currentUser.id && (
          <Stack
            direction='row'
            alignItems='center'
            spacing={1}
            sx={{ alignSelf: 'flex-start' }}
            onClick={() => {
              toggleDrawer();
              logOutHandler();
            }}
          >
            <IconBox
              backgroundColor={error.transparent}
              color={error.main}
              containerSize='32px'
              icon={<LogoutIcon />}
            />
            <Typography variant='body4' sx={{ cursor: 'pointer' }} color={error.main}>
              Log out
            </Typography>
          </Stack>
        )}
      </Stack>
    </SwipeableDrawer>
  );
});
