import React, { useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { SentimentAnalysisMetric } from '@packs/components/MaterialUi/CompanyPage/components/Sidebar/components/SentimentAnalysisMetric/SentimentAnalysisMetric';
import { Benefits } from '@packs/components/MaterialUi/Leaderboard/CompanyItem/components/AdditionInfo/components/Benefits/Benefits';
import { ProductDetails } from '@packs/components/MaterialUi/Leaderboard/CompanyItem/components/AdditionInfo/components/ProductDetails/ProductDetails';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import useStyles from './styles';

import size from 'lodash-es/size';

export const AdditionInfo = ({ company, showCompanyType }) => {
  const { isMobile } = useWindowSize();
  const styles = useStyles();
  const [activeTab, setActiveTab] = useState('productDetails');

  const benefits = company?.benefits || [];
  const sentimentAnalysis = company?.sentimentAnalysis || {};

  const tabsList = useMemo(() => {
    const list = [{ value: 'productDetails', label: 'Product details' }];

    if (size(benefits)) {
      list.push({ value: 'benefits', label: 'Benefits' });
    }

    if (sentimentAnalysis?.score || sentimentAnalysis?.summary) {
      list.push({ value: 'sentimentAnalysis', label: 'Sentiment analysis' });
    }

    return list;
  }, [benefits]);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={styles.additionalInfoWrapper}>
      {!isMobile ? (
        <>
          <Tabs value={activeTab} onChange={handleChange} variant='scrollable' sx={styles.tabsWrapper}>
            {tabsList.map(item => (
              <Tab key={item.value} value={item.value} label={item.label} variant='scrollable' />
            ))}
          </Tabs>
          {activeTab === 'productDetails' && <ProductDetails {...{ company, showCompanyType }} />}
          {activeTab === 'benefits' && <Benefits {...{ company }} />}
          {activeTab === 'sentimentAnalysis' && (
            <Box sx={{ maxWidth: 275, width: 1, mx: 'auto' }}>
              <SentimentAnalysisMetric {...{ sentimentAnalysis }} textVariant='body3' />
            </Box>
          )}
        </>
      ) : (
        <ProductDetails {...{ company, showCompanyType }} />
      )}
    </Box>
  );
};
