import React, { useMemo } from 'react';
import { find } from 'lodash-es';

import Box from '@mui/material/Box';
import { LAST_BUY_OPTIONS } from '@packs/models/policy/constants';
import { SidebarAnswer } from '@packs/screens/PersonalizeResults/components/SidebarAnswer';
import { useStore } from '@packs/stores';

import { observer } from 'mobx-react';

export const SidebarContentWrapper = observer(({ formValues, category }) => {
  const { policiesListStore } = useStore();
  const { mixOptions, brokerOptions, comparisonSiteOptions, providersOptions } = policiesListStore;

  const insuranceCategory = category?.root?.code === 'insurance';
  const anyCompany = useMemo(() => {
    return find([...brokerOptions, ...comparisonSiteOptions, ...(insuranceCategory ? mixOptions : providersOptions)], {
      id: formValues.anyCompanyId || formValues.sourceProviderCompanyId
    });
  }, [
    brokerOptions,
    comparisonSiteOptions,
    insuranceCategory,
    formValues.anyCompanyId,
    formValues.sourceProviderCompanyId
  ]);

  const providerCompany = useMemo(() => {
    return find([...(insuranceCategory ? mixOptions : providersOptions)], { id: formValues.sourceProviderCompanyId });
  }, [insuranceCategory, formValues.sourceProviderCompanyId]);

  return (
    <div>
      {formValues?.aliasName && <SidebarAnswer answer={formValues?.aliasName} label='My Details' />}
      {anyCompany && <SidebarAnswer answer={anyCompany?.name} label='Current business' />}
      {providerCompany && anyCompany && anyCompany?.companyType !== 'provider' && (
        <SidebarAnswer answer={providerCompany?.name} label='Current provider' />
      )}
      {formValues?.lastBuy && (
        <SidebarAnswer
          label='When did you sign up with your current provider?'
          answer={LAST_BUY_OPTIONS.find(({ value }) => value === formValues?.lastBuy)?.label}
        />
      )}
      {formValues?.anyCompanyRate && (
        <>
          <SidebarAnswer
            label='Would you recommend this business to other members of our community?'
            answer={formValues.anyCompanyRate}
          />
          {formValues?.anyCompanyRateComment && (
            <Box className='lh-5 secondary-text' sx={{ fontSize: '11px', pl: '28px', wordBreak: 'break-all' }}>
              {formValues?.anyCompanyRateComment}
            </Box>
          )}
        </>
      )}
    </div>
  );
});
