import React from 'react';

export const GeneralBusinessServices = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <g>
          <path d='M14.17 13.71L15.57 11.29C15.66 11.14 15.62 10.95 15.49 10.84L14.01 9.68C14.04 9.46 14.06 9.23 14.06 9C14.06 8.77 14.04 8.54 14.01 8.31L15.49 7.15C15.62 7.04 15.66 6.85 15.57 6.7L14.17 4.28C14.08 4.13 13.9 4.07 13.74 4.13L12 4.83C11.64 4.55 11.25 4.32 10.82 4.14L10.56 2.29C10.53 2.13 10.38 2 10.21 2H7.41C7.24 2 7.09 2.13 7.06 2.3L6.8 4.15C6.38 4.33 5.98 4.56 5.62 4.84L3.88 4.14C3.72 4.08 3.54 4.14 3.45 4.29L2.05 6.71C1.96 6.86 2 7.05 2.13 7.16L3.61 8.32C3.58 8.54 3.56 8.77 3.56 9C3.56 9.23 3.58 9.46 3.61 9.69L2.13 10.85C2 10.96 1.96 11.15 2.05 11.3L3.45 13.72C3.54 13.87 3.72 13.93 3.88 13.87L5.62 13.17C5.98 13.45 6.37 13.68 6.8 13.86L7.06 15.71C7.09 15.87 7.24 16 7.41 16H10.21C10.38 16 10.53 15.87 10.56 15.7L10.82 13.85C11.24 13.67 11.64 13.44 12 13.16L13.74 13.86C13.9 13.92 14.08 13.86 14.17 13.71ZM8.81 11C7.71 11 6.81 10.1 6.81 9C6.81 7.9 7.71 7 8.81 7C9.91 7 10.81 7.9 10.81 9C10.81 10.1 9.91 11 8.81 11Z' />
          <path d='M21.92 18.67L20.96 17.93C20.98 17.79 21 17.64 21 17.49C21 17.34 20.99 17.19 20.96 17.05L21.91 16.31C21.99 16.24 22.02 16.12 21.96 16.02L21.06 14.47C21.01 14.37 20.89 14.34 20.78 14.37L19.67 14.82C19.44 14.64 19.19 14.49 18.91 14.38L18.74 13.2C18.73 13.08 18.63 13 18.53 13H16.74C16.63 13 16.53 13.08 16.52 13.19L16.35 14.37C16.08 14.49 15.82 14.63 15.59 14.81L14.48 14.36C14.38 14.32 14.26 14.36 14.2 14.46L13.3 16.01C13.25 16.11 13.26 16.23 13.35 16.3L14.3 17.04C14.28 17.18 14.27 17.33 14.27 17.48C14.27 17.63 14.28 17.78 14.3 17.92L13.35 18.66C13.27 18.73 13.24 18.85 13.3 18.95L14.2 20.5C14.25 20.6 14.37 20.63 14.48 20.6L15.59 20.15C15.82 20.33 16.07 20.48 16.35 20.59L16.52 21.77C16.54 21.88 16.63 21.96 16.74 21.96H18.53C18.64 21.96 18.74 21.88 18.75 21.77L18.92 20.59C19.19 20.47 19.45 20.33 19.67 20.15L20.79 20.6C20.89 20.64 21.01 20.6 21.07 20.5L21.97 18.95C22.03 18.86 22 18.74 21.92 18.67ZM17.63 18.83C16.89 18.83 16.28 18.23 16.28 17.48C16.28 16.73 16.88 16.13 17.63 16.13C18.38 16.13 18.98 16.73 18.98 17.48C18.98 18.23 18.37 18.83 17.63 18.83Z' />
        </g>
      </g>
    </svg>
  );
};
