import { VerificationForm as VerificationFormC } from './VerificationForm';

import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';

const mapStateToProps = (state, props) => ({ formAttributes: getFormValues(props.form)(state) });

const createReduxForm = reduxForm({ form: 'verificationForm' });

export const VerificationForm = createReduxForm(connect(mapStateToProps)(VerificationFormC));
