import React, { useEffect } from 'react';
import _ from 'lodash-es';

import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import { CustomReactSelect, FieldWithErrors } from '@packs/components';
import { Avatar } from '@packs/components/UI';
import { ICountry, IUser } from '@packs/interfaces/rootInterfaces';
import { IUserFormAttributes } from '@packs/layouts/AppLayer/components/AuthModal/AuthModal';
import { IRegisterFormProps } from '@packs/layouts/AppLayer/components/AuthModal/components/RegisterForm';
import { SocialButtonGroup } from '@packs/layouts/AppLayer/components/AuthModal/components/SocialButtonGroup';
import useStyles from '@packs/layouts/AppLayer/components/AuthModal/styles';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { MINIMUM_PASSWORD_CHARACTERS } from '@packs/lib/constants';
import { serializeCountryName } from '@packs/models/country/helpers';
import { useStore } from '@packs/stores';
import { getWindow } from '@shared/lib/utils';

import { connect } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';

const validate = ({ email, website, password, firstName, lastName }) => {
  const errors: Record<string, string> = {};

  if (!email) errors.email = 'Required';
  if (!password) {
    errors.password = 'Required';
  } else if (password.length < MINIMUM_PASSWORD_CHARACTERS) {
    errors.password = `Should me more or equal than ${MINIMUM_PASSWORD_CHARACTERS}`;
  }

  if (!firstName) {
    errors.firstName = 'Required';
  }

  if (!lastName) {
    errors.lastName = 'Required';
  }

  if (website && !website.match(/http?s*:\/\/.*/)) errors.website = 'has invalid format';
  return errors;
};

const mapStateToProps = (state, props) => ({ formAttributes: getFormValues(props.form)(state) });

const createReduxForm = reduxForm({ form: 'acceptInvite', validate });

interface IProps extends IRegisterFormProps {
  formType: 'business';
}

export const AcceptInviteForm = createReduxForm(
  connect(mapStateToProps)(
    ({
      handleSubmit,
      formType,
      formAttributes,
      setSocialUser,
      socialUser,
      change,
      setShowSignUpForm,
      showSignUpForm
    }: IProps) => {
      const styles = useStyles();
      const windowWidth = getWindow().innerWidth;
      const { togglePrivacyModal, toggleTermsModal } = useModalsContext();
      const { currentCountry, launchCountries } = useStore();

      const handleSetSocialUser = data => {
        setShowSignUpForm && setShowSignUpForm(true);
        setSocialUser(data);
      };

      useEffect(() => {
        const isRegisterBusiness = formAttributes?.registerBusiness;

        if (formType === 'business') {
          if (!isRegisterBusiness) change('registerBusiness', true);
        } else {
          if (isRegisterBusiness) change('registerBusiness', false);
        }
      }, [formAttributes, formType]);

      const isBusiness = formType === 'business';

      const countryOptions: ICountry[] = launchCountries.map((country: ICountry) => {
        return {
          ...country,
          label: serializeCountryName(country.enName),
          value: country.isoA2Code,
          countryCode: country.isoA2Code
        };
      });

      const currentCountryOption =
        formAttributes?.country || _.filter(countryOptions, { value: currentCountry.isoA2Code }).at(0)!;
      const countryStatesOptions = currentCountryOption.regions.map(option => ({
        label: option.name,
        value: option.name
      }));

      useEffect(() => {
        const region = getWindow().localStorage.getItem('region');
        const prefilledEmail = getWindow().localStorage.getItem('prefilled-email');
        const state = countryStatesOptions.find(({ value }) => value === region);

        if (state) {
          change('state', state);
        }
        if (prefilledEmail) {
          change('email', prefilledEmail);
        }
      }, []);

      return (
        <Box sx={styles.formFieldsContainer}>
          <form onSubmit={handleSubmit}>
            {!showSignUpForm ? (
              <Slide direction='right' in={true} timeout={200}>
                <Box>
                  <Typography variant='h5' sx={styles.formTitle}>
                    Join our community!
                  </Typography>
                  <Typography variant='subtitle1' sx={styles.formSubTitle}>
                    Please select a social network or continue with email.
                  </Typography>
                  <Box sx={styles.socialButtonsContainer}>
                    <SocialButtonGroup change={change} setSocialUser={handleSetSocialUser} />
                    <Box className='social-link email' onClick={() => setShowSignUpForm(true)}>
                      <EmailRoundedIcon />
                      Continue with Email
                    </Box>
                  </Box>
                </Box>
              </Slide>
            ) : (
              <Slide direction='right' in={true} timeout={200}>
                <Box>
                  {socialUser ? (
                    <>
                      {socialUser?.avatarUrl && (
                        <div className='avatar-container d-flex align-items-center justify-content-center'>
                          <Avatar
                            size={70}
                            avatarUrl={socialUser?.avatarUrl}
                            placeholder={socialUser?.firstName + socialUser?.lastName}
                            withBG
                          />
                        </div>
                      )}
                      <Typography variant='h5' sx={styles.formTitle}>
                        {socialUser?.firstName || socialUser?.lastName
                          ? socialUser?.firstName + ' ' + socialUser?.lastName
                          : 'Accept your invitation'}
                      </Typography>
                    </>
                  ) : (
                    <Typography variant='h5' sx={styles.formTitle}>
                      Accept your invitation
                    </Typography>
                  )}

                  <Typography variant='subtitle1' sx={styles.formSubTitle}>
                    Please enter your details below to invitation your account.
                  </Typography>
                  <Box sx={styles.fieldsList}>
                    <Field
                      component={FieldWithErrors}
                      type='text'
                      name='firstName'
                      autoComplete='firstName'
                      placeholder='First name'
                      disableFocusReset
                      material
                      variant='outlined'
                    />
                    <Field
                      component={FieldWithErrors}
                      type='text'
                      name='lastName'
                      autoComplete='lastName'
                      placeholder='Last name'
                      disableFocusReset
                      material
                      variant='outlined'
                    />
                    <Field
                      component={FieldWithErrors}
                      type='email'
                      name='email'
                      autoComplete='name'
                      className='form-input medium white-bg'
                      placeholder={isBusiness ? 'Business email' : 'Email'}
                      material
                      variant='outlined'
                    />
                    <Field
                      component={FieldWithErrors}
                      type='password'
                      name='password'
                      autoComplete='password'
                      className='form-input medium white-bg'
                      placeholder='Password'
                      material
                      variant='outlined'
                    />
                    <Field
                      component={CustomReactSelect}
                      name='country'
                      options={countryOptions}
                      classNamePrefix='Select'
                      placeholder='Select country'
                      isSearchable={windowWidth > 767}
                      material
                    />
                    <Field
                      component={CustomReactSelect}
                      name='state'
                      options={countryStatesOptions}
                      classNamePrefix='Select'
                      placeholder={`Select ${currentCountryOption.regionType || 'region'}`}
                      material
                    />
                    <Typography variant='body3' component='div' sx={styles.termsPolicy}>
                      By continuing, I agree with Bill Winner’s
                      <Typography variant='body3' sx={styles.termsPolicyLink} onClick={() => toggleTermsModal()}>
                        {' Terms of Service '}
                      </Typography>
                      and acknowledge BillWinner`s
                      <Typography variant='body3' sx={styles.termsPolicyLink} onClick={() => togglePrivacyModal()}>
                        {' Privacy Policy '}
                      </Typography>
                      .
                    </Typography>
                  </Box>
                  <Box sx={{ ...styles.btnContainer, mb: 3 }}>
                    <Button type='submit' variant='contained' size='large' fullWidth>
                      Accept
                    </Button>
                  </Box>
                </Box>
              </Slide>
            )}

            {/* {size(socialUser) === 0 && (*/}
            {/*  <>*/}
            {/*    <div className='auth-or'>OR</div>*/}
            {/*    <div className='login-social-list d-flex align-items-center'>*/}
            {/*      <SocialButtonGroup change={change} setSocialUser={setSocialUser} />*/}
            {/*    </div>*/}
            {/*  </>*/}
            {/* )}*/}
          </form>
        </Box>
      );
    }
  )
);
