import React, { useMemo, useRef, useState } from 'react';
import _ from 'lodash-es';
import moment from 'moment';

import CommentRoundedIcon from '@mui/icons-material/CommentRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import { SignInModalLink } from '@packs/components';
import { InsightCompany } from '@packs/components/UI/Insights/InsightCompany';
import { commentableType } from '@packs/lib/constants';
import { divideStarRating } from '@packs/lib/helpers';
import { shortSecondName } from '@packs/lib/helpers/additionalHelpers';
import { avatarPlaceholder, stringToColor } from '@shared/helpers/additionalHelpers';
import { SUB_ICONS } from '@shared/helpers/subCategoryIcon';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { CommentsList } from '../CommentsList/CommentsList';
import { ReviewForm } from '../ReviewForm/ReviewForm';

import useStyles from './styles';

import AnimateHeight from 'react-animate-height';

export const ReviewItem = ({
  insight,
  questionsContainerOffset,
  withOutInsightCompany,
  isProfile = false,
  owner = null,
  insightsCompanyStore,
  colors,
  IS_CRM,
  currentUser,
  toggleSignUpModal,
  index = 0
}) => {
  const styles = useStyles();
  const questionItemRef = useRef(null);
  const descriptionRef = useRef(null);
  const { isMobile } = useWindowSize();
  const {
    id,
    user,
    title,
    category,
    createdAt,
    isHelpful,
    businessUser,
    helpfulCount,
    reviewCommentsCount,
    feedback,
    rating = 0,
    purchaseScore = 0,
    anyCompanyRate = 0,
    anyCompanyRateComment,
    isVote,
    tokenVerified
  } = insight;
  const [isOpenAnswers, setIsOpenAnswers] = useState(false);
  const [descriptionIsOpen, setDescriptionIsOpen] = useState(false);

  const insightOwner = insight.user || {};
  const avatarUrl = businessUser?.company?.avatarUrl || owner?.avatarUrl || user?.avatarUrl || '';
  const displayName = businessUser?.businessName || owner?.displayName || user?.displayName || '';
  const comment = !(isProfile && isVote) ? anyCompanyRateComment || _.get(feedback, 'insightsComment') || '' : '';
  const descriptionSize = comment.length >= (isMobile ? 60 : 214);
  const isCurrentUserPostOwner = currentUser.id === insightOwner?.id;

  const divRating = useMemo(() => {
    return isVote ? divideStarRating(purchaseScore || anyCompanyRate) : divideStarRating(rating);
  }, [rating, purchaseScore]);

  const toggleDescription = () => {
    setDescriptionIsOpen(!descriptionIsOpen);
  };

  const toggleAnswers = () => {
    setIsOpenAnswers(!isOpenAnswers);
  };

  const onToggleHelpful = () => {
    if (!isCurrentUserPostOwner) {
      insightsCompanyStore.toggleHelpfulInsight({
        attrs: {
          feedbackType: 'review',
          renewalDetailId: insight.id
        },
        postId: insight.id
      });
    }
  };

  const submitCallback = () => {
    window.scrollTo({
      top: questionItemRef?.current?.offsetTop + questionItemRef?.current?.clientHeight - 100,
      behavior: 'smooth'
    });
  };

  return (
    <Zoom timeout={500} in={true} style={{ transitionDelay: `${index * 200}ms` }}>
      <Box sx={styles.wrapper} ref={questionItemRef}>
        <Box sx={styles.topSection}>
          <Box sx={styles.userInfoSection}>
            <Avatar
              src={avatarUrl}
              variant='rounded'
              sx={{
                width: '48px',
                height: '48px',
                '& img': { height: '100%' },
                backgroundColor: stringToColor(displayName)
              }}
            >
              {avatarPlaceholder(displayName)}
            </Avatar>
            <Box>
              <Typography variant='body2' sx={{ mb: 1 }}>
                {shortSecondName(displayName)}
              </Typography>
              <Typography variant='body5' sx={{ color: colors.text.secondary }}>
                {(createdAt && moment(createdAt).fromNow()) || '-'}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={styles.ratingCategorySection}>
          <Box sx={styles.ratingWrapper}>
            <Rating value={divRating} max={isMobile ? 1 : 5} readOnly precision={0.5} />
            <Typography variant='body4'>{divRating} out of 5</Typography>
          </Box>
          <Box sx={styles.ratingWrapper}>
            <Box sx={styles.categorySection}>
              {SUB_ICONS({ category, fill: colors.dark.main, size: '16px' })}
              <Typography variant='caption'>{category.name}</Typography>
            </Box>
            {tokenVerified && <Box sx={styles.verified}>Verified</Box>}
          </Box>
        </Box>
        <Box>
          {title && (
            <Typography variant='body2' sx={styles.title}>
              {title}
            </Typography>
          )}
          {comment.length > 0 && (
            <>
              <Typography variant='body1' ref={descriptionRef} sx={styles.comment}>
                {descriptionSize && !descriptionIsOpen ? comment.slice(0, isMobile ? 60 : 214) : comment}
                {descriptionSize && !descriptionIsOpen && (
                  <Typography variant='body2' as='span' onClick={toggleDescription} sx={{ ml: 1, cursor: 'pointer' }}>
                    Read more
                  </Typography>
                )}
              </Typography>
            </>
          )}
        </Box>
        {!withOutInsightCompany && !IS_CRM && (
          <Box>
            <InsightCompany company={insight.anyCompany} category={category} />
          </Box>
        )}
        <Box sx={styles.actionPanelWrapper}>
          <Box sx={styles.actionPanelSection}>
            <Button
              variant='text'
              color='dark'
              size='smallToMedium'
              startIcon={<CommentRoundedIcon />}
              onClick={toggleAnswers}
            >
              {!isMobile && 'Comments '}
              {reviewCommentsCount}
            </Button>
          </Box>
          <Box sx={styles.actionPanelSection}>
            {currentUser?.id ? (
              <Button
                variant='text'
                color={isHelpful ? 'primary' : 'dark'}
                size='smallToMedium'
                startIcon={<ThumbUpAltRoundedIcon />}
                disabled={isCurrentUserPostOwner}
                onClick={onToggleHelpful}
              >
                {!isMobile && 'Helpful'} {helpfulCount || 0}
              </Button>
            ) : (
              <SignInModalLink variant='text' color='dark' size='smallToMedium'>
                {!isMobile && 'Helpful'} {helpfulCount || 0}
              </SignInModalLink>
            )}
          </Box>
        </Box>

        <AnimateHeight duration={250} height={isOpenAnswers ? 'auto' : 0}>
          {!isCurrentUserPostOwner && (
            <ReviewForm
              submitBtnText='Add a comment'
              placeholder='Add a comment...'
              commentableType={commentableType.post}
              postId={id}
              submitCallback={submitCallback}
              createComment={insightsCompanyStore?.createCommentInsight}
              currentUser={currentUser}
              toggleSignUpModal={toggleSignUpModal}
            />
          )}
          <CommentsList
            postId={id}
            isOpen={isOpenAnswers}
            questionsContainerOffset={questionsContainerOffset}
            insightsCompanyStore={insightsCompanyStore}
            currentUser={currentUser}
            toggleSignUpModal={toggleSignUpModal}
          />
        </AnimateHeight>
      </Box>
    </Zoom>
  );
};
