import React from 'react';
import _ from 'lodash-es';
import cx from 'classnames';
import moment from 'moment';

import { useCalendarContext } from '@packs/components/Calendar/CalendarContext';
import { WeekRow } from '@packs/components/Calendar/yearGridCustomPlugin/WeekRow';
import { MONTH_FORMAT } from '@packs/screens/PersonalizeResults/formHooks/defaults';

export const MonthDesktop = ({ date, cells, rowIndex, openPopover, monthIndex, weekData, eventsData }) => {
  const MonthHeader = () => {
    const classes = { 6: 'fc-last', 0: 'fc-first' };
    const allclasses = 'fc-day-header fc-year-weekly-head fc-mon fc-widget-header';

    return (
      <table className='fc-year-month-header'>
        <thead>
          <tr className='fc-year-week-days'>
            {_.map(weekData, ({ date }, inx) => (
              <th key={inx} className={cx(allclasses, `fc-${date.getDay()}`, classes[inx])}>
                {moment(date).format('dd')[0]}
              </th>
            ))}
          </tr>
        </thead>
      </table>
    );
  };

  const eventsDataFiltered = _.pickBy(eventsData, (value, key) => {
    return new Date(key).getMonth() === monthIndex;
  });

  return (
    <>
      <td className='fc-month-view fc-year-monthly-td fc-widget-content'>
        <div className={cx('fc-year-monthly-name', { 'fc-first': rowIndex === 0 })}>
          <span className='month-title'>{moment(date).format(MONTH_FORMAT)}</span>
        </div>

        <div className='fc-row fc-widget-header'>
          <MonthHeader />
        </div>

        <div className='fc-scroller fc-day-grid-container'>
          <div className='fc-day-grid fc-body fc-unselectable'>
            {_.map(cells, (obj, inx) => (
              <WeekRow
                key={inx}
                weekDays={obj}
                openPopover={openPopover}
                trigger='hover'
                eventsData={eventsDataFiltered}
                {...{ inx, monthIndex }}
              />
            ))}
          </div>
        </div>

        <div className='fc-year-monthly-footer'></div>
      </td>

      <td className='fc-year-month-separator'></td>
    </>
  );
};
