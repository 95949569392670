import React, { createContext, useContext } from 'react';

import { RootStore } from '@packs/stores/index';

import { ModalStore } from './ModalStore';

export const StoreContext = createContext<RootStore>({} as RootStore);

export const StoreProvider = ({ children, rootStore }) => {
  return <StoreContext.Provider value={rootStore}>{children}</StoreContext.Provider>;
};

export const useStore = () => useContext(StoreContext);

export class AppRootStore {
  modalStore = new ModalStore();
}
