import React from 'react';

export default () => {
  return (
    <svg width='12' height='6' viewBox='0 0 12 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.9939 5.01562L6.00593 1.00063L0.993942 4.9856'
        stroke='#212529'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
