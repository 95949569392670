export const fetchUserCategories = `
query userCategories($categoryCode: String, $location: String)  {
  userCategories(categoryCode: $categoryCode, location: $location) {
    id
    code
    name
    slag
    isPinned
    root {
      code
      enName
    }
  }
}`.trim();

export const fetchUserCategoriesPricePerPeriod = `
query userCategories($categoryCode: String, $location: String)  {
  userCategories(categoryCode: $categoryCode, location: $location) {
    id
    averagePriceData {
      averageMonthly
      averageYearly
    }
    minPriceData {
      minMonthly
      minYearly
    }
  }
}`;

export const fetchLeaderboardFilters = `
query criteriaQuestionLeaderboardFilters($categoryId: Int!)  {
  criteriaQuestionLeaderboardFilters(categoryId: $categoryId) {
    leaderboardFilters
  }
}`;

export const fetchUserCategoryByPolicy = `
query dashboardDetailPriceData($renewalDetailId: Int!)  {
  dashboardDetailPriceData(renewalDetailId: $renewalDetailId) {
    averagePriceData {
      averageYearly
      averageMonthly
    }
    minPriceData {
      minYearly
      minMonthly
    }
  }
}`;
