import colors from '@packs/assets/theme/base/colors';

const { borderColor, white } = colors;

export default () => ({
  companyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: '16px',
    border: `1px solid ${borderColor.main}`,
    backgroundColor: white.main
  },
  mainSection: {
    display: 'flex',
    alignItems: 'stretch'
  },
  companyInfoSection: {
    flexGrow: 1,
    px: 3,
    pt: 3,
    pb: {
      xs: 2,
      lg: 3
    }
  },
  companyTopInfo: {
    display: 'flex',
    gap: {
      xs: 2,
      lg: 3
    }
  },
  ratingInfoSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 1,
    borderLeft: `1px solid ${borderColor.main}`,
    minWidth: {
      sx: '100%',
      md: '310px',
      lg: '326px'
    },
    p: 3
  },
  additionalInfoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    pt: 2
  },
  additionalItems: {
    display: 'grid',
    gridTemplateColumns: {
      xs: 'repeat(2, 1fr)',
      md: 'repeat(3, 1fr)'
    },
    gap: 2
  },
  additionalItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    mb: 0.5
  },
  ratingItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    mb: 1
  }
});
