import React from 'react';

export const CustomMention = ({ mention, theme, searchValue, isFocused, ...parentProps }) => {
  return (
    <div {...parentProps} className='mentionSuggestionsEntryContainerWrapper' data-mention={mention.title}>
      <div className='mentionSuggestionsEntryContainer d-flex align-items-center'>
        {mention?.avatar && (
          <div className='mentionSuggestionsEntryContainerLeft mr-10'>
            <img src={mention?.avatar} className='mentionSuggestionsEntryAvatar' role='presentation' alt='C' />
          </div>
        )}

        <div className='mentionSuggestionsEntryContainerRight'>
          <div className='mentionSuggestionsEntryText'>{mention.name}</div>
        </div>
      </div>
    </div>
  );
};
