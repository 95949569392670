import React from 'react';

export const BusinessBusinessLoans = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path d='M4.77344 4C3.82144 4 3.04787 4.75941 3.04688 5.69141V11.3496C2.82196 11.496 2.61154 11.6619 2.42188 11.8516L1 13H0V21H1C1 21 1.61316 21.3801 1.91016 21.5781C2.19016 21.7651 2.632 22 3 22L7.08984 21.998C7.68784 21.998 8.27197 21.8467 8.79297 21.5527C10.127 20.7987 12.8189 19.2733 14.2969 18.4043C14.9509 18.0203 15.4176 17.3913 15.5996 16.6543L15.7734 15.9531H21.2734C22.2264 15.9531 23 15.1947 23 14.2617V5.69141C23 4.75941 22.2264 4 21.2734 4H4.77344ZM7.18164 5.95312H18.8613C18.7758 6.18008 18.7467 6.42439 18.7764 6.66508C18.8061 6.90577 18.8938 7.13566 19.0319 7.33502C19.17 7.53439 19.3544 7.69727 19.5693 7.80969C19.7842 7.92211 20.0231 7.98071 20.2656 7.98047C20.5416 7.97957 20.8119 7.90253 21.0469 7.75781V12.1953C20.8117 12.0513 20.5414 11.9749 20.2656 11.9746C20.0235 11.9748 19.785 12.0336 19.5706 12.1461C19.3562 12.2585 19.1722 12.4212 19.0344 12.6203C18.8966 12.8194 18.809 13.0488 18.7792 13.2891C18.7494 13.5294 18.7783 13.7733 18.8633 14H8.40625L9.90039 12.9453C10.7604 12.3713 11.06 11.2923 10.625 10.3613L10.5742 10.2598C10.0992 9.36877 9.07031 8.95406 8.07031 9.28906L5 10.498V7.75977C5.23516 7.90379 5.50549 7.98016 5.78125 7.98047C6.02344 7.98008 6.26193 7.92101 6.4763 7.80833C6.69068 7.69564 6.87456 7.53269 7.0122 7.33342C7.14985 7.13415 7.23716 6.90449 7.26667 6.66411C7.29618 6.42373 7.267 6.17977 7.18164 5.95312ZM13.0293 7.00781C12.2293 7.00781 11.5068 7.32494 10.9688 7.83594C11.5288 8.19694 12.0068 8.69341 12.3398 9.31641L12.4219 9.48047C12.8089 10.3065 12.8924 11.1848 12.7344 12.0078H15.25C15.729 11.4758 16.0293 10.7798 16.0293 10.0078C16.0293 8.35081 14.6863 7.00781 13.0293 7.00781Z' />
      </g>
    </svg>
  );
};
