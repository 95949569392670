import cx from 'classnames';

import { Box, ButtonBase, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { useDetails } from '@packs/models/policy/useDetails';
import { useStore } from '@packs/stores';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import BillBuddyIconV2 from '../../../images/icons/UI/BillBuddyIconV2';

import { observer } from 'mobx-react';

export const BillBuddyButton = observer(() => {
  const { isMobile } = useWindowSize();
  const { leaderboardStore } = useStore();
  const { currentPolicy } = useDetails();

  const onShowBillBuddy = () => {
    leaderboardStore.showBillBuddy(true);
  };

  if (!currentPolicy?.id || isMobile) {
    return null;
  }

  return (
    <ButtonBase
      sx={{
        position: 'fixed',
        top: '380px',
        left: 0,
        background: colors.secondary.main,
        borderBottomLeftRadius: 16,
        borderBottomRightRadius: 16,
        transform: 'translate(-100%) rotate(-90deg)',
        transformOrigin: 'right top',
        px: 2,
        py: 1,
        zIndex: 17
      }}
      onClick={onShowBillBuddy}
    >
      <Stack direction='row' alignItems='center' spacing={1}>
        <BillBuddyIconV2 fill={'#fff'} />
        <Typography color={colors.white.main} noWrap>
          My Reminder
        </Typography>
        {currentPolicy?.smartReminder?.id && currentPolicy?.smartReminder?.status && (
          <Box
            className={cx('reminder-status', currentPolicy?.smartReminder?.id && currentPolicy?.smartReminder?.status)}
          />
        )}
      </Stack>
    </ButtonBase>
  );
});
