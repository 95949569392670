import React from 'react';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { useDetails } from '@packs/models/policy/useDetails';

import { observer } from 'mobx-react';

export const PolicyAlert = observer(() => {
  const { toggleWinnerModal } = useModalsContext();
  const { currentPolicy } = useDetails();

  const onPickWinner = () => {
    toggleWinnerModal({ modalProps: {} });
  };

  if (!currentPolicy?.alertPickWinner) {
    return null;
  }

  return (
    <Alert
      severity='info'
      action={
        <Button variant='contained' color='secondary' onClick={onPickWinner}>
          Pick your winner!
        </Button>
      }
      sx={{ alignItems: 'center' }}
    >
      <Typography>
        You have not chosen your winner. Vote now for your winner! It only takes about 10 seconds to vote!
      </Typography>
    </Alert>
  );
});
