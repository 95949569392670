import colors from '@packs/assets/theme/base/colors';

const { white } = colors;

export default () => ({
  wrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    maxWidth: '100%',
    padding: '16px',
    backgroundColor: white.main,
    zIndex: 100
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 3
  }
});
