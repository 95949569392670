import React from 'react';

export const BankingIncomeProtection = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.188 4.47198L11.188 1.36098C11.705 1.13098 12.295 1.13098 12.812 1.36098L19.812 4.47198C20.535 4.79298 21 5.50998 21 6.29998V11C21 18.83 14.561 22.486 12 23C9.439 22.486 3 18.83 3 11V6.29998C3 5.50898 3.465 4.79298 4.188 4.47198ZM12.2149 5.64844C11.9379 5.64844 11.7129 5.87339 11.7129 6.15039V7.07617C11.3739 7.11917 9.47657 7.47758 9.47657 9.76758C9.47657 11.6065 10.5245 12.1334 11.4668 12.6071C12.2364 12.994 12.9356 13.3455 12.9356 14.3477C12.9356 15.4637 12.2134 15.4902 12.0664 15.4902C12.062 15.4902 12.0567 15.4903 12.0507 15.4904C11.9057 15.4926 11.3159 15.5016 11.1172 14.5684C11.0272 14.1464 10.6371 13.8535 10.2051 13.8535C9.58909 13.8535 9.1457 14.4402 9.3047 15.0352C9.77203 16.7781 11.2478 16.976 11.5548 17.0172C11.5611 17.018 11.5669 17.0188 11.5723 17.0195V17.8496C11.5723 18.1266 11.7972 18.3496 12.0742 18.3496C12.3512 18.3496 12.5742 18.1266 12.5742 17.8496V17.0195C12.9122 16.9775 14.8242 16.6639 14.8242 14.3359C14.8242 12.5381 13.7365 11.9411 12.781 11.4165C12.0326 11.0057 11.3652 10.6394 11.3652 9.77539C11.3652 8.64839 11.9871 8.61328 12.1211 8.61328C12.3121 8.61328 12.7071 8.74417 12.8731 9.45117C12.9741 9.88517 13.3441 10.2031 13.7891 10.2031H13.793C14.4 10.2031 14.8553 9.62802 14.6973 9.04102C14.2673 7.44602 12.9887 7.15647 12.7227 7.10547V6.15039C12.7227 5.87339 12.4977 5.64844 12.2207 5.64844H12.2149Z'
      />
    </svg>
  );
};
