import React from 'react';
import cx from 'classnames';

import RatingNormalIcon from '@packs/images/svg/ui/smile.svg';
import RatingBadIcon from '@packs/images/svg/ui/smile-bad.svg';
import RatingGoodIcon from '@packs/images/svg/ui/smile-good.svg';
import { useDetailsForm } from '@packs/screens/PersonalizeResults/DetailsFormProvider';

import { DebugName } from './debug';

const ratingSteps = 10;
const steps = Array.from(Array(ratingSteps), (_, i) => i + 1);

export const RatingField = props => {
  const { onChange } = useDetailsForm();

  const { name, value } = props;

  if (!name) {
    throw new Error('no name');
  }

  const ratingSmileIcon = index => {
    if (index <= 5) {
      return RatingBadIcon;
    }

    if (index <= 7) {
      return RatingNormalIcon;
    }

    return RatingGoodIcon;
  };

  const onRatingChange = dataValue => {
    onChange({ target: { name, value: dataValue } });
    props.onChange && props.onChange(typeof dataValue === 'object' ? dataValue?.value : dataValue);
  };

  return (
    <div className='field rating-field'>
      <DebugName name={name} />
      <div className='rating-list d-flex align-items-center justify-content-between'>
        {steps.map((item, index) => (
          <div key={item} className='d-flex flex-column align-items-center'>
            <div
              className={cx('rating-item', { 'active theme-bg': value === item })}
              onClick={() => onRatingChange(item)}
            >
              {item}
            </div>
            <div className='rating-smile'>
              <img src={ratingSmileIcon(index)} alt='Smile' />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
