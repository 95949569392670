import React, { useContext } from 'react';

import { useDetailsForm } from '@packs/screens/PersonalizeResults/DetailsFormProvider';

import { DebugName } from './debug';

export const RadioField = props => {
  const { getRef, onChange } = useDetailsForm();
  const { options, name, multiple } = props;

  if (!name) {
    throw new Error('no name');
  }

  const onChangeRadio = value => {
    onChange({ target: { name, multiple, value } }, { isSliderSteps: props.isSliderSteps });
    props.onChange && props.onChange(value);
  };

  return (
    <>
      <DebugName name={name} />
      {options.map(({ value, label }, index) => (
        <div className='field-radio' key={index + `-${value}`}>
          <input
            id={name + `-${value}`}
            {...{ name, value, multiple }}
            ref={getRef}
            onChange={() => onChangeRadio(value)}
            type={multiple ? 'checkbox' : 'radio'}
          />
          <label htmlFor={name + `-${value}`}>
            <span className='label-text'>{label}</span>
          </label>
        </div>
      ))}
    </>
  );
};
