import colors from '@packs/assets/theme/base/colors';

const { grey, green, text } = colors;

export default () => ({
  content: {
    p: 2,
    backgroundColor: grey[50]
  },
  listItem: {
    alignItems: 'center',
    py: 1,
    px: 0
  }
});
