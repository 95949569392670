import React from 'react';

import { Rate } from './components';

import { bool, func, object } from 'prop-types';

const propTypes = {
  input: object.isRequired,
  disabled: bool,
  onTrigger: func
};

const RateField = ({
  input: { value, onChange },
  disabled,
  onTrigger,
  controlError,
  touched,
  likeText,
  unLikeText,
  anyTouched,
  meta: { submitFailed }
}) => {
  value = value || undefined;

  return (
    <Rate
      {...{
        onChange,
        value,
        disabled,
        onTrigger,
        controlError,
        touched,
        likeText,
        unLikeText,
        submitFailed
      }}
    />
  );
};
RateField.propTypes = propTypes;

export default RateField;
