import colors from '@packs/assets/theme/base/colors';

const { borderColor } = colors;

export default () => ({
  wrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '53px',
    borderBottom: `1px solid ${borderColor.main}`,
    px: {
      xs: 2,
      md: 3
    }
  },
  text: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  label: {
    position: 'absolute',
    top: '-5px',
    left: '-8px',
    zIndex: 1
  }
});
