import React from 'react';

export default () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18.9728 7.27976L8.80857 17.3312C7.12441 18.9968 4.3936 18.9968 2.70945 17.3312C1.02529 15.6656 1.02529 12.9655 2.70945 11.2999L11.8578 2.2537C12.9808 1.14352 14.8006 1.14352 15.9237 2.2537C17.0467 3.36385 17.0467 5.16441 15.9237 6.27456L6.77529 15.3208C6.21415 15.8763 5.30387 15.8763 4.74201 15.3208C4.18086 14.7661 4.18086 13.8658 4.74201 13.3104L12.8737 5.26933L11.8571 4.26413L3.7261 12.3058C2.60309 13.416 2.60309 15.2166 3.7261 16.3267C4.84911 17.4369 6.66894 17.4369 7.79195 16.3267L16.9403 7.28047C18.6245 5.61489 18.6245 2.9148 16.9403 1.24919C15.2561 -0.416395 12.5254 -0.416395 10.8412 1.24919L1.1845 10.798L1.21949 10.833C-0.534654 13.0633 -0.380445 16.2874 1.69282 18.3371C3.76609 20.3868 7.02518 20.5403 9.28119 18.8047L9.31618 18.8397L19.9888 8.28567L18.9728 7.27976Z'
        fill='black'
      />
    </svg>
  );
};
