export const getWindow = () => {
  return typeof window !== 'undefined'
    ? window
    : {
        innerWidth: 0,
        localStorage: {
          getItem: () => null,
          setItem: () => null,
          removeItem: () => null
        },
        location: {
          pathname: '/'
        },
        document: Document
      };
};
