import React from 'react';

export default () => {
  return (
    <svg width='160' height='32' viewBox='0 0 160 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M47.211 24.2734H42.5969C41.8946 24.2734 41.4241 24.2256 41.1853 24.1298C40.9465 24.0341 40.8271 23.8837 40.8271 23.6786V10.4888C40.8271 9.83241 42.8568 9.50421 46.916 9.50421C49.2477 9.50421 50.8559 9.72301 51.7408 10.1606C52.6397 10.6119 53.2156 11.1863 53.4684 11.8837C53.6089 12.294 53.6791 12.7726 53.6791 13.3196C53.6791 14.3863 53.4895 15.1863 53.1103 15.7196C52.7451 16.2529 52.3448 16.5538 51.9094 16.6222C52.5695 16.7589 53.1173 17.0734 53.5527 17.5657C53.9881 18.0581 54.2059 18.8922 54.2059 20.0683C54.2059 21.4358 53.7775 22.4478 52.9207 23.1042C52.1762 23.6786 51.0385 24.041 49.5075 24.1914C48.8614 24.2461 48.0959 24.2734 47.211 24.2734ZM47.2321 15.3298C47.9484 15.3298 48.3066 14.8649 48.3066 13.935C48.3066 13.47 48.2083 13.1281 48.0116 12.9093C47.829 12.6769 47.6183 12.5606 47.3796 12.5606H46.7054V15.3298H47.2321ZM46.7054 21.217H47.2321C47.9484 21.217 48.3066 20.7521 48.3066 19.8222C48.3066 19.3572 48.2083 19.0153 48.0116 18.7965C47.829 18.564 47.6183 18.4478 47.3796 18.4478H46.7054V21.217Z'
        fill='black'
        fillOpacity='0.87'
      />
      <path
        d='M61.1651 13.6683V23.7811C61.1651 23.9316 60.7578 24.0546 59.9432 24.1504C59.1425 24.2324 58.3138 24.2734 57.457 24.2734C56.6143 24.2734 56.0665 24.2392 55.8137 24.1709C55.5749 24.0888 55.4555 23.9589 55.4555 23.7811V14.0375C55.4555 13.7367 55.8488 13.4427 56.6354 13.1555C57.436 12.8546 58.3068 12.7042 59.2479 12.7042C60.5261 12.7042 61.1651 13.0256 61.1651 13.6683ZM58.2998 8.27344C59.2268 8.27344 59.9502 8.41019 60.4699 8.68369C61.0036 8.94352 61.2705 9.42216 61.2705 10.1196C61.2705 11.3504 60.2873 11.9657 58.3209 11.9657C56.3544 11.9657 55.3712 11.3504 55.3712 10.1196C55.3712 8.88882 56.3474 8.27344 58.2998 8.27344Z'
        fill='black'
        fillOpacity='0.87'
      />
      <path
        d='M68.4257 9.21703V23.7811C68.4257 23.9316 68.0254 24.0546 67.2247 24.1504C66.4241 24.2324 65.5954 24.2734 64.7386 24.2734C63.8959 24.2734 63.3481 24.2392 63.0952 24.1709C62.8565 24.0888 62.7371 23.9589 62.7371 23.7811V9.58626C62.7371 9.2854 63.1304 8.99139 63.9169 8.70421C64.7175 8.41703 65.5743 8.27344 66.4873 8.27344C67.7796 8.27344 68.4257 8.58797 68.4257 9.21703Z'
        fill='black'
        fillOpacity='0.87'
      />
      <path
        d='M75.5858 9.21703V23.7811C75.5858 23.9316 75.1855 24.0546 74.3848 24.1504C73.5842 24.2324 72.7555 24.2734 71.8987 24.2734C71.056 24.2734 70.5082 24.2392 70.2554 24.1709C70.0166 24.0888 69.8972 23.9589 69.8972 23.7811V9.58626C69.8972 9.2854 70.2905 8.99139 71.077 8.70421C71.8777 8.41703 72.7345 8.27344 73.6474 8.27344C74.9397 8.27344 75.5858 8.58797 75.5858 9.21703Z'
        fill='black'
        fillOpacity='0.87'
      />
      <path
        d='M84.2029 24.0649C83.5304 24.0649 83.0891 24.016 82.8789 23.9182C82.6687 23.8204 82.5216 23.6528 82.4376 23.4154L77.793 10.6349C77.793 10.3695 78.2904 10.0622 79.2851 9.71305C80.2939 9.36386 81.3097 9.18926 82.3325 9.18926C83.6495 9.18926 84.35 9.60131 84.4341 10.4254L85.1487 17.067H85.4429L86.0313 10.4254C86.1014 9.60131 87.0471 9.18926 88.8685 9.18926C89.5691 9.18926 90.0384 9.28704 90.2766 9.48259C90.5148 9.66417 90.6479 9.9086 90.6759 10.2159L91.3695 17.067H91.6637L92.2101 10.4254C92.2522 10.0203 92.7285 9.71305 93.6392 9.50354C94.5639 9.29402 95.4746 9.18926 96.3713 9.18926C97.282 9.18926 97.8985 9.32196 98.2208 9.58734C98.557 9.85273 98.7252 10.1321 98.7252 10.4254L94.2277 23.4154C94.0736 23.8484 93.0368 24.0649 91.1173 24.0649C90.5428 24.0649 90.1575 24.016 89.9614 23.9182C89.7652 23.8204 89.6321 23.6528 89.5621 23.4154L88.4692 19.7698H88.217L87.1032 23.4154C86.9771 23.8484 86.0103 24.0649 84.2029 24.0649Z'
        fill='#F8B500'
      />
      <path
        d='M105.442 13.2329V23.562C105.442 23.7157 105.036 23.8414 104.224 23.9392C103.425 24.023 102.598 24.0649 101.744 24.0649C100.903 24.0649 100.357 24.0299 100.104 23.9601C99.8662 23.8763 99.7471 23.7436 99.7471 23.562V13.61C99.7471 13.3028 100.139 13.0024 100.924 12.7091C101.723 12.4018 102.591 12.2482 103.53 12.2482C104.805 12.2482 105.442 12.5764 105.442 13.2329ZM102.584 7.72266C103.509 7.72266 104.231 7.86233 104.749 8.14169C105.281 8.40707 105.548 8.89594 105.548 9.6083C105.548 10.8654 104.567 11.4939 102.605 11.4939C100.644 11.4939 99.663 10.8654 99.663 9.6083C99.663 8.3512 100.637 7.72266 102.584 7.72266Z'
        fill='#F8B500'
      />
      <path
        d='M119.683 17.109V23.562C119.683 23.7157 119.284 23.8414 118.485 23.9392C117.687 24.023 116.86 24.0649 116.005 24.0649C115.165 24.0649 114.618 24.0299 114.366 23.9601C114.128 23.8763 114.009 23.7436 114.009 23.562V16.8994C114.009 16.2709 113.981 15.8588 113.925 15.6633C113.883 15.4677 113.841 15.2931 113.799 15.1395C113.701 14.8741 113.526 14.7414 113.273 14.7414C112.881 14.7414 112.685 14.93 112.685 15.3071V23.562C112.685 23.7157 112.286 23.8414 111.487 23.9392C110.702 24.023 109.883 24.0649 109.028 24.0649C108.187 24.0649 107.641 24.0299 107.389 23.9601C107.137 23.8763 107.01 23.7436 107.01 23.562V13.2329C107.01 12.9396 107.41 12.6463 108.208 12.3529C109.021 12.0596 109.876 11.913 110.772 11.913C111.669 11.913 112.173 12.1714 112.286 12.6882L112.475 13.61H112.622C113.028 12.7999 113.729 12.2342 114.723 11.913C115.13 11.7873 115.578 11.7244 116.069 11.7244C117.484 11.7244 118.436 12.1504 118.927 13.0024C119.431 13.8405 119.683 15.2093 119.683 17.109Z'
        fill='#F8B500'
      />
      <path
        d='M133.824 17.109V23.562C133.824 23.7157 133.425 23.8414 132.626 23.9392C131.828 24.023 131.001 24.0649 130.146 24.0649C129.306 24.0649 128.759 24.0299 128.507 23.9601C128.269 23.8763 128.15 23.7436 128.15 23.562V16.8994C128.15 16.2709 128.122 15.8588 128.066 15.6633C128.024 15.4677 127.982 15.2931 127.94 15.1395C127.841 14.8741 127.666 14.7414 127.414 14.7414C127.022 14.7414 126.826 14.93 126.826 15.3071V23.562C126.826 23.7157 126.426 23.8414 125.628 23.9392C124.843 24.023 124.024 24.0649 123.169 24.0649C122.328 24.0649 121.782 24.0299 121.53 23.9601C121.277 23.8763 121.151 23.7436 121.151 23.562V13.2329C121.151 12.9396 121.551 12.6463 122.349 12.3529C123.162 12.0596 124.017 11.913 124.913 11.913C125.81 11.913 126.314 12.1714 126.426 12.6882L126.616 13.61H126.763C127.169 12.7999 127.87 12.2342 128.864 11.913C129.271 11.7873 129.719 11.7244 130.209 11.7244C131.624 11.7244 132.577 12.1504 133.068 13.0024C133.572 13.8405 133.824 15.2093 133.824 17.109Z'
        fill='#F8B500'
      />
      <path
        d='M140.819 19.3298C140.819 20.2377 141.254 20.6917 142.122 20.6917C142.627 20.6917 143.285 20.5241 144.098 20.1888C144.911 19.8396 145.338 19.665 145.38 19.665C145.646 19.665 145.996 20.0631 146.431 20.8593C146.865 21.6554 147.082 22.2421 147.082 22.6192C147.082 23.1639 146.522 23.576 145.401 23.8553C144.28 24.1347 143.18 24.2744 142.101 24.2744C141.037 24.2744 140.14 24.1906 139.411 24.023C138.697 23.8553 138.108 23.6458 137.646 23.3944C137.198 23.129 136.798 22.8078 136.448 22.4306C136.112 22.0535 135.86 21.6973 135.691 21.3621C135.523 21.0129 135.39 20.6288 135.292 20.2098C135.152 19.5952 135.082 18.9597 135.082 18.3032C135.082 15.7611 135.747 14.0081 137.079 13.0443C137.737 12.5555 138.473 12.2133 139.285 12.0177C140.098 11.8222 141.058 11.7244 142.164 11.7244C145.709 11.7244 147.482 12.9536 147.482 15.4119C147.482 18.0238 145.492 19.3298 141.513 19.3298H140.819ZM140.777 16.2709V17.6537C141.184 17.6537 141.513 17.4931 141.765 17.1718C142.031 16.8505 142.164 16.4595 142.164 15.9985C142.164 15.1605 141.947 14.7414 141.513 14.7414C141.023 14.7414 140.777 15.2512 140.777 16.2709Z'
        fill='#F8B500'
      />
      <path
        d='M154.41 17.4442V23.562C154.41 23.7157 154.01 23.8414 153.212 23.9392C152.427 24.023 151.607 24.0649 150.753 24.0649C149.912 24.0649 149.366 24.0299 149.113 23.9601C148.861 23.8763 148.735 23.7436 148.735 23.562V13.2329C148.735 12.9675 149.12 12.6812 149.891 12.3739C150.676 12.0666 151.523 11.913 152.434 11.913C153.359 11.913 153.877 12.1644 153.989 12.6672L154.199 13.631H154.346C154.543 13.1002 155.012 12.6533 155.755 12.2901C156.497 11.913 157.24 11.7244 157.982 11.7244C158.725 11.7244 159.243 11.8431 159.538 12.0806C159.846 12.318 160 12.6253 160 13.0024C160 13.3656 159.972 13.8335 159.916 14.4062C159.86 14.9789 159.72 15.6004 159.495 16.2709C159.285 16.9413 159.005 17.2766 158.655 17.2766C158.515 17.2766 158.073 17.1718 157.331 16.9623C156.588 16.7528 155.972 16.648 155.481 16.648C155.005 16.648 154.704 16.7179 154.578 16.8575C154.466 16.9972 154.41 17.1928 154.41 17.4442Z'
        fill='#F8B500'
      />
      <path
        d='M30.4 1.6H25.6V0H6.4V1.6H1.6C0.64 1.6 0 2.24 0 3.2V7.04C0 10.72 2.72 13.76 6.4 14.24V14.4C6.4 19.04 9.6 22.88 13.92 23.84L12.8 27.2H9.12C8.48 27.2 7.84 27.68 7.68 28.32L6.4 32H25.6L24.32 28.32C24.16 27.68 23.52 27.2 22.88 27.2H19.2L18.08 23.84C22.4 22.88 25.6 19.04 25.6 14.4V14.24C29.28 13.76 32 10.72 32 7.04V3.2C32 2.24 31.36 1.6 30.4 1.6ZM6.4 11.04C4.64 10.56 3.2 8.96 3.2 7.04V4.8H6.4V11.04ZM19.2 16L16 14.24L12.8 16L13.6 12.8L11.2 9.6H14.56L16 6.4L17.44 9.6H20.8L18.4 12.8L19.2 16ZM28.8 7.04C28.8 8.96 27.36 10.72 25.6 11.04V4.8H28.8V7.04Z'
        fill='#F8B500'
      />
    </svg>
  );
};
