import React from 'react';

const PlusIcon = ({ fill }) => {
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' xmlns='http://www.w3.org/2000/svg' fill='none'>
      <circle cx='12.5' cy='12.5' r='12' stroke={fill || '#1E2030'} />
      <path
        d='M17.1873 11.8295H13.1707V7.81279C13.1707 7.4425 12.8702 7.14199 12.4998 7.14199C12.1295 7.14199 11.829 7.44251 11.829 7.81279V11.8295H7.81231C7.44203 11.8295 7.1415 12.13 7.1415 12.5003C7.1415 12.8707 7.44201 13.1712 7.81231 13.1712H11.829V17.1878C11.829 17.5582 12.1295 17.8587 12.4998 17.8587C12.8702 17.8587 13.1707 17.5582 13.1707 17.1878V13.1712H17.1873C17.5577 13.1712 17.8582 12.8707 17.8582 12.5003C17.8582 12.13 17.5577 11.8295 17.1873 11.8295Z'
        fill={fill || '#1E2030'}
        stroke={fill || '#1E2030'}
        strokeWidth='0.3'
      />
    </svg>
  );
};

export default PlusIcon;
