const companyFragment = `{
  id
  name
  companyType
  avatarUrl
}`;

const priceFragment = `
  price {
    period
    value
  }
`;

const smartReminderFragment = `{
  id
  aliasName
  triggeredAt
  name
  status
  categoryId
  category {
    id
    enName
    code
    root {
      id
      enName
      code
    }
  }
  recurringTime
  isAutoQuote
  quoteRequest {
    phoneNumber
  }
}`;

export const fetchRenewalDetailQuery = `
  query renewalDetail($renewalDetailId: Int ) {
    renewalDetail(renewalDetailId: $renewalDetailId) {
      id
      policyType
      buyingType
      lastBuy
      year
      firstTime
      alertPickWinner
      preference {
        id
        status
        searchCriteria
        policyId
      }
      criteriaQuestions
      pricesQuestions {
        ${priceFragment}
      }
      discountPrice {
        period
        periodStartDate
        periodEndDate
        priceAfter
      }
      advancedPrice {
        electricity { 
          dayPrice
          nightPrice
          standingPrice
          includingTaxes
        }
        gas {
          dayPrice
          nightPrice
          standingPrice
          includingTaxes
        }
      }
      anyCompanyRate
      anyCompanyRateComment
      sourceProviderCompanyRate
      sourceProviderCompanyRateComment
      updatedAt
      overallRate
      anyCompany ${companyFragment}
      sourceProviderCompany ${companyFragment}
      smartReminder ${smartReminderFragment}
      purchaseScore
      purchaseComment
    }
  }
`;
