import React from 'react';

export const BusinessBusinessInterruptionInsurance = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <g>
          <path d='M11 8.89474H13V7.94737H11V8.89474Z' />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M4.188 4.47198L11.188 1.36098C11.705 1.13098 12.295 1.13098 12.812 1.36098L19.812 4.47198C20.535 4.79298 21 5.50998 21 6.29998V11C21 18.83 14.561 22.486 12 23C9.439 22.486 3 18.83 3 11V6.29998C3 5.50898 3.465 4.79298 4.188 4.47198ZM14 8.89474H16C16.555 8.89474 17 9.31632 17 9.8421V15.0526C17 15.5784 16.555 16 16 16H8C7.445 16 7 15.5784 7 15.0526L7.005 9.8421C7.005 9.31632 7.445 8.89474 8 8.89474H10V7.94737C10 7.42158 10.445 7 11 7H13C13.555 7 14 7.42158 14 7.94737V8.89474Z'
          />
        </g>
      </g>
    </svg>
  );
};
