import React, { useEffect, useState } from 'react';
import _, { get, isEmpty } from 'lodash-es';
import cx from 'classnames';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { InfoComponent } from '@packs/components';
import RangeSelect from '@packs/components/RangeInput/RangeSelect';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { PersonalizeModalHeader } from '@packs/screens/PersonalizeResults/components/PersonalizeModalHeader';
import { useDetailsForm } from '@packs/screens/PersonalizeResults/DetailsFormProvider';
import RangeFieldAdapter from '@packs/screens/PersonalizeResults/formFields/RangeFieldAdapter';
import { scrollToTopPersonalize } from '@packs/screens/PersonalizeResults/helpers';
import { useStore } from '@packs/stores/base';
import { getWindow } from '@shared/lib/utils';

import { ActionPanel } from '../components/ActionPanel/ActionPanel';
import { FieldContainer } from '../FieldContainer';
import { AssociationField } from '../formFields/AssociationField';
import { DateField } from '../formFields/DateField';
import { InputField } from '../formFields/InputField';
import { InputPercentageField } from '../formFields/InputPercentageField';
import { LocationField } from '../formFields/LocationField';
import { PriceCriteriaPerPeriod } from '../formFields/PriceCriteriaPerPeriod';
import { RadioField } from '../formFields/RadioField';
import { RatingField } from '../formFields/RatingField';
import { SelectField } from '../formFields/SelectField';
import { isValid } from '../formHooks/validate';

import { observer } from 'mobx-react';

export const MyCriteria = observer(
  ({
    step,
    setStep,
    remount,
    category,
    localData,
    percentage,
    formErrors,
    formValues,
    currentUser,
    hideCliteria,
    simpleForm = false,
    inModalFrame = false,
    handleCloseModal = () => {}
  }) => {
    const windowWidth = getWindow().innerWidth;
    const { detailsFormStore, policiesListStore, currentCountry, countryCode } = useStore();
    const appContext = useModalsContext();
    const [currentStep, setCurrentStep] = useState(1);
    const [rerender, setRerender] = useState(false);
    const [locals] = useState({ nextStep: 1 });

    const { showSkipButton, formOptions } = detailsFormStore;
    const { categoryCriteriaQuestions } = useDetailsForm();
    const { pricesQuestionsOptions } = policiesListStore;

    let criteriaOptions = categoryCriteriaQuestions
      .filter(cqv => cqv.type && !hideCliteria[cqv.name] && detailsFormStore.criteriaVisibleByConditions(cqv))
      .map(qv => ({ attrName: `criteriaQuestions.${qv.name}`, ...qv }));
    criteriaOptions = _.sortBy(criteriaOptions, 'index');

    const priceOptions = pricesQuestionsOptions?.price
      ? [
          {
            ...pricesQuestionsOptions.price,
            attrName: 'pricesQuestions.price',
            name: 'price',
            id: 99999
          }
        ]
      : [];
    const allOptions = [...criteriaOptions, ...priceOptions];
    const questionsCount = allOptions.length;

    const stepCriteria = allOptions[currentStep - 1];
    const getAtt = stepCriteria?.attrName;
    const getDataMultiple = stepCriteria?.multiple;
    const getType = stepCriteria?.type;

    const disabled = simpleForm && formErrors[getAtt] && !get(showSkipButton, getAtt);

    const skipButton =
      (simpleForm && (getAtt === 'criteriaQuestions.companiesClients' || get(showSkipButton, getAtt))) ||
      (getAtt === 'pricesQuestions.price' && category?.root?.code === 'energy');
    const showButton =
      getType === 'savingMetrePrice' ||
      getType === 'number' ||
      getType === 'string' ||
      getType === 'textarea' ||
      getType === 'occupationSelector' ||
      getType === 'association' ||
      getType === 'priceType' ||
      getType === 'pricePerPeriod' ||
      getType === 'priceInTheCriteria' ||
      getDataMultiple ||
      get(showSkipButton, getAtt) ||
      (get(formValues, getAtt, null) !== null && locals.nextStep === currentStep); // wait for change effect

    const handleNextQuestion = () => {
      locals.nextStep = currentStep + 1;

      if (currentStep < questionsCount) {
        setCurrentStep(currentStep + 1);
      }
    };

    const handlePrevQuestion = () => {
      locals.nextStep = currentStep - 1;

      if (currentStep >= 2) {
        setCurrentStep(currentStep - 1);
      } else {
        setStep(step - 1);
      }
    };

    const initialState = () => {
      locals.nextStep = 1;
      setCurrentStep(1);
    };

    useEffect(() => {
      scrollToTopPersonalize();

      if (isEmpty(categoryCriteriaQuestions) && isEmpty(pricesQuestionsOptions) && simpleForm) {
        setStep(step + 1);
      }

      return initialState();
    }, []);

    useEffect(() => {
      if (simpleForm) {
        isValid(localData, simpleForm);
      }
    }, [getAtt, formValues]);

    const handleChange = qv => value => {
      if (
        (value || value === false) &&
        qv.type !== 'pricePerPeriod' &&
        qv.type !== 'string' &&
        qv.type !== 'number' &&
        !getDataMultiple
      ) {
        handleNextQuestion();
      }
    };

    const animateActiveStep = () => {
      // animate active step, ( animations not works due render )
      setTimeout(() => {
        document.querySelector('.policy-form .field-container').classList.add('active');
      }, 1);
    };

    useEffect(() => {
      if (simpleForm && locals.nextStep === currentStep) {
        animateActiveStep();
      }
    }, [getAtt, get(formValues, getAtt), simpleForm]);

    useEffect(() => {
      if (simpleForm) {
        animateActiveStep();
      }
    }, []);

    const types = {
      select: SelectField,
      radio: RadioField,
      star: RatingField,
      datetime: DateField,
      // subquestions: Subquestions,
      location: LocationField,
      savingMetrePrice: InputField,
      number: InputField,
      // eslint-disable-next-line id-denylist
      string: InputField,
      carMake: SelectField,
      carModel: SelectField,
      petType: SelectField,
      carTrim: SelectField,
      bikeTrim: SelectField,
      bikeMake: SelectField,
      bikeModel: SelectField,
      mobileMake: SelectField,
      mobileModel: SelectField,
      autocomplete: SelectField,
      childrenCount: SelectField,
      priceType: InputField,
      pricePerPeriod: PriceCriteriaPerPeriod,
      textarea: InputField,
      // header: HeaderCriteria,
      occupationSelector: InputField,
      association: AssociationField,
      percentage: InputPercentageField,
      range: RangeFieldAdapter,
      rangeWithInput: RangeFieldAdapter
    };

    const ButtonsGroup = () => {
      if (!simpleForm) {
        return null;
      }

      return (
        <>
          {(showButton || currentStep === questionsCount) && (
            <div className='policy-steps-controller d-flex align-items-center'>
              <Button
                variant='contained'
                color='primary'
                size='large'
                disabled={disabled}
                onClick={() => {
                  if (!disabled) {
                    if (currentStep === questionsCount) {
                      setStep(step + 1);
                    } else {
                      handleNextQuestion();
                    }
                  }
                }}
              >
                {skipButton ? 'Next / Skip' : 'Next'}
              </Button>
            </div>
          )}
        </>
      );
    };

    return (
      <div className='policy-from-wrapper'>
        {inModalFrame && <ActionPanel handleBack={handlePrevQuestion} />}
        <div className='form-inner-wrapper'>
          <div className='policy-form'>
            {_.map(allOptions, (question, index) => {
              const { type, title, label, name, attrName, options, multiple, description } = question;

              if (simpleForm && currentStep !== index + 1) {
                return null;
              }

              const storedOptions = (get(formOptions, attrName) || []).slice();
              const Component = types[type] || 'div';

              let storedOptionsTransformed = storedOptions || [];

              if (name === 'mobileMake') {
                storedOptionsTransformed = [{ value: 'not_remember', label: "I don't remember." }, ...options];
              }

              const claimTitle = formValues.anyCompanyId && !formValues.sourceProviderCompanyId ? 'broker' : 'provider';
              const filedTitle =
                (title || label) &&
                (title || label)
                  .replace(':category_name', _.startCase(category.name))
                  .replace(':buyingType', claimTitle);

              const showError =
                _.get(formErrors, name) ||
                _.get(formErrors, attrName) ||
                _.get(formErrors, `${attrName}.period`) ||
                _.get(formErrors, `${attrName}.value`);
              const isLoading = get(detailsFormStore.loadingsComponentOptions, attrName);
              const isWideInput = ['pricePerPeriod', 'datetime'].includes(type);

              return (
                <FieldContainer
                  error={showError}
                  remount={remount}
                  key={question.id}
                  type={type}
                  multiple={multiple}
                  className={cx({ active: !!get(formValues, getAtt) })}
                >
                  <Typography
                    variant={inModalFrame ? 'h3' : 'h6'}
                    sx={{ textAlign: inModalFrame ? 'center' : 'left' }}
                    className={cx(description ? 'mb-8' : 'mb-32')}
                  >
                    {types[type] === 'location' ? 'Please select your location' : filedTitle}
                  </Typography>
                  {description && (
                    <div className='description d-flex align-items-center gap-8 mb-32'>
                      <span className='fs-19 secondary-text'>What’s this?</span>
                      <InfoComponent popoverId='what-is' placement='top' dark large>
                        <div>{description}</div>
                      </InfoComponent>
                    </div>
                  )}
                  <div className={cx('field-types-list', question.className, { wide: isWideInput })}>
                    <Component
                      {...{
                        question,
                        multiple,
                        type,
                        category,
                        currentCountry,
                        currentUser,
                        appContext,
                        countryCode,
                        setRerender,
                        rerender,
                        formValues
                      }}
                      numeric={type === 'priceType'}
                      withDot={type === 'priceType'}
                      value={formValues?.criteriaQuestions[name] || null}
                      defaultValue={formValues?.criteriaQuestions[name] || null}
                      name={attrName}
                      isSliderSteps={simpleForm}
                      isLoading={isLoading}
                      options={(storedOptions.length && storedOptionsTransformed) || options || []}
                      minLabel='Very poor'
                      maxLabel='Excellent'
                      swipePicker={windowWidth < 768}
                      onChange={handleChange(question)}
                      // search
                    />
                  </div>
                  {showError && <div className='field-error'>Required</div>}
                  <ButtonsGroup />
                </FieldContainer>
              );
            })}
          </div>
        </div>

        {inModalFrame && (
          <PersonalizeModalHeader
            {...{
              category,
              handleCloseModal,
              percentage
            }}
            handleBack={handlePrevQuestion}
          />
        )}
      </div>
    );
  }
);
