export const satisfactionOptions = [
  {
    label: 'Excellent',
    value: 'excellent'
  },
  {
    label: 'Great',
    value: 'great'
  },
  {
    label: 'Good',
    value: 'good'
  },
  {
    label: 'Fair',
    value: 'fair'
  },
  {
    label: 'Poor',
    value: 'poor'
  }
];

export const REVIEWS_PER_PAGE = 10;
