import React, { useState } from 'react';
import { map } from 'lodash-es';

import companyApi from '@packs/apis/company';
import { toastr } from '@packs/lib/helpers/index';

import { cantRemember } from '../../screens/PersonalizeResults/stepComponents/MyProvider';

import { createFilter } from 'react-select';

export const useCompanyFilterOptions = ({
  category,
  buyingType,
  allTypeOptions,
  value,
  isSetCallback,
  isFetchCompanies
}) => {
  const insuranceCategory = category?.root?.code === 'insurance';
  const sourceFormType = buyingType !== 'provider';
  const providerFormType = buyingType === 'provider';
  const companyType = providerFormType ? buyingType : undefined;
  const [options, setOptions] = useState([]);

  const makeFilterOption = companyType => (option, search) => {
    const isMatch = createFilter({
      ignoreCase: true,
      ignoreAccents: true,
      trim: true,
      matchFromStart: false,
      stringify: option => `${option.label.split(' ').join('')}`
    })(option, search);

    const sourceSelect = companyType !== 'provider';
    const providerSelect = companyType === 'provider';

    if (!companyType) {
      return isMatch;
    }

    if (companyType === 'provider' && insuranceCategory && buyingType === 'provider') {
      // all matched mixed options for insurance
      return isMatch;
    } else if (search.length > 0 && ((providerFormType && providerSelect) || (sourceFormType && sourceSelect))) {
      // all matched mixed options for case when user search some
      return isMatch;
    }

    return (isMatch && option.companyType === companyType) || option.value === 'i_cant_remember';
  };

  const loadFilterOption = makeFilterOption(companyType);

  const defaultOptions = isFetchCompanies ? options : allTypeOptions.filter(option => loadFilterOption(option, ''));

  const makeOptions = search => {
    let options = defaultOptions;

    if (search && search.length > 0) {
      options = allTypeOptions.filter(option => loadFilterOption(option, search));
    }

    options = options.sort((a, b) => {
      const matcher = new RegExp(`^${search}`);

      const nameA = a.label.toLowerCase();
      const nameB = b.label.toLowerCase();
      if (nameA === nameB) return 0;
      if (nameA.match(matcher) && nameB.match(matcher)) {
        return nameA > nameB ? -1 : 1;
      } else if (nameA.match(matcher) && !nameB.match(matcher)) {
        return -1;
      } else if (!nameA.match(matcher) && nameB.match(matcher)) {
        return 1;
      } else if (!nameA.match(matcher) && !nameB.match(matcher)) {
        return nameA > nameB ? -1 : 1;
      }
    });

    return options;
  };

  const loadOptions = async (query, callback) => {
    if (isFetchCompanies) {
      const isInitFetch = query?.length === 0;
      const withCompanyId = value && value !== 'i_cant_remember' && isInitFetch ? value : undefined;

      // load all company types but sort providers first then by alphabetical
      const resp = await companyApi.companiesAutocompleateListSelect({
        categoryId: category.id,
        keyWord: query,
        perPage: isInitFetch ? 50 : 10,
        withCompanyId,
        companyType
      });

      if (resp.errors?.length) {
        toastr.error(map(resp.errors, 'message').join(', '));
        return [];
      }
      const opts = resp.companies.map(c => ({ value: c.id, label: c.name, ...c }));

      // callback for react-select-virtualized (promise does not work)
      if (isSetCallback) {
        callback(opts);
      }
      // return opts for promise
      return [cantRemember, ...opts];
    } else {
      const options = makeOptions(query);
      if (isSetCallback) {
        callback(options);
      }
      return [cantRemember, ...options];
    }
  };

  const formatOptionLabel = ({ id, name, avatarUrl, companyType }) => {
    return (
      <div className='d-flex align-items-center'>
        {avatarUrl && (
          <div className='option-avatar'>
            <img src={avatarUrl} width={50} alt='Company' className='option-avatar' />
          </div>
        )}
        <span className='fs-12 fw-500'>{name}</span>
      </div>
    );
  };

  return {
    formatOptionLabel,
    loadOptions,
    defaultOptions,
    makeOptions,
    loadFilterOption,
    makeFilterOption
  };
};

export const fetchCompanyFilter = (query, value, categoryId, buyingType) => {
  const isInitFetch = query?.length === 0;
  const withCompanyId = value && value !== 'i_cant_remember' && isInitFetch ? value : undefined;
  const providerFormType = buyingType === 'provider';
  const companyType = providerFormType ? buyingType : undefined;

  // load all company types but sort providers first then by alphabetical
  return companyApi.companiesAutocompleateListSelect({
    categoryId,
    keyWord: query,
    perPage: isInitFetch ? 50 : 10,
    withCompanyId,
    companyType
  });
};
