import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const BadgeSimple = ({ icon, label }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 0.5,
        minHeight: '24px',
        borderRadius: '4px',
        px: 0.5,
        backgroundColor: 'rgba(0, 0, 0, 0.08)'
      }}
    >
      {icon}
      {label && <Typography variant='caption'>{label}</Typography>}
    </Box>
  );
};
