import React from 'react';

export const GeneralTakeAways = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path d='M9.99999 9H4.99999V5.5C4.99999 4.119 6.11899 3 7.49999 3C8.88099 3 9.99999 4.119 9.99999 5.5V9ZM14.207 4.565C13.407 4.565 12.664 4.779 12 5.125V9H18.982C18.786 6.522 16.736 4.565 14.207 4.565ZM18.504 11H5.37099C4.71499 11 4.23299 11.617 4.39299 12.253L5.03699 14.821L4.17799 18.551C3.89099 19.804 4.84199 21 6.12799 21H17.919C19.143 21 20.079 19.911 19.897 18.701L19.095 13.396L19.458 12.334C19.682 11.68 19.196 11 18.504 11Z' />
      </g>
    </svg>
  );
};
