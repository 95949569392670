import React, { useState } from 'react';

import Box from '@mui/material/Box';
import DocumentIcon from '@packs/images/icons/Document';

import useStyles from './styles';

import { Lightbox } from 'react-modal-image';

export const FilesPreview = ({ files }) => {
  const styles = useStyles();
  const [openImage, setOpenImage] = useState(null);

  const openLightbox = file => {
    setOpenImage(file);
  };

  const closeLightbox = () => {
    setOpenImage(null);
  };
  return (
    <Box sx={styles.wrapper}>
      {files.map(({ url, contentType, filename }) => {
        const isImage = contentType?.match(/image/) && contentType !== 'image/svg+xml';

        return (
          <Box
            key={url}
            sx={styles.filePreview}
            onClick={() => {
              if (isImage) {
                openLightbox({ url, filename });
              }
            }}
          >
            {isImage ? (
              <img src={url} alt={filename} />
            ) : (
              <a href={url} target='_blank' rel='noreferrer'>
                <DocumentIcon />
              </a>
            )}
          </Box>
        );
      })}
      {openImage && (
        <Lightbox medium={openImage.url} large={openImage.url} alt={openImage.filename} onClose={closeLightbox} />
      )}
    </Box>
  );
};
