import React from 'react';

const InfoIconLarge = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.0088 11.2461H12.1593V15.4979'
        stroke='#332F53'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.9998 15.5002H13.3101'
        stroke='#332F53'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.1002 8.2424C12.1002 8.38052 11.9882 8.4925 11.8501 8.4925C11.7119 8.4925 11.6 8.38052 11.6 8.2424C11.6 8.10427 11.7119 7.99229 11.8501 7.99229'
        stroke='#332F53'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.85 7.99234C11.9881 7.99234 12.1001 8.10432 12.1001 8.24245'
        stroke='#332F53'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <rect
        x='2.99634'
        y='2.99219'
        width='18.0075'
        height='18.0075'
        rx='5'
        stroke='#332F53'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default InfoIconLarge;
