import React from 'react';

export const Local_servicesRealEstateAgentsPropertyAgents = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <g>
          <path d='M20.5859 13C20.2239 13 19.8619 13.1381 19.5859 13.4141L15 18H11C10.724 18 10.5 17.776 10.5 17.5C10.5 17.224 10.724 17 11 17H13.5C14.328 17 15 16.328 15 15.5C15 14.672 14.328 14 13.5 14H8.65625C7.59525 14 6.57812 14.4209 5.82812 15.1719L2 19L6 23L8 21H15.1719C15.7019 21 16.2109 20.7891 16.5859 20.4141L21.5859 15.4141C22.1379 14.8621 22.1379 13.9661 21.5859 13.4141C21.3099 13.1381 20.9479 13 20.5859 13Z' />
          <path d='M11.0854 11.5V8.67697H13.9115V11.5C13.9115 11.8963 14.2294 12.2205 14.618 12.2205H16.7375C17.1261 12.2205 17.444 11.8963 17.444 11.5V7.23596H18.6451C18.9701 7.23596 19.1255 6.82528 18.8783 6.60913L12.9718 1.18373C12.7033 0.938757 12.2935 0.938757 12.0251 1.18373L6.11859 6.60913C5.87837 6.82528 6.02674 7.23596 6.35174 7.23596H7.55282V11.5C7.55282 11.8963 7.87075 12.2205 8.25933 12.2205H10.3789C10.7675 12.2205 11.0854 11.8963 11.0854 11.5Z' />
        </g>
      </g>
    </svg>
  );
};
