import _ from 'lodash-es';
import moment from 'moment';

import { selectOptions as dateSelectOptions } from './dateTimeFunctions';

export const setInitValues = (node, { renewalDetail, rootMobxStore }) => {
  const { detailsFormStore } = rootMobxStore;
  const { formOptions, formValues, formErrors, clear } = detailsFormStore;

  const name = node.name;
  const type = node.type;
  let options = [];

  if (name === 'criteriaQuestions.location') {
    if (node) node.options = _.get(formOptions, 'criteriaQuestions.location');
  }

  if (name === 'triggeredAtDate.month') {
    options = dateSelectOptions(moment().add(1, 'day'));
    node.options = options.yearMonth;
  }

  if (name === 'triggeredAtDate.day') {
    options = dateSelectOptions(moment().add(1, 'year'));
    node.options = options.day;
  }

  if (name === 'criteriaQuestions.make') {
    if (node) node.options = _.get(formOptions, 'criteriaQuestions.make', []);
  }

  if (name === 'criteriaQuestions.model') {
    if (node) node.options = _.get(formOptions, 'criteriaQuestions.model', []);
  }

  if (name === 'criteriaQuestions.mobileMake') {
    const options = [
      { value: 'not_remember', label: "I don't remember." },
      ..._.get(formOptions, 'criteriaQuestions.mobileMake', [])
    ];

    if (node) node.options = options;
  }

  if (name === 'criteriaQuestions.mobileModel') {
    const options = [
      { value: 'not_remember', label: "I don't remember." },
      ..._.get(formOptions, 'criteriaQuestions.mobileModel', [])
    ];
    if (node) node.options = options;
  }

  if (name === 'criteriaQuestions.companiesClients') {
    if (node) node.options = _.get(formOptions, 'criteriaQuestions.companiesClients', []);
  }

  if (_.get(formValues, name) !== undefined || clear || (type === 'select' && !name.match(/triggeredAtDate/))) {
    const value = _.get(formValues, name);
    const radioValue = node.value === `${value}`;

    if (type === 'radio') {
      node.checked = radioValue;
    } else if (type === 'checkbox') {
      if (node.multiple) {
        node.checked = _.includes(value, node.value);
      } else {
        node.checked = value;
      }
    } else if (type === 'text') {
      if (!node.defaultValue) {
        node.defaultValue = value;
      }
    } else {
      node.value = value;
    }
  } else if (name === 'addSmartReminder') {
    node.checked = !renewalDetail?.smartReminder?.id; // !!renewalDetail?.smartReminder?.id;
  } else if (name === 'policySelect') {
    node.value = renewalDetail.id || 'new-detail';
  }
};

export const getTargetValue = target => {
  let value;

  if (target.type === 'radio') {
    value = target.value;
  } else if (target.type === 'checkbox') {
    value = target.checked;
  } else {
    value = target.value;
  }
  return value;
};
