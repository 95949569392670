//
// ACTION TYPES
//

export const EXCLUDED_VIEW_CATEGORIES = ['government'];

export const EXCLUDED_CATEGORIES = ['government', 'waste_collection', 'general'];

export const EXCLUDED_CATEGORIES_NEW_CATEGORIES = ['banking', 'waste_collection', 'general'];

export const EXCLUDED_SUB_CATEGORIES = ['certificates_deposit', 'deposits', 'prepaid_cards', 'protection'];

export const COMING_SOON_CATEGORIES = ['local_services', 'travel', 'food'];

export const AVAILABLE_CATEGORIES = {
  banking: [
    'mortgages',
    'investments',
    'pensions',
    'savings',
    'accounts',
    'account_us',
    'loans',
    'credit_cards',
    'mortgages_protection',
    'income_protection'
  ]
};

export const NO_WIKI_TABLE_CATEGORIES = ['insurance', 'business'];

export const WIKI_TABLE_CATEGORIES = [
  'business_broadband_subcat',
  'business_mobile',
  'business_electricity',
  'business_gas',
  'business_gas_and_electricity'
];

export const COMPANY_PER_MONTH = [
  'broadband',
  'mobile',
  'subscriptions',
  'loans',
  'mortgages',
  'accounts',
  'business_broadband_subcat',
  'business_mobile'
];
export const COMPANY_PER_YEAR = ['anti_virus'];
export const NO_PERSONALIZATION_CATEGORIES = ['subscriptions', 'investments'];

export const CATEGORY_PER_MONTH = [
  'gadget_insurance',
  'health_insurance',
  'pet_insurance',
  'life_insurance',
  'bike_insurance',
  'broadband',
  'mobile',
  'energy',
  'subscriptions',
  'business_broadband_subcat',
  'business_mobile',
  'mortgages',
  'pensions',
  'income_protection',
  'mortgages_protection'
];
