import React, { FC } from 'react';
import cx from 'classnames';

type LoaderProps = {
  local?: boolean;
  theme?: string;
};
export const Loader: FC<LoaderProps> = ({ local, theme }) => {
  return (
    <div className={cx('preloader-container', { local })}>
      <div className={cx('circle-loader', { theme, local })}>
        <div className='circle' />
        <div className='circle' />
        <div className='circle' />
        <div className='circle' />
        <div className='circle' />
      </div>
    </div>
  );
};
