import React from 'react';

import { Box, Button, ButtonProps, FormHelperText, Grid, Radio, Stack, Typography } from '@mui/material';
import colors from '@packs/assets/theme/base/colors';
import { BusinessVoteModalForm } from '@packs/components/BusinessVoteModal/BusinessVoteModal';
import { LAST_BUY_OPTIONS } from '@packs/models/policy/constants';

import { Controller, useFormContext } from 'react-hook-form';

const LastBuy = () => {
  const { control } = useFormContext<BusinessVoteModalForm>();

  return (
    <Stack spacing={3}>
      <Controller
        name='lastBuy'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Box>
            <Typography variant='body2' sx={{ mb: 1 }}>
              When did you buy from this business?
            </Typography>
            <Grid container spacing={2}>
              {LAST_BUY_OPTIONS.map(option => (
                <Grid key={option.value} item xs={12} md={4}>
                  <Button
                    variant='outlined'
                    color={'defaultGrey' as ButtonProps['color']}
                    fullWidth
                    startIcon={<Radio sx={{ p: 0 }} disableRipple checked={field.value === option.value} />}
                    onClick={() => field.onChange(option.value)}
                    sx={{
                      px: 2,
                      borderColor: field.value === option.value ? colors.primary.main : colors.borderColor.main,
                      justifyContent: 'flex-start'
                    }}
                  >
                    {option.label}
                  </Button>
                </Grid>
              ))}
            </Grid>
            <FormHelperText>{error?.message}</FormHelperText>
          </Box>
        )}
      />
    </Stack>
  );
};

export default LastBuy;
