import React, { ElementType, FC } from 'react';

import { Box, Button, ButtonProps } from '@mui/material';
import useSignUpModal from '@packs/components/SignUpModalLink/useSignUpModal';

interface ISignUpModalLinkProps extends ButtonProps {
  validate?: () => boolean;
  bussiness?: Record<string, string>;
  onSignUpSuccess?: () => void;
  authModalCall?: string;
  skipCategories?: boolean;
  skipRedirect?: boolean;
  component?: ElementType<any> | (ElementType<any> & undefined);
}

export const SignUpModalLink: FC<ISignUpModalLinkProps> = ({
  disabled,
  children,
  validate,
  bussiness,
  onClick,
  onSignUpSuccess,
  authModalCall = '',
  skipCategories = true,
  skipRedirect = false,
  variant = 'contained',
  color = 'primary',
  component = null,
  sx,
  ...rest
}) => {
  const handleToggleSignUpModal = useSignUpModal({
    authModalCall,
    skipCategories,
    skipRedirect,
    bussiness
  });

  const buttonProps = {
    ...rest,
    sx: {
      ...sx,
      cursor: 'pointer'
    },
    variant,
    color,
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      onClick && onClick(e);

      if (disabled) {
        return;
      }

      if (validate) {
        if (validate()) {
          handleToggleSignUpModal(onSignUpSuccess);
        }
      } else {
        handleToggleSignUpModal(onSignUpSuccess);
      }
    }
  };

  if (component) {
    return (
      <Box component={component} {...buttonProps} {...{ disabled }}>
        {children}
      </Box>
    );
  }

  return (
    <Button {...{ disabled }} {...buttonProps}>
      {children}
    </Button>
  );
};
