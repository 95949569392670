import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

import useStyles from './styles';

export const TypeFilter = ({ title = 'Filter by message types', options, value, onChange }) => {
  const styles = useStyles();

  const onChooseType = option => () => {
    onChange(option);
  };

  return (
    <Box>
      <Typography variant='h5' sx={styles.title}>
        {title}
      </Typography>
      <Box sx={styles.filtersWrapper}>
        {options.map(option => (
          <Chip
            key={option.value}
            label={option.label}
            onClick={onChooseType(option)}
            {...{ ...(option.value === value.value ? { color: 'primary' } : {}) }}
          />
        ))}
      </Box>
    </Box>
  );
};
