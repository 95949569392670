import React, { FC } from 'react';

import { Button, Stack } from '@mui/material';
import { FieldWithErrors } from '@packs/components';

import { Field, reduxForm } from 'redux-form';

type Props = { handleSubmit: (form: any) => void; inProcess: boolean };

const Form: FC<Props> = ({ handleSubmit, inProcess }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={3}>
        <Field
          name='password'
          component={FieldWithErrors}
          type='password'
          label='New password'
          disableFocusReset
          material
          variant='outlined'
        />
        <Field
          name='passwordConfirmation'
          component={FieldWithErrors}
          type='password'
          label='Confirm new password'
          disableFocusReset
          material
          variant='outlined'
        />
      </Stack>
      <Button type='submit' variant='contained' size='large' fullWidth disabled={inProcess} sx={{ mt: 3 }}>
        Create
      </Button>
    </form>
  );
};

const validate = ({ password, passwordConfirmation }) => {
  const errors: { password?: string; passwordConfirmation?: string } = {};

  if (!password) {
    errors.password = 'Field is required!';
  }

  if (!passwordConfirmation) {
    errors.passwordConfirmation = 'Field is required!';
  }

  if (password && passwordConfirmation && password !== passwordConfirmation) {
    errors.passwordConfirmation = 'Passwords do not match';
  }

  return errors;
};

const createReduxForm = reduxForm({ form: 'userSettingsUpdate', validate });

export default createReduxForm(Form);
