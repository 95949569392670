import React, { useEffect, useRef, useState } from 'react';

import { Box, InputAdornment, Slider, Stack, TextField, Typography } from '@mui/material';
import NumberFormat from '@packs/components/MaterialUi/NumberFormat/NumberFormat';

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, value]);

  return debouncedValue;
}

const RangeSelect = ({
  label = '',
  valueLabel = '',
  marks = [],
  defaultValue,
  value,
  onChange,
  minValue,
  maxValue,
  step,
  withInput = false
}) => {
  const hasMarks = marks.length > 0;

  const [val, setVal] = useState(() => parseInt(value || defaultValue, 10));

  const debouncedVal = useDebounce(val, 500);

  const min = minValue ? parseInt(minValue, 10) : undefined;
  const max = maxValue ? parseInt(maxValue, 10) : undefined;

  const inited = useRef(false);

  useEffect(() => {
    if (!inited.current) {
      inited.current = true;
      return;
    }

    onChange(debouncedVal);
  }, [debouncedVal]);

  function getSelectValueLabel(options, value) {
    const find = options.find(el => el.value === value.toString());
    if (!find) {
      return `${value}${valueLabel ? ` ${valueLabel}` : ''}`;
    }
    return find.label;
  }

  return (
    <Box>
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='body6'>{label}</Typography>
        {!withInput && <Typography variant='body6'>{hasMarks ? getSelectValueLabel(marks, val) : val}</Typography>}
      </Stack>
      {withInput && (
        <NumberFormat
          fullWidth
          size='small'
          sx={{ mt: 1 }}
          value={val}
          InputProps={{
            startAdornment: valueLabel ? <InputAdornment position='start'>{valueLabel}</InputAdornment> : undefined
          }}
          onChange={e => {
            const tmpVal = e.target.value;
            if (tmpVal) {
              const intTmpVal = parseInt(tmpVal, 10);
              if (intTmpVal < min) {
                setVal(min);
              } else if (intTmpVal > max) {
                setVal(max);
              } else {
                setVal(intTmpVal);
              }
            }
          }}
        />
      )}
      <Slider
        sx={{ mt: 1 }}
        valueLabelDisplay='auto'
        min={min}
        max={max}
        step={step ? parseInt(step, 10) : 1}
        value={val}
        marks={marks}
        onChange={(_, tmpVal) => {
          setVal(tmpVal);
        }}
      />
    </Box>
  );
};

export default RangeSelect;
