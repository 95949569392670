import { useStore } from '@packs/stores';

export const useCategories = () => {
  const { categoryStore } = useStore();

  return {
    currentCategory: categoryStore.currentCategory,
    categories: categoryStore.sortedCategories,
    categoriesOptions: categoryStore.categoriesOptions,
    flattenCategories: categoryStore.flattenCategories,
    flattenCategoriesOptions: categoryStore.flattenCategoriesSortedOptions,
    rootCategoriesOptions: categoryStore.categories.map(({ id, name, code, root }) => ({
      id,
      value: code,
      label: name,
      rootCategory: root,
      rootCode: (root && root.code) || undefined,
      code
    }))
  };
};
