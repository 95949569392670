import React, { Fragment } from 'react';
import cx from 'classnames';

import PropsTypes from 'prop-types';

const propTypes = {
  input: PropsTypes.object.isRequired,
  meta: PropsTypes.object.isRequired,
  currencySymbol: PropsTypes.string.isRequired,
  disabled: PropsTypes.bool,
  withDot: PropsTypes.bool,
  incrementStep: PropsTypes.number,
  symbolsAfterDot: PropsTypes.number,
  maxValue: PropsTypes.number,
  placeholder: PropsTypes.string
};

const defaultProps = {
  withDot: false,
  disabled: false
};

const PercentageField = ({
  input,
  meta: { touched, error },
  currencySymbol,
  disabled,
  placeholder,
  className,
  handleChange = null
}) => {
  const onInputChange = data => {
    input.onChange(data);
    if (handleChange) handleChange(data, input.name);
  };

  const onChangeHandler = ({ target: { value } }) => {
    if (!(value <= 100)) {
      return false;
    }

    onInputChange(value);
  };

  return (
    <Fragment>
      <div className={cx('price-input')}>
        <input
          type='number'
          pattern='^([0-9]*[,])?[0-9]*$'
          className={cx('reminder-input', className)}
          name={input.name}
          {...{
            disabled,
            placeholder,
            value: input.value
          }}
          onChange={onChangeHandler}
          onBlur={onChangeHandler}
        />
        <span className='price-currency'>{currencySymbol}</span>
      </div>
      {touched && error && <span className='error-hint'>{error}</span>}
    </Fragment>
  );
};
PercentageField.propTypes = propTypes;
PercentageField.defaultProps = defaultProps;

export default PercentageField;
