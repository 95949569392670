import React, { useLayoutEffect, useRef, useState } from 'react';

import { Box, Typography } from '@mui/material';
import colors from '@packs/assets/theme/base/colors';
import pxToRem from '@shared/assets/theme/functions/pxToRem';

export const SentimentAnalysisMetric = ({ sentimentAnalysis }) => {
  const widthPerc = sentimentAnalysis?.score || 0;

  const ref = useRef();

  const [parrentWidth, setParrentWidth] = useState(0);

  useLayoutEffect(() => {
    if (ref?.current) {
      setParrentWidth(ref?.current.offsetWidth);
    }
  }, [ref]);

  const arcWidth = 16;
  const textLabelHeight = 24;

  const radius = parrentWidth / 2 - arcWidth / 2;

  const height = radius + arcWidth + textLabelHeight;
  const cx = radius + arcWidth / 2;
  const cy = radius + arcWidth / 2;

  const dashArray = (Math.PI * radius * widthPerc) / 100;
  const dashArrayBG = Math.PI * radius;

  return (
    <Box sx={{ position: 'relative', width: '100%' }} ref={ref}>
      <svg width={parrentWidth} height={height}>
        <circle
          cx={cx}
          cy={cy}
          r={radius}
          fill='none'
          strokeWidth={arcWidth}
          strokeLinecap='round'
          strokeDashoffset={-1 * Math.PI * radius}
          strokeDasharray={`${dashArrayBG} 10000`}
          stroke={'#e5e5e5'}
        />
        <circle
          cx={cx}
          cy={cy}
          r={radius}
          fill='none'
          strokeWidth={arcWidth}
          strokeLinecap='round'
          strokeDashoffset={-1 * Math.PI * radius}
          strokeDasharray={`${dashArray} 10000`}
          stroke={'url(#score-gradient)'}
        />

        <defs>
          <linearGradient id='score-gradient'>
            <stop offset='0%' stopColor='#F05450' />
            <stop offset='27%' stopColor='#FDA625' />
            <stop offset='50%' stopColor='#FFEE58' />
            <stop offset='73%' stopColor='#9DCC65' />
            <stop offset='100%' stopColor='#67BB6A' />
          </linearGradient>
        </defs>
      </svg>
      <Box sx={{ position: 'absolute', top: height / 2, left: '50%', transform: 'translateX(-50%)' }}>
        <Typography variant='body2' sx={{ fontSize: pxToRem(64) }}>
          {widthPerc}
        </Typography>
      </Box>
      <Box sx={{ position: 'absolute', bottom: 0, left: 0 }}>
        <Typography variant='body2' color={colors.text.secondary}>
          0
        </Typography>
      </Box>
      <Box sx={{ position: 'absolute', bottom: 0, right: 0 }}>
        <Typography variant='body2' color={colors.text.secondary}>
          100
        </Typography>
      </Box>
      <Box sx={{ position: 'absolute', bottom: 0, left: '50%', transform: 'translateX(-50%)' }}>
        <Typography variant='subtitle1' sx={{ textTransform: 'uppercase' }}>
          {widthPerc >= 50 ? 'Positive' : 'Negative'}
        </Typography>
      </Box>
    </Box>
  );
};
