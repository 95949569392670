import React from 'react';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { pathGen } from '@packs/layouts/constants';
import { useStore } from '@packs/stores';

import useStyles from './styles';

import size from 'lodash-es/size';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

export const RequestQuotesBar = observer(
  ({ onCancel, submitButton, sx = {}, disableRemove = false, onBack = null }) => {
    const { leaderboardStore } = useStore();
    const styles = useStyles();

    if (size(leaderboardStore.requestBusinesses) === 0) {
      return null;
    }

    const handleDelete = company => () => {
      leaderboardStore.removeRequestBusiness(company);
    };

    const handleClearAll = () => {
      onCancel();
    };

    return (
      <Slide direction='down' in={true}>
        <Box sx={{ ...styles.wrapper, ...sx }}>
          <Box sx={styles.wrapperInner}>
            <Box>
              <Box sx={styles.titleWrapper}>
                <Typography variant='h6' sx={{ mb: 1 }}>
                  {size(leaderboardStore.requestBusinesses)} of 3 Selected quotes!
                </Typography>

                {!onBack && (
                  <IconButton onClick={handleClearAll} size='small' sx={styles.titleClose}>
                    <CloseRoundedIcon />
                  </IconButton>
                )}
              </Box>
              <Stack direction='row' sx={styles.list}>
                {leaderboardStore.requestBusinesses.map(company => (
                  <Chip
                    key={company.id}
                    label={
                      <Stack direction='row' spacing={1}>
                        <Avatar sx={{ width: 24, height: 24 }} variant='rounded' src={company?.avatarUrl} />
                        <Typography variant='body4'>{company?.name}</Typography>
                      </Stack>
                    }
                    sx={styles.chip}
                    onDelete={!disableRemove ? handleDelete(company) : null}
                  />
                ))}
              </Stack>
            </Box>
            <Box sx={styles.btnContainer}>
              {!!onBack && (
                <Button variant='text' color='dark' size='medium' onClick={onBack} sx={styles.submit}>
                  Back
                </Button>
              )}
              {!!onCancel && (
                <Button variant='text' color='dark' size='medium' onClick={handleClearAll} sx={styles.cancel}>
                  Cancel
                </Button>
              )}
              {!!submitButton && submitButton({ sx: styles.submit })}
            </Box>
          </Box>
        </Box>
      </Slide>
    );
  }
);
