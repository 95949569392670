import { useStore } from '@packs/stores';

export const useDetails = () => {
  const { policyStore, policiesListStore } = useStore();

  return {
    currentPolicy: policyStore.currentPolicy,
    formOptions: policyStore.formOptions,
    userRenewalDetails: policiesListStore.userRenewalDetails
  };
};
