import React from 'react';

export const InsuranceTenantContentInsurance = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <path d='M21.2782 17.1446L15.4452 19.769C14.8648 20.025 14.1797 20.073 13.5612 19.889L5.80615 17.6087L2 18.6568V12.8881L5.89179 12.12L5.94888 12.112C6.2058 12.04 6.49126 12 6.77672 12C7.14401 12 7.48562 12.076 7.80724 12.1832L7.82341 12.184L8.21354 12.328L13.3994 14.2243C14.37 14.5843 14.8077 15.5364 14.37 16.3445L14.094 16.8726L14.4461 17.2647L20.0697 15.0964C20.26 15.0244 20.4503 14.9924 20.6406 14.9924C21.1545 14.9924 21.6397 15.2324 21.8681 15.6485C22.1821 16.2005 21.9157 16.8566 21.2782 17.1446Z' />
      <path d='M15.7268 6.40083C15.151 4.78711 13.4539 3.7013 11.5567 4.07323C10.1687 4.34918 9.04138 5.44699 8.75045 6.82076C8.25344 9.14236 10.0354 11.2 12.3022 11.2C13.8842 11.2 15.2298 10.1982 15.7268 8.80041H18.3633V10.0002C18.3633 10.6601 18.9088 11.2 19.5756 11.2C20.2423 11.2 20.7878 10.6601 20.7878 10.0002V8.80041C21.4545 8.80041 22 8.26051 22 7.60062C22 6.94074 21.4545 6.40083 20.7878 6.40083H15.7268ZM12.3022 8.80041C11.6355 8.80041 11.09 8.26051 11.09 7.60062C11.09 6.94074 11.6355 6.40083 12.3022 6.40083C12.969 6.40083 13.5145 6.94074 13.5145 7.60062C13.5145 8.26051 12.969 8.80041 12.3022 8.80041Z' />
    </svg>
  );
};
