export const popularCategories = [
  'car_insurance',
  'home_insurance',
  'mortgages',
  'energy',
  'broadband',
  'mobile',
  'savings',
  'loans',
  'credit_cards',
  'mortgages_protection',
  'life_insurance',
  'business_electricity'
];

export const rootCategoriesLanding = ['broadband', 'energy', 'mobile'];

export const categoriesWithoutSimpleForm = [
  'business_electricity',
  'business_gas',
  'business_gas_and_electricity',
  'savings',
  'life_insurance',
  'mortgages_protection',
  'income_protection',
  'loans'
];

export const categoriesWithoutPersonalizeFlow = ['subscriptions'];
export const subCategoriesWithoutPersonalizeFlow = [
  'credit_cards',
  'deposits',
  'investments',
  'pensions',
  'prepaid_cards',
  'holiday_home_insurance',
  'business_subscriptions',
  'business_cards',
  'business_deposits',
  'business_interruption_insurance',
  'card_payment_solutions',
  'business_electricity',
  'corporate_pensions',
  'cyber_insurance',
  'e_payments',
  'international_payments'
];

export const myResultsFiltersByCategory = ['mobile'];
export const myResultsFiltersBySubCategory = ['mortgages', 'loans'];
