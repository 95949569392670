import React, { useEffect, useState } from 'react';
import _ from 'lodash-es';
import cx from 'classnames';

import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { renewalDetailApi } from '@packs/apis';
import { BusinessVoteModalForm } from '@packs/components/BusinessVoteModal/BusinessVoteModal';
import DateField from '@packs/components/DateField';
import { FieldWithErrors } from '@packs/components/FieldWithErrors';
import { RadioButtonField } from '@packs/components/RadioButtonField';
import { StarRateField } from '@packs/components/RatingFormData/components';
import { SelectField } from '@packs/components/SelectField';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { locationOptions } from '@packs/screens/PersonalizeResults/formHooks/options';
import { useStore } from '@packs/stores';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { MacScrollbar } from 'mac-scrollbar';
import { observer } from 'mobx-react';
import { Controller, useFormContext } from 'react-hook-form';

const inputTypeFields = ['savingMetrePrice', 'string', 'priceType', 'textarea', 'occupationSelector', 'percentage'];
const selectTypeFields = ['location', 'select', 'carMake', 'carModel', 'petType', 'autocomplete', 'childrenCount'];

const types = {
  select: SelectField,
  radio: RadioButtonField,
  star: StarRateField,
  location: SelectField,
  savingMetrePrice: FieldWithErrors,
  // eslint-disable-next-line id-denylist
  string: FieldWithErrors,
  carMake: SelectField,
  carModel: SelectField,
  petType: SelectField,
  autocomplete: SelectField,
  childrenCount: SelectField,
  priceType: FieldWithErrors,
  textarea: FieldWithErrors,
  occupationSelector: FieldWithErrors,
  datetime: DateField,
  number: FieldWithErrors,
  carTrim: SelectField,
  bikeTrim: SelectField,
  bikeMake: SelectField,
  bikeModel: SelectField,
  mobileMake: SelectField,
  mobileModel: SelectField,
  pricePerPeriod: FieldWithErrors,
  percentage: FieldWithErrors
};

const VoteCriteria = observer(({ company, voteCriteriaQuestions }) => {
  const { currentUser } = useUserSelectors();
  const { countryCode, currentCountry, currentCategory } = useStore();
  const { isMobile } = useWindowSize();

  const { control, watch } = useFormContext<BusinessVoteModalForm>();

  const criteriaQuestions = watch('criteriaQuestions');

  return (
    <Stack spacing={1}>
      <MacScrollbar
        style={{ height: '400px' }}
        trackStyle={horizontal => ({
          [horizontal ? 'height' : 'width']: 0,
          right: 2,
          border: 0
        })}
        thumbStyle={horizontal => ({ [horizontal ? 'height' : 'width']: 4 })}
        suppressAutoHide
      >
        {voteCriteriaQuestions.map(question => {
          const {
            type,
            title,
            label,
            options,
            withDot,
            placeholder,
            currencySymbol,
            symbolsAfterDot,
            name,
            voteForm,
            multiple
          } = question;

          const Component = types[type] || 'div';

          if (!voteForm || !type || (type === 'childrenCount' && criteriaQuestions?.typeOfCoverRequired !== 'family')) {
            return null;
          }

          const filedTitle =
            (title || label) &&
            (title || label)
              .replace(':category_name', _.startCase(currentCategory.name))
              .replace(':buyingType', company.companyType);
          const inputField = inputTypeFields.includes(type);
          const selectField = selectTypeFields.includes(type);
          let selectOptions = options || [];
          if (type === 'location') {
            selectOptions = locationOptions(currentCountry);
          }

          return (
            <Box key={name} sx={{ mb: 2 }}>
              <Typography variant='body2' sx={{ mb: 1 }}>
                {types[type] === 'location' ? 'Please select your location' : filedTitle}
              </Typography>
              <Box sx={{ w: 1, maxWidth: 410 }}>
                <Controller
                  name={`criteriaQuestions.${name}`}
                  control={control}
                  render={({ field, fieldState: { isTouched, error } }) => (
                    <Component
                      {...{
                        type,
                        question,
                        withDot,
                        placeholder,
                        countryCode,
                        currentUser,
                        currentCountry,
                        currencySymbol,
                        symbolsAfterDot
                      }}
                      input={{ onChange: field.onChange, value: field.value }}
                      meta={{ touched: isTouched, error: error?.message }}
                      category={currentCategory}
                      className={cx(
                        'question-field-element',
                        inputField && 'form-input with-border',
                        selectField && 'select-ui with-light-border gray-bg'
                      )}
                      innerClassName={type === 'number' && 'form-input with-border'}
                      classNamePrefix='Select'
                      component={Component}
                      name={`criteriaQuestions.${name}`}
                      options={selectOptions}
                      minLabel='Very poor'
                      maxLabel='Excellent'
                      swipePicker={isMobile}
                      customValue={criteriaQuestions[name] || ''}
                      multiple={multiple}
                      material
                    />
                  )}
                />
              </Box>
            </Box>
          );
        })}
      </MacScrollbar>
    </Stack>
  );
});

export default VoteCriteria;
