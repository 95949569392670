import React from 'react';
import { toJS } from 'mobx';
import _ from 'lodash-es';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { AutocompleteMUI } from '@packs/components/MaterialUi/AutocompleteMUI/AutocompleteMUI';
import { MaterialRadioGroup } from '@packs/components/MaterialUi/MaterialRadioGroup/MaterialRadioGroup';
import RangeSelect from '@packs/components/RangeInput/RangeSelect';
import { useDetails } from '@packs/models/policy/useDetails';
import { useStore } from '@packs/stores';

import { observer } from 'mobx-react';

const allOption = { value: 'all', label: 'All' };

export const DashboardCriteriaFilters = observer(({ disabled = false, onChange, sidebarFilter = false }) => {
  const { companiesStore, currentCategory, currentCountry } = useStore();
  const { formOptions } = useDetails();

  const options = _.sortBy(_.values(toJS(formOptions)), 'index');
  const filterCriteria = companiesStore.filters.criteriaQuestions || {};

  if (_.isEmpty(options)) {
    return null;
  }

  const onChangeRadio = (value, key, checkedList = [], question) => {
    return () => {
      const checkedArray = Array.isArray(checkedList) ? checkedList : [checkedList];
      if (question.multiple) {
        let newValue = [];
        if (checkedArray.includes(value)) {
          newValue = checkedArray.filter(item => item !== value);
        } else {
          newValue = [...checkedArray, value];
        }

        onChange(newValue, key);
      } else {
        onChange(value, key);
      }
    };
  };

  return (
    <>
      {_.map(options, question => {
        const name = _.camelCase(question.name);
        const filterValue = filterCriteria[name];

        // spec case for keys with  range options
        if (question.name === 'occupation') {
          question.options = _.uniqBy([allOption, ...question.options], 'value');
        } else if (name === 'mobileMake') {
          question.options = _.uniqBy([allOption, ...question.options], 'value');
        } else if (currentCategory?.enName === 'motorbike_insurance' && name === 'age') {
          question.options = _.uniqBy([allOption, ...question.options], 'value');
        }

        return (
          <Box display='flex' flexDirection='column' gap={sidebarFilter ? 3 : 2} pb={sidebarFilter ? 3 : 2}>
            <Box display='flex' flexDirection='column' gap={sidebarFilter ? 3 : 2}>
              {question.type === 'select' ? (
                <AutocompleteMUI
                  key={name + filterValue}
                  value={question.options.find(
                    ({ value }) => value === (filterValue === '' || filterValue === null ? 'all' : filterValue)
                  )}
                  options={question.options}
                  onChange={obj => {
                    onChange(obj?.value, name);
                  }}
                  isOptionEqualToValue={(option, value) => option?.value === value?.value}
                  placeholder={question?.placeholder || 'Select'}
                  label={question?.label}
                  size={sidebarFilter ? 'small' : 'medium'}
                  withTitle={sidebarFilter}
                  mobileSelect
                  filter
                  widthOutReCache
                />
              ) : question.type === 'number' ? (
                <>
                  <TextField
                    key={name}
                    label={question.label}
                    placeholder={question?.placeholder}
                    type='number'
                    size={sidebarFilter ? 'small' : 'medium'}
                    value={filterValue || ''}
                    onChange={({ target: { value: num } }) => {
                      onChange(num, name);
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      min: question.minValue,
                      max: question.maxValue
                    }}
                  />
                </>
              ) : question.type === 'location' ? (
                <AutocompleteMUI
                  key={name + filterValue}
                  value={filterValue}
                  options={_.get(currentCountry, 'regions', []).map(option => {
                    return {
                      label: option.name,
                      value: option.name
                    };
                  })}
                  label={question?.label}
                  placeholder={question?.placeholder}
                  size={sidebarFilter ? 'small' : 'medium'}
                  withTitle={sidebarFilter}
                  mobileSelect
                  filter
                  widthOutReCache
                />
              ) : question.type === 'range' ? (
                <RangeSelect
                  label={question?.label}
                  valueLabel={question?.labelType}
                  marks={question?.options}
                  value={filterValue}
                  defaultValue={question?.defaultValue}
                  onChange={val => onChange(val, name)}
                  minValue={question?.minValue}
                  maxValue={question?.maxValue}
                  step={question?.incrementStep}
                />
              ) : question.type === 'rangeWithInput' ? (
                <RangeSelect
                  label={question?.label}
                  valueLabel={question?.labelType}
                  marks={question?.options}
                  value={filterValue}
                  defaultValue={question?.defaultValue}
                  onChange={val => onChange(val, name)}
                  minValue={question?.minValue}
                  maxValue={question?.maxValue}
                  step={question?.incrementStep}
                  withInput
                />
              ) : (
                <Box>
                  <Typography variant='body6'>{question?.label}</Typography>
                  {question.multiple ? (
                    <FormGroup key={question.name}>
                      {_.map(question.options, ({ value, label }, index) => {
                        const fieldValue = filterValue || [];
                        const valToCheck = Array.isArray(fieldValue) ? [...fieldValue] : [fieldValue];
                        const checked = valToCheck.includes(value);

                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={checked}
                                disabled={disabled}
                                size='small'
                                onChange={onChangeRadio(value, name, valToCheck, question)}
                              />
                            }
                            label={<Typography variant='body3'>{label}</Typography>}
                          />
                        );
                      })}
                    </FormGroup>
                  ) : (
                    <MaterialRadioGroup
                      row={false}
                      name={name}
                      options={question.options}
                      value={question.options.find(
                        ({ value }) => value === (filterValue === '' || filterValue === null ? 'all' : filterValue)
                      )}
                      onChange={data => onChange(data.value, name)}
                    />
                  )}
                </Box>
              )}
            </Box>
            {!sidebarFilter && <Divider flexItem />}
          </Box>
        );
      })}
    </>
  );
});
