import React from 'react';
import cx from 'classnames';

import { CustomSocialButton } from '@packs/components/UI/SocialButton';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import CheckboxIcon from '@packs/images/icons/CheckboxIcon';
import LinkedinIcon from '@packs/images/svg/ui/social-linkedin.svg';
import TwitterIcon from '@packs/images/svg/ui/social-twitter.svg';
import { toastr } from '@packs/lib/helpers';
import { useStore } from '@packs/stores';

import { observer } from 'mobx-react';

export const SocialSync = observer(
  ({ allowSocials = ['twitter', 'facebook', 'linkedin'], withConnectButton = false }) => {
    const { userStore } = useStore();
    const { currentUser } = useUserSelectors();

    const twitterIsConnected = currentUser?.socialProviders?.twitter;
    const facebookIsConnected = currentUser?.socialProviders?.facebook;
    const linkedinIsConnected = currentUser?.socialProviders?.linkedin;

    const submitHandler = (data, type) => {
      userStore.connectSocial(data).then(() => {
        toastr.success(`${type} account connected successfully!`);
      });
    };

    const handleSocialLogin = data => {
      const type = data?.provider || data?._provider;

      if (type === 'google') {
        submitHandler({ googleUid: currentUser.profile.id }, 'Google');
      } else if (type === 'facebook') {
        submitHandler(
          {
            type: 'facebook',
            accessToken: data?._token?.accessToken,
            secret: data?._token?.secret
          },
          'Facebook'
        );
      } else if (type === 'twitter') {
        submitHandler(
          {
            type: 'twitter',
            accessToken: data?.token?.accessToken,
            secret: data?.token?.secret
          },
          'Twitter'
        );
      } else if (type === 'linkedin') {
        submitHandler(
          {
            type: 'linkedin',
            accessToken: data?.token?.accessToken,
            secret: data?.token?.secret
          },
          'Linkedin'
        );
      }
    };

    const onDisconnect = type => {
      return () => {
        userStore.disconnectSocial({ type });
      };
    };

    const handleSocialLoginFailure = error => {
      console.error('handleSocialLoginFailure', error);
    };

    if (withConnectButton) {
      return (
        <div className='d-flex flex-column'>
          {allowSocials.includes('twitter') && (
            <div className='social-login-button-wrapper d-flex justify-content-between'>
              <div className='d-flex align-items-center'>
                <div className={cx('social-login-button', { active: twitterIsConnected })}>
                  <img src={TwitterIcon} alt='Twitter' />
                  <span className='check'>
                    <CheckboxIcon />
                  </span>
                </div>
                <div className='ml-10 fs-13'>Twitter Account</div>
              </div>

              {twitterIsConnected ? (
                <button className='btn empty primary' onClick={onDisconnect('twitter')}>
                  <span>Disconnect</span>
                </button>
              ) : (
                <CustomSocialButton
                  provider='twitter'
                  className='btn small full white'
                  onLoginSuccess={handleSocialLogin}
                  onLoginFailure={handleSocialLoginFailure}
                >
                  <span>Connect</span>
                </CustomSocialButton>
              )}
            </div>
          )}
          {allowSocials.includes('linkedin') && (
            <div className='social-login-button-wrapper d-flex justify-content-between'>
              <div className='d-flex align-items-center'>
                <div className={cx('social-login-button', { active: linkedinIsConnected })}>
                  <img src={LinkedinIcon} alt='Linkedin' />
                  <span className='check'>
                    <CheckboxIcon />
                  </span>
                </div>
                <div className='ml-10 fs-13'>Linkedin Account</div>
              </div>

              {linkedinIsConnected ? (
                <button className='btn empty primary' onClick={onDisconnect('linkedin')}>
                  <span>Disconnect</span>
                </button>
              ) : (
                <CustomSocialButton
                  provider='linkedin'
                  redirect={`${location.origin}/auth/linkedin/callback`}
                  appId={import.meta.env.VITE_LINKEDIN_APP_ID}
                  className='btn small full white'
                  onLoginSuccess={handleSocialLogin}
                  onLoginFailure={handleSocialLoginFailure}
                >
                  <span>Connect</span>
                </CustomSocialButton>
              )}
            </div>
          )}
        </div>
      );
    }

    return (
      <div className='social-sync-wrapper login-social-list d-flex align-items-center justify-content-center'>
        {allowSocials.includes('twitter') && (
          <>
            {twitterIsConnected ? (
              <button
                className={cx('social-login-button', { active: twitterIsConnected })}
                title='Disconnect'
                onClick={onDisconnect('twitter')}
              >
                <img src={TwitterIcon} alt='Twitter' />
                <span className='check'>
                  <CheckboxIcon />
                </span>
              </button>
            ) : (
              <CustomSocialButton
                provider='twitter'
                className={cx('social-login-button', { active: twitterIsConnected })}
                onLoginSuccess={handleSocialLogin}
                onLoginFailure={handleSocialLoginFailure}
              >
                <img src={TwitterIcon} alt='Twitter' />
                <span className='check'>
                  <CheckboxIcon />
                </span>
              </CustomSocialButton>
            )}
          </>
        )}
        {allowSocials.includes('linkedin') && (
          <>
            {linkedinIsConnected ? (
              <button
                className={cx('social-login-button', { active: linkedinIsConnected })}
                title='Disconnect'
                onClick={onDisconnect('linkedin')}
              >
                <img src={LinkedinIcon} alt='Linkedin' />
                <span className='check'>
                  <CheckboxIcon />
                </span>
              </button>
            ) : (
              <CustomSocialButton
                provider='linkedin'
                redirect={`${location.origin}/auth/linkedin/callback`}
                appId={import.meta.env.VITE_LINKEDIN_APP_ID}
                className='social-login-button'
                onLoginSuccess={handleSocialLogin}
                onLoginFailure={handleSocialLoginFailure}
              >
                <img src={LinkedinIcon} alt='Linkedin' />
                <span className='check'>
                  <CheckboxIcon />
                </span>
              </CustomSocialButton>
            )}
          </>
        )}
      </div>
    );
  }
);
