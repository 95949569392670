import React from 'react';

export const BusinessEmployerLiabilityInsurance = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path d='M4 3C2.895 3 2 3.895 2 5V18C2 19.657 3.343 21 5 21H19C20.645 21 22 19.645 22 18V8C22.0018 7.86621 21.9768 7.73341 21.9264 7.60947C21.876 7.48553 21.8012 7.37297 21.7065 7.27845C21.6118 7.18393 21.4991 7.10937 21.375 7.05919C21.251 7.00902 21.1182 6.98424 20.9844 6.98633C20.7195 6.99047 20.4671 7.09953 20.2825 7.28957C20.098 7.47962 19.9964 7.73512 20 8V18C20 18.565 19.565 19 19 19C18.448 19 18 18.551 18 18V5C18 3.895 17.105 3 16 3H4ZM7 6H13C13.552 6 14 6.448 14 7V8C14 8.552 13.552 9 13 9H7C6.448 9 6 8.552 6 8V7C6 6.448 6.448 6 7 6ZM7 12H13C13.552 12 14 12.448 14 13C14 13.552 13.552 14 13 14H7C6.448 14 6 13.552 6 13C6 12.448 6.448 12 7 12ZM7 16H13C13.552 16 14 16.448 14 17C14 17.552 13.552 18 13 18H7C6.448 18 6 17.552 6 17C6 16.448 6.448 16 7 16Z' />
      </g>
    </svg>
  );
};
