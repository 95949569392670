import _ from 'lodash-es';

import { isValid } from './validate';

export const setStepHandleLogic = (nextStep, currentStep, localData = {}) => {
  const { setStep, rootMobxStore } = localData;
  const { detailsFormStore } = rootMobxStore;
  const { formValues, formErrors } = detailsFormStore;

  // validate step change
  localData.nextStep = nextStep;
  if (!isValid(localData) && nextStep > currentStep) return;

  setStep(nextStep);

  if (nextStep === 0) {
    // criteria
    // nothing
  }

  if (nextStep === 1) {
    // criteria
    // this.props.change('criteriaQuestions[trim]', '');
    // formValues[]
    if (_.get(formValues, 'criteriaQuestions.make')) {
      // this.props.change('criteriaQuestions[model]', '');
      // this.props.change('criteriaQuestions[trim]', '');
      // this.props.fetchRenewalDetailCriteriasQuestionsCarModelsOptionsDispatch({
      //   make: value
      // });
      // const make = _.get(formValues, 'criteriaQuestions.make');
      // const model = _.get(formValues, 'criteriaQuestions.model');
      // // formValues criteriaQuestions['make']
      // props.fetchRenewalDetailCriteriasQuestionsCarTrimOptionsDispatch({
      //   make, model: value
      // });
    }

    // this.props.change('criteriaQuestions[trim]', '');
  }
  detailsFormStore.setFormValues(formValues);
};
