import colors from '@packs/assets/theme/base/colors';

const { dark, white } = colors;

export default () => ({
  wrapper: {
    position: 'fixed',
    top: {
      xs: '56px',
      lg: '98px'
    },
    left: 0,
    right: 0,
    py: {
      xs: 2,
      lg: 3
    },
    borderTop: `1px solid ${dark.transparent}`,
    borderBottom: `2px solid ${dark.transparent}`,
    backgroundColor: white.main,
    zIndex: 5
  },
  wrapperInner: {
    display: 'flex',
    alignItems: {
      md: 'center'
    },
    justifyContent: 'space-between',
    flexDirection: {
      xs: 'column',
      md: 'row'
    },
    gap: 2,
    width: '100%',
    maxWidth: '1176px',
    px: 3,
    mx: 'auto'
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  titleClose: {
    display: {
      xs: 'block',
      md: 'none'
    }
  },
  list: {
    gap: 1,
    flexWrap: 'wrap'
  },
  btnContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 3
  },
  cancel: {
    display: {
      xs: 'none',
      md: 'block'
    }
  },
  submit: {
    width: {
      xs: '100%',
      md: 'auto'
    }
  },
  chip: {
    borderRadius: '8px',
    '& .MuiChip-label': {
      pl: 0.5
    }
  }
});
