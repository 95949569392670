import React from 'react';
import cx from 'classnames';
import moment from 'moment';

import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { SignInModalLink } from '@packs/components';
import { shortSecondName } from '@packs/lib/helpers/additionalHelpers';
import { avatarPlaceholder, stringToColor } from '@shared/helpers/additionalHelpers';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import useStyles from './styles';

export const ReplyItemInsight = ({ answer, postId, commentId, insightsCompanyStore, currentUser }) => {
  const styles = useStyles();
  const { isMobile } = useWindowSize();

  const { id, user, message, createdAt, isHelpful, businessUser, helpfulCount } = answer;

  const avatarUrl = businessUser?.company?.avatarUrl || user?.avatarUrl || '';
  const displayName = businessUser?.businessName || user?.displayName || '';

  const onToggleHelpful = () => {
    insightsCompanyStore.toggleHelpfulInsightComment({
      attrs: { commentId: id },
      postId,
      rootCommentId: commentId,
      isHelpful
    });
  };

  return (
    <Box sx={styles.wrapper}>
      <Box sx={{ pt: 2 }}>
        <Avatar
          sx={{ width: '40px', minWidth: '40px', height: '40px', backgroundColor: stringToColor(displayName) }}
          src={avatarUrl}
          variant='rounded'
        >
          {avatarPlaceholder(displayName)}
        </Avatar>
      </Box>
      <Box sx={styles.bodyWrapper}>
        <Box sx={styles.bodyContainer}>
          <Box sx={{ mb: 1 }}>
            <Typography variant='body2'>{shortSecondName(displayName)}</Typography>
            <Typography variant='body3' sx={{ color: 'rgba(0, 0, 0, 0.60)' }}>
              {(createdAt && moment(createdAt).fromNow()) || '-'}
            </Typography>
          </Box>
          <Box>
            <Typography variant='body1'>{message}</Typography>
          </Box>
        </Box>
        <Box sx={styles.actionPanelWrapper}>
          {currentUser?.id ? (
            <Button
              variant='text'
              color={isHelpful ? 'primary' : 'dark'}
              size='smallToMedium'
              startIcon={<ThumbUpAltRoundedIcon />}
              className={cx({ active: isHelpful })}
              onClick={onToggleHelpful}
            >
              {!isMobile && 'Helpful '}
              {helpfulCount || 0}
            </Button>
          ) : (
            <SignInModalLink variant='text' color='dark' size='smallToMedium' startIcon={<ThumbUpAltRoundedIcon />}>
              {!isMobile && 'Helpful '}
              {helpfulCount || 0}
            </SignInModalLink>
          )}
        </Box>
      </Box>
    </Box>
  );
};
