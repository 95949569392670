export default () => ({
  wrapper: {
    width: '100%',
    maxWidth: '1160px',
    mx: 'auto',
    px: 2,
    py: {
      xs: 4,
      md: 6
    }
  },
  filtersWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3
  }
});
