export default () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    maxWidth: '500px',
    width: '100%',
    mx: 'auto',
    mt: 4
  }
});
