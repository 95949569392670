import React, { useEffect, useMemo, useState } from 'react';
import _, { get } from 'lodash-es';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { useDetails } from '@packs/models/policy/useDetails';
import { RequestQuotesBar } from '@packs/screens/MaterialUi/MyResults/components/RequestQuotesBar/RequestQuotesBar';
import { useStore } from '@packs/stores';

import { ContactInfo } from './ContactInfo';
import { ShortList } from './ShortList';
import useStyles from './styles';

import { observer } from 'mobx-react';
import queryString from 'query-string';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

const navList = [
  {
    value: 'shortList',
    label: 'My Matches'
  },
  {
    value: 'contactInfo',
    label: 'Contact information'
  }
];

export const Form = observer(
  ({
    change,
    category,
    submitModal,
    handleSubmit,
    formAttributes,
    showVerificationForm,
    submittedForm,
    setActiveStep,
    activeStep,
    detailsIsLoading
  }) => {
    const styles = useStyles();
    const { renewalDetailId, policyId } = queryString.parse(location.search);
    const { currentUser } = useUserSelectors();
    const { leaderboardStore, userStore } = useStore();
    const { currentPolicy, userRenewalDetails } = useDetails();
    const { toggleSignUpModal } = useModalsContext();

    const [files, setFiles] = useState([]);
    const disabledUpload = files.length > 0;

    const lastStepActive = activeStep === navList.length;

    const policyOptions = useMemo(() => {
      return _(userRenewalDetails)
        .filter(el => el.id)
        .map((detail, inx) => {
          detail.index = inx;

          return {
            value: detail.id,
            object: detail,
            label: get(detail, 'smartReminder.name')
          };
        })
        .value();
    }, [userRenewalDetails]);

    const onFileAdd = ev => {
      if (!disabledUpload) {
        const newFiles = [...files]
          .concat(Array.from(ev.target.files))
          .sort((a, b) => (a.type.match(/image/) ? -1 : 1));
        setFiles(newFiles);
      }
    };

    const removeFile = index => {
      let newFiles = [...files];
      delete newFiles[index];
      newFiles = newFiles.filter(f => f);
      setFiles(newFiles);
    };

    const onSubmit = () => {
      const newData = { ...formAttributes };
      newData.quoteAgentIds = leaderboardStore.requestBusinesses.map(business => business?.quoteAgent?.id);
      newData.files = files;

      const submit = () => {
        submitModal(
          _.pick(newData, [
            'files',
            'address',
            'comment',
            'attached',
            'postalCode',
            'phoneNumber',
            'quoteAgentIds',
            'renewalDetailId'
          ]),
          leaderboardStore.requestBusinesses
        );
      };

      if (currentUser.id) {
        submit();
      } else {
        userStore.setSignUpModalType('bussiness');
        userStore.setAuthModalType('signup');
        userStore.setAuthModalCall('');
        userStore.setSkipCategoriesModal(true);
        toggleSignUpModal({ onSignUpSuccess: submit });
      }
    };

    const handleCancel = () => {
      leaderboardStore.resetRequestBusinesses();
    };

    const handleBack = () => {
      setActiveStep(prevState => prevState - 1);
    };

    const isFormValid = () => {
      if (activeStep === 1) {
        return leaderboardStore.requestBusinesses.length;
      } else if (activeStep === 2) {
        return (
          formAttributes?.phoneNumber &&
          isPossiblePhoneNumber(formAttributes?.phoneNumber.replace(/\s+/g, '')) &&
          formAttributes?.address &&
          formAttributes?.postalCode &&
          formAttributes?.check
        );
      }
    };

    const disabled = !isFormValid();

    const onNextStep = () => {
      if (!lastStepActive && !disabled) {
        setActiveStep(prevState => prevState + 1);
      }
    };

    useEffect(() => {
      const verifiedNumbers = currentUser?.verifiedNumbers;
      const verifiedLastNumber = verifiedNumbers?.length && verifiedNumbers[verifiedNumbers.length - 1];
      if (verifiedLastNumber) {
        change('phoneNumber', verifiedLastNumber);
      }
    }, []);

    useEffect(() => {
      if (renewalDetailId || policyId) {
        change('renewalDetailId', Number(renewalDetailId || policyId));
      } else if (policyOptions.length) {
        change('renewalDetailId', currentPolicy?.id ? currentPolicy.id : policyOptions[0].value);
      }
    }, [renewalDetailId, policyId, currentPolicy, policyOptions]);

    useEffect(() => {
      document.querySelector('.request-quote-scroll-container')?.scrollTo(0, 0);
    }, [activeStep]);

    return (
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <RequestQuotesBar
            sx={styles.requestTopBar}
            disableRemove={activeStep !== 1}
            onCancel={activeStep === 1 ? handleCancel : null}
            onBack={activeStep !== 1 ? handleBack : null}
            submitButton={
              activeStep === 1
                ? ({ sx }) => (
                    <Button
                      sx={sx}
                      variant='contained'
                      type={lastStepActive ? 'submit' : 'button'}
                      disabled={disabled}
                      onClick={onNextStep}
                    >
                      {lastStepActive ? 'Submit' : 'Next'}
                    </Button>
                  )
                : null
            }
          />
          <div className='form'>
            {activeStep === 1 && (
              <Slide direction='right' in={true} timeout={500}>
                <Box>
                  <ShortList {...{ category, setActiveStep, detailsIsLoading }} stepName='shortList' />
                </Box>
              </Slide>
            )}
            {activeStep === 2 && (
              <Slide direction='right' in={true} timeout={500}>
                <Box>
                  <ContactInfo
                    stepName='contactInfo'
                    renewalDetail={currentPolicy}
                    {...{
                      files,
                      change,
                      category,
                      onFileAdd,
                      removeFile,
                      formAttributes,
                      userRenewalDetails,
                      showVerificationForm,
                      submittedForm,
                      policyOptions
                    }}
                  />
                </Box>
              </Slide>
            )}
          </div>
          {lastStepActive && (
            <Box sx={styles.btnContainer}>
              <Button
                type={lastStepActive ? 'submit' : 'button'}
                disabled={disabled}
                onClick={onNextStep}
                variant='contained'
                color='primary'
              >
                Send
              </Button>
            </Box>
          )}
        </form>
      </>
    );
  }
);
