import { action, observable } from 'mobx';
import _ from 'lodash-es';

import api from '@packs/apis/client';
import { toastr } from '@packs/lib/helpers';

export class AssociationStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable associations = [];
  @observable myAssociations = [];
  @observable myAssociationsCategories = [];
  @observable popularAssociations = [];
  @observable countryCode = '';
  @observable userId = 0;
  @observable categoryId = 0;

  @action setParam({ countryCode, userId, categoryId }) {
    this.countryCode = countryCode;
    this.userId = userId;
    this.categoryId = categoryId;
  }

  @action create(attrs, onSuccess, onError) {
    api.createClient(attrs).then(res => {
      if (res.errors.length) {
        onError && onError(res.errors);
      } else {
        onSuccess && onSuccess(res);
      }
    });
  }

  get getAssociations() {
    const myAssociationIds = _.map(this.myAssociations, 'id');
    return this.associations.filter(item => !myAssociationIds.includes(item.id));
  }

  @action setMyAssociation(item) {
    const itemUniq = this.myAssociations.find(ob => ob.id === item.id);
    if (itemUniq) {
      toastr.warning('You have already assigned to this association');
      return;
    }

    if (!this.userId) {
      item.saveLater = true;
      this.setMyAssociations([...this.myAssociations, item]);
      return;
    }
    api
      .assignClientUser({
        clientId: +item.id,
        categoryId: +this.categoryId
      })
      .then(res => {
        if (res.errors?.length) {
          toastr.error(res.errors);
        } else {
          this.setMyAssociations([...this.myAssociations, item]);
        }
      });
  }

  @action removeMyAssociation(item) {
    if (!this.userId) {
      this.setMyAssociations(this.myAssociations.filter(ob => ob.id !== item.id));
      return;
    }
    api
      .assignClientUser({
        clientId: +item.id,
        categoryId: +this.categoryId,
        type: 'remove'
      })
      .then(res => {
        if (res.errors?.length) {
          toastr.error(res.errors);
        } else {
          this.setMyAssociations(this.myAssociations.filter(ob => ob.id !== item.id));
        }
      });
  }

  @action assignClientUsersLater() {
    if (!this.myAssociations || this.myAssociations.length === 0) return;

    const items = [...this.myAssociations].filter(ob => ob.saveLater);

    _.each(items, item => {
      api.assignClientUser({
        clientId: +item.id,
        categoryId: +this.categoryId
      });
    });
    this.myAssociations = [...this.myAssociations].forEach(el => (el.saveLater = false));
  }

  @action fetchAssociations(opts = {}) {
    this.associations = [];

    api
      .listClients({
        countryCode: this.countryCode,
        ...opts
      })
      .then(res => {
        if (!res?.errors?.length) {
          this.setAssociations(res);
        }
      });
  }

  @action fetchUserAssociations(attrs = {}, onSuccess, onError) {
    this.myAssociations = [];
    api
      .listClients({
        userId: this.userId,
        countryCode: this.countryCode
      })
      .then(res => {
        if (res.errors?.length) {
          onError && onError(res.errors);
        } else {
          this.setMyAssociations(res);
          onSuccess && onSuccess(res);
        }
      });
  }

  @action fetchUserAssociationsCategories(attrs = {}, onSuccess, onError) {
    this.myAssociationsCategories = [];
    api.listAssociationsCategories({ clientId: attrs.clientId }).then(res => {
      if (res.errors?.length) {
        onError && onError(res.errors);
      } else {
        this.setAssociationsCategories(res);
        onSuccess && onSuccess(res);
      }
    });
  }

  @action addUserAssociationsCategory({ attr, onSuccess, onError }) {
    api.assignClientUser({ clientId: +attr?.associationId, code: attr?.code }).then(res => {
      if (res?.errors?.length) {
        toastr.error(res.errors);
        onError && onError();
      } else {
        onSuccess && onSuccess(res);
      }
    });
  }

  @action fetchPopularAssociations(onSuccess, onError) {
    this.popularAssociations = [];
    api
      .listClients({
        type: 'popular',
        countryCode: this.countryCode
      })
      .then(res => {
        if (res.errors?.length) {
          onError && onError(res.errors);
        } else {
          this.setPopularAssociations(res);
          onSuccess && onSuccess(res);
        }
      });
  }

  @action setAssociations(data) {
    this.associations = data;
  }

  @action setMyAssociations(data) {
    this.myAssociations = data;
  }

  @action setAssociationsCategories(data) {
    this.myAssociationsCategories = data;
  }

  @action setPopularAssociations(value) {
    this.popularAssociations = value;
  }
}
