export const criteriaQuestionListQuery = `
  query criteriaQuestionList(
    $categoryId: Int!,
    $formType: String!
  ) {
    criteriaQuestionList(categoryId: $categoryId, formType: $formType) {
      id
      name
      type
      label
      placeholder
      maxValue
      minValue
      defaultValue
      description
      voteForm
      options {
        label
        value
      }
      index
      incrementStep
      conditions {
        and 
      }
    }
  }
`;
