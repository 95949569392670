import React from 'react';
import _ from 'lodash-es';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { AppliedFilters } from '@packs/components/MaterialUi/Leaderboard/AppliedFilters/AppliedFilters';
import { appliedFiltersLabels } from '@packs/models/company/constants';
import { useStore } from '@packs/stores';
import useStyles from '@packs/styles/Leaderboard/topBarStyles';

import { observer } from 'mobx-react';

export const QuestionsActionPanel = observer(({ filters, INITIAL_FILTERS, createButton }) => {
  const styles = useStyles();
  const { questionsStore } = useStore();

  const appliedFilters = Object.keys(_.omit(filters, ['page'])).reduce((acc, item) => {
    const filterItem = filters[item];

    if (typeof filterItem === 'object' && filterItem?.value && filterItem?.value !== 'all') {
      return acc + 1;
    } else if (typeof filterItem === 'string' && INITIAL_FILTERS[item] !== filterItem && filterItem !== 'all') {
      return acc + 1;
    }

    return acc;
  }, 0);

  return (
    <Box sx={styles.actionPanelWrapper}>
      <Box sx={styles.container}>
        <Typography variant='body3' sx={styles.resultsTitle}>
          {`${questionsStore.questionsTotalCount} posts found`}
        </Typography>
        {createButton && <Box sx={styles.createButtonWrapper}>{createButton}</Box>}
      </Box>
      <AppliedFilters filters={appliedFiltersLabels(filters, Object.keys(_.omit(filters, ['page'])))} />
    </Box>
  );
});
