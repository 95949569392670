export const tableWrapperStyles = {
  width: '100%',
  backgroundColor: '#fff',
  '& .table-th': {
    backgroundColor: '#F4F4F4',
    px: '16px !important',
    fontSize: '12px',
    textTransform: 'uppercase',
    borderRight: '1px solid rgba(0, 0, 0, 0.08)',
    '&:last-child': {
      borderRight: 'none'
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: '700'
    }
  },
  '& .MuiDataGrid-cellContent': {
    fontSize: '14px',
    fontWeight: '700'
  },
  '& .MuiDataGrid-row:last-child': {
    borderRight: 'none'
  },
  '& .MuiDataGrid-cell': {
    padding: '16px !important',
    borderRight: '1px solid rgba(0, 0, 0, 0.08)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    '&:last-child': {
      borderRight: 'none'
    }
  }
};
