import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { AppliedFilters } from '@packs/components/MaterialUi/Leaderboard/AppliedFilters/AppliedFilters';
import { appliedFiltersLabels } from '@packs/models/company/constants';
import { useStore } from '@packs/stores';
import useStyles from '@packs/styles/Leaderboard/topBarStyles';

import omit from 'lodash-es/omit';
import size from 'lodash-es/size';

export const MyNotesActionPanel = ({ filters, INITIAL_FILTERS, toggleCreateModal }) => {
  const styles = useStyles();
  const { notesStore } = useStore();
  const fields = Object.keys(omit(filters, ['page']));

  const appliedFilters = fields.reduce((acc, item) => {
    const filterItem = filters[item];

    if (typeof filterItem === 'object' && filterItem?.value && filterItem?.value !== 'all') {
      return acc + 1;
    } else if (typeof filterItem === 'string' && INITIAL_FILTERS[item] !== filterItem && filterItem !== 'all') {
      return acc + 1;
    }

    return acc;
  }, 0);

  return (
    <Box sx={styles.actionPanelWrapper}>
      <Box sx={styles.container}>
        <Typography variant='body3' sx={styles.resultsTitle}>
          {`${size(notesStore?.list)} documents found`}
        </Typography>
        {toggleCreateModal && (
          <Box sx={styles.createButtonWrapper}>
            <Button variant='contained' size='smallToMedium' onClick={toggleCreateModal}>
              Add My Docs/Notes
            </Button>
          </Box>
        )}
      </Box>
      <AppliedFilters filters={appliedFiltersLabels(filters, fields)} />
    </Box>
  );
};
