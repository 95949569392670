export default ({ colors }) => ({
  wrapper: {
    borderRadius: '16px',
    border: `1px solid ${colors.borderColor.main}`,
    p: 3,
    backgroundColor: colors.white.main
  },
  ratingsTitle: {
    mb: 1
  },
  mainSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  mainRatingStars: {
    my: 1
  },
  openTrigger: {
    display: 'flex',
    alignItems: 'center',
    mt: 2,
    cursor: 'pointer'
  }
});
