import React from 'react';

export const BusinessInternationalPayments = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path d='M12 2C8.74107 2 5.84869 3.57739 4.02148 6H4V6.02734C2.74995 7.69669 2 9.76039 2 12C2 17.511 6.489 22 12 22C17.511 22 22 17.511 22 12C22 6.489 17.511 2 12 2ZM15 4.58398C17.9351 5.76736 20 8.63265 20 12C20 14.0878 19.2006 15.9783 17.8984 17.4004C17.6426 16.5907 16.8946 16 16 16H15V13C15 12.448 14.552 12 14 12H9V10H10C10.552 10 11 9.552 11 9V7H13C14.105 7 15 6.105 15 5V4.58398ZM4.20703 10.207L6 12L9 15V16C9 17.105 9.895 18 11 18V19.9316C7.04577 19.4412 4 16.0907 4 12C4 11.3822 4.07552 10.784 4.20703 10.207Z' />
      </g>
    </svg>
  );
};
