import React from 'react';

export const InsuranceTelematicsInsurance = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <path d='M16.6133 12.6313C16.48 12.2625 16.1067 12 15.6667 12H8.33333C7.89333 12 7.52667 12.2625 7.38667 12.6313L6.07333 16.175C6.02667 16.3063 6 16.4438 6 16.5875V21.0625C6 21.5812 6.44667 22 7 22C7.55333 22 8 21.5812 8 21.0625V20.75H16V21.0625C16 21.575 16.4467 22 17 22C17.5467 22 18 21.5812 18 21.0625V16.5875C18 16.45 17.9733 16.3063 17.9267 16.175L16.6133 12.6313ZM8.33333 18.875C7.78 18.875 7.33333 18.4562 7.33333 17.9375C7.33333 17.4187 7.78 17 8.33333 17C8.88667 17 9.33333 17.4187 9.33333 17.9375C9.33333 18.4562 8.88667 18.875 8.33333 18.875ZM15.6667 18.875C15.1133 18.875 14.6667 18.4562 14.6667 17.9375C14.6667 17.4187 15.1133 17 15.6667 17C16.22 17 16.6667 17.4187 16.6667 17.9375C16.6667 18.4562 16.22 18.875 15.6667 18.875ZM7.33333 15.75L8.18 13.3625C8.27333 13.1125 8.52667 12.9375 8.81333 12.9375H15.1867C15.4733 12.9375 15.7267 13.1125 15.82 13.3625L16.6667 15.75H7.33333Z' />
      <path d='M6.24341 4.96331C6.53868 5.23306 7.00764 5.2595 7.32606 5.0162C10.0298 2.98512 13.9551 2.98512 16.6646 5.01091C16.9889 5.25421 17.4636 5.23306 17.7589 4.96331C18.1005 4.65124 18.0773 4.13289 17.701 3.85256C14.3951 1.38248 9.61295 1.38248 6.30131 3.85256C5.92499 4.1276 5.89604 4.64595 6.24341 4.96331ZM10.7361 9.06777L11.5872 9.84529C11.813 10.0516 12.1777 10.0516 12.4035 9.84529L13.2546 9.06777C13.5267 8.81917 13.4688 8.39074 13.1214 8.22678C12.4151 7.89355 11.5698 7.89355 10.8577 8.22678C10.5277 8.39074 10.464 8.81917 10.7361 9.06777ZM8.57661 7.09488C8.8603 7.35405 9.3061 7.3805 9.6361 7.16364C11.0488 6.2486 12.9477 6.2486 14.3604 7.16364C14.6904 7.37521 15.1362 7.35405 15.4199 7.09488L15.4257 7.08959C15.7731 6.77223 15.7499 6.23273 15.3504 5.97355C13.3588 4.65653 10.6435 4.65653 8.64609 5.97355C8.24661 6.23802 8.22345 6.77223 8.57661 7.09488Z' />
    </svg>
  );
};
