import React from 'react';

import RangeSelect from '@packs/components/RangeInput/RangeSelect';
import { useDetailsForm } from '@packs/screens/PersonalizeResults/DetailsFormProvider';

const RangeFieldAdapter = props => {
  const { getRef, onChange, onFocus } = useDetailsForm();
  const { name, type, question, symbolsAfterDot, withDot, maxLength, value, numeric = false } = props;

  return (
    <RangeSelect
      label={question?.label}
      valueLabel={question?.labelType}
      marks={question?.options}
      value={value}
      defaultValue={question?.defaultValue}
      onChange={val => onChange({ target: { value: val, name } })}
      minValue={question?.minValue}
      maxValue={question?.maxValue}
      step={question?.incrementStep}
      withInput={question?.type === 'rangeWithInput'}
    />
  );
};

export default RangeFieldAdapter;
