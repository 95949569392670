import React, { ChangeEvent, FC } from 'react';

import { InputAdornment, TextField } from '@mui/material';
import ButtonSelect from '@shared/components/ButtonSelect/ButtonSelect';

export enum PERIOD {
  PER_MONTH = 'per_month',
  PER_YEAR = 'per_year'
}

interface IEstimateCostInputProps {
  period: PERIOD;
  value: number;
  name?: string;
  fullWidth: boolean;
  onChange: (e: ChangeEvent) => void;
  disabled?: boolean;
  error?: string | undefined;
  estimateCostsEnabled: boolean;
  directOnChange: (val: any) => void;
}

export const EstimateCostInput: FC<IEstimateCostInputProps> = ({
  period,
  value,
  name,
  fullWidth,
  onChange,
  disabled,
  error,
  estimateCostsEnabled,
  directOnChange
}) => {
  const estimateCostsRangeDisabled = !estimateCostsEnabled;

  return (
    <TextField
      label={`Estimate cost per ${period === PERIOD.PER_MONTH ? 'month' : 'year'}`}
      value={value}
      name={name}
      fullWidth={fullWidth}
      onChange={onChange}
      disabled={disabled}
      error={!!error}
      helperText={error}
      InputProps={{
        startAdornment: <InputAdornment position='start'>€</InputAdornment>,
        endAdornment: estimateCostsRangeDisabled ? (
          <InputAdornment position='end'>
            <ButtonSelect
              buttonValue={`Per ${period === 'per_month' ? 'month' : 'year'}`}
              value={period}
              onChange={directOnChange}
              options={[
                { title: 'Per month', value: PERIOD.PER_MONTH },
                { title: 'Per year', value: PERIOD.PER_YEAR }
              ]}
              disabled={disabled}
            />
          </InputAdornment>
        ) : undefined
      }}
    />
  );
};
