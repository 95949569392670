import React from 'react';

export const InsuranceCarHomeInsurance = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <path d='M12 3.5C11.8461 3.5 11.6919 3.53119 11.5486 3.59425L3.31944 7.2059C2.51611 7.55796 2 8.34125 2 9.2088V18.3065C2 19.5184 2.99444 20.5 4.22222 20.5H4.77778V11.4023C4.77778 10.1904 5.77222 9.2088 7 9.2088H17C18.2278 9.2088 19.2222 10.1904 19.2222 11.4023V20.5H19.7778C21.0056 20.5 22 19.5184 22 18.3065V9.2088C22 8.34234 21.4839 7.55796 20.6806 7.2059L12.4514 3.59425C12.3081 3.53119 12.1539 3.5 12 3.5Z' />
      <path d='M16.2716 12.2797C16.1481 11.9561 15.8025 11.7258 15.3951 11.7258H8.60494C8.19753 11.7258 7.85803 11.9561 7.7284 12.2797L6.51235 15.389C6.46914 15.5042 6.44444 15.6248 6.44444 15.751V19.6774C6.44444 20.1326 6.85803 20.5 7.37037 20.5C7.88272 20.5 8.2963 20.1326 8.2963 19.6774V19.4032H15.7037V19.6774C15.7037 20.1271 16.1173 20.5 16.6296 20.5C17.1358 20.5 17.5556 20.1326 17.5556 19.6774V15.751C17.5556 15.6303 17.5309 15.5042 17.4877 15.389L16.2716 12.2797ZM8.60494 17.7581C8.09259 17.7581 7.67901 17.3906 7.67901 16.9355C7.67901 16.4803 8.09259 16.1129 8.60494 16.1129C9.11728 16.1129 9.53086 16.4803 9.53086 16.9355C9.53086 17.3906 9.11728 17.7581 8.60494 17.7581ZM15.3951 17.7581C14.8827 17.7581 14.4691 17.3906 14.4691 16.9355C14.4691 16.4803 14.8827 16.1129 15.3951 16.1129C15.9074 16.1129 16.321 16.4803 16.321 16.9355C16.321 17.3906 15.9074 17.7581 15.3951 17.7581ZM7.67901 15.0161L8.46296 12.9213C8.54938 12.7019 8.78395 12.5484 9.04938 12.5484H14.9506C15.2161 12.5484 15.4506 12.7019 15.537 12.9213L16.321 15.0161H7.67901Z' />
    </svg>
  );
};
