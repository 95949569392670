import React from 'react';

export default () => {
  return (
    <svg width='35' height='34' viewBox='0 0 35 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path
          d='M24.7412 18.845L25.605 13.1573H20.2016V9.46796C20.2016 7.91153 20.9551 6.39353 23.3752 6.39353H25.8333V1.5513C25.8333 1.5513 23.6035 1.16699 21.4726 1.16699C17.0205 1.16699 14.1134 3.89171 14.1134 8.82233V13.1573H9.16663V18.845H14.1134V32.5954C15.1065 32.753 16.1225 32.8337 17.1575 32.8337C18.1925 32.8337 19.2085 32.753 20.2016 32.5954V18.845H24.7412Z'
          fill='white'
        />
      </g>
    </svg>
  );
};
