import React from 'react';

import { useStore } from '@packs/stores';

import { SelectField } from './SelectField';

export const LocationField = ({
  question,
  multiple,
  type,
  name,
  options,
  swipePicker,
  onChange,
  isSliderSteps,
  value
}) => {
  const { currentCountry } = useStore();

  return (
    <SelectField
      {...{
        question,
        multiple,
        type,
        name,
        options,
        swipePicker,
        isSliderSteps,
        value
      }}
      onChange={onChange}
      placeholder={`Select ${currentCountry.regionType || 'region'}`}
      useOptions
    />
  );
};
