import React from 'react';

export const BusinessBusinessGasElectricity = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path d='M18.399 10.9999H14L14.992 2.55687C15.046 2.07487 14.451 1.80687 14.125 2.16487L5.157 11.9959C4.807 12.3819 5.08 12.9999 5.601 12.9999H10L10.008 21.4429C9.954 21.9249 10.549 22.1929 10.875 21.8349L18.843 12.0039C19.193 11.6179 18.92 10.9999 18.399 10.9999Z' />
      </g>
    </svg>
  );
};
