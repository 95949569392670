import React, { FC, KeyboardEventHandler, ReactElement, useEffect, useRef, useState } from 'react';

import { AutocompleteProps, TextFieldProps, TypographyProps } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { MobileSelect } from '@packs/components/MaterialUi/MobileSelect/MobileSelect';
import { SelectMUI } from '@packs/components/MaterialUi/SelectMUI/SelectMUI';
import useAutocompleteStyles from '@packs/styles/Autocomplete/autocomplete';
import { useWindowSize } from '@shared/hooks/useWindowSize';

interface IAutocompleteMUIProps extends Partial<AutocompleteProps<any, any, any, any>> {
  label: string;
  onChange?: (data: any) => void;

  clearIcon?: null | ReactElement;
  width?: string;

  variant?: TextFieldProps['variant'];
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
  InputProps?: TextFieldProps['InputProps'];
  placeholder?: string;
  withTitle?: boolean;
  mobileSelect?: boolean;
  onFilter?: (val: string) => void;
  filter?: boolean;

  disabled?: boolean;
  error?: string;
  filterPlaceholder?: string;
  widthOutReCache?: boolean;
}
export const AutocompleteMUI: FC<IAutocompleteMUIProps> = ({
  value,
  label,
  onChange,
  sx = {},
  options = [],
  clearIcon = null,
  width = 'auto',
  size = 'medium',
  getOptionLabel = option => option.label,
  variant = 'outlined',
  onKeyDown = undefined,
  InputProps,
  placeholder = '',
  withTitle = false,

  mobileSelect = false,
  onFilter,
  filter,
  disabled,
  error = '',
  filterPlaceholder,

  widthOutReCache = false,
  ...rest
}) => {
  const { isMobile } = useWindowSize();
  const autocompleteStyles = useAutocompleteStyles();

  const [reCache, setReCache] = useState(0);
  const oldOpts = useRef<string | null>(null);
  useEffect(() => {
    const hashOpts = JSON.stringify(options);
    if (!widthOutReCache && oldOpts.current !== hashOpts) {
      oldOpts.current = hashOpts;
      setReCache(prev => prev + 1);
    }
  }, [options, widthOutReCache]);

  if (isMobile) {
    if (onFilter) {
      return (
        <MobileSelect
          value={value}
          onChange={val => onChange && onChange(val)}
          options={options}
          label={label}
          size={size}
          placeholder={placeholder}
          onFilter={onFilter}
          {...rest}
        />
      );
    }

    return (
      <SelectMUI
        value={value}
        label={label}
        size={size}
        options={[...options]}
        onChange={(e, data) => onChange && onChange(data)}
      />
    );
  }

  return (
    <Box>
      {withTitle && label && (
        <Box sx={{ mb: size === 'small' ? 0.5 : 1 }}>
          <Typography variant={(size === 'small' ? 'body6' : 'body2') as TypographyProps['variant']}>
            {label}
          </Typography>
        </Box>
      )}
      <Autocomplete
        key={reCache} // Remount component on options change
        {...rest}
        disabled={disabled}
        sx={{ ...sx, ...(!clearIcon ? autocompleteStyles.noCleanIcon : {}), width }}
        value={value}
        options={options}
        getOptionLabel={getOptionLabel}
        onChange={(e, val) => onChange && onChange(val)}
        renderInput={params => (
          <TextField
            {...params}
            label={withTitle ? '' : label}
            placeholder={placeholder}
            variant={variant}
            size={size}
            onKeyDown={onKeyDown}
            error={!!error}
            helperText={error}
            InputProps={{
              ...params.InputProps,
              ...InputProps
            }}
          />
        )}
        clearIcon={clearIcon}
      />
    </Box>
  );
};
