import { associationSendMailMutation, becomeAPartnerSendMailMutation } from '@packs/graphql/queries/additional';
import { graphQLRequest } from '@packs/lib/utils';

export const additionalApi = {
  becomeAPartnerSendMail: attrs =>
    graphQLRequest({
      query: becomeAPartnerSendMailMutation,
      variables: {
        input: attrs
      }
    }),
  associationSendMail: attrs =>
    graphQLRequest({
      query: associationSendMailMutation,
      variables: { input: attrs }
    })
};
