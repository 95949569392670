import { ICountry } from '@packs/interfaces/rootInterfaces';

export const currentCountryRegionOptions = (currentCountry: ICountry) => {
  if (!currentCountry) {
    return [];
  }

  const defaultCountryRegionOption = {
    label: currentCountry.name,
    value: 'All'
  };

  return [
    defaultCountryRegionOption,
    ...currentCountry.regions.map(option => ({
      label: option.name,
      value: option.name
    }))
  ];
};
