import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { useStore } from '@packs/stores';

const useSignUpModal = ({ skipCategories = true, skipRedirect = false, bussiness = '', authModalCall = '' }) => {
  const { userStore } = useStore();
  const { toggleSignUpModal } = useModalsContext();

  return onSignUpSuccess => {
    userStore.setSignUpModalType(bussiness ? 'bussiness' : 'customer');
    userStore.setAuthModalType('signup');
    userStore.setAuthModalCall(authModalCall);
    userStore.setSkipCategoriesModal(skipCategories);
    toggleSignUpModal({ onSignUpSuccess, modalAuthProps: { skipRedirect } });
  };
};

export default useSignUpModal;
