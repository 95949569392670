import React from 'react';
import cx from 'classnames';

import { numberToString, stringToNumber } from '@packs/lib/helpers';

export const NumberInput = ({
  inputProps = {},
  minValue,
  disabled = false,
  placeholder = '',
  symbolsAfterDot,
  value,
  onChange,
  withoutComma,
  maxValue = null,
  name,
  detailsNumeric = false,
  className
}) => {
  const onChangeHandler = ({ target: { value } }) => {
    if (maxValue) {
      if (value.length <= maxValue + 1) {
        if (detailsNumeric) {
          onChange({ target: { name, value: stringToNumber(value, { minValue }) } });
        } else {
          onChange(stringToNumber(value, { minValue }));
        }
      }
    } else {
      if (detailsNumeric) {
        onChange({ target: { name, value: stringToNumber(value, { minValue }) } });
      } else {
        onChange(stringToNumber(value, { minValue }));
      }
    }
  };

  return (
    <input
      type='text'
      className={cx('reminder-input', className)}
      name={name}
      {...{
        ...inputProps,
        disabled,
        placeholder,
        value: numberToString(value, { symbolsAfterDot, withoutComma })
      }}
      onChange={onChangeHandler}
      onBlur={onChangeHandler}
    />
  );
};
