import React from 'react';

export const BroadbandBroadbandPhone = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <path d='M19 20.5V3.5C19 2.12 17.88 1 16.5 1H7.5C6.12 1 5 2.12 5 3.5V20.5C5 21.88 6.12 23 7.5 23H16.5C17.88 23 19 21.88 19 20.5ZM16.5 21H7.5C7.224 21 7 20.776 7 20.5V3.5C7 3.224 7.224 3 7.5 3H8.625L9.349 4.447C9.518 4.786 9.864 5 10.243 5H13.715C14.094 5 14.44 4.786 14.609 4.447L15.333 3H16.5C16.776 3 17 3.224 17 3.5V20.5C17 20.776 16.776 21 16.5 21Z' />
      <path d='M8.16228 11.2225C8.35912 11.4248 8.67176 11.4446 8.88404 11.2621C10.6865 9.73884 13.3034 9.73884 15.1098 11.2582C15.3259 11.4407 15.6424 11.4248 15.8393 11.2225C16.067 10.9884 16.0515 10.5997 15.8007 10.3894C13.5968 8.53686 10.4086 8.53686 8.20087 10.3894C7.94999 10.5957 7.93069 10.9845 8.16228 11.2225ZM11.1574 14.3008L11.7248 14.884C11.8753 15.0387 12.1185 15.0387 12.269 14.884L12.8364 14.3008C13.0178 14.1144 12.9792 13.7931 12.7476 13.6701C12.2767 13.4202 11.7132 13.4202 11.2385 13.6701C11.0185 13.7931 10.976 14.1144 11.1574 14.3008ZM9.71774 12.8212C9.90687 13.0155 10.2041 13.0354 10.4241 12.8727C11.3658 12.1864 12.6318 12.1864 13.5736 12.8727C13.7936 13.0314 14.0908 13.0155 14.2799 12.8212L14.2838 12.8172C14.5154 12.5792 14.4999 12.1745 14.2336 11.9802C12.9059 10.9924 11.0957 10.9924 9.76406 11.9802C9.49774 12.1785 9.4823 12.5792 9.71774 12.8212Z' />
    </svg>
  );
};
