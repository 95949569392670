import colors from '@crm/assets/theme/base/colors';
import typography from '@crm/assets/theme/base/typography';
import pxToRem from '@shared/assets/theme/functions/pxToRem';

const { white, text, info, secondary, orange } = colors;
const { size } = typography;

const contained = {
  base: {
    backgroundColor: white.main,
    minHeight: pxToRem(40),
    color: text.main,
    padding: `${pxToRem(10)} ${pxToRem(24)}`,

    '&:active, &:active:focus, &:active:hover': {
      opacity: 0.85
    },

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(16)} !important`
    }
  },

  small: {
    minHeight: pxToRem(32),
    padding: `${pxToRem(6)} ${pxToRem(16)}`,
    fontSize: `${pxToRem(13)}`,

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(16)} !important`
    }
  },

  smallToMedium: {
    minHeight: pxToRem(40),
    padding: `${pxToRem(6)} ${pxToRem(16)}`,
    fontSize: size.sm,

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(16)} !important`
    }
  },

  medium: {
    minHeight: pxToRem(48),
    padding: `${pxToRem(12)} ${pxToRem(22)}`,
    fontSize: pxToRem(16),

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(22)} !important`
    }
  },

  large: {
    minHeight: pxToRem(53),
    padding: `${pxToRem(14)} ${pxToRem(24)}`,
    fontSize: pxToRem(18),

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(22)} !important`
    }
  },

  xl: {
    minHeight: pxToRem(64),
    padding: `${pxToRem(16)} ${pxToRem(28)}`,
    fontSize: pxToRem(18),

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(22)} !important`
    }
  },

  primary: {
    backgroundColor: info.main,
    color: white.main,

    '&:focus:not(:hover)': {
      backgroundColor: info.focus
    }
  },

  secondary: {
    backgroundColor: secondary.main
  },

  orange: {
    backgroundColor: orange.main,

    '&:focus:not(:hover)': {
      backgroundColor: orange.focus
    }
  }
};

export default contained;
