import React from 'react';
import moment from 'moment';

import { pathGen, usePath } from '@packs/layouts/constants';
import { useStore } from '@packs/stores/base';

import { useNavigate } from 'react-router-dom';

const TARGET = {
  RenewalDetail: 'Review',
  ReviewComment: 'Comment',
  Question: 'Question',
  SmartReminder: 'Smart Reminder',
  Comopany: 'Company'
};

const TARGET_ACTION = {
  answer: 'answered',
  comment: 'commented',
  create_reminder: 'created',
  update_reminder: 'updated',
  delete_reminder: 'deleted',
  update_details: 'updated',
  create_company: 'created'
};

export const NotificationBody = ({ item, toggle }) => {
  const category = item.category;
  const company = item.company;
  const navigate = useNavigate();
  const { countryCode } = useStore();
  const { PROFILE_MY_REMINDERS_PATH } = usePath();

  const redirectLink = (item, category, company) => {
    let path = null;
    (type => {
      switch (type) {
        case 'ReviewComment':
          path = pathGen('PRODUCT', {
            countryCode,
            category,
            company
          });
          return;
        case 'RenewalDetail':
          return;
        case 'SmartReminder':
          path = PROFILE_MY_REMINDERS_PATH;

        case 'Question':

        default:
      }
    })(item.notifyType);

    if (path) {
      toggle();
      navigate(path);
    }
  };

  const handleClick = e => {
    e.preventDefault();
    redirectLink(item, category, company);
  };

  const commentNotification = item => (
    <div className='item-content'>
      <span className='name fw-bold'>{item.creator} </span>
      <span>{TARGET_ACTION[item.noticeType]} on your </span>
      <a href='#' className='text-secondary' onClick={handleClick}>
        <span className='fw-bold'>{TARGET[item.notifyType]} </span>
      </a>
      {item.company && (
        <span>
          for '{(company || {}).name} {(category || {}).name} Company'
        </span>
      )}
    </div>
  );

  const reminderNotification = item => (
    <div className='item-content'>
      <a href='#' className='text-secondary' onClick={handleClick}>
        <span className='name fw-bold'>"{item.aliasName}" </span>
        <span>
          Smart Reminder {TARGET_ACTION[item.noticeType]}.
          <p>Reminder date {moment(item.triggeredAt).format('Y-D-M')}.</p>
        </span>
      </a>
    </div>
  );

  const detailsNotification = item => (
    <div className='item-content' onClick={handleClick}>
      <a href='#' className='text-secondary' onClick={handleClick}>
        <span className='fw-bold'>My details </span>
      </a>
      <span>{TARGET_ACTION[item.noticeType]} </span>
      {item.company && (
        <span>
          for '{(company || {}).name} {(category || {}).name} Company'
        </span>
      )}
    </div>
  );

  const companyNotification = item => (
    <div className='item-content'>
      <span className='name fw-bold'>{item.creator} </span>
      <span>{TARGET_ACTION[item.noticeType]} </span>
      <span className='fw-bold'>{item.company.name} </span>
      <span>Company</span>
    </div>
  );

  switch (item.noticeType) {
    case 'create_reminder':
      return reminderNotification(item);
    case 'update_reminder':
      return reminderNotification(item);
    case 'delete_reminder':
      return reminderNotification(item);
    case 'update_details':
      return detailsNotification(item);
    case 'create_company':
      return companyNotification(item);
    default: {
      return commentNotification(item);
    }
  }
};
