import React from 'react';

export const WasteCollectionCommercialWasteCollection = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path
          id='Vector'
          d='M7 3C6.7 3 6.4107 3.14086 6.2207 3.38086L2.2207 8.38086C2.0807 8.55086 2 8.77 2 9V17C2 17.55 2.45 18 3 18H3.05078C3.29818 19.6854 4.75007 21 6.5 21C8.24993 21 9.70182 19.6854 9.94922 18H14H14.0508C14.2982 19.6854 15.7501 21 17.5 21C19.2499 21 20.7018 19.6854 20.9492 18H22C22.2652 18 22.5195 17.8946 22.7071 17.7071C22.8946 17.5195 23 17.2652 23 17V12.3066C23 11.7511 22.8448 11.2069 22.5547 10.7344V10.7324L20.5176 7.42578C19.972 6.54004 19.0027 6 17.9629 6H14V4C14 3.45 13.55 3 13 3H9V9H7V3ZM14 8H17.9629C18.3111 8 18.632 8.17835 18.8145 8.47461L20.8516 11.7812C20.9488 11.9404 21 12.1229 21 12.3066V16H20.6445C20.0777 14.8247 18.8832 14 17.5 14C16.1168 14 14.9223 14.8247 14.3555 16H14V8ZM6.5 16C7.34027 16 8 16.6597 8 17.5C8 18.3403 7.34027 19 6.5 19C5.65973 19 5 18.3403 5 17.5C5 16.6597 5.65973 16 6.5 16ZM17.5 16C18.3403 16 19 16.6597 19 17.5C19 18.3403 18.3403 19 17.5 19C16.6597 19 16 18.3403 16 17.5C16 16.6597 16.6597 16 17.5 16Z'
        />
      </g>
    </svg>
  );
};
