import { companiesFragment } from '@packs/graphql/queries/company/fetchCompaniesList';

export const autocomplete = `
  query companyAutocompleteList(
    $categoryId: Int!,
    $keyWord: String,
    $companyType: String
  ) {
    companyAutocompleteList(
      categoryId: $categoryId,
      keyWord: $keyWord,
      companyType: $companyType
    ) {
      ${companiesFragment}
      errors
      totalCount
    }
  }
`;

export const autocompleteWiki = `
  query companyAutocompleteList(
    $categoryId: Int!,
    $keyWord: String,
    $companyType: String
  ) {
    companyAutocompleteList(
      categoryId: $categoryId,
      keyWord: $keyWord,
      companyType: $companyType
    ) {
      ${companiesFragment}
      errors
      totalCount
    }
  }
`;
