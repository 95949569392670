import React from 'react';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import PhoneInput from '@packs/components/PhoneInput/phoneInput';
import { SwitchTempField } from '@packs/components/SwitchFieldTemp';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { autoQuoteTooltip } from '@packs/lib/constants';

import AnimateHeight from 'react-animate-height';
import { Field } from 'redux-form';

export const AutoQuotes = ({ formAttributes }) => {
  const { currentUser } = useUserSelectors();

  const defaultCountry = currentUser?.country?.isoA2Code || 'ie';

  return (
    <Box>
      <Box sx={{ mt: 2, mb: 1 }}>
        <Field
          component={SwitchTempField}
          label={
            <Stack direction='row' spacing={1}>
              <Typography>Auto. quote</Typography>
              <Tooltip title={autoQuoteTooltip} arrow>
                <HelpOutlineIcon />
              </Tooltip>
            </Stack>
          }
          name='isAutoQuote'
          size='medium'
        />
      </Box>
      <AnimateHeight duration={250} height={formAttributes?.isAutoQuote ? 'auto' : 0}>
        <Stack>
          <Box sx={{ mt: 2, mb: 1 }}>
            <Field
              type='text'
              component={PhoneInput}
              name='phoneNumber'
              placeholder='Phone (ex. +353 123...)'
              countryCallingCodeEditable={false}
              defaultCountry={defaultCountry}
            />
          </Box>
        </Stack>
      </AnimateHeight>
    </Box>
  );
};
