import React, { useMemo } from 'react';
import { startCase } from 'lodash-es';
import cx from 'classnames';

import { postTypes } from '@shared/lib/constants';

export const PostBadge = ({ type }) => {
  const label = useMemo(() => {
    if (type === 'tip') {
      return startCase('tips');
    } else if (type === 'insight') {
      return 'Insights';
    } else if (type === 'vote') {
      return 'Votes';
    } else if (type === 'request') {
      return 'Requests';
    } else if (postTypes.some(item => item.value === type)) {
      return 'Q&A';
    }
  }, [type]);

  const typeClassName = useMemo(() => {
    if (type === 'tip') {
      return 'tip';
    } else if (type === 'insight') {
      return 'insight';
    } else if (type === 'vote') {
      return 'vote';
    } else if (type === 'request') {
      return 'request';
    } else if (postTypes.some(item => item.value === type)) {
      return 'qa';
    }

    return '';
  }, [type]);

  return <div className={cx('post-badge', typeClassName)}>{label}</div>;
};
