export default () => ({
  filtersWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    borderRadius: '16px',
    mb: 4,
    border: '1px solid rgba(0, 0, 0, 0.16)',
    px: {
      xs: 2,
      lg: 3
    },
    py: {
      xs: 3,
      lg: 4
    },
    background: '#FFF'
  },
  filtersTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  filterItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 2
  },
  filterCheckbox: {
    minWidth: '100px',
    '& .MuiFormControlLabel-label': {
      fontSize: '14px',
      fontWeight: '500',
      color: 'rgba(0, 0, 0, 0.60)'
    }
  },
  filterProgress: {
    height: '12px',
    '&.MuiLinearProgress-root': {
      borderRadius: '16px',
      border: '1px solid rgba(0, 0, 0, 0.16)',
      background: 'none'
    }
  },
  filterPercent: {
    minWidth: '40px'
  }
});
