import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { CategoryIconBox } from '@packs/components/MaterialUi/CategoryIconBox/CategoryIconBox';
import { useCategories } from '@packs/models/category/useCategories';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import useStyles from './styles';

const { secondary } = colors;

export const CategoryTopBar = ({ description }) => {
  const styles = useStyles();
  const { isMobile } = useWindowSize();
  const { currentCategory } = useCategories();

  return (
    <Box sx={styles.wrapper}>
      <CategoryIconBox
        containerSize={isMobile ? '40px' : '88px'}
        iconSize={isMobile ? '28px' : '56px'}
        category={currentCategory}
        backgroundColor={secondary.transparent}
        color={secondary.main}
        borderRadius='16px'
      />
      <Box sx={styles.contentSection}>
        <Typography variant='h4'>Request a quote in {currentCategory.name}</Typography>
        <Typography variant='subtitle1' sx={styles.subTitle}>
          {description}
        </Typography>
      </Box>
    </Box>
  );
};
