import React, { useMemo, useRef, useState } from 'react';
import _ from 'lodash-es';
import cx from 'classnames';
import moment from 'moment';

import colors from '@packs/assets/theme/base/colors';
import { SignInModalLink } from '@packs/components';
import { Avatar, PostBadge } from '@packs/components/UI';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import InsuranceIcon from '@packs/images/icons/InsuranceIcon/insuranceIcon';
import LikeIcon from '@packs/images/icons/LikeIconEmpty';
import ShareIcon from '@packs/images/icons/ShareIcon';
import CommentIcon from '@packs/images/icons/UI/CommentIcon';
import ArrowDownIcon from '@packs/images/svg/ui/arrow-down-gray.svg';
import { commentableType } from '@packs/lib/constants';
import { divideStarRating } from '@packs/lib/helpers';
import { shortSecondName } from '@packs/lib/helpers/additionalHelpers';
import { useStore } from '@packs/stores';
import { RatingMUI } from '@shared/components/RatingMUI/RatingMUI';
import { SUB_ICONS } from '@shared/helpers/subCategoryIcon';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { SocialBlock } from '../../Business/ReviewsList/SocialBlock';
import CommentsList from '../CommentsList';
import { InsightCompany } from '../InsightCompany';
import { AnswerFormComment } from '../InsightForm/InsightForm';

import AnimateHeight from 'react-animate-height';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

export const InsightItem = ({
  insight,
  questionsContainerOffset,
  first = false,
  postType,
  withOutInsightCompany,
  isProfile = false,
  owner = null
}) => {
  const questionItemRef = useRef(null);
  const descriptionRef = useRef(null);
  const { currentUser } = useUserSelectors();
  const { insightsCompanyStore } = useStore();
  const { isMobile } = useWindowSize();
  const {
    id,
    user,
    title,
    category,
    createdAt,
    isHelpful,
    companyUser,
    helpfulCount,
    reviewCommentsCount,
    feedback,
    rating = 0,
    purchaseScore = 0,
    anyCompanyRate = 0,
    anyCompanyRateComment,
    isVote,
    tokenVerified
  } = insight;
  const [isOpenAnswers, setIsOpenAnswers] = useState(false);
  const [isOpenShare, setIsOpenShare] = useState(false);
  const [descriptionIsOpen, setDescriptionIsOpen] = useState(false);

  const currentUrl = location.origin + location.pathname;
  const insightOwner = insight.user || {};
  const avatarUrl = companyUser?.company?.avatarUrl || owner?.avatarUrl || user?.avatarUrl || '';
  const displayName = companyUser?.company?.name || owner?.displayName || user?.displayName || '';
  const categoryImg = SUB_ICONS({ category, fill: colors?.category[category.root.enName]?.main });
  const comment = useMemo(() => {
    if (isProfile && isVote) {
      return '';
    }

    return anyCompanyRateComment || _.get(feedback, 'insightsComment') || '';
  }, [anyCompanyRateComment, feedback]);

  const divRating = useMemo(() => {
    return isVote ? divideStarRating(purchaseScore || anyCompanyRate) : divideStarRating(rating);
  }, [rating, purchaseScore]);

  const toggleDescription = () => {
    setDescriptionIsOpen(!descriptionIsOpen);
  };

  const toggleAnswers = () => {
    setIsOpenAnswers(!isOpenAnswers);
  };

  const onToggleHelpful = () => {
    if (currentUser.id !== insightOwner?.id) {
      insightsCompanyStore.toggleHelpfulInsight({
        attrs: {
          feedbackType: 'review',
          renewalDetailId: insight.id
        },
        postId: insight.id
      });
    }
  };

  const submitCallback = () => {
    window.scrollTo({
      top:
        questionItemRef?.current?.offsetTop + questionItemRef?.current?.clientHeight + questionsContainerOffset - 245,
      behavior: 'smooth'
    });
  };

  const descriptionSize = comment.length >= (isMobile ? 60 : 214);

  return (
    <div className='questions-item' ref={questionItemRef}>
      <div className='top-part d-flex align-items-start justify-content-between'>
        <div className='user-info'>
          <div className='img-container'>
            <Avatar avatarUrl={avatarUrl} placeholder={displayName} size={48} fullSize withBorder withBG />
          </div>
          <div className='pl-15 flex-grow-1'>
            <div className='user-name fs-14 fw-600 lh-5 position-relative'>
              <div className='name'>{shortSecondName(displayName)}</div>
            </div>
            <div className='fs-12 lh-5 secondary-text'>{(createdAt && moment(createdAt).fromNow()) || '-'}</div>
          </div>
        </div>
      </div>
      <div className='question-info'>
        <div className='d-flex align-items-center justify-content-between mb-16'>
          <PostBadge type={postType} />
          <div className='review-rating d-flex align-items-center ml-16'>
            <RatingMUI value={divRating} max={isMobile ? 1 : 5} />
            <div className='rating-number fs-13 ml-8'>
              {(divRating && (_.isInteger(divRating) ? `${divRating}.0` : divRating)) || 0.0}
            </div>
          </div>
          <div className='review-type fs-13 ml-4 mr-auto'>{_.startCase(feedback?.feedbackType)}</div>
          <div className='d-flex align-items-center'>
            {categoryImg && (
              <div className='d-flex align-items-center'>
                {categoryImg}
                {!isMobile && <div className='ml-10 fs-14'>{category?.name}</div>}
              </div>
            )}
            {tokenVerified && (
              <div className={cx('verified-business fs-12', isMobile ? 'ml-8' : 'ml-16')}>
                <InsuranceIcon />
                {!isMobile && <span>Verified</span>}
              </div>
            )}
          </div>
        </div>

        {title && <div className='question-question fw-700 mb-8'>{title}</div>}
        {comment.length > 0 && (
          <>
            <div
              ref={descriptionRef}
              className={cx(
                'question-description lh-5',
                descriptionIsOpen || (!descriptionIsOpen && !descriptionSize) ? 'mb-20' : 'mb-8',
                { descriptionIsOpen: descriptionIsOpen || (!descriptionIsOpen && !descriptionSize) }
              )}
            >
              {comment}
            </div>
            {descriptionSize && (
              <div className='d-flex mb-8'>
                <div
                  className={cx('show-description d-flex align-items-center secondary-text fs-16 cursor-pointer', {
                    active: descriptionIsOpen
                  })}
                  onClick={toggleDescription}
                >
                  <span className='mr-10'>{descriptionIsOpen ? 'Read less' : 'Read more'}</span>
                  <img src={ArrowDownIcon} alt='down' />
                </div>
              </div>
            )}
          </>
        )}
        {!withOutInsightCompany && <InsightCompany company={insight.anyCompany} category={category} />}
      </div>
      <div className={cx('bottom-part d-flex align-items-center', !isMobile && 'justify-content-between')}>
        <div className='answer-action-btn comments-btn mr-5' onClick={toggleAnswers}>
          <CommentIcon />
          <span>
            {!isMobile && 'Comments '}
            {reviewCommentsCount}
          </span>
        </div>
        <div className={cx('d-flex align-items-center', isMobile && 'justify-content-between flex-grow-1')}>
          {currentUser?.id ? (
            <div className={cx('answer-action-btn mr-20', { active: isHelpful })} onClick={onToggleHelpful}>
              <LikeIcon />
              <span>
                {!isMobile && 'Helpful'} {helpfulCount || 0}
              </span>
            </div>
          ) : (
            <SignInModalLink>
              <div className='answer-action-btn mr-20'>
                <LikeIcon />
                <span>
                  {!isMobile && 'Helpful'} {helpfulCount || 0}
                </span>
              </div>
            </SignInModalLink>
          )}
          <div className='share-dropdown'>
            <Dropdown isOpen={isOpenShare} toggle={() => setIsOpenShare(!isOpenShare)}>
              <DropdownToggle tag='div' className={cx('answer-action-btn', { active: isOpenShare })}>
                <ShareIcon />
                {!isMobile && <span>Share</span>}
              </DropdownToggle>
              <DropdownMenu center>
                <SocialBlock url={currentUrl} />
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>

      <AnimateHeight duration={250} height={isOpenAnswers ? 'auto' : 0}>
        <AnswerFormComment
          form={`insight-from-${id}`}
          submitBtnText='Add a comment'
          placeholder='Add a comment...'
          commentableType={commentableType.post}
          commentableId={id}
          postId={id}
          submitCallback={submitCallback}
          createComment={insightsCompanyStore.createCommentInsight}
        />
        <CommentsList postId={id} isOpen={isOpenAnswers} questionsContainerOffset={questionsContainerOffset} />
      </AnimateHeight>
    </div>
  );
};
