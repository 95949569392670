import React from 'react';

import { Box, FormHelperText, Stack, TextField, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { BusinessVoteModalForm } from '@packs/components/BusinessVoteModal/BusinessVoteModal';
import Rate from '@packs/components/RatingFormData/components/RateField/components/Rate';
import { stepsCollection } from '@packs/components/RatingFormData/components/RateField/components/Rate/Rate';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { Controller, useFormContext } from 'react-hook-form';

const TotalRate = () => {
  const { isMobile } = useWindowSize();

  const { control } = useFormContext<BusinessVoteModalForm>();

  return (
    <Stack spacing={3}>
      <Controller
        name='anyCompanyRate'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Box>
            <Typography variant='body2' sx={{ mb: 1 }}>
              How likely are you to recommend this business to a friend or colleague?
            </Typography>
            {isMobile ? (
              <Select value={field.value} onChange={field.onChange} fullWidth>
                {stepsCollection.map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <Rate value={field.value} onChange={field.onChange} />
            )}

            <FormHelperText error={!!error?.message}>{error?.message}</FormHelperText>
          </Box>
        )}
      />
      <Controller
        name='anyCompanyRateComment'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Box>
            <Typography variant='body2' sx={{ mb: 1 }}>
              Please add comment
            </Typography>
            <TextField
              placeholder='Enter cour comment'
              multiline
              rows={4}
              fullWidth
              value={field.value}
              onChange={field.onChange}
              error={!!error?.message}
              helperText={
                <Box component='span' sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>{error?.message || 'You need to enter a minimum number of charecters of 25'}</span>
                  <span>{`${(field.value as string).length} / 200`}</span>
                </Box>
              }
            />
          </Box>
        )}
      />
    </Stack>
  );
};

export default TotalRate;
