import React, { useState } from 'react';

import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcons from '@mui/icons-material/KeyboardArrowUpRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { getCurrency } from '@packs/lib/constants';
import { useCategories } from '@packs/models/category/useCategories';
import { getWikiList, WIKI_LIMIT, WIKI_LIMIT_MOBILE } from '@packs/models/company/constants';
import { useStore } from '@packs/stores/base';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import useStyles from './styles';

import size from 'lodash-es/size';
import { MacScrollbar } from 'mac-scrollbar';

export const WikiList = ({ company }) => {
  const { countryCode } = useStore();
  const { currentCategory } = useCategories();
  const styles = useStyles();
  const { isMobile } = useWindowSize();
  const [showAll, setShowAll] = useState(false);

  const currency = getCurrency(countryCode);
  const wikiList = getWikiList({ company, currentCategory, currency });
  const wikiSliceCount = isMobile ? WIKI_LIMIT_MOBILE : WIKI_LIMIT;
  const slicedWikiList = wikiList?.list?.slice(0, wikiSliceCount) || [];
  const restWikiList = wikiList?.list?.slice(wikiSliceCount, wikiList.count) || [];

  const toggleShowAll = () => {
    setShowAll(prevState => !prevState);
  };

  const Item = ({ title, description, sx }) => {
    return (
      <Box sx={{ ...styles.item, ...sx }} key={title + description}>
        <Box>
          <Typography variant={isMobile ? 'body3' : 'body5'} sx={{ color: sx?.color || colors.text.secondary }}>
            {title}
          </Typography>
        </Box>
        <Typography component='div' variant={isMobile ? 'body2' : 'body6'}>
          <MacScrollbar
            style={{ flex: 1, maxHeight: '100px' }}
            trackStyle={horizontal => ({
              [horizontal ? 'height' : 'width']: 0,
              right: 2,
              border: 0
            })}
            thumbStyle={horizontal => ({ [horizontal ? 'height' : 'width']: 4 })}
            suppressAutoHide
          >
            {description}
          </MacScrollbar>
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Box sx={styles.wrapper}>
        {slicedWikiList.map(({ title, description, sx = {} }) => (
          <Item key={title + description} {...{ title, description, sx }} />
        ))}
        {size(restWikiList) > 0 &&
          showAll &&
          restWikiList.map(({ title, description }) => <Item key={title + description} {...{ title, description }} />)}
      </Box>
      {size(restWikiList) > 0 && (
        <Box sx={styles.btnContainer}>
          <Button
            variant='text'
            color='dark'
            size='small'
            endIcon={showAll ? <KeyboardArrowUpRoundedIcons /> : <KeyboardArrowDownRoundedIcon />}
            onClick={toggleShowAll}
          >
            See {showAll ? 'less' : 'all'}
          </Button>
        </Box>
      )}
    </>
  );
};
