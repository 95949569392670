import React from 'react';

export const Local_servicesElectricians = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_62_146)'>
        <g>
          <path d='M9.81206 6.80649L8.29739 5.29182C8.61323 4.11422 8.40314 2.84944 7.7062 1.827C6.62133 0.234706 4.44605 -0.416247 2.68205 0.273144C2.4623 0.358972 2.30025 0.55055 2.25084 0.781925C2.20209 1.01335 2.27283 1.25363 2.439 1.42186L3.72164 2.71828C3.99216 2.9888 3.99858 3.43008 3.72164 3.70707C3.45661 3.9721 3.00066 3.97486 2.73014 3.7043L1.43648 2.42443C1.26755 2.25755 1.0245 2.18822 0.796547 2.23627C0.565125 2.28572 0.373594 2.44777 0.287766 2.66747C0.0967969 3.15432 0 3.67135 0 4.20418C0 5.60152 0.688688 6.90544 1.84158 7.69163C2.86261 8.38791 4.12744 8.59936 5.30639 8.28282L7.31972 10.2961L9.81206 6.80649Z' />
          <path d='M8.90809 21.083C8.60392 20.9511 8.43292 20.6249 8.49817 20.2995L9.73619 14.1094H7.78131C7.51764 14.1094 7.27595 13.9618 7.15576 13.7269C7.03492 13.4921 7.0562 13.2092 7.2107 12.9956L14.2399 3.15393C14.4335 2.88477 14.7892 2.78657 15.092 2.91702C15.3962 3.04888 15.5672 3.37504 15.502 3.70049L14.2639 9.89062H16.2188C16.4818 9.89062 16.7228 10.0376 16.8437 10.271C16.9638 10.5044 16.9446 10.786 16.7928 11.0002L9.76023 20.8461C9.56354 21.1202 9.20758 21.2122 8.90809 21.083Z' />
          <path d='M22.1577 16.2931C21.1339 15.5948 19.8712 15.3874 18.6936 15.7019L16.6807 13.689L14.188 17.179L15.7026 18.6936C15.3868 19.8705 15.5969 21.136 16.2938 22.1584C17.08 23.3113 18.3839 24 19.7812 24C20.3141 24 20.8311 23.9032 21.318 23.7123C21.5377 23.6265 21.6997 23.4349 21.7492 23.2035C21.7979 22.9721 21.7272 22.7318 21.561 22.5636L20.2784 21.2672C20.0078 20.9967 20.0014 20.5554 20.2784 20.2784C20.5434 20.0134 20.9993 20.0106 21.2699 20.2812L22.5635 21.5611C22.7325 21.728 22.9734 21.798 23.2035 21.7493C23.4349 21.6998 23.6264 21.5378 23.7122 21.318C23.9032 20.8311 24 20.3141 24 19.7812C24 18.3832 23.3113 17.0793 22.1577 16.2931Z' />
        </g>
      </g>
      <defs>
        <clipPath>
          <rect width='24' height='24' />
        </clipPath>
      </defs>
    </svg>
  );
};
