import React from 'react';
import cx from 'classnames';
import moment from 'moment';

import Delete from '@packs/images/icons/Delete';
import MyNotesIcon from '@packs/images/icons/MyNotesIcon';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { toastr } from '@packs/lib/helpers';
import { useStore } from '@packs/stores';

export const ReminderItem = ({ reminder, category, company }) => {
  const { smartRemindersStore } = useStore();
  const { onEditReminder, onDeleteReminder } = useModalsContext();

  const clickDeleteReminder = () => {
    const handleDeleteReminder = rem => {
      smartRemindersStore.destroy(
        { ids: [rem.id] },
        () => {
          toastr.success('Smart reminder deleted');
        },
        () => {
          toastr.error('Smart reminder NOT deleted');
        }
      );
    };
    onDeleteReminder(reminder, handleDeleteReminder);
  };

  return (
    <div className='business-reminder-item'>
      <div className={cx('reminder-status', reminder?.status)}></div>
      {moment(reminder.triggeredAt).format('DD MMM YYYY')}
      <div className='business-reminder-item-actions d-flex align-items-start gap-8 ml-a'>
        <div
          className='cursor-pointer'
          onClick={() =>
            onEditReminder(reminder, {
              parentType: 'calendar',
              category,
              company
            })
          }
        >
          <MyNotesIcon />
        </div>
        <div className='delete-icon cursor-pointer' onClick={clickDeleteReminder}>
          <Delete />
        </div>
      </div>
    </div>
  );
};
