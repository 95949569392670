import React from 'react';

export const BusinessFarmInsurance = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path d='M18 10.346V6.535C18 6.158 17.788 5.813 17.452 5.643L12.452 3.108C12.168 2.964 11.832 2.964 11.548 3.108L6.548 5.643C6.212 5.814 6 6.158 6 6.535V10.346L2.545 12.113C2.21 12.284 2 12.628 2 13.003V19C2 20.105 2.895 21 4 21H9V17C9 16.448 9.448 16 10 16H14C14.552 16 15 16.448 15 17V21H20C21.105 21 22 20.105 22 19V13.003C22 12.628 21.79 12.284 21.455 12.113L18 10.346ZM11 8H13C13.552 8 14 8.448 14 9V11C14 11.552 13.552 12 13 12H11C10.448 12 10 11.552 10 11V9C10 8.448 10.448 8 11 8Z' />
      </g>
    </svg>
  );
};
