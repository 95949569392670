import React, { FC } from 'react';

import { TypographyProps } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { IOption } from '@packs/interfaces/rootInterfaces';

interface ISelectMUIProps extends Omit<SelectProps, 'onChange'> {
  value: IOption | undefined;
  options: IOption[];
  onChange: (e: SelectChangeEvent<unknown>, val: IOption | undefined) => void;
  width?: string;
  withTitle?: boolean;
}
export const SelectMUI: FC<ISelectMUIProps> = ({
  value,
  onChange,
  options,
  label,
  size = 'medium',
  sx = {},
  width = 'auto',
  withTitle = false,
  ...rest
}) => {
  const handleChange = event => {
    const selectedOption = options.find(option => option.value === event.target.value);
    if (onChange) {
      onChange(event, selectedOption);
    }
  };

  return (
    <Box>
      {withTitle && label && (
        <Box sx={{ mb: size === 'small' ? 0.5 : 1 }}>
          <Typography variant={(size === 'small' ? 'body6' : 'body2') as TypographyProps['variant']}>
            {label}
          </Typography>
        </Box>
      )}
      <FormControl fullWidth>
        {!withTitle && <InputLabel>{label}</InputLabel>}
        <Select
          {...rest}
          value={value?.value}
          label={withTitle ? null : label}
          size={size}
          onChange={handleChange}
          sx={{ ...sx, width }}
        >
          {options.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
