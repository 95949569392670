import React, { FC, useState } from 'react';
import { get } from 'lodash-es';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import Typography from '@mui/material/Typography';
import api from '@packs/apis/currentUser';
import colors from '@packs/assets/theme/base/colors';
import { Loader } from '@packs/components/Loader';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { usePath } from '@packs/layouts/constants';
import { toastr } from '@packs/lib/helpers';
import { useDetails } from '@packs/models/policy/useDetails';
import { SAVE_DATE_FORMAT } from '@packs/screens/PersonalizeResults/formHooks/defaults';
import { useStore } from '@packs/stores';

import { WithAutoQuotes } from './WithAutoQuotes';

import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';

const { success } = colors;

type ThankYouModalProps = {
  phoneNumber: string;
};
export const ThankYouModal: FC<ThankYouModalProps> = ({ phoneNumber }) => {
  const { smartRemindersStore } = useStore();
  const { currentPolicy } = useDetails();
  const { currentUser } = useUserSelectors();
  const { toggleVerificationModalOpen } = useModalsContext();
  const navigate = useNavigate();
  const { LEADERBOARD_PATH } = usePath();
  const { currentCategory } = useStore();

  const [loading, setLoading] = useState(false);

  const initialValues = {
    triggeredAt: moment(currentPolicy?.smartReminder?.triggeredAt) || moment(),
    renewalDetailId: currentPolicy?.id,
    recurringTime: currentPolicy?.smartReminder?.recurringTime,
    isAutoQuote: currentPolicy?.smartReminder?.isAutoQuote || false,
    phoneNumber
  };

  const handleSubmitVerification = (data, reminder) => {
    toggleVerificationModalOpen({ modalProps: {} }, true);
    submitHandler({ ...data, verified: true }, reminder);
  };

  const handleVerification = async (data, reminder) => {
    setLoading(true);

    const resp = await api.phoneVerify({ phoneNumber: data?.phoneNumber.replace(/\s+/g, '') });
    if (resp?.errors) {
      toastr.error(resp?.errors);
      setLoading(false);
    } else {
      toggleVerificationModalOpen({
        modalProps: {
          handleSubmitVerification: () => handleSubmitVerification(data, reminder),
          phoneNumber: data?.phoneNumber
        }
      });
      setLoading(false);
    }
  };

  const submitHandler = async (data, reminder = null) => {
    const {
      triggeredAt,
      aliasName,
      reminderType = 'select',
      verified = false,
      phoneNumber,
      isAutoQuote,
      topProvider,
      topAgencyBroker,
      topComparisonSite,
      autoQuoteCompany,
      recurringTime
    } = data;
    const triggeredAtValue = new Date(triggeredAt);
    const renewalDetailId = currentPolicy?.id || null;

    if (moment(triggeredAtValue).format(SAVE_DATE_FORMAT) === moment().format(SAVE_DATE_FORMAT)) {
      toastr.error('Reminder should be in the future');
      return;
    }

    const callback = resp => {
      if (currentPolicy?.smartReminder?.id) {
        toastr.success('Smart reminder updated');
      } else {
        toastr.success('Smart reminder created');
      }
      navigate(LEADERBOARD_PATH);
    };

    const payload = {
      renewalDetailId,
      categoryId: currentCategory.id,
      triggeredAt: moment(triggeredAtValue).format(SAVE_DATE_FORMAT),
      aliasName,
      reminderType,
      isAutoQuote,
      topProvider,
      topAgencyBroker,
      topComparisonSite,
      autoQuoteCompanyId: autoQuoteCompany?.value || null,
      phoneNumber: phoneNumber?.replace(/\s+/g, '') || null,
      recurringTime: null
    };

    if (payload?.renewalDetailId !== null && (payload?.renewalDetailId <= 0 || payload?.renewalDetailId === 'new-0')) {
      payload.renewalDetailId = null;
    }

    if (payload.triggeredAt && recurringTime) {
      payload.recurringTime = recurringTime;
    }

    const phoneVerification =
      phoneNumber && get(currentUser, 'verifiedNumbers', []).some(item => item === phoneNumber.replace(/\s+/g, ''));

    if (isAutoQuote && !phoneVerification && !verified) {
      handleVerification(data, reminder);

      return;
    }

    const reminderId = currentPolicy?.smartReminder?.id;
    if (reminderId) {
      smartRemindersStore.update({ id: reminderId, ...payload }, callback);
    } else {
      smartRemindersStore.create(payload, callback);
    }
  };

  return (
    <div className='request-quote-thank-you-wrapper'>
      {loading && <Loader />}
      <div className='request-quote-thank-you'>
        <Typography
          variant='h6'
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1, mb: 2, textAlign: 'center' }}
        >
          <CheckCircleRoundedIcon sx={{ color: success.main }} />
          Your request has been sent!
        </Typography>
        <WithAutoQuotes onSubmit={submitHandler} initialValues={initialValues} />
      </div>
    </div>
  );
};
