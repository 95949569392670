/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React base styles
import borders from '@crm/assets/theme/base/borders';
import colors from '@crm/assets/theme/base/colors';
import typography from '@crm/assets/theme/base/typography';
// Material Dashboard 2 PRO React helper functions
import pxToRem from '@shared/assets/theme/functions/pxToRem';

const { size, fontWeightBold, fontWeightRegular } = typography;
const { borderRadius } = borders;
const { dark, white } = colors;

const tab = {
  variants: [
    {
      props: { variant: 'standard' },
      style: {
        color: `${dark.main}`,
        textTransform: 'none',
        fontSize: '15px',
        fontWeight: 700,

        '&.Mui-selected': {
          color: `${dark.main} !important`
        }
      }
    },
    {
      props: { variant: 'buttonTabs' },
      style: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        flex: '1 1 auto',
        textAlign: 'center',
        maxWidth: 'unset !important',
        minWidth: 'unset !important',
        minHeight: 'unset !important',
        fontSize: size.md,
        fontWeight: fontWeightRegular,
        textTransform: 'none',
        lineHeight: 'inherit',
        padding: pxToRem(4),
        borderRadius: borderRadius.lg,
        color: `${dark.main} !important`,
        opacity: '1 !important',

        '& .material-icons, .material-icons-round': {
          marginBottom: '0 !important',
          marginRight: pxToRem(6)
        },

        '& svg': {
          marginBottom: '0 !important',
          marginRight: pxToRem(6)
        },
        '&.Mui-selected': {
          boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 6px 0px rgba(0, 0, 0, 0.02)'
        },
        labelIcon: {
          paddingTop: pxToRem(4)
        }
      }
    }
  ]
};

export default tab;
