import React from 'react';

export const GovernmentPassport = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g id='Government'>
        <path
          id='Vector'
          d='M2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4H4C2.895 4 2 4.895 2 6ZM8 8C9.105 8 10 8.895 10 10C10 11.105 9.105 12 8 12C6.895 12 6 11.105 6 10C6 8.895 6.895 8 8 8ZM5 15.1C5 14.102 6.999 13.6 8 13.6C9.001 13.6 11 14.102 11 15.1V16H5V15.1ZM18 11H14C13.448 11 13 10.552 13 10C13 9.448 13.448 9 14 9H18C18.552 9 19 9.448 19 10C19 10.552 18.552 11 18 11ZM18 15H14C13.448 15 13 14.552 13 14C13 13.448 13.448 13 14 13H18C18.552 13 19 13.448 19 14C19 14.552 18.552 15 18 15Z'
        />
      </g>
    </svg>
  );
};
