import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { IPolicy, IPolicyPricesQuestions } from '@packs/interfaces/rootInterfaces';
import { CATEGORY_PER_MONTH } from '@packs/models/category/constants';
import { useCategories } from '@packs/models/category/useCategories';
import { DYNAMIC_OPTIONS_TYPES } from '@packs/models/policy/constants';
import { fetchDynamicFormOptions } from '@packs/screens/PersonalizeResults/formHooks/changeCallbacks';
import {
  getDayOption,
  getMonthOption,
  selectOptions as dateSelectOptions
} from '@packs/screens/PersonalizeResults/formHooks/dateTimeFunctions';
import { defaultFormValues, SAVE_DATE_FORMAT } from '@packs/screens/PersonalizeResults/formHooks/defaults';
import { useStore } from '@packs/stores';

import set from 'lodash-es/set';
import moment from 'moment/moment';
import queryString from 'query-string';

export const useOnDetailRetrieve = ({ simpleForm }) => {
  const { currentCategory } = useCategories();
  const { detailsFormStore } = useStore();
  const { currentUser } = useUserSelectors();
  const { hideCliteria, hideBlock, showFields } = detailsFormStore;

  const onDetailRetrieve = async ({
    renewalDetail,
    setRemount
  }: {
    renewalDetail: IPolicy;
    setRemount: (arg: boolean) => void;
  }) => {
    let pricesQuestionsDefault: IPolicyPricesQuestions = {
      price: { period: 'per_year', value: 0 }
    };
    const perMonth = CATEGORY_PER_MONTH;
    if (perMonth.includes(currentCategory?.code) || perMonth.includes(currentCategory?.root?.code)) {
      pricesQuestionsDefault = { price: { period: 'per_month', value: null } };
    }
    const criteriaQuestions = renewalDetail?.criteriaQuestions || {};
    const pricesQuestions = renewalDetail?.pricesQuestions || pricesQuestionsDefault || {};

    const { city, policyId } = queryString.parse(location?.search);

    if (city) {
      criteriaQuestions.location = city;
    } else {
      criteriaQuestions.location = criteriaQuestions.location || currentUser.cityName || currentUser.state;
    }

    const { phoneNumber } = renewalDetail?.smartReminder?.quoteRequest || {};

    const newFormValues = {
      ...defaultFormValues,
      aliasName: renewalDetail?.smartReminder?.aliasName,
      buyingType: renewalDetail?.buyingType,
      anyCompanyId: renewalDetail?.anyCompany?.id,
      anyCompanyRate: renewalDetail?.anyCompanyRate,
      anyCompanyRateComment: renewalDetail?.anyCompanyRateComment || '',
      sourceProviderCompanyId: renewalDetail?.sourceProviderCompany?.id,
      criteriaQuestions,
      pricesQuestions,
      policySelect: policyId ? +policyId : renewalDetail?.id || `new-${renewalDetail?.index}`,
      triggeredAtDate: {
        month: '',
        day: ''
      },
      addSmartReminder: simpleForm ? true : !!renewalDetail?.smartReminder?.id,
      isAutoQuote: renewalDetail?.smartReminder?.isAutoQuote || false,
      phoneNumber: phoneNumber || currentUser.verifiedNumbers?.[0] || '',
      recurringTime: renewalDetail?.smartReminder?.recurringTime || null,
      lastBuy: renewalDetail?.lastBuy
    };

    const reminderTriggeredAt = renewalDetail?.smartReminder?.triggeredAt;

    let formOpt = { ...detailsFormStore.formOptions };
    const momentTriggeredAt =
      renewalDetail?.smartReminder?.id && reminderTriggeredAt ? moment(reminderTriggeredAt) : moment().add(1, 'year');
    const options = dateSelectOptions(momentTriggeredAt);

    set(formOpt, 'triggeredAtDate.day', options.day);
    set(formOpt, 'triggeredAtDate.month', options.yearMonth);

    // TODO use  Promise.all to wait all options
    // car Model
    if (criteriaQuestions.carMake) {
      const modelOps = await fetchDynamicFormOptions({
        type: DYNAMIC_OPTIONS_TYPES.carModel,
        make: criteriaQuestions.carMake
      });
      formOpt = { ...detailsFormStore.formOptions };
      set(formOpt, 'criteriaQuestions.carModel', modelOps);
    }
    let opts: { value: string; label: string }[] = [];
    // car Trim
    let hasCarTrimOptions = false;
    if (criteriaQuestions.carModel) {
      opts = await fetchDynamicFormOptions({
        type: DYNAMIC_OPTIONS_TYPES.carTrim,
        model: criteriaQuestions.carModel,
        make: criteriaQuestions.carMake
      });
      formOpt = { ...detailsFormStore.formOptions };
      set(formOpt, 'criteriaQuestions.carTrim', opts);
      hasCarTrimOptions = opts.length > 0;
    }
    // Model
    if (criteriaQuestions.make) {
      opts = await fetchDynamicFormOptions({ type: DYNAMIC_OPTIONS_TYPES.model, make: criteriaQuestions.make });
      formOpt = { ...detailsFormStore.formOptions };
      set(formOpt, 'criteriaQuestions.model', opts);
    }
    // Trim
    let hasTrimOptions = false;
    if (criteriaQuestions.model) {
      opts = await fetchDynamicFormOptions({
        type: DYNAMIC_OPTIONS_TYPES.trim,
        model: criteriaQuestions.model,
        make: criteriaQuestions.make
      });
      formOpt = { ...detailsFormStore.formOptions };
      set(formOpt, 'criteriaQuestions.trim', opts);
      hasTrimOptions = opts.length > 0;
    }

    set(newFormValues, 'triggeredAtDate.day', getDayOption(momentTriggeredAt));
    set(newFormValues, 'triggeredAtDate.month', getMonthOption(momentTriggeredAt));
    set(newFormValues, 'triggeredAt', momentTriggeredAt.format(SAVE_DATE_FORMAT));

    if (criteriaQuestions) {
      hideCliteria.mobileModel = !criteriaQuestions.mobileMake;
      hideCliteria.model = !criteriaQuestions.make;
      hideCliteria.vehicleAge = !criteriaQuestions.make;
      hideCliteria.trim = !(criteriaQuestions.model && criteriaQuestions.make && hasTrimOptions);
      hideCliteria.carModel = !criteriaQuestions.carMake;
      hideCliteria.carTrim = !(criteriaQuestions.carModel && criteriaQuestions.carMake && hasCarTrimOptions);
      hideCliteria.claimWithBussiness = !criteriaQuestions.claimWithBussiness;
      hideCliteria.claimRate = !criteriaQuestions.claimWithBussiness;

      if (currentCategory?.root?.code) {
        hideCliteria.price = currentCategory?.root?.code === 'energy';
      }

      if (criteriaQuestions.isCurrentCost) {
        hideCliteria.price = false;
        showFields.price = true;
      }

      detailsFormStore.setHideCliteria(hideCliteria);
    }

    if (renewalDetail?.id) {
      hideBlock.policySelect = false;
      hideBlock.lastUpdated = false;
      detailsFormStore.setHideBlock(hideBlock);
    } else {
      hideBlock.lastUpdated = true;
    }

    showFields.dontRememberProvider = !newFormValues.sourceProviderCompanyId;

    if (newFormValues.anyCompanyId) {
      showFields[renewalDetail?.anyCompany?.companyType] = true;
      detailsFormStore.setShowFields(showFields);
    }

    detailsFormStore.setFormValues(newFormValues);
    setRemount && setRemount(true);
    setTimeout(() => setRemount && setRemount(false), 10);
  };

  return {
    onDetailRetrieve
  };
};
