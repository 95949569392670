import React, { useEffect, useMemo, useState } from 'react';
import { get } from 'lodash-es';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { CommentItem } from '../CommentItem/CommentItem';

import { observer } from 'mobx-react';

export const CommentsList = observer(
  ({ postId, isOpen, insightsCompanyStore, questionsContainerOffset, currentUser, toggleSignUpModal }) => {
    const [loading, setLoading] = useState(false);

    const answers = useMemo(() => {
      return get(insightsCompanyStore.answers, `${postId}`, []);
    }, [insightsCompanyStore.answers]);

    useEffect(() => {
      if (isOpen) {
        setLoading(true);
        insightsCompanyStore.loadAnswersInsight({ renewalDetailId: +postId }).finally(() => {
          setLoading(false);
        });
      }
    }, [isOpen]);

    if (loading) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '300px' }}>
          <CircularProgress />
        </Box>
      );
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, py: 2 }}>
        {answers.map(answer => (
          <CommentItem
            key={answer?.id}
            postId={postId}
            comment={answer}
            questionsContainerOffset={questionsContainerOffset}
            insightsCompanyStore={insightsCompanyStore}
            currentUser={currentUser}
            toggleSignUpModal={toggleSignUpModal}
          />
        ))}
      </Box>
    );
  }
);
