import { Form } from './form';

import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';

const validate = ({ phoneNumber, check, address, postalCode }) => {
  const errors = {};

  if (!phoneNumber) {
    errors.phoneNumber = 'Required';
  } else if (!isPossiblePhoneNumber(phoneNumber.replace(/\s+/g, ''))) {
    errors.phoneNumber = 'Phone number is not valid';
  }
  if (!check) errors.check = 'Required';
  if (!address) errors.address = 'Required';
  if (!postalCode) errors.postalCode = 'Required';

  return errors;
};

const mapStateToProps = (state, props) => ({ formAttributes: getFormValues(props.form)(state) });

const createReduxForm = reduxForm({ form: 'requestQuote', validate, touchOnBlur: false });

export default createReduxForm(connect(mapStateToProps)(Form));
