import {
  fetchMyOfferDetail,
  fetchMyOffers,
  fetchMyOffersFeatured,
  offerOtherList
} from '@packs/graphql/queries/myOffers';
import { graphQLRequest } from '@packs/lib/utils';

const api = {
  fetchMyOffers: variables =>
    graphQLRequest({
      query: fetchMyOffers,
      variables
    }),
  fetchMyOffersFeatured: variables =>
    graphQLRequest({
      query: fetchMyOffersFeatured,
      variables
    }),
  fetchMyOffersDetailed: variables =>
    graphQLRequest({
      query: fetchMyOfferDetail,
      variables
    }),
  fetchOtherOffers: variables =>
    graphQLRequest({
      query: offerOtherList,
      variables
    })
};

export default api;
