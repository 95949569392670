import React from 'react';

export const BusinessBusinessMobile = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path
          id='Vector'
          d='M19 20.5V3.5C19 2.12 17.88 1 16.5 1H7.5C6.12 1 5 2.12 5 3.5V20.5C5 21.88 6.12 23 7.5 23H16.5C17.88 23 19 21.88 19 20.5ZM16.5 21H7.5C7.224 21 7 20.776 7 20.5V3.5C7 3.224 7.224 3 7.5 3H8.625L9.349 4.447C9.518 4.786 9.864 5 10.243 5H13.715C14.094 5 14.44 4.786 14.609 4.447L15.333 3H16.5C16.776 3 17 3.224 17 3.5V20.5C17 20.776 16.776 21 16.5 21Z'
        />
      </g>
    </svg>
  );
};
