import React from 'react';

export const GeneralTax = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <g>
          <path d='M7.5 11C9.43 11 11 9.42998 11 7.49998C11 5.56998 9.43 3.99998 7.5 3.99998C5.57 3.99998 4 5.56998 4 7.49998C4 9.42998 5.57 11 7.5 11ZM7.5 5.99998C8.33 5.99998 9 6.66998 9 7.49998C9 8.32998 8.33 8.99998 7.5 8.99998C6.67 8.99998 6 8.32998 6 7.49998C6 6.66998 6.67 5.99998 7.5 5.99998Z' />
          <path d='M18.59 3.99561L4.0025 18.5831L5.4167 19.9973L20.0042 5.40981L18.59 3.99561Z' />
          <path d='M16.5 13C14.57 13 13 14.57 13 16.5C13 18.43 14.57 20 16.5 20C18.43 20 20 18.43 20 16.5C20 14.57 18.43 13 16.5 13ZM16.5 18C15.67 18 15 17.33 15 16.5C15 15.67 15.67 15 16.5 15C17.33 15 18 15.67 18 16.5C18 17.33 17.33 18 16.5 18Z' />
        </g>
      </g>
    </svg>
  );
};
