import React from 'react';

export const GeneralRent = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path d='M15.8691 3.0001C14.2719 3.07023 13 4.3901 13 6.0001C13 7.3001 13.84 8.40041 15 8.82041V12.0001C15 12.5501 15.45 13.0001 16 13.0001H18C18.55 13.0001 19 12.5501 19 12.0001C19 11.4501 18.55 11.0001 18 11.0001H17V8.82041C18.34 8.34041 19.2495 6.96049 18.9395 5.40049C18.7095 4.22049 17.7403 3.27088 16.5703 3.05088C16.3316 3.00713 16.0973 2.99008 15.8691 3.0001ZM16 5.0001C16.55 5.0001 17 5.4501 17 6.0001C17 6.5501 16.55 7.0001 16 7.0001C15.45 7.0001 15 6.5501 15 6.0001C15 5.4501 15.45 5.0001 16 5.0001ZM6 11.0001C5.68957 11.0013 5.38121 11.0508 5.08594 11.1466L1 12.1075V19.3087L5 18.0001L13.1562 20.8536C13.8083 21.0816 14.5267 21.0273 15.1367 20.7013L21.2676 17.4239C21.9386 17.0649 22.2107 16.2438 21.8887 15.5548C21.5557 14.8428 20.7111 14.5315 19.9961 14.8595L14.0859 17.5704L13.7129 17.0821L14.0078 16.4278C14.4628 15.4128 14.0024 14.2195 12.9824 13.7755L7.23047 11.2677C7.19562 11.2514 7.16045 11.2357 7.125 11.2208C6.76767 11.0756 6.3857 11.0007 6 11.0001Z' />
      </g>
    </svg>
  );
};
