import React, { useMemo } from 'react';

import { Box, FormHelperText, Stack, Typography, TypographyProps } from '@mui/material';
import { RatingProps } from '@mui/material/Rating';
import colors from '@packs/assets/theme/base/colors';
import { BusinessVoteModalForm } from '@packs/components/BusinessVoteModal/BusinessVoteModal';
import { useCategories } from '@packs/models/category/useCategories';
import { RatingMUI } from '@shared/components/RatingMUI/RatingMUI';

import { Controller, useFormContext } from 'react-hook-form';

const ServiceRate = () => {
  const { categories } = useCategories();
  const { control, watch } = useFormContext<BusinessVoteModalForm>();
  const categoryId = watch('categoryId');

  const isInsuranceCategory = useMemo(() => {
    return categories.some(
      category => category.code === 'insurance' && category.leafChildren.some(item => item.id === categoryId)
    );
  }, [categories, categoryId]);

  return (
    <Stack spacing={3}>
      <Controller
        name='customerServiceRate'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Box>
            <Typography variant='body2'>Customer service</Typography>
            <Stack direction='row' spacing={1} alignItems='center' sx={{ mt: 1 }}>
              <RatingMUI
                value={field.value}
                onChange={field.onChange}
                readOnly={false}
                size={'extraLarge' as RatingProps['size']}
              />
              <Typography variant={'body4' as TypographyProps['variant']} color={colors.text.secondary}>
                {field.value || 0} out of 5
              </Typography>
            </Stack>
            <FormHelperText error={!!error?.message}>{error?.message}</FormHelperText>
          </Box>
        )}
      />
      <Controller
        name='valueForMoneyRate'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Box>
            <Typography variant='body2'>Value for money</Typography>
            <Stack direction='row' spacing={1} alignItems='center' sx={{ mt: 1 }}>
              <RatingMUI
                value={field.value}
                onChange={field.onChange}
                readOnly={false}
                size={'extraLarge' as RatingProps['size']}
              />
              <Typography variant={'body4' as TypographyProps['variant']} color={colors.text.secondary}>
                {field.value || 0} out of 5
              </Typography>
            </Stack>
            <FormHelperText error={!!error?.message}>{error?.message}</FormHelperText>
          </Box>
        )}
      />
      <Controller
        name='staffKnowledgeRate'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Box>
            <Typography variant='body2'>Staff Knowledge</Typography>
            <Stack direction='row' spacing={1} alignItems='center' sx={{ mt: 1 }}>
              <RatingMUI
                value={field.value}
                onChange={field.onChange}
                readOnly={false}
                size={'extraLarge' as RatingProps['size']}
              />
              <Typography variant={'body4' as TypographyProps['variant']} color={colors.text.secondary}>
                {field.value || 0} out of 5
              </Typography>
            </Stack>
            <FormHelperText error={!!error?.message}>{error?.message}</FormHelperText>
          </Box>
        )}
      />
      {isInsuranceCategory && (
        <Controller
          name='claimRate'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Box>
              <Typography variant='body2'>Claims experience (optional)</Typography>
              <Stack direction='row' spacing={1} alignItems='center' sx={{ mt: 1 }}>
                <RatingMUI
                  value={field.value}
                  onChange={field.onChange}
                  readOnly={false}
                  size={'extraLarge' as RatingProps['size']}
                />
                <Typography variant={'body4' as TypographyProps['variant']} color={colors.text.secondary}>
                  {field.value || 0} out of 5
                </Typography>
              </Stack>
              <FormHelperText error={!!error?.message}>{error?.message}</FormHelperText>
            </Box>
          )}
        />
      )}
    </Stack>
  );
};

export default ServiceRate;
