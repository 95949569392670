import React, { Fragment } from 'react';
import _ from 'lodash-es';

import { ReminderList } from '@packs/components/Calendar/components/ReminderList';
import PlusCircleIcon from '@packs/images/icons/UI/PlusCircleIcon';
import { useStore } from '@packs/stores';

import { observer } from 'mobx-react';

export const SideRemindersList = observer(
  ({
    list,
    perCategoryReminders,
    setPerCategoryReminders,
    perOtherReminders,
    setPerOtherReminders,
    onCalendarAddNewReminder,
    onCalendarDeleteReminder,
    onCalendarEditReminder
  }) => {
    const { currentCategory } = useStore();

    const categorySmartReminders = _.filter(list, { categoryId: currentCategory.id });
    const otherSmartReminders = _.filter(list, r => r.categoryId !== currentCategory.id);

    const showMoreCategoryRemindersButton = () =>
      perCategoryReminders <= 4 ? (
        <div className='see-more' onClick={() => setPerCategoryReminders(_.size(categorySmartReminders))}>
          <div className='show-more-wrap business-show-more'>
            <span>Show more reminders</span>
          </div>
        </div>
      ) : (
        <div className='see-more' onClick={() => setPerCategoryReminders(4)}>
          <div className='show-more-wrap business-show-more'>
            <span>Show less reminders</span>
          </div>
        </div>
      );

    const showMoreOtherRemindersButton = () =>
      perOtherReminders <= 4 ? (
        <div className='see-more' onClick={() => setPerOtherReminders(_.size(otherSmartReminders))}>
          <div className='show-more-wrap business-show-more'>
            <span>Show more reminders</span>
          </div>
        </div>
      ) : (
        <div className='see-more' onClick={() => setPerOtherReminders(4)}>
          <div className='show-more-wrap business-show-more'>
            <span>Show less reminders</span>
          </div>
        </div>
      );

    return (
      <Fragment>
        <div className='sidebar-reminders'>
          <ReminderList
            reminders={categorySmartReminders.slice(0, perCategoryReminders)}
            {...{ onCalendarDeleteReminder, onCalendarEditReminder }}
          />
          {_.size(categorySmartReminders) > 4 && showMoreCategoryRemindersButton()}

          <ReminderList
            reminders={otherSmartReminders.slice(0, perOtherReminders)}
            {...{ onCalendarDeleteReminder, onCalendarEditReminder }}
          />
          {_.size(otherSmartReminders) > 4 && showMoreOtherRemindersButton()}
        </div>
      </Fragment>
    );
  }
);
