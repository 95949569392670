import borders from '@crm/assets/theme/base/borders';
import colors from '@crm/assets/theme/base/colors';
import pxToRem from '@shared/assets/theme/functions/pxToRem';

const { primary, white } = colors;
const { borderRadius } = borders;

const menuItem = {
  styleOverrides: {
    root: {
      minWidth: pxToRem(160),
      minHeight: 'unset',
      borderRadius: borderRadius.md,
      fontSize: '14px',
      transition: 'background-color 300ms ease, color 300ms ease',

      '&:hover': {
        backgroundColor: `${primary[50]} !important`
      },
      '&.Mui-selected': {
        backgroundColor: `${primary.main} !important`,
        color: white.main
      }
    }
  }
};

export default menuItem;
