import React from 'react';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';

import useStyles from './styles';

const { text } = colors;

export const Benefits = ({ company }) => {
  const styles = useStyles();
  const benefits = company?.benefits || [];

  const BenefitItem = ({ title, description }) => {
    return (
      <Box sx={styles.item}>
        <Box>
          <CheckRoundedIcon />
        </Box>
        <Box>
          <Box sx={{ mb: '2px' }}>
            <Typography variant='body5' sx={{ color: text.secondary }}>
              {title}:
            </Typography>
          </Box>
          <Box>
            <Typography variant='body6'>{description}</Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={styles.wrapper}>
      {benefits.map(({ title, description }) => (
        <BenefitItem key={title + description} title={title} description={description} />
      ))}
    </Box>
  );
};
