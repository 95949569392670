import React from 'react';

export const GeneralKids = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path d='M7 2C6.33696 2 5.70107 2.26339 5.23223 2.73223C4.76339 3.20107 4.5 3.83696 4.5 4.5C4.5 5.16304 4.76339 5.79893 5.23223 6.26777C5.70107 6.73661 6.33696 7 7 7C7.3283 7 7.65339 6.93534 7.95671 6.8097C8.26002 6.68406 8.53562 6.49991 8.76777 6.26777C8.99991 6.03562 9.18406 5.76002 9.3097 5.45671C9.43534 5.15339 9.5 4.8283 9.5 4.5C9.5 3.83696 9.23661 3.20107 8.76777 2.73223C8.29893 2.26339 7.66304 2 7 2ZM17 2C16.337 2 15.7011 2.26339 15.2322 2.73223C14.7634 3.20107 14.5 3.83696 14.5 4.5C14.5 5.16304 14.7634 5.79893 15.2322 6.26777C15.7011 6.73661 16.337 7 17 7C17.3283 7 17.6534 6.93534 17.9567 6.8097C18.26 6.68406 18.5356 6.49991 18.7678 6.26777C18.9999 6.03562 19.1841 5.76002 19.3097 5.45671C19.4353 5.15339 19.5 4.8283 19.5 4.5C19.5 3.83696 19.2366 3.20107 18.7678 2.73223C18.2989 2.26339 17.663 2 17 2ZM5 8C3.9 8 3 8.89 3 10V17H4V20C4 20.552 4.448 21 5 21C5.552 21 6 20.552 6 20V17H8V20C8 20.552 8.448 21 9 21C9.552 21 10 20.552 10 20V17H11V10C11 8.89 10.1 8 9 8H5ZM15.75 8C14.87 8 14.0801 8.59922 13.8301 9.44922L11.6699 17H14V20C14 20.552 14.448 21 15 21C15.552 21 16 20.552 16 20V17H18V20C18 20.552 18.448 21 19 21C19.552 21 20 20.552 20 20V17H22.3301L20.1699 9.44922C19.9199 8.59922 19.13 8 18.25 8H15.75Z' />
      </g>
    </svg>
  );
};
