import React from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, Button, ButtonGroup, MenuItem, MenuList, Popover } from '@mui/material';
import { grey } from '@mui/material/colors';

const ButtonSelect = ({
  buttonValue,
  options,
  value,
  onChange,
  disabled = false,
  backgroundColor = grey[200],
  wrapperSx = {}
}) => {
  const handleMenuItemClick = (event, val) => {
    onChange(val);
    handleClose();
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'dropdown-select-popover' : undefined;

  return (
    <Box sx={{ backgroundColor, borderRadius: 1, ...wrapperSx }}>
      <ButtonGroup
        variant='text'
        color='inherit'
        size='small'
        disableElevation
        disabled={disabled}
        sx={{ width: '100%' }}
      >
        <Button sx={{ flexGrow: 1, color: 'rgba(0, 0, 0, 0.87)' }} onClick={event => event.stopPropagation()}>
          {buttonValue}
        </Button>
        <Button sx={{ px: 1 }} onClick={handleClick}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          horizontal: 'right'
        }}
        PaperProps={{
          style: { backgroundColor: '#fff' }
        }}
      >
        <MenuList autoFocusItem>
          {options.map(option => (
            <MenuItem
              key={option.value}
              selected={option.value === value}
              onClick={event => handleMenuItemClick(event, option.value)}
            >
              {option.title}
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
    </Box>
  );
};

export default ButtonSelect;
