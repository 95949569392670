import { action, observable, runInAction } from 'mobx';

import { notesApi } from '@packs/apis/notes';
import { setError } from '@packs/lib/utils';

export class NotesStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable
  list = [];
  @observable
  loading = false;
  @observable
  total = 0;
  @observable
  filters = {};
  @observable
  noteYear = '';

  @action
  setLoading(state) {
    runInAction(() => {
      this.loading = state;
    });
  }

  @action
  load({ filters }) {
    return notesApi.fetchList(filters).then(notes => {
      if (notes?.errors?.length) {
        setError(notes.errors);
        return Promise.reject(notes.errors);
      }

      runInAction(() => {
        this.list = notes;
      });

      return notes;
    });
  }

  @action
  create(attrs) {
    return notesApi.createNote(attrs).then(resp => {
      if (resp.errors.length) {
        setError(resp.errors);
        return Promise.reject(resp.errors);
      }

      runInAction(() => {
        this.list = [resp.note, ...this.list];
      });

      return resp.note;
    });
  }

  @action
  update(attrs) {
    return notesApi.updateNote(attrs).then(resp => {
      if (resp.errors.length) {
        setError(resp.errors);
        return Promise.reject(resp.errors);
      }

      runInAction(() => {
        this.list = this.list.map(note => {
          if (note.id === resp?.note?.id) {
            return resp?.note;
          }

          return note;
        });
      });

      return resp.note;
    });
  }

  @action
  delete(attrs) {
    return notesApi.deleteNote({ input: attrs }).then(resp => {
      if (resp.errors.length) {
        setError(resp.errors);
        return Promise.reject(resp.errors);
      }

      runInAction(() => {
        this.list = this.list.filter(note => note.id !== +attrs?.id);
      });

      return resp;
    });
  }

  @action
  setNoteYear(val) {
    this.noteYear = val;
  }
}
