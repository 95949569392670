import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import { SelectField } from '@packs/components/SelectField';
import { useCategories } from '@packs/models/category/useCategories';

import companyApi from '../../apis/company';

import { observer } from 'mobx-react';
import { Field } from 'redux-form';

const makeOption = options => {
  return options.map(c => ({ value: c.id, label: c.name, ...c }));
};

export const SelectFieldAnyCompany = observer(({ className }) => {
  const { currentCategory } = useCategories();

  const loadOptions = (keyWord = '') => {
    return companyApi
      .winnerCompanies({
        categoryId: currentCategory.id,
        perPage: 10,
        keyWord
      })
      .then(resp => {
        if (!resp?.errors || resp?.errors.length === 0) {
          return [
            ...[{ label: 'The business you are interested in:', title: true, options: makeOption(resp.interestedIn) }],
            ...[{ label: 'All business:', title: true, options: makeOption(resp.all) }]
          ];
        }

        return [];
      });
  };

  const formatOptionLabel = ({ label, avatarUrl }) => {
    return (
      <div className='d-flex align-items-center'>
        {avatarUrl && (
          <div className='option-avatar'>
            <img src={avatarUrl} width={50} alt='Company' className='option-avatar' />
          </div>
        )}

        <span className='fs-12 fw-500'>{label}</span>
      </div>
    );
  };

  const formatGroupLabel = ({ label }) => <div className='fs-11 fw-600 tt-uc mb-8 main-text'>{label}</div>;

  return (
    <Field
      component={SelectField}
      autoload
      name='winnerCompany'
      className={cx('select-ui', className)}
      classNamePrefix='Select'
      formatOptionLabel={formatOptionLabel}
      formatGroupLabel={formatGroupLabel}
      loadOptions={loadOptions}
      options={[]}
      returnObjectValue
    />
  );
});
