import colors from '@packs/assets/theme/base/colors';

const { grey, dark } = colors;

export default () => ({
  wrapper: {
    position: {
      xs: 'static',
      md: 'absolute'
    },
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 1,
    pt: {
      xs: 2,
      md: 4
    },
    px: {
      xs: 2,
      md: 4
    },
    textAlign: 'center',
    zIndex: 5,
    '& svg': {
      fontSize: '40px'
    }
  },
  btnWrapper: {
    minWidth: {
      xs: '32px',
      md: '56px'
    }
  },

  btn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: {
      xs: '32px',
      md: '56px'
    },
    height: {
      xs: '32px',
      md: '56px'
    },
    borderRadius: '24px',
    backgroundColor: grey[200],
    color: dark.main,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: grey[300]
    },
    '& svg': {
      fontSize: {
        xs: '20px',
        md: '40px'
      }
    }
  },
  title: {
    fontSize: {
      xs: '12px',
      md: '16px'
    },
    lineHeight: {
      xs: '18px',
      md: '24px'
    },
    maxWidth: {
      xs: '258px',
      md: 'initial'
    }
  }
});
