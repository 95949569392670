export const isLandingVotePage = () => {
  return location.pathname.match(/^\/\w{2}(?:-\w{2})?\/?$/) || location.pathname.match(/^\/\w{2}(?:-\w{2})?\/?vote$/);
};

export const isLandingPage = pathname => {
  return (pathname || location.pathname).match(/^\/\w{2}(?:-\w{2})?\/?$/);
};

// divide 10 scale to 5 scale for star UI
export const divideStarRating = rate => {
  return rate ? rate / 2 : null;
};
