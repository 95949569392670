import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { Stack } from '@mui/material';
import { SelectMUI } from '@packs/components/MaterialUi/SelectMUI/SelectMUI';
import {
  getDayOption,
  getMonthOption,
  selectOptions
} from '@packs/screens/PersonalizeResults/formHooks/dateTimeFunctions';
import { MONTH_FORMAT } from '@packs/screens/PersonalizeResults/formHooks/defaults';

export const DateFieldSelect = ({
  input: { onChange, value },
  meta: { touched, error },
  controlError,
  defaultMonthsNumber,
  disabled = false,
  monthLabel,
  dateLabel
}) => {
  const currentDay = moment.isMoment(value) ? value : value ? moment(value) : moment();
  const [dateStr, setDateStr] = useState(currentDay.format('DD'));
  const [monthStr, setMonthStr] = useState(currentDay.format(MONTH_FORMAT));

  const getDefaultMonthOption = options => {
    const currentMonth = moment().format(MONTH_FORMAT);
    const months = options.yearMonth;
    const monthIndex = months.findIndex(op => op.value === currentMonth);

    return months[monthIndex + defaultMonthsNumber];
  };

  useEffect(() => {
    setMonthStr(currentDay.format(MONTH_FORMAT));
  }, [currentDay.format(MONTH_FORMAT)]);

  useEffect(() => {
    if (defaultMonthsNumber) {
      const monthOp = getDefaultMonthOption(selectOptions(new Date()));

      if (monthOp?.value) {
        onChange(moment(`${dateStr} ${monthOp.value}`));
      }
    }
  }, [defaultMonthsNumber]);

  const onChangeMonthHandler = ({ value }) => {
    const val = moment(`${dateStr} ${value}`);
    setMonthStr(value);
    onChange(val);
  };

  const onChangeDayHandler = ({ value }) => {
    const val = moment(`${value} ${monthStr}`);
    setDateStr(value);
    onChange(val);
  };

  const options = selectOptions(value);

  return (
    <Stack direction='row' spacing={2}>
      <SelectMUI
        options={options.yearMonth}
        value={getMonthOption(value)}
        onChange={(_, val) => onChangeMonthHandler(val)}
        label={monthLabel}
        disabled={disabled}
        error={touched && error}
        helperText={controlError}
      />
      <SelectMUI
        options={options.day}
        value={getDayOption(value)}
        onChange={(_, val) => onChangeDayHandler(val)}
        label={dateLabel}
        disabled={disabled}
        error={touched && error}
        helperText={controlError}
      />
    </Stack>
  );
};
